import React, { Component } from 'react'

import './css/Card.css';

class Card extends Component {
    render() {
        return (
            <div className={"card " + this.props.addCSS}>
                {this.props.children} 
            </div>
        );
    }
}

export default Card
