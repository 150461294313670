import React from 'react';
import Label from '../../atom/Label/Label';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import Button from '../../atom/Button/Button';
import RadioButtonGroup from '../../molecule/RadioButtonGroup/RadioButtonGroup';
import RadioButton from '../../atom/RadioButon/RadioButon';
class LoanParty extends React.Component{
    render(){
        return(
           <>
                <h6>Loan Party #1</h6>
                <Label>Party Type</Label>
                <RadioButtonGroup onChange={this.onChange}>
                    <RadioButton value="borrower" name="PartyType" for="Borrower"/>                              
                    <RadioButton value="guarantor" name="PartyType" for="Guarantor"/>
                </RadioButtonGroup>
                <Label>Individual or Company</Label>
                <RadioButtonGroup onChange={this.onChange}>
                    <RadioButton value="person" name="PersonOrCompany" for="Person"/>                              
                    <RadioButton value="company" name="PersonOrCompany" for="Company"/>
                </RadioButtonGroup>
                <Label>Name</Label>
                <FormTextBox type="text" className="formTextBox" placeholder="Given Name"  />
                <FormTextBox type="text" className="formTextBox" placeholder="Surname"  />
                <Label>Email</Label>
                <FormTextBox type="text" className="formTextBox" placeholder="example@email.com"  />
            </>)
    }
}

export default LoanParty