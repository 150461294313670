import React from 'react';
import CenterContent from '../../template/CenterContent/CenterContent'; 
import PasswordCodeForm from '../../organism/PasswordCodeForm/PasswordCodeForm'; 
import ResetPasswordForm from '../../organism/ResetPasswordForm/ResetPasswordForm'; 

import Card from '../../atom/Card/Card';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';
import { Redirect }                                         from 'react-router-dom';

import './css/ResetPasswordPage.css';

import MSALogo from '../../../images/logo/MSANational.png';
import DisclaimerPage from '../DisclaimerPage/DisclaimerPage';
import { AuthService } from '../../../services/auth-service';

class ResetPasswordPage extends React.Component{
    constructor(props){
        super(props);
        let matterrestrictions = AuthService.getUserTypeRestrictions();
        this.state={
            createAccount: false,
            userName : '',
            forgotPassword: true,
            enterCode: false,
            resetSuccess: false,
            successMessage: null,
            mustAuthenticate: null,
            userPassword: null,
            matterrestrictions: matterrestrictions,
            UserHasNewLoansRestriction: null,
            UserHasDischargesRestriction: null,
            UserHasConsentsRestriction: null,
            UserHasMatterRestriction: false
    }

        this.handleCodeFormCallback = this.handleCodeFormCallback.bind(this);
        this.handleResetPasswordCallback = this.handleResetPasswordCallback.bind(this);
        this.handleCancelCallback = this.handleCancelCallback.bind(this);
        
    }

    handleCancelCallback(event){
        //event.preventDefault();
        //this.CleanErrorAndSuccess();
        this.setState({
            cancel:null,
            enterCode:null,
            errorMessage:null,
            userName:'',
        });
        this.props.parentCallback([false,null]);
    }

    handleCodeFormCallback = (childData) => {
        this.setState(
            {
                forgotPassword: childData,
                userName: childData[0],
                enterCode: childData[1],                
                successMessage: childData[2]
            }, ()=>{
                this.props.parentCallback(this.state.forgotPassword);
            }
        );
        
    }

    handleResetPasswordCallback = (resetdata) =>{
        var matterrestrictions = AuthService.getUserTypeRestrictions();
        
        let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        
        this.setState({
            resetSuccess: resetdata[0],
            mustAuthenticate: resetdata[1],
            userPassword: resetdata[2],
            enterCode: false,
            UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
            UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
            UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
            UserHasMatterRestriction: ((userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false) ||
                                        (userHasDischargesRestriction[0] != null && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false) ||
                                        (userHasConsentsRestriction[0] != null && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false)
                                        )? true: false
                
        });

    }

    disclaimerCallback= (ack) => {
        var matterrestrictions = AuthService.getUserTypeRestrictions();
        
        let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        this.setState({
            shouldRedirect: ack,
            resetSuccess: ack,
            UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
            UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
            UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
            UserHasMatterRestriction: ((userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false) ||
                                        (userHasDischargesRestriction[0] != null && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false) ||
                                        (userHasConsentsRestriction[0] != null && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false)
                                        )? true: false
                
        });
    }

    render(){
        if(this.state.enterCode){
            return(
                <CenterContent>
    
                    <MarginGuide />
        
                    <div className="content">
                        <Card addCSS="cardReset">
                        {/* <ResetPasswordForm userName={this.state.userName} callBack = {this.handleResetPasswordCallback}/> */}
                            <ResetPasswordForm resetDetails={[this.state.userName,this.state.successMessage]} callBack = {this.handleResetPasswordCallback} cancelCallback = {this.handleCancelCallback}/>
                        </Card> 
                    </div>
                </CenterContent>  
            );
        }
        if(this.state.resetSuccess){
            // return(
            //     <Redirect to = '/dashboard' />  
            // );
            if(this.state.UserHasMatterRestriction){
                if(this.state.UserHasNewLoansRestriction){
                    return ( <Redirect to = '/dashboard' /> );
                }
                else{
                    if(this.state.UserHasDischargesRestriction){
                        return ( <Redirect to = '/discharges' /> );
                    }                    
                    else{
                        return ( <Redirect to = '/consents' /> );
                    }
                }
                
            }
            else  return ( <Redirect to = '/dashboard' /> ); 
        }
        if(this.state.mustAuthenticate){
            
                return(<DisclaimerPage userDetails = {[this.state.userName, this.state.userPassword]} parentCallback = {this.disclaimerCallback} />);
            
        }
        return(
            <CenterContent>

                <MarginGuide />
    
                <div className="content">
                    <Card addCSS="cardReset">
                        <PasswordCodeForm userName={this.props.userName} callBack = {this.handleCodeFormCallback} /> 
                    </Card> 
                </div>
            </CenterContent>  
        );
    }

}
export default ResetPasswordPage;