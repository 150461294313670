import React, { Component } from 'react'
import PropTypes from "prop-types";
import Button from '../../atom/Button/Button';
import IconBox from '../../atom/IconBox/IconBox';

import { MatterService } from '../../../services/matter-service';
import AllOption from "../../../models/all-option";

 
import './css/CustodySearchLoanBox.css';
import DropDownList from '../../atom/DropDownList/DropDownList';
import LenderControl from './lender-control';
import MortManagerControl from './mort-manager-control';

class CustodySearchLoanBox extends Component {
    //anchor = null;
  constructor(props) {
    super(props);
    this.addressChanged = this.addressChanged.bind(this);
    this.borrowersChanged = this.borrowersChanged.bind(this);
    // //this.buildColumnEditor = this.buildColumnEditor.bind(this);
    this.detailedSearch = this.detailedSearch.bind(this);
    // this.generateCSV = this.generateCSV.bind(this);
    this.fileOwnerChanged = this.fileOwnerChanged.bind(this);
    // this.handleCancelUpdate = this.handleCancelUpdate.bind(this);
    // this.handleColumnClick = this.handleColumnClick.bind(this);
    // this.handleColumnUpdate = this.handleColumnUpdate.bind(this);
    this.lenderChanged = this.lenderChanged.bind(this);
    this.lenderRefNoChanged = this.lenderRefNoChanged.bind(this);
    this.loadLookupLists = this.loadLookupLists.bind(this);
     this.matterNoChanged = this.matterNoChanged.bind(this);
    this.matterStateChanged = this.matterStateChanged.bind(this);
    this.matterStatusChanged = this.matterStatusChanged.bind(this);
    this.matterTypeChanged = this.matterTypeChanged.bind(this);
    this.mortManagerChanged = this.mortManagerChanged.bind(this);
    // this.brokerDetailChanged = this.brokerDetailChanged.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.titleRefNoChanged = this.titleRefNoChanged.bind(this);
    // this.workComplete = this.workComplete.bind(this);
     this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    // let as = this.props.getAdvancedSearch();

    this.state = {
      matterTypes: [],
      matterStatuses: [],
      matterStates: [],
      fileOwners: [],
      lenders: [],
      mortManagers: [],
      shouldSearch: null,
      matterNo: null,
      borrowers: null,
      matterType: null,
      matterStatus: null,
      matterState: null,
      fileOwner: null,
      lender: null,
      mortManager: null,
      titleRefNo: null,
      lenderRefNo: null,
      address:null,
      brokers:[],
      broker:null,
      show: false,
      isWorking: false,
      width:'',
      height:''
    };
  }

  componentDidMount() {
    //instantiate advanced search to state
    //let as = this.props.getAdvancedSearch();
    // console.log("as")
    // console.log(as)
    console.log(this.state.matterType)
    this.loadLookupLists();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  loadLookupLists() {
    //load various filters and list options for advanced search function
    let self = this;

    let p1 = MatterService.getMatterTypeGroupList();
    let p2 = MatterService.getMatterStatusList();
    let p3 = MatterService.getStateList();
    let p4 = MatterService.getFileOwnerList();
    let p5 = MatterService.getLenderList();
    let p6 = MatterService.getMortMgrList();
    //let p7 = MatterService.GetBrokerList();
    
    Promise.all([p1, p2, p3, p4, p5, p6]).then(values => {
      let matterTypes = values[0].data;
      matterTypes.splice(0, 0, AllOption);

      let statuses = values[1].data;
      statuses.splice(0, 0, AllOption);

      let states = values[2].data;
      states.splice(0, 0, AllOption);

      let fileOwners = values[3].data;
      fileOwners.splice(0, 0, AllOption);

      let lenders = values[4].data;

      let mortManagers = values[5].data;

    //  let brokers = values[6].data;
      //brokers.splice(0,0,AllOption);
      self.setState({
        //set values
        matterTypes: matterTypes,
        //matterType: matterTypes,
        matterStatuses: statuses,
        //matterStatus: statuses,
        matterStates: states,
       // matterState: states,
        fileOwners: fileOwners,
        //fileOwner: fileOwners,
        lenders: lenders,
        //lender: lenders,
        mortManagers: mortManagers,
        //mortManager: mortManagers,
      //  brokers:brokers,
      //  broker:brokers,
      },()=>{
          console.log(this.state.matterTypes)
      });
    });
    // if (as.shouldSearch) {
    //   this.detailedSearch(); 
    //   as.shouldSearch = false;
    // }
  }

  detailedSearch(){
    //alert(this.state.state)
    let as = [];
    as[0]=this.state.matterNo;
    as[1]=this.state.borrowers;
    as[2]=this.state.lenderRefNo;
    as[3]=this.state.address;
    as[4]=this.state.matterState !=null? this.state.matterState.id:0;
    as[5]=this.state.matterType !=null? this.state.matterType.id:0;
    as[6]=this.state.matterStatus !=null? this.state.matterStatus.id:0;
    as[7]=this.state.mortManager !=null? this.state.mortManager.id:0;
    as[8]=this.state.fileOwner !=null? this.state.fileOwner.id:0;
    as[9]=this.state.titleRefNo;
    as[10]=this.state.lender !=null? this.state.lender.id:0;
    
    this.setState({
      // goBack: child[0],
      // successMessage: child[1]
  },()=>{
      this.props.parentCallback(as);
  });

  }

  resetSearch() {
    //reset search

    // this.props.resetAdvancedSearch();
    // let as = this.props.getAdvancedSearch();
    let self = this;

    // let matterStatus = as.matterStatus;
    // let matterState = as.matterState;

    this.setState(
      {
        // matterType: AllOption,
        // matterStatus: AllOption,
        // matterState: AllOption,
        // fileOwner: AllOption,
         matterNo: "",
        borrowers: "",
        shouldSearch: false,
        matterType: null,//this.state.matterTypes,
        matterTypes: [],//this.state.matterTypes,
        matterStatus: null,//this.state.matterStatuses,
        matterStatuses: [],//this.state.matterStatuses,
        matterState: null,//this.state.matterStates,
        matterStates: [],//this.state.matterStates,
        fileOwner: null,//this.state.fileOwners,
        fileOwners: [],//this.state.fileOwners,
        mortManager: null,//this.state.mortManagers,
        mortManagers: [],//this.state.mortManagers,
        lenders:[],
        lender: null,
        brokerDetail:"",
        titleRefNo: "",
        lenderRefNo: "",
        address: "",
        show: false
      },
      () => {
        self.setState(
          {
           
          },
          () => {
            self.loadLookupLists(); //update lookup lists
          }
        );
      }
    );
  }

  borrowersChanged(event) {
    //changed events for filter

    const target = event.target;
    this.setState({
      borrowers: target.value
    });
  }

  matterNoChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      matterNo: target.value
    });
  }

  titleRefNoChanged(event) {
    //setter

    const target = event.target;
    this.setState({
      titleRefNo: target.value
    });
  }

  matterTypeChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      matterType: {id:target.value}
      //matterType: target.value
    },()=>{
      //alert(this.state.matterType)
    });
  }

  fileOwnerChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      fileOwner: {id:target.value}
    });
  }

  mortManagerChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      mortManager: {id:target.value}
    });
  }

  matterStateChanged(event) {
    //setter

    const target = event.target;
    this.setState({
      matterState: {id:target.value}
    },()=>{
        //alert(this.state.matterState)
    });
  }

  matterStatusChanged(event) {
    //setter

    const target = event.target;
    this.setState({
      matterStatus: {id:target.value}
    });
  }

  lenderChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      lender: {id:target.value}
    });
  }

  lenderRefNoChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      lenderRefNo: target.value
    });
  }

  addressChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      address: target.value
    });
  }

 
    render() {
        return (

           
          <div className={"csbBox "+this.props.display}>
              <div className="csbBG"></div>

              <div className="CustodySearchLoanBox">

                  <IconBox 
                      id="cslbBTNClose"
                      title="Close Search"
                      addCSS="" 
                      iconURL="../../../images/icons/OutlineDark/Cross.svg"
                      clickAction={this.props.closeAction}
                  />
                  
                  {/* <div className="slbTitle">
                      <h5>Search Loans {this.props.display}</h5>
                  </div> */}

                  <div className="cslbFormsBox">
                      <div>
                          <div className="inputLabel">
                              MSA Reference
                          </div>
                          <div>
                              <input 
                                  type="text"
                                  name="matterNo"
                                  onChange={this.matterNoChanged}
                                  value={this.state.matterNo}
                                  autoFocus 
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Loan Type 
                          </div>
                          <div>
                              <DropDownList options={this.state.matterTypes} value={this.state.matterTypes} onChange={this.matterTypeChanged} >
                              </DropDownList>              
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              MSA File Owner 
                          </div>
                          <div>
                              <DropDownList options={this.state.fileOwners} value={this.state.fileOwners} onChange={this.fileOwnerChanged}>
                              </DropDownList>
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Title Reference 
                          </div>
                          <div>
                              <input type="text" 
                                  onChange={this.titleRefNoChanged}
                                  value={this.state.titleRefNo}
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Borrowers
                          </div>
                          <div>
                              <input type="text" 
                                  onChange={this.borrowersChanged}
                                  value={this.state.borrowers}
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Status 
                          </div>
                          <div>
                              <DropDownList options={this.state.matterStatuses} value={this.state.matterStatuses} onChange={this.matterStatusChanged}>
                              </DropDownList>
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Lender 
                          </div>
                          <div>
                              <LenderControl
                                  data={this.state.lenders}
                                  value={this.state.lender}
                                  onChange={this.lenderChanged}
                              />
                              {/* <DropDownList options={this.state.lenders} value={this.state.lender} onChange={this.lenderChanged}>
                                  
                              </DropDownList> */}
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Lender Reference 
                          </div>
                          <div>
                              <input type="text" 
                                  onChange={this.lenderRefNoChanged}
                                  value={this.state.lenderRefNo}
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Branch 
                          </div>
                          <div>
                              <DropDownList options={this.state.matterStates} value={this.state.matterStates} onChange={this.matterStateChanged}>
                              </DropDownList>
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Referer 
                          </div>
                          <div>
                              {/* <MortManagerControl
                                  data={this.state.mortManagers}
                                  value={this.state.mortManager}
                                  onChange={this.mortManagerChanged}
                              /> */}
                              <DropDownList options={this.state.mortManagers} value={this.state.mortManagers} onChange={this.mortManagerChanged}>
                                  
                              </DropDownList>
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Security 
                          </div>
                          <div>
                              <input type="text"
                                  onChange={this.addressChanged}
                                  value={this.state.address}
                              />
                          </div>
                      </div>
                      <div className="cslbActionBox">  
                          <div className="cslbAction" style={{"grid-template-columns":this.props.slbActionGTC}}>
                              <Button clickFunction={this.detailedSearch}>Search</Button>
                              <Button addCSS="secondaryBTN" clickFunction={this.resetSearch}>Clear</Button>
                              <Button id="closeButton" addCSS="secondaryBTN btnCloseAdvancedSearch" clickFunction={this.props.closeAction}>Close</Button>

                              {this.props.columnBtnStatus==1?( 
                              <div 
                                className="btnFieldsBox"
                                //onClick={this.props.columnAction}
                              > 
                                <IconBox
                                  iconURL="../../../images/icons/OutlineDark/Options.svg"
                                  addCSS=""
                                  tooltip="Column Actions" 
                                />
                              </div>
                              ):""}
                          </div>
                      </div> 
                  </div>
              
              </div>
       
          </div>
                        
        )
    }
}
// CustodySearchLoanBox.propTypes = {
//     getAdvancedSearch: PropTypes.func.isRequired,
//     handleDetailedSearch: PropTypes.func.isRequired,
//     handleGenerateCSV: PropTypes.func.isRequired,
//     updateAdvancedSearch: PropTypes.func.isRequired
//   };
export default CustodySearchLoanBox
