import React, { Component } from 'react'

import './css/ValidationText.css';

class ValidationText extends Component {
    render() {
        return (
            <div className={this.props.msgeType}>
                {this.props.children} 
            </div>
        )
    }
}

export default ValidationText
