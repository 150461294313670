import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent'; 

import './css/InsightsPage.css';  
import AutoLogout from '../../../auto-logout';
import { MatterService } from '../../../services/matter-service';
import { AuthService } from '../../../services/auth-service';

class InsightsPage extends Component {

    constructor(props){
        super(props);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        this.state = {
            pageTitle : 'insights',
            leftContent : 'insights',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            profileDisplay: "",
            loanStatistics: [],
            shouldRedirect: false,   
        }

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    componentDidMount(){
        window.addEventListener("resize", this.updateWindowDimension)
        //check if user has priv to view LoanStat

        AuthService.canUserViewLoanStat().then((r)=>{
            //alert("page"+r.data.Content)
            this.setState({canUserViewLoanStat:r.data.Content},()=>
            {
                if(this.state.canUserViewLoanStat == true){
                    MatterService.getLoanStatisics().then((resp) => {
                        let loanStat = resp.data.Content;
                        console.log(loanStat)
                        this.setState({
                            loanStatistics: loanStat
                        })
            
                        
                    }, err => {
                        this.setState({
                            shouldRedirect: true
                        })
                    });
                }
                else
                {
                    this.setState({
                        shouldRedirect: true
                    })
                }
            });
        },(err)=>{
            this.setState({canUserViewLoanStat:false});
        });

        
    }

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }


     
    render() {

        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        }
        
        if(this.state.leftContent != this.state.pageTitle){
            return( 
                <DisplayLeftMenu 
                    page={this.state.leftContent} 
                    desktopMenu={this.state.desktopMenu}
                    lmwInstructMSASub={this.state.lmwInstructMSASub} 
                />
            )
        }
 
        return ( 
             
            <LeftMenuContent 
                id="InsightsPage" 
                page="insights" 
                desktopMenu={this.state.desktopMenu}
                callBack = {this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
            > 
                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />
                       

                    <div className="content">                          
                        <div className='InsightBox'>
                            <TitleSegment>Loan Insights</TitleSegment>

                            {/* <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div> */}

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>{this.state.loanStatistics.SettledMatterCount}</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Securities</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>{this.state.loanStatistics.NSWSecurities}</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>{this.state.loanStatistics.VICSecurities}</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>{this.state.loanStatistics.QLDSecurities}</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>{this.state.loanStatistics.WASecuritiesCount}</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>{this.state.loanStatistics.SASecuritiesCount}</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>{this.state.loanStatistics.TASSecurities}</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>{this.state.loanStatistics.NTSecurities}</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>ACT</div>
                                    <div className='SGData'>{this.state.loanStatistics.ACTSecurities}</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>{this.state.loanStatistics.AverageDaysToSettlement}</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>{this.state.loanStatistics.SettledUsingEsignMatterCount}</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>{this.state.loanStatistics.EscalatedMatterCount}</div>
                            </div>

                            {/* <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div>  */}

                        </div>

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}

InsightsPage=AutoLogout(InsightsPage);
export default InsightsPage

