import React, { Component } from 'react' 
import { Redirect } from 'react-router-dom';
import DisplayLeftMenu from '../../template/DisplayLeftMenu';

import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import FormCheckBox from '../../atom/FormCheckBox/FormCheckBox';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import IconBox from '../../atom/IconBox/IconBox';
import Line from '../../atom/Line/Line';
import MessageBox from '../../atom/MessageBox/MessageBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import BackBar from '../../molecules/BackBar/BackBar'; 
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';
import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox'; 

import Accordion from '../../organism/Accordion/Accordion'; 
import DataTableDocuments from '../../organism/DataTableDocuments/DataTableDocuments';
import CommunicationContent from '../../organism/CommunicationContent/CommunicationContent';
import CommunicationForm from '../../organism/CommunicationForm/CommunicationForm';
import LoanDetails from '../../organism/LoanDetails/LoanDetails';
import ProgressStep from '../../organism/ProgressStep/ProgressStep';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/PayoutUploadPage.css';   
import { AuthService } from '../../../services/auth-service';
import { InstructionService } from '../../../services/instruction-service';
import ValidationText from '../../atom/ValidationText/ValidationText';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import AutoLogout from '../../../auto-logout';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import InsightBox from '../../molecules/InsightBox/InsightBox';


class PayoutUploadPage extends Component { 
    
    constructor(props){
        super(props) 

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        let userdetails = AuthService.getUserDetails();

        this.state={ 
            pageTitle : 'payoutupload',
            leftContent : 'payoutupload',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            isInstructPriv: false,
            isLoading:false,
            onboarding:true,
            fade:false,
            option:"",
            prompt:"",
            copy:"This page can update MSA's systems, eliminating the need to it for an update to be actioned. only certain lenders are permitted to instruct MSA through the portal. For documentation please contact MSA. You will receive a message asking you to confirm your changes.",
            lenderReference:'',
            dischargeType:0,
            dischargeTypeName:'',
            subDischargeType:0,
            subDischargeTypeOptions:['Sale','External Refinance','Internal Refinance','Debt Repaid'],
            matterNumber:'',
            matterDescription:'',
            isChecking:false,
            isExistingLenderReference:false,
            isNewLoanConfirmed:false,
            hasCheckedReferenceNumber:false,
            errorMessage:'',
            LoanIds:[],
            IndicativeAmounts:[],
            LoanIdEntityCount:0,
            LoanIdEntityMinCount:1,
            LoanIdEntities:[],
            PartyRepEntityCount:1,
            PartyRepEntitySegmentMinCount:1,
            PartyRepEntitySegmentMaxCount:1,
            PartyRepNames:[],
            PartyRepTypes:[],
            PartyRepEmails:[],
            PartyRepNumbers:[],
            PartyRepEntities:[],
            IncomingMortgageeNames:[],
            IncomingMortgageeEmails:[],
            IncomingMortgageeNumbers:[],
            IncomingMortgageeType:[],
            IncomingMortgageeSegmentEntities:[],
            IncomingMortgageeSegmentCount:0,
            IncomingMortgageeSegmentMinCount:0,
            IncomingMortgageeSegmentMaxCount:1,
            PartySegmentPhoneNumbers:[],
            PartySegmentPartyTypes:[],
            PartySegmentPorCTypes:[],
            PartySegmentNames:[],
            PartySegmentEmails:[],
            PartySegmentAddresses:[],
            PartySegmentMinCount:1,
            PartySegmentCount:1,
            PartySegmentEntities:[],
            SecuritySegmentAddreses:[],
            SecuritySegmentTitleReferences:[],
            SecuritySegmentEntities:[],
            SecuritySegmentMinCount:1,
            SecuritySegmentCount:1,
            SecuritySegmentMaxCount:1000,
            SimultaneousSettlementSegmentEntities:[],
            SimultaneousSettlementSegmentCount:0,
            SimultaneousSettlementSegmentMaxCount:1,
            SimultaneousSettlementSegmentLoanIds:[],
            LoanHasSimultaneous:false,
            ImportantNoteSegmentEntities:[],
            ImportantNoteSegmentCount:1,
            ImportantNoteSegmentMinCount:1,
            ImportantNoteSegmentMaxCount:1,
            HasImportantNoteSegmentGuarantorTitleDeedToAddress:false,
            ImportantNoteSegmentGuarantorTitleDeedToAddress:[],
            HasBorrowerClearTitleToAddress:false,
            BorrowerClearTitleToAddress:[],
            ImportantNoteSegmentSettlementNotToBeBookedPriorToDates:[],
            ImportantNoteSegmentCollectSurplus:[],
            ImportantNoteSegmentGuarantorNames:[],
            SpecialConditionSegmentEntities:[],
            SpecialConditionSegmentCount:0,
            SpecialConditionSegmentConditionValues:[],
            SpecialConditionSegmentConditionTypes:[],
            InstructionType:null,
            InstructionLenderReference:null,
            
            PayoutLoanDetailsSegmentEntities:[],
            PayoutLoanDetailsSegmentCount:1,
            PayoutLoanDetailsSegmentMinCount:1,
            PayoutLoanDetailsSegmentLoanIds:[],
            PayoutLoanDetailsSegmentPayoutFigures:[],
            PayoutLoanDetailsSegmentAccountNames:[],
            PayoutLoanDetailsSegmentBsbs:[],
            PayoutLoanDetailsSegmentAccountNumbers:[],
            PayoutLoanDetailsSegmentAccountNamesOptions:[],


            PayoutLoanDetailsDateValid:null,
            PayoutTrustOptions:[],
            MatterExists:false,
            CheckedMatter:false,
            MsaReference:null,
            MatterDescription:null,
            MatterLenderReference:null,
            DateValidGood:true,
            SecurityDischargeReasons:[],
            DischargeTypeChoice:[],

            SelfActing:[],
            States:[],
            DischargeTypes:{},
            MappedStates:[],
            msg:'',

            PayoutHasSpecialConditions:false,
            PayoutSpecialConditions:'',

            HasAnticipatedDate:false,
            MatterDateValid:null,
            MatterAdditionalNotes:null,
            MatterLoanAccounts:null,
            PayoutStage:'',
            shouldRedirect:false,
            InstructionSubmit:false,
            CanInstructNewLoans: false,
            CanInstructDischarges: false,
            CanProvideDischargePayout: false,
            CanInstructDischargeUpload: false,
            IsLenderThinkTank: false,
            // ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            userdetails: userdetails,
            matterValidationMessage: null,
            profileDisplay: "",
            InsightBox:0, 
        }
    

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        //this.CheckMatter=this.CheckMatter.bind(this);
        //this.UpdateLenderReferenceNumber = this.UpdateLenderReferenceNumber.bind(this);
        //this.LoanIdUpdated = this.LoanIdUpdated.bind(this);
        //this.IndicativeAmountUpdated=this.IndicativeAmountUpdated.bind(this);
        //this.UpdateLoanIdSegment=this.UpdateLoanIdSegment.bind(this);
        //this.CreateDischargeInstructionEntity=this.CreateDischargeInstructionEntity.bind(this);
        this.GetDischargeTypes=this.GetDischargeTypes.bind(this);
        this.GetStates = this.GetStates.bind(this);
        //this.LoanIdUpdated=this.LoanIdUpdated.bind(this);
        //this.IndicativeAmountUpdated = this.IndicativeAmountUpdated.bind(this);
        //this.UpdateLoanIdSegment = this.UpdateLoanIdSegment.bind(this);
        //this.PartyRepNameUpdated = this.PartyRepNameUpdated.bind(this);
        //this.PartyRepEmailUpdated = this.PartyRepEmailUpdated.bind(this);
        //this.PartyRepNumberUpdated = this.PartyRepNumberUpdated.bind(this);
        //this.UpdatePartyRep = this.UpdatePartyRep.bind(this);
        this.IncomingMortgageeNameUpdated = this.IncomingMortgageeNameUpdated.bind(this);
        this.IncomingMortgageeEmailUpdated=this.IncomingMortgageeEmailUpdated.bind(this);
        this.IncomingMortgageeNumberUpdated = this.IncomingMortgageeNumberUpdated.bind(this);
        this.UpdateIncomingMortgageeSegment = this.UpdateIncomingMortgageeSegment.bind(this);
        //this.PartySegmentEmailUpdated = this.PartySegmentEmailUpdated.bind(this);
        //this.PartySegmentNumberUpdated = this.PartySegmentNumberUpdated.bind(this);
        //this.PartyAddressUpdated=this.PartyAddressUpdated.bind(this);
        //this.PartyTypeUpdated=this.PartyTypeUpdated.bind(this);
        //this.PartyNameUpdated = this.PartyNameUpdated.bind(this);
        //this.UpdatePartySegment=this.UpdatePartySegment.bind(this);
        //this.SecurityAddressUpdated = this.SecurityAddressUpdated.bind(this);
        //this.UpdateSecuritySegment = this.UpdateSecuritySegment.bind(this);
        //this.SimultaneousSettlementSegmentLoanIdUpdated = this.SimultaneousSettlementSegmentLoanIdUpdated.bind(this);
        //this.UpdateSimultaneuosSettlementSegment = this.UpdateSimultaneuosSettlementSegment.bind(this);
        //this.UpdateGuarantorTitleDeedToAddress = this.UpdateGuarantorTitleDeedToAddress.bind(this);
        //this.UpdateBorrowerClearTitleToAddress = this.UpdateBorrowerClearTitleToAddress.bind(this);
        //this.SettlementNotToBeBookedPriorToUpdated = this.SettlementNotToBeBookedPriorToUpdated.bind(this);
        //this.CollectSurplusUpdated = this.CollectSurplusUpdated.bind(this);
        //this.GuarantorUpdated = this.GuarantorUpdated.bind(this);
        //this.UpdateImportNotesSegment = this.UpdateImportNotesSegment.bind(this);
        //this.UpdateSpecialConditionValues = this.UpdateSpecialConditionValues.bind(this);
        //this.UpdateSpecialConditionSegmentTypes = this.UpdateSpecialConditionSegmentTypes.bind(this);
        //this.UpdateSpecialConditionSegment = this.UpdateSpecialConditionSegment.bind(this);
        //this.CreateDischargeInstructionEntity =this.CreateDischargeInstructionEntity.bind(this);
        //this.SecurityTitleReferenceUpdated = this.SecurityTitleReferenceUpdated.bind(this);
        this.CreateDischargePayoutEntity = this.CreateDischargePayoutEntity.bind(this);
        this.CheckMatterExists = this.CheckMatterExists.bind(this);
        //this.MsaReferenceUpdate = this.MsaReferenceUpdate.bind(this);
        this.PayoutDateValidUpdated = this.PayoutDateValidUpdated.bind(this);
        this.PayoutLoanIdUpdated = this.PayoutLoanIdUpdated.bind(this);
        this.PayoutLoanFigureUpdate = this.PayoutLoanFigureUpdate.bind(this);
        this.PayoutAccountNameUpdated = this.PayoutAccountNameUpdated.bind(this);
        this.PayoutAccountNumberUpdated = this.PayoutAccountNumberUpdated.bind(this);
        this.PayoutAccountBsbUpdated = this.PayoutAccountBsbUpdated.bind(this);
        this.PayoutTrustUpdated = this.PayoutTrustUpdated.bind(this);
        this.UpdatePayoutSegment = this.UpdatePayoutSegment.bind(this);
        this.GetTrustAccountOptions = this.GetTrustAccountOptions.bind(this);
        this.GetDischargeTypes = this.GetDischargeTypes.bind(this);
        this.GetStates = this.GetStates.bind(this);
        //this.UpdateLenderReferenceCombined = this.UpdateLenderReferenceCombined.bind(this);
        //this.LoanIdUpdated = this.LoanIdUpdated.bind(this);
        //this.IndicativeAmountUpdated=this.IndicativeAmountUpdated.bind(this);
        //this.DownloadInstructionHtml=this.DownloadInstructionHtml.bind(this);
        //this.DownloadInstructionXml=this.DownloadInstructionXml.bind(this);
        //this.GetExternalUserPrivilege = this.GetExternalUserPrivilege.bind(this);
        this.GetLenderDetails = this.GetLenderDetails.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    } 

    componentDidMount(){
        document.title='Loantrak - Payout Upload';
        AuthService.isUserAuthorisedToInstruct().then((resp)=>{
            this.setState({isInstructPriv:true});
        },(err)=>{
            this.setState({
                isInstructPriv:false,
                shouldRedirect:true
            });
        });
        AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({
                CanInstructDischarges:resp.data.Content.CanInstructDischarges,
                CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
                CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
                CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
                shouldRedirect: !resp.data.Content.CanProvideDischargePayout,
            },()=>{
                console.log(this.state.CanProvideDischargePayout)
            });
        },(err)=>{
            console.log("There is an error with the request")
            //this.setState({isInstructPriv:false});
        })

        //this.setState({LoanIdEntityCount:this.state.LoanIdEntityCount+1},()=>{this.UpdateLoanIdSegment();});
        this.GetStates();
        //this.GetExternalUserPrivilege();
        this.GetLenderDetails();
        window.addEventListener("resize", this.updateWindowDimension)
    }

    GetLenderDetails(){
        //let userdetails = AuthService.getUserDetails();
        //let usertypeid =  userdetails != null?userdetails.map(d=>d.usertypeid) : '';
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2)
        {
            AuthService.GetLenderDetails().then((resp)=>{
                this.setState({
                    IsLenderThinkTank:resp.data.Content
                },()=>{console.log(this.state.IsLenderThinkTank)});
            },(err)=>{
                console.log("There is an error with the request")
                //this.setState({isInstructPriv:false});
            })
        }
        
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    GetTrustAccountOptions(){
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        //commented while no userdetails yet
        if(usertypeid == 2) //lender
        {
            InstructionService.GetPayoutTrusts().then((resp)=>{
                this.setState({PayoutTrustOptions:resp.data.Content},()=>{this.UpdatePayoutSegment()});
            },(err)=>{
                //alert(err);
                alert("There is an error with the request");
            });
        }
    }

    GetDischargeTypes(){
        this.setState({DischargeTypes:['Full','Partial']});
        // InstructionService.GetDischargeTypes().then((resp)=>{
        //     this.setState({DischargeTypes:resp.data.Content});
            
        // },
        // (err)=>{
        //     alert(err);
        // });
    }



    GetStates(){
        let respVal = [];
        
        InstructionService.GetInstructionStates().then((resp)=>{
            this.setState({States:resp.data.Content},()=>{
                let mapd=[];
                mapd.push(<option label=""></option>);
                for(let f=0;f<this.state.States.length;f++){
                    mapd.push(<option>{this.state.States[f]}</option>);
                };
                
                this.setState({MappedStates:mapd},()=>{
                    //this.UpdateIncomingMortgageeSegment();
                    //this.UpdateSecuritySegment();
                    //this.UpdatePartySegment();
                    //this.UpdatePartyRep();
                    this.GetDischargeTypes();
                    //this.UpdateImportNotesSegment();
                    this.GetTrustAccountOptions();
                    this.UpdatePayoutSegment();
                });
            });
        },
        (err)=>{
            this.setState({

                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
            });
            
        });

    }

    IncomingMortgageeNameUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeNames;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNames:val},()=>{this.UpdateIncomingMortgageeSegment()});
    }

    IncomingMortgageeEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeEmails;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeEmails:val},()=>{this.UpdateIncomingMortgageeSegment()});
    }
    IncomingMortgageeNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeNumbers;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNumbers:val},()=>{this.UpdateIncomingMortgageeSegment()});
    }

    IncomingMortgageeTypeUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeType;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeType:val},()=>{this.UpdateIncomingMortgageeSegment()});
    }

    UpdateIncomingMortgageeSegment(){
        let ents = this.state.IncomingMortgageeSegmentEntities;
    } 

    CheckMatterExists(e){
        e.preventDefault();
        
        InstructionService.GetExistingMatterDetails(this.state.MsaReference).then((resp)=>{
            
            
            
            this.setState({
                MatterExists:true,
                CheckedMatter:true,
                MatterDescription:resp.data.Content['MatterDescription'],
                MatterLenderReference:resp.data.Content['LenderRefNo'],
                MatterDateValid:resp.data.Content['DateValid']===undefined?null:(resp.data.Content['DateValid']),
                MatterAdditionalNotes:resp.data.Content['AdditionalNotes']===undefined?null:resp.data.Content['AdditionalNotes'],
                MatterLoanAccounts:resp.data.Content['LoanDetails']===undefined?null:resp.data.Content['LoanDetails'],
                PayoutStage:resp.data.Content['finalised']===undefined?null:resp.data.Content['finalised'],
                

        },()=>{
                
                let bsbs = this.state.PayoutLoanDetailsSegmentBsbs;
                // console.log("bsbs")
                // console.log(bsbs)
                // console.log(bsbs.length)
                        
                let payoutFigs = this.state.PayoutLoanDetailsSegmentPayoutFigures;
                let accNames = this.state.PayoutLoanDetailsSegmentAccountNames;
                let accNumbs = this.state.PayoutLoanDetailsSegmentAccountNumbers;
                //if(this.state.PayoutStage==='QA' | this.state.PayoutStage==='finalised'){
                if(this.state.PayoutStage==='QA' | this.state.PayoutStage==='finalised' && this.state.MatterLoanAccounts!= null){
                    this.state.MatterLoanAccounts.forEach((c,i)=>{
                        if(bsbs.length < i+1){
                            bsbs.push(this.state.MatterLoanAccounts[i]['trustAccountField']['bSBField']);
                            accNames.push(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNameField']);
                            accNumbs.push(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNumberField']);
                            payoutFigs.push(this.state.MatterLoanAccounts[i]['payoutFigureField']);        
                        }else{
                            bsbs[i]=this.state.MatterLoanAccounts[i]['trustAccountField']['bSBField'];
                            accNames[i]=(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNameField']);
                            accNumbs[i]=(this.state.MatterLoanAccounts[i]['trustAccountField']['accountNumberField']);
                            payoutFigs[i]=(this.state.MatterLoanAccounts[i]['payoutFigureField']);
                        }
                    });
                    
                }
                var loans = this.state.MatterLenderReference; 
                let splitLoans = loans.split(', ');
                let vals = this.state.PayoutLoanDetailsSegmentLoanIds; 
                splitLoans.forEach((c,i)=>{
                    if(vals.length < i+1){
                        vals.push(splitLoans[i]);
                    }else{
                        vals[i]=splitLoans[i];
                    }
                });
                
                this.setState({PayoutLoanDetailsDateValid:this.state.MatterDateValid,DateValidGood:this.state.MatterDateValid? true:false, PayoutLoanDetailsSegmentCount:splitLoans.length,PayoutLoanDetailsSegmentLoanIds:vals,PayoutLoanDetailsSegmentPayoutFigures:payoutFigs,PayoutLoanDetailsSegmentBsbs:bsbs,PayoutLoanDetailsSegmentAccountNumbers:accNumbs,PayoutLoanDetailsSegmentAccountNames:accNames},()=>{this.UpdatePayoutSegment()})
            });
        },(err)=>{
            if (err.response && err.response.status === 401) {
                this.setState({
                    shouldRedirect: true,
                    errorMessage: 'there is an error with the request'
                    //,MatterDescription:null,MatterLenderReference:'',PayoutLoanDetailsSegmentLoanIds:[],PayoutLoanDetailsSegmentEntities:[]
                },()=>{this.UpdatePayoutSegment()});

            } else {
                this.setState({MatterExists:false,CheckedMatter:true,MatterDescription:null,MatterLenderReference:'',PayoutLoanDetailsSegmentLoanIds:[],PayoutLoanDetailsSegmentEntities:[],PayoutLoanDetailsSegmentPayoutFigures:[],PayoutLoanDetailsDateValid:"",MatterDateValid:"",DateValidGood: false
                ,PayoutLoanDetailsSegmentAccountNamesOptions:[],PayoutLoanDetailsSegmentAccountNames:[],PayoutLoanDetailsSegmentBsbs:[],PayoutLoanDetailsSegmentAccountNumbers:[]
                },()=>{this.UpdatePayoutSegment()});
            }  
      
         
        })
    }

    CreateDischargePayoutEntity(e){
        e.preventDefault();
        let payout={};
        let hasError  = false; 
        let elem = document.getElementById('payout-form');
        if(!elem.checkValidity()){
            elem.reportValidity();
            return;
        }
        this.state.PayoutLoanDetailsSegmentLoanIds.forEach((p,i)=>{

            //alert(this.state.PayoutLoanDetailsSegmentAccountNames[i])
            if(this.state.PayoutLoanDetailsSegmentAccountNames[i]== "" || this.state.PayoutLoanDetailsSegmentAccountNames[i]=="Choose trust account")
            {
                hasError = true;
                alert("Please choose trust account")
                return;
            }

        })

        if(!hasError){
            payout['DateValid']=this.state.PayoutLoanDetailsDateValid;
            payout['LoanDetails']=[];
            this.state.PayoutLoanDetailsSegmentLoanIds.forEach((e,i)=>payout['LoanDetails'].push({'LoanId':e,'PayoutFigure':this.state.PayoutLoanDetailsSegmentPayoutFigures[i],'TrustAccount':{'AccountName':this.state.PayoutLoanDetailsSegmentAccountNames[i],'BSB':this.state.PayoutLoanDetailsSegmentBsbs[i],'AccountNumber':this.state.PayoutLoanDetailsSegmentAccountNumbers[i]}}))
            
            let payBatch = {};
            payout['AdditionalNotes']=[{'Note':this.state.PayoutSpecialConditions}];
            payBatch['Payout']=payout;
            this.setState({isLoading:true});
            InstructionService.SendPayoutData(this.state.MsaReference,payBatch).then((resp)=>{
                this.setState({msg:resp.data.Content,isLoading:false});
            },(err)=>{
                if (err.response && err.response.status === 401) {
                    this.setState({
                        isLoading:false,
                        shouldRedirect: true,
                        errorMessage: 'there is an error with the request'
                    });
    
                } else {
                    this.setState({isLoading:false});
                    //alert(err);
                    alert("There is an error with the request");
                }
           
            })
        }     

       
    }

    PayoutDateValidUpdated(e){
        e.preventDefault();
        this.setState({PayoutLoanDetailsDateValid:e.target.value},()=>{this.UpdatePayoutSegment()});
    }

    PayoutLoanIdUpdated(e,i){
        e.preventDefault();
        let vals = this.state.PayoutLoanDetailsSegmentLoanIds;
        vals[i]=e.target.value;
        this.setState({PayoutLoanDetailsSegmentLoanIds:vals},()=>{this.UpdatePayoutSegment()});
    }

    PayoutLoanFigureUpdate(e,i){
        e.preventDefault();
        let vals = this.state.PayoutLoanDetailsSegmentPayoutFigures;
        vals[i]=e.target.value;
        this.setState({PayoutLoanDetailsSegmentPayoutFigures:vals},()=>{this.UpdatePayoutSegment()});
    }

    PayoutAccountNameUpdated(e,i){
        e.preventDefault();
        let vals =this.state.PayoutLoanDetailsSegmentAccountNames;
        vals[i]=e.target.value;
        this.setState({PayoutLoanDetailsSegmentAccountNames:vals},()=>{this.UpdatePayoutSegment()});
    }

    PayoutAccountNumberUpdated(e,i){
        e.preventDefault();
        let vals = this.state.PayoutLoanDetailsSegmentAccountNumbers;
        vals[i]=e.target.value;
        this.setState({PayoutLoanDetailsSegmentAccountNumbers:vals},()=>{this.UpdatePayoutSegment()});
    }

    PayoutAccountBsbUpdated(e,i){
        e.preventDefault();
        let vals=this.state.PayoutLoanDetailsSegmentAccountNumbers;
        vals[i]=e.target.value;
        this.setState({PayoutLoanDetailsSegmentBsbs:vals},()=>{this.UpdatePayoutSegment()});
    }

    PayoutTrustUpdated(e,i){
        e.preventDefault();
        if(e.target.value===""){
            let vals = this.state.PayoutLoanDetailsSegmentAccountNamesOptions;
            vals[i]=e.target.value;
            let trustOptions = this.state.PayoutTrustOptions;
            let f = {};
            let bsbs = this.state.PayoutLoanDetailsSegmentBsbs;
            bsbs[i]='';
            let accNo = this.state.PayoutLoanDetailsSegmentAccountNumbers;
            accNo[i]='';
            let accNames = this.state.PayoutLoanDetailsSegmentAccountNames;
            accNames[i]='';
            this.setState({PayoutLoanDetailsSegmentAccountNamesOptions:vals,PayoutLoanDetailsSegmentAccountNames:accNames,PayoutLoanDetailsSegmentBsbs:bsbs,PayoutLoanDetailsSegmentAccountNumbers:accNo},()=>{this.UpdatePayoutSegment()});
        }else{
            let vals = this.state.PayoutLoanDetailsSegmentAccountNamesOptions;
            vals[i]=e.target.value;
            // alert(vals[i])
            let trustOptions = this.state.PayoutTrustOptions;

            // console.log("trustOptions")
            // console.log(trustOptions)
            let f = {};
            for(let i=0; i<trustOptions.length;i++){
                if(e.target.value.replace(trustOptions[i]['TrustAccRefNo'].trim()+' - ','')==trustOptions[i]['EFT_AccName'] ){
                    f=trustOptions[i];
                    break;
                }
            }
            let bsbs = this.state.PayoutLoanDetailsSegmentBsbs;
            bsbs[i]=f['EFT_BSB'];
            let accNo = this.state.PayoutLoanDetailsSegmentAccountNumbers;
            accNo[i]=f['EFT_AccNo'];
            let accNames = this.state.PayoutLoanDetailsSegmentAccountNames;
            accNames[i]=f['EFT_AccName'];
            this.setState({PayoutLoanDetailsSegmentAccountNamesOptions:vals,PayoutLoanDetailsSegmentAccountNames:accNames,PayoutLoanDetailsSegmentBsbs:bsbs,PayoutLoanDetailsSegmentAccountNumbers:accNo},()=>{this.UpdatePayoutSegment()});
        }
        
        
    }

    UpdatePayoutSegment(){
        let ents = this.state.PayoutLoanDetailsSegmentEntities;
        let bsbs = this.state.PayoutLoanDetailsSegmentBsbs;
        let loanIds = this.state.PayoutLoanDetailsSegmentLoanIds;
        let payoutFigs = this.state.PayoutLoanDetailsSegmentPayoutFigures;
        let accNames = this.state.PayoutLoanDetailsSegmentAccountNames;
        let accNumbs = this.state.PayoutLoanDetailsSegmentAccountNumbers;
        let accOptions = this.state.PayoutLoanDetailsSegmentAccountNamesOptions;
      
        if(ents.length>this.state.PayoutLoanDetailsSegmentCount){
            for(let i = ents.length;i>this.state.PayoutLoanDetailsSegmentCount;i--){
                loanIds.pop();
                payoutFigs.pop();
                accNames.pop();
                accNumbs.pop();
                bsbs.pop();
                ents.pop();
                accOptions.pop();
            }
        }else{
            for(let i=0;i<this.state.PayoutLoanDetailsSegmentCount;i++){
                if(loanIds.length<this.state.PayoutLoanDetailsSegmentCount){
                    loanIds.push(null);
                }
                if(payoutFigs.length < this.state.PayoutLoanDetailsSegmentCount){
                    payoutFigs.push(null);
                    accNames.push(null);
                    accNumbs.push(null);
                    bsbs.push(null);
                    accOptions.push(null);
                }
                if(accNames[i]===null){
                    accNames[i]='';//this.state.PayoutTrustOptions[0]['EFT_AccName'];
                    accNumbs[i]='';//this.state.PayoutTrustOptions[0]['EFT_AccNo'];
                    bsbs[i]='';//this.state.PayoutTrustOptions[0]['EFT_BSB'];
                    accOptions[i]='';

                }
               
            }
            this.setState({PayoutLoanDetailsSegmentBsbs:bsbs,PayoutLoanDetailsSegmentLoanIds:loanIds,PayoutLoanDetailsSegmentPayoutFigures:payoutFigs,PayoutLoanDetailsSegmentAccountNamesOptions:accOptions,PayoutLoanDetailsSegmentAccountNames:accNames,PayoutLoanDetailsSegmentAccountNumbers:accNumbs},()=>{
                for(let i=0;i<this.state.PayoutLoanDetailsSegmentCount;i++){
                    let names = [];
                    names.push(<option label="Choose trust account"></option>);
                    this.state.PayoutTrustOptions.forEach(x=>{
                        names.push(<option selected={this.state.PayoutLoanDetailsSegmentAccountNames[i]===x['EFT_AccName']?true:false}>{x['TrustAccRefNo'].trim()} - {x['EFT_AccName']}</option>);
                    });
                    
                    ents[i]=(
                    <div>
                         <h5 className="listContentTitle">Loan Details #{i+1}</h5>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Loan ID
                            </div>
                            <div>
                                <input type="text" id={'PayoutLoanId'+i} className="form-control" value={this.state.PayoutLoanDetailsSegmentLoanIds[i]} onChange={(e)=>{e.preventDefault();this.PayoutLoanIdUpdated(e,i)}} placeholder="Enter loan ID" required autoComplete={false}/>
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Payout Figure
                            </div>
                            <div>  
                                <input type="number" step="0.01" id={'PayoutFigure'+i} className="dollarTextBox" value={this.state.PayoutLoanDetailsSegmentPayoutFigures[i]} onChange={(e)=>{e.preventDefault();this.PayoutLoanFigureUpdate(e,i)}} placeholder="Amount" required autoComplete={false}/>
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Trust Account
                            </div>
                            <div>
                            <select className="form-control" id={"TrustAccountSelection"+i} value={this.state.PayoutLoanDetailsSegmentAccountNamesOptions.length>i?this.state.PayoutLoanDetailsSegmentAccountNamesOptions[i]:''} onChange={(e)=>{this.PayoutTrustUpdated(e,i)}} >
                                {names}
                            </select>
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="subFormBox">
                                <div className="inputBox"> 
                                    <div>
                                        {/* <input type="text" placeholder=" Account Name" /> */}
                                        <input type="text" id={'PayoutAccountName'+i} className="form-control" value={this.state.PayoutLoanDetailsSegmentAccountNames[i]} placeholder=" Account Name" autoComplete={false} readOnly/>
                                    </div>
                                </div>
                                <div> 
                                    <div className="multiColsInputBox">
                                        {/* <input type="text" placeholder="BSB" /> */}
                                        <input type="text" id={'PayoutBsb'+i} className="form-control" value={this.state.PayoutLoanDetailsSegmentBsbs[i]} placeholder="BSB" autoComplete={false}  readOnly/>
                                        {/* <input type="text" placeholder="Account Number" /> */}
                                        <input type="text" id={'PayoutAccountNumber'+i} className="form-control" value={this.state.PayoutLoanDetailsSegmentAccountNumbers[i]} placeholder="Account Number" autoComplete={false} readOnly/>
                                    </div>
                                </div> 
                            </div>
                        </div> 
    
                    </div>);
                }
                this.setState({PayoutLoanDetailsSegmentEntities:ents});
            });
            return;
        }
        this.setState({PayoutLoanDetailsSegmentEntities:ents,PayoutLoanDetailsSegmentBsbs:bsbs,PayoutLoanDetailsSegmentLoanIds:loanIds,PayoutLoanDetailsSegmentPayoutFigures:payoutFigs,PayoutLoanDetailsSegmentAccountNames:accNames,PayoutLoanDetailsSegmentAccountNumbers:accNumbs});
    }

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }




    render() {
        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        
        if(this.state.leftContent != this.state.pageTitle){
            // alert(this.state.lmwInstructMSASub)
             return( 
                 <DisplayLeftMenu 
                     page={this.state.leftContent} 
                     desktopMenu={this.state.desktopMenu} 
                     lmwInstructMSASub={this.state.lmwInstructMSASub}
                 />
             )
        } 
        
        let payoutRenderable=this.state.PayoutLoanDetailsSegmentEntities.length?this.state.PayoutLoanDetailsSegmentEntities.reduce((p,c)=>[p,'',c]):'';

        return ( 
             
            <LeftMenuContent 
                id="PayoutUploadPage"
                page="payoutupload" 
                desktopMenu={this.state.desktopMenu} 
                callBack = {this.handleLeftMenuCallback} 
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
                    
                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />

                    <div className="content"> 
                    
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>

                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 

                        </div> */}


                        
                        <div className="FormsBox">
                        {(this.state.msg!=='' && !this.state.InstructionSubmit)?(
                            <div className="spacingBottomLarge">
                                 <TitleSegment>Payout Upload</TitleSegment> 
                                    
                                 <Card>
                                    <div className="instruction page">               
                                        <div className="instruction container">
                                            <div className="instruction inner">
                                                <p>{this.state.PayoutStage==='QA'?(<span>This request has been uploaded and locked for MSA. Our reference number for this loan is <b>{this.state.msg}</b>.</span>):'Payout has now been saved. Please lock the payout to submit to MSA.'}</p>                                                
                                            </div>                                            
                                        </div>
                                    </div>
                                 </Card>
                                 <div>
                                     <br />
                                    <a type="link" className="btn btn-secondary" href="/payoutupload">  
                                        <ButtonIcon
                                            id=""
                                            addCSS="ButtonIconSelected biRight mainBtn"
                                            >
                                        Close</ButtonIcon>    
                                    </a>
                                </div>
                            </div>

                        ):(
                            <form id="payout-form">  
                                <div className="spacingBottomLarge">
                                    <TitleSegment>Payout Upload</TitleSegment> 
                                    
                                    <Card>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                MSA Reference
                                            </div>
                                            <div>
                                                <input type="text" id={'MatterId'} className={this.state.CheckedMatter && this.state.MatterDescription===null?"errorTextBox":this.state.CheckedMatter?"successTextBox":""} value={this.state.MsaReference} onChange={(e)=>{e.preventDefault();this.setState({MsaReference:e.target.value,MatterDescription:null,MatterLenderReference:null})}} onBlur={(e)=>{e.preventDefault();if(this.state.MsaReference!==null){this.setState({MatterExists:false,CheckedMatter:false,MatterDescription:null,MatterLenderReference:null},()=>{this.CheckMatterExists(e)})}}} placeholder="e.g. 3173116" required />
                                            </div>
                                            {/* <ValidationText>{this.state.CheckedMatter && this.state.MatterDescription===null?"This matter does not exist. Please check the details you have provided.":this.state.CheckedMatter?"This is a valid MSA Reference number. Please verify this is the correct MSA Reference with the details below.":""}</ValidationText> */}
                                            <DisplayMessage message={this.state.CheckedMatter && this.state.MatterDescription===null?"This matter does not exist. Please check the details you have provided.":this.state.CheckedMatter?"This is a valid MSA Reference number. Please verify this is the correct MSA Reference with the details below.":""}/>
                                            {/* <div className="valid-feedback">
                                        This is a valid MSA Reference number. Please verify this is the correct MSA Reference with the details below.
                                    </div>
                                    <div className="invalid-feedback">
                                        This matter does not exist. Please check the details you have provided.
                                    </div> */}
                                        </div> 
                                        <div className="inputBox">
                                            <div className="subFormBox">
                                                <div className="inputBox">
                                                    <div className="inputLabel">
                                                        Matter Description
                                                    </div>
                                                    <div>
                                                        <input type="text" id={'MatterDescription'} className={this.state.CheckedMatter && this.state.MatterDescription===null?"errorTextBox":this.state.CheckedMatter?"successTextBox":""} value={this.state.MatterDescription===null?'':this.state.MatterDescription} placeholder="" readOnly />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="inputLabel">
                                                        Lender Reference
                                                    </div>
                                                    <div>
                                                        <input type="text" id={'LenderReference'} className={this.state.CheckedMatter && this.state.MatterDescription===null?"errorTextBox":this.state.CheckedMatter?"successTextBox":""} value={this.state.MatterLenderReference} placeholder="" readOnly/>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                        <div>
                                            <div className="inputLabel">
                                                Date valid
                                            </div>
                                            <div className="dateBox">
                                                {/* <FormTextBox 
                                                    type="text" className="dateTextBoxRight"
                                                    placeholder="DD/MM/YY"
                                                /> */}
                                                {/* <input type="date" id={'PayoutDateValid'} className={"form-control"+(this.state.DateValidGood? this.state.PayoutLoanDetailsDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.PayoutLoanDetailsDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DateValidGood:true}):this.setState({DateValidGood:false})}}} value={this.state.MatterDateValid===undefined?this.state.PayoutLoanDetailsDateValid:this.state.MatterDateValid} onChange={(e)=>{e.preventDefault();this.PayoutDateValidUpdated(e)}} placeholder="DD/MM/YY" required/> */}
                                                <input type="date" id={'PayoutDateValid'} className={"form-control"+(this.state.DateValidGood? this.state.PayoutLoanDetailsDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.PayoutLoanDetailsDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DateValidGood:true}):this.setState({DateValidGood:false})}}} value={this.state.PayoutLoanDetailsDateValid} onChange={(e)=>{e.preventDefault();this.PayoutDateValidUpdated(e)}} placeholder="DD/MM/YY" required/>
                                            </div>
                                        </div>   
                                    </Card>

                                </div>
    


                                <div className="spacingBottomLarge">
                                    
                                    <h5>Loan Account (Optional)</h5> 
                                    <Card>
                                        {payoutRenderable}                                        
                                        <div className="multiColsInputBox"> 
                                            <div>
                                                <ButtonIcon
                                                    id="btnCFPost"
                                                    addCSS="ButtonIconForm"
                                                    iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                    onClick={(e)=>{e.preventDefault();this.setState({PayoutLoanDetailsSegmentCount:this.state.PayoutLoanDetailsSegmentCount+1},()=>{this.UpdatePayoutSegment()})}}
                                                >Add loan ID</ButtonIcon>
                                            </div>
                                            <div className="tar">
                                                <ButtonIcon
                                                    id="btnCFPost"
                                                    addCSS="ButtonIconForm"
                                                    iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                    display={this.state.PayoutLoanDetailsSegmentCount===0?'none':'inline-block'}
                                                    onClick={(e)=>{e.preventDefault();if(this.state.PayoutLoanDetailsSegmentCount>=0){this.setState({PayoutLoanDetailsSegmentCount:this.state.PayoutLoanDetailsSegmentCount-1},()=>{this.UpdatePayoutSegment()})}}}
                                            >Remove Payout</ButtonIcon> </div>                                      
                                        </div> 
                                    </Card>
                                </div> 


                                <div className="spacingBottomLarge">
                                    <h5>Others</h5> 
                                    <Card>    
                                        <div>
                                            <div className="inputLabel">
                                                Other requests or comments
                                            </div>
                                            <div>
                                                <textarea id={'PayoutSpecialConditionComment'} row="7" className="form-control" value={this.state.PayoutSpecialConditions} onChange={(e)=>{e.preventDefault();this.setState({PayoutSpecialConditions:e.target.value})}} placeholder="Write message"/>
                                            </div>
                                        </div>

                                        {/* <div className="form-check" style={{padding:'20px 0px'}}>
                                        <input className="form-check-input" type="checkbox" name="payoutHasSpecial" id="PayoutHasSpecialConditions" checked={this.state.PayoutHasSpecialConditions} value={this.state.PayoutHasSpecialConditions} onChange={(e)=>{this.setState({PayoutHasSpecialConditions:!this.state.PayoutHasSpecialConditions});}}/>
                                        <label className="form-check-label" for="PayoutHasSpecialConditions">
                                            Special Conditions
                                        </label>
                                    </div>
                                    {this.state.PayoutHasSpecialConditions?(<div className="form-group">
                                        <label for={"PayoutSpecialConditionComment"}>Condition</label>
                                        <textarea id={'PayoutSpecialConditionComment'} className="form-control" value={this.state.PayoutSpecialConditions} onChange={(e)=>{e.preventDefault();this.setState({PayoutSpecialConditions:e.target.value})}} placeholder=""/>
                                    </div>):''} */}
                                    </Card>
                                </div>

                                

                                <div>
                                    <a type="link" className="btn btn-secondary" href="/payoutupload">  
                                        <ButtonIcon
                                            id="puClose"
                                            addCSS="biRight mainBtn secondaryBTN"
                                            >
                                        Close</ButtonIcon>    
                                    </a>  
                                    {this.state.PayoutStage==='finalised'?<span><b>This Loans payout has been locked.</b></span>:
                                    this.state.isLoading?<ProgressDisplay/>
                                    :
                                    (<ButtonIcon
                                        id=""
                                        addCSS="ButtonIconSelected biRight mainBtn"
                                        iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                        onClick={(e)=>{e.preventDefault();this.CreateDischargePayoutEntity(e);}}
                                    >{this.state.PayoutStage==='QA'?'Lock Payout':'Submit Payout'}</ButtonIcon>)}
                                </div>

                            </form>
                        )
                        }
                        </div> 
                        
                        
                         

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
PayoutUploadPage=AutoLogout(PayoutUploadPage);
export default PayoutUploadPage
