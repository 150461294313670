import WebApiService                                        from './web-api-service';
import MatterDocumentService                                        from './matter-document-service';
import { AuthService }                                      from './auth-service';
import { SSL_OP_NO_SESSION_RESUMPTION_ON_RENEGOTIATION } from 'constants';


 
class custodyService extends WebApiService{

    getCustodyMatterSearch(matterNumber, lenderReference, borrowersName, securityAddress, titleReference, solicitor, putawayNumber){
        return new Promise((resolve,reject)=>{
            const url = "api/custodyMatterSearch";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterNumber,
                    // 'LenderReference':lenderReference,
                    // 'BorrowersName':borrowersName,
                    // 'SecurityAddress':securityAddress,
                    // 'TitleReference':titleReference,
                    // 'Solicitor':solicitor,
                    // 'PutawayNumber':putawayNumber,        
                }
            };


            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    getCustodyMatterAdvancedSearch(matterNumber, borrowers, lenderRefNo, address, matterState, matterType, matterStatus, mortManager, fileOwner, titleRefNo, lender){
        //alert(borrowers)
        return new Promise((resolve,reject)=>{
            const url = "api/custodyMatterAdvancedSearch";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterNumber,
                    'BorrowersName':borrowers,
                    'LenderReference':lenderRefNo,                    
                    'SecurityAddress':address,
                    'StateId':matterState,
                    'MatterTypeId':matterType,
                    'MatterStatusTypeId':matterStatus,
                    'MortMgrId':mortManager,
                    'FileOwnerId':fileOwner,
                    'TitleRefNo':titleRefNo,
                    'LenderId':lender,
                    // 'Solicitor':solicitor,
                    // 'PutawayNumber':putawayNumber,        
                }
            };


            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    getMatterDocuments(matterNumber){
        return new Promise((resolve,reject)=>{
            const url ="api/getDocumentsForMatter"; 
        })
    }



    getCustodyLocationForMatter(matterId){
        const url="api/GetCustodyLocation";
        const options={
            'headers':{
                'Authorization':AuthService.token,
                'MatterId':matterId,
            }
        };
        return new Promise((resolve,reject)=>{
            super.get(url,options).then((response)=>{
            
                resolve(response);
            },(err)=>{
                reject(err);
            })
        });
    }

    registerCustodyRequest(matterId, requestLocation, isUrgent){
       
        return new Promise((resolve,reject)=>{
            const url="api/RegisterCustodyRequest";
            let data = new FormData();
           // data.append('Authorization',AuthService.token);
            data.append('MatterId',matterId);
            data.append('RequestedDeliveryLocation',requestLocation);
            data.append('IsUrgent',isUrgent);
    
            super.post(url,data).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            })
        });
    }    
    getActiveDocumentRequests(custodyStatusType){
        const url = "api/GetActiveCustodyRequests";
        const options ={
            'headers':{
                'Authorization': AuthService.token,
                'CustodyStatusType':custodyStatusType,
            }
        };
        return new Promise(
            (resolve,reject)=>{
                super.get(url,options).then((response)=>{
                    resolve(response);
                },(err)=>{
                    reject(err);
                })
            }
        );
    }
    getDocumentDeliveryLocations(){
        const url = "api/getCustodyDeliveryLocations";
        const options={
            'headers':{
                'Authorization':AuthService.token,
                // 'Logged':'logged',
            }
        };
        return new Promise(
          
            (resolve,reject)=>{
                super.get(url,options).then((response)=>{
                    resolve(response);
                },(err)=>{
                    reject(err);
                })
            }
        );
    }

    getCurrentDocumentLocation(matterId){
        const url = "api/getCurrentDocumentLocation";
        const options={
            'headers':{
                'Authorization':AuthService.token,
                'matterId': matterId
            }
        };
        return new Promise(
          
            (resolve,reject)=>{
                super.get(url,options).then((response)=>{
                    resolve(response);
                },(err)=>{
                    reject(err);
                })
            }
        );
    }

    cancelCustodyRequest(matterId){
       
        return new Promise((resolve,reject)=>{
            const url="api/CancelCustodyDocumentRequest";
            let data = new FormData();
           // data.append('Authorization',AuthService.token);
            data.append('MatterId',matterId);
            //data.append('RequestedDeliveryLocation',requestLocation);            
    
            super.post(url,data).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            })
        });
    }  
    
    downloadCustodyCentreDocumentsAsZip(matterId) {
        return new Promise((resolve, reject) => {
          const url = "api/DownloadCustodyCentreDocuments";
          const options = {
            responseType: "blob",
            headers: {
              Authorization: AuthService.token,
              matterId: matterId
            }
          };
    
          super.get(url, options).then(
            response => {
              resolve(response);
            },
            err => {
              reject(err);
            }
          );
        });
      }


}

export const CustodyService = new custodyService();

