import React, { Component } from 'react' 

import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';

import './css/BackBar.css';

class BackBar extends Component {
    render() {
        return (
            <div className="BackBar"  onClick={this.props.onClick}>
                <ButtonIcon 
                    addCSS="btnBack"
                    iconURL="../../../../images/icons/OutlineGrey/Back.svg">
                    {this.props.children}
                </ButtonIcon>
            </div>
        )
    }
}

export default BackBar
