import React, { Component } from 'react' 

import Button from '../../atom/Button/Button';
import DataListResult from '../../atom/DataListResult/DataListResult';

import './css/ButtonLoadMore.css';

class ButtonLoadMore extends Component {
    render() {
        return (
            <div className={this.props.addCSS} onClick={this.props.onClickFunction}>
                <Button addCSS="ButtonLoadMore secondaryBTN">
                    {/* Load more... */}
                    {this.props.btnLabel}
                </Button>

                <DataListResult addCSS="blmResult">
                    {this.props.resultDisplay}
                </DataListResult>
            </div>
        )
    }
}

export default ButtonLoadMore
