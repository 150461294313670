import React from 'react';
import CreateAccountForm from '../../organism/CreateAccountForm/CreateAccountForm';
//import LoginForm from '../loginpa/loginform';
//import LoginPage                           from '../LoginPage/LoginPage';	
import CenterContent from '../../template/CenterContent/CenterContent'; 
import Card from '../../atom/Card/Card';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';

class CreateAccount extends  React.Component{

    constructor(props){
        super(props);
        this.state = {
            email: '',
            goBack: false,
            successMessage: null,
        }        
        this.handleCreateAccountCallback = this.handleCreateAccountCallback.bind(this);
    }

   
    handleCreateAccountCallback(child){
        this.setState({
            goBack: child[0],
            successMessage: child[1]
        },()=>{
            this.props.parentCallback([this.state.goBack,this.state.successMessage]);
        });

    }

    render(){
        // if(this.state.goBack){
        //     return(<LoginPage createAccountDetails={[this.state.goBack, this.state.successMessage]}/>);
        // }
        return(            
             <CenterContent>

             <MarginGuide />
 
             <div className="content">
                <Card addCSS="cardReset">
                    <CreateAccountForm createAccountCallback={this.handleCreateAccountCallback}/>                     
                </Card> 
             </div>
         </CenterContent>  
        );
    }

}

export default CreateAccount;