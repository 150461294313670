import React, { Component } from 'react'

 
import HighlightText from '../../atom/HighlightText/HighlightText';

import './css/ButtonGroupCap.css';
import { tsNullKeyword } from '@babel/types';

class ButtonGroupCap extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            highlighted: this.props.highlighted,
            activeHighlight: 'HighlightGrey bgcGreen',
            inactiveHighlight: 'HighlightGrey'
        }
        this.changeHighlight = this.changeHighlight.bind(this);
    }

    componentDidMount(){
        //alert(this.props.highlighted);
        // if(this.state.highlighted == 'active'){
        //     this.setState({
        //         activeHighlight: 'HighlightGrey bgcGreen',
        //         inactiveHighlight: 'HighlightGrey'
        //     });
        // }
        // else{
        //     this.setState({
        //         activeHighlight: 'HighlightGrey',
        //         inactiveHighlight: 'HighlightGrey bgcGreen'
        //     });
        // }
    }
    changeHighlight(e){
        //alert(e.target.id);
        this.props.callback(e.target.id);
        if(e.target.id == "email"){

            this.setState({
                activeHighlight: 'HighlightGrey bgcGreen',
                inactiveHighlight: 'HighlightGrey',
                
            });
        }
        else{
            this.setState({
                activeHighlight: 'HighlightGrey',
                inactiveHighlight: 'HighlightGrey bgcGreen',
                
            });
        }       
            
    }
    render() {
        return ( 
            <div className="ButtonGroupCap">
                <div className="bgcContent">
                    <HighlightText type={this.props.highlighted=='email'?'HighlightGrey bgcGreen':'HighlightGrey'} onClick={this.changeHighlight} id = "email">{this.props.firstOption}</HighlightText>
                    <HighlightText type={this.props.highlighted=='email'?'HighlightGrey':'HighlightGrey bgcGreen'}  onClick={this.changeHighlight} id = "sms">{this.props.secondOption}</HighlightText>
                </div>
            </div>
        )
    }
}

export default ButtonGroupCap
