import React, { Component } from 'react'

import './css/FormTextBox.css';

class FormTextBox extends Component {
    render() {
        return (
            <div className="inputBox">
                {/* <input type="text" className="emailTextBox" placeholder="Your Email" /> */}
                <input 
                    type={this.props.type} 
                    className={this.props.className} 
                    placeholder={this.props.placeholder} 
                    value={this.props.value} 
                    onChange={this.props.onChange} 
                    disabled={this.props.disabled}
                    defaultValue={this.props.defaultValue}
                    >
                </input>
            </div>
        )
    }
}

export default FormTextBox
