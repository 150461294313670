import React, { Component } from 'react'

import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';

import './css/MessageBox.css';

class MessageBox extends Component {
    render() {
        return (
            <>
            <ButtonIcon
                addCSS={"MessageBox " + this.props.addCSS }
                iconURL={this.props.iconURL}>
                    {this.props.children}
                </ButtonIcon>
            </>
        )
    }
}

export default MessageBox
