import React, { Component } from 'react'
 
import HighlightText from '../../atom/HighlightText/HighlightText'; 

 
import './css/DataTableSearch.css';
import { MatterService } from '../../../services/matter-service';

class DataTableSearch extends Component {
    constructor(props){
        super(props);

        this.state={
            //advancedSearch: DataTableSearch.createAdvancedSearchConfig(),
            //matters: this.props.matters
            matter: this.props.matter,
            columns: this.props.columns,
            visibleColumns: [],
            dtDataSizing: "80px 1fr 80px 1fr 1fr 1fr 1fr 1fr 1fr"
        }
 
        //this.getadvancedSearch = this.getadvancedSearch.bind(this);
        //this.rowClick = this.rowClick.bind(this);

    }
    componentDidMount(){
        // let as = this.getadvancedSearch();
        // this.runDetailSearch(as);
        console.log("matters");
        console.log(this.props.matter);
        console.log("columns");
        console.log(this.props.columns)
        let visibleCols = [];

        this.props.columns.forEach(o => {
            if(o.Visible == true){
                //newArr = [...unallocatedOutstanding];
                visibleCols.push(o.Field);
            }

           
        })

        this.setState({
            visibleColumns: visibleCols,
        },()=>{
            console.log("visible columns")
            console.log(this.state.visibleColumns)
        })
        
    }   
    

    render() {
        let searchResult= '';


        let columns = '' 
        var newDataSizing="";
        var NumColumn=0;
        columns =(this.props.columns.map((c) => {  
            if (c.Visible) {

                NumColumn+=1;

                // if(c.Field=="matterNo"){
                //     var matterNoHeader=" matterNoHeader";
                // }else{
                //     var matterNoHeader="";
                // }

                // if(c.Field=="stateName"){
                //     var branchHeader=" branchHeader";
                // }else{
                //     var branchHeader="";
                // }

                if(c.Field=="matterNo"){
                    newDataSizing=newDataSizing.concat("80px ");
                }else if(c.Field=="stateName"){
                    newDataSizing=newDataSizing.concat("80px "); 
                }else if(c.Field=="matterTypeName"){
                    newDataSizing=newDataSizing.concat("2fr "); 
                }else{
                    newDataSizing=newDataSizing.concat("1fr ");
                }  

                // return(<div id={c.DetailSearchColumnId} name={c.Field} width={c.Width + "px"}>{c.Title}</div>)
                return(<div className={(c.Title=="Current Status"?"dtHeaderItem currentStatusHeader":"dtHeaderItem")} id={c.DetailSearchColumnId} name={c.Field} key={c.DetailSearchColumnId} >{c.Title}</div>)
            }
        
        }));
        //console.log(newDataSizing);
        //console.log(window.innerWidth);

        if(window.innerWidth<=768){
            newDataSizing="unset"
        }

        var ColWidth="100%" ;
        var ColOverflow="hidden";
        switch(NumColumn) {
            case 10:
                ColWidth="130%"; 
                ColOverflow="scroll";
                break;
            case 11:
                ColWidth="140%";
                ColOverflow="scroll";
                break;
            case 12:
                ColWidth="150%"; 
                ColOverflow="scroll";
                break;
            case 13:
                ColWidth="160%"; 
                ColOverflow="scroll";
                break;
            case 14:
                ColWidth="170%";
                ColOverflow="scroll";
                break;
            default:
                ColWidth="100%"; 
                ColOverflow="hidden";
        }

        if(window.innerWidth<=768){
            ColWidth=""; 
            ColOverflow="";
        }

        
        let grid='';
        if(this.props.matter != null){
            grid=(
                <>            
                    {
                        this.props.matter.map((matter) => {  
                            var matterlen = matter.matterNo.toString().length;        
                            return (
                                //need to adjust this when there is a new column
                                //<div className="dtRow" id={matter.MatterId} onClick={(e)=>this.rowClick(e)} >
                                     
 

                                    <div 
                                        className="dtRow" 
                                        id={matter.MatterId}
                                        onClick={(e)=>this.props.onRowClick(e, matter.matterNo)} 
                                        style={{"width": ColWidth, "overflow":ColOverflow}}
                                    >

                                    <div className="dataItemBox" style={{'grid-template-columns':newDataSizing==""?this.state.dtDataSizing:newDataSizing}}>
                                        {this.state.visibleColumns.includes('matterNo')?
                                            (<div className="dataItem dtRowSmallBold matterNo">
                                                <div className="dtHeaderMobile">MSA Ref</div>
                                                {('0000000' + matter.matterNo).slice(matterlen)}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('fileOwner')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">MSA File Owner</div>
                                                {matter.fileOwner}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('stateName')?
                                            (<div className="dataItem branch">
                                                <div className="dtHeaderMobile">Branch</div>
                                                {matter.stateName}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('matterDescription')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Borrowers</div>
                                                {matter.matterDescription}
                                            </div>   )
                                            : ''
                                        }
                                        {/* {this.state.visibleColumns.includes('matterStatus')?
                                            // (<div className="dataItem currentStatus">
                                            //     <div className="dtHeaderMobile">Current Status</div>
                                            //     <HighlightText type="HighlightBlue">Documents Sent</HighlightText>
                                            // </div>)
                                            // ( <HighlightText type={matter.matterStatusCategory == 1? "HighlightGreen" : (matter.matterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.matterCurrentStatus}</HighlightText>)
                                            // : ''
                                            (<div className="dataItem currentStatus">
                                                <div className="dtHeaderMobile">Current Status</div>
                                                <HighlightText type={matter.matterStatusCategory == 1? "HighlightGreen" : (matter.matterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.matterCurrentStatus}</HighlightText>
                                             </div>)
                                             : ''
                                        }                                  */}
                                        {this.state.visibleColumns.includes('securities')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Securities</div>
                                                {matter.securities}
                                            </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('titleRefs')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Title Ref</div>
                                                {matter.titleRefs}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('loanAmount')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Loan Amount</div>
                                                {matter.loanAmount}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('lenderRefNo')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Lender Ref</div>
                                                {matter.lenderRefNo}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('relationshipManager')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Relationship Manager</div>
                                                {matter.relationshipManager}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('matterTypeName')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Type</div>
                                                {matter.matterTypeName}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('matterStatus')?
                                            // (<div className="dataItem currentStatus">
                                            //     <div className="dtHeaderMobile">Current Status</div>
                                            //     <HighlightText type="HighlightBlue">Documents Sent</HighlightText>
                                            // </div>)
                                            // ( <HighlightText type={matter.matterStatusCategory == 1? "HighlightGreen" : (matter.matterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.matterCurrentStatus}</HighlightText>)
                                            // : ''
                                            (<div className="dataItem currentStatus">
                                                <div className="dtHeaderMobile">Current Status</div>
                                                <HighlightText type={matter.matterStatusCategory == 1? "HighlightGreen" : (matter.matterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.matterCurrentStatus}</HighlightText>
                                             </div>)
                                             : ''
                                        }      
                                        {this.state.visibleColumns.includes('settlementDate')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Settlement Date</div>
                                                {matter.settlementDate}
                                                </div>)
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('instructionDate')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Instruction Date</div>
                                                {matter.instructionDate}
                                            </div> )
                                            : ''
                                        }
                                        {this.state.visibleColumns.includes('fundingChannel')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Funding Channel</div>
                                                {matter.fundingChannel}
                                            </div>)
                                            : ''
                                        } 
                                        {this.state.visibleColumns.includes('payoutAmount')?
                                            (<div className="dataItem">
                                                <div className="dtHeaderMobile">Payout Amount</div>
                                                {matter.payoutAmount}
                                            </div>)
                                            : ''
                                        }                 
                                    </div>
                                </div>
                            )
                        })
                    }                
                </>       
            )
        }
        
       

        if(this.state.matter != null && this.state.matter.length > 0 ){
            searchResult=(
            <> 
                <div className="dtHeader" style={{"width": ColWidth, "overflow":ColOverflow}}>
                    <div className="dtHeaderItemBox" style={{'grid-template-columns': newDataSizing==""?this.state.dtDataSizing:newDataSizing}}>
                        {columns}
                    </div>
                </div>

                {grid}
            </>);
        }
        else{
            searchResult=(
                <>
                    <div className="dtHeader">
                        <div className="dtHeaderItem" style={{'grid-template-columns': newDataSizing==""?this.state.dtDataSizing:newDataSizing}}>
                            <div className="matterNoHeader">MSA Ref</div>
                            <div>Borrowers</div>
                            <div>Lender Ref</div>
                            <div>Securities</div>
                            <div className="currentStatusHeader">Current Status</div>
                            <div>Settlement Date/Time</div>
                        </div>
                    </div>
                </>);
            // searchResult=(                
            //         <>No matching records found :)</>);
            //searchResult = (<div>No matching records found :)</div>);
        }
        
        return ( 
            <div className="DataTable">   
                           
                    
               {searchResult}                
            
            </div>
        )
    }
}

export default DataTableSearch
