import React, { Component } from 'react'
 
import Line from '../../atom/Line/Line'; 
import TextCapsule from '../../atom/TextCapsule/TextCapsule';
 
import './css/CommunicationContent.css';

class CommunicationContent extends Component {
    render() {
        return ( 
            <div className="CommunicationContent">
                <div className="CommunicationHeader">
                    <div className="chDate">{this.props.date}</div>
                    <div className="chCompany">
                        <TextCapsule>{this.props.company}</TextCapsule>
                    </div>
                </div>
                <h6>{this.props.title}</h6>
                <Line />
                <div className="CommunicationDetails">
                    {this.props.children}  
                </div>
            </div>
        )
    }
}

export default CommunicationContent
