import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import CenterContent from '../../template/CenterContent/CenterContent';
import MatterEscalateForm from '../../organism/MatterEscalateForm/MatterEscalateForm';
import Card from '../../atom/Card/Card';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage';
import DisclaimerPage from '../DisclaimerPage/DisclaimerPage';
import CreateAccountPage from '../CreateAccountPage/CreateAccountPage';
import { AuthService } from '../../../services/auth-service';

import './css/MatterEscalate.css';

import MSALogo from '../../../images/logo/MSANational.png';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import Logo from '../../atom/Logo/Logo';
import ValidationText from '../../atom/ValidationText/ValidationText';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import Button from '../../atom/Button/Button';
import { MatterEscalateService } from '../../../services/matter-escalate-service';

class MatterEscalate extends Component {
    constructor(props) {
        super(props);
        this.state={
            matterId: this.props.match.params.token.substring(1,this.props.match.params.token.length),
            matterDescription:'',
            lenderReferenceNumber:'',
            possibleEscalationReasons:[],
            selectedReason:null,
            comments:"",
            loading:true,
            shouldRedirect:false,
            response:null,
            errorResponse:null,
            isInError:false,
            isSuccessful:false,
            value:'Select Escalation Reason',
            dropDownOptions:(<div></div>),
            errorMessage: null,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
        };
        this.RequestEscalateMatter=this.RequestEscalateMatter.bind(this);
        this._onDatalistChange = this._onDatalistChange.bind(this);
        this._onCommentChange=this._onCommentChange.bind(this);
    }

    _onDatalistChange(e){
        this.setState({selectedReason:e});
    }
    _onCommentChange(e){
        this.setState({comments:e.target.value});
    }

    RequestEscalateMatter(e){
        e.preventDefault();
        this.setState({isSuccessful:false,isInError:false});
        MatterEscalateService.PostMatterEscalationRequest(this.state.matterId,this.state.selectedReason,this.state.comments).then((resp)=>
        {
            this.setState({isSuccessful:true,response:resp},()=>{
               
            });
        },(err)=>
        {
            this.setState({
                isInError:true,errorResponse:"There is an error with the request"//err
            });
        });
    }

    CloseWindow(e){
        window.close();
    }

    componentDidMount(){
        this.setState({loading:true});
        document.title = "Loantrak - Escalate:" + this.state.matterId;
        MatterEscalateService.GetMatterEscalationDetails(this.state.matterId).then(
            (resp)=>{
                this.setState({lenderReferenceNumber:resp.data.Content.LenderReferenceNumber,matterDescription:resp.data.Content.BorrowerDescription});
            },
            (err)=>{
                this.setState({shouldRedirect:true,
                    errorMessage: 'there is an error with the request',
                    loading:false},()=>{});
            }
        );
        MatterEscalateService.GetMatterEscalationReasons().then(
            resp=>{
                this.setState({possibleEscalationReasons:resp.data.Content,loading:false},()=>{
                    let options = this.state.possibleEscalationReasons.map(o=>(<option key={o.EscalationReasonTypeId} value={o.EscalationReasonTypeDescription}>{o.EscalationReasonTypeDescription}</option>))
                    //let [selectedOption, setSelectedOption] = useState(this.state.possibleEscalationReasons[0].EscalationReasonTypeDescription);
                    console.log(options)
                    this.setState({dropDownOptions:(
                        <select
                        className="user-input"
                        value={options[0].value}
                        //value={options[0].props.value}
                        onChange={e => this._onDatalistChange(e.target.value)}>
                        {options}
                    </select>),
                    selectedReason:options[0].props.value} )
                });
            },
            err=>{
                this.setState({shouldRedirect:true,
                    errorMessage: 'there is an error with the request',
                    loading:false},()=>{});
            }
        );
    }



    render() {
        if (this.state.shouldRedirect) {
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
              }
              else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
              }
              //return <Redirect to="/" />;
          } else {
            if (this.state.loading) {
              return <ProgressDisplay />;
            }
            else{
                let errorMessage="";
                let successMessage="";
                let MyDropDown = (<div></div>);
                //let escalateButton = (<input type="submit" onClick={(e)=>this.RequestEscalateMatter(e)}/>);
                let escalateButton = (<Button clickFunction={(e)=>this.RequestEscalateMatter(e)}>Submit</Button>);
                
                if(this.state.isSuccessful){
                    //successMessage=(<div>Matter Escalation Request Received. Please note an Escalation Request can only be made once.</div>);
                    successMessage=(<DisplayMessage message="Matter Escalation Request Received. Please note an Escalation Request can only be made once." /> );
                    escalateButton=(<div><Button clickFunction={(e)=>this.CloseWindow(e)}>Close</Button></div>);
                }

                if(this.state.isInError){
                    //errorMessage=(<div>{this.state.errorResponse.message}</div>)
                    errorMessage=(<DisplayMessage message={this.state.errorResponse.message} />)
                }
            
                return (
                    <CenterContent id="MatterEscalate">
                        <MarginGuide />
                        <div className="content">
                            <Card>
                                {/* <MatterEscalateForm MatterEscalateFormCallback={this.handleMatterEscalateFormCallback} /> */}
                                <Logo />
                                <h5>Matter Escalate</h5>
                                <p>
                                    Loan Escalation Request for {this.state.matterDescription}<br />
                                    MSA Reference: {this.state.matterId}<br />
                                    Lender Reference: {this.state.lenderReferenceNumber}
                                </p>
                                
                                <ValidationText>{this.state.errorMessage}</ValidationText>
                                
                                <div className="inputBox">
                                    {this.state.dropDownOptions}
                                </div>
                                
                                <FormTextArea placeholder="Comments" value={this.state.comments} onChange={this._onCommentChange} rows="5" />

                                {escalateButton}
                                
                                <br/>
                                {/* <DisplayMessage message={this.props.responseMessage} /> */}
                                {successMessage}
                                {errorMessage}
                            </Card> 
                            <div className="tac spacingTopLarge">
                                <img src={MSALogo} alt="Loantrak" />
                            </div>
                        </div>
                    </CenterContent>
                )
            }
        }
    }
}

export default MatterEscalate;