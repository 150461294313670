import React, { Component } from 'react'

import './css/BGTransparentDark.css';

class BGTransparentDark extends Component {
    render() {
        return (
            <div className="BGTransparentDark"></div>
        )
    }
}

export default BGTransparentDark
