import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card';
import FormCheckBox from '../../atom/FormCheckBox/FormCheckBox';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import IconBox from '../../atom/IconBox/IconBox';
import Line from '../../atom/Line/Line';
import MessageBox from '../../atom/MessageBox/MessageBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment';

import BackBar from '../../molecules/BackBar/BackBar';
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox'; import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import Accordion from '../../organism/Accordion/Accordion';
import DataTableDocuments from '../../organism/DataTableDocuments/DataTableDocuments';
import CommunicationContent from '../../organism/CommunicationContent/CommunicationContent';
import CommunicationForm from '../../organism/CommunicationForm/CommunicationForm';
import LoanDetails from '../../organism/LoanDetails/LoanDetails';
import ProgressStep from '../../organism/ProgressStep/ProgressStep';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/FundDiscussionPage.css';
import { AuthService } from '../../../services/auth-service';
import { InstructionService } from '../../../services/instruction-service';
import RadioButton from '../../atom/RadioButon/RadioButon';
import RadioButtonGroup from '../../molecule/RadioButtonGroup/RadioButtonGroup';
import { saveAs } from 'file-saver';
import Button from '../../atom/Button/Button';
import ButtonGroupDischarges from '../../molecule/ButtonGroupDischarges/ButtonGroupDischarges';
import AutoLogout from '../../../auto-logout';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import CheckBox from '../../atom/CheckBox/CheckBox';
import InsightBox from '../../molecules/InsightBox/InsightBox';
import { MatterService } from '../../../services/matter-service';
import { FundService } from '../../../services/fund-service';
import Moment from "moment";


class FundDiscussionPage extends Component {

    constructor(props) {
        super(props)

        try {
            var dmInit = this.props.location.state.desktopMenu;
            var lmwimsInit = this.props.location.state.lmwInstructMSASub;
        } catch {
            var dmInit = "";
            var lmwimsInit = "";
        }
        let userdetails = AuthService.getUserDetails();
        let lendername = AuthService.getLendername();
        //alert(this.props.match.params.myKey)
        this.state = {
            MatterId: this.props.match.params.myKey == null?'' : this.props.match.params.myKey.slice(1),
            pageTitle: 'fundingdiscussion',
            leftContent: 'fundingdiscussion',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            isInstructPriv: null,
            isLoading: false,
            onboarding: true,
            fade: false,
            option: "",
            prompt: "",
            copy: "This page can update MSA's systems, eliminating the need to it for an update to be actioned. only certain lenders are permitted to instruct MSA through the portal. For documentation please contact MSA. You will receive a message asking you to confirm your changes.",
          
            errorMessage: '',          
            shouldRedirect: false,
            
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            userdetails: userdetails,
            emailValidationError: null,
            profileDisplay: "",
            lenderName: lendername,

 

            activeSection: 'divMatterDetails',
            FormStepsValue: '',
            InsightBox: 0,
            windowWidth: window.innerWidth,
            matterDesc: '',
            MarkAsPriority: false,
            UserCanAddConversation: false,
            convoDetail: '',
            currentFundingAmount: '',
        }

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        

        this.handleChangeConvoDetail = this.handleChangeConvoDetail.bind(this);
        this.SwitchForm = this.SwitchForm.bind(this);
        //this.UpdateActiveSection = this.UpdateActiveSection.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this);
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);

        
        //Discussion Start 
        // this.gotoDiscussionTop = this.gotoDiscussionTop.bind(this);
        // this.gotoDiscussionBottom = this.gotoDiscussionBottom.bind(this);
        this.getMatterDesc = this.getMatterDescription.bind(this);
        this.getFundingConversations = this.getFundingConversations.bind(this);        
        // this.computeDiscussionBoxHeight = this.computeDiscussionBoxHeight.bind(this);
        //Discussion End
        this.MarkAsPriorityChanged = this.MarkAsPriorityChanged.bind(this);
        this.AddConversation = this.AddConversation.bind(this);

    }

    // UpdateActiveSection(e) {
    //     //alert(e);
    //     window.scrollTo(0, 0);
    //     this.setState({
    //         activeSection: e
    //     });

    //     e.preventDefault(); 
    // }

    handleChangeConvoDetail(e) {
        e.preventDefault();
        this.setState({ convoDetail: e.target.value });
    }
    
    handleLeftMenuCallback(child, dmMode, InstructSubMenu) {
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    componentDidMount() {
        document.title = 'Funding Discussion';
        AuthService.isNewVersionDetected();

        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("FundingDiscussion").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }

        MatterService.CanUserViewConversation().then((resp) => {
            console.log("CanUserViewConversationResp");
            console.log(resp);
            // if(resp.status==200)
            // {
            //     console.log(resp)
            //     this.setState({ CanViewFundConversation: true, shouldRedirect: false, isLoading: true },()=>
            //     {
            //         this.getMatterDescription();
            //         this.getFundingConversations()  
            //     });
            // }
            //alert(resp.data.Content)
            this.setState({ CanViewFundConversation: resp.data.Content, shouldRedirect: resp.data.Content == true ? false : true },()=>
            {
                this.getMatterDescription();
                this.getFundingConversations()  
            });
           
        }, (err) => {
            this.setState({ CanViewFundConversation: false, shouldRedirect: true });
        });      

        MatterService.CanUserAddFundConverstation().then((resp) => {
            console.log("CanUserAddFundConverstation");
            console.log(resp);
            // if(resp.status==200)
            // {
            //     console.log(resp)
            //     this.setState({ UserCanAddConversation: true, shouldRedirect: false},()=>
            //     {
                   
            //     });
            // }
            // else{
            //     this.setState({ UserCanAddConversation: false})
            // }
            //alert(resp.data.Content)
            this.setState({ UserCanAddConversation: resp.data.Content});
           
           
        }, (err) => {
            this.setState({ UserCanAddConversation: false});
        }); 

        FundService.GetFundingSummary(this.state.MatterId).then(
            resp => {
            this.setState({
                currentFundingAmount: resp.data.Content,        
                isLoading: false,
            },()=>{
                console.log("currentFundingAmount")
                console.log(this.state.currentFundingAmount)
                //this.getLenderFundListCount();
            });
            },
            err => {
           
            }
        ); 

        window.addEventListener("resize", this.updateWindowDimension);
        
        // this.computeDiscussionBoxHeight();
        //this.gotoDiscussionBottom(); 
    }

    componentWillUpdate(np, ns) {
        if (ns !== this.state) {

        }
    }

    getMatterDescription()
    {
        FundService.GetMatterDescription(this.state.MatterId).then(
            resp => {
            this.setState({
                matterDesc: resp.data.Content,        
                isLoading: false,
            },()=>{
                console.log("matterDesc")
                console.log(this.state.matterDesc)
                //this.getLenderFundListCount();
            });
            },
            err => {
           
            }
        ); 
    }

    getFundingConversations()
    {
        FundService.GetLenderFundingConversation(this.state.MatterId, this.state.format).then(
            resp => {
            //const data = resp.data.Content;
            //const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
            this.setState({
                fundingDiscussionItemsCount: resp.data.Content.length,
                fundingDiscussionItems: resp.data.Content,//slice,//resp.data.Content,
               // hasSearched: true,
                isLoading: false,
                //pageCount: Math.ceil(data.length / this.state.perPage),
                //data: slice,//resp.data.Content,          
            },()=>{
                console.log("fundingDiscussionItems")
                console.log(this.state.fundingDiscussionItems)
                //this.getLenderFundListCount();
                if(this.state.fundingDiscussionItemsCount>0)
                {
                    this.gotoDiscussionBottom(); 
                }
            });
            },
            err => {
           
            }
        );    
    }

    componentDidUpdate() {
        let displayNoneElems = document.querySelectorAll('div[style*="display:none"]');
        for (let y = 0; y < displayNoneElems.length; y++) {
            let noneInputElems = displayNoneElems[y].getElementsByTagName('input');
            for (let i = 0; i < noneInputElems.length; ++i) {
                if (noneInputElems[i].required) {
                    noneInputElems[i].required = false;
                }
            }
        }



    }

    updateWindowDimension = () => {
        this.setState({
            profileDisplay: "",
            windowWidth: window.innerWidth
        })

        if (this.state.windowWidth < 768) {
            this.hideInsightBox();
        }

        // alert("Width: "+this.state.windowWidth)
        // this.computeDiscussionBoxHeight();
    }

    toggleProfileMenu() {
        if (this.state.profileDisplay == "") {
            this.setState({
                profileDisplay: "display"
            });
        } else {
            this.setState({
                profileDisplay: ""
            });
        }
    }

    SwitchForm(selectedForm) {
        this.setState({
            FormStepsValue: selectedForm
        });
    }

    showInsightBox() {
        // alert("Show Insights")
        if (this.state.InsightBox == 0) {
            this.setState({
                InsightBox: 1
            });
        } else {
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox() {
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights() {
        this.handleLeftMenuCallback("insights", "", "");
        // window.open('/insights') 
    }

    MarkAsPriorityChanged(event) {
        this.setState({MarkAsPriority:!this.state.MarkAsPriority},()=>{
            //alert(this.state.isPrivateNote);
        });   
    }

    AddConversation()
    {
        if(this.state.convoDetail == "")
        {
            alert("Please type in your message");
            return;
        }

        this.setState({isAddingConvo: true})
        FundService.AddFundingDiscussion(this.state.MatterId, this.state.convoDetail, this.state.MarkAsPriority ).then(
            resp => {
              this.setState({
                isAddingConvo: false,
                convoDetail: '' ,
                MarkAsPriority: false       
              });
              this.getFundingConversations();
            },
            err => {
              this.setState({
                //shouldRedirect: true,
                errorMessage: 'there is an error with the request',
                message: "",
              });
            }
          );
    }

    //Discussion Start
    gotoDiscussionTop() {  
        document.getElementById('conversationBox').scroll({
            top:-10,
            behavior:'smooth'
        });
    }

    gotoDiscussionBottom() {  
        document.getElementById('conversationBox').scroll({
            top:document.getElementById('conversationBox').scrollHeight+10 ,
            behavior:'smooth'
        });   
        
    }

    // computeDiscussionBoxHeight(){   
    //     var origHeight=document.getElementById('conversationBox').offsetHeight;
    //     var formHeight=document.getElementById('msgeFormBox').offsetHeight;
  
    //     if (this.state.windowWidth <= 736) { 
    //         document.getElementById("conversationBox").style.height = (origHeight-formHeight-80)+"px";
    //     }else{
    //         // alert(origHeight+"-"+formHeight+"="+(origHeight-formHeight))
    //         document.getElementById("conversationBox").style.height = (origHeight-formHeight)+"px";
    //     }
    // }
    //Discussion End


    render() {
        let divProgress = "";

        if (this.state.shouldRedirect) {
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if (localStorage.getItem("ssoTimeout") == null) {
                return <Redirect to="/error" />;
            }
            else {
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        } 

        if (this.state.isLoading) {
            divProgress=(<Card><ProgressDisplay /></Card>);
        }

        if (this.state.leftContent != this.state.pageTitle) {
            // alert(this.state.lmwInstructMSASub)
            return (
                <DisplayLeftMenu
                    page={this.state.leftContent}
                    desktopMenu={this.state.desktopMenu}
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                />
            )
        }

        let divfundingDiscussions = "";
        //var currentFundingAmount = 0;

        if(this.state.fundingDiscussionItemsCount > 0)
        {
            // const last = this.state.fundingDiscussionItemsCount[this.state.fundingDiscussionItemsCount - 1];
            //var last = this.state.fundingDiscussionItemsCount-1;
            //var lastVal = [];
            //lastVal = this.state.fundingDiscussionItems.slice(-1);
            //currentFundingAmount = this.state.fundingDiscussionItems[last].AmountAtTimeOfDiscussion;
            // this.props.FundingRequestItems.forEach(elem => {
            //     currentFundingAmount = elem.las.FundingAmount;
            // });
            divfundingDiscussions = this.state.fundingDiscussionItems.map(d => (
                <>                
                    {
                        d.DiscussionByMSA ?
                        <div className="convoBox">
                            <div className={d.Priority?"convoPriority":"convo"}>
                                {d.Priority? 
                                    <IconBox 
                                        addCSS="prioIcon" 
                                        iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                                        tooltip="Priority" 
                                    />
                                  :""
                                }
                                <div className="convoNotes">
                                    {/* <div>{d.DiscussionByName}- {new Date(d.DiscussionDate).toLocaleTimeString('EN-AU', { hour: 'numeric', minute: 'numeric', hour12: true,  })} {new Date(d.DiscussionDate).toLocaleDateString()}</div> */}
                                    <div>{d.DiscussionByName}- {Moment(this.state.DiscussionDate).format("HH:mm A")} { Moment(d.DiscussionDate).format("DD/MM/YYYY")}</div>
                                    <div>Amount at time: ${new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,      
                                        maximumFractionDigits: 2,
                                        }).format(d.AmountAtTimeOfDiscussion)}
                                    </div>
                                </div>
                                <div>{d.DiscussionText}</div>
                            </div>
                        </div>
                        :
                        <div className="replyBox">
                            <div className={d.Priority?"convoPriority":"reply"}>
                                {d.Priority? 
                                    <IconBox 
                                        addCSS="prioIcon" 
                                        iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                                        tooltip="Priority" 
                                    />
                                  :""
                                }
                                <div className="convoNotes">
                                    {/* <div>{d.DiscussionByName}- {new Date(d.DiscussionDate).toLocaleTimeString('EN-AU', { hour: 'numeric', minute: 'numeric', hour12: true,  })} {new Date(d.DiscussionDate).toLocaleDateString()}</div> */}
                                    <div>{d.DiscussionByName}- {Moment(this.state.DiscussionDate).format("HH:mm A")} { Moment(d.DiscussionDate).format("DD/MM/YYYY")}</div>
                                    <div>Amount at time: ${new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,      
                                        maximumFractionDigits: 2,
                                        }).format(d.AmountAtTimeOfDiscussion)}
                                    </div>
                                </div>
                                <div>{d.DiscussionText}</div>
                            </div>
                        </div>
                    }
                    {/* <div className={d.DiscussionByMSA?"convoBox":"replyBox"}>
                        <div className={d.Priority?"convoPriority":"convo"}>
                            <div className="convoNotes"> */}
                                {/* <div>{d.DiscussionByName}- {new Date(d.DiscussionDate).toLocaleTimeString('EN-AU', { hour: 'numeric', minute: 'numeric', hour12: true,  })} {new Date(d.DiscussionDate).toLocaleDateString()}</div> */}
                                {/* <div>{d.DiscussionByName}- {Moment(this.state.DiscussionDate).format("HH:mm A")} { Moment(d.DiscussionDate).format("DD/MM/YYYY")}</div>
                                <div>Amount at time: ${new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,      
                                    maximumFractionDigits: 2,
                                    }).format(d.AmountAtTimeOfDiscussion)}
                                </div>
                            </div>
                            <div>{d.DiscussionText}</div>
                        </div>
                    </div> */}

                    {/* <div className="convoBox">
                        <div className="convoPriority">
                            <IconBox 
                                addCSS="prioIcon" 
                                iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                                tooltip="Priority" 
                            />
                            <div className="convoNotes">
                                <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                <div>Amount at time: $123,456.78</div>
                            </div>
                            <div>This is priority.</div>
                        </div>
                    </div>  

                    <div className="replyBox">
                        <div className="reply">
                            <div className="convoNotes">
                                <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                <div>Amount at time: $123,456.78</div>
                            </div>
                            <div>Wow funds are cool!</div>
                        </div>
                    </div> */}

                </>
                // <div id="conversationBox" className="conversationBox"> 

                //     <div className="convoBox">
                //         <div className="convo">
                //             <div className="convoNotes">
                //                 <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                //                 <div>Amount at time: $123,456.78</div>
                //             </div>
                //             <div>Wow funds are cool!</div>
                //         </div>
                //     </div>

                //     <div className="convoBox">
                //         <div className="convoPriority">
                //             <IconBox 
                //                 addCSS="prioIcon" 
                //                 iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                //                 tooltip="Priority" 
                //             />
                //             <div className="convoNotes">
                //                 <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                //                 <div>Amount at time: $123,456.78</div>
                //             </div>
                //             <div>This is priority.</div>
                //         </div>
                //     </div>

                //     <div className="replyBox">
                //         <div className="reply">
                //             <div className="convoNotes">
                //                 <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                //                 <div>Amount at time: $123,456.78</div>
                //             </div>
                //             <div>Wow funds are cool!</div>
                //         </div>
                //     </div>

                //     <div className="replyBox">
                //         <div className="replyPriority">
                //             <IconBox 
                //                 addCSS="prioIcon" 
                //                 iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                //                 tooltip="Priority" 
                //             />
                //             <div className="convoNotes">
                //                 <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                //                 <div>Amount at time: $123,456.78</div>
                //             </div>
                //             <div>This is priority.</div>
                //         </div>
                //     </div>

                //     <div className="convoBox">
                //         <div className="convo">
                //             <div className="convoNotes">
                //                 <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                //                 <div>Amount at time: $123,456.78</div>
                //             </div>
                //             <div>Wow funds are cool!</div>
                //         </div>
                //     </div>

                //     <div className="convoBox">
                //         <div className="convoPriority">
                //             <IconBox 
                //                 addCSS="prioIcon" 
                //                 iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                //                 tooltip="Priority" 
                //             />
                //             <div className="convoNotes">
                //                 <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                //                 <div>Amount at time: $123,456.78</div>
                //             </div>
                //             <div>This is priority.</div>
                //         </div>
                //     </div>
                // </div>
    
            ));             
            
        }
        else 
        {

        }

        return (

            <LeftMenuContent
                id="FundDiscussionPage"
                page="fundingdiscussion"
                desktopMenu={this.state.desktopMenu}
                callBack={this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={() => this.showInsightBox()}
                showInsightsDetails={this.moreInsights}
            >

                <ProfileMenu
                    addCSS={this.state.profileDisplay == "display" ? "table" : "none"}
                    clickAction={() => this.toggleProfileMenu()}
                />


                <div id="content" className="content">

                    <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>

                    {divProgress}
                   
                    {this.state.fundingDiscussionItemsCount > 0 ? 
                        <>
                        <div id="discussionBox" className="discussionBox">

                            <TitleSegment>Funding Discussions - {this.state.matterDesc} - {this.state.MatterId}</TitleSegment> 
    
                            <IconBox 
                                addCSS="cbTop btn" 
                                iconURL="../../../images/icons/OutlineWhite/Top.svg" 
                                tooltip="Go to top" 
                                clickAction={() => this.gotoDiscussionTop()}
                            />

                            <IconBox 
                                addCSS="cbBottom" 
                                iconURL="../../../images/icons/OutlineWhite/Bottom.svg" 
                                tooltip="Go to bottom"
                                clickAction={() => this.gotoDiscussionBottom()}
                            /> 
                            <div id="conversationBox" className="conversationBox"> 
                                {divfundingDiscussions}

                                {/* <div className="convoBox">
                                    <div className="convo">
                                        <div className="convoNotes">
                                            <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                            <div>Amount at time: $123,456.78</div>
                                        </div>
                                        <div>Wow funds are cool!</div>
                                    </div>
                                </div>

                                <div className="convoBox">
                                    <div className="convoPriority">
                                        <IconBox 
                                            addCSS="prioIcon" 
                                            iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                                            tooltip="Priority" 
                                        />
                                        <div className="convoNotes">
                                            <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                            <div>Amount at time: $123,456.78</div>
                                        </div>
                                        <div>This is priority.</div>
                                    </div>
                                </div>  

                                <div className="replyBox">
                                    <div className="reply">
                                        <div className="convoNotes">
                                            <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                            <div>Amount at time: $123,456.78</div>
                                        </div>
                                        <div>Wow funds are cool!</div>
                                    </div>
                                </div>

                                <div className="replyBox">
                                    <div className="replyPriority">
                                        <IconBox 
                                            addCSS="prioIcon" 
                                            iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                                            tooltip="Priority" 
                                        />
                                        <div className="convoNotes">
                                            <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                            <div>Amount at time: $123,456.78</div>
                                        </div>
                                        <div>This is priority.</div>
                                    </div>
                                </div>

                                <div className="convoBox">
                                    <div className="convo">
                                        <div className="convoNotes">
                                            <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                            <div>Amount at time: $123,456.78</div>
                                        </div>
                                        <div>Wow funds are cool!</div>
                                    </div>
                                </div>

                                <div className="convoBox">
                                    <div className="convoPriority">
                                        <IconBox 
                                            addCSS="prioIcon" 
                                            iconURL="../../../images/icons/OutlineWhite/Exclamation.svg" 
                                            tooltip="Priority" 
                                        />
                                        <div className="convoNotes">
                                            <div>Robbie Quinn - 4:13 PM 22/08/2024</div>
                                            <div>Amount at time: $123,456.78</div>
                                        </div>
                                        <div>This is priority.</div>
                                    </div>
                                </div> */}
                            </div>

                            

                        </div>                        
                        </>
                        : ''
                    }                    

                    {this.state.UserCanAddConversation? 
                    <div id="msgeFormBox" className="msgeFormBox">
                        <Card> 
                            <div>
                                <strong>New Message</strong> - Current Funding : ${new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,      
                                        maximumFractionDigits: 2,
                                        }).format(this.state.currentFundingAmount)}
                            </div>
                            
                            <div className="FormBox">
                                <div>  
                                    {/* <textarea class="FormTextArea undefined" placeholder="Your message here"></textarea> */}
                                    <FormTextArea className="FormTextArea undefined" placeholder="Your message here" rows="5" value={this.state.convoDetail} onChange={this.handleChangeConvoDetail} />
                                </div>
                                <div>
                                    {this.state.isAddingConvo ? <ProgressDisplay /> 
                                    : 
                                    <ButtonIcon
                                        id=""
                                        addCSS="btnSendDesktop ButtonIconSelected biRight mainBtn"
                                        iconURL="../../../../images/icons/OutlineWhite/Send.svg"
                                        onClick= {()=>{this.AddConversation()}}
                                    >Send</ButtonIcon>
                                    }

                                    {/* {this.state.isAddingConvo ? <ProgressDisplay /> 
                                    : 
                                    <IconBox 
                                        addCSS="btnSendMobile btn" 
                                        iconURL="../../../../images/icons/OutlineWhite/Send.svg" 
                                        tooltip="Send" 
                                        // clickAction={() => this.gotoDiscussionTop()}
                                        clickAction= {()=>{this.AddConversation()}}
                                    /> 
                                    }                                    */}

                                         
                                </div>
                            </div> 

                            <div>
                                {/* <CheckBox value="Mark Priority" title="Mark Priority" /> */}
                                <CheckBox handleCheckChildElement={this.MarkAsPriorityChanged} isChecked={this.state.MarkAsPriority} value=" Mark as Priority" />                                
                            </div> 

                        </Card> 
                    </div>
                    :
                    ''
                    }
                </div>



            </LeftMenuContent>
        )
    }
}
FundDiscussionPage = AutoLogout(FundDiscussionPage);
export default FundDiscussionPage
