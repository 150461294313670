import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import Button from '../../atom/Button/Button';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import HighlightText from '../../atom/HighlightText/HighlightText';
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';
import TextIcon from '../../molecules/TextIcon/TextIcon';
  
import DataTableUser from '../../organism/DataTableUser/DataTableUser';
import DataTablePaging from '../../organism/DataTablePaging/DataTablePaging';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent'; 

import './css/AdminControlPage.css';  
import SearchBox from '../../molecules/SearchBox/SearchBox';
import { AuthService } from '../../../services/auth-service';
import { UserLender } from '../../../services/user-lender';
import AutoLogout from '../../../auto-logout';
import SearchTextIcon from '../../molecules/SearchTextIcon/SearchTextIcon';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import InsightBox from '../../molecules/InsightBox/InsightBox';

class AdminControlPage extends Component {
    constructor(props){
        super(props);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        this.state = {
            pageTitle : 'admincontrol',
            leftContent : 'admincontrol',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            lenderUserTotal: [],
            lenderUsers: [],
            lenderUser: '' ,   
            lenderUsersCount: [],  
            isUpdated: false       ,
            ViewUserAction: false,
            CreateUserAction: false ,
            isDisplayed: true,
            data: 'aa',
            UserFilter: '',
            SearchFilter: '',
            SearchInput: '',
            enabledCount: [],
            disabledCount: [],
            page: '',
            param: '',
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0, 
            profileDisplay: "",
            createNewUser: false,
            isLoading: true,
            searchCount: 0,
            InsightBox:0, 
        }

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.lenderUsersChanged = this.lenderUsersChanged.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleFilterAll = this.handleFilterAll.bind(this);
        this.handleFilterEnabled = this.handleFilterEnabled.bind(this);
        this.handleFilterDisabled = this.handleFilterDisabled.bind(this);
        this.updateState = this.updateState.bind(this);
        
        this.cancelChange = this.cancelChange.bind(this);
        this.getLenderListForUser = this.getLenderListForUser.bind(this);
        this.updateSelectedPage = this.updateSelectedPage.bind(this);
        this.getEnabledLenderListForUser = this.getEnabledLenderListForUser.bind(this);
        this.getDisabledUserLenderList = this.getDisabledUserLenderList.bind(this);

        this.searchUser = this.searchUser.bind(this);
        this.changeSearhInput = this.changeSearhInput.bind(this);
        this.simpleUserSearch = this.simpleUserSearch.bind(this);

        this.handleNewUser = this.handleNewUser.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    }

    componentDidMount(){
        document.title="Admin Control";
        AuthService.isUserAdminEnabled().then((resp)=>{
            this.setState({isAdminPriv:resp.data.Content,shouldRedirect:!resp.data.Content});
        },(err)=>{
          this.setState({isAdminPriv:false,shouldRedirect:true});
        });

        this.setState({
            ViewUserAction: true,
            UserFilter: 'All',
        }, ()=>{
           // console.log("edsfdsffdsfdf" + this.state.lenderUsersCount.slice(0,1) )
        }
        )
        // console.log("useraction:" + this.state.ViewUserAction)
        // console.log("filter:" + this.state.UserFilter)

        this.getUserLenderListCount();
        this.getLenderListForUser();
        this.setState({
            //set values         
                
            //EnabledUsers: this.state.lenderUsersCount.map(d=>{d.EnabledUsers}),
            //DisabledUsers : this.state.lenderUsersCount.map(d=>{d.DisabledUsers}),
                
            
          }, ()=>{
            // console.log("sgsfd" + this.state.lenderUsersCount)
            // console.log("wqrqewr" + this.state.EnabledUsers)
        });

        window.addEventListener("resize", this.updateWindowDimension)
    }

    handleNewUser(){
        //alert('dfgdfg')
        //window.open('/userdetails')
        this.setState({
            createNewUser: true
        })
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    toggleShowHide = () => {
        this.setState(state => ({ isDisplayed: !state.isDisplayed }));
      };

     

    getLenderListForUser()
    {
        let p = UserLender.getLenderListForUser();
        let lenderUsers ;



        UserLender.getLenderListForUser(         
           
            ).then(
                resp => {
                const data = resp.data.Content;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
                this.setState({
                    lenderUserTotal: resp.data.Content,
                    lenderUsers: slice,//resp.data.Content,
                   // hasSearched: true,
                    isLoading: false,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    data: slice,//resp.data.Content,          
                },()=>{
                    //console.log(data)
                    this.getUserLenderListCount();
                });
                },
                err => {
                // this.setState({
                //     hasSearched: true,
                //     isLoading: false,
                //     errorExists: true,
                //     errorMessage: err,
                // });
                }
            );    

    } 

    getUserLenderListCount()
    {       
        this.setState({
            isLoading: true
        })
        let p = UserLender.getUserLenderListCount();       
        
        Promise.all([p]).then(values => {
            let lenderUsersCount = values[0].data.Content.slice(0,1);      
            let enabledCount = values[0].data.Content.slice(1,2);
            let disabledCount = values[0].data.Content.slice(2,3);
            
            this.setState({
                //set values     
                isLoading: false  ,  
                lenderUsersCount: lenderUsersCount, 
                enabledCount:    enabledCount   ,
                disabledCount: disabledCount
                // EnabledUsers: 15,//this.state.lenderUsersCount.map(d=>{d.EnabledUsers}),
                //DisabledUsers : this.state.lenderUsersCount.map(d=>{d.DisabledUsers}),              
            
          },()=>{
            //this.updateSelectedPage(1)
          }
          /* , ()=>{console.log("lenderscount" + this.state.lenderUsersCount.splice(0,1))
           
        } */
        );
        

        
    });
       

    }

    updateSelectedPage(e) {
        //alert(e.selected)
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
      
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            //console.log(this.state.offset + " offset - perpage " + this.state.perPage),
            this.receivedData()
        });
        
    }

    onStartClick = (e) => {
        const selectedPage = 0;
        const offset = 0;
    
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            this.receivedData()
        });
    
    };

    onEndClick = (e) => {
        const selectedPage = this.state.pageCount-1;//e.selected;
        const offset = selectedPage * this.state.perPage;
        
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            // console.log(this.state.offset + " offset - perpage " + this.state.perPage);
            this.receivedData()
        });
    
    };   


    receivedData() {
        //alert(this.state.offset)
        const data = this.state.lenderUserTotal;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({      
          isLoading: false,
          pageCount: Math.ceil(data.length / this.state.perPage),
          //currentPage : 0,
          data: slice,//resp.data.Content
          lenderUsers: slice,
          //offset: 0,
        },()=>{
            // console.log(data)
        });    
    }

    handleFilterAll(e){
        this.getLenderListForUser();

        this.setState({
           UserFilter: 'All',           
                currentPage: 0,
                offset: 0,
                pageCount: 0 ,
                SearchInput:''
       })      
   }

    handleFilterEnabled(e){
        this.getEnabledLenderListForUser();        

        this.setState({
            UserFilter: 'Enabled',
            currentPage: 0,
            offset: 0,
            pageCount: 0 ,
            SearchInput:''
        })
    }

    handleFilterDisabled(e){
        this.getDisabledUserLenderList()
        this.setState({
            UserFilter: 'Disabled',
            currentPage: 0,
            offset: 0,
            pageCount: 0 ,
            SearchInput:''
        })    
    }

    getEnabledLenderListForUser()
    {
        let p = UserLender.getEnabledLenderListForUser();
        
        UserLender.getEnabledLenderListForUser(         
           
            ).then(
                resp => {
                const data = resp.data.Content;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
                this.setState({
                    lenderUserTotal: resp.data.Content,
                    lenderUsers: slice,//resp.data.Content,
                   // hasSearched: true,
                    isLoading: false,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    data: slice,//resp.data.Content,          
                },()=>{
                    //console.log(data)
                    this.getUserLenderListCount();
                });
                },
                err => {
                // this.setState({
                //     hasSearched: true,
                //     isLoading: false,
                //     errorExists: true,
                //     errorMessage: err,
                // });
                }
            );      
    }

    getDisabledUserLenderList()
    {
        let p = UserLender.getDisabledUserLenderList();
        
        UserLender.getDisabledUserLenderList(         
           
            ).then(
                resp => {
                const data = resp.data.Content;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
                this.setState({
                    lenderUserTotal: resp.data.Content,
                    lenderUsers: slice,//resp.data.Content,
                   // hasSearched: true,
                    isLoading: false,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    data: slice,//resp.data.Content,          
                },()=>{
                    //console.log(data)
                    this.getUserLenderListCount();
                });
                },
                err => {
                // this.setState({
                //     hasSearched: true,
                //     isLoading: false,
                //     errorExists: true,
                //     errorMessage: err,
                // });
                }
            );      
    }

    changeSearhInput(event){
         this.setState({
            SearchInput : event.target.value,
            //UserFilter: 'Search'
         }, () =>{
            //  console.log("SearchFilter: " + this.state.SearchInput)
            });
    }
    
    searchUser(e){
        // if(this.state.SearchInput == "")        {
        //     this.getLenderListForUser();  
        // }
        // else{
            //this.simpleUserSearch();
        // }
       
       //alert(this.state.SearchInput)
        this.setState({
            UserFilter: 'Search',
            SearchFilter: this.state.SearchInput
        }, () =>{
            // console.log("search param: " + this.state.SearchFilter)
        })
    }

    simpleUserSearch()
    {
        this.setState({
            isLoading: true,
            UserFilter: 'Search',
        })
        //alert('dsfdsf')
        //alert(this.state.SearchInput);
        if(this.state.SearchInput == ''){
                //return;
                this.setState({
                    UserFilter: 'All'
                })
        }
       //let p = UserLender.simpleUserSearch(this.state.SearchInput);

        UserLender.simpleUserSearch(         
            this.state.SearchInput
            ).then(
                resp => {
                const data = resp.data.Content;
                //alert(data.length)
                //const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
                const slice = data.slice(0, 0 + this.state.perPage)        
                this.setState({
                    //lenderUsersCount: data.length,
                    searchCount: data.length,
                    lenderUserTotal: resp.data.Content,
                    lenderUsers: slice,//resp.data.Content,
                   // hasSearched: true,
                    isLoading: false,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    data: slice,//resp.data.Content,   
                    selectedPage: 0,
                    
                    currentPage:0,
                    
                offset: 0
                //    length
                //     perPage       
                },()=>{
                    //console.log(data)
                    //this.getUserLenderListCount();
                    //this.updateSelectedPage(this.state.selectedPage);
                });
                },
                err => {
                // this.setState({
                //     hasSearched: true,
                //     isLoading: false,
                //     errorExists: true,
                //     errorMessage: err,
                // });
                }
            );     
    }


    handleClick(id, event){
        let name = event.target.name
        // console.log(id)
        // console.log("value" +event.target.value)
        // console.log("id" +event.target.getAttribute('id'))
        // console.log("name" +event.target.name)
        //alert(event.target.getAttribute('id'))
        UserLender.updateLenderUserAccess(event.target.getAttribute('id')) 
        .then(response => {
            if(response.data.Successful == true)
            {
                this.getLenderListForUser()
                alert('User Updated!')
            }
            //console.log(response.data.Successful)
        })
        .catch(error => {
        console.log(error)
        })       
    }

  lenderUsersChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      lenderUser: target.value
    });
  }
  updateState()
  {
    this.setState(state => ({ data: '!state.isDisplayed' }));
  }

    cancelChange(event) {
        event.preventDefault();
        //alert(event.target.getAttribute('id'))
            
        this.setState({
            ViewUserAction: true,
            CreateUserAction: false
        } ) 
    }

   
    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }
    
    render() {        
        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        if(this.state.createNewUser){
            return <Redirect to="/userdetails" />;
        }

        if(this.state.leftContent != this.state.pageTitle){
            return( 
                <DisplayLeftMenu 
                    page={this.state.leftContent} 
                    desktopMenu={this.state.desktopMenu} 
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                />
            )
        } 

        let paging= '';
        let divUserDetails= "";

        if(this.state.UserFilter=='Search'){
            if(this.state.searchCount > 0){
                paging=(
                    <DataTablePaging
                        pageCount= {this.state.pageCount}
                        currentPage={this.state.currentPage}
                        handlePageClick={this.updateSelectedPage}
                        items={this.state.offset}
                        total={this.state.lenderUserTotal.length}
                        onEndClick={this.onEndClick}
                        onStartClick={this.onStartClick}
                        perPage={this.state.perPage}
                    >
                        
                    </DataTablePaging>
                )
            }
            else {paging = "";}
            
        }
        else{
            if(this.state.lenderUsersCount > 0){
                //alert(this.state.lenderUsersCount)
            paging=(
                <DataTablePaging
                    pageCount= {this.state.pageCount}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.updateSelectedPage}
                    items={this.state.offset}
                    total={this.state.lenderUserTotal.length}
                    onEndClick={this.onEndClick}
                    onStartClick={this.onStartClick}
                    perPage={this.state.perPage}
                >
                    
                </DataTablePaging>
            )
        }

            else {paging = "";}
        }
        
        
        

        if (this.state.isLoading) {
            divUserDetails=(<Card><ProgressDisplay /></Card>);
        }
        else{
            divUserDetails=(
               <>

               
                {(this.state.UserFilter=='Search')?
                ((this.state.searchCount > 0) ?<Card>
                <DataTableUser 
                    userLender = {this.state.lenderUsers}
                    getEnabledLenderListForUser = { this.getEnabledLenderListForUser}
                    getDisabledUserLenderList = { this.getDisabledUserLenderList}
                    //simpleUserSearch = { this.simpleUserSearch()}
                    getLenderListForUser = { this.getLenderListForUser}
                    UserFilter = {this.state.UserFilter}

                />

             
</Card>
        : <Card>No record found </Card>)
                :
                (this.state.lenderUsersCount > 0 || (this.state.UserFilter=='Search' && this.state.searchCount > 0)) ?
                      <Card>
                        <DataTableUser 
                            userLender = {this.state.lenderUsers}
                            getEnabledLenderListForUser = { this.getEnabledLenderListForUser}
                            getDisabledUserLenderList = { this.getDisabledUserLenderList}
                            //simpleUserSearch = { this.simpleUserSearch()}
                            getLenderListForUser = { this.getLenderListForUser}
                            UserFilter = {this.state.UserFilter}

                        />

                     
</Card>
                : <Card>No record found </Card>
                
                }
           
                {paging}
            {/* {(this.state.UserFilter=='Search' && this.state.searchCount > 0)? paging : (this.state.lenderUsersCount > 10 ) ?        paging:""} */}
            
            </>
            )
        }

        return ( 
             
            <LeftMenuContent
                id="AdminControlPage"
                page="admincontrol"
                desktopMenu={this.state.desktopMenu}
                callBack = {this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
                    
                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />

                    <div className="content">  
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>

                    {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                        <IconBox 
                            addCSS="btnClose" 
                            iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                            tooltip="Close Insights"
                            clickAction={() => this.hideInsightBox()} 
                        />

                        <TitleSegment>Loan Insights</TitleSegment>

                        <div className='StatBar'>
                            <div className='SBLabel'>Stats</div>
                            <div className='SBData'>100</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Settled Matters</div>
                            <div className='SBData'>100</div>
                        </div>

                        <div className='StatGroup'>
                            <div className='SGTitle'>Number of Settled Matters</div>
                            <div className='SGBar'>
                                <div className='SGLabel'>NSW</div>
                                <div className='SGData'>1000</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>VIC</div>
                                <div className='SGData'>500</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>QLD</div>
                                <div className='SGData'>356</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>WA</div>
                                <div className='SGData'>576</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>SA</div>
                                <div className='SGData'>234</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>TAS</div>
                                <div className='SGData'>642</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>NT</div>
                                <div className='SGData'>123</div>
                            </div> 
                        </div> 

                        <div className='StatBar'>
                            <div className='SBLabel'>Average Days to Settlement</div>
                            <div className='SBData'>3</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Settled using eSign Documents</div>
                            <div className='SBData'>25</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Escalated Files</div>
                            <div className='SBData'>30</div>
                        </div>

                        <div className='StatAction'>
                            <ButtonIcon
                                id="insights"
                                addCSS="ButtonIconSelected biRight mainBtn"
                                iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                onClick={()=>{this.moreInsights()}}
                            >More Insights</ButtonIcon>
                        </div> 

                    </div> */}
                        
                        <div>
                            <TitleSegment addCSS="titleDesktop">User</TitleSegment>

                            <SearchBox
                                label="Search for user" placeholder="Enter given or surname" 
                                onClick={() => this.simpleUserSearch()}  
                                searchTextChange={this.changeSearhInput}
                                value={this.state.SearchInput}
                            />
                            <div>
                                <Button addCSS="btnSearch secondaryBTN TextIconCenter ">    
                                    <SearchTextIcon
                                        tiText="Search for user"
                                        placeholder="Search for user"
                                        iconURL="../../../images/icons/OutlineDark/Search.svg"
                                        onClick={() => this.simpleUserSearch()}  
                                        searchTextChange={this.changeSearhInput}
                                    />
                                </Button>
                            </div>

                            
                            {/* <div>
                                <Button addCSS="btnSearch secondaryBTN TextIconCenter ">    
                                    <TextIcon
                                        tiText="Search User"
                                        iconURL="../../../images/icons/OutlineDark/Search.svg"
                                    />
                                </Button>
                            </div> */}

                            <div className="filterBox">
                                <div className="topBox"> 
                                    <ButtonIcon 
                                        addCSS="ButtonIconFormDark BtnAddUser"
                                        iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                        onClick= {this.handleNewUser}
                                    >New User</ButtonIcon> 
                                </div>
                                <div className="leftBox">
                                    <div className="DataFilters">
                                        <div className="title">Filter status:</div>
                                        <HighlightText type={this.state.UserFilter =='All'? "HighlightSemiDark" : "HighlightGrey"} onClick={this.handleFilterAll}>All ({this.state.lenderUsersCount})</HighlightText>
                                        <HighlightText type={this.state.UserFilter =='Enabled'? "HighlightSemiDark" : "HighlightGrey"} onClick={this.handleFilterEnabled}>Active ({this.state.enabledCount})</HighlightText>
                                        <HighlightText type={this.state.UserFilter =='Disabled'? "HighlightSemiDark" : "HighlightGrey"} onClick={this.handleFilterDisabled}>Inactive ({this.state.disabledCount})</HighlightText>
                                    </div>
                                </div>
                                <div className="rightBox"> 
                                    <ButtonIcon 
                                        addCSS="ButtonIconFormDark BtnAddUser"
                                        iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                        onClick= {this.handleNewUser}
                                    >New User</ButtonIcon> 
                                </div>   
                            </div>
                            {divUserDetails}

                            {/* <Card>
                                {(this.state.isLoading) ?progressDisplay :
                                (this.state.lenderUsersCount > 0) ?
                                    (<>
                                        <DataTableUser 
                                            userLender = {this.state.lenderUsers}
                                            getEnabledLenderListForUser = { this.getEnabledLenderListForUser}
                                            getDisabledUserLenderList = { this.getDisabledUserLenderList}
                                            //simpleUserSearch = { this.simpleUserSearch()}
                                            getLenderListForUser = { this.getLenderListForUser}
                                            UserFilter = {this.state.UserFilter}

                                        />

                                     
                                    </>
                                ):"No record found."} 
                            </Card>

                            {(this.state.lenderUsersCount > 10) ?        paging:""
                            }   */}
                                                      
                        </div>
                        
                        
                         

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
AdminControlPage=AutoLogout(AdminControlPage);
export default AdminControlPage
