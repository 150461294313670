import React, { Component }                                 from 'react';
import { Redirect }                                         from 'react-router-dom';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import { AuthService } from '../../../services/auth-service';
// import ProgressDisplay                                      from '../../controls/progress-display'
// import { AuthService }                                      from '../../services/auth-service';

class MyLoanRedirect extends Component {

    constructor(props){       
        super(props);
        this.state=({
            matter: this.props.match.params.myKey.substring(1,8)       
        })
    }

    componentDidMount(){   
        //alert(this.state.matter)
    }

    render(){
        if(localStorage.getItem("token") == null)
        {
            return (<Redirect to={"/login:" + this.state.matter} />);
        }
        else
        {
            return (<Redirect to={"/matter-details:" + this.state.matter} />);
        } 
    }
}

export default MyLoanRedirect;
