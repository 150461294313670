import React, { Component } from 'react' 

import Logo from '../../atom/Logo/Logo'; 
import Button from '../../atom/Button/Button';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import ValidationText from '../../atom/ValidationText/ValidationText';
import DisclaimerPage from '../../pages/DisclaimerPage/DisclaimerPage';
import { Redirect }                                         from 'react-router-dom';
import {AuthService}                                  from '../../../services/auth-service';

import './css/MatterEscalateForm.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';

class MatterEscalateForm extends Component {
    constructor(props){
        super(props);
        this.state = {            
            
        }; 
    } 

    render() {
        let progressIcon = "";
        
        if (this.state.loginInProgress) {   
            progressIcon=<ProgressDisplay/>;
        } else{ 

        }  

       return (        
            <> 
                <Logo />
                <h5>Matter Escalate</h5>
                <p>
                    Loan Escalation Request for Aaron Wai Lun Li<br />
                    MSA Reference: 3032390<br />
                    Lender Reference: APP-1111294
                </p>
                
                <ValidationText>{this.state.errorMessage}</ValidationText>
                
                <div className="inputBox">
                    <select>
                        <option value="">Reasons</option>
                    </select>
                </div>
                 
                <FormTextArea placeholder="Comments" rows="5" />

                <Button>Submit</Button>
                
                <br/>
                <DisplayMessage message={this.props.responseMessage} />
            </>
        )
    }
}

export default MatterEscalateForm
