import React from 'react'; 
//import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {Route, Switch }                                     from 'react-router-dom';

import AdminControlPage from './components/pages/AdminControlPage/AdminControlPage';
import LoginPage from './components/pages/LoginPage/LoginPage';
// import MyLoanLoginPage from './components/pages/LoginPage/MyLoanLoginPage';
import MyLoanRedirect from './components/pages/LoginPage/MyLoanRedirect';
import Logout from './components/pages/LoginPage/logout';
import CustodyCenterPage from './components/pages/CustodyCenterPage/CustodyCenterPage';
import ConsentsPage from './components/pages/ConsentsPage/ConsentsPage';
import DischargesPage from './components/pages/DischargesPage/DischargesPage';
import DisclaimerPage from './components/pages/DisclaimerPage/DisclaimerPage';
import FundingRequestPage from './components/pages/FundingRequestPage/FundingRequestPage';
import FundDiscussionPage from './components/pages/FundDiscussionPage/FundDiscussionPage';
import HomePage from './components/pages/HomePage/HomePage';
import InstructMSAPage from './components/pages/InstructMSA/InstructMSAPage';
import InsightsPage from './components/pages/InsightsPage/InsightsPage';
import NewLoansInstructionsPage from './components/pages/NewLoansInstructionsPage/NewLoansInstructionsPage';
import ResourcesPage from './components/pages/ResourcesPage/ResourcesPage';
import SearchPage from './components/pages/SearchPage/SearchPage';
import MatterPage from './components/pages/MatterPage/MatterPage';
import MatterDetailsPage from './components/pages/MatterDetailsPage/MatterDetailsPage';
import MatterEscalate from './components/pages/MatterEscalate/MatterEscalate';
import MyLoans from './components/pages/MyLoans/MyLoans';
import DischargeApplicationPage from './components/pages/DischargeApplicationPage/DischargeApplicationPage';
import CreateUserPage from './components/pages/UserCreatePage/UserCreatePage';
import PayoutUploadPage from './components/pages/PayoutUploadPage/PayoutUploadPage';
import UserDetailPage from './components/pages/UserDetailPage/UserDetailPage';

import './css/app.css';
import EscalateMatterForm from './components/organism/EscalateMatterForm/EscalateMatterForm';
import DischargesUploadPage from './components/pages/DischargesUploadPage/DischargesUploadPage';
import SSOLogin from './components/pages/LoginPage/ssologin';
import SSOLogout from './components/pages/LoginPage/ssologout';
import ErrorPage from './errorpage';
import SSOErrorPage from './ssoerrorpage';
import { AuthService } from './services/auth-service';
import NewLoanInstructionFormPage from './components/pages/NewLoanInstructionFormPage/NewLoanInstructionFormPage';
import NewLoanPage from './components/pages/NewLoanPage/NewLoanPage';
import EditInstructionPage from './components/pages/EditInstructionPage/EditInstructionPage';
import ConsentUploadPage from './components/pages/ConsentUploadPage/ConsentUploadPage';
// import FundingItemsPage from './components/pages/FundingItemsPage/FundingItemsPage';
import VariationUploadPage from './components/pages/VariationUploadPage/VariationUploadPage';


class App extends React.Component{
   
  constructor(props){
      super(props)
      this.state={
        pageUnderMaintenance: true,
        errorMessage: AuthService.getNetworkError()
      }
  }

  componentDidMount(){
      AuthService.getVersion().then(resp => {
        this.setState({
            versionNo: resp.data.VersionNo,
            //pageUnderMaintenance: true
        }, (err) => { 
            this.setState({
                errorMessage: err,
                //pageUnderMaintenance: false
            },()=>{
              //alert(err)
            });
        });
    },(err)=>{
      this.setState({
          errorMessage: err
      },()=>{
        //alert(err)
      })
      //this.red()
  });
    }

  displayError(e){ //
    console.log('file not found')
    this.setState({
      pageUnderMaintenance: false
    })
    
  }

  render(){
    let errorDisplay="";
    if(this.state.pageUnderMaintenance && !this.state.errorMessage){
      errorDisplay=(<iframe src={process.env.PUBLIC_URL + 'maintenancepage.html'} width="100%" height="850px" ></iframe> );
    }
    if(this.state.errorMessage){
     return( <div className="App">   
        <iframe src={process.env.PUBLIC_URL + 'maintenancepage.html'} width="100%" height="850px" ></iframe>
      </div>)
    }
    if(this.state.pageUnderMaintenance  && !this.state.errorMessage){
      return( <div className="App">      
      {/* <iframe src={process.env.PUBLIC_URL + 'maintenancepage.html'} onError={this.displayError()} width="100%" height="850px"></iframe> */}
      <img src={process.env.PUBLIC_URL + 'maintenancepage.png'} onError={(e)=>this.displayError(e)} width="100%" height="850px" alt="Error" style={{display:"none"}}></img>
      {errorDisplay}
      </div>)
    }else{
      return (
        <div className="App">      
          <Switch>  
              <Route path='/sso-login/:token/:userid/:ssourl' component={SSOLogin} />
              <Route path='/admincontrol' component={AdminControlPage} />
              <Route path='/custodycentre' component={CustodyCenterPage} />
              {/* <Route path='/fundingitems' component={FundingItemsPage} /> */}
              {/* <Route path='/disclaimer' component={DisclaimerPage} />  */}
              {/* <Route path='/discharge' component={DischargeApplicationPage} />  */}
              <Route path='/dashboard' component={HomePage} />   
              <Route path='/discharges' component={DischargesPage} />    
              <Route path='/consents' component={ConsentsPage} />      
              <Route path='/login' component={LoginPage} />
              <Route path='/dischargeinstructions' component={InstructMSAPage} />
              <Route path='/dischargesupload' component={DischargesUploadPage} />
              <Route path='/variationupload' component={VariationUploadPage} />
              <Route path='/consentsupload' component={ConsentUploadPage} />
              <Route path='/fundingrequest' component={FundingRequestPage} />
              {/* <Route path='/fundingdiscussion' component={FundDiscussionPage} /> */}
              <Route path='/fundingdiscussion:myKey' component={FundDiscussionPage} />
              <Route path='/insights' component={InsightsPage} />
              <Route path='/newloansinstructions' component={NewLoansInstructionsPage} />           
              {/* <Route path='/newloan' component={NewLoanPage} />            */}
              <Route path='/matter-details:myKey' component={MatterPage} />
              <Route path='/InstructionEdit:myKey' component={EditInstructionPage} />
              <Route path='/newloansinstructionsform1' component={NewLoanInstructionFormPage} />   
              <Route path='/newloansinstructionsform' component={NewLoanPage} />  
              {/* <Route path='/escalate-matter:token' component={ EscalateMatterForm } /> */}
              <Route path='/escalate-matter:token' component={ MatterEscalate } />
              <Route path='/matterdetails' component={MatterDetailsPage} />
              <Route path='/matterescalate' component={MatterEscalate} />
              <Route path='/my-loan:token/:usertype' component={MyLoans} />
              <Route path='/payoutupload' component={PayoutUploadPage} />
              <Route path='/resources' component={ResourcesPage} />  
              <Route path='/search' component={SearchPage} />  
              {/* <Route path='/useradmin' component={CreateUserPage} /> */}
              <Route path='/userdetails' component={UserDetailPage} />  
              <Route path='/userdetails:myKey' component={UserDetailPage} />
              {/* <Route path='/sso-login/:token/:userid/:ssourl' component={SSOLogin} /> */}
              {/* <Route path='/myloan-login:myKey' component={MyLoanLoginPage} /> */}
              <Route path='/myloan-redirect:myKey' component={MyLoanRedirect} />
              <Route path='/login:myKey' component={LoginPage} />
              {/* <Route path='/sso-relogin/:token/:userid/:ssourl' component={SSOReLogin} /> */}
              <Route path='/logout' component={Logout} />
              <Route path='/sso-logout' component={SSOLogout} />
              <Route path='/error' component={ErrorPage} />
              <Route path='/ssoerror' component={SSOErrorPage} />
              {/* <Route path='/disclaimer' component={DisclaimerPage} /> */}
              <Route path='/' component={LoginPage} />
          </Switch>
        </div>
      );
    }
  }
}
export default App;
