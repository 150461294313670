import React, { Component } from 'react'

import './css/FormTextBoxIcon.css';

class FormTextBoxIcon extends Component {
    render() {
        return (
            <div className="inputBox">
                <div 
                    className="FormTextBoxIcon"
                    style={{backgroundImage: `url("${this.props.icon}")`}}
                >
                    <input 
                        id={this.props.id}
                        name={this.props.name}
                        title={this.props.tooltip}
                        type={this.props.type} 
                        className={this.props.addCSS} 
                        placeholder={this.props.placeholder} 
                        value={this.props.value} 
                        onChange={this.props.onChange} 
                        disabled={this.props.disabled}
                        defaultValue={this.props.defaultValue}
                        autoComplete={this.props.autocomplete}
                        onKeyDown={this.props.onKeyDown}
                        >
                    </input>
                </div>
            </div>
        )
    }
}

export default FormTextBoxIcon
