import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
 
import IconBox from '../../atom/IconBox/IconBox'; 

 
import './css/ProgressStep.css';
import Moment from "moment";
import { MatterEventService } from '../../../services/matter-event-service';

class ProgressStep extends Component {
    constructor(props){
        super(props);

        this.state={
            //matterId: this.props.matterId,
            loading: true,
            eventList: [],
            shouldRedirect: false
        }        
    }

    componentDidMount() {
        this.setState({
          loading: true,
          eventList: []
        });
    
        MatterEventService.getMatterEvents(this.props.matterId).then(
        resp => {
          this.setState({
            eventList: resp.data.Content,
            loading: false
          });
        },
        err => {
          this.setState({
            eventList: [],
            loading: false,
            shouldRedirect: true,
            errorMessage: 'there is an error with the request'
          });
        }
      );
    }

    render() {
        let eventList = "";
        let eventDates = "";
        let eventStatus = "";
        let classname = "";
        //let eventLength = this.state.eventList.Items.length;

        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        if (this.state.loading) {
            //return <ProgressDisplay />;
          } else {
            // eventList = this.state.eventList.Items.map(e => (              
            //   <ProgressStep
            //     matterEvent={e}
            //     Status={this.state.eventList.MatterStatusTypeName}
            //   >
                
            //   </ProgressStep>
            // ));
             
            eventDates = this.state.eventList.Items.map(e => (
                //e.ProgressBarName
                <div className="spbDateItem">
                    <div>{e.CompletedDate == null? " " :  Moment(e.CompletedDate).format("DD MMM YYYY").toUpperCase()}</div>
                    <div>{e.CompletedDate == null? " " :  Moment(e.CompletedDate).format("hh:mm A")}</div>
                </div>                
            ));        

            eventStatus = this.state.eventList.Items.map((e,i) =>(        
                //e.ConpletedDate
                <div className="spbCircleItem"> 
                    <IconBox 
                        addCSS={e.StatusTypeId == 4 ? "doneIcon"  : e.StatusTypeId == 2 ? "currentIcon" : ""}
                        //addCSS={e.StatusTypeId == 4 ? "doneIcon"  : "spbCircleItem"}                        
                        iconURL={e.StatusTypeId == 4 ? "../../../../images/icons/OutlineWhite/Check.svg"  : e.StatusTypeId == 2 ? "../../../../images/icons/OutlineWhite/Exclamation.svg" : ""}
                        //iconURL={e.StatusTypeId == 4 ? "../../../../images/icons/OutlineWhite/Check.svg" : ""}
                    />
                        <div className={this.state.eventList.Items.length === i+1 ? "" : e.StatusTypeId == 4 ? "spbLineDone" : "spbLine"}></div> 
                       {/* {alert(e.length)} */}
                </div>                
            ));
               
            
            // eventStatus = this.state.eventList.Items.map((e,i) => (
            //     //e.ConpletedDate
            //     <div className="spbCircleItem"> 
            //         <IconBox 
            //             addCSS={e.StatusTypeId == 4 ? "doneIcon"  : "currentIcon"}
            //             iconURL={e.StatusTypeId == 4 ? "../../../../images/icons/OutlineWhite/Check.svg" : "../../../../images/icons/OutlineWhite/Exclamation.svg"}
            //         />
            //             <div className={e.StatusTypeId == 4 ? "spbLineDone" : classname}></div> 
            //     </div>
            // ));
            eventList = this.state.eventList.Items.map(e => (
                <div className={e.StatusTypeId == 4 ? "doneTxt" : e.StatusTypeId == 2 ? "currentTxt" : ""}>{e.ProgressBarName}</div>
            ));
        }
        return ( 
            <div className="ProgressStep"> 
                <div className="spbDateBox">
                    {eventDates}                    
                </div>

                <div className="spbCirclesBox">
                    {eventStatus}                    
                </div>
                
                <div className="spbTitleBox">
                    {eventList}                    
                </div>
            </div>
        )
    }
}

export default ProgressStep
