import React, { Component } from 'react'

 
import IconBox from '../../atom/IconBox/IconBox';

import './css/IconLabelBox.css';

class IconLabelBox extends Component {
 
    render() {
        return (
            <div
                id={this.props.id}
                className={"IconLabelBox "+this.props.addCSS}
                onClick={this.props.clickAction} 
            >
                {this.props.children}
                <div className="ilbContent" >
                    <div>
                        <IconBox iconURL={this.props.iconURL} />
                    </div>
                    <div>
                        {this.props.ilbLabel}
                    </div>
                </div>
            </div>
        )
    }
}

export default IconLabelBox
