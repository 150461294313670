import React from 'react';
import './css/UserCreatePage.css'
import CenterContent from '../../template/CenterContent/CenterContent';
import Card from '../../atom/Card/Card';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';
import Button from '../../atom/Button/Button';
import RadioButtonGroup from '../../molecule/RadioButtonGroup/RadioButtonGroup';
import RadioButton from '../../atom/RadioButon/RadioButon';
import Label from '../../atom/Label/Label';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import CheckBox from '../../atom/CheckBox/CheckBox';
// import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MatterService } from "../../../services/matter-service";
import { UserLender } from "../../../services/user-lender";
import DropDownList from '../../atom/DropDownList/DropDownList';
import ValidationText from '../../atom/ValidationText/ValidationText';

class UserCreatePage extends React.Component{
    constructor(props){
            super(props);
            this.state={
                active: true,
                inactive: null,
                selectedoption: "active",
                // userprivileges: [],
                // checkedAll: false,
                // reststates: [],
                // isChecked: false,
                // hasstaterestriction: false,
                emailasusername: false,
            username: '',
            email: '',
            enabled: true,
            disabled: false,
            lastname: '',
            firstname: '',
            stateid: '',
            shouldRedirect: false,
            errorMessage: '',
            states: [],
            ustate: '',
            hasstaterestriction: false,
            matterTypes: [],
            matterStatuses: [],
            matterStates: [],     
            items:[],
            irene: [],
            reststates: [],
            selecttedstates: [],
            selectedmattertypes: [],
            selectedprivileges: [],          
            checked : [],
            staterestrictions: [],
            checkedAll: false,
            hasmattertyperestriction : false,
            mattertyperestrictions : [],
            checkedAllType: false,
            userprivileges: [],
            seluserprivileges : [],
           
            usernameError: "",
            lastnameError: "",
            stateidError: "",
            newLenderUserId: "",
            stateView: [],            
            className: 'formTextBox',
            validFirstname: false,
            validLastname: false,
            validEmail: false,
            validPhone: false,
            validMobile: false,
            validFax: false,
            firstnameValidationError: null,
            lastnameValidationError: null,
            emailValidationError: null,
            phoneValidationError: null,
            mobileValidationError: null,
            faxValidationError: null,
            errorCounter: 0,
            //firstnameValidationError: null,
            }
            this.onValueChange = this.onValueChange.bind(this);
           // this.cancelChange = this.cancelChange.bind(this);
            this.EmailAsUsernameChanged = this.EmailAsUsernameChanged.bind(this);
            this.usernameChanged = this.usernameChanged.bind(this);
            this.emailChanged = this.emailChanged.bind(this);
            // this.enabledChanged = this.enabledChanged.bind(this);
            this.firstnameChanged = this.firstnameChanged.bind(this);
           // this.isFirstnameValid = this.isFirstnameValid.bind(this);
            this.lastnameChanged = this.lastnameChanged.bind(this);
            //this.isLastnameValid = this.isLastnameValid.bind(this);
            
            // this.notesChanged = this.notesChanged.bind(this);
            this.phoneChanged = this.phoneChanged.bind(this);
            //this.isPhoneValid = this.isPhoneValid.bind(this);
            this.mobileChanged = this.mobileChanged.bind(this);
            //this.isMobileValid = this.isMobileValid.bind(this);
            this.faxChanged = this.faxChanged.bind(this);
           // this.isFaxValid = this.isFaxValid.bind(this);
            this.stateChanged = this.stateChanged.bind(this);
            this.stateRestrictionChanged = this.stateRestrictionChanged.bind(this);
            this.SaveUserHandler = this.SaveUserHandler.bind(this);        
    }

    // onUpdateActive(e){
    //     alert("hello");
    //     this.setState({
    //         active: e?true:null,
    //         inactive: e?null:true,
    //     });
    // }

    componentDidMount() {       
        this.loadLookupLists();        
    }

    loadLookupLists() {
        let self = this;
    
        //----let p1 = MatterService.getMatterTypeGroupList();
        let p1 = UserLender.getMatterTypeList();
        let p2 = MatterService.getMatterStatusList();
        let p3 = MatterService.getStateList();
        let p4 = UserLender.getExternalPrivListForUser();
        let p5 = MatterService.getStateViewList();

        //----let p3 = UserLender.getStateListForUser();
        /* ---let p5 = MatterService.getLenderList();
        let---- p6 = MatterService.getMortMgrList();
        let ----p7 = MatterService.GetBrokerList(); */
        
        // Promise.all([p1, p2, p3, p4]).then(values => {
            Promise.all([p1,p2,p3,p4,p5]).then(values => {
          let matterTypes = values[0].data;
        //   matterTypes.splice(0, 0);   
          
          let statuses = values[1].data;
          statuses.splice(0, 0);   
          //statuses.splice(0, 0, AllOption);
    
          let resstates = values[2].data;    
          resstates.splice(0, 0);   

         let userprivileges = values[3].data;

         let stateView = values[4].data;
          
          /* let lenders = values[4].data;
    
          let mortManagers = values[5].data;
    
          let brokers = values[6].data;
          brokers.splice(0,0,AllOption); */
          this.setState({
            //set values
            matterTypes: matterTypes,
            //matterType: matterType,
           matterStatuses: statuses,
           // matterStatus: matterStatus,
            states: resstates,//states,
            reststates: values[2].data,//resstates    
            hasstaterestriction: true,  
            userprivileges: userprivileges  ,
            //matterState: matterState,
            stateView: stateView
            
            /* lenders: lenders,
            lender: lenders[0],
            mortManagers: mortManagers,
            mortManager: mortManagers[0],
            brokers:brokers,
            broker:brokers[0], */
          },()=>{
              console.log("states"+this.state.reststates+"userstates:" + this.state.states);
            //   this.initialAllStateChecked();
            //   this.initialAllTypeChecked();
            });
          
        });       
      } 

    
    

    onValueChange(e){ 
        //alert("hello");
        this.setState({
            selectedoption:e.target.value
        },()=>{

       //alert("selected" + this.state.selectedoption )
       if( this.state.selectedoption =="yes"){
        this.setState({
            enabled: true,
            disabled: false
        })
       }
       else{
        this.setState({
            enabled: false,
            disabled: true
        })
       }
        });
    }

    stateRestrictionChanged = (event) =>{
        this.setState({
                hasstaterestriction: !this.state.hasstaterestriction
            }
        ,()=>{this.initialAllStateChecked()}
            );    
        
        console.log(this.state.hasstaterestriction)

    }

    handleAllStateChecked= (event) => {
        let states = this.state.reststates
        const { checked } = this.state;
        let newArr = [];
        states.forEach(stat => {
            stat.isChecked = event.target.checked
            
            if(stat.isChecked){             
                newArr.push(stat.id);           
            }
        }
        ) 
        this.setState(
            {staterestrictions: newArr,
                checkedAll: !this.state.checkedAll,
                hasstaterestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{console.log('updated state', newArr)}
        )        
      }

      handleCheckChildElement = (event) => {
        let states = this.state.reststates
        const { staterestrictions } = this.state;
        let newArr = [];
        var index = event.target.getAttribute('id')
        states.forEach(stat => {
           if (stat.value === event.target.value){             
            stat.isChecked =  event.target.checked
              if(stat.isChecked){
                newArr = [...staterestrictions, stat.id];
              }          
              else {
                newArr =  staterestrictions.filter(item => item != stat.id);
              }
           }  
        }) 
        this.setState(
            {staterestrictions: newArr,
                //checkedAll: false
                checkedAll: states.length == newArr.length?true:false,
                hasstaterestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{console.log('updated state', newArr, 'index', index)}
        )
      } 
      
      handleAllTypesChecked= (event) => {
        let types = this.state.matterTypes
        const { checked } = this.state;
        let newArr = [];
        types.forEach(type => {
            type.isChecked = event.target.checked
            
            if(type.isChecked){             
                newArr.push(type.id);           
            }
        }
        ) 
        this.setState(
            {mattertyperestrictions : newArr,
                checkedAllType: !this.state.checkedAllType,
                hasmattertyperestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{console.log('updated types', newArr)}
        )        
      }

      handleCheckTypeChildElement = (event) => {
        let types = this.state.matterTypes
        const { mattertyperestrictions  } = this.state;
        let newArr = [];
        var index = event.target.getAttribute('id')
        types.forEach(type => {
           if (type.value === event.target.value){             
            type.isChecked =  event.target.checked
              if(type.isChecked){
                newArr = [...mattertyperestrictions , type.id];
              }          
              else {
                newArr =  mattertyperestrictions .filter(item => item != type.id);
              }
           }  
        }) 
        this.setState(
            {mattertyperestrictions : newArr,
                //checkedAllType: false
                checkedAllType: types.length == newArr.length?true:false,
                hasmattertyperestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{
                //alert(this.state.checkedAllType);
                console.log('updated types', newArr, 'index', index)}
        )
      }  

    handleCheckPrivElement= (event) => {
        let privs = this.state.userprivileges
        const { seluserprivileges } = this.state;
        let newArr = [];
        var index = event.target.getAttribute('id')
        privs.forEach(priv => {
           if (priv.value === event.target.value){             
            priv.isChecked =  event.target.checked
              if(priv.isChecked){
                newArr = [...seluserprivileges, priv.id];
              }          
              else {
                newArr =  seluserprivileges.filter(item => item != priv.id);
              }
           }  
        }) 
        this.setState(
            {seluserprivileges: newArr,
                checkedAll: false
            }
            , ()=>{console.log('updated priv', newArr, 'index', index)}
        )
      } 
      
      stateChanged(event) {
          //alert( event.target.value.id);
        // this.setState({
        //   ustate: event.target.value,
        //   stateid: event.target.value.id
        // });
        // console.log(event.target.value.id)
        let stateidError = "";
        this.setState({
            ustate: event.target.value,
            stateid: event.target.value,//event.target.value.id
        },()=>{
            //alert(this.state.ustate + " " + this.state.stateid)
        });
        if(event.target.value.id == 0){
            stateidError = "Please select State";
            this.setState(
                {stateidError: stateidError}
            );
        }
        else{
            stateidError = "";
            this.setState(
                {stateidError: stateidError}
            );
        }

        if(stateidError){
            this.setState({stateidError},()=>{console.log(stateidError)});
            return false;
        }
        

        // return true;
        //alert(event.value)
    }

    firstnameChanged(event) {
        let errorCounter = this.state;
        this.setState({
            firstname: event.target.value,
            firstnameValidationError: ''
        },()=>{
            //alert(this.state.firstname.length);
            //this.isFirstnameValid(this.state.firstname);
            this.setState({
                validFirstname: this.state.firstname.length>0?true:false,
           },()=>{
                if(!this.state.validFirstname){
                    this.setState({
                        firstnameValidationError: 'Please enter given name',
                        errorCounter: errorCounter + 1
                    });
                }
                else{
                    this.setState({
                        firstnameValidationError: '',
                        errorCounter: 0
                    });
                }
            });  
           
       });
    }

    lastnameChanged(event) {
        this.setState({
            lastname: event.target.value,
            lastnameValidationError: ''
        },()=>{
            //this.isLastnameValid(this.state.lastname);
            this.setState({
                validLastname: this.state.lastname.length>0?true:false
            },()=>{
                if(!this.state.validLastname){
                    this.setState({
                        lastnameValidationError: 'Please enter surname',
                    });
                }
            });
        });
    }

    // isFirstnameValid(event){
    //     this.setState({
    //         validFirstname: event.length>0?true:false
    //     });        

    // }

    // isLastnameValid(event){
    //     this.setState({
    //         validLastname: event.length>0?true:false
    //     });        

    // }

    isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidNumber(number){
        ///^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        const re =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        return re.test(String(number).toLowerCase());
    }

    emailChanged(event) {
        let emailValidationError = "";
        this.setState({
            email: event.target.value
        },()=>{
            if(this.state.emailasusername){
                this.setState({
                    username: this.state.email
                })
            }
        });
        if(event.target.value.trim() == ""){
            emailValidationError = "Please fill out email field";
            this.setState(
                {emailValidationError: emailValidationError}
            );
        }
        else if(!this.isValidEmail(event.target.value)){
            emailValidationError = "Invalid email";
            this.setState(
                {emailValidationError: emailValidationError}
            );
        }
        else{
            emailValidationError = "";
            this.setState(
                {emailValidationError: emailValidationError}
            );
        }

        if(emailValidationError){
            this.setState({emailValidationError},()=>{console.log(emailValidationError)});
            return false;
        }
        

        return true;
        //alert(event.value)
        
    }

    EmailAsUsernameChanged(event){
        const email = this.state
        //alert("dsfgdfg");
        this.setState(initialState=>
            ({
                emailasusername: !initialState.emailasusername
            }),()=>{
                //alert(this.state.emailasusername)
                if(this.state.emailasusername){
                    this.setState({
                        username: this.state.email
                    })
                }
                else {
                    this.setState({
                        username: ''
                    })
                }
            });
        //alert(this.state.emailasusername)
        console.log(this.state.emailasusername)   
    }

    usernameChanged(event) {
        let usernameError = "";
        if(this.state.emailasusername){
            this.setState({
                username: this.state.email
            });
        }
        else{
            this.setState({
                username: event.target.value
            });
        }
       
        // if(event.target.value.trim() == ""){
        //     usernameError = "Please fill out username field";
        //     this.setState(
        //         {usernameError: usernameError}
        //     );
        // }
        // else{
        //     usernameError = "";
        //     this.setState(
        //         {usernameError: usernameError}
        //     );
        // }

        // if(usernameError){
        //     this.setState({usernameError},()=>{console.log(usernameError)});
        //     return false;
        // }
        

        // return true;
        //alert(event.value)
    }

    phoneChanged(event) {
        this.setState({
            phone: event.target.value
        },()=>{
            const {phone} = this.state
            if(phone.length > 0 && this.isValidNumber(phone)){
                this.setState({
                    validPhone: true,//this.state.phone.length>0?true:false
                }); 
            }
            else{                
                this.setState({
                    validPhone: false
                });                
            }
            
        });
    }
    

    mobileChanged(event) {
        this.setState({
            mobile: event.target.value
        },()=>{
            const {mobile} = this.state
            if(mobile.length > 0 && this.isValidNumber(mobile)){
                this.setState({
                    validMobile: true,
                }); 
            }
            else{                
                this.setState({
                    validMobile: false
                });                
            }
            
        });
    }

    faxChanged(event) {
        this.setState({
            fax: event.target.value
        },()=>{
            const {fax} = this.state
            if(fax.length > 0 && this.isValidNumber(fax)){
                this.setState({
                    validFax: true,
                }); 
            }
            else{                
                this.setState({
                    validFax: false
                });                
            }
            
        });
    }

    SaveUserHandler(event){
        //ADD VALIDATION HERE
        //"Email is required."
        event.preventDefault();
        if(this.state.errorCounter > 0) {alert("there is an error"); return;}
        // const isValid = this.validateInput();
        // if (isValid){
        
         let self = this.state;
         let jstaterestrictions = JSON.stringify(self.staterestrictions);
         let jmattertyperestrictions = JSON.stringify(self.mattertyperestrictions);
         let jseluserprivileges = JSON.stringify(self.seluserprivileges);
 
             let userinfo = {
             //userid: self.props.matterId,
             username: self.emailasusername == true ? self.email : self.username,
             email: self.email,
             lastname: self.lastname,
             firstname: self.firstname,
             stateid: self.stateid,
             usertypeid: self.usertypeid,
             lenderid: self.lenderid,
             mortmgrid: self.mortmgrid,
             brokerid: self.brokerid,
             relmgrid: self.relmgrid,
             notes: self.notes,
             phone: self.phone,
             mobile : self.mobile,
             fax : self.fax,
             emailasusername: self.emailasusername,
             enabled: self.enabled,
             hasstaterestriction: self.hasstaterestriction,
             //staterestrictions: self.staterestrictions,
             staterestrictions : jstaterestrictions,
             hasmattertyperestriction : self.hasmattertyperestriction,
             //mattertyperestrictions : self.mattertyperestrictions,
             mattertyperestrictions : jmattertyperestrictions,
             userprivileges : jseluserprivileges,//self.seluserprivileges,//self.userprivileges,
             //noteTypeId: this.state.isPrivateNote ? 9 : null,
             };
 
             UserLender.CreateUpdateLenderUser(userinfo).then((resp)=>{
                 alert("User successfully added!");
                 //alert(resp.data.UserTypeId);                
                //  window.open("/view-update-user:" + resp.data.UserTypeId);
                //  window.close();
             },(err)=>{
                 //alert(err);
                 alert("There is an error with the request");
             });
            // UserLender.CreateUpdateLenderUser(userinfo).then()
         //}
         
     }


    render(){
        let StateRestictionChekboxes, MatterTypeRestictionChekboxes, DisplayUsername ;
        
        StateRestictionChekboxes= (       
            <div>
                {
                    this.state.reststates.map((rstates) => {
                        return (<CheckBox className="chkStates" handleCheckChildElement={this.handleCheckChildElement}  {...rstates} />)
                    })
                }                
            </div>
        );           
       
        MatterTypeRestictionChekboxes= (       
            <div>
                {
                    this.state.matterTypes.map((types) => {
                        return (<CheckBox className="chkMatterType" handleCheckChildElement={this.handleCheckTypeChildElement}  {...types} />)
                    })
                }                
            </div>
        );
        

        return ( <CenterContent id="UserCreatePage">
        <MarginGuide />    
        <div className="userContent">
       
            <h4>Irene</h4>
            <div class="switch-field" onChange={this.onValueChange}>
                <input type="radio" id="radio-one" name="switch-one" value="yes" checked={this.state.enabled}/>
                <label for="radio-one">Active</label>
                <input type="radio" id="radio-two" name="switch-one" value="no" checked={this.state.disabled}/>
                <label for="radio-two">Inactive</label>
            </div>
            {/* <div className="switch-field" onClick={this.onValueChange} >
                <RadioButton value="yes" name="IsActive" checked={this.state.selectedoption ==="active"} onChange={this.onValueChange}  for="Active"/>                              
                <RadioButton value="nono" name="IsActive" checked={this.state.selectedoption ==="inactive"} onChange={this.onValueChange}  for="Inactive"/>
            </div> */}

            {/* <div className="switch-field"  >
                <RadioButton id="active" value="active" name="IsActive" checked  for="Active"/>                              
                <RadioButton id="inactive" value="inactive" name="IsActive"  for="Inactive"/>
            </div> */}

            {/* <div onChange={this.onValueChange} >
                <RadioButton value="active" name="IsActive" checked={this.state.selectedoption==="active"} onChange={this.onValueChange}  for="Active"/>                              
                <RadioButton value="inactive" name="IsActive" checked={this.state.selectedoption==="inactive"} onChange={this.onValueChange}  for="Inactive"/>
            </div> */}
            <Card>
                <h6>Personal Details</h6>
                
                <div className="row"><Label>Name</Label></div>
                <div className="row">
                    <div className="column">
                        <FormTextBox type="text" className={this.state.validFirstname==true?'successTextBox':this.state.firstnameValidationError?'errorTextBox':this.state.className} placeholder="Given Name" onChange={this.firstnameChanged} />
                        <ValidationText>{this.state.firstnameValidationError}</ValidationText>
                    </div>
                    <div className="column">
                        <FormTextBox type="text" className={this.state.validLastname==true?'successTextBox':this.state.lastnameValidationError?'errorTextBox':this.state.className} placeholder="Surname" onChange={this.lastnameChanged}  />
                        <ValidationText>{this.state.lastnameValidationError}</ValidationText>
                    </div>
                </div>
                
                <div className="row"><Label>Email</Label></div>  
                <div className="row">
                    <FormTextBox type="text" className={this.state.validEmail==true?'successTextBox':this.state.emailValidationError?"successTextBox" :"formTextBox"} placeholder="example@email.com" onChange={this.emailChanged}  />
                </div>     
                <div className="row"><Label>Username</Label></div>
                <div className="row">
                    <div className="column">
                        {/* <FormTextBox type="text" className="formTextBox" placeholder="Enter chosen username" defaultValue={this.state.username} /> */}
                        <FormTextBox type="text" className="formTextBox" placeholder={this.state.username?this.state.username:"Enter chosen username"} onChange={this.usernameChanged} value={this.state.username} disabled={this.state.emailasusername}/>
                    </div>
                    <div className="column">
                        {/* <input type="checkbox" id="username" />
                        <Label>&nbsp;Use email as username</Label> */}
                        <CheckBox handleCheckChildElement={this.EmailAsUsernameChanged} checked={this.state.emailasusername} value=" Use email as the username" />
                   </div>
                </div>         
                <div className="row"><Label>Contact Information</Label></div>
                <div className="row">
                    <div className="column">
                        <FormTextBox type="text" className={this.state.validPhone?"successTextBox" :"formTextBox"} placeholder="Phone" onChange={this.phoneChanged} />
                    </div>
                    <div className="column">
                        <FormTextBox type="text" className={this.state.validMobile?"successTextBox" :"formTextBox"} placeholder="Mobile" onChange={this.mobileChanged} />
                    </div>
                </div>  
                <div className="row">
                    <div className="column">
                        <FormTextBox type="text" className={this.state.validFax?"successTextBox" :"formTextBox"} placeholder="Fax" onChange={this.faxChanged} />
                    </div>
                    <div className="column">                       
                    </div>
                </div>   
                <div className="row"><Label>Time Zone</Label></div>
                <div className="row">
                {/* <select>
                    {this.state.stateView.map((s)=><option key={s.StateId}>{s.StateDesc}</option>)}
                </select> */}
                <DropDownList options={this.state.stateView} value={this.state.ustate} onChange={this.stateChanged}>
                
                </DropDownList>
               {/* <select>
                   <option>sdfsdfsdfsdf</option>
                   <option>sdfsdfsdfsdf</option>
               </select> */}
                {/* <DropDownList
                    //className="k-dropdownlist"
                    options={this.state.states}
                    //textField={"value"}
                    // value={this.state.ustate}
                    // onChange={this.stateChanged}
                    //className="text"
                /> */}
                </div>   
                <h6>User Controls</h6>
                <div className="row"><Label>Select the states this user can access:</Label></div>
                <div>
                {/* <input type="checkbox" className="input-check" name="hasstaterestriction" checked={this.state.hasstaterestriction} onChange={this.stateRestrictionChanged}></input> */}
                {/* <input type="checkbox" onClick={this.handleAllStateChecked} checked={this.state.checkedAll} value="checkedall" />All states */}
                <CheckBox handleCheckChildElement={this.handleAllStateChecked}  isChecked={this.state.checkedAll} value="All states"  />
                </div>
                <div className="divStates">
                {StateRestictionChekboxes}
                {/* {
                    this.state.userprivileges.map((privs) => {                                   
                    return (<CheckBox handleCheckChildElement={this.handleCheckPrivElement}  {...privs} />)
                    })
                } */}
                </div>
                <div className="row"><Label>Choose access areas:</Label></div>
                <div>
                {/* <input type="checkbox" className="input-check" name="hasstaterestriction" checked={this.state.hasstaterestriction} onChange={this.stateRestrictionChanged}></input> */}
                {/* <input type="checkbox" onClick={this.handleAllStateChecked} checked={this.state.checkedAll} value="checkedall" />All states */}
                <CheckBox handleCheckChildElement={this.handleAllTypesChecked}  isChecked={this.state.checkedAllType} name="checkedAllType" value="All matters"  />
                {/* <input type="checkbox" onClick={this.handleAllTypesChecked} checked={this.state.handleAllTypesChecked} value="checkedAllType" />All  */}
                </div>
                <div className="divMatterTypes">
                {MatterTypeRestictionChekboxes}
                {/* {
                    this.state.userprivileges.map((privs) => {                                   
                    return (<CheckBox handleCheckChildElement={this.handleCheckPrivElement}  {...privs} />)
                    })
                } */}
                </div>
                <div className="row"><Label>Choose user permissions:</Label></div>
                <div className="divMatterTypes">
                {
                    this.state.userprivileges.map((privs) => {                                   
                    return (<CheckBox className="chkPermission" handleCheckChildElement={this.handleCheckPrivElement}  {...privs} />)
                    })
                }
                </div>
                {/* <FormTextBox type="text" className="formTextBox" placeholder="example@email.com"  /> */}
                {/* <Button onClick={ (e)=>this.handleSendCode(e)}>Change Password</Button> */}
            </Card> 
            <div className="divButtons">
                <Button className="btnCancel">Cancel</Button>
                <Button onClick={ this.SaveUserHandler}>Save Changes</Button>
            </div>
           
           
        </div>
    </CenterContent>  );
    }


}

export default UserCreatePage