import React, { Component } from 'react'

import './css/FormCheckBox.css';

class FormCheckBox extends Component {
    render() {
        let $type=this.props.type;
        return (  
            <div className="FormCheckBox" >
                <input type={$type==""||$type==null?"checkbox":$type} checked={this.props.checked} onChange={this.props.onChange} onClick={this.props.onClick}/>
                <div>{this.props.title}</div>
            </div>
        )
    }
}

export default FormCheckBox
