import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom'
import { Redirect } from 'react-router-dom';

import DataListCard from '../../atom/DataListCard/DataListCard';
import GroupBox from '../../atom/GroupBox/GroupBox';
import LabelCount from '../../atom/LabelCount/LabelCount';

import { DashboardService } from '../../../services/dashboard-service';


import './css/LoanStatus.css';
import SearchPage from '../../pages/SearchPage/SearchPage';
import { AuthService } from '../../../services/auth-service';


const storeSettlingToday = "SettlingToday"
const storeSettledToday = "SettledToday"
const storeLoansInProgress = "InstructionsReceived"
const storeInstructionsReceived = "LoansOnHold"
const storeDocsSent = "DocsSent"
const storeDocsReturned = "DocsReturned"
const storeDocsVerified = "DocsVerified"
const storeOutstandingRequirements = "OutstandingReq"
const storeLoansOnHold = "SettlementBooked"
const storeLoansToExpire = "LoansToExpire"
const timestamp = "TimeStamp"
const storeSettlementBooked = "SettlementBooked"

const LoansInProgressReceivedStatus = {
   id: 1,
   value: 'Loans in Progress'
};

const InstructionsReceivedStatus = {
    id: 2,
    value: 'Instructions Received'
};

const LoansOnHoldStatus = {
    id: 3,
    value: 'Loans On Hold'
};

const DocsSentStatus = {
    id: 4,
    value: 'Docs Sent'
};

const DocsReturnedStatus = {
    id: 5,
    value: 'Docs Returned'
};

const DocsVerifiedStatus = {
    id: 6,
    value: 'Docs Verified'
};

const OutstandingReqStatus = {
    id: 7,
    value: 'Outstanding Requirements'
};
const ReadyToBookStatus = {
    id: 8,
    value: 'Ready To Book'
};

const SettlementBookedStatus = {
    id: 9,
    value: 'Settlement Booked'
};

const LoansToExpireStatus = {
    id: 10,
    value: 'Loans about to Expire'
};

const SettledStatus = {
    id: 11,
    value: 'Settled'
};

const SettledToday = {
    id: 14,
    value: 'Settled Today',
};

const SettlingToday = {
    id: 15,
    value: 'Settling Today',
};

const PacketReceived = {
    id: 16,
    value: 'Security Packets Received',
};

const PayoutProvided = {
    id: 17,
    value: 'Payout Provided',
};

const Registered = {
    id: 18,
    value: 'Registered',
};
const TitleNomination = {
    id: 21,
    value: 'Title Nomination',
};
const Lodged = {
    id: 22,
    value: 'Lodged',
};
const ProvisionalHoldStatus = {
    id: 24,
    value: 'Provisional Hold'
};


const newLoans = {
    id: 1,
    value: 'New Loan',
};
const discharges = {
    id: 2,
    value: 'Discharges',
}
const consents = {
    id: 3,
    value: 'Consents',
}

const options = {
    legend: {
        "position": "bottom"
    },
    "cutoutPercentage": "80"
}

class LoanStatus extends Component {
    static contextTypes = {
        router: PropTypes.object
    }


    constructor(props) {
        super(props);
        let userprivileges = AuthService.getUserExternalPrivilegesMenu();
        let userdetails = AuthService.getUserDetails();
        this.state = {
            loading: false,
            discharges: false,
            // SettlingToday: 0,
            // SettledToday: 0,
            //LoansInProgress: 0,
            // LoansInProgress: 0,
            // InstructionsReceived: 0,
            // LoansOnHold: 0,
            // DocsSent: 0,
            // DocsReturned: 0,
            // DocsVerified: 0,
            // OutstandingReq: 0,
            // SettlementBooked: 0,
            // ReadyToBook:0,
            // LoansToExpire: 0,
            SettlementCount: 0,
            ActionRequiredCount: 0,
            DischargesSettlementCount: 0,
            DischargesActionRequiredCount: 0,
            DischargesLoansInProgressCount: 0,
            PacketReceived: 0,
            PayoutProvided: 0,
            TimeStamp: null,
            lastUpdated: null,
            LoansInProgress: 0,
            InstructionsReceived: 0,
            ProvisionalHold: null,
            LoansOnHold: 0,
            DocsSent: 0,
            DocsReturned: 0,
            DocsVerified: 0,
            OutstandingReq: 0,
            SettlementBooked: 0,
            LoansToExpire: 0,
            TotalOutstanding: 0,
            SettledToday: 0,
            SettlingToday: 0,
            PacketReceived: 0,
            ReadyToBook: 0,
            PayoutProvided: 0,
            discharges: false,
            shouldRedirect: false,
            width: 0,
            height: 0,
            currUrl: '',
            errorMessage: null,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            path: '/search',
            arg: 0,
            sarg: 1,
            SettledTodayTotalAmount: 0,
            SettlingTodayTotalAmount: 0,
            CanViewTotalLoanAmount: false,
            userprivileges: userprivileges,
            userdetails: userdetails,
            canuserviewtotalamount: false,
            shouldRedirect: false,
            consents: false,
            TitleNomination: 0,
            Lodged: 0,
            Registered: 0,
        }
        this.setLocalStorage = this.setLocalStorage.bind(this);
        //this.handleCountClicked = this.handleCountClicked.bind(this);
    }

    nextPath(path, arg, sArg) {
        this.context.router.history.push({
            pathname: path,
            state: {
                searchArg: arg,
                loanType: sArg
            }
        })
    }



    // handleCountClicked(path, arg, sArg) {
    //     this.setState({
    //         shouldRedirect: true,
    //         path: path,
    //         arg: arg,
    //         sarg: sArg
    //     });
    // }

    updateDimensions() {
        let update_width = 800;
        let update_height = 500;

        if (window.innerHeight > 530) {
            update_height = window.innerHeight - 30;
        }

        if (window.innerWidth > 900) {
            update_width = window.innerWidth - 100;
        }

        this.setState({
            width: update_width,
            height: update_height
        }, () => {
            this.mySecretFunction()
        });
    }

    mySecretFunction() {
        // if (this.state.width < 1024) {
        //     document.getElementById('home').style.overflowY = 'auto'
        // }
        // if (this.state.width >= 1024) {
        //     document.getElementById('home').style.overflow = 'hidden'
        // }
    }
    setLocalStorage() {
        // this.setState({
        //     SettlingToday: SettlingToday,
        //     SettledToday: SettledToday,
        //     LoansInProgress: TotalLoanCount,
        //     InstructionsReceived: InstructionsReceivedCount,
        //     LoansOnHold: OnHoldCount,
        //     SettlementBooked: filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount,
        //     PayoutProvided:PayoutProvided,
        //     data: {
        //         labels: ["Ongoing", "Actions Required"],
        //         datasets: [{
        //             backgroundColor: ['#5879b6', '#FF9400'],
        //             data: [TotalLoanCount - TottalOutstanding, TottalOutstanding],
        //         }]
        //     },
        //     loading: false
        // });


    }
    hydrateFilterWithLocalStorage() {
        for (let key in this) {
            if (localStorage.hasOwnProperty(key)) {
                let value = localStorage.getItem(key)

                this.setState({
                    [key]: value
                })
            }
        }
    }

    componentDidUpdate() {
        if (this.state.currUrl === window.location.pathname) {

        } else {
            let dischargeCheck = false;
            let consentsCheck = false;

            if (window.location.pathname === '/discharges') {
                dischargeCheck = true;
            }
            if (window.location.pathname === '/consents') {
                consentsCheck = true;
            }
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
                discharges: dischargeCheck,
                consents: consentsCheck,
                currUrl: window.location.pathname,

            }, () => {
                this.mySecretFunction()
            });
            document.title = dischargeCheck? "Loantrak - Discharges" : consentsCheck? "Loantrak - Consents" : "Loantrak - New Loans";

            this.setState({
                loading: true,
            });
            if (dischargeCheck) {
                DashboardService.getFilterCountDischarges().then((resp) => {
                    let filterNumber = resp.data.Content;

                    let OnHoldCount = filterNumber.filter(obj => { return obj.FilterType === "OnHold" })[0].FilterCount;
                    let InstructionsReceivedCount = filterNumber.filter(obj => { return obj.FilterType === "InstrReceived" })[0].FilterCount;
                    let SettlingToday = filterNumber.filter(obj => { return obj.FilterType === "SettlingToday" })[0].FilterCount
                    let SettlementBooked = filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount;
                    let SettledToday = filterNumber.filter(obj => { return obj.FilterType === "SettledToday" })[0].FilterCount;
                    let PacketReceived = filterNumber.filter(obj => { return obj.FilterType === "PacketReceived" })[0].FilterCount;
                    let PayoutProvided = filterNumber.filter(obj => { return obj.FilterType === "PayoutProvided" })[0].FilterCount;
                    let TotalLoanCount = InstructionsReceivedCount + SettlementBooked + PacketReceived + PayoutProvided;
                    let TottalOutstanding = OnHoldCount;
                    let LoansInProgress = filterNumber.filter(obj => { return obj.FilterType === "InProgress" })[0].FilterCount;
                    this.setState({
                        SettlingToday: SettlingToday,
                        SettledToday: SettledToday,
                        //LoansInProgress: TotalLoanCount,
                        LoansInProgress: LoansInProgress,
                        InstructionsReceived: InstructionsReceivedCount,
                        LoansOnHold: OnHoldCount,
                        PacketReceived: PacketReceived,
                        SettlementBooked: filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount,
                        PayoutProvided: PayoutProvided,
                        PacketReceived: PacketReceived,
                        data: {
                            labels: ["Actions Required", "Ongoing"],
                            datasets: [{
                                backgroundColor: ['#FF9400', '#5879b6'],
                                data: [TottalOutstanding, TotalLoanCount - TottalOutstanding],
                            }]
                        },
                        loading: false
                    });

                }, err => {
                    if (err.response && err.response.status === 401) {
                        this.setState({

                            loading: false,
                            shouldRedirect: true,
                            errorMessage: 'there is an error with the request'
                        });

                    } else {

                        this.setState({
                            SettlingToday: 0,
                            SettledToday: 0,
                            LoansInProgress: 0,
                            InstructionsReceived: 0,
                            LoansOnHold: 0,
                            SettlementBooked: 0,
                            PayoutProvided: 0,
                            data: {
                                labels: ["Actions Required", "Ongoing"],
                                datasets: [{
                                    backgroundColor: ['#FF9400', '#5879b6'],
                                    data: [0, 0],
                                }]
                            },
                            loading: false
                        });



                    }
                });
            }
            else if (consentsCheck) {
                DashboardService.getFilterCountConsents().then((resp) => {
                    let filterNumber = resp.data.Content;

                    let InstructionsReceivedCount = filterNumber.filter(obj => { return obj.FilterType === "InstrReceived" })[0].FilterCount;
                    let PacketReceived = filterNumber.filter(obj => { return obj.FilterType === "PacketReceived" })[0].FilterCount;
                    let TitleNomination = filterNumber.filter(obj => { return obj.FilterType === "TitleNomination" })[0].FilterCount;
                    let Lodged = filterNumber.filter(obj => { return obj.FilterType === "Lodged" })[0].FilterCount;
                    let Registered = filterNumber.filter(obj => { return obj.FilterType === "Registered" })[0].FilterCount;
                    // let TotalLoanCount = InstructionsReceivedCount + SettlementBooked + PacketReceived + PayoutProvided;
                    // let TottalOutstanding = OnHoldCount;
                    //let LoansInProgress = filterNumber.filter(obj => { return obj.FilterType === "InProgress" })[0].FilterCount;
                    let LoansInProgress = InstructionsReceivedCount + PacketReceived + TitleNomination + Lodged + Registered;
                    this.setState({
                        // SettlingToday: SettlingToday,
                        // SettledToday: SettledToday,
                        //LoansInProgress: TotalLoanCount,
                        LoansInProgress: LoansInProgress,
                        InstructionsReceived: InstructionsReceivedCount,
                        //LoansOnHold: OnHoldCount,
                        PacketReceived: PacketReceived,
                        TitleNomination: TitleNomination,
                        Lodged: Lodged,
                        Registered: Registered,                        
                        loading: false
                    });

                }, err => {
                    if (err.response && err.response.status === 401) {
                        this.setState({

                            loading: false,
                            shouldRedirect: true,
                            errorMessage: 'there is an error with the request'
                        });

                    } else {

                        this.setState({
                            InstructionsReceived: 0,
                            PacketReceived: 0,
                            TitleNomination: 0,
                            Lodged: 0,
                            Registered: 0,
                            
                            data: {
                                labels: ["Actions Required", "Ongoing"],
                                datasets: [{
                                    backgroundColor: ['#FF9400', '#5879b6'],
                                    data: [0, 0],
                                }]
                            },
                            loading: false
                        });



                    }
                });
            }
            else {
                //if(this.state.TimeStamp> new Date().toLocaleString()){
                DashboardService.getFilterCount().then((resp) => {
                    let filterNumber = resp.data.Content;

                    let OnHoldCount = filterNumber.filter(obj => { return obj.FilterType === "OnHold" })[0].FilterCount;
                    let OutstandingCount = filterNumber.filter(obj => { return obj.FilterType === "Outstandings" })[0].FilterCount;
                    let ExpireCount = filterNumber.filter(obj => { return obj.FilterType === "AboutToExpire" })[0].FilterCount;

                    let InstructionsReceivedCount = filterNumber.filter(obj => { return obj.FilterType === "InstrReceived" })[0].FilterCount;
                    let DocsSentCount = filterNumber.filter(obj => { return obj.FilterType === "DocsSent" })[0].FilterCount
                    let SettlingToday = filterNumber.filter(obj => { return obj.FilterType === "SettlingToday" })[0].FilterCount
                    let DocsReturned = filterNumber.filter(obj => { return obj.FilterType === "DocsReturned" })[0].FilterCount;
                    let DocsVerified = filterNumber.filter(obj => { return obj.FilterType === "DocsVerified" })[0].FilterCount;
                    let SettlementBooked = filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount;
                    let SettledToday = filterNumber.filter(obj => { return obj.FilterType === "SettledToday" })[0].FilterCount;
                    let readyToBook = filterNumber.filter(obj => { return obj.FilterType === 'ReadyToBook' })[0].FilterCount;
                    let TotalLoanCount = InstructionsReceivedCount + DocsSentCount + DocsReturned + readyToBook + DocsVerified + SettlementBooked;
                    let LoansInProgress = filterNumber.filter(obj => { return obj.FilterType === "InProgress" })[0].FilterCount;
                    let TottalOutstanding = OnHoldCount + OutstandingCount;
                    this.setState({
                        SettlingToday: SettlingToday,
                        SettledToday: SettledToday,
                        //LoansInProgress: TotalLoanCount,
                        LoansInProgress: TotalLoanCount,//LoansInProgress,
                        InstructionsReceived: InstructionsReceivedCount,
                        LoansOnHold: OnHoldCount,
                        ReadyToBook: readyToBook,
                        DocsSent: DocsSentCount,
                        DocsReturned: filterNumber.filter(obj => { return obj.FilterType === "DocsReturned" })[0].FilterCount,
                        DocsVerified: filterNumber.filter(obj => { return obj.FilterType === "DocsVerified" })[0].FilterCount,
                        OutstandingReq: OutstandingCount,
                        SettlementBooked: filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount,
                        LoansToExpire: ExpireCount,
                        data: {
                            labels: ["Actions Required", "Ongoing"],
                            datasets: [{
                                backgroundColor: ['#FF9400', '#5879b6'],
                                data: [TottalOutstanding, TotalLoanCount - TottalOutstanding],
                            }]
                        },
                        loading: false
                    }, () => { this.setLocalStorage() });

                }, err => {
                    if (err.response && err.response.status === 401) {
                        this.setState({

                            loading: false,
                            shouldRedirect: true,
                            errorMessage: 'there is an error with the request'
                        });

                    } else {
                        this.setState({
                            SettlingToday: 0,
                            SettledToday: 0,
                            LoansInProgress: 0,
                            InstructionsReceived: 0,
                            LoansOnHold: 0,
                            SettlementBooked: 0,
                            PayoutProvided: 0,
                            data: {
                                labels: ["Actions Required", "Ongoing"],
                                datasets: [{
                                    backgroundColor: ['#FF9400', '#5879b6'],
                                    data: [0, 0],
                                }]
                            },
                            loading: false
                        });



                    }
                });
                // }else{
                //     this.hydrateFilterWithLocalStorage();
                // }
            }
            window.addEventListener('resize', this.updateWindowDimensions);
        }

    }

    componentDidMount() {
        AuthService.isNewVersionDetected();
        // alert("dfdsfds");
        // return;
        let dischargeCheck = false;
        let consentsCheck = false;
        let usertypeid = this.state.userdetails != null ? this.state.userdetails.map(d => d.usertypeid) : '';
        let CanViewTotalLoanAmount = this.state.userprivileges != null ? this.state.userprivileges.map(d => d.CanViewTotalLoanAmount) : null;

        AuthService.canUserViewTotalAmount().then((resp) => {
            this.setState({ canuserviewtotalamount: resp.data.Content }, () => {
                //console.log(resp)
                //alert("a"+ this.state.canuserviewtotalamount)
            }
            );
        }, (err) => {
            this.setState({ canuserviewtotalamount: false });
        });


        // alert("canview"+CanViewTotalLoanAmount)
        // alert("usertype" + usertypeid)

        if ((usertypeid == 2 || usertypeid == 3 || usertypeid == 4) && CanViewTotalLoanAmount == "true") {
            //alert("hi")
            this.setState({
                CanViewTotalLoanAmount: true
            }, () => {
                //alert(this.state.CanViewTotalLoanAmount);
            })
        }

        if (window.location.pathname === '/discharges') {
            dischargeCheck = true;
            this.setState({
                dischargeCheck: dischargeCheck
            });
        }
        if (window.location.pathname === '/consents') {
            consentsCheck = true;
        }
        //AuthService.isNewVersionDetected();
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            discharges: dischargeCheck,
            consents: consentsCheck,
            currUrl: window.location.pathname,

        }, () => {
            //this.mySecretFunction()
        });

        document.title = dischargeCheck? "Loantrak - Discharges" : consentsCheck? "Loantrak - Consents" : "Loantrak - New Loans";

        
        if (dischargeCheck) {
            DashboardService.getFilterCountDischarges().then((resp) => {
                let filterNumber = resp.data.Content;

                let OnHoldCount = filterNumber.filter(obj => { return obj.FilterType === "OnHold" })[0].FilterCount;
                let InstructionsReceivedCount = filterNumber.filter(obj => { return obj.FilterType === "InstrReceived" })[0].FilterCount;
                let SettlingToday = filterNumber.filter(obj => { return obj.FilterType === "SettlingToday" })[0].FilterCount
                let SettlementBooked = filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount;
                let SettledToday = filterNumber.filter(obj => { return obj.FilterType === "SettledToday" })[0].FilterCount;
                let PacketReceived = filterNumber.filter(obj => { return obj.FilterType === "PacketReceived" })[0].FilterCount;
                let PayoutProvided = filterNumber.filter(obj => { return obj.FilterType === "PayoutProvided" })[0].FilterCount;
                let TotalLoanCount = InstructionsReceivedCount + SettlementBooked + PacketReceived + PayoutProvided;
                let TottalOutstanding = OnHoldCount;
                let LoansInProgress = filterNumber.filter(obj => { return obj.FilterType === "InProgress" })[0].FilterCount;
                let SettlementCount = SettlingToday + SettledToday;
                let SettlingTodayTotalAmount = filterNumber.filter(obj => { return obj.FilterType === "SettlingToday" })[0].TotalLoanAmount;
                let SettledTodayTotalAmount = filterNumber.filter(obj => { return obj.FilterType === "SettledToday" })[0].TotalLoanAmount;
                this.setState({
                    SettlingToday: SettlingToday,
                    SettledToday: SettledToday,
                    //LoansInProgress: TotalLoanCount,
                    LoansInProgress: LoansInProgress,
                    InstructionsReceived: InstructionsReceivedCount,
                    LoansOnHold: OnHoldCount,
                    PacketReceived: PacketReceived,
                    SettlementBooked: filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount,
                    PayoutProvided: PayoutProvided,
                    PacketReceived: PacketReceived,
                    SettlementCount: SettlementCount,
                    SettlingTodayTotalAmount: SettlingTodayTotalAmount,
                    SettledTodayTotalAmount: SettledTodayTotalAmount,
                    data: {
                        labels: ["Actions Required", "Ongoing"],
                        datasets: [{
                            backgroundColor: ['#FF9400', '#5879b6'],
                            data: [TottalOutstanding, TotalLoanCount - TottalOutstanding],
                        }]
                    },
                    loading: false
                });

            }, err => {
                if (err.response && err.response.status === 401) {
                    this.setState({

                        loading: false,
                        shouldRedirect: true
                    });

                } else {

                    this.setState({
                        SettlingToday: 0,
                        SettledToday: 0,
                        LoansInProgress: 0,
                        InstructionsReceived: 0,
                        LoansOnHold: 0,
                        SettlementBooked: 0,
                        PayoutProvided: 0,
                        data: {
                            labels: ["Actions Required", "Ongoing"],
                            datasets: [{
                                backgroundColor: ['#FF9400', '#5879b6'],
                                data: [0, 0],
                            }]
                        },
                        loading: false
                    });



                }
            });
        } 
        else if (consentsCheck) {
            DashboardService.getFilterCountConsents().then((resp) => {
                let filterNumber = resp.data.Content;

                let InstructionsReceivedCount = filterNumber.filter(obj => { return obj.FilterType === "InstrReceived" })[0].FilterCount;
                let PacketReceived = filterNumber.filter(obj => { return obj.FilterType === "PacketReceived" })[0].FilterCount;
                let TitleNomination = filterNumber.filter(obj => { return obj.FilterType === "TitleNomination" })[0].FilterCount;
                let Lodged = filterNumber.filter(obj => { return obj.FilterType === "Lodged" })[0].FilterCount;
                let Registered = filterNumber.filter(obj => { return obj.FilterType === "Registered" })[0].FilterCount;
                // let TotalLoanCount = InstructionsReceivedCount + SettlementBooked + PacketReceived + PayoutProvided;
                // let TottalOutstanding = OnHoldCount;
                // let LoansInProgress = filterNumber.filter(obj => { return obj.FilterType === "InProgress" })[0].FilterCount;
                let LoansInProgress = InstructionsReceivedCount + PacketReceived + TitleNomination + Lodged + Registered;
                this.setState({
                    // SettlingToday: SettlingToday,
                    // SettledToday: SettledToday,
                    //LoansInProgress: TotalLoanCount,
                    LoansInProgress: LoansInProgress,
                    InstructionsReceived: InstructionsReceivedCount,
                    //LoansOnHold: OnHoldCount,
                    PacketReceived: PacketReceived,
                    TitleNomination: TitleNomination,
                    Lodged: Lodged,
                    Registered: Registered,                        
                    loading: false
                });

            }, err => {
                if (err.response && err.response.status === 401) {
                    this.setState({

                        loading: false,
                        shouldRedirect: true,
                        errorMessage: 'there is an error with the request'
                    });

                } else {

                    this.setState({
                        InstructionsReceived: 0,
                        PacketReceived: 0,
                        TitleNomination: 0,
                        Lodged: 0,
                        Registered: 0,
                        
                        data: {
                            labels: ["Actions Required", "Ongoing"],
                            datasets: [{
                                backgroundColor: ['#FF9400', '#5879b6'],
                                data: [0, 0],
                            }]
                        },
                        loading: false
                    });



                }
            });
        }
        else {

            //if(this.state.TimeStamp> new Date().toLocaleString()){
            DashboardService.getFilterCount().then((resp) => {
                let filterNumber = resp.data.Content;

                let ProvisionalCount = filterNumber.filter(obj => { return obj.FilterType === "ProvisionalHold" })[0].FilterCount;
                let OnHoldCount = filterNumber.filter(obj => { return obj.FilterType === "OnHold" })[0].FilterCount;
                let OutstandingCount = filterNumber.filter(obj => { return obj.FilterType === "Outstandings" })[0].FilterCount;
                let ExpireCount = filterNumber.filter(obj => { return obj.FilterType === "AboutToExpire" })[0].FilterCount;

                let InstructionsReceivedCount = filterNumber.filter(obj => { return obj.FilterType === "InstrReceived" })[0].FilterCount;
                let DocsSentCount = filterNumber.filter(obj => { return obj.FilterType === "DocsSent" })[0].FilterCount;
                let SettlingToday = filterNumber.filter(obj => { return obj.FilterType === "SettlingToday" })[0].FilterCount;
                let DocsReturned = filterNumber.filter(obj => { return obj.FilterType === "DocsReturned" })[0].FilterCount;
                let DocsVerified = filterNumber.filter(obj => { return obj.FilterType === "DocsVerified" })[0].FilterCount;
                let readyToBook = filterNumber.filter(obj => { return obj.FilterType === 'ReadyToBook' })[0].FilterCount;

                let SettlementBooked = filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount;
                let SettledToday = filterNumber.filter(obj => { return obj.FilterType === "SettledToday" })[0].FilterCount;
                let TotalLoanCount = InstructionsReceivedCount + DocsSentCount + readyToBook + DocsReturned + DocsVerified + SettlementBooked;
                let SettlingTodayTotalAmount = filterNumber.filter(obj => { return obj.FilterType === "SettlingToday" })[0].TotalLoanAmount;
                let SettledTodayTotalAmount = filterNumber.filter(obj => { return obj.FilterType === "SettledToday" })[0].TotalLoanAmount;

                let LoansInProgress = filterNumber.filter(obj => { return obj.FilterType === "InProgress" })[0].FilterCount;
                let TottalOutstanding = ProvisionalCount + OnHoldCount + OutstandingCount;
                let SettlementCount = SettlingToday + SettledToday;
                let ActionRequiredCount = TottalOutstanding + ExpireCount;
                this.setState({
                    SettlingToday: SettlingToday,
                    SettledToday: SettledToday,
                    LoansInProgress: TotalLoanCount,
                    //LoansInProgress: LoansInProgress,
                    InstructionsReceived: InstructionsReceivedCount,
                    ProvisionalHold: ProvisionalCount,
                    LoansOnHold: OnHoldCount,
                    DocsSent: DocsSentCount,
                    DocsReturned: filterNumber.filter(obj => { return obj.FilterType === "DocsReturned" })[0].FilterCount,
                    DocsVerified: filterNumber.filter(obj => { return obj.FilterType === "DocsVerified" })[0].FilterCount,
                    OutstandingReq: OutstandingCount,
                    SettlementBooked: filterNumber.filter(obj => { return obj.FilterType === "SettlementBooked" })[0].FilterCount,
                    ReadyToBook: readyToBook,
                    LoansToExpire: ExpireCount,
                    SettlementCount: SettlementCount,
                    ActionRequiredCount: ActionRequiredCount,
                    SettlingTodayTotalAmount: SettlingTodayTotalAmount,
                    SettledTodayTotalAmount: SettledTodayTotalAmount,
                    data: {
                        labels: ["Actions Required", "Ongoing"],
                        datasets: [{
                            backgroundColor: ['#FF9400', '#5879b6'],
                            data: [TottalOutstanding, TotalLoanCount],
                        }]
                    },
                    loading: false
                }, () => { this.setLocalStorage() });

            }, err => {
                if (err.response && err.response.status === 401) {
                    this.setState({
                        loading: false,
                        shouldRedirect: true
                    });

                } else {
                    this.setState({
                        loading: false,
                        shouldRedirect: false
                    });


                }
            });
        }

    }

    setLocalStorage() {
    }


    render() {
        let matterTable = "";
        // if(this.state.shouldRedirect){
        //    return(<Redirect to={{
        //     //    pathname: '/search',
        //     //    arg: 15,
        //     //    sarg: 1
        //        pathname: '/search',
        //         state: { searchArg: 15, loanType: 1 }
        //    }}/> )
        // }
        if (this.state.shouldRedirect) {
            if (localStorage.getItem("ssoTimeout") == null) {
                return <Redirect to="/error" />;
            }
            else {
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return (<Redirect to='/error' />);
        }


        if (this.state.discharges) {
            matterTable = (
                <>
                    <GroupBox title={"Settlement (" + this.state.SettlementCount + ")"}>
                        <DataListCard
                            addCSS={this.state.SettlingToday ? "dlcGreen" : ""}
                            path="/search" 
                            arg={SettlingToday} 
                            sarg={discharges}
                        >
                            <LabelCount 
                                className="labelTooltip" 
                                label="Book to Settle Today" 
                                count={this.state.SettlingToday}
                            >
                                <span class={(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "tooltipText" : ""}>{(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "Total Loan Amount: $ " + this.state.SettlingTodayTotalAmount : ""}</span>
                            </LabelCount>

                        </DataListCard>
                        <DataListCard 
                            addCSS={this.state.SettledToday ? "dlcGreen" : ""}
                            path="/search" 
                            arg={SettledToday} 
                            sarg={discharges}
                        > 
                            <LabelCount 
                                className="labelTooltip" 
                                label="Settled Today" count={this.state.SettledToday}
                            >
                                <span class={(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "tooltipText" : ""}>{(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "Total Loan Amount: $ " + this.state.SettledTodayTotalAmount : ""}</span>
                            </LabelCount>
                        </DataListCard>
                    </GroupBox>

                    <GroupBox title={"Action Required (" + this.state.ActionRequiredCount + ")"}>
                        <DataListCard 
                            addCSS={this.state.LoansOnHold ? "dlcYellow" : ""}
                            path="/search" 
                            arg={LoansOnHoldStatus} 
                            sarg={discharges}
                        >
                            <LabelCount 
                                label="Loans on Hold" 
                                count={this.state.LoansOnHold} 
                            />
                        </DataListCard>
                    </GroupBox>

                    <GroupBox title={"Loans In Progress (" + this.state.LoansInProgress + ")"}>
                        <DataListCard 
                            addCSS={this.state.LoansInProgress ? "dlcBlue" : ""}
                            path="/search" 
                            arg={LoansInProgressReceivedStatus} 
                            sarg={discharges} 
                        >
                            <LabelCount 
                                label="Loans In Progress" 
                                count={this.state.LoansInProgress} 
                            />
                        </DataListCard>
                        <DataListCard
                            addCSS={this.state.InstructionsReceived ? "dlcBlue" : ""}
                            path="/search" 
                            arg={InstructionsReceivedStatus} 
                            sarg={discharges}
                        >
                            <LabelCount 
                                label="Instructions Received" 
                                count={this.state.InstructionsReceived} 
                            />
                        </DataListCard>
                        <DataListCard 
                            addCSS={this.state.PacketReceived ? "dlcBlue" : ""}
                            path="/search" 
                            arg={PacketReceived} 
                            sarg={discharges}
                        >
                            <LabelCount 
                                label="Security Packet Received" 
                                count={this.state.PacketReceived}
                            />
                        </DataListCard>
                        <DataListCard 
                            addCSS={this.state.SettlementBooked ? "dlcBlue" : ""}
                            path="/search" 
                            arg={SettlementBookedStatus} 
                            sarg={discharges}
                        >
                            <LabelCount 
                                label="Settlement Booked" 
                                count={this.state.SettlementBooked} 
                            />
                        </DataListCard>
                        <DataListCard 
                            addCSS={this.state.PayoutProvided ? "dlcBlue" : ""}
                            path="/search" 
                            arg={PayoutProvided} 
                            sarg={discharges}
                        >
                            <LabelCount label="Payout Provided" count={this.state.PayoutProvided}/>
                        </DataListCard>

                    </GroupBox>
                </>
            )
        }
        else if (this.state.consents) {
            matterTable = (<>
            <GroupBox title={"Loans In Progress (" + this.state.LoansInProgress + ")"}>
                <DataListCard 
                    addCSS={this.state.LoansInProgress ? "dlcBlue" : ""}
                    path="/search" 
                    arg={LoansInProgressReceivedStatus} 
                    sarg={consents} 
                >
                    <LabelCount 
                        label="Loans In Progress" 
                        count={this.state.LoansInProgress} 
                    />
                </DataListCard>
                <DataListCard 
                    addCSS={this.state.InstructionsReceived ? "dlcBlue" : ""}
                    path="/search" 
                    arg={InstructionsReceivedStatus} 
                    sarg={consents} 
                >
                    <LabelCount 
                        label="Instructions Received" 
                        count={this.state.InstructionsReceived} 
                    />
                </DataListCard>
                <DataListCard 
                    addCSS={this.state.PacketReceived ? "dlcBlue" : ""}
                    path="/search" 
                    arg={PacketReceived} 
                    sarg={consents}
                >
                    <LabelCount 
                        label="Security Packet Received" 
                        count={this.state.PacketReceived}
                    />
                </DataListCard>
                <DataListCard 
                    addCSS={this.state.TitleNomination ? "dlcBlue" : ""}
                    path="/search" 
                    arg={TitleNomination} 
                    sarg={consents} 
                >
                    <LabelCount 
                        label="Title Nomination" 
                        count={this.state.TitleNomination} 
                    />
                </DataListCard>
                <DataListCard 
                    addCSS={this.state.Lodged ? "dlcBlue" : ""}
                    path="/search" 
                    arg={Lodged} 
                    sarg={consents} 
                >
                    <LabelCount 
                        label="Lodged" 
                        count={this.state.Lodged} 
                    />
                </DataListCard>
                <DataListCard 
                    addCSS={this.state.Registered ? "dlcBlue" : ""}
                    path="/search" 
                    arg={Registered} 
                    sarg={consents} 
                >
                    <LabelCount 
                        label="Registered" 
                        count={this.state.Registered} 
                    />
                </DataListCard>
            </GroupBox>
            </>
            );
        }
        else {
            let divProvisional = '';
            if(this.state.ProvisionalHold != null)
            {
                divProvisional = (
                    <DataListCard 
                    addCSS={this.state.ProvisionalHold ? "dlcYellow" : ""}
                    path="/search" 
                    arg={ProvisionalHoldStatus} 
                    sarg={newLoans}
                >
                    <LabelCount 
                        label="Provisional Hold" 
                        count={this.state.ProvisionalHold}
                    />
                </DataListCard>)
            }
            matterTable = (<>
                <GroupBox title={"Settlement (" + this.state.SettlementCount + ")"}>
                    <DataListCard 
                        addCSS={this.state.SettlingToday ? "dlcGreen" : ""}
                        path="/search" 
                        arg={SettlingToday} 
                        sarg={newLoans}
                    > 
                        <LabelCount 
                            className="labelTooltip" 
                            label="Book to Settle Today" 
                            count={this.state.SettlingToday}
                        >
                            <span class={(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "tooltipText" : ""}>{(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "Total Loan Amount: $ " + this.state.SettlingTodayTotalAmount : ""}</span>
                        </LabelCount>
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.SettledToday ? "dlcGreen" : ""}
                        path="/search" 
                        arg={SettledToday}
                        sarg={newLoans}
                    > 
                        <LabelCount 
                            className="labelTooltip" 
                            label="Settled Today" 
                            count={this.state.SettledToday} 
                        >
                            <span class={(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "tooltipText" : ""}>{(this.state.CanViewTotalLoanAmount && this.state.canuserviewtotalamount) ? "Total Loan Amount: $ " + this.state.SettledTodayTotalAmount : ""}</span>
                        </LabelCount>
                    </DataListCard>
                </GroupBox>

                <GroupBox title={"Action Required (" + this.state.ActionRequiredCount + ")"}>
                    {divProvisional}
                    <DataListCard 
                        addCSS={this.state.LoansOnHold ? "dlcYellow" : ""}
                        path="/search" 
                        arg={LoansOnHoldStatus} 
                        sarg={newLoans}
                    >
                        <LabelCount 
                            label="Loans on Hold" 
                            count={this.state.LoansOnHold}
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.OutstandingReq ? "dlcYellow" : ""}
                        path="/search" 
                        arg={OutstandingReqStatus} 
                        sarg={newLoans}
                    >
                        <LabelCount 
                            label="Outstanding Requirements" 
                            count={this.state.OutstandingReq}  
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.LoansToExpire ? "dlcYellow" : ""}
                        path="/search" 
                        arg={LoansToExpireStatus} 
                        sarg={newLoans}
                    >
                        <LabelCount 
                            label="Loans About to Expire" 
                            count={this.state.LoansToExpire}  
                        />
                    </DataListCard>
                </GroupBox>

                <GroupBox title={"Loans In Progress (" + this.state.LoansInProgress + ")"}>
                    <DataListCard 
                        addCSS={this.state.LoansInProgress ? "dlcBlue" : ""}
                        path="/search" 
                        arg={LoansInProgressReceivedStatus} 
                        sarg={newLoans} 
                    >
                        <LabelCount 
                            label="Loans In Progress" 
                            count={this.state.LoansInProgress} 
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.InstructionsReceived ? "dlcBlue" : ""}
                        path="/search" 
                        arg={InstructionsReceivedStatus} 
                        sarg={newLoans} 
                    >
                        <LabelCount 
                            label="Instructions Received" 
                            count={this.state.InstructionsReceived} 
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.DocsSent ? "dlcBlue" : ""}
                        path="/search" 
                        arg={DocsSentStatus} 
                        sarg={newLoans} 
                    >
                        <LabelCount 
                            label="Docs Sent" 
                            count={this.state.DocsSent}  
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.DocsReturned ? "dlcBlue" : ""}
                        path="/search" 
                        arg={DocsReturnedStatus} 
                        sarg={newLoans} 
                    >
                        <LabelCount 
                            label="Docs Returned" 
                            count={this.state.DocsReturned}   
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.DocsVerified ? "dlcBlue" : ""}
                        path="/search" 
                        arg={DocsVerifiedStatus} 
                        sarg={newLoans} 
                    >
                        <LabelCount 
                            label="Docs Verified" 
                            count={this.state.DocsVerified}  
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.ReadyToBook ? "dlcBlue" : ""}
                        path="/search" 
                        arg={ReadyToBookStatus} 
                        sarg={newLoans} 
                    >
                        <LabelCount 
                            label="Ready to Book" 
                            count={this.state.ReadyToBook} 
                        />
                    </DataListCard>
                    <DataListCard 
                        addCSS={this.state.SettlementBooked ? "dlcBlue" : ""}
                        path="/search" 
                        arg={SettlementBookedStatus} 
                        sarg={newLoans}
                    >
                        <LabelCount 
                            label="Settlement Booked" 
                            count={this.state.SettlementBooked} 
                        />
                    </DataListCard>

                </GroupBox>
            </>);
        }
        return (
            <>
                {matterTable}
            </>

        )
    }
}

export default LoanStatus
