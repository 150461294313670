import React, { Component } from 'react';
import Moment from "moment";
import { Redirect }                                         from 'react-router-dom';
//import ReactHtmlParser                                      from 'react-html-parser';
import ReactHtmlParser                                      from 'html-react-parser';
 
import FormCheckBox from '../../atom/FormCheckBox/FormCheckBox';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import FormTextBox from '../../atom/FormTextBox/FormTextBox'; 

import Button from '../../atom/Button/Button'; 
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon'; 

 
import './css/CommunicationForm.css';
import Card from '../../atom/Card/Card';
import CommunicationContent from '../CommunicationContent/CommunicationContent';
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';


import { MatterNoteService } from '../../../services/matter-note-service';
import { AuthService } from '../../../services/auth-service';
import CheckBox from '../../atom/CheckBox/CheckBox';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import ValidationText from '../../atom/ValidationText/ValidationText';
import { MatterEscalateService } from '../../../services/matter-escalate-service';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import { MatterService } from '../../../services/matter-service';

class CommunicationForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            modalIsOpen: false,
            notes: [],
            noteTypes: [],
            noteTypeLookups: [],
            noteType: null,
            shouldRedirect: false,
            showGroups: true,
            divHeight: 0,
            noteHeader: "",
            noteDetail: "",
            isPrivateNote:false,
            isPrivateNoteCheckAvailable:false,
            isMatterEscalatable:false,      
            errorMessage: null,
            ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage=',
            limit: 3,
            loadMorelabel: "Load more...",
            isNotePosting: false,
            canRequestForMNP: false,
            isRequestPosting: false,
            escalateMatter: false,
            matterEscalated: false,
            matterDescription:'',
            lenderReferenceNumber:'',
            possibleEscalationReasons:[],
            selectedReason:null,
            comments:"",
            loading:true,
            response:null,
            errorResponse:null,
            isInError:false,
            isSuccessful:false,
            value:'Select Escalation Reason',
            dropDownOptions:(<div></div>),
            cannotAddMatterNotes: false
        };

        this.handleChangeHeader = this.handleChangeHeader.bind(this);
        this.handleChangeDetail = this.handleChangeDetail.bind(this);
        this.handlePrivateCheckboxChanged = this.handlePrivateCheckboxChanged.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
        this.handleRequestForMNP = this.handleRequestForMNP.bind(this);
        this.handleEscalateMatter = this.handleEscalateMatter.bind(this);
        this.RequestEscalateMatter=this.RequestEscalateMatter.bind(this);
        this._onDatalistChange = this._onDatalistChange.bind(this);
        this._onCommentChange=this._onCommentChange.bind(this);
        this.closeWindow=this.closeWindow.bind(this);
        this.cancelEscalateRequest=this.cancelEscalateRequest.bind(this);
        
    }
    componentWillMount() {

        this.setState({
          loading: true,
          notes: []
        });
        this.getNotes();
        MatterNoteService.GetEscalatableStatus(this.props.matterId).then(
          (resp)=>{
            this.setState({isMatterEscalatable:resp.data.Content});
          },
          (err)=>{
            this.setState({isMatterEscalatable:false});
          }
        );
        MatterNoteService.GetShowPrivateNoteOption().then(
            (resp)=>{
              this.setState({isPrivateNoteCheckAvailable:resp.data.Content})
            },
            (err)=>{
              this.setState({isPrivateNoteCheckAvailable:false})
            }
        );
        MatterNoteService.LenderCanRequestForMNP().then(
          (resp)=>{
            this.setState({canRequestForMNP:resp.data.Content})
          },
          (err)=>{
            this.setState({canRequestForMNP:false})
          }
        );
        MatterService.UserCannotAddMatterNotes().then(
            (resp)=>{
              this.setState({cannotAddMatterNotes:resp.data.Content})
            },
            (err)=>{
              this.setState({cannotAddMatterNotes:false})
            }
          );
      }
    // componentDidMount(){
    //     this.getNotes();
    // }

    componentDidUpdate(prevProps){
        if(this.props.ShouldReload===true){
          this.props.Reload();
          this.getNotes();
        }
    }

    handleChangeHeader(event) {
        this.setState({ noteHeader: event.target.value });
    }

    handleChangeDetail(event) {
        this.setState({ noteDetail: event.target.value });
    }

    handlePrivateCheckboxChanged(){   
        this.setState({isPrivateNote:!this.state.isPrivateNote},()=>{
            //alert(this.state.isPrivateNote);
        });
    }

    handleSaveNote() {
        this.setState({
            //loading: true
            isNotePosting: true
        });
        let self = this;
        let header = this.state.noteHeader;
        let detail = this.state.noteDetail;
    
        if (!header || !detail) {
        //   SAlert({
        //     title: "Save Note",
        //     html: "All fields are required to create a note.",
        //     type: "warning"
        //   });
    
          return;
        }
    
        let note = {
          matterId: self.props.matterId,
          noteDetail: detail,
          noteHeader: header,
          noteTypeId: this.state.isPrivateNote ? 9 : null,
        };
    
        MatterNoteService.postNewNote(note).then(
          resp => {
            self.setState({
              //loading: false,
              isNotePosting: false,
              modalIsOpen: false,
              message: "",
              noteHeader: "",
              noteDetail: "",
              notes: [],
            });
            this.getNotes();
          },
          err => {
            self.setState({
              notes: [],
              loading: false,
              shouldRedirect: true,
              errorMessage: 'there is an error with the request',
              message: "",
            });
          }
        );
      }

      handleRequestForMNP() {
        // alert("sdfsadf");
        this.setState({
            //loading: true
            isRequestPosting: true
        });
        let self = this;
        let header = this.state.noteHeader;
        let detail = this.state.noteDetail;
    
        // if (!header || !detail) {
        // //   SAlert({
        // //     title: "Save Note",
        // //     html: "All fields are required to create a note.",
        // //     type: "warning"
        // //   });
    
        //   return;
        // }
    
        let note = {
          matterId: self.props.matterId,
          noteDetail: "Lender has requested to MNP this matter",
          noteHeader: "Lender Requested For MNP",
          noteTypeId: 9,//private note
        };
    
        MatterNoteService.postNewNote(note).then(
          resp => {
            self.setState({
              //loading: false,
              isRequestPosting: false,
              modalIsOpen: false,
              message: "",
              noteHeader: "",
              noteDetail: "",
              notes: [],
            });
            this.getNotes();
          },
          err => {
            self.setState({
              notes: [],
              loading: false,
              shouldRedirect: true,
              errorMessage: 'there is an error with the request',
              message: "",
            });
          }
        );
      }

    getNotes() {
        MatterNoteService.getMatterNoteTypes().then(
          resp => {
            let noteTypes = resp.data.Content.map(function(nt) {
              return { id: nt.MatterNoteTypeId, value: nt.MatterNoteTypeName };
            });
    
            this.setState({
              noteTypes: noteTypes
            });
          },
          err => {
            this.setState({
              noteTypes: [],
              loading: false,
              shouldRedirect: true,
              errorMessage: 'there is an error with the request'
            });
          }
        );
    
        MatterNoteService.getMatterNotes(this.props.matterId).then(
          resp => {
            this.setState({
              notes: resp.data.Content,
              loading: false
            });
          },
          err => {
            this.setState({
              notes: [],
              loading: false,
              shouldRedirect: true,
              errorMessage: 'there is an error with the request'
            });
          }
        );
        this.setState();
    }
    onLoadMore(){
        //alert("dsfd");
        this.setState({
            limit: this.state.limit + 10
        });
        if(this.state.limit >= this.state.notes.length){
            this.setState({
                loadMorelabel: "Nothing more to load"
            });
        }
    }
    handleEscalateMatter(){
        this.setState({escalateMatter: true, loading:true})
        //this.setState({loading:true});
        //document.title = "Loantrak - Escalate:" + this.state.matterId;
        MatterEscalateService.GetMatterEscalationDetails(this.props.matterId).then(
            (resp)=>{
                this.setState({lenderReferenceNumber:resp.data.Content.LenderReferenceNumber,matterDescription:resp.data.Content.BorrowerDescription});
            },
            (err)=>{
                this.setState({shouldRedirect:true,
                    errorMessage: 'there is an error with the request',
                    loading:false},()=>{});
            }
        );
        MatterEscalateService.GetMatterEscalationReasons().then(
            resp=>{
                this.setState({possibleEscalationReasons:resp.data.Content,loading:false},()=>{
                    let options = this.state.possibleEscalationReasons.map(o=>(<option key={o.EscalationReasonTypeId} value={o.EscalationReasonTypeDescription}>{o.EscalationReasonTypeDescription}</option>))
                    //let [selectedOption, setSelectedOption] = useState(this.state.possibleEscalationReasons[0].EscalationReasonTypeDescription);
                    //console.log(options)
                    this.setState({dropDownOptions:(
                        <select
                        className="user-input"
                        value={options[0].value}
                        //value={options[0].props.value}
                        onChange={e => this._onDatalistChange(e.target.value)}>
                        {options}
                    </select>),
                    selectedReason:options[0].props.value} )
                });
            },
            err=>{
                this.setState({shouldRedirect:true,
                    errorMessage: 'there is an error with the request',
                    loading:false},()=>{});
            }
        );
    }

    //from matterescalate.js
    _onDatalistChange(e){
        this.setState({selectedReason:e});
    }
    _onCommentChange(e){
        this.setState({comments:e.target.value});
    }

    RequestEscalateMatter(e){
        //e.preventDefault();        
        this.setState({isSuccessful:false,isInError:false});
        MatterEscalateService.PostMatterEscalationRequest(this.props.matterId,this.state.selectedReason,this.state.comments).then((resp)=>
        {
            this.setState({isSuccessful:true,response:resp},()=>{
               
            });
        },(err)=>
        {
            this.setState({
                isInError:true,errorResponse:"There is an error with the request"//err
            });
        });
    }

    cancelEscalateRequest(e){
        this.setState({
            escalateMatter: false
        })
    }

    closeWindow(e){
        this.setState({
            escalateMatter: false
        },()=>{
            window.location.reload();
        })
    }

    render() {
        if (this.state.shouldRedirect) {
            if(localStorage.getItem("ssoTimeout") == null){
              return <Redirect to="/error" />;
            }
            else{
              return <Redirect to="/ssoerror" />;
              //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return <Redirect to="/" />;
          } else {
            if (this.state.loading) {
              return <ProgressDisplay />;
              //return (<div>progressDisplay</div>);
            }
            let privateCheck = "";
            let escalateButton = "";
            let RequestforMNPButton = "";
            
            if(this.state.isPrivateNoteCheckAvailable){
                privateCheck=(                
                    <FormCheckBox  checked={this.state.isPrivateNote} onChange={this.handlePrivateCheckboxChanged} 
                                    title="Make this a private note to MSA"
                                />
        
                    //   <CheckBox handleCheckChildElement={this.handlePrivateCheckboxChanged} checked={this.state.isPrivateNote} value="  Make this a private note to MSA" />
              
                );
            }
            if(this.state.isMatterEscalatable){
              escalateButton=(
                // <li><button id="escalate-matter-button"  onClick={()=> window.open("/escalate-matter:" + this.props.matterId)}>Escalate Matter</button></li>
                // <FormCheckBox  onClick={()=> window.open("/escalate-matter:" + this.props.matterId)} 
                //     title="Escalate"
                // />
                <Button
                                id="btnCFPost"
                                addCSS="btnRegular btnSmall btnEscalateMatter"
                                iconURL="../../../../images/icons/OutlineWhite/Edit.svg"
                                //clickFunction={()=> window.open("/escalate-matter:" + this.props.matterId)}
                                clickFunction={() => this.handleEscalateMatter()}
                            >Escalate Matter</Button> 
              )
            }
            if(this.state.canRequestForMNP){
                RequestforMNPButton=( 
                        <Button
                                id="btnCFPost"
                                addCSS="btnRegular btnSmall btnMNP"
                                iconURL="../../../../images/icons/OutlineWhite/Edit.svg"
                                clickFunction={() => this.handleRequestForMNP()}
                            >Request for MNP</Button> 
                   );
            }
        let display = "";
        let divLoadMore = "";
        if (this.state.notes.length == 0 )
        {
            divLoadMore=(<div>No notes found</div>);
           //return <div>No notes found</div>;
        }
        else{
            if(this.state.limit >= this.state.notes.length){
                divLoadMore='';
            }else{
                divLoadMore=( <ButtonLoadMore 
                    addCSS="btnLoadMore"
                    btnLabel={this.state.loadMorelabel}
                    //resultDisplay="1 of 10"
                    onClickFunction={this.onLoadMore}
                />);
            }            
        }
        

        if (this.state && this.state.notes){           

            display = this.state.notes.slice(0,this.state.limit).map(nt => (
                <Card>
                    <CommunicationContent
                        title={nt.NoteHeader}
                        date={Moment(nt.NoteHelper.UpdatedDate).format('DD/MM/YYYY hh:mm A')}
                        company={(nt.MatterNoteType.MatterNoteTypeId === 7? nt.NoteHelper.UpdatedByFullname : "MSA") + " (" + nt.NoteHelper.UpdatedByUserType + ")"}//"MSA (Internal"
                    >
                    {/* <div>{nt.NoteBody}</div> */}
                    {/* <div dangerouslySetInnerHTML={{__html:nt.NoteBody}}></div> */}
                    {/* {nt.NoteBody} */}
                        {/* <div>{ReactHtmlParser(nt.NoteBody) }</div> */}
                        {/* <div className="notesBody">{ReactHtmlParser(nt.NoteBody)}     </div>                    */}
                        {/* <div className="notesBody">{nt.NoteBody == null ? nt.NoteBody : ReactHtmlParser(nt.NoteBody)}</div>    */}
                        <div dangerouslySetInnerHTML={{__html:nt.NoteBody}} className="notesBody" style={{backgroundColor:"#F5F8FA"}}></div>
                        
                    </CommunicationContent> 
                </Card>
            ));
        }
        let matterEscalateForm = "";
        let matterEscalateButtons = (
            // <Button clickFunction={(e)=>this.RequestEscalateMatter(e)}>Submit</Button>
            <ButtonGroup>
                <Button clickFunction={(e)=>this.RequestEscalateMatter(e)}>Submit</Button>
                <Button addCSS="tertiaryBTN" clickFunction={(e)=>this.cancelEscalateRequest(e)}>Cancel</Button>
            </ButtonGroup>
        );
        let errorMessage="";
        let successMessage="";
        
        
        if(this.state.isSuccessful){
            //successMessage=(<div>Matter Escalation Request Received. Please note an Escalation Request can only be made once.</div>);
            successMessage=(<DisplayMessage message="Matter Escalation Request Received. Please note an Escalation Request can only be made once." /> );
            // escalateButton=(<div><Button clickFunction={(e)=>this.CloseWindow(e)}>Close</Button></div>);
            matterEscalateButtons=(<div><Button clickFunction={(e)=>this.closeWindow(e)}>Close</Button></div>);
        }

        if(this.state.isInError){
            //errorMessage=(<div>{this.state.errorResponse.message}</div>)
            errorMessage=(<DisplayMessage message={this.state.errorResponse} />)
        }
    

        if(this.state.escalateMatter  && this.state.isMatterEscalatable){
            matterEscalateForm=(<div className="popup-box-escalate" style={{display: this.state.escalateMatter ? 'block':'none', height:"100%"}}>
                    <div className="box-escalate" >
                    <div className="content">
                <Card>
                    {/* <MatterEscalateForm MatterEscalateFormCallback={this.handleMatterEscalateFormCallback} /> */}
                    {/* <Logo /> */}
                    <h5>Matter Escalate</h5>
                    <p>
                        Loan Escalation Request for {this.state.matterDescription}<br />
                        MSA Reference: {this.props.matterId}<br />
                        Lender Reference: {this.state.lenderReferenceNumber}
                    </p>
                    
                    <ValidationText>{this.state.errorMessage}</ValidationText>
                    
                    <div className="inputBox">
                        {this.state.dropDownOptions}
                    </div>
                    
                    <FormTextArea placeholder="Comments" value={this.state.comments} onChange={this._onCommentChange} rows="5" />
                    {/* <ButtonGroup>
                    {matterEscalateButton}
                    <Button addCSS="tertiaryBTN" clickFunction={(e)=>this.closeWindow(e)}>Cancel</Button>
                    </ButtonGroup> */}
                    
                    {matterEscalateButtons}
                    
                    <br/>
                    {/* <DisplayMessage message={this.props.responseMessage} /> */}
                    {successMessage}
                    {errorMessage}
                </Card> 
                {/* <div className="tac spacingTopLarge">
                    <img src={MSALogo} alt="Loantrak" />
                </div> */}
                </div>
                        </div>
                    
                    
                </div>);

        }

        let divAddNote = '';
        if(!this.state.cannotAddMatterNotes){
            divAddNote=(
                <div>
                    <FormTextBox className="formTextBoxBlue" placeholder="Subject" value={this.state.noteHeader} onChange={this.handleChangeHeader}  />
                    <div className="inputBox">
                        <FormTextArea className="formTextBoxBlue" placeholder="Message" rows="5" value={this.state.noteDetail} onChange={this.handleChangeDetail} />
                    </div>
                    <div className="CFAction">
                        <div className="cbBox"> 
                            {privateCheck}                        
                        </div>       
                        <div className="cbBox"> 
                            {/* <FormCheckBox
                                title="Escalate"
                            /> */}
                            {/* {escalateButton} */}
                        </div>   
                        
                        {this.state.isNotePosting?
                        <ProgressDisplay/>
                        :
                        <div className="btnBox">
                            <ButtonIcon
                                id="btnCFPost"
                                addCSS="ButtonIconSelected ButtonIconSmall"
                                iconURL="../../../../images/icons/OutlineWhite/Edit.svg"
                                onClick={() => this.handleSaveNote()}
                            >Post</ButtonIcon>
                        </div>
                        }
                    </div>
                </div>
            );
        }
        
        return ( 
            <div>                 
                <div className="CommunicationForm">         
                    {matterEscalateForm}      
                    {divAddNote}
                    {/* <FormTextBox className="formTextBoxBlue" placeholder="Subject" value={this.state.noteHeader} onChange={this.handleChangeHeader}  />
                    <div className="inputBox">
                        <FormTextArea className="formTextBoxBlue" placeholder="Message" rows="5" value={this.state.noteDetail} onChange={this.handleChangeDetail} />
                    </div>
                    <div className="CFAction">
                        <div className="cbBox"> 
                            {privateCheck}                        
                        </div>          
                        
                        {this.state.isNotePosting?
                        <ProgressDisplay/>
                        :
                        <div className="btnBox">
                            <ButtonIcon
                                id="btnCFPost"
                                addCSS="ButtonIconSelected ButtonIconSmall"
                                iconURL="../../../../images/icons/OutlineWhite/Edit.svg"
                                onClick={() => this.handleSaveNote()}
                            >Post</ButtonIcon>
                        </div>
                        }
                    </div> */}
                    <div className="rfmnpBox">
                {/* {this.state.isRequestPosting?
                    <ProgressDisplay/>:

                        RequestforMNPButton
                
                    } */}
                    {/* {RequestforMNPButton} */}
                    {escalateButton}
                    </div>
                     <div className="rfmnpBox">
                {this.state.isRequestPosting?
                    <ProgressDisplay/>:

                        RequestforMNPButton
                
                    }
                    {/* {RequestforMNPButton} */}
                    </div>                  
                    
                    
                    {/* <div className="CFAction">
                        <div className="cbBox"> 
                            <FormCheckBox
                                title="Request for MNP"
                            />
                        </div>
                    </div> */}
                </div>
                {display}
                
                {divLoadMore}
            
            </div>
        )
        }
    }
}

export default CommunicationForm
