import React, { Component } from 'react' 
import { Redirect } from 'react-router-dom';
import {withRouter}  from 'react-router-dom'; 

import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card';
import IconBox from '../../atom/IconBox/IconBox';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import Line from '../../atom/Line/Line';
import MessageBox from '../../atom/MessageBox/MessageBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import BackBar from '../../molecules/BackBar/BackBar'; 
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox'; 
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import Accordion from '../../organism/Accordion/Accordion'; 
import DataTableDocuments from '../../organism/DataTableDocuments/DataTableDocuments';
import CommunicationContent from '../../organism/CommunicationContent/CommunicationContent';
import CommunicationForm from '../../organism/CommunicationForm/CommunicationForm';
import LoanDetails from '../../organism/LoanDetails/LoanDetails';
import ProgressStep from '../../organism/ProgressStep/ProgressStep';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/MatterPage.css';   
import { AuthService } from '../../../services/auth-service';
import DataTableOutstandingItems from '../../organism/DataTableOutstandingItems/DataTableOutstandingItems';
import {Route, Switch }                                     from 'react-router-dom';
import SearchPage from '../SearchPage/SearchPage';
import { MatterService } from '../../../services/matter-service';
import { MatterDocumentService } from '../../../services/matter-document-service';
import { saveAs } from 'file-saver';
 
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import AutoLogout from '../../../auto-logout';
import Button from '../../atom/Button/Button';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import InsightBox from '../../molecules/InsightBox/InsightBox';


class MatterPage extends Component { 
    
    constructor(props){
        super(props);

        let userdetails = AuthService.getUserDetails();
        let viewedMatters = AuthService.getViewedMatters();

        this.state={
            matter: this.props.match.params.myKey.substring(1,8),
            tbbTarget: null ,
            $windowWidth: window.innerWidth,
            pageTitle : 'matter',
            leftContent : 'matter',
            backToSearch: false,
            canDownloadDocPack:false,
            userdetails: userdetails,
            brokershouldverify: false,
            lendershouldverify: false,
            successMessage: null,
            errorMessage: null,
            enterCode: false,
            userName: null,
            userCode: null,
            matterId: null,
            usertypeId: null,
            viewedMatters : viewedMatters == null? [] : viewedMatters,
            codeRequired: true,
            lender:null,
            profileDisplay: "",
            hasError: false,
            displayDetails: false,
            loading: true,
            cannotViewFiles: false,
            loantrakAnnouncement: [],
            InsightBox:0, 
            MatterIsPOH: false,
        }

        this.updateTab=this.updateTab.bind(this);
        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.backToSearch = this.backToSearch.bind(this);
        this.downloadDocuments = this.downloadDocuments.bind(this);
        this.handleUserCodeChanged = this.handleUserCodeChanged.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
        this.getLoantrakAnnouncement = this.getLoantrakAnnouncement.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    }

    componentDidMount(){
        
        //alert(this.state.matter);
        window.addEventListener("resize", this.updateWindowDimension);

        AuthService.isNewVersionDetected();

        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("view matter").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }

        MatterService.getMatterDetail(this.state.matter).then(
            resp => {
              //success
              this.setState({                
                //loading: true,
              });
            },
            err => {
              this.setState({                
                hasError: true,
                //loading: false
              });
            }
          );

        //check if matter is provisionally on hold       
        MatterService.CheckMatterIsProvisionalOnHold(this.state.matter).then(
            (resp)=>{
              this.setState({MatterIsPOH:resp.data.Content})
            },
            (err)=>{
              this.setState({MatterIsPOH:false})
            }
        );

        this.getLoantrakAnnouncement(this.state.matter);
        
        MatterService.UserCannotViewFiles().then(
            (resp)=>{
              this.setState({cannotViewFiles:resp.data.Content})
            },
            (err)=>{
              this.setState({cannotViewFiles:false})
            }
        );

        //check user before viewing matter details     
        let username =  this.state.userdetails != null? this.state.userdetails.map(d=>d.username) : '';
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';

        
        if(usertypeid == 3)
        {
            AuthService.GetLenderInfo().then((resp)=>{
                this.setState({
                    lender:resp.data.Content
                },()=>{console.log("lenderirene" + this.state.lender)});
            },(err)=>{
                console.log("there is an error")
                //this.setState({isInstructPriv:false});
            })
        }
   
        this.setState({
            userName:username,        
            enterCode: false,
            successMessage: null,
            userCode: null
        }
        //,()=>{this.getVerificationCode();}
        )

        var vmatters = this.state.viewedMatters;
        if(localStorage.getItem("ssoTimeout") == null){
        if(usertypeid==4){
            MatterService.CheckIfBrokerRequiresMatterAuthentication(this.state.matter).then(response=>{
            
            this.setState({codeRequired:response.data.Content},()=>{
                //alert(this.state.codeRequired);
                //if(!vmatters.includes(this.state.matter) && usertypeid==4 && this.state.codeRequired == true){
                if(this.state.codeRequired == true){
                    this.setState({
                        brokershouldverify: true,
                        usertypeId: usertypeid,
                        displayDetails: false,
                        loading: false
                        // matterId: event.dataItem.MatterId
                    });  
                    this.getVerificationCode();
                }   
                else{
                    this.setState({                        
                        displayDetails: true,
                        loading: false
                        // matterId: event.dataItem.MatterId
                    });  
                }
                }
                // ,()=>{
                //     if(this.state.codeRequired == true)
                //     this.getVerificationCode();
                // }
                );
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });  
        }
        else if(usertypeid==2){            
            MatterService.CheckLenderRequiresAuthentication().then(response=>{
        
            this.setState({codeRequired:response.data.Content},()=>{
            //alert(this.state.codeRequired);
            //if(!vmatters.includes(this.state.matter) && usertypeid==2 && this.state.codeRequired == true){
            if(this.state.codeRequired == true){
                this.setState({
                    lendershouldverify: true,
                    usertypeId: usertypeid,
                    loading: false
                // matterId: event.dataItem.MatterId
                });                  
                this.getVerificationCode();
               
            }   
            else{
                this.setState({                        
                    displayDetails: true,
                    loading: false
                    // matterId: event.dataItem.MatterId
                }); 
                
            }
            });
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });  
        }
        else{
            if(usertypeid==3 || usertypeid==5){ //User is MM or RelationshipManager
                this.setState({                        
                    displayDetails: true,
                    loading: false
                    // matterId: event.dataItem.MatterId
                }); 
            }
        }
        }
        else{
            this.setState({
                loading: false,
                displayDetails: true,
               })
        }

        AuthService.isUserDockPackEnabled().then((resp)=>{
            this.setState({canDownloadDocPack:resp.data.Content});
          },(err)=>{
            this.setState({canDownloadDocPack:false});
          })
    }

    EditInstruction(){
        window.open('/InstructionEdit:'+this.state.matter)
    }
    getLoantrakAnnouncement(matterId){
        MatterService.getMatterAnnouncements(matterId).then(
            resp => {
              this.setState(
                {
                  loantrakAnnouncement: resp.data.Content,
                  loading: false
                },()=>{
                    console.log(this.state.loantrakAnnouncement)
                }
                );
            },
            err => {
              this.setState({
                loading: false,
                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
              });
            }
          );
    }
    
    // backToSearch(child){
    //     //alert('child');
    //     this.setState({
    //         backToSearch: true
    //     });
    //     //window.close();
    // }

    backToSearch=()=>{
        //alert(this.state.searchArg);
        this.props.history.push({pathname:"/search",
        state: {
           // searchArg : null, 
           searchArg: this.props.searchArg
        }
    });
    }

    updateWindowDimension=()=>{
        this.setState({
            $windowWidth: window.innerWidth,
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    } 

    updateTab($tbbTarget){
        //alert("Active: "+$tbbTarget)

        this.setState({
            tbbTarget: $tbbTarget
        })
    }

    handleLeftMenuCallback(child){
        //alert(child);
        this.setState({
            leftContent: child
        });
    }

    downloadDocuments(e){
        /* e.preventDefault();*/
    
        MatterDocumentService.getDocumentsAsZip(this.state.matter).then(
          resp => {
            const file = new Blob([resp.data], { type: "application/zip" });
            const url = URL.createObjectURL(resp.data);
            saveAs(file,this.state.matter+".zip");  
          },
          err => {
            if (err.response && err.response.status === 401) {
              console.log(err.response);
            }})
    }
    
    getVerificationCode(e)
    {
        this.setState({
            errorMessage:null,
        });
        AuthService.requestUserVerificationCode(this.state.userName).then((resp) => {
            this.setState({
                errorMessage:null,
                successMessage:resp.Message,                
                //enterCode: true,
            })
        },
        (err)=>{
            this.setState({
                errorMessage:'Invalid Details. Please check your security code and details.',//+err,
                resetPasswordButton:'submit-button error',
                resetPasswordEmailInput:this.state.resetPasswordEmailInput+" input-error",
            },()=>{setTimeout(()=>{this.setState({resetPasswordButton: "submit-button"})},1000);});
            return;
        });
    }

    getNewVerificationCode(e)
    {
        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("matter access verification").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }
        this.setState({
            errorMessage:null,
            successMessage:null,           
        });
        AuthService.resendUserVerificationCode(this.state.userName).then((resp) => {
            this.setState({
                errorMessage:null,
                successMessage:resp.Message,                
                //enterCode: true,
            })
        },
        (err)=>{
            this.setState({
                errorMessage:'Invalid Details. Please check your security code and details.',//+err,
                resetPasswordButton:'submit-button error',
                resetPasswordEmailInput:this.state.resetPasswordEmailInput+" input-error",
            },()=>{setTimeout(()=>{this.setState({resetPasswordButton: "submit-button"})},1000);});
            return;
        });
    }

    submitVerificationCode(e){
        e.preventDefault();       
        //Sends api call to begin expecting entry of code from text
        if (!this.state.userCode) {
            this.setState({
                successMessage:'',
                errorMessage: 'Security Code is required. The code has been sent to your SMS inbox.',
            });

            return;
        }
        
        this.setState({
            errorMessage:null,
            successMessage:null
        },()=>{
            AuthService.verifyBrokerCode(this.state.userName, this.state.userCode.trim()).then((resp) => {
                // if(window.innerWidth>768){
                //     window.open('/loan-details:'+this.state.matterId)
                //     }
                // if(window.innerWidth<=768){
                //     this.context.router.history.push(
                //        '/loan-details:'+this.state.matterId
                //        )
                //     }
                this.setState({
                    errorMessage:null,
                    successMessage:resp.Message,   
                    enterCode: true,
                    brokershouldverify: false,
                    lendershouldverify: false,
                    displayDetails: true
                })
                //after viewing successfully, add the matter to viewed matters
                this.state.viewedMatters.push(this.state.matter);     
                AuthService.setViewedMatters(this.state.viewedMatters);
            },
            (err)=>{
                this.setState({
                    errorMessage:'Invalid Code. Please check your security code and details.',//+err,                    
                });
                return;
            });
        });        
    }

    cancel(e){
        e.preventDefault();
        // this.setState({
        //     brokershouldverify: false,
        //     lendershouldverify: false,
        //     errorMessage: null,
        //     userCode: null,
        //     successMessage:null
        // });
       // AuthService.deleteBrokerCode(this.state.userName).then((resp) => {
            // if(window.innerWidth>768){
            //     window.open('/loan-details:'+this.state.matterId)
            //     }
            // if(window.innerWidth<=768){
            //     this.context.router.history.push(
            //        '/loan-details:'+this.state.matterId
            //        )
            //     }
            this.setState({
                errorMessage: null,
                userCode: null,
                successMessage:null
            })
            // //after viewing successfully, add the matter to viewed matters
            // this.state.viewedMatters.push(this.state.matter);     
            // AuthService.setViewedMatters(this.state.viewedMatters);
        // },
        // (err)=>{
            this.setState({
                brokershouldverify: true,
                lendershouldverify: true,
                //errorMessage:'There is an error with the request.',//+err,                    
            });
            //return;
        //});
        window.close();
    }

    handleUserCodeChanged( event ) {    //Gets new user code when entered, nonfunctional backend api
        this.setState({
            userCode: event.target.value
        });
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }





    render() {  
        
        // console.log("Window Width: "+this.state.$windowWidth);
        // console.log("Active: "+this.state.tbbTarget);

        var $mobileMaxWidth=768; 
        const $TabButtonProps={ 
            "title":"",
            "activeTarget":"StepProgressBar",
            "action": this.updateTab,
            "addcss": "TabButtonIcon",
            "buttons": [ 
                {
                    "id": "1",
                    "label": "Loan Status",
                    "target":"StepProgressBar",
                    "addcss":" iGreyLoanStatus",
                    "status": this.state.tbbTarget=="StepProgressBar"||this.state.tbbTarget==null?1:0
                },{
                    "id": "2",
                    "label": "Loan Details",
                    "target":"LoanDetailsBox",
                    "addcss":"iGreyLoanDetails",
                    "status": this.state.tbbTarget=="LoanDetailsBox"?1:0
                },{
                    "id": "3",
                    "label": "Communications",
                    "target":"CommunicationBox",
                    "addcss":"iGreyCommunications",
                    "status": this.state.tbbTarget=="CommunicationBox"?1:0
                },{
                    "id": "4",
                    "label": "Outstandings and Docs",
                    "target":"DocumentsBox",
                    "addcss":"iGreyDocuments",
                    "status": this.state.tbbTarget=="DocumentsBox"?1:0
                }
            ]
        }; 

        if(this.state.hasError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        }    
        else{
            //return <Redirect to="/?" />;
            if(localStorage.getItem("user") == null){
                return ( <Redirect to ={{pathname: '/', state: {matterId: this.state.matter} }}  /> );
            }

        }
        
        let matterDetailsBox ="";
        let divAnnouncement = "";
        //let divAnnouncement2 = "";

        if(this.state.loantrakAnnouncement != null || this.state.loantrakAnnouncement.length > 0){
            divAnnouncement = this.state.loantrakAnnouncement.map(announcement => (<div style={{"padding-top": "30px"}}>
                    <MessageBox
                        addCSS="MBInfo"
                        iconURL="../../../../images/icons/OutlineDark/Light_Bulb.svg">{announcement.AnnouncementTitle} : &nbsp;
                        {announcement.AnnouncementContent} 
                        
                    </MessageBox>
                    {/* {this.state.loantrakAnnouncement.length > 1 ? <div> <br /><br /></div>: ""} */}
                    {/* <br /><br />
                    <MessageBox
                        addCSS="MBWarning"
                        iconURL="../../../../images/icons/OutlineDark/Exlaimation_Circle.svg">Warning
                    </MessageBox>

                    <br /><br />
                    <MessageBox
                        addCSS="MBError"
                        iconURL="../../../../images/icons/OutlineDark/Cross_Cirlce.svg">Error
                    </MessageBox>

                    <br /><br />
                    <MessageBox
                        addCSS="MBSuccess"
                        iconURL="../../../../images/icons/OutlineDark/Save.svg">Success
                    </MessageBox>  */}
            </div>));
        }

        // if(this.state.loantrakAnnouncement != null || this.state.loantrakAnnouncement.length > 0){
        //     divAnnouncement2 = this.state.loantrakAnnouncement.map(announcement => (<>
        //            {announcement.AnnouncementTitle} : &nbsp;
        //                 {announcement.AnnouncementContent} 
                        
        //           <br/>
                    
        //     </>));
        // }

        if(this.state.leftContent != this.state.pageTitle){
            return( <DisplayLeftMenu page={this.state.leftContent}></DisplayLeftMenu>)
        }
        if((this.state.brokershouldverify == true && this.state.usertypeId == 4) || (this.state.lendershouldverify == true && this.state.usertypeId == 2)){
            return(
              <div className="popup-box" style={{display: (this.state.brokershouldverify || this.state.lendershouldverify) ? 'block':'none', height:"60%", textAlign:"center"}}>
                        <div className="box card">
                            <div className="tal">                       
                                Hi, this lender has requested an email verification to view this matter. Please enter the security code that has been sent to your email.
                                <br /><br />
                                Code: <FormTextBox autoComplete="off" id="userCode" type="text" style={{border:"thick"}} onChange={ this.handleUserCodeChanged } defaultValue={ this.state.userCode } required autoFocus />
                            </div>  
                            <div>&nbsp;</div>                       
                            <ButtonGroup>
                                <Button
                                    clickFunction={(e) => this.submitVerificationCode(e)}
                                >
                                Submit Code
                                </Button>
                            
                                <Button
                                    addCSS="tertiaryBTN" 
                                    clickFunction={(e) => this.cancel(e)}
                                >
                                Cancel
                                </Button>
                            </ButtonGroup>
      
                            <div>&nbsp;</div> 
      
                            <div className="tal" style={{display: "block"}}>
                                Haven't received the code after one minute? Please click on <a onClick={(e) => this.getNewVerificationCode(e)} style={{textAlign:'center'}}> Get Verification Code</a>
                            </div>
                            {(this.state.successMessage||this.state.errorMessage)?
                                (<div className={"tal "+(this.state.successMessage?"success":"error")} >
                                    <div>&nbsp;</div>
                                    {this.state.successMessage}
                                    {this.state.errorMessage}
                                    {/* <SuccessDisplay message={this.state.successMessage} />
                                    <ErrorDisplay message={this.state.errorMessage} /> */}
                                </div>):""
                            }                            
                        </div>                       
                    </div>
            );
          }
          if(this.state.displayDetails){
            matterDetailsBox=(<div className="MatterTabBox">

            <div className="MTBBox">
                <TabButtonBox  
                    tbbinit={ $TabButtonProps } 
                />  

                {this.state.MatterIsPOH ?
                    <div className="newBTNBoxMobile">
                        <ButtonIcon
                            addCSS="ButtonIconSelected ButtonIconSmall"
                            iconURL="../../../../images/icons/OutlineWhite/edit.svg"
                        >Edit Instruction</ButtonIcon> 
                    </div>
                    :
                    ''
                }
                
            </div>
        
            <div className={ this.state.tbbTarget=="StepProgressBar"||this.state.tbbTarget==null||this.state.$windowWidth>$mobileMaxWidth?"StepProgressBar block":" StepProgressBar none"}> 
                <ProgressStep matterId = {this.state.matter} /> 
                {divAnnouncement}
                {/* <div style={{"padding-top": "30px"}}>
                    <MessageBox
                        addCSS="MBInfo"
                        iconURL="../../../../images/icons/OutlineDark/Light_Bulb.svg">{divAnnouncement2}
                    </MessageBox>
                </div> */}
                

                {/* ANNOUNCEMENT CODE START */}
                {/* <div style={{"padding-top": "30px"}}>
                    <MessageBox
                        addCSS="MBInfo"
                        iconURL="../../../../images/icons/OutlineDark/Light_Bulb.svg">Announcement
                    </MessageBox>

                    <br /><br />
                    <MessageBox
                        addCSS="MBWarning"
                        iconURL="../../../../images/icons/OutlineDark/Exlaimation_Circle.svg">Warning
                    </MessageBox>

                    <br /><br />
                    <MessageBox
                        addCSS="MBError"
                        iconURL="../../../../images/icons/OutlineDark/Cross_Cirlce.svg">Error
                    </MessageBox>

                    <br /><br />
                    <MessageBox
                        addCSS="MBSuccess"
                        iconURL="../../../../images/icons/OutlineDark/Save.svg">Success
                    </MessageBox> 
                </div>  */}
                {/* ANNOUNCEMENT CODE END */}

                {this.state.MatterIsPOH ?
                    <div className="newBTNBox">
                        <ButtonIcon
                            addCSS="ButtonIconSelected ButtonIconSmall"
                            iconURL="../../../../images/icons/OutlineWhite/edit.svg"
                            onClick={(e)=>{e.preventDefault; this.EditInstruction();}}
                        >Edit Instruction</ButtonIcon> 
                    </div>
                    :
                    ''
                }

            </div>

            <div className={ this.state.tbbTarget=="LoanDetailsBox" || this.state.$windowWidth>=$mobileMaxWidth?"LoanDetailsBox block":"LoanDetailsBox none"}
            
            >
                <TitleSegment>Loan Details</TitleSegment>
                <Card> 
                    {/* {this.state.MatterIsPOH ? <a onClick={(e)=>{e.preventDefault; this.EditInstruction();}}>Edit Matter Instruction</a> : ''} */}
                    <LoanDetails matterId = {this.state.matter} />  
                </Card>
            </div>

            <div className={ this.state.tbbTarget=="CommunicationBox" || this.state.$windowWidth>=$mobileMaxWidth?"CommunicationBox block":" CommunicationBox none"}> 
                <TitleSegment>Communications</TitleSegment>

                <CommunicationForm matterId = {this.state.matter} />

            </div>

            <div className={ this.state.tbbTarget=="DocumentsBox" || this.state.$windowWidth>=$mobileMaxWidth?"DocumentsBox block":"DocumentsBox none"}> 
                <TitleSegment>Outstandings and Documents</TitleSegment>
                
                <DataTableOutstandingItems  matterId = {this.state.matter} /> 
                {!this.state.cannotViewFiles?
                (<h6>{this.state.lender=="TicToc"?"Backchanneled Documents":"Documents"}</h6>):''}
                {!this.state.cannotViewFiles?(<Card> 
                     <DataTableDocuments matterId = {this.state.matter}  />  
                </Card>)
                : ''
                }
                                               
            </div>

        
            </div> );
          }

        // if(this.state.backToSearch == true){
        //     // return(<Redirect to = '/search'  />)
        //     this.context.router.push({
        //         pathname:'/search'
        //     })
        // }
        let divProgress="";
        if (this.state.loading) {
            divProgress=(<div className="content"> <ProgressDisplay /></div>);
            //return (<div>progressDisplay</div>);
          }

        return ( 
             
            <LeftMenuContent id="MatterPage" page="matter" callBack = {this.handleLeftMenuCallback} statBoxShow={()=>this.showInsightBox()} showInsightsDetails={this.moreInsights}>  
                    

                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />

                    {divProgress}
                    <div className="content">  

                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>

                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 

                        </div>                    */}

                        <BackBar onClick={this.backToSearch}>back to search</BackBar>
                        
                        {matterDetailsBox}

                        {/* <div className="MatterTabBox">

                            <div className="MTBBox">
                                <TabButtonBox  
                                    tbbinit={ $TabButtonProps } 
                                />  
                            </div>
                        
                            <div className={ this.state.tbbTarget=="StepProgressBar"||this.state.tbbTarget==null||this.state.$windowWidth>$mobileMaxWidth?"StepProgressBar block":" StepProgressBar none"}> 
                                <ProgressStep matterId = {this.state.matter} />  
                            </div>

                            <div className={ this.state.tbbTarget=="LoanDetailsBox" || this.state.$windowWidth>=$mobileMaxWidth?"LoanDetailsBox block":"LoanDetailsBox none"}
                            
                            >
                                <TitleSegment>Loan Details</TitleSegment>
                                <Card> 
                                    <LoanDetails matterId = {this.state.matter} />  
                                </Card>
                            </div>

                            <div className={ this.state.tbbTarget=="CommunicationBox" || this.state.$windowWidth>=$mobileMaxWidth?"CommunicationBox block":" CommunicationBox none"}> 
                                <TitleSegment>Communications</TitleSegment>

                                <CommunicationForm matterId = {this.state.matter} />

                            </div>

                            <div className={ this.state.tbbTarget=="DocumentsBox" || this.state.$windowWidth>=$mobileMaxWidth?"DocumentsBox block":"DocumentsBox none"}> 
                                <TitleSegment>Outstandings and Documents</TitleSegment>
                                
                                <DataTableOutstandingItems  matterId = {this.state.matter} /> 
                                <h6>{this.state.lender=="TicToc"?"Backchanneled Documents":"Documents"}</h6>
                                <Card> 
                                     <DataTableDocuments matterId = {this.state.matter}  />  
                                </Card>
                                
                                                               
                            </div>

                        
                        </div>  */}
                        
                        
                         

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
MatterPage=AutoLogout(MatterPage);
export default MatterPage
