import React, { Component } from 'react'

import LabelForm from '../../atom/LabelForm/LabelForm';
import TextCapsule from '../../atom/TextCapsule/TextCapsule';
 
import Accordion from '../../organism/Accordion/Accordion';
 
import './css/LoanDetails.css';
import { MatterService } from '../../../services/matter-service';
import Moment from "moment";
import LabelCount from '../../atom/LabelCount/LabelCount';

class LoanDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            matter: [],
            isLoanSummaryExpanded: true,
            expanded: false,
            shouldRedirect: false,
            errorMessage: null,
            linkedMatters: [],
            strLinkedMatters: "",
            //ssourl: AuthService.getSSOUrl(),
          };
         this.handleAccordionCallback = this.handleAccordionCallback.bind(this);
    }

    handleAccordionCallback(child){
        //alert(child);
        if(child[0] == "LoanSummary"){
            this.setState({
                isLoanSummaryExpanded: child[1],
              });
        }
        else{
            this.setState({
                expanded: child[1],
              });
        }       
      
    }

    componentDidMount(){
        this.setState({
            loading: true,
            matter: [],
          });
      
          MatterService.getMatterDetail(this.props.matterId).then(
            resp => {
              this.setState(
                {
                  matter: resp.data.Content,
                  loading: false,
                  //linkedMatters: resp.data.Content.LinkedMattersString != null && resp.data.Content.LinkedMattersString != "" ? resp.data.Content.LinkedMattersString.split(', ') : []
                },
                () => {
                  this.setTitle();               
                }
              );
            },
            err => {
              this.setState({
                matter: [],
                loading: false,
                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
              });
            }
          );


          MatterService.getLinkedMatters(this.props.matterId).then(
            resp => {
             // console.log("resp")
                //console.log(resp.data[0].LinkedMatterId)
              this.setState(                {
                  strLinkedMatters: resp.data ,
                 //linkedMatters: resp.data != null && resp.data != "" ? resp.data.includes(',')?resp.data.split(', ') : resp.data : []
                 linkedMatters: resp.data,//resp.data != null && resp.data != "" ? resp.data.split(', ') : []
                },
                () => {
                  //this.setTitle(); 
                     //console.log("linkedMattersirene")
                     console.log(this.state.linkedMatters)  
                     //this.GenerateLinkedMatters(this.state.linkedMatters)
                }
              );
            },
            err => {
              this.setState({
                matter: [],
                loading: false,
                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
              });
            }
          );
          
    }

    setTitle() {
        document.title = "Loantrak - " + this.state.matter.MatterDescription;
    }

    viewLinkMatter(matterId) {
        window.open('/matter-details:'+matterId)
    }

    GenerateMatter(matter) {
        if (matter === undefined) {
          return <div />;
        }
        let dischargeType = <div />;
        
        if (matter.DischargeTypeName != null) {
            dischargeType = (
                <LabelForm label="Discharge Type" count={matter.DischargeTypeName ? matter.DischargeTypeName : "-"} />
            );
        }
        let loanType = <div />;
        if (matter.MatterTypeName != null) {
          loanType = (
            <>             
                {matter.MatterTypes
                  ? matter.MatterTypes.reverse().map(matType => (                     
                        
                        <LabelForm label="Loan Type" count={matType.MatterTypeName} />
                     
                    ))
                  :  <LabelForm label="Loan Type" count={" - "}/>}
              </>          
           
          );
        }
        let mortgageManager="";
        if(matter.MortMgrId != null){
            mortgageManager=(
                <LabelForm label="Mortgage Manager" count={matter.MortMgrName} />
            )
        }
        let emailOwner = "";
        if (
          matter.FileOwnerUsername === "Discharges Team" ||
          matter.FileOwnerUsername === "Discharges.Team"
        ) {
          emailOwner = (
            
            <a
            href={
              "mailto:discharges.nsw@msanational.com.au')" +
              "?Subject=LoanTrak%20Query%20-%20" +
              matter.MatterDescription.replace("&", "%26").replace(
                " ",
                "%20"
              ) +
              "%20(MSA%20Ref:%20" +
              matter.MatterId +
              ")"
            }
            className="matterDetailValue"
          >
            discharges.nsw@msanational.com.au
          </a>
             
           
          );
        } else {
          emailOwner = (
            <a
              href={
                "mailto:" +
                (matter.FileOwnerEmail
                  ? matter.FileOwnerEmail
                  : matter.FileOwnerUsername + "@msanational.com.au") +
                "?Subject=LoanTrak%20Query%20-%20" +
                // matter.MatterDescription.replace("&", "%26").replace(
                //   " ",
                //   "%20"
                // ) +
                matter.MatterDescription +
                "%20(MSA%20Ref:%20" +
                matter.MatterId +
                ")"
              }
              className="matterDetailValue"
            >
              {matter.FileOwnerEmail
                ? matter.FileOwnerEmail
                : matter.FileOwnerUsername + "@msanational.com.au"}
            </a>
    
           
          );
        }
        return (
            <div>
                {/* MSA Reference */}
                <LabelForm label="MSA Reference" count= {matter.MatterId ? matter.MatterId : "-"} />
                {/* MSA File Owner */}
                <LabelForm label="MSA File Owner" count= {matter.FileOwnerFullName ? matter.FileOwnerFullName : "-"} />
                {/* MSA File Owner */}
                <LabelForm label="File Owner Email" count={emailOwner} />
                <LabelForm label="File Owner Phone" count={matter.FileOwnerPhone ? matter.FileOwnerPhone : "-" } />
                {/* Lender */}
                <LabelForm label="Lender" count={matter.LenderName ? matter.LenderName : "-"} />
                {/* Mortgage Manager */}
                {mortgageManager}
                {/* Lender Reference */}
                <LabelForm label="Lender Reference" count= {matter.LenderRefNo ? matter.LenderRefNo : "-"} />
                {/* <LabelForm label="Lender Reference" count= {matter.LenderRefNo ? matter.LenderRefNo + (matter.SecondaryRefNo ?  ", " + matter.SecondaryRefNo : "") : "-"} /> */}
                {/* Secondary Lender Reference */}
                {/* {this.state.secondaryReferenceAlias == null || this.state.secondaryReferenceAlias == "" ? '' : (<><strong>{this.state.secondaryReferenceAlias}:</strong> {this.state.secondaryReferenceValue}<br /></>)} */}
                {matter.SecondaryRefName != null && matter.SecondaryRefName != "" ? 
                    (<LabelForm label={matter.SecondaryRefName == null || matter.SecondaryRefName == "" ? '' : (matter.SecondaryRefName)} count= {matter.SecondaryRefNo == null || matter.SecondaryRefNo == "" ? '-' : (matter.SecondaryRefNo)} />    )
                    : 
                    ""
                }
                {/* <LabelForm label={matter.SecondaryRefName == null || matter.SecondaryRefName == "" ? '' : (matter.SecondaryRefName)} count= {matter.SecondaryRefNo == null || matter.SecondaryRefNo == "" ? '-' : (matter.SecondaryRefNo)} />     */}
                
                
                {/* Borrower Description */}
                <LabelForm label="Borrower Description" count= {matter.MatterDescription ? matter.MatterDescription : "-"} />
                {/* Securities */}
                {this.GenerateAddress(this.state.matter.Securities)}   
                 {/* Facilities */}             
                <LabelForm label="Facilities" count={matter.LoanAccountsString ? matter.LoanAccountsString : "-"} />
                {/* Loan Type */}
                {loanType}
                {/* <LabelForm label="Loan Type" count="Refinance" /> */}
                
                {/* Discharge Type */}
                {dischargeType}
    
                {/* Settlement Date/Time */}                
                <LabelForm label="Settlement Date/Time" count={matter.SettlementDate
                        ? Moment(this.state.matter.SettlementDate).format(
                            "DD/MM/YYYY"
                        ) +
                        " " +
                        Moment(this.state.matter.SettlementTime, "hh:mm:ss").format(
                            "hh:mm A"
                        )
                        : "-"} /> 
                        {
                            
                        }
                {/* <LabelForm label="Linked Matters" count={matter.LinkedMattersString ? <a onClick={() => this.viewLinkMatter()}>{this.state.linkedMatters}</a>: "-"} /> */}
                {/* <LabelForm label="Linked Matters" count={matter.LinkedMattersString ? this.GenerateLinkedMatters(this.state.linkedMatters) : " - "}/>  */}
                {this.GenerateLinkedMatters(this.state.linkedMatters)}
            </div>

            
    
          
        );
    }

    GenerateAddress(securities) {
        if (securities === undefined) {
          return (<LabelForm label="Security" count=" - "></LabelForm>);
        }
    
        return securities.map(sec => (
            <LabelForm label="Security" count={sec.AddressString ? sec.AddressString : ""} />          
        ));
    }

    static GenerateParties(description, parties) {
        if (parties === undefined) {
          return <div />;
        }
        let i = 1;
        let x = 1;
        let y = 1;
        return parties.map(p => (
          <>
            {/* <LabelForm label={p.PartyTypeName}/>   */}
            {/* <TextCapsule addCSS="TCLoanDetails">{p.PartyTypeName + " " + i++} </TextCapsule>             */}
            {p.PartyTypeId==1? <TextCapsule addCSS="TCLoanDetails">{p.PartyTypeName + " " + i++} </TextCapsule>     
            :
            p.PartyTypeId==2 ? <TextCapsule addCSS="TCLoanDetails">{p.PartyTypeName + " " + x++} </TextCapsule>
            :
            <TextCapsule addCSS="TCLoanDetails">{p.PartyTypeName + " " + y++} </TextCapsule>
            }
            <div>
              {p.IsIndividual ? (
                <>                  
                  <LabelForm label="Last Name" count={p.Lastname} />
                  <LabelForm label="First Name" count={p.Firstname} />   
                </>
              ) : (
                <>                  
                    <LabelForm label="Company Name" count={p.CompanyName} />
                </>               
              )}
              <LabelForm label="Party Type" count={p.PartyTypeName} />
              <LabelForm label="Email" count= {p.Email ? p.Email : "-"} />
              <LabelForm label="Phone" count= {p.Phone ? p.Phone : "-"} />
              <LabelForm label="Mobile" count= {p.Mobile ? p.Mobile : "-"} />   
              <LabelForm label="Address" count= {(p.StreetAddress ? p.StreetAddress : "") + " " + (p.Suburb ? p.Suburb : "") + " " +  (p.StateName ? p.StateName : "") + " " + (p.PostCode ? p.PostCode : "") } />  
              <br />
            </div>
          </>
        ));
    }

    GenerateSecurity(securities) {
        let i = 1;
    
        if (securities === undefined) {
          return <div />;
        }
    
        return securities.map(sec => (
          <>  
            <TextCapsule addCSS="TCLoanDetails">{"Security " + i++}</TextCapsule>
            <LabelForm label="Street Address" count={(sec.StreetAddress ? sec.StreetAddress : "") +  " " +                    
                    (sec.Suburb ? sec.Suburb : "") +  " " + 
                    (sec.StateName ? sec.StateName : "") +  " " + 
                    (sec.PostCode ? sec.PostCode : "")} />
            <LabelForm label="Loan Type" count={sec.MatterTypeName} />
            <LabelForm label="Settlement Type" count={sec.SettlementTypeName} />
            {this.GenerateTitle(sec.TitleRefs)}
          </>
        ));
    }
    
    GenerateTitle(title) {
        if (title === undefined) {
          return <div />;
        }
    
        return title.map(t => (
          <>
            <LabelForm label="Title Reference" count={t.TitleReference} />
            <LabelForm label="Plan" count={t.LandDescription} />
            <LabelForm label="Registered" count={t.IsRegistered === true ? "Yes" : "No"} />            
          </>
        ));
    }

    static GenerateFinancials(loanAccounts) {
        if (loanAccounts === undefined) {
          return <div />;
        }
        let i = 1;
    
        return loanAccounts.map(a => (
          <> 
            <TextCapsule addCSS="TCLoanDetails">{"Facility " + i++}</TextCapsule> 
            <LabelForm label="Facility ID" count={a.LoanAccountNo} /> 
            <LabelForm label="Amount" count={a.LoanAmountStr} /> 
            <LabelForm label="Facility Type" count={a.LoanDescription} />          
           </>
        ));
    }

    GenerateContact(primaryContact) {
        if (primaryContact === undefined) {
          return <div />;
        }
        let brokercontact = <div />;
        
        if (this.state.matter.BrokerPrimaryContact != null) {
            let brokeremail = ( 
                <a
                    href={
                    this.state.matter.BrokerPrimaryContact.Email
                        ? "mailto:" +
                        this.state.matter.BrokerPrimaryContact.Email +
                        "?Subject=LoanTrak%20-%20" +
                        this.state.matter.MatterDescription.replace(
                            "&",
                            "%26"
                        ).replace(" ", "%20") +
                        "%20(MSA%20Ref:%20" +
                        this.state.matter.MatterId +
                        ")"
                        : ""
                    }
                
                >
                    {this.state.matter.BrokerPrimaryContact.Email
                    ? this.state.matter.BrokerPrimaryContact.Email
                    : "-"}
                </a>);
            brokercontact = (
                <>
                    {/* Email */}
                    <LabelForm label="Email" count= {brokeremail} />     
                    {/* Mobile */}
                    <LabelForm label="Mobile" count= {this.state.matter.BrokerPrimaryContact.Mobile
                            ? this.state.matter.BrokerPrimaryContact.Mobile
                            : "-"} />                      
                    {/* Phone */}
                    <LabelForm label="Phone" count= {this.state.matter.BrokerPrimaryContact.Phone
                            ? this.state.matter.BrokerPrimaryContact.Phone
                            : "-"} />   
                </>
            );
        }
    
        return (
            <div>
                <LabelForm label="Broker"/>
                <LabelForm label="Name" count= {this.state.matter.BrokerName
                        ? this.state.matter.BrokerName
                        : "-"} />          
                {brokercontact}
            </div>
        );
    }

    static GenerateOtherParty(otherParty) {
        if (otherParty === undefined) {
          return <div />;
        }
    
        return (
          <>
            {/* Name */}
            <LabelForm label="Name" count= {otherParty ? otherParty.Name : "-"} />         
                
            {/* Contact */}
            <LabelForm label="Contact" count= {otherParty ? otherParty.Contact : "-"} />     
            
            {/* Email */}
            <LabelForm label="Email" count= {otherParty ? otherParty.Email : "-"} />
            
            {/* Phone */}
            <LabelForm label="Phone" count={otherParty ? otherParty.Phone : "-"} />           
    
            {/* Fax */}
            <LabelForm label="Fax" count= {otherParty ? otherParty.Fax : "-"} />
                
            {/* Address */}
            <LabelForm label="Address" count= {otherParty ? otherParty.Address : "-"} />
            
            {/* Reference */}
            <LabelForm label="Reference" count= {otherParty ? otherParty.Reference : "-"} />
           
          </>
        );
    }
    
    static GeneratePexa(pexaWorkspace) {
        if (pexaWorkspace === undefined) {
          return <div />;
        }
    
        return pexaWorkspace.map(pexaDetail => (
          <>
            <LabelForm label="Workspace Id" count= {pexaDetail.PexaWorkspaceNo} />
            <LabelForm label="Workspace Status" count= {pexaDetail.PexaWorkspaceStatus} />
            
            {/* <div className="customRow">
                <div className="customLabelCol">Workspace Id:</div>            
                  <span className="matterDetailValue">
                    {pexaDetail.PexaWorkspaceNo}
                  </span>            
              </div>
            <div className="customRow">
                <div className="customLabelCol">Workspace Status:</div>
                <span className="matterDetailValue">
                   {pexaDetail.PexaWorkspaceStatus}
                  </span>
              </div>  
          </div> */}
          </>
        ));
    }  

    // GenerateLinkedMatters(linkedMatters) 
    // {          
    //     if (linkedMatters === undefined || linkedMatters.count == 0 || linkedMatters.count === "") {
    //       return (<>-</>);
    //     }
    //     return linkedMatters.map((linked, i) => (
    //         <div>
    //         {
    //             i==0? <LabelForm label="Linked Matter(s)" count={<a onClick={() => this.viewLinkMatter(linkedMatters[0])}>{linkedMatters[0]}</a>} /> :
    //             <LabelForm label="" count={<a onClick={() => this.viewLinkMatter(linked)}>{linked}</a>} />
    //         }
    //         </div>
    //     ));       
    // }

    GenerateLinkedMatters(linkedMatters) 
    {  
      if (linkedMatters === undefined || linkedMatters.count == 0 || linkedMatters.count === "") {
              return (<>-</>);
            }
            return linkedMatters.map((linked, i) => (
                <div>
                {
                  i==0? 
                  <>
                    {/* for mobile: display everything */}
                    <LabelForm addCSS="linkedMatterMobile " label="Linked Matter(s)" count={<a onClick={() => this.viewLinkMatter(linkedMatters[0].LinkedMatterId)}>{linkedMatters[0].LinkedMatterId}</a>} /> 
                    <LabelForm addCSS="linkedMatterMobile " label="" count={<>Lender Ref: <br />{linkedMatters[0].LinkedLenderRef} </>} /> 
                    <LabelForm addCSS="linkedMatterMobile " label="" count={<>Security: <br />{linkedMatters[0].LinkedMatterSecurities==null?" - " : linkedMatters[0].LinkedMatterSecurities} </>} />

                    {/* for desktop: hover on matter number */}
                    <LabelCount
                        addCSS="linkedMatterDesktopTablet labelTooltip"
                        label="Linked Matter(s)" 
                        count={<a onClick={() => this.viewLinkMatter(linkedMatters[0].LinkedMatterId)}>{linkedMatters[0].LinkedMatterId}</a>}
                    >
                        <span class="tooltipText2">
                          <p>Lender Ref: {linkedMatters[0].LinkedLenderRef}</p>
                          <p>Security: {linkedMatters[0].LinkedMatterSecurities==null?" - " : linkedMatters[0].LinkedMatterSecurities}</p>
                        
                        </span>
                    </LabelCount>
                    {/* <br/> */}
                  </>
                  :
                  <>
                    {/* for mobile: display everything */}
                    <LabelForm addCSS="linkedMatterMobile" label="" count={<a onClick={() => this.viewLinkMatter(linked.LinkedMatterId)}>{linked.LinkedMatterId}</a>} />
                    <LabelForm  addCSS="linkedMatterMobile " label="" count={<>Lender Ref: <br />{linked.LinkedLenderRef} </>} />                     
                    <LabelForm  addCSS="linkedMatterMobile " label="" count={<>Security: <br />{linked.LinkedMatterSecurities==null?" - " : linked.LinkedMatterSecurities} </>} />                    
                    {/* for desktop: hover on matter number */}
                    <LabelCount
                        addCSS="linkedMatterDesktopTablet labelTooltip" 
                        label="" 
                        count={<a onClick={() => this.viewLinkMatter(linked.LinkedMatterId)}>{linked.LinkedMatterId}</a>}
                    >
                        <span class="tooltipText2">
                          <p>Lender Ref: {linked.LinkedLenderRef}</p>
                          <p>Security: {linked.LinkedMatterSecurities==null?" - " : linked.LinkedMatterSecurities}</p>
                        
                        </span>
                    </LabelCount>
                    {/* <br/> */}
                  </>
                    
                }
                </div>
            ));      
    }

    render() {
        let NominatedDate = <div />;
      if (this.state.matter.IsPEXAMatter) {
        if (this.state.matter.PexaDetail != null) {
          if (
            this.state.matter.PexaDetail.hasOwnProperty(
              "NominatedSettlementDate"
            )
          ) {
            NominatedDate = (
                <LabelForm label="Nominated Settlement Date" count= {this.state.matter.PexaDetail.NominatedSettlementDate
                    ? Moment(
                        this.state.matter.PexaDetail.NominatedSettlementDate
                      ).format("DD/MM/YYYY") +
                      " " +
                      Moment(
                        this.state.matter.PexaDetail.NominatedSettlementDate
                      ).format("hh:mm A")
                    : "-"} />
            //   <div className="customRow">
            //     <div className="customLabelCol">Nominated Settlement Date:</div>
            //     <div className="customDataCol">
            //       <span className="matterDetailValue">
            //         {this.state.matter.PexaDetail.NominatedSettlementDate
            //           ? Moment(
            //               this.state.matter.PexaDetail.NominatedSettlementDate
            //             ).format("DD/MM/YYYY") +
            //             " " +
            //             Moment(
            //               this.state.matter.PexaDetail.NominatedSettlementDate
            //             ).format("hh:mm A")
            //           : "-"}
            //       </span>
            //     </div>
            //   </div>             
            );
          }
        }
      }
        let PEXAOption = <div />;
        if (this.state.matter.IsPEXAMatter) {
            PEXAOption = (
                <Accordion 
                    callback={this.handleAccordionCallback}
                    name="PEXA"
                    title="PEXA"                     
                    //iconURL="../../../../images/icons/OutlineDark/Minus.svg"
                    expanded={this.state.expanded}
                >
                    {NominatedDate}
                    {LoanDetails.GeneratePexa(this.state.matter.PexaWorkspaces)}                
                </Accordion>
            //   <PanelBarItem title="PEXA" expanded={false}>
            //     {NominatedDate}
            //     {MatterDetail.GeneratePexa(this.state.matter.PexaWorkspaces)}
            //   </PanelBarItem>
            );

        // let linkedMatters = '';
        //  this.state.linkedMatters.map(n => {
        //     linkedMatters =
        // });
        }
        return ( 
            <>
            <Accordion 
                callback={this.handleAccordionCallback}                
                name="LoanSummary"
                title="Loan Summary" 
                // addCSS="AMOpen"
                // addCSSContent="ACOpen"
                // iconURL="../../../../images/icons/OutlineDark/Minus.svg"
                expanded={this.state.isLoanSummaryExpanded}                
            >
                {this.GenerateMatter(this.state.matter)}
                
            </Accordion>
            {PEXAOption}

            <Accordion 
                callback={this.handleAccordionCallback}
                name="PartyDetails"
                title="Party Details"  
                //iconURL="../../../../images/icons/OutlineDark/Plus.svg"
                expanded={this.state.expanded}
            >
                {LoanDetails.GenerateParties(
                    this.state.matter.MatterDescription,
                    this.state.matter.Parties
                )}
            </Accordion>

            <Accordion 
                callback={this.handleAccordionCallback}
                name="Securities"
                title="Securities"  
                //iconURL="../../../../images/icons/OutlineDark/Plus.svg"
                expanded={this.state.expanded}
            >
                {this.GenerateSecurity(this.state.matter.Securities)}
            </Accordion>

            <Accordion 
                callback={this.handleAccordionCallback}
                name="Financials"
                title="Financials"  
                //iconURL="../../../../images/icons/OutlineDark/Plus.svg"
                expanded={this.state.expanded}
            >
                {LoanDetails.GenerateFinancials(
                    this.state.matter.LoanAccounts
                )}
            </Accordion>

            <Accordion 
                callback={this.handleAccordionCallback}
                name="Contacts"
                title="Contacts"  
                //iconURL="../../../../images/icons/OutlineDark/Plus.svg"
                expanded={this.state.expanded}
            >
                {this.GenerateContact(this.state.matter.LenderPrimaryContact)}
            </Accordion>

            <Accordion 
                callback={this.handleAccordionCallback}
                name="OtherParties"
                title="Other Parties"  
                //iconURL="../../../../images/icons/OutlineDark/Plus.svg"
                expanded={this.state.expanded}
            >
                {LoanDetails.GenerateOtherParty(
                  this.state.matter.OtherPartyDetails
                )}
            </Accordion> 
            </>
        )
    }
}

export default LoanDetails
