import React, { Component } from 'react'
 
import HighlightText from '../../atom/HighlightText/HighlightText'; 

import { Redirect }                                         from 'react-router-dom';
import { MatterService }                                    from '../../../services/matter-service';


 
import './css/DataTable.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class DataTable extends Component {
    constructor(props){
        super(props);
        this.state={
            loading: false,
            matters: [],
            shouldRedirect: false,
            matterStatusHighlight: 'HighlightBlue',
        }
        this.rowClick = this.rowClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        MatterService.getRecentMatters().then(resp => {
           this.setState({
               matters: resp.data.Content,
               loading: false
           });
        }, (err) => {
            if ( err.response && err.response.status === 401) {
                this.setState({
                    matter: [],
                    loading: false,
                    shouldRedirect: true
                });
            } else {
                this.setState({
                    matter: [],
                    loading: false,
                    shouldRedirect: true
                });

               
            }

        });
    }

    rowClick = (e, matterId)  => {
          //if(e.target!==-1){
            //alert(e.target.id);
            //alert(matterId);
            window.open('/matter-details:'+matterId)
          //}
       
        //if(event.rowIdx!==-1 &&window.innerWidth>768){
            //window.open('/matter')
            //window.open('/matter:'+event.dataItem.MatterId)
        //     }
        // if(event.rowIdx!==-1 &&window.innerWidth<=768){
        //     this.context.router.history.push(
        //        '/loan-details:'+event.dataItem.MatterId
        //        )
        //     }
        //this.context.router.history.push(
        //    '/loan-details:'+event.dataItem.MatterId    
        //)
    }

    render() {

        if(this.state.loading)
        {
            return(<ProgressDisplay></ProgressDisplay>);
        }

        if (this.state.shouldRedirect) {
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return (<Redirect to='/error' />);
        }
        let grid='';
        if(this.state.matters != null){
            grid=(
                <>            
                    {
                        this.state.matters.map((matter) => {  
                            var matterlen = matter.MatterId.toString().length;              
                            return (
                                // <CheckBox className="chkStates" handleCheckChildElement={this.handleCheckChildElement}  {...rstates} />
                                // <div className="dtRow" id={matter.MatterId} onClick={(e)=>this.rowClick(e)} >
                                <div className="dtRow" id={matter.MatterId} onClick={(e)=>this.rowClick(e, matter.MatterId)} >
                                    <div className="dtRowSmallBold">
                                        <div className="dtHeaderMobile">MSA Ref</div>
                                        {('0000000' + matter.MatterId).slice(matterlen)}
                                    </div> 
                            
                                    <div>
                                        <div className="dtHeaderMobile">Borrowers</div>
                                        {matter.Description.length > 30? matter.Description.slice(0,30)+"..." : matter.Description }
                                    </div>
                            
                                    <div>
                                        <div className="dtHeaderMobile">Lender Ref</div>
                                        {matter.LenderRefNo}
                                    </div>
    
                                    <div>
                                        <div className="dtHeaderMobile">Current Status</div> 
                                        {/* {matter.MatterStatusCategory} */}
                                        {/* <HighlightText type="HighlightYellow">{matter.MatterStatus}</HighlightText> */}
                                        <HighlightText type={matter.MatterStatusCategory == 1? "HighlightGreen" : (matter.MatterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.MatterStatus}</HighlightText>
                                    </div>
    
                                </div> 
                            )
                        })
                    }                
                </>       
            )
        }        

        let datatable = "";

        if(this.state.matters!=null){
            datatable=(
            <>
                <div className="dtHeader">
                    <div>MSA Ref</div>
                    <div>Borrowers</div>
                    <div>Lender Ref</div>
                    <div>Current Status</div>
                </div>

                {grid}
            </>);
        }
        else{
            datatable = (<div>No Recently Viewed Loans :)</div>);
        }
        return ( 
            <div className="DataTable">
                {datatable}

                {/* <div className="dtRow"> 
                    
                    <div className="dtRowSmallBold">
                        <div className="dtHeaderMobile">MSA Ref</div>
                        3173116
                    </div> 
                    
                    <div>
                        <div className="dtHeaderMobile">Borrowers</div>
                        Amy Frances Flintoft
                    </div>
                    
                    <div>
                        <div className="dtHeaderMobile">Lender Ref</div>
                        MN123465789012345
                    </div>

                    <div>
                        <div className="dtHeaderMobile">Current Status</div>
                        <HighlightText type="HighlightBlue">Documents Sent</HighlightText>
                    </div>

                </div>

                <div className="dtRow">

                <div className="dtRowSmallBold">
                        <div className="dtHeaderMobile">MSA Ref</div>
                        3173116
                    </div> 
                    
                    <div>
                        <div className="dtHeaderMobile">Borrowers</div>
                        Amy Frances Flintoft
                    </div>
                    
                    <div>
                        <div className="dtHeaderMobile">Lender Ref</div>
                        MN123465789012345
                    </div>

                    <div>
                        <div className="dtHeaderMobile">Current Status</div> 
                        <HighlightText type="HighlightYellow">Documents on Hold</HighlightText>
                    </div>

                </div>  */}

            </div> 
        )
    }
}

export default DataTable
