import WebApiService                                        from './web-api-service';
import { AuthService }                                      from './auth-service';

class fundService extends WebApiService {

    constructor(props) {
        super(props);
    
        this.SearchFundingItems = this.SearchFundingItems.bind(this);
        // this.getFileOwnerList = this.getFileOwnerList.bind(this);
        // this.getLenderList = this.getLenderList.bind(this);
        // this.getMatterDetail = this.getMatterDetail.bind(this);
        // this.generateCSV = this.generateCSV.bind(this);
        // this.getMatterTypeGroupList = this.getMatterTypeGroupList.bind(this);
        // this.getMortMgrList = this.getMortMgrList.bind(this);
        // this.getRecentMatters = this.getRecentMatters.bind(this);
        // this.getStateList = this.getStateList.bind(this);
        // this.simpleSearch = this.simpleSearch.bind(this);
    }


    SearchFundingItems(srchState, srchStatus, settlementDate, format){
        //alert(borrowers)
        return new Promise((resolve,reject)=>{
            const url = "api/getLenderFundingItems";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'searchStr':srchState,
                    'searchStatus': srchStatus,
                    'searchDate': settlementDate,
                    'format': format      
                }
            };

            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    GetLenderFundingConversation(matterId){
        //alert(borrowers)
        return new Promise((resolve,reject)=>{
            const url = "api/getLenderFundingConversation";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterId,
                    //'format': format
                }
            };

            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    GetMatterDescription(matterId){
        //alert(borrowers)
        return new Promise((resolve,reject)=>{
            const url = "api/getMatterDescription";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterId
                }
            };

            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }

    GetFundingSummary(matterId){
        //alert(borrowers)
        return new Promise((resolve,reject)=>{
            const url = "api/getFundingSummary";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'MatterId':matterId
                }
            };

            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            });
        })
    }
    
    // AddFundingDiscussion(note) {
    //     //window.alert(note.noteTypeId);
    //     return new Promise((resolve, reject) => {

    //         const url = "api/addFundingDiscussion";
    //         let data = new FormData();
    //         data.append('noteTypeId',note.noteTypeId);
    //         data.append('matterId',note.matterId);
    //         data.append('noteHeader',note.noteHeader);
    //         data.append('noteDetail',note.noteDetail)
    //         super.post(url, data).then((response) => {
    //             resolve(response);
    //         }, (err) => {
    //             reject(err);
    //         });
    //     });
    // }

    // AddFundingDiscussion(matterId,convoText, isPriority){
    //     alert(matterId)
    //     alert(convoText)
    //     alert(isPriority)
    //     return new Promise((resolve,reject)=>{
    //         const url = "api/addFundingDiscussion";
    //         const options={
    //             'headers':{
    //                 "Content-Type": "application/json",
    //                 'Authorization':AuthService.token,
    //                 'MatterId': matterId,
    //                 'DiscussionText': convoText,
    //                 'IsPriority': isPriority
    //                 //"LenderId": lenderId
    //             }
    //         }
    //         super.postJSONWithHeaders(url,'',options).then((resp)=>{
    //             resolve(resp);
    //         },(err)=>{
    //             reject(err);
    //         })
    //     })
    // }

    AddFundingDiscussion(matterId,convoText, isPriority){
        //window.alert(note.noteTypeId);
        return new Promise((resolve, reject) => {

            const url = "api/addFundingDiscussion";
            let data = new FormData();
            data.append('MatterId',matterId);
            data.append('DiscussionText',convoText);
            data.append('IsPriority',isPriority);
            super.post(url, data).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    	
    AddFundingRequest(fundingDetails){
        return new Promise((resolve,reject)=>{
            const url = "api/addFundingRequest";
            super.postJSON(url,fundingDetails).then((resp)=>{
                resolve(resp);
            },(err)=>{
                //reject(err);
                if(err.response.data.Message)
                {
                    reject(err.response.data.Message)
                }
                else
                {
                    reject(err);
                }
            })
        })
    }

    GetStates(){
        return new Promise((resolve,reject)=>{
            const url ="api/GetInstructionStates";
            const options={
                'headers':{
                    'Authorization':AuthService.token
                }
            }
            super.get(url,options).then((resp)=>{
                resolve(resp);
            },(err)=>{
                reject(err);
            });
        })
    }

    GetRequestStatusTypes() {

        return new Promise((resolve, reject) => {

            const url = "api/GetFundingRequestStatusType";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }  
}

export const FundService = new fundService();
