import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BrowserRouter, Route }                             from 'react-router-dom'


import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon'; 

import './css/LabelBtn.css';

class LabelBtn extends Component {
    render() {
        var $leftIcon=this.props.leftIcon;; 

        return (
           <div className={"LabelCount LabelBtn"+this.props.addCSS}>
                <div
                    className={$leftIcon?"iconLeft":""}
                    style={{ backgroundImage: `url(${$leftIcon})`}}
                >{this.props.label}</div>
                <div>
                    <ButtonIcon
                        addCSS="ButtonIconSelected ButtonIconSmall btnOpenPDF"
                        iconURL="../../../../images/icons/OutlineWhite/Download.svg"
                    >{this.props.count}</ButtonIcon>
                </div>
            </div>
        )
    }
}

export default LabelBtn
