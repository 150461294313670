import React, { Component } from 'react'

import './css/FormTextArea.css';

class FormTextArea extends Component {
    render() {
        return (
            <>
             <div className="inputBox">
                <textarea 
                    type={this.props.type} 
                    className={"FormTextArea " + this.props.addCSS} 
                    placeholder={this.props.placeholder} 
                    value={this.props.value} 
                    onChange={this.props.onChange} 
                    disabled={this.props.disabled}
                    defaultValue={this.props.defaultValue}
                    rows={this.props.rows}>

                    {this.props.children}
                </textarea>
             </div>
            </>
        )
    }
}

export default FormTextArea
