import React, { Component }                                 from 'react';
import { Redirect }                                         from 'react-router-dom';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import { AuthService } from '../../../services/auth-service';
// import ProgressDisplay                                      from '../../controls/progress-display'
// import { AuthService }                                      from '../../services/auth-service';

class SSOLogout extends Component {

  constructor(props) {
    super(props);
    this.state=({
      loading:true,
      //url: 'https://tictocssouat.msanational.com.au/Home/AllSignOut'
      url: AuthService.getSSOUrl() + 'Home/AllSignOut',
      //url: 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=https://tictocssouat.msanational.com.au/response'
      hasError: false
    })
   

  }
  componentDidMount(){
    //window.alert("ssologout")
    //check first if there is value in memory
    if(localStorage.getItem("ssoTimeout") != null){	
      //get SSO SingleSignOnURL

      AuthService.ssoGetSsoUrl().then(resp => {
        this.setState({
          url: resp.data.Content + 'Home/AllSignOut' //UAT
        }, ()=>{
          AuthService.SSOlogout();
          window.location.href=this.state.url;
          this.setState({loading:false,});
        });
      },(err)=>{
          // this.setState({
          //     errorMessage: err
          //     //errorMessage: 'There is an error with the request'//err
          // })
          //this.red()
      });

      //   AuthService.ssoGetSsoUrl(this.state.userid).then((resp) =>( 
      //     this.setState({
      //       url: resp.data.Content + 'Home/AllSignOut' //UAT
      //         //ssourl: 'https://tictocsso.msanational.com.au/' + 'Home/AllSignOut' //PROD
      //     },()=>{
      //       AuthService.SSOlogout();
      //       window.location.href=this.state.url;
      //       this.setState({loading:false,});
      //     }
      //     ))
      
      //   ), (err) => {            
      
      // } 
    }
    else{
      this.setState({loading:false,hasError:true});
    }
    

    // AuthService.SSOlogout();
    // window.location.href=this.state.url;
    // this.setState({loading:false,});
  }
  render() {
    if(this.state.hasError)
    {
      return( <iframe src={process.env.PUBLIC_URL + 'maintenancepage.html'} width="100%" height="850px" ></iframe>)
    }
    if(this.state.loading){
      return (<ProgressDisplay />);
    }else{
      //return ( <Redirect to={this.state.url} /> );
      window.location.href=this.state.url;
    }
    
  } 
}

export default SSOLogout;
