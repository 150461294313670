import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BrowserRouter, Route }                             from 'react-router-dom'

import './css/LabelForm.css';

class LabelForm extends Component {
    render() {
        var $leftIcon=this.props.leftIcon;; 

        return (
           <div className={"LabelCount "+this.props.addCSS}>
                <div
                    className={$leftIcon?"iconLeft":""}
                    style={{ backgroundImage: `url(${$leftIcon})`}}
                >{this.props.label}</div>
                <div>{this.props.count}</div>
            </div>
        )
    }
}

export default LabelForm
