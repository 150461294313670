import React, { Component } from 'react'

import BoxFullscreen from '../../atom/BoxFullscreen/BoxFullscreen'; 


import './css/CenterContent.css';

class CenterContent extends Component {
    render() {
        return ( 
            <BoxFullscreen id={this.props.id}> 
                {this.props.children} 
            </BoxFullscreen> 
        )
    }
}

export default CenterContent
