import WebApiService                                        from './web-api-service';
import { AuthService }                                      from './auth-service';

class matterService extends WebApiService {

    constructor(props) {
        super(props);
    
        this.detailSearch = this.detailSearch.bind(this);
        this.getFileOwnerList = this.getFileOwnerList.bind(this);
        this.getLenderList = this.getLenderList.bind(this);
        this.getMatterDetail = this.getMatterDetail.bind(this);
        this.generateCSV = this.generateCSV.bind(this);
        this.getMatterTypeGroupList = this.getMatterTypeGroupList.bind(this);
        this.getMortMgrList = this.getMortMgrList.bind(this);
        this.getRecentMatters = this.getRecentMatters.bind(this);
        this.getStateList = this.getStateList.bind(this);
        this.simpleSearch = this.simpleSearch.bind(this);
    }
    CheckUserIsNotBroker(){
        return new Promise((resolve,reject)=>{
            const url="api/CheckUserIsBroker";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            };
            super.get(url,options).then((response)=>{
                resolve(response);
            },(error)=>{
                reject(error);
            })
        });
    }
    CheckIfBrokerRequiresMatterAuthentication(matterId){
        return new Promise((resolve,reject)=>{
            const url="api/CheckIfBrokerRequiresMatterAuthentication";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                    'matterId': matterId
                }
            };
            super.get(url,options).then((response)=>{
                resolve(response);
            },(error)=>{
                reject(error);
            })
        });
    }
    CheckLenderRequiresAuthentication(){
        return new Promise((resolve,reject)=>{
            const url="api/CheckLenderRequiresAuthentication";
            const options={
                'headers':{
                    'Authorization':AuthService.token,
                }
            };
            super.get(url,options).then((response)=>{
                resolve(response);
            },(error)=>{
                reject(error);
            })
        });
    }
    GetLimitedMatterDetails(token){
        return new Promise((resolve,reject)=>{
            const url="api/GetMatterDetailsLimited";
            const options = {
                'headers':{
                    'Token':token
                }
            }

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        })
    }
    GetLimitedMatterDetailsWeb(token){
        return new Promise((resolve,reject)=>{
            const url="api/GetMatterDetailsLimitedWeb";
            const options = {
                'headers':{
                    'Token':token
                }
            }

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        })
    }
    GetBrokerList(){
        return new Promise((resolve,reject)=>{
            const url = "api/GetSearchableBrokers";
            const options = {
                'headers':{
                    'Authorization':AuthService.token,
        
                }
            }

            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            })
        });
    }
    getMatterDetail(matterId) {
        
        return new Promise((resolve, reject) => {

            const url = "api/getMatterForId";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getMatterAnnouncements(matterId) {
        
        return new Promise((resolve, reject) => {

            const url = "api/getMatterAnnouncements";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getLinkedMatters(matterId) {
        
        return new Promise((resolve, reject) => {

            const url = "api/GetLinkedMatters";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getAdvancedSearchColumns() {

        return new Promise((resolve, reject) => {

            const url = "api/getDetailSearchColumns";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    detailSearch(advancedSearch) {
        //console.log("advancedSearch")
        //console.log(advancedSearch)

        return new Promise((resolve, reject) => {

            const url = "api/detailedSearchByPage";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'srchMatterNo': advancedSearch.matterNo,
                    'srchMatterDesc': advancedSearch.borrowers,
                    'srchMatterTypeGroupId': advancedSearch.matterType.id,
                    'srchMatterStatusId': advancedSearch.matterStatus.id,
                    'srchStateId': advancedSearch.matterState.id,
                    'srchFileOwnerUserId': advancedSearch.fileOwner.id,
                    'srchLenderId': advancedSearch.lender.id,
                    'srchMortMgrId': advancedSearch.mortManager.id,
                    'srchLenderRefNo': advancedSearch.lenderRefNo,
                    'srchTitleRef': advancedSearch.titleRefNo,
                    'srchAddress': advancedSearch.address,
                     'srchBrokerId':advancedSearch.broker,
                    'skip': advancedSearch.skip,
                    'take': advancedSearch.pageSize
                }
            };

            super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }


    generateCSV(advancedSearch) {

        return new Promise((resolve, reject) => {

            const url = "api/detailedSearchByPage";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'format':"csv",
                    'srchMatterNo': advancedSearch.matterNo,
                    'srchMatterDesc': advancedSearch.borrowers,
                    'srchMatterTypeGroupId': advancedSearch.matterType.id,
                    'srchMatterStatusId': advancedSearch.matterStatus.id,
                    'srchStateId': advancedSearch.matterState.id,
                    'srchFileOwnerUserId': advancedSearch.fileOwner.id,
                    'srchLenderId': advancedSearch.lender.id,
                    'srchMortMgrId': advancedSearch.mortManager.id,
                    'srchLenderRefNo': advancedSearch.lenderRefNo,
                    'srchTitleRef': advancedSearch.titleRefNo,
                    'srchAddress': advancedSearch.address,
                    'skip': advancedSearch.skip,
                    'take': advancedSearch.pageSize
                }
            };

            super.get( url, options ).then((response) => {
     
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }


    // getRecentMatters() {
    //     return new Promise((resolve, reject) => {

    //         const url = "api/getRecentMatters";
    //         const options = {
    //             "headers": {
    //                 'Authorization': AuthService.token
    //             }
    //         };

    //         super.get( url, options ).then((response) => {
    //             resolve(response);
    //         }, (err) => {
    //             reject(err);
    //         });
    //     });
    // }

    getRecentMatters() {
        return new Promise((resolve, reject) => {

            const url = "api/getRecentMattersv2";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getRecentMatterEvents() {
        return new Promise((resolve, reject) => {

            const url = "api/getRecentMatterEvents";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getRecentMatterEventsDischarges() {
        return new Promise((resolve, reject) => {

            const url = "api/getRecentMatterEventsDischarges";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getRecentMatterEventsFiltered(matterGroupTypeId) {
        return new Promise((resolve, reject) => {

            const url = "api/getRecentMatterEventsFiltered";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'MatterGroupTypeId': matterGroupTypeId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getRecentBackchannelEvents() {
        return new Promise((resolve, reject) => {

            const url = "api/getRecentBackchannelEvents";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    // ---------------------------------------------------------------------------------------------
    //  Retreive data for a simple search
    // ---------------------------------------------------------------------------------------------
    simpleSearch(searchText) {

        return new Promise((resolve, reject) => {

            const url = "api/simpleSearch";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    "searchText": searchText
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getFileOwnerList() {

        return new Promise((resolve, reject) => {

            const url = "api/getFileOwnerList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }

    getLenderList() {

        return new Promise((resolve, reject) => {

            const url = "api/getLenderList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getMatterTypeGroupList() {

        return new Promise((resolve, reject) => {

            const url = "api/GetMatterTypeGroupList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getMortMgrList() {

        return new Promise((resolve, reject) => {

            const url = "api/getMortMgrList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getLinkedMortMgrList() {

        return new Promise((resolve, reject) => {

            const url = "api/getLinkedMortMgrList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getStateList() {

        return new Promise((resolve, reject) => {

            const url = "api/getStateList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getStateViewList() {

        return new Promise((resolve, reject) => {

            const url = "api/GetStateViewList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getMatterStatusList() {

        return new Promise((resolve, reject) => {

            const url = "api/GetMatterStatusList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }
    UserCanQuickSearch(){
        return new Promise((resolve,reject) =>{
            const url ="api/CanQuickSearch";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    UserCannotDownloadCSVReport(){
        return new Promise((resolve,reject) =>{
            const url ="api/CannotDownloadCSVReport";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    UserCannotAddMatterNotes(){
        return new Promise((resolve,reject) =>{
            const url ="api/CannotAddMatterNotes";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    UserCannotViewFiles(){
        return new Promise((resolve,reject) =>{
            const url ="api/CannotViewFiles";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    CheckMatterIsProvisionalOnHold(matterId){
        return new Promise((resolve,reject) =>{
            const url ="api/CheckMatterIsProvisionalOnHold";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                    'matterId': matterId
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    ShouldVerifyQLDSecurityTitle(){
        return new Promise((resolve,reject) =>{
            const url ="api/checkVerifyQLDSecurityTitleRequired";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    VerifyQLDSecurityTitle(titleRef){
        return new Promise((resolve,reject) =>{
            const url ="api/verifyQLDSecurityTitle";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                    'titleRef': titleRef
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    CanUserDoTitleSearchUpload(){	
        return new Promise((resolve,reject) =>{	
            const url ="api/canUserDoTitleSearchUpload";	
            const options={	
                "headers":{	
                    'Authorization':AuthService.token,	
                }	
            }	
            super.get(url,options).then(	
                (response)=>{	
                    resolve(response);	
                },	
                (err)=>{	
                    reject(err);	
                }	
            )	
        }	
        )	
    }

    DisplayPPSRDischarges(){	
        return new Promise((resolve,reject) =>{	
            const url ="api/DisplayPPSRDischarges";	
            const options={	
                "headers":{	
                    'Authorization':AuthService.token,	
                }	
            }	
            super.get(url,options).then(	
                (response)=>{	
                    resolve(response);	
                },	
                (err)=>{	
                    reject(err);	
                }	
            )	
        }	
        )	
    }

    ShouldVerifyNSWSecurityTitle(){
        return new Promise((resolve,reject) =>{
            const url ="api/checkVerifyNSWSecurityTitleRequired";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    VerifyNSWSecurityTitle(titleRef){
        return new Promise((resolve,reject) =>{
            const url ="api/verifyNSWSecurityTitle";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                    'titleRef': titleRef
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    getLoanStatisics(titleRef){
        return new Promise((resolve,reject) =>{
            const url ="api/GetLoanStatistics";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                    'titleRef': titleRef
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    getMatterLatestXml(matterId) {
        
        return new Promise((resolve, reject) => {

            const url = "api/getMatterLatestXml";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    CanUserViewFundingItems(){
        return new Promise((resolve,reject) =>{
            const url ="api/canUserViewFundingItems";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    CanUserViewConversation(){
        return new Promise((resolve,reject) =>{
            const url ="api/canUserViewConversation";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    CanUserAddFundConverstation(){
        return new Promise((resolve,reject) =>{
            const url ="api/canUserAddFundConverstation";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    CanUserChooseMortMgr(){
        return new Promise((resolve,reject) =>{
            const url ="api/canUserChooseMortMgr";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

    canUserAddSecondaryLenderRef(){
        return new Promise((resolve,reject) =>{
            const url ="api/canUserAddSecondaryLenderRef";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }

}

export const MatterService = new matterService();
