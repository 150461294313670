const AnyOption = {
    id: 0,
    value: 'Any'
};


// const AnyOption = ({param}) => {
//     alert(param+"dfdsfsd")
//     const  hello=
//     {
//         id: 0,
//         value: param
//     }
//    return(
    
//    <>{hello}</>
//    )
// };

export default AnyOption;