import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BrowserRouter, Route }                             from 'react-router-dom'
import { Redirect }                                         from 'react-router-dom';

import './css/DataListCard.css';

class DataListCard extends Component {

    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props){
        super(props)
        this.state = {
            shouldRedirect: false,
            path: '',
            arg: '',
            sArg: '',
        }
    }

    nextPath(path, arg, sArg) {
        this.setState({
            path: path,
            arg: arg,
            sArg: sArg,
            shouldRedirect: true,
        }); 
    }

    render() {

        if(this.state.shouldRedirect){
            return(<Redirect to={{ 
                    pathname: this.state.path,
                    state: { searchArg: this.state.arg, loanType: this.state.sArg }
               }}/> )
        } 

        return (
            <div 
                className={"dlcDefault "+this.props.addCSS} 
                onClick={() => this.nextPath(this.props.path, this.props.arg, this.props.sarg)}
            >
                {this.props.children} 
            </div>
        )
    }
}

export default DataListCard
