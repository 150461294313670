import React, { Component } from 'react'
 
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon'; 

 
import './css/Accordion.css';

class Accordion extends Component {
    constructor(props){
        super(props);
        this.state={
            expanded: this.props.expanded,
            iconURL: this.props.iconURL,
            addCSS: this.props.addCSS,
            addCSSContent: this.props.addCSSContent
        }
        this.toggle = this.toggle.bind(this);
    }
    componentDidMount(){
        if(this.state.expanded == false){
            this.setState({
                iconURL: "../../../../images/icons/OutlineDark/Plus.svg",
                addCSS: "",
                addCSSContent: ""
            });
        }
        else{
            this.setState({
                iconURL: "../../../../images/icons/OutlineDark/Minus.svg",
                addCSS: "AMOpen",
                addCSSContent: "ACOpen"
            })   ;           
             
        }
    
    }
    toggle(){
        //alert(this.props.title);
        if(this.props.name !== undefined){
            //this.props.callback(this.props.name);
            if(this.state.expanded == true){
                this.setState({
                    iconURL: "../../../../images/icons/OutlineDark/Plus.svg",
                    addCSS: "",
                    addCSSContent: "",
                    expanded: false
                },()=>{
                    this.props.callback([this.props.name,this.state.expanded]);
                })
            }
            else{
                this.setState({
                    iconURL: "../../../../images/icons/OutlineDark/Minus.svg",
                    addCSS: "AMOpen",
                    addCSSContent: "ACOpen",
                    expanded: true
                },()=>{
                    this.props.callback([this.props.name,this.state.expanded]);
                })              
                 
            }
        }
        
    }

    // toggle(e){
    //     alert("hi");
    //     alert(e);
    //     //alert(this.props.addCSS);
    //     // if(css == "undefined"){
    //     //     alert("hi");
    //     // }
    //     // else{
    //     //     alert("hello");
    //     // }
    // }
    // toggle(){
    //     this.props.callback("irene");
    // }
    render() {
        return ( 
            // <div className="Accordion"  onclick={this.toggle(this.props.addCSS)}> 
            // <div className="Accordion"   onClick={(e) => this.toggle(e.this.props.addCSS)}> 
            // <div className="Accordion" onClick={this.props.onClick}> 
            //     <ButtonIcon 
            //         iconURL={this.props.iconURL} 
            //         addCSS={"AccordionMain biRight "+this.props.addCSS}
            //        // onClick={this.toggle}
            //         >
            //             {this.props.title}
            //     </ButtonIcon>
            //     <div className={"AccordionContent " + this.props.addCSSContent} >
            //         {this.props.children} 
            //     </div> 
            // </div> 

            <div className="Accordion" name={this.props.name}> 
            <ButtonIcon 
                iconURL={this.state.iconURL} 
                addCSS={"AccordionMain biRight "+this.state.addCSS}
                onClick={this.toggle}
            >
                {this.props.title}
            </ButtonIcon>
            <div className={"AccordionContent " + this.state.addCSSContent} >
                {this.props.children} 
            </div> 
        </div> 
        )
    }
}

export default Accordion
