import React, { Component } from 'react' 

import './css/Logo.css';
import BrandLogo from '../../../images/logo/Loantrak.png';

class Logo extends Component {
    render() {
        return (
            <> 
                <div className={"logo "+this.props.addCSS}>
                    <img src={BrandLogo} alt="Loantrak" /> 
                </div>
               
            </>
        )
    }
}

export default Logo
