import React, { Component }                                 from 'react';
import PropTypes                                            from 'prop-types';

class Loader extends Component {

    render() {

        let size;

        switch (this.props.spinnerSize) {
            case '2':
                size = '48px';
                break;
            case '3':
                size = '64px';
                break;
            default:
                size = '32px';
        }

        let spinner = (<span className="loaderSpan k-icon k-i-loading" style={{fontSize: size, color: this.props.color}}></span>);

        return (
            <div className='loaderDiv'>
                {spinner}
            </div>
        );
    }
}

Loader.propTypes = {
    color: PropTypes.string.isRequired,
    spinnerSize: PropTypes.number.isRequired
};

Loader.defaultProps = {
    spinnerSize: 1,
    color: 'orange'
};

export default Loader;