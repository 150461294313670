import React, { Component }                                 from 'react';

import './css/DisplayMessage.css';

class DisplayMessage extends Component {

    render() {

        if (!this.props.message) {
            return ( <div></div> );
        }

        

        let messageContent = this.props.message ? {__html: this.props.message } : { __html: '<div></div>' };

        return (
            <div className={this.props.messageType == "success"?"success-display"  : this.props.messageType == "error" ? "error-display" : "default-display" } dangerouslySetInnerHTML={messageContent}>

            </div>
        )
    }
}

export default DisplayMessage;