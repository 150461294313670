import React from 'react';
import './css/DropDownList.css'

class DropDownList extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <select className="dropdownlist" value={this.props.value.id} selectedoption={this.props.selectedoption} onChange={this.props.onChange}>
                {this.props.options.map((s)=><option value={s.id} id={s.id} selected={this.props.selectedoption==s.id}>{s.value}</option>)}            
            </select>
        );
    }

}
export default DropDownList;