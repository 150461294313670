import React, { Component } from 'react'

import Logo from '../../atom/Logo/Logo';
import IconBox from '../../atom/IconBox/IconBox';

import './css/LogoMenu.css';

class LogoMenu extends Component {
    constructor(props){
        super(props);

        this.state={
            menuUrl: this.props.menuUrl,
        }
    }
    
    render() {
        return (
            <div className="LogoMenu">
                <div>
                    {/* <a href="/dashboard"><Logo /></a> */}
                    <a href={"/"+this.state.menuUrl}><Logo /></a>
                </div>
                <div>
                    <IconBox
                        iconURL="../../../images/interface/iconMenuVeggieDark.svg" tooltip="Compress Menu" 
                        clickAction={this.props.toggleAction}
                        addCSS="btnToggle"
                    />
                </div>
            </div>
            // onClick={()=>{this.props.toggleAction()}} 
        )
    }
}

export default LogoMenu
