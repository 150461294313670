import WebApiService                                        from './web-api-service';
import { AuthService }                                      from './auth-service';

class userLender extends WebApiService {

    constructor(props) {
        super(props);
    
        this.getLenderListForUser = this.getLenderListForUser.bind(this);     
        this.updateLenderUserAccess = this.updateLenderUserAccess.bind(this);     
    }   

    getLenderListForUser() {

        return new Promise((resolve, reject) => {

            const url = "api/getLenderListForUser";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    getUserLenderListCount() {

        return new Promise((resolve, reject) => {

            const url = "api/GetUserLenderListCount";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    getEnabledLenderListForUser() {

        return new Promise((resolve, reject) => {

            const url = "api/GetEnabledUserLenderList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    getDisabledUserLenderList() {

        return new Promise((resolve, reject) => {

            const url = "api/GetDisabledUserLenderList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    simpleUserSearch(searchtext) {

        return new Promise((resolve, reject) => {

            const url = "api/simpleUserSearch";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    "searchtext": searchtext,
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    /* updateLenderUserAccess(UserId) {

        return new Promise((resolve, reject) => {

            const url = "api/updateLenderUserAccess";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    'UserId': UserId,
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           
        });
    } */

    

    getMatterTypeList() {

        return new Promise((resolve, reject) => {

            const url = "api/GetMatterTypeList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }    

    getExternalPrivListForUser() {

        return new Promise((resolve, reject) => {

            const url = "api/GetExternalPrivListForUser";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getStateListForUser() {

        return new Promise((resolve, reject) => {

            const url = "api/GetStateListForUser";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    updateLenderUserAccess(userid) {
        return new Promise((resolve, reject) => {
            const url = "api/updateLenderUserAccess";
           /*  const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    'UserId': userid,
                }
            }; */
            var options = new FormData();
            options.append("UserId", userid);
        
            //let self = this;

            super.post( url, options ).then((response) => {
               // let json = AuthService.parseJson(response);
              //  resolve(json);
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }


    CreateUpdateLenderUser(user) {
        return new Promise((resolve, reject) => {
            const url = "api/CreateUpdateLenderUser";
           /*  const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    'UserId': userid,
                }
            }; */
            var userinfo = new FormData();
            userinfo.append("UserId", user.userid);
            userinfo.append("Username", user.username);
            userinfo.append("Email", user.email);
            userinfo.append("Lastname", user.lastname);
            userinfo.append("Firstname", user.firstname);
            userinfo.append("StateId", user.stateid);
            userinfo.append("UsertypeId", user.usertypeid);
            userinfo.append("LenderId", user.lenderid);
            userinfo.append("MortMgrId", user.mortmgrid);
            userinfo.append("BrokerId", user.brokerid);  
            userinfo.append("RelMgrId", user.relmgrid);          
            userinfo.append("Notes", user.notes);
            userinfo.append("Phone", user.phone);
            userinfo.append("Mobile", user.mobile);
            userinfo.append("Fax", user.fax);   
            userinfo.append("EmailAsUsername", user.emailasusername);
            userinfo.append("Enabled", user.enabled);
            userinfo.append("HasStateRestriction", user.hasstaterestriction);
            userinfo.append("StateRestrictions", user.staterestrictions);
            userinfo.append("HasMatterTypeRestriction", user.hasmattertyperestriction);
            userinfo.append("MatterTypeRestrictions", user.mattertyperestrictions);
            userinfo.append("UserPrivileges", user.userprivileges);
        
            //let self = this;

            super.post( url, userinfo ).then((response) => {
               // let json = AuthService.parseJson(response);
              //  resolve(json);
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getLenderUserDetails(UserId) {

        return new Promise((resolve, reject) => {

            const url = "api/getLenderUserDetails";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    "UserId": UserId,
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    getUserStateRestrictionList(UserId) {

        return new Promise((resolve, reject) => {

            const url = "api/GetUserStateRestrictionList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    "UserId": UserId,
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    getUserMatterTypeRestrictionList(UserId) {

        return new Promise((resolve, reject) => {

            const url = "api/GetUserMatterTypeRestrictionList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json",
                    "UserId": UserId,
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
           /*  super.get( url, options ).then((response) => {
                let data = response.data;
                resolve(data);
            }, (err) => {
                reject(err);
            }); */
        });
    }

    getUserExternalPrivilegesList() {

        return new Promise((resolve, reject) => {

            const url = "api/GetUserExternalPrivilegesList";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    "responseType": "application/json"
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
}

    

export const UserLender = new userLender();
