import React, { Component } from 'react'

 
import IconBox from '../../atom/IconBox/IconBox';

import './css/TextIcon.css';

class TextIcon extends Component {
    render() {
        return ( 
            <div className="TextIcon">
                <div>{this.props.tiText}</div>
                <IconBox 
                    iconURL={this.props.iconURL} 
                />
            </div>
        )
    }
}

export default TextIcon
