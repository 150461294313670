import React, { Component } from 'react'

import CenterContent from '../../template/CenterContent/CenterContent'; 

import Card from '../../atom/Card/Card';
import Logo from '../../atom/Logo/Logo';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';

import './css/MyLoans.css';    
import BrandLogo from '../../../images/logo/MSANational.png';
import { MatterEventService } from '../../../services/matter-event-service';
import { MatterService } from '../../../services/matter-service';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';


class MyLoans extends Component {  
    constructor(props){
        super(props);
        this.state=({
            token: this.props.match.params.token.substring(1,this.props.match.params.token.length),
            userType: this.props.match.params.usertype.substring(1,this.props.match.params.usertype.length),
            eventList:[],
            loading:true,
            borrowerDescription:'',
            lenderReference:[],
            matterId:'',
            secondaryReferenceValue:'',
            secondaryReferenceAlias:'',
            settlementScheduleDate:'',          
            settlementScheduleTime:'',  
            errorMessage: null,
            lastUpdatedDate:'',
            userEmail: '',
            userPhone: '',
            loantrakBaseUrl: '',
            lenderName: '',
            mortmgrName: '',
            mortgageBrokerName: ''
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
        })

        
    }
    componentDidMount(){
        document.title = "Loantrak - My Loan";
        this.setState({
            loading: true,
            eventList: []
          });
        
        MatterService.GetLimitedMatterDetailsWeb(this.state.token).then(
            (resp)=>{
                this.setState({
                    matterId:resp.data.Content.MatterId,
                    borrowerDescription:resp.data.Content.BorrowersInitials,
                    lenderReference:resp.data.Content.LenderReferenceNumbers,
                    secondaryReferenceValue:resp.data.Content.SecondaryLenderReferenceValue,
                    secondaryReferenceAlias:resp.data.Content.SecondaryLenderReferenceAlias,
                    settlementScheduleDate:resp.data.Content.SettlementScheduleDate,
                    settlementScheduleTime:resp.data.Content.SettlementScheduleTime,
                    lastUpdatedDate:resp.data.Content.LastUpdatedDate,
                    userEmail: resp.data.Content.UserEmail,
                    userPhone: resp.data.Content.UserPhone,
                    loantrakBaseUrl: resp.data.Content.LoantrakBaseUrl,
                    lenderName: resp.data.Content.LenderName,
                    mortmgrName: resp.data.Content.MortMgrName,
                    mortgageBrokerName: resp.data.Content.MortgageBrokerName
                },()=>{
                    //console.log("matterdetails",resp.data.Content)
                    //alert(this.state.userType)
                })
            },
            (err)=>{
                this.setState({
                    errorMessage: err,
                })
            }
        )
        
        //MatterEventService.getMatterEventsLimited(this.state.token).then(
        MatterEventService.getMatterEventsLimitedWeb(this.state.token).then(
            
            resp => {
              this.setState({
                eventList: resp.data.Content,
                loading: false
              },()=>
              {
                  //console.log(this.state.eventList);
              });
            },
            err => {
              this.setState({
                eventList: [],
                loading: false,
                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
              });
            }
          );
        
    }


   

    render() { 
        let progress=<div></div>;
        let matteronHold=<div></div>;
        let mailTo = "mailto:" + this.state.userEmail + "?subject=Enquiry - " + this.state.borrowerDescription + " / " + "MSA Reference: " + this.state.matterId;
        let tel = "tel:" + this.state.userPhone;
        let divIcon = <div></div>;
        let websiteUrl = "";
        // if(localStorage.getItem("token") == null){
            
        // }
        // else{
        //     websiteUrl = "http://localhost:7502"+"/matter-details:" + this.state.matterId;
        // }
        if (this.state.loading) {
            return(<ProgressDisplay />);
        }
        else if(this.state.errorMessage != null) {
            return(<CenterContent id="MyLoans">
                <MarginGuide />    
                <div className="content">There is an error with the request. <br/>Please contact MSA support if the issue persists.</div>
            </CenterContent>)
        }
        else {
            if(this.state.userType.toLowerCase() == "bkr"){
                divIcon=(
                    <div class="iconBox">
                        <a href={tel} class="iconRound phone" title="Call Us"></a>
                        <a href={mailTo} class="iconRound email" title="Email Us"></a>
                        {/* <a href={this.state.loantrakBaseUrl+"/matter-details:" + this.state.matterId} target="_blank" class="iconRound globe" title="Visit our website"></a> */}
                        <a href={this.state.loantrakBaseUrl+"/myloan-redirect:" + this.state.matterId} target="_blank" class="iconRound globe" title="Visit our website"></a>
                    </div>
                );
            }
            if(this.state.userType.toLowerCase() == "brr"){
                divIcon=(
                    <div class="iconBox">
                        <a href={tel} class="iconRound phone" title="Call Us"></a>
                        <a href={mailTo} class="iconRound email" title="Email Us"></a>
                    </div>
                );
            }
            if(this.state.eventList.MatterStatusTypeId == 3){
                matteronHold = (<div class="outstandingCard">
                    <div class="scDetails">
                        <div class="scDetailsTitle">Matter On Hold</div>
                        <div class="scDetailsDate">{this.state.eventList.MatterOnHoldDate}</div>
                    </div> 
                </div>);
            }
            progress = this.state.eventList.Items.map((e,i) => (
                // <></>
                // <div class={item.StatusTypeName.toLowerCase() == "complete"? "completedCard"}>
                //     <div class="scDetails">
                //         <div class="scDetailsTitle">Documents Checked and Verified</div>
                //         <div class="scDetailsDate">17/02/2020</div>
                //     </div> 
                // </div>
                // <div className="event item contained">
                //     <div className="event inner">
                        // <div className={"event index "+(e['StatusTypeName'].replace(' ','').toLowerCase()==="inprogress"? e['ProgressBarName']==='Outstanding Requirements'?e['StatusTypeName'].replace(' ','').toLowerCase(): 'notstarted' :e['StatusTypeName'].replace(' ','').toLowerCase()==='starting'?'notstarted':e['StatusTypeName'].replace(' ','').toLowerCase())}>
                        //     {e['StatusTypeName']==='Complete'?<img className="filter-white" width="32px" height="32px"></img>:e['StatusTypeName']==='In Progress'&e['ProgressBarName']==='Outstanding Requirements'?<img className="filter-white" width="32px" height="32px" src={exclamation}></img>:<div style={{width:'24.66px'}}></div>}
                        // </div>
                        <div className={(e['StatusTypeName'] !== null && e['StatusTypeName'] !== "") ? (e['StatusTypeName'].replace(' ','').toLowerCase()=="complete"  ? 'completedCard' : e['StatusTypeName'].replace(' ','').toLowerCase()=="inprogress" &&  e['ProgressBarName']==='Outstanding Requirements' ? 'outstandingCard' : e['StatusTypeName'].replace(' ','').toLowerCase()=="inprogress" ? 'inprogressCard' : e['ProgressBarName']=='notstarted'? 'pendingCard' : 'pendingCard'): 'pendingCard'}>
                            <div class="scDetails">
                                <div className="scDetailsTitle">
                                    {/* <span class="scDetailsTitle">{e['StatusTypeName']==='Complete' && (e['ProgressBarName']==='Settlement Booked'| e['ProgressBarName']==='Settled') ? e['ProgressBarName'] ==='Settled'?e['ProgressBarName'] +' on ' + new Date(e['CompletedDate']).toLocaleDateString() :e['ProgressBarName'] +' for ' + new Date(this.state.settlementScheduleDate).toDateString() :e['ProgressBarName']}</span> */}
                                    <span class="scDetailsTitle">{e['StatusTypeName']==='Complete' && (e['ProgressBarName']==='Settlement Booked'| e['ProgressBarName']==='Settled') ? e['ProgressBarName'] ==='Settled'?e['ProgressBarName'] +' on ' + new Date(e['CompletedDate']).toLocaleDateString() :e['ProgressBarName'] +' for ' + new Date(this.state.settlementScheduleDate).toLocaleDateString() + " " 
                                    + (this.state.settlementScheduleTime != "" ? this.state.settlementScheduleTime.slice(0,5) : '')
                                      + " AEST" :e['ProgressBarName']}</span>
                                    {/* {e['StatusTypeName']==='Complete' && (e['ProgressBarName']==='Settlement Booked'| e['ProgressBarName']==='Settled') ? e['ProgressBarName'] ==='Settled'?e['ProgressBarName'] :e['ProgressBarName'] :e['ProgressBarName']} */}
                                </div>
                                <div className="scDetailsDate">
                                    {/* <span class="scDetailsDate"><i>{e['StatusTypeName']==='Complete' && (e['ProgressBarName']!=='Settlement Booked'&& e['ProgressBarName']!=='Settled') ?new Date(e['CompletedDate']).toLocaleDateString():'--/--/----'}</i></span> */}
                                    {/* {e['StatusTypeName']==='Complete' && (e['ProgressBarName']!=='Settlement Booked'&& e['ProgressBarName']!=='Settled') ?new Date(e['CompletedDate']).toLocaleDateString(): e['StatusTypeName']==='Complete' && (e['ProgressBarName']==='Settlement Booked'| e['ProgressBarName']==='Settled') ? e['ProgressBarName'] ==='Settled'?new Date(e['CompletedDate']).toLocaleDateString() :new Date(this.state.settlementScheduleDate).toLocaleDateString() + " " + new Date(this.state.lastUpdatedDate).toLocaleTimeString() + " AEST":'--/--/----'} */}
                                    {e['StatusTypeName']==='Complete' && (e['ProgressBarName']!=='Settlement Booked'&& e['ProgressBarName']!=='Settled') ?
                                    new Date(e['CompletedDate']).toLocaleDateString() 
                                      + " " + new Date(e['CompletedDate']).toLocaleTimeString([], {
                                        timeStyle: 'short'
                                      }) 
                                      + " AEST": e['StatusTypeName']==='Complete' && (e['ProgressBarName']==='Settlement Booked'| e['ProgressBarName']==='Settled') ? e['ProgressBarName'] ==='Settled'?
                                      new Date(e['CompletedDate']).toLocaleDateString() 
                                      + " " + new Date(e['CompletedDate']).toLocaleTimeString([], {
                                        timeStyle: 'short'
                                      }) 
                                      + " AEST" 
                                      :new Date(this.state.settlementScheduleDate).toLocaleDateString() 
                                      + " " + (this.state.settlementScheduleTime != "" ? this.state.settlementScheduleTime.slice(0,5) : '')
                                      + " AEST":'--/--/----'}
                                </div>
                            </div>
                    </div>
                  
                // </div>
            ));
        } 

        return ( 
             
            <CenterContent id="MyLoans">
            <MarginGuide />    
            <div className="content">
                <Card>
                    <div id="header">
                        {divIcon}
                        {/* <div class="iconBox">
                            <a href={tel} class="iconRound phone" title="Call Us"></a>
                            <a href={mailTo} class="iconRound email" title="Email Us"></a>
                            <a href={this.state.loantrakBaseUrl} target="_blank" class="iconRound globe" title="Visit our website"></a>
                        </div> */}
                        {/* <Logo addCSS="logoBox" /> */}
                        <div class="logoBox"><img src={BrandLogo} alt="Loantrak" /></div>
                    </div>  

                    <div class="contentBox">
                        
                        <div class="dataBox">
                            {/* <div className="title">{this.state.userType.toLowerCase() == "bkr" ? "Loan for " + this.state.borrowerDescription :  "Loan From: " + this.state.lenderName.toUpperCase() + " " + (this.state.mortmgrName != null && this.state.mortmgrName != "" ? this.state.mortmgrName.toUpperCase(): "")}</div> */}
                            <div className="title">{this.state.userType.toLowerCase() == "bkr" ? "Loan for " + this.state.borrowerDescription :  "Loan From: " + this.state.lenderName.toUpperCase()}</div>
                            {this.state.userType.toLowerCase() == "bkr"?
                            <>
                                <strong>MSA Reference:</strong> { ('0000000' + this.state.matterId).slice(this.state.matterId.toString().length)}<br />
                                <strong>Lender Reference:</strong> {this.state.lenderReference}<br />
                                {this.state.secondaryReferenceAlias == null || this.state.secondaryReferenceAlias == "" ? '' : (<><strong>{this.state.secondaryReferenceAlias}:</strong> {this.state.secondaryReferenceValue}<br /></>)}
                                {/* {(this.state.mortmgrName != null && this.state.mortmgrName != "") ? <><strong>Mortgage Broker: </strong> {this.state.mortmgrName.toUpperCase()} <br /></>: ""}  */}
                                {(this.state.mortgageBrokerName != null && this.state.mortgageBrokerName != "") ? <><strong>Mortgage Broker: </strong> {this.state.mortgageBrokerName.toUpperCase()} <br /></>: (this.state.mortmgrName != null && this.state.mortmgrName != "") ? <><strong>Mortgage Manager: </strong> {this.state.mortmgrName.toUpperCase()} <br /></>: ""} 
                                <strong>Last Activity:</strong> {new Date(this.state.lastUpdatedDate).toLocaleDateString() + " " + new Date(this.state.lastUpdatedDate).toLocaleTimeString() + " AEST"} <br />
                                {this.state.settlementScheduleDate != null && this.state.settlementScheduleDate!="0001-01-01T00:00:00"?
                                <>
                                    <strong>Target Settlement Date:</strong>  {new Date(this.state.settlementScheduleDate).toLocaleDateString() + " " + (this.state.settlementScheduleTime) + " AEST"}
                                </>
                                :
                                ""
                                }
                                
                            </>
                            :
                            <>
                                {/* <strong>Mortgage Broker: </strong> {(this.state.mortmgrName != null && this.state.mortmgrName != "" ? this.state.mortmgrName.toUpperCase(): "")} <br /> */}
                                {(this.state.mortgageBrokerName != null && this.state.mortgageBrokerName != "") ? <><strong>Mortgage Broker: </strong> {this.state.mortgageBrokerName.toUpperCase()} <br /></>: (this.state.mortmgrName != null && this.state.mortmgrName != "") ? <><strong>Mortgage Manager: </strong> {this.state.mortmgrName.toUpperCase()} <br /></>: ""} 
                                <strong>Last Activity:</strong> {new Date(this.state.lastUpdatedDate).toLocaleDateString() + " " + new Date(this.state.lastUpdatedDate).toLocaleTimeString() + " AEST"} <br />
                                {/* <strong>Target Settlement Date:</strong>  {new Date(this.state.settlementScheduleDate).toLocaleDateString() + " " + (this.state.settlementScheduleTime) + " AEST"} */}
                                {this.state.settlementScheduleDate != null && this.state.settlementScheduleDate!="0001-01-01T00:00:00"?
                                <>
                                    <strong>Target Settlement Date:</strong>  {new Date(this.state.settlementScheduleDate).toLocaleDateString() + " " + (this.state.settlementScheduleTime) + " AEST"}
                                </>
                                :
                                ""
                                }
                            </>
                            }
                            
                        </div>                        

                        <div class="statusBox">
                            {matteronHold}
                            {progress}
                            
                            {/* <div class="completedCard">
                                <div class="scDetails">
                                    <div class="scDetailsTitle">Documents Checked and Verified</div>
                                    <div class="scDetailsDate">17/02/2020</div>
                                </div> 
                            </div>

                            <div class="outstandingCard">
                                <div class="scDetails">
                                    <div class="scDetailsTitle">Documents Checked and Verified</div>
                                    <div class="scDetailsDate">Following up</div>
                                </div> 
                            </div>

                            <div class="inprogressCard">
                                <div class="scDetails">
                                    <div class="scDetailsTitle">Documents Checked and Verified</div>
                                    <div class="scDetailsDate">In Progress</div>
                                </div> 
                            </div>

                            <div class="pendingCard">
                                <div class="scDetails">
                                    <div class="scDetailsTitle">Documents Checked and Verified</div>
                                    <div class="scDetailsDate">------------</div>
                                </div> 
                            </div> */}

                        </div>
                        
                    </div>
                </Card> 
                 
            </div>
        </CenterContent>  
        )
    }
}
export default MyLoans
