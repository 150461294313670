import React, { Component } from 'react' 
import { Redirect } from 'react-router-dom';
import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import FormCheckBox from '../../atom/FormCheckBox/FormCheckBox';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import IconBox from '../../atom/IconBox/IconBox';
import Line from '../../atom/Line/Line';
import MessageBox from '../../atom/MessageBox/MessageBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import BackBar from '../../molecules/BackBar/BackBar'; 
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox'; 
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import Accordion from '../../organism/Accordion/Accordion'; 
import DataTableDocuments from '../../organism/DataTableDocuments/DataTableDocuments';
import CommunicationContent from '../../organism/CommunicationContent/CommunicationContent';
import CommunicationForm from '../../organism/CommunicationForm/CommunicationForm';
import LoanDetails from '../../organism/LoanDetails/LoanDetails';
import ProgressStep from '../../organism/ProgressStep/ProgressStep';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/NewLoanInstructionFormPage.css';   
import { AuthService } from '../../../services/auth-service';
import { InstructionService } from '../../../services/instruction-service';
import RadioButton from '../../atom/RadioButon/RadioButon';
import RadioButtonGroup from '../../molecule/RadioButtonGroup/RadioButtonGroup';
import {saveAs}         from 'file-saver';
import Button from '../../atom/Button/Button';
import ButtonGroupDischarges from '../../molecule/ButtonGroupDischarges/ButtonGroupDischarges';
import AutoLogout from '../../../auto-logout';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import CheckBox from '../../atom/CheckBox/CheckBox';
import InsightBox from '../../molecules/InsightBox/InsightBox';


class NewLoanInstructionFormPage extends Component { 
    
    constructor(props){
        super(props) 

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }
        let userdetails = AuthService.getUserDetails();	
        let lendername= AuthService.getLendername();
        this.state={ 
            pageTitle : 'newloansinstructionsform',
            leftContent : 'newloansinstructionsform',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            isInstructPriv: null,
            isLoading:false,
            onboarding:true,
            fade:false,
            option:"",
            prompt:"",
            copy:"This page can update MSA's systems, eliminating the need to it for an update to be actioned. only certain lenders are permitted to instruct MSA through the portal. For documentation please contact MSA. You will receive a message asking you to confirm your changes.",
            lenderReference:'',
            ApplicationType:0,
            applicationTypeName:'',
            subApplicationType:0,
            subApplicationTypeOptions:['Current Asset','Future Security','Current Security'],
            ApplicationDateValidGood:true,
            ApplicationDateValid:null,
            ApplicationMortgagee: '',
            ApplicationSendDocsToRecipient: '',
            ApplicationElectronicSigning: 'Yes',
            ApplicationMethod:'',
            ApplicationSalesChannel:'',
            ApplicationClassification:'',
            DisclosureDateValidGood:true,
            DisclosureDateValid:null,
            //subApplicationTypeOptions:{},
            matterNumber:'',
            matterDescription:'',
            isChecking:false,
            isExistingLenderReference:false,
            isNewLoanConfirmed:false,
            hasCheckedReferenceNumber:false,
            errorMessage:'',
            


            
            MappedLoanTermTypes:['Variable','Fixed Rate'],
            //MappedLoanPaymentFrequencies:['Monthly','Fortnightly','Semi-annual','Annual'],      
            MappedLoanPaymentFrequencies:['Weekly','Fortnightly','Monthly','Twice Monthly'],      
            MappedLoanPaymentTypes:['Principal And Interest','Principal Only'],            
            LoanIdEntityCount:0,
            LoanIdEntityMinCount:1,
            LoanIdEntities:[],
            FeeSegmentEntityCount:1,
            FeeSegmentEntityMinCount:1,
            FeeSegmentEntityMaxCount:1,
            FeeSegmentEntities:[],
            FeeSegmentNames:[],
            FeeSegmentFees:[],
            FeeSegmentDisclosureDates:[],            
            FeeSegmentDisclosureDateValid:[],
            FeeSegmentDisclosureDateValidGood:false,

            PartyRepEntityCount:1,
            PartyRepEntitySegmentMinCount:1,
            PartyRepEntitySegmentMaxCount:1,
            PartyRepNames:[],
            PartyRepTypes:[],
            PartyRepEmails:[],
            PartyRepNumbers:[],
            PartyRepEntities:[],
            RelatedPartyRepEntityCount:1,
            RelatedPartyRepEntitySegmentMinCount:1,
            RelatedPartyRepEntitySegmentMaxCount:1,
            RelatedPartyRepNames:[],
            RelatedPartyRepTypes:[],
            RelatedPartyRepEmails:[],
            RelatedPartyRepNumbers:[],
            RelatedPartyRepEntities:[],
            IncomingMortgageeNames:[],
            IncomingMortgageeEmails:[],
            IncomingMortgageeNumbers:[],
            IncomingMortgageeType:[],
            IncomingMortgageeSegmentEntities:[],
            IncomingMortgageeSegmentCount:0,
            IncomingMortgageeSegmentMinCount:0,
            IncomingMortgageeSegmentMaxCount:1,
            //PartySegmentPhoneNumbers:[],
            PartySegmentHomePhoneNumbers:[],
            PartySegmentHomeFaxNumbers:[],
            PartySegmentMobileNumbers:[],
            PartySegmentWorkPhoneNumbers:[],
            PartySegmentWorkFaxNumbers:[],
            PartySegmentPartyTypes:[],
            PartySegmentPorCTypes:[],
            PartySegmentNames:[],
            PartySegmentEmails:[],
            PartySegmentAddresses:[],
            PartySegmentApplicantTypes:[],
            PartySegmentIndependentLegalAdvice:[],
            PartySegmentIndependentFinancialAdvice:[],
            PartySegmentChangeOfName:[],
            PartySegmentChangeOfNameReason:[],
            PartySegmentSignUnderPoA:[],
            PartySegmentGrantGSA:[],
            PartySegmentGrantSSA:[],
            PartySegmentGuarantorLimitedLiability:[],
            PartySegmentGuarantorLiabilityAmount:[],
            PartySegmentGuarantorLiabilityOther:[],
            PartySegmentMinCount:1,
            PartySegmentCount:1,
            PartySegmentEntities:[],
            PartySegmentPrimaryApplicant:[],
            PartySegmentAddreses:[],
            PartySegmentAddressUsedforMailing:[],
            PartySegmentAddressPostSettlement:[],
            PartySegmentAddressPreSettlement:[],
            PartySegmentAddressResidential:[],
            //RelatedPartySegmentPhoneNumbers:[],
            RelatedPartySegmentHomePhoneNumbers:[],
            RelatedPartySegmentHomeFaxNumbers:[],
            RelatedPartySegmentWorkPhoneNumbers:[],
            RelatedPartySegmentWorkFaxNumbers:[],
            RelatedPartySegmentMobileNumbers:[],
            RelatedPartySegmentPartyTypes:[],
            RelatedPartySegmentPorCTypes:[],
            RelatedPartySegmentNames:[],
            RelatedPartySegmentEmails:[],
            RelatedPartySegmentAddresses:[],
            RelatedPartySegmentMinCount:1,
            RelatedPartySegmentCount:1,
            RelatedPartySegmentEntities:[],
            TrustSegmentSettlorNames:[],
            TrustSegmentTrustNames:[],
            TrustSegmentTrustTypes:[],
            TrustSegmentTrustees:[],
            TrustSegmentBeneficiaries:[],
            TrustSegmentDeclarationDates:[],
            TrustSegmentEstablishmentDates:[],
            TrustSegmentVestingDates:[],
            TrustSegmentTrusteeAppointmentDates:[],
            TrustSegmentDeedVariationDates:[],
            OtherPartyTypes:[
                'Broker',                
                'CommissionPayee',
                'CoOwner',
                'Director',
                'Donee',
                'FinancialInstitution',
                'Insurer',
                'MortgageManager',
                'Partner',
                'Solicitor',
                'TrustBeneficiary',
                'TrustTrustee',
                'Other',
                'Payee',
                'Valuer'
            ],
            SecuritySegmentPrimarySecurities:[],
            SecuritySegmentAddreses:[],
            SecuritySegmentTitleReferences:[],
            SecuritySegmentEntities:[],
            SecuritySegmentMinCount:1,
            SecuritySegmentCount:1,
            SecuritySegmentMaxCount:1000,
            SecuritySegmentSettlementActions:[],
            SecuritySegmentConstructions:[],
            SecuritySegmentTrustAssets:[],
            SecuritySegmentPropertyPrimaryPurposes:[],
            SecuritySegmentSecurityHeld:[],
            SecuritySegmentTransactions:[],
            SecuritySegmentResidentialTypes:[],
            SecuritySegmentResidentialOffThePlans:[],
            SecuritySegmentCommercialTypes:[],
            SecuritySegmentResidentialOrCommercialTypes:[],
            SecuritySegmentTitles:[],
            SecuritySegmentLeaseStartDates:[],
            SecuritySegmentLeaseEndDates:[],
            SecuritySegmentEncumbrances:[],
            SecuritySegmentEncumbranceDescription:[],
            SecuritySegmentEncumbranceTypes:[],
            SecuritySegmentEncumbranceRegisteredNumbers:[],
            SecuritySegmentEncumbranceDates:[],
            SecuritySegmentEncumbranceRelatedIds:[],
            SecuritySegmentEncumbranceCreditProviders:[],
            SecuritySegmentFinanceTypes:[],
            SecuritySegmentContractPrices:[],
            SecuritySegmentContractPriceAmount:[],
            SecuritySegmentTransferOfLandAmount:[],
            SecuritySegmentBuildersContractAmount:[],

            FinancialSegmentNonRealEstates:[],
            FinancialSegmentNonRealEstateDepositAccounts:[],
            FinancialSegmentNonRealEstateOtherAssets:[],
            FinancialSegmentMortgages:[],
            FinancialSegmentAssetInsurance:[],
            FinancialSegmentValuation:[],


            


            LoanDetailsLVRNumber:'',
            LoanDetailsFHOG: null,
            LoanDetailsFHOGAmount: '',
            LoanIds:[],            
            LoanTermMonths:[],
            LoanTermTypes:[],
            LoanPaymentFrequencies:[],
            LoanPaymentTypes:[],
            LoanInterestRates:[],            
            IndicativeAmounts:[],  
            CurrentLoanRepaymentAmount:[],
            LoanDefaultInterestRates:[],
            LoanTermIsLocked:[],
            LoanPaymentAmounts:[],
            LoanPurposes:[],
            LoanAmounts:[],
            LoanSegmentLoanPurposeDetails:[],
            LoanSegmentKeyDates:[],
            LoanSegmentLoansToBeRepaid:[],
            LoanSegmentLoanDocPrep:[],

            

            InsuranceSegment:[],
            InsuranceSegmentInsuranceType:[],
            InsuranceSegmentInsuranceDescription:[],
            InsuranceSegmentInsurancePolicyNo:[],
            InsuranceSegmentInsuranceStartDate:[],
            InsuranceSegmentInsuranceEndDate:[],
            InsuranceSegmentPaymentPaidStatus:[],
            InsuranceSegmentPaymentAmountExGST:[],
            InsuranceSegmentPaymentTotal:[],
            InsuranceSegmentPaymentGST:[],
            InsuranceSegmentPaymentBalance:[],
            InsuranceSegmentPremiumPayerType:[],

            SpecialConditionSegmentEntities:[],
            SpecialConditionSegmentCount:0,
            SpecialConditionSegmentConditionValues:[],
            SpecialConditionSegmentConditionTypes:[],

            SpecialConditionSegmentConditionName:'',
            SpecialConditionSegmentConditionDescription:'',

            InstructionsSegment:[],
            InstructionsSegmentDocuments:[],
            InstructionsSegmentDocumentsEntities:[],
            InstructionsSegmentDocumentsEntityCount:1,
            InstructionsSegmentDocumentsEntityMinCount:1,

            CommissionSegment:[],
            CommentsSegment:[],
            Comment:'',
            CommissionSegmentPayerType:[],
            CommissionSegmentPayment:[],
            CommissionSegmentPaymentPaidStatus:[],

            // SecuritySegmentTitleTypes:[],
            // SecuritySegmentTitleTenureTypes:[],
            // SecuritySegmentTitleTorrensExtents:[],
            // SecuritySegmentTitleTorrensStrata:[],
            // SecuritySegmentTitleTorrensExtents:[],
            // SecuritySegmentTitleTorrensExtents:[],
            // SecuritySegmentTitleTorrensExtents:[],
            // SecuritySegmentTitleTorrensExtents:[],
            // SecuritySegmentTitleTorrensExtents:[],
            // SecuritySegmentTitleTorrensExtents:[],
            // SecuritySegmentTitleTorrensExtents:[],
            SimultaneousSettlementSegmentEntities:[],
            SimultaneousSettlementSegmentCount:0,
            SimultaneousSettlementSegmentMaxCount:1,
            SimultaneousSettlementSegmentLoanIds:[],
            LoanHasSimultaneous:false,
            ImportantNoteSegmentEntities:[],
            ImportantNoteSegmentCount:1,
            ImportantNoteSegmentMinCount:1,
            ImportantNoteSegmentMaxCount:1,
            HasImportantNoteSegmentGuarantorTitleDeedToAddress:false,
            ImportantNoteSegmentGuarantorTitleDeedToAddress:[],
            HasBorrowerClearTitleToAddress:false,
            BorrowerClearTitleToAddress:[],
            ImportantNoteSegmentSettlementNotToBeBookedPriorToDates:[],
            ImportantNoteSegmentCollectSurplus:[],
            ImportantNoteSegmentGuarantorNames:[],
            
            DischargeinstructionOtherRequests:'',
            InstructionType:null,
            InstructionLenderReference:null,
            
            MatterExists:false,
            CheckedMatter:false,
            MsaReference:null,
            MatterDescription:null,
            MatterLenderReference:null,
            DateValidGood:true,
            SecurityTypes:[],
            ApplicationTypeChoice:[],
            msg:'',
            SelfActing:[],
            States:[],
            ApplicationTypes:{},
            MappedStates:[],
            ApplicantTransactionList:[
                'AddLoanParty',
                'NoChangeLoanParty',
                'ReleaseLoanParty',
                'UpdateDetailsLoanParty'
            ],
            ChangeNameYNList:[
                'No',
                'YesRegForm',
                'YesStatDec'
            ],
            ChangeNameReasonList:[
                'Adoption of new name',
                'Change of incorporated name',
                'Deed Poll',
                'Error in Register',
                'Marriage',
                'Resumption of maiden name',
            ],
            SendDocsToRecipientList:[
                'Applicant',
                'Branch',
                'Broker',
                'Lender',
                'LenderRep',
                'MortgageManager',
                'Solicitor'
            ],
            SalesChannelList:[
                'Direct',
                'Branch',
                'Broker',
                'Online'
            ],
            DocumentationInstructionsMethodList:[
                'Collect',
                'Digital',
                'Email',
                'Post'
            ],
            ClassificationList:[
                'Prime',
                'SubPrime'
            ],
            MappedPartyTypes:['Person','Company','Partnership'],
            GenderList:['Male',
                'Female'],
            MappedTitles:['Mr','Mrs','Ms','Dr'],
            MappedPartnershipTypes:['JointVenture','Partnership'],
           
            FloorTypes:[
                'Floor Type',
                'Basement',
                'Floor',
                'Ground',
                'Level',
                'LowerGroundFloor',
                'LowerLevel',
                'Mezzanine',
                'ObservationDeck',
                'Parking',
                'Podium',
                'Platform',
                'Penthouse',
                'Rooftop',
                'SubBasement',
                'UpperGroundFloor'
            ],
            CompanyTypes:[
                'Corporation',
                'SoleTrader',
                'Superannuation'
            ],
            DeliveryTypes:[
                'Postal Delivery Type',
                'Care PO',
                'CMA',
                'CMB',
                'GPO Bag',
                'GPO Box',
                'Locked Bag',
                'MS',
                'PO Box',
                'Private Bag',
                'RBN',
                'RMB',
                'RMS',
                'RSD'
            ],
            TrustTypes:[
                'BareTrust',
                'Discretionary',
                'Hybrid',
                'Unit'
            ],
            SettlementActionLists:[
                'ExtendSecurity',
                'ReleaseSecurity',
                'TakeSecurity'
            ],
            PropertyPurposeList:[
                'Business',
                'Investment',
                'OwnerOccupied'
            ],
            PropertyTransactionList:[
                'Owns',
                'Purchasing',
                'Selling'
            ],
            PropertyResidentialTypeList:[
                'CompanyTitleUnit',
                'ConvertedCommercialProperty',
                'House',
                'StrataTitleUnit',
                'StatumTitleUnit',
                'VacantLand'
            ],
            PropertyCommercialTypeList:[
                'Factory',
                'NonSpecialisedCommercial',
                'Offices',
                'ProfChambers',
                'ResidentialCommercial',
                'RetirementVillage',
                'VacantLand',
                'Warehouse'
            ],
            TitleTorrensExtent:[
                'Part',
                'Whole'
            ],
            TitleTenureTypeList:[
                'CompanyTitle',
                'Freehold',
                'Leasehold',
                'StrataTitle',
                'StratumTitle',
            ],
            TitleTypeList:[
                'Company',
                'Stratum',
                'Torrens'
            ],
            UnitTypeList:[
                'Antenna','Apartment','Automatic Teller','Automated Teller Machine','Barbecue','Building','Bungalow','Boatshed','Cage','Carpark','Carspace','Club','Condominium','Coolroom','Cottage','Duplex','Factory','Flat','Garage',
                'Hall','House','Kiosk','Lobby','Loft','Lot','Lease','Marine Berth','Maisonette','Office','Penthouse','Reserve','Room','Suite','Shed','Shop','Showroom','Sign','Site','Stall','Store','Strata Unit','Studio / Studio Apartment',
                'Substation','Tenancy','Townhouse','Tower','Unit','Villa','Vault','Ward','Warehouse','Workshop'
            ],
            StreetTypeList:[
                'Access','Alley','Alleyway','Amble','Anchorage','Approach','Arcade','Arterial','Artery','Avenue','Banan','Bank','Basin','Bay','Beach','Bend','Block','Boardwalk','Boulevard','Boulevarde','Bowl','Brace','Brae','Break','Bridge','Broadway','Brow','Bypass','Byway',
                'Causeway','Centre','Centreway','Chase','Circle','Circlet','Circuit','Circus','Close','Cluster','Colonnade','Common','Concourse','Connection','Copse','Corner','Corso','Course','Court','Courtyard','Cove','Crescent',
                'Crest','Cross','Crossing','Crossroad','Crossway','Cruiseway','Cul-de-Sac','Cutting','Dale','Dell','Dene','Deviation','Dip','Distributor','Divide','Dock','Domain','Drive','Driveway','Edge','Elbow','End','Entrance',
                'Esplanade','Estate','Expressway','Extension','Fairway','Firebreak','Fireline','Firetrack','Firetrail','Flat','Flats','Follow','Footway','Ford','Foreshore','Formation','Freeway','Front','Frontage','Gap','Garden',
                'Gardens','Gate','Gateway','Glade','Glen','Grange','Green','Ground','Grove','Gully','Harbour','Heath','Heights','Highroad','Highway','Hill','Hollow','Hub','Interchange','Intersection','Island','Junction','Key','Keys',
                'Landing','Lane','Laneway','Lees','Line','Link','Little','Lookout','Loop','Lower','Mall','Manor','Meander','Mew','Mews','Motorway','Mount','Nook','Outlet','Outlook','Parade','Park','Parklands','Parkway','Part','Pass',
                'Passage','Path','Pathway','Piazza','Place','Plateau','Plaza','Pocket','Point','Port','Promenade','Pursuit','Quad','Quadrangle','Quadrant','Quay','Quays','Ramble','Ramp','Range','Reach','Reserve','Rest','Retreat','Return',
                'Ride','Ridge','Ridgeway','Right of Way','Ring','Rise','Rising','River','Riverway','Riviera','Road','Roads','Roadside','Roadway','Ronde','Rosebowl','Rotary','Round','Route','Row','Rue','Run','Service Way','Shunt',
                'Siding','Slope','Sound','Spur','Square','Stairs','State Highway','Steps','Strand','Street','Strip','Subway','Tarn','Terrace','Thoroughfare','Throughway','Tollway','Top','Tor','Towers','Track','Trail','Trailer','Triangle',
                'Trunkway','Turn','Twist','Underpass','Upper','Vale','Valley','Viaduct','View','Views','Villas','Vista','Wade','Walk','Walkway','Waters','Waterway','Way','Wharf','Woods','Wynd','Yard'
            ],
            CountryCodeList:[
                'AD','AE','AF','AG','AI','AL','AM','AN','AO','AQ','AR','AS','AT','AU','AW','AX','AZ','BA','BB','BD','BE','BF','BG','BH','BI','BJ','BL','BM','BN','BO','BR','BS','BT','BV','BW','BY','BZ','CA','CC','CD','CF','CG',
                'CH','CI','CK','CL','CM','CN','CO','CR','CU','CV','CX','CY','CZ','DE','DJ','DK','DM','DO','DZ','EC','EE','EG','EH','ER','ES','ET','FI','FJ','FK','FM','FO','FR','GA','GB','GD','GE','GF','GG','GH','GI','GL',
                'GM','GN','GP','GQ','GR','GS','GT','GU','GW','GY','HK','HM','HN','HR','HT','HU','ID','IE','IL','IM','IN','IO','IQ','IR','IS','IT','JE','JM','JO','JP','KE','KG','KH','KI','KM','KN','KP','KR','KW','KY','KZ',
                'LA','LB','LC','LI','LK','LR','LS','LT','LU','LV','LY','MA','MC','MD','ME','MF','MG','MH','MK','ML','MM','MN','MO','MP','MQ','MR','MS','MT','MU','MV','MW','MX','MY','MZ','NA','NC','NE','NF','NG','NI','NL',
                'NO','NP','NR','NU','NZ','OM','PA','PE','PF','PG','PH','PK','PL','PM','PN','PR','PS','PT','PW','PY','QA','RE','RO','RS','RU','RW','SA','SB','SC','SD','SE','SG','SH','SI','SJ','SK','SL','SM','SN','SO','SR',
                'SS','ST','SV','SY','SZ','TC','TD','TF','TG','TH','TJ','TK','TL','TM','TN','TO','TR','TT','TV','TW','TZ','UA','UG','UM','US','UY','UZ','VA','VC','VE','VG','VI','VN','VU','WF','WS','YE','YT','ZA','ZM','ZW'
            ],
            EncumbranceTypeList:[
                'AdministrativeAdvice',
                'Caveat',
                'Charge',
                'Covenant',
                'Easement',
                'LandTaxCharge',
                'Lease',
                'Mortgage',
                'Writ'
            ],
            FinanceTypeList:[
                'ClearTitle',
                'FurtherAdvance',
                'Purchase',
                'RefinanceAttended',
                'RefinanceUnattended'
            ],
            NonPropertyTransactionList:[
                'Owns',
                'Purchasing',
                'Selling',
            ],
            FinancialDepositAccountTypeList:[
                'BankAccount',
                'CashManagement',
                'InvestmentSavings',
                'TermDeposit'
            ],
            OtherAssetTypeList:[
                'Boat',
                'Cash',
                'ChargeOverCash',
                'CirculatingAssets',
                'Collections',
                'DebentureCharge',
                'Gifts',
                'GoodwillOfBusiness',
                'Guarantee',
                'HomeContents',
                'LifeInsurance',
                'ManagedFunds',
                'MotorVehicle',
                'Other',
                'PersonalEquityInAnyPrivateBusiness',
                'Receivables',
                'Shares',
                'StockAndMachinery',
                'Superannuation',
                'ToolsOfTrade'
            ],
            NonPropertyShareTypeList:[
                'BearerSecurities',
                'Debentures',
                'InscribedStockCth',
                'InscribedStockState',
                'ListedShares',
                'Options',
                'UnlistedShares'
            ],
            MotorVehicleTypeList:[
                '4WD',
                'Bike',
                'Large',
                'LuxuryCar',
                'Medium',
                'Small',
                'SmallMedium',
            ],
            MortgagePriorityList:[
                'First',
                'Second',
                'Third',
                'Fourth'
            ],
            AssetInsuranceCategoryList:[
                'Building',
                'Other'
            ],
            AssetInsuranceTypeList:[
                'CertOfCurr',
                'Undertaking'
            ],
            FinancialLoanTypeList:[
                'BusinessLoan',
                'CreditCard',
                'Mortgage',
                'Other',
                'PersonalLoan'
            ],
            PaidStatusList:[
                'Paid',
                'Part Paid',
                'Unpaid'
            ],
            FeeNameList:[
                'EarlyRepayment',
                'Establishment',
                'FixedRate',
                'LegalFeesAndDisb',
                'LenderProtectionFee',
                'LendersMortgageInsurance',
                'LoanApplicationFee',
                'LoanProcessingFee',
                'MortgageRiskFee',
                'Other',
                'ProductFee',
                'ProgressPaymentFee',
                'TitleInsurance',
                'ValuationFee',
            ],
            FeeTypeList:[
                'Origination',
                'Product',
                'Settlement'
            ],
            FeePayableList:[
                'AfterSettlement',
                'AtOrBeforeSettlement'
            ],
            PayerTypeList:[
                'Borrower',
                'Lender'
            ],
            InsuranceTypeList:[
                'ConsumerCreditInsurance',
                'MortgageInsurance',
                'TitleInsurance'
            ],
            ConditionTypeList:[
                'ConditionsPrecedent',
                'SpecialCondition'
            ],
            DocumentIssuerList:[
                'Lender',
                'MSA'
            ],

            PayoutHasSpecialConditions:false,
            PayoutSpecialConditions:'',

            HasAnticipatedDate:false,
            MatterDateValid:null,
            MatterAdditionalNotes:null,
            MatterLoanAccounts:null,
            PayoutStage:'',
            shouldRedirect:false,
            InstructionSubmit:false,
            CanInstructNewLoans: false,
            CanInstructDischarges: false,
            CanProvideDischargePayout: false,
            CanInstructDischargeUpload: false,
            IsLenderThinkTank: false,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            userdetails: userdetails,
            emailValidationError: null,
            profileDisplay: "",	
            lenderName: lendername,


            activeMatterDetails: true,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false,

            activeSection: 'divMatterDetails',
            FormStepsValue:'',
            InsightBox:0, 

        }
    
        this.GoToPartyDetails = this.GoToPartyDetails.bind(this);
        this.BackToMatterDetails = this.BackToMatterDetails.bind(this);
        this.GoToRelatedPartyDetails = this.GoToRelatedPartyDetails.bind(this);
        this.BackToPartyDetails = this.BackToPartyDetails.bind(this);
        this.GoToSecurityDetails = this.GoToSecurityDetails.bind(this);
        this.BackToRelatedPartyDetails = this.BackToRelatedPartyDetails.bind(this);
        this.GoToLoanDetails = this.GoToLoanDetails.bind(this);
        this.BackToSecurityDetails = this.BackToSecurityDetails.bind(this);
        this.GoToFeeDetails = this.GoToFeeDetails.bind(this);
        this.BackToLoanDetails = this.BackToLoanDetails.bind(this);
        this.GoToInsuranceDetails = this.GoToInsuranceDetails.bind(this);
        this.BackFeeDetails = this.BackFeeDetails.bind(this);
        this.GoToSpecialCondition = this.GoToSpecialCondition.bind(this);
        this.BackToInsuranceDetails = this.BackToInsuranceDetails.bind(this);
        this.GoToInstructions = this.GoToInstructions.bind(this);
        this.BackToSpecialCondition = this.BackToSpecialCondition.bind(this);
        this.GoToCommissions = this.GoToCommissions.bind(this);
        this.BackToInstructions = this.BackToInstructions.bind(this);
        this.GoToComments = this.GoToComments.bind(this);
        this.BackToCommissions = this.BackToCommissions.bind(this);
        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.CheckMatter=this.CheckMatter.bind(this);
        this.UpdateLenderReferenceNumber = this.UpdateLenderReferenceNumber.bind(this);
        this.LoanIdUpdated = this.LoanIdUpdated.bind(this);
        this.IndicativeAmountUpdated=this.IndicativeAmountUpdated.bind(this);
        this.UpdateLoanIdSegment=this.UpdateLoanIdSegment.bind(this);
        this.CreateNewLoanInstructionEntity=this.CreateNewLoanInstructionEntity.bind(this);
        this.GetApplicationTypes=this.GetApplicationTypes.bind(this);
        this.GetOtherPartyTypes=this.GetOtherPartyTypes.bind(this);
        this.GetStates = this.GetStates.bind(this);
        this.LoanIdUpdated=this.LoanIdUpdated.bind(this);
        this.LoanInterestRateUpdated = this.LoanInterestRateUpdated.bind(this);
        this.UpdateLoanIdSegment = this.UpdateLoanIdSegment.bind(this);
        this.PartyRepNameUpdated = this.PartyRepNameUpdated.bind(this);
        this.PartyRepEmailUpdated = this.PartyRepEmailUpdated.bind(this);
        this.PartyRepNumberUpdated = this.PartyRepNumberUpdated.bind(this);
        this.UpdatePartyRep = this.UpdatePartyRep.bind(this);
        this.IncomingMortgageeNameUpdated = this.IncomingMortgageeNameUpdated.bind(this);
        this.IncomingMortgageeEmailUpdated=this.IncomingMortgageeEmailUpdated.bind(this);
        this.IncomingMortgageeNumberUpdated = this.IncomingMortgageeNumberUpdated.bind(this);
        this.UpdateIncomingMortgageeSegment = this.UpdateIncomingMortgageeSegment.bind(this);
        this.PartySegmentEmailUpdated = this.PartySegmentEmailUpdated.bind(this);
        // this.PartySegmentNumberUpdated = this.PartySegmentNumberUpdated.bind(this);
        this.PartySegmentWorkPhoneNumberUpdated = this.PartySegmentWorkPhoneNumberUpdated.bind(this);
        this.PartySegmentWorkFaxNumberUpdated = this.PartySegmentWorkFaxNumberUpdated.bind(this);
        this.PartySegmentHomePhoneNumberUpdated = this.PartySegmentHomePhoneNumberUpdated.bind(this);
        this.PartySegmentHomeFaxNumberUpdated = this.PartySegmentHomeFaxNumberUpdated.bind(this);
        this.RelatedPartySegmentWorkPhoneNumberUpdated = this.RelatedPartySegmentWorkPhoneNumberUpdated.bind(this);
        this.RelatedPartySegmentWorkFaxNumberUpdated = this.RelatedPartySegmentWorkFaxNumberUpdated.bind(this);
        this.RelatedPartySegmentHomePhoneNumberUpdated = this.RelatedPartySegmentHomePhoneNumberUpdated.bind(this);
        this.RelatedPartySegmentHomeFaxNumberUpdated = this.RelatedPartySegmentHomeFaxNumberUpdated.bind(this);
        this.PartyAddressUpdated=this.PartyAddressUpdated.bind(this);
        this.PartyTypeUpdated=this.PartyTypeUpdated.bind(this);
        this.PartyNameUpdated = this.PartyNameUpdated.bind(this);
        this.UpdatePartySegment=this.UpdatePartySegment.bind(this);
        this.UpdateRelatedPartySegment=this.UpdateRelatedPartySegment.bind(this);
        this.SecurityAddressUpdated = this.SecurityAddressUpdated.bind(this);
        this.UpdateSecuritySegment = this.UpdateSecuritySegment.bind(this);
        this.UpdateFeeSegment = this.UpdateFeeSegment.bind(this);
        
        this.SimultaneousSettlementSegmentLoanIdUpdated = this.SimultaneousSettlementSegmentLoanIdUpdated.bind(this);
        this.UpdateSimultaneuosSettlementSegment = this.UpdateSimultaneuosSettlementSegment.bind(this);
        this.UpdateGuarantorTitleDeedToAddress = this.UpdateGuarantorTitleDeedToAddress.bind(this);
        this.UpdateBorrowerClearTitleToAddress = this.UpdateBorrowerClearTitleToAddress.bind(this);
        this.SettlementNotToBeBookedPriorToUpdated = this.SettlementNotToBeBookedPriorToUpdated.bind(this);
        this.CollectSurplusUpdated = this.CollectSurplusUpdated.bind(this);
        this.GuarantorUpdated = this.GuarantorUpdated.bind(this);
        this.UpdateImportNotesSegment = this.UpdateImportNotesSegment.bind(this);
        this.UpdateSpecialConditionValues = this.UpdateSpecialConditionValues.bind(this);
        this.UpdateSpecialConditionSegmentTypes = this.UpdateSpecialConditionSegmentTypes.bind(this);
        this.UpdateSpecialConditionSegment = this.UpdateSpecialConditionSegment.bind(this);
        //this.CreateDischargeInstructionEntity =this.CreateDischargeInstructionEntity.bind(this);
        this.SecurityTitleReferenceUpdated = this.SecurityTitleReferenceUpdated.bind(this);
        this.GetApplicationTypes = this.GetApplicationTypes.bind(this);
        this.GetStates = this.GetStates.bind(this);
        this.UpdateLenderReferenceCombined = this.UpdateLenderReferenceCombined.bind(this);
        this.LoanIdUpdated = this.LoanIdUpdated.bind(this);
        this.IndicativeAmountUpdated=this.IndicativeAmountUpdated.bind(this);
        this.DownloadInstructionHtml=this.DownloadInstructionHtml.bind(this);
        this.DownloadInstructionXml=this.DownloadInstructionXml.bind(this);
        this.GetExternalUserPrivilege = this.GetExternalUserPrivilege.bind(this);
        this.GetLenderDetails = this.GetLenderDetails.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
        this.GetDischargeReasonTypes = this.GetDischargeReasonTypes.bind(this);
        this.GetTiles = this.GetTitles.bind(this);
        this.SubmissionDateValidUpdated = this.SubmissionDateValidUpdated.bind(this);
        this.DisclosureDateValidUpdated = this.DisclosureDateValidUpdated.bind(this);    
        this.FeeSegmentDisclosureDateValidUpdated = this.FeeSegmentDisclosureDateValidUpdated.bind(this);    
        this.UpdateInstructionsDocumentsSegment = this.UpdateInstructionsDocumentsSegment.bind(this);

        this.CommentUpdated = this.CommentUpdated.bind(this);
        this.SwitchForm = this.SwitchForm.bind(this);
        //this.UpdateActiveSection = this.UpdateActiveSection.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    
    }

    UpdateActiveSection(e){
        alert(e);
        window.scrollTo(0,0);
        this.setState({
            activeSection: e
        });

        e.preventDefault();
        // let elem = document.getElementById(e);
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
    }

    CommentUpdated(e){
        e.preventDefault();
        this.setState({Comment:e.target.value});
    }

    ApplicationElectronicSigningUpdated(e){
        e.preventDefault();
        let val = this.state.ApplicationElectronicSigning;
        val=e.target.value;
        
        this.setState({ApplicationElectronicSigning:val});
    }

    SubmissionDateValidUpdated(e){
        e.preventDefault();
        this.setState({ApplicationDateValid:e.target.value});
    }

    DisclosureDateValidUpdated(e){
        e.preventDefault();
        this.setState({DisclosureDateValid:e.target.value});
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }
    
    componentDidMount(){
        document.title='Loantrak - New Loans Application Form';
        AuthService.isNewVersionDetected();
        this.setState({LoanIdEntityCount:this.state.LoanIdEntityCount+1},()=>{this.UpdateLoanIdSegment();});
        //this.GetDischargeReasonTypes();
        this.GetStates();
        this.GetExternalUserPrivilege();
        //this.GetLenderDetails();

        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2)	
        {	
            this.setState({	
                IsLenderThinkTank: this.state.lenderName !=null ? this.state.lenderName.toLowerCase().includes('think tank')?true:false : false	
            });	
            // AuthService.GetLenderDetails().then((resp)=>{	
            //     this.setState({	
            //         IsLenderThinkTank:resp.data.Content	
            //     },()=>{console.log(this.state.IsLenderThinkTank);	
            //         //alert(this.state.IsLenderThinkTank)	
            //     });	
            // },(err)=>{	
            //     console.log("there is an error")	
            //     //this.setState({isInstructPriv:false});	
            // })	
        }	


        AuthService.isUserAuthorisedToInstruct().then((resp)=>{
            this.setState({isInstructPriv:true,shouldRedirect:false},
                ()=>{
                    console.log("caninstruct" + "shouldred")
                    console.log(this.state.isInstructPriv )
                    console.log( this.state.shouldRedirect)
                });
        },(err)=>{
            this.setState({
                isInstructPriv:false,
                shouldRedirect:true
            });
        });
        AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({
                CanInstructDischarges:resp.data.Content.CanInstructDischarges,
                CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
                CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
                CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
                shouldRedirect: !resp.data.Content.CanInstructDischarges,
            },()=>{
                console.log(this.state.CanInstructDischarges)
            });
        },(err)=>{
            console.log("there is an error")
            //this.setState({isInstructPriv:false});
        })

        window.addEventListener("resize", this.updateWindowDimension)
    }

    componentWillUpdate(np,ns){
        if(ns!==this.state){
            
        }
    }
    componentDidUpdate(){
        let displayNoneElems = document.querySelectorAll('div[style*="display:none"]');
        for(let y=0; y<displayNoneElems.length;y++){
            let noneInputElems = displayNoneElems[y].getElementsByTagName('input');
            for(let i =0; i<noneInputElems.length;++i){
                if(noneInputElems[i].required){
                    noneInputElems[i].required=false;
                }
            }
        } 
      
    
        
    }

    isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidNumber(number){
        ///^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        const re =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        return re.test(String(number).toLowerCase());
    }

    stringToBool(value){
        if(value && typeof value === "string"){
            if(value === "Yes") return true;
            if(value === "No") return false;
        }
        //return value;
    }


    GoToPartyDetails(e){
        //alert('hi')
        window.scrollTo(0,0);
        e.preventDefault();
        let elem = document.getElementById('instruction-form-matterdetails');
        if(!elem.checkValidity()){
            elem.reportValidity();
            var result = elem.checkValidity();
            //alert('there is error');
            console.log('there is an error');
            console.log(elem.reportValidity());
            console.log(result);
            return;
        }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: true,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false						

        });

        console.log("matterdetails");
        let application={};
        let instruction={};
        // let lenderRef={};
        // lenderRef['UniqueID']=this.state.InstructionLenderReference;
        application['Identifier'] = {'UniqueID':this.state.InstructionLenderReference};//lenderRef;
        // application['Submission']=this.state.ApplicationDateValid;
        // instruction['Disclosure']=this.state.DisclosureDateValid;
        
        //instruction['LenderRef']=this.state.InstructionLenderReference;
        // instruction['ApplicationType']=this.state.applicationTypeName?this.state.applicationTypeName:'New Loan';
        instruction['Application']={
            'TransactionType': this.state.applicationTypeName?this.state.applicationTypeName:'New Loan',
            'Mortgagee': 'this is the lender',
            'SendDocsToRecipient': this.state.SendDocsToRecipient?this.state.SendDocsToRecipient:'Applicant',
            'ElectronicSigning': this.state.ApplicationElectronicSigning,
            'Method':this.state.ApplicationMethod?this.state.ApplicationMethod:'Collect',
            'SalesChannel': this.state.ApplicationSalesChannel?this.state.ApplicationSalesChannel:'Direct',
            'Classification': this.state.ApplicationClassification?this.state.ApplicationClassification:'Prime',
        };

        console.log(application);
        console.log(instruction);
    }

    BackToMatterDetails(e){
        //alert('back')
        window.scrollTo(0,0);
        e.preventDefault();
        
        this.setState({
            activeMatterDetails: true,
            activePartyDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to matter details")
    }

    GoToRelatedPartyDetails(e){
       // alert('hello')
        window.scrollTo(0,0);
        this.setState({
            PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        });

        e.preventDefault();
        let elem = document.getElementById('instruction-form-partydetails');
        if(!elem.checkValidity()){
            elem.reportValidity();
            var result = elem.checkValidity();
            //alert('there is error');
            console.log('there is an error');
            console.log(elem.reportValidity());
            console.log(result);
            return;
        }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: true,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("party details");
        let applicationBatch={};
        let application={};
        let instruction={};
      
       
        //instruction['SubType']=this.state.subApplicationType;
        //instruction['SelfActing']=this.state.SelfActing;
        // var d=new Date(this.state.PartySegmentNames[i]['Birthdate']);
        // var year = d.getFullYear();
        // var month = d.getMonth() < 12 ?  d.getMonth()+ 1 : d.getMonth();
        // var day = d.getDay();

        let partySegment={}; 
        partySegment['Party']=[];
        this.state.PartySegmentPartyTypes.forEach((e,i)=>
        partySegment['Party'].push({
            'Identifier':{'UniqueID':'Applicant'+i.toString()},
            'PrimaryApplicant':this.state.PartySegmentPrimaryApplicant[i]=='Yes'?true:false,
            'Type': this.state.PartySegmentPartyTypes[i],
            'ApplicantTransaction': this.state.PartySegmentApplicantTypes[i],
            'IndependentLegalAdvice': this.state.PartySegmentIndependentLegalAdvice[i],
            'IndependentFinancialAdvice': this.state.PartySegmentIndependentFinancialAdvice[i],
            'ChangeOfName': this.state.PartySegmentChangeOfName[i],
            'ChangeOfNameReason': this.state.PartySegmentChangeOfNameReason[i],
            'SignUnderPoA': this.state.PartySegmentSignUnderPoA=='Yes'?true:false,
            'GrantGSA': this.state.PartySegmentGrantGSA=='Yes'?true:false,
            'GrantSSA': this.state.PartySegmentGrantSSA=='Yes'?true:false,
            'GuarantorLimitedLiability': this.state.PartySegmentGuarantorLimitedLiability[i],
            'GuarantorLiabilityAmount': this.state.PartySegmentGuarantorLiabilityAmount[i],
            'GuarantorLiabilityOther': this.state.PartySegmentGuarantorLiabilityOther[i],
            'Person':{
                'PersonName':{
                    'NameTitle':{
                        'Value':this.state.PartySegmentNames[i]['NameTitle']===''?'Mr':this.state.PartySegmentNames[i]['NameTitle']
                    }
                    ,'FirstName':this.state.PartySegmentNames[i]['GivenName']
                    ,'MiddleNames':this.state.PartySegmentNames[i]['MiddleName']
                    ,'Surname':this.state.PartySegmentNames[i]['Surname']
                },
                'DateOfBirth':{
                    'Date1':{
                        // 'Day':this.state.PartySegmentNames[i]['Birthdate'].getDay(),
                        // 'Month':this.state.PartySegmentNames[i]['Birthdate'].getMonth(),
                        // 'Year':this.state.PartySegmentNames[i]['Birthdate'].getFullYear()
                    }
                }
                    
            },
            'Company':{
                'CompanyName':this.state.PartySegmentNames[i]['CompanyName'],
                'CompanyNumber':{
                    'ACN':this.state.PartySegmentNames[i]['Acn'],
                    'ABN':this.state.PartySegmentNames[i]['Abn'],
                    'ARBN':this.state.PartySegmentNames[i]['Arbn'],
                },
                'CompanyType':{
                    'NumberOfDirectors':this.state.PartySegmentNames[i]['NumberOfDirectors'],
                    'Description':this.state.PartySegmentNames[i]['CompanyTypeDesc'],
                },                    
                
                
                'AttentionTo':{
                    'Position':this.state.PartySegmentNames[i]['CompanyPosition'],
                    'Name':this.state.PartySegmentNames[i]['CompanyAttentionName'],
                },
                'Director':{
                    'RelatedEntityRef':{
                        'RelatedID':''
                    },
                    'Role':this.state.RelatedPartySegmentPartyTypes[i],
                }
            },
            'Partnership':{
                'PartnershipName': this.state.PartySegmentNames[i]['PartnershipName'],
                'PartnershipNumber': {
                    'ABN':this.state.PartySegmentNames[i]['PartnershipAbn'],
                },
                'PartnershipType':{
                    'NumberOfPartners': this.state.PartySegmentNames[i]['PartnershipNumberOfPartners'],
                    'Description': this.state.PartySegmentNames[i]['PartnershipType'],
                },
                'AttentionTo':{
                    'Position':this.state.PartySegmentNames[i]['PartnerPosition'],
                    'Name':this.state.PartySegmentNames[i]['PartnerAttentionName'],
                },
                'Partner':{
                    'RelatedEntityRef':{
                        'RelatedID':''
                    },
                    'Role':this.state.RelatedPartySegmentPartyTypes[i],
                }
            },
            'PartyTrust':{
                'RelatedEntityRef':{
                    'RelatedID':''
                },
            },
            'PowerOfAttorney':{
                'Date':'',
                'DealingNumber':'',
                'RelatedEntityRef':{
                    'RelatedID':''
                },
            },
            'ContactDetails':{
                'AddressDetails':{
                    'RelatedID':'Applicant' + i.toString(),
                    'UsedforMailing':this.state.PartySegmentAddressUsedforMailing[i],
                    'PostSettlement':this.state.PartySegmentAddressPostSettlement[i],
                    'PreSettlement':this.state.PartySegmentAddressPreSettlement[i],
                    'Residential':this.state.PartySegmentAddressResidential[i],
                    'Address':{
                    //     'AddressSimple':{
                    //         'Street':this.state.PartySegmentAddresses[i]['street'],
                    //         'City':this.state.PartySegmentAddresses[i]['city'],
                            'BuildingName': this.state.PartySegmentAddresses[i]['building'],
                            'Floor':{
                                'Number': this.state.PartySegmentAddresses[i]['floor'],
                                'Type': this.state.PartySegmentAddresses[i]['floorType']
                            },
                            'Unit':{
                                'NumberFrom': this.state.PartySegmentAddresses[i]['floor'],
                                'NumberTo': this.state.PartySegmentAddresses[i]['floor'],
                                'Type': this.state.PartySegmentAddresses[i]['floor'],
                            },
                            'Street':{
                                'NumberFrom': this.state.PartySegmentAddresses[i]['floor'],
                                'NumberTo': this.state.PartySegmentAddresses[i]['floor'],
                                'Name': this.state.PartySegmentAddresses[i]['floor'],
                                'Type': this.state.PartySegmentAddresses[i]['floor'],
                            },
                            'POBox':{
                                'DeliveryType': this.state.PartySegmentAddresses[i]['floor'],
                                'Number': this.state.PartySegmentAddresses[i]['floor'],
                            },
                            'OverseasLine1': this.state.PartySegmentAddresses[i]['overseasline1'],
                            'OverseasLine2': this.state.PartySegmentAddresses[i]['overseasline2'],
                            'OverseasLine3': this.state.PartySegmentAddresses[i]['overseasline3'],
                            'OverseasLine4': this.state.PartySegmentAddresses[i]['overseasline4'],
                            'City': this.state.PartySegmentAddresses[i]['city'],
                            'State':{
                                'Name':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state']
                            },
                            'OverseasState':{
                                'Name': this.state.PartySegmentAddresses[i]['overseasstate'],
                            },
                            'Postcode':this.state.PartySegmentAddresses[i]['postcode'],
                            'Country':{
                                'ISO3166': this.state.PartySegmentAddresses[i]['countrycode']
                            }
                    //     }
                        
                     },
                },
                'HomePhone':{
                    'Phone':{
                        'FixedPhone':this.state.PartySegmentHomePhoneNumbers[i]
                    },
                    'Fax':{
                        'FixedPhone':this.state.PartySegmentHomeFaxNumbers[i]
                    }
                },
                'MobilePhone':{
                    'Phone':{
                        'Mobile':this.state.PartySegmentMobileNumbers[i]
                    },                    
                },
                'WorkPhone':{
                    'Phone':{
                        'FixedPhone':this.state.PartySegmentWorkPhoneNumbers[i]
                    },
                    'Fax':{
                        'FixedPhone':this.state.PartySegmentWorkFaxNumbers[i]
                    }
                },
                // 'Phone':{
                //     'Phone1':{
                //         'Item':this.state.PartySegmentMobileNumbers[i]
                //     }
                // },
                // 'WorkPhone':{
                //     'Phone':{
                //         'FixedPhone':''//this.state.PartySegmentPhoneNumbers[i]
                //     }
                // },
                // 'HomePhone':this.state.PartySegmentPhoneNumbers[i],
                // 'MobileNumber':this.state.PartySegmentMobileNumbers[i],
                'Email':this.state.PartySegmentEmails[i]
            }
            // ,
            // 'Address':{'Street':this.state.PartySegmentAddresses[i]['street'],'City':this.state.PartySegmentAddresses[i]['city'],'State':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state'],'Postcode':this.state.PartySegmentAddresses[i]['postcode']},
            // 'PhoneNumber':this.state.PartySegmentPhoneNumbers[i],'MobileNumber':this.state.PartySegmentMobileNumbers[i], 'Email':this.state.PartySegmentEmails[i],'PartyType':e,'PersonCompany':this.state.PartySegmentPorCTypes[i]
            }));
        
            // partySegment['Party'].push({'Identifier':{'UniqueID':'P'+i.toString()},'IsPrimaryApplicant':this.state.PartySegmentPrimaryApplicant[i]=='Yes'?true:false,'Person':{},'Name':{'NameTitle': this.state.PartySegmentNames[i]['NameTitle'],'GivenName':this.state.PartySegmentNames[i]['GivenName'],'MiddleName':this.state.PartySegmentNames[i]['MiddleName'],'Surname':this.state.PartySegmentNames[i]['Surname'],'CompanyName':this.state.PartySegmentNames[i]['CompanyName'],'ACN':this.state.PartySegmentNames[i]['Acn'],'ABN':this.state.PartySegmentNames[i]['Abn']},
            // 'Address':{'Street':this.state.PartySegmentAddresses[i]['street'],'City':this.state.PartySegmentAddresses[i]['city'],'State':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state'],'Postcode':this.state.PartySegmentAddresses[i]['postcode']},
            // 'PhoneNumber':this.state.PartySegmentPhoneNumbers[i],'MobileNumber':this.state.PartySegmentMobileNumbers[i], 'Email':this.state.PartySegmentEmails[i],'PartyType':e,'PersonCompany':this.state.PartySegmentPorCTypes[i]}));
        instruction['PartySegment']=partySegment;

        console.log(application);
        console.log(instruction);
    }

    BackToPartyDetails(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: true,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to party details");
    }

    GoToSecurityDetails(e){
        alert('security')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: true,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });
    }

    BackToRelatedPartyDetails(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: true,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to party details");
    }


    GoToLoanDetails(e){
        alert('loan details')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: true,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });
    }

    BackToSecurityDetails(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: true,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to security details");
    }

    GoToFeeDetails(e){
        alert('fees')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: true,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });
    }

    BackToLoanDetails(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: true,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to loan details");
    }

    GoToInsuranceDetails(e){
        alert('insurance')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: true,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });
    }

    BackFeeDetails(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: true,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to party details");
    }

    GoToSpecialCondition(e){
        alert('special condition')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: true,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });
    }

    BackToInsuranceDetails(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: true,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to insurance details");
    }

    GoToInstructions(e){
        alert('instructions')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: true,
            activeCommissions: false,
            activeComments: false	
        });
    }

    BackToSpecialCondition(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: true,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to special condition");
    }


    GoToCommissions(e){
        alert('commissions')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: true,
            activeComments: false	
        });
    }

    BackToInstructions(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: true,
            activeCommissions: false,
            activeComments: false	
        });

        console.log("back to instructions");
    }

    GoToComments(e){
        alert('comments')
        window.scrollTo(0,0);
        // this.setState({
        //     PartySegmentPorCTypes: this.state.PartySegmentPorCTypes == ''? 'Person': this.state.PartySegmentPorCTypes,           
        // });

        // e.preventDefault();
        // let elem = document.getElementById('instruction-form-partydetails');
        // if(!elem.checkValidity()){
        //     elem.reportValidity();
        //     var result = elem.checkValidity();
        //     //alert('there is error');
        //     console.log('there is an error');
        //     console.log(elem.reportValidity());
        //     console.log(result);
        //     return;
        // }
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: false,
            activeComments: true	
        });
    }

    BackToCommissions(e){
        window.scrollTo(0,0);
        e.preventDefault();
       
        this.setState({
            activeMatterDetails: false,
            activePartyDetails: false,
            activeRelatedPartyDetails: false,
            activeSecurityDetails: false,
            activeLoanDetails: false,
            activeFeeDetails: false,
            activeInsuranceDetails: false,
            activeSpecialCondition: false,
            activeInstructions: false,
            activeCommissions: true,
            activeComments: false	
        });

        console.log("back to commissions details");
    }


    CreateNewLoanInstructionEntity(e){
        window.scrollTo(0,0);
        e.preventDefault();
        let elem = document.getElementById('instruction-form');
        if(!elem.checkValidity()){
            elem.reportValidity();
            var result = elem.checkValidity();
            //alert('there is error');
            console.log('there is an error');
            console.log(elem.reportValidity());
            console.log(result);
            return;
        }
        let applicationBatch={};
        let application={};
        let instruction={};
        // let lenderRef={};
        // lenderRef['UniqueID']=this.state.InstructionLenderReference;
        application['Identifier'] = {'UniqueID':this.state.InstructionLenderReference};//lenderRef;
        // application['Submission']=this.state.ApplicationDateValid;
        // instruction['Disclosure']=this.state.DisclosureDateValid;
        
        //instruction['LenderRef']=this.state.InstructionLenderReference;
        // instruction['ApplicationType']=this.state.applicationTypeName?this.state.applicationTypeName:'New Loan';
        instruction['Application']={
            'TransactionType': this.state.applicationTypeName?this.state.applicationTypeName:'New Loan',
            'Mortgagee': 'this is the lender',
            'SendDocsToRecipient': this.state.ApplicationMortgagee,
            'ElectronicSigning': this.state.ApplicationElectronicSigning,
            'Method':this.state.ApplicationMethod,
            'SalesChannel': this.state.ApplicationSalesChannel,
            'Classification': this.state.ApplicationClassification,
        };
        
       
        //instruction['SubType']=this.state.subApplicationType;
        //instruction['SelfActing']=this.state.SelfActing;

        let partySegment={}; 
        partySegment['Party']=[];
        this.state.PartySegmentPartyTypes.forEach((e,i)=>
        partySegment['Party'].push({
            'Identifier':{'UniqueID':'Applicant'+i.toString()},
            'PrimaryApplicant':this.state.PartySegmentPrimaryApplicant[i]=='Yes'?true:false,
            'Type': this.state.PartySegmentPartyTypes[i],
            'Person':{
                'PersonName':{
                    'NameTitle':{
                        'Value':this.state.PartySegmentNames[i]['NameTitle']===''?'Mr':this.state.PartySegmentNames[i]['NameTitle']
                    }
                    ,'FirstName':this.state.PartySegmentNames[i]['GivenName']
                    ,'MiddleNames':this.state.PartySegmentNames[i]['MiddleName']
                    ,'Surname':this.state.PartySegmentNames[i]['Surname']
                }
            },
            'Company':{
                'CompanyNumber':{
                    'CompanyName':this.state.PartySegmentNames[i]['CompanyName'],
                    'ACN':this.state.PartySegmentNames[i]['Acn'],
                    'ABN':this.state.PartySegmentNames[i]['Abn'],
                    'ARBN':this.state.PartySegmentNames[i]['Arbn'],
                },
                'CompanyType':{
                    'NumberOfDirectors':this.state.PartySegmentNames[i]['NumberOfDirectors'],
                    'Description':this.state.PartySegmentNames[i]['CompanyTypeDesc'],
                },
                'AttentionTo':{
                    'Position':this.state.PartySegmentNames[i]['CompanyPosition'],
                    'Name':this.state.PartySegmentNames[i]['CompanyAttentionName'],
                },
                'Director':{
                    'RelatedEntityRef':{
                        'RelatedID':''
                    },
                    'Role':this.state.RelatedPartySegmentPartyTypes[i],
                }
            },
            'Partnership':{
                'PartnershipName': this.state.PartySegmentNames[i]['PartnershipName'],
                'PartnershipNumber': {
                    'ABN':this.state.PartySegmentNames[i]['PartnershipAbn'],
                },
                'PartnershipType':{
                    'NumberOfPartners': this.state.PartySegmentNames[i]['PartnershipNumberOfPartners'],
                    'Description': this.state.PartySegmentNames[i]['PartnershipType'],
                },
                'AttentionTo':{
                    'Position':this.state.PartySegmentNames[i]['PartnerPosition'],
                    'Name':this.state.PartySegmentNames[i]['PartnerAttentionName'],
                },
                'Partner':{
                    'RelatedEntityRef':{
                        'RelatedID':''
                    },
                    'Role':this.state.RelatedPartySegmentPartyTypes[i],
                }
            },
            'ContactDetails':{
                'AddressDetails':{
                    'RelatedID':'Applicant' + i.toString(),
                    'Address':{
                    //     'AddressSimple':{
                    //         'Street':this.state.PartySegmentAddresses[i]['street'],
                    //         'City':this.state.PartySegmentAddresses[i]['city'],
                            'State':{
                                'Name':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state']
                            },
                            'Postcode':this.state.PartySegmentAddresses[i]['postcode']
                    //     }
                        
                     },
                },
                'HomePhone':{
                    'Phone':{
                        'FixedPhone':this.state.PartySegmentHomePhoneNumbers[i]
                    },
                    'Fax':{
                        'FixedPhone':this.state.PartySegmentHomeFaxNumbers[i]
                    }
                },
                'MobilePhone':{
                    'Phone':{
                        'Mobile':this.state.PartySegmentMobileNumbers[i]
                    },                    
                },
                'WorkPhone':{
                    'Phone':{
                        'FixedPhone':this.state.PartySegmentWorkPhoneNumbers[i]
                    },
                    'Fax':{
                        'FixedPhone':this.state.PartySegmentWorkFaxNumbers[i]
                    }
                },
                // 'Phone':{
                //     'Phone1':{
                //         'Item':this.state.PartySegmentMobileNumbers[i]
                //     }
                // },
                // 'WorkPhone':{
                //     'Phone':{
                //         'FixedPhone':''//this.state.PartySegmentPhoneNumbers[i]
                //     }
                // },
                // 'HomePhone':this.state.PartySegmentPhoneNumbers[i],
                // 'MobileNumber':this.state.PartySegmentMobileNumbers[i],
                'Email':this.state.PartySegmentEmails[i]
            }
            // ,
            // 'Address':{'Street':this.state.PartySegmentAddresses[i]['street'],'City':this.state.PartySegmentAddresses[i]['city'],'State':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state'],'Postcode':this.state.PartySegmentAddresses[i]['postcode']},
            // 'PhoneNumber':this.state.PartySegmentPhoneNumbers[i],'MobileNumber':this.state.PartySegmentMobileNumbers[i], 'Email':this.state.PartySegmentEmails[i],'PartyType':e,'PersonCompany':this.state.PartySegmentPorCTypes[i]
            }));
        
            // partySegment['Party'].push({'Identifier':{'UniqueID':'P'+i.toString()},'IsPrimaryApplicant':this.state.PartySegmentPrimaryApplicant[i]=='Yes'?true:false,'Person':{},'Name':{'NameTitle': this.state.PartySegmentNames[i]['NameTitle'],'GivenName':this.state.PartySegmentNames[i]['GivenName'],'MiddleName':this.state.PartySegmentNames[i]['MiddleName'],'Surname':this.state.PartySegmentNames[i]['Surname'],'CompanyName':this.state.PartySegmentNames[i]['CompanyName'],'ACN':this.state.PartySegmentNames[i]['Acn'],'ABN':this.state.PartySegmentNames[i]['Abn']},
            // 'Address':{'Street':this.state.PartySegmentAddresses[i]['street'],'City':this.state.PartySegmentAddresses[i]['city'],'State':this.state.PartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state'],'Postcode':this.state.PartySegmentAddresses[i]['postcode']},
            // 'PhoneNumber':this.state.PartySegmentPhoneNumbers[i],'MobileNumber':this.state.PartySegmentMobileNumbers[i], 'Email':this.state.PartySegmentEmails[i],'PartyType':e,'PersonCompany':this.state.PartySegmentPorCTypes[i]}));
        instruction['PartySegment']=partySegment;

        // let relatedPartySegment={}; 
        // relatedPartySegment['RelatedParty']=[];
        instruction['RelatedPartySegment']=[];
        this.state.RelatedPartySegmentPartyTypes.forEach((e,i)=>
        instruction['RelatedPartySegment'].push({
            //'RelatedParty':{
                'Identifier':{'UniqueID':'P'+i.toString()},
                'Person':{'PersonName':
                    {
                        //'NameTitle': {'Value':this.state.PartySegmentNames[i]['NameTitle']},
                        'FirstName':this.state.RelatedPartySegmentNames[i]['GivenName'],
                        'MiddleNames':this.state.RelatedPartySegmentNames[i]['MiddleName'],
                        'Surname':this.state.RelatedPartySegmentNames[i]['Surname']}},
                'Company':
                    {
                        'CompanyName':this.state.RelatedPartySegmentNames[i]['CompanyName'],
                        'ACN':this.state.RelatedPartySegmentNames[i]['Acn'],
                        'ABN':this.state.RelatedPartySegmentNames[i]['Abn'],
                        'ARBN':this.state.PartySegmentNames[i]['Arbn'],
                    },
                'ContactDetails':{
                    'AddressDetails':{
                        'Address':{
                            'AddressSimple':{
                                'Street':this.state.RelatedPartySegmentAddresses[i]['street'],
                                'City':this.state.RelatedPartySegmentAddresses[i]['city'],
                                'State':{
                                    'Name':this.state.RelatedPartySegmentAddresses[i]['state']===''?'NSW':this.state.PartySegmentAddresses[i]['state']
                                },
                                'Postcode':this.state.RelatedPartySegmentAddresses[i]['postcode']
                            }
                            
                        }
                    },
                    'HomePhone':{
                        'Phone':{
                            'FixedPhone':this.state.RelatedPartySegmentHomePhoneNumbers[i]
                        },
                        'Fax':{
                            'FixedPhone':this.state.RelatedPartySegmentHomeFaxNumbers[i]
                        }
                    },
                    'MobilePhone':{
                        'Phone':{
                            'Mobile':this.state.RelatedPartySegmentMobileNumbers[i]
                        },                    
                    },
                    'WorkPhone':{
                        'Phone':{
                            'FixedPhone':this.state.RelatedPartySegmentWorkPhoneNumbers[i]
                        },
                        'Fax':{
                            'FixedPhone':this.state.RelatedPartySegmentWorkFaxNumbers[i]
                        }
                    },
                    // 'HomePhone':{
                    //     'Phone':{
                    //         'FixedPhone':this.state.RelatedPartySegmentPhoneNumbers[i]
                    //     }
                    // },
                    // 'MobilePhone':{
                    //     'Phone':{
                    //         'FixedPhone':this.state.RelatedPartySegmentPhoneNumbers[i]
                    //     }
                    // },
                    // 'WorkPhone':{
                    //     'Phone':{
                    //         'FixedPhone':''//this.state.PartySegmentPhoneNumbers[i]
                    //     }
                    // },
                    'Email':this.state.RelatedPartySegmentEmails[i]
                },               
                'RelPartyType': this.state.RelatedPartySegmentPartyTypes[i]

            //}
           
           }));
        
        //instruction['RelatedPartySegment']=relatedPartySegment;

        let loanDetailsSegment={}; 
        loanDetailsSegment['LoanDetails']=[];
        this.state.LoanIds.forEach((e,i)=> loanDetailsSegment['LoanDetails'].push({
            //'LoanId':e,
            'LoanTermMonths':this.state.LoanTermMonths[i],
            'AccountNumber':{
                'AcctNbr':e,
            },
            'CurrentLoanRepayment':{
                'Amount':this.state.IndicativeAmounts[i],
            },
            'AmountRequested':{
                'Amount':this.state.IndicativeAmounts[i],
            },
            
            'LoanTerm':[{
                'Type':this.state.LoanTermTypes[i]==='Variable'?'Variable':'FixedRate',
                'PaymentFrequency':this.state.LoanPaymentFrequencies[i]==='Twice Monthly'?'TwiceMonthly':this.state.LoanPaymentFrequencies[i],
                'InterestRate':this.state.LoanInterestRates[i],
                'PaymentType':this.state.LoanPaymentTypes[i]==='Principal And Interest'?'PrincipalAndInterest':'InterestOnly', 
            }],
            
                     
            
            // 'LoanTermType':this.state.LoanTermTypes[i],
            // 'IndicativeAmount':this.state.IndicativeAmounts[i],
            // 'InterestRate':this.state.LoanInterestRates[i],
            // 'PaymentType':this.state.LoanPaymentTypes[i],          
            // 'PaymentFrequency':this.state.LoanPaymentFrequencies[i]
        }));

        instruction['LoanDetailSegment']=loanDetailsSegment;


        let fees=[]; 
        //fees['Fee']=[];
        this.state.LoanIds.forEach((e,i)=> fees.push({
            //'LoanId':e,
            // 'DisclosureDate':{
            //     'Date':{
            //         'Day':'',
            //         'Month':'',
            //         'Year':''
            //     }
            // },            
            //'Fee':[{
                'Description':'desc',
                'Name':'EarlyRepayment',                
                //'Type':'',
                'WhenPayable':'AfterSettlement', 
            //}],
            
                     
            
            // 'LoanTermType':this.state.LoanTermTypes[i],
            // 'IndicativeAmount':this.state.IndicativeAmounts[i],
            // 'InterestRate':this.state.LoanInterestRates[i],
            // 'PaymentType':this.state.LoanPaymentTypes[i],          
            // 'PaymentFrequency':this.state.LoanPaymentFrequencies[i]
        }));

        var d=new Date(this.state.DisclosureDateValid);
        var year = d.getFullYear();
        var month = d.getMonth() < 12 ?  d.getMonth()+ 1 : d.getMonth();
        var day = d.getDay();
        //var day = ("00"+d.getDate()).slice(d.getDate().toString().length)
        d= day + "/" + ("00"+month).slice(month.toString().length) + "/" + d.getFullYear();

        instruction['FeeSegment']={
            'DisclosureDate':{
                'Date1':{
                    'Day':day,
                    'Month':month,
                    'Year':year
                }
            },   
            'Fee':fees  
        };


        // instruction['FeeSegment']=[];//SettlementSimultaneousWith
        // // //this.state.LoanIds.forEach((v,i)=>{instruction['FeeSegment'].push(v)});//(p,c,i)=>[{'LoanId':this.state.SimultaneousSettlementSegmentLoanIds[i-1]},{'LoanId':this.state.SimultaneousSettlementSegmentLoanIds[i]}]):
        // this.state.LoanIds.forEach((v,i)=>{
        //     instruction['FeeSegment'].push({
        //         'DisclosureDate':{
        //                     'Date1':{
        //                         'Day':'05',
        //                         'Month':'02',
        //                         'Year':'2023'
        //                     }
        //                 },  
        //                 'Fee':[{
        //         'Description':'desv',
        //         'Name':'feeNameList"',                
        //         //'Type':'',
        //         'WhenPayable':'feePayableList"', 
        //     }],
        //              } )});
       

        // instruction['FeeSegment']={
        //     'DisclosureDate':{
        //         'Date':{
        //             'Day':'',
        //             'Month':'',
        //             'Year':''
        //         }
        //     },   
        //     'Fee': this.state.LoanIds.forEach((e,i)=> fees['Fee'].push({
           
        //         'Name':'',
        //         'Description':'',
        //         'Type=':'',
        //         'WhenPayable':'', 
        //     }
            
                     
            
        //     // 'LoanTermType':this.state.LoanTermTypes[i],
        //     // 'IndicativeAmount':this.state.IndicativeAmounts[i],
        //     // 'InterestRate':this.state.LoanInterestRates[i],
        //     // 'PaymentType':this.state.LoanPaymentTypes[i],          
        //     // 'PaymentFrequency':this.state.LoanPaymentFrequencies[i]
        // ))  
        // };
       

        // let relatedPartySegment={}; 
        // relatedPartySegment['RelatedParty']=[];
        // this.state.RelatedPartySegmentPartyTypes.forEach((e,i)=>


        // instruction['RelatedParty']=[];
        // this.state.RelatedPartySegmentPartyTypes.forEach((e,i)=>
        // instruction['RelatedParty'].push({'UniqueID':'P'+i.toString(),'Name':{'GivenName':this.state.RelatedPartySegmentNames[i]['GivenName'],'MiddleName':this.state.PartySegmentNames[i]['MiddleName'],'Surname':this.state.RelatedPartySegmentNames[i]['Surname'],'CompanyName':this.state.RelatedPartySegmentNames[i]['CompanyName'],'ACN':this.state.RelatedPartySegmentNames[i]['Acn'],'ABN':this.state.RelatedPartySegmentNames[i]['Abn']},
        //     'Address':{'Street':this.state.RelatedPartySegmentAddresses[i]['street'],'City':this.state.RelatedPartySegmentAddresses[i]['city'],'State':this.state.RelatedPartySegmentAddresses[i]['state']===''?'NSW':this.state.RelatedPartySegmentAddresses[i]['state'],'Postcode':this.state.RelatedPartySegmentAddresses[i]['postcode']},
        //     'PhoneNumber':this.state.RelatedPartySegmentPhoneNumbers[i],'MobileNumber':this.state.RelatedPartySegmentMobileNumbers[i],'Email':this.state.RelatedPartySegmentEmails[i],'PartyType':e,'RelatedPersonCompany':this.state.RelatedPartySegmentPorCTypes[i]}));
            
        // instruction['ValueItem']=[];
        // this.state.SecuritySegmentAddreses.forEach((p,i)=>
        
        // instruction['ValueItem'].push(
        //     {
        //         'UniqueID':'S'+i.toString(),'TitleRef':this.state.SecuritySegmentTitleReferences[i],
        //         'IsPrimarySecurity':this.state.SecuritySegmentPrimarySecurities[i]=='Yes'? true: false,
        //         'AssetClass':this.state.SecurityTypes[i],
        //         //'SelfActing':this.state.SelfActing[i],
        //         //'SubType':this.state.SecurityTypes[i]==='Sale'?0:this.state.SecurityTypes[i]==='External Refinance'?1:this.state.SecurityTypes[i]==='Internal Refinance'?2:this.state.SecurityTypes[i]==='Debt Repaid'?3:0,
        //         //'SubType':this.state.SecurityTypes[i]==='Sale'?0:this.state.SecurityTypes[i]==='External Refinance'?1:this.state.SecurityTypes[i]==='Debt Repaid'?2:this.state.SecurityTypes[i]==='Internal Refinance'?3:0,
        //         //'SubType':this.state.SecurityTypes[i]==='Sale'?0:this.state.SecurityTypes[i]==='External Refinance'?1:this.state.SecurityTypes[i]==='Debt Repaid'?2:this.state.SecurityTypes[i]==='Internal Refinance'?3:0,
        //         //'SubType':this.state.SecurityTypes[i]==='Sale'?1:this.state.SecurityTypes[i]==='External Refinance'?2:this.state.SecurityTypes[i]==='Internal Refinance'?3:this.state.SecurityTypes[i]==='Debt Repaid'?4:1,
        //         //'SubType':this.state.SecurityTypes[i]?this.state.SecurityTypes[i]:1,
        //         //'IncomingMortgagee':this.state.SecurityTypes[i]==='External Refinance'?{'UniqueID':'IM'+i,'Type':'IncomingMortgagee','FullName':this.state.IncomingMortgageeNames[i],'Email':this.state.IncomingMortgageeEmails[i],'FaxNumber':this.state.IncomingMortgageeNumbers[i]}:null,
        //         //'PartyRep':!this.state.SelfActing[i]?{'UniqueID':'PR'+i,'Type':'Solicitor','FullName':this.state.PartyRepNames[i],'Email':this.state.PartyRepEmails[i],'PhoneNumber':this.state.PartyRepNumbers[i]}:null,
        //         //'Address':{'Street':this.state.SecuritySegmentAddreses[i]['street'],'City':this.state.SecuritySegmentAddreses[i]['city'],'State':this.state.SecuritySegmentAddreses[i]['state'],'Postcode':this.state.SecuritySegmentAddreses[i]['postcode']}}));
        //         'Address':{'Street':this.state.SecuritySegmentAddreses[i]['street'],'City':this.state.SecuritySegmentAddreses[i]['city'],'State':this.state.SecuritySegmentAddreses[i]['state']!=""?this.state.SecuritySegmentAddreses[i]['state']:'NSW','Postcode':this.state.SecuritySegmentAddreses[i]['postcode']}}));
        

       
        // instruction['PartyRep']={'UniqueID':'PR1','Type':'Solicitor','FullName':this.state.PartyRepNames[0],'Email':this.state.PartyRepEmails[0],'PhoneNumber':this.state.PartyRepNumbers[0]};
        //this.state.PartyRepNames.forEach((e,i)=>instruction['PartyRep'].push({'FullName':e,'Email':this.state.PartyRepEmails[i],'PhoneNumber':this.state.PartyRepNumbers[i]}));

        // instruction['IncomingMortgagee']=this.state.IncomingMortgageeSegmentCount===0?{}:{'UniqueID':'IM1','Type':'IncomingMortgagee','FullName':this.state.IncomingMortgageeNames[0],'Email':this.state.IncomingMortgageeEmails[0],'PhoneNumber':this.state.IncomingMortgageeNumbers[0]};
        // instruction['SettlementSimultaneousWith']=[];//SettlementSimultaneousWith
        // this.state.SimultaneousSettlementSegmentLoanIds.forEach((v,i)=>{instruction['SettlementSimultaneousWith'].push(v)});//(p,c,i)=>[{'LoanId':this.state.SimultaneousSettlementSegmentLoanIds[i-1]},{'LoanId':this.state.SimultaneousSettlementSegmentLoanIds[i]}]):
       
        // instruction['ImportantNotes'] = {'SettlementNotToBeBookedPriorTo':this.state.ImportantNoteSegmentSettlementNotToBeBookedPriorToDates[0],'CollectSurplus':this.state.ImportantNoteSegmentCollectSurplus[0]==='on'?true:false};
        // if(this.state.HasBorrowerClearTitleToAddress){
        //     instruction['ImportantNotes']['BorrowerClearTitleTo']={'Address':{'Street':this.state.BorrowerClearTitleToAddress[0]['street'],'City':this.state.BorrowerClearTitleToAddress[0]['city'],'State':this.state.BorrowerClearTitleToAddress[0]['state'],'Postcode':this.state.BorrowerClearTitleToAddress[0]['postcode']}};
        // }
        // if(this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress){
        //     instruction['ImportantNotes']['GuarantorTitleDeedTo']={'Address':{'Street':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['street'],'City':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['city'],'State':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['state'],'Postcode':this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[0]['postcode']}};
        // }
        // instruction['SpecialConditions']=[];
        // this.state.SpecialConditionSegmentConditionValues.forEach((e,i)=>

        // instruction['SpecialConditions'].push({'Type':this.state.SpecialConditionSegmentConditionTypes[i],'SpecialCondition1':this.state.SpecialConditionSegmentConditionValues[i]}));

        // instruction['OtherRequests']=this.state.DischargeinstructionOtherRequests;
        application['Application']=instruction;
        //applicationBatch['ApplicationBatch']=instruction;
        applicationBatch=application;
        console.log(applicationBatch);
        this.setState({isLoading:true});
        InstructionService.SendNewLoansInstruction(applicationBatch).then((resp)=>{
            this.setState({msg:resp.data.Content,
                InstructionSubmit:true,
                isLoading:false});
            
         
        },(err)=>{
            if (err.response && err.response.status === 401) {
                this.setState({
                    isLoading:false,
                    shouldRedirect: true,
                    errorMessage: 'there is an error with the request'
                });

            } else {
                if(err.response.status== 400){
                    alert('A matter with this lender reference already exists.');
                }
                else{
                    alert("There is an error with the request");
                }
                //alert('A matter with this lender reference already exists.');
                this.setState({isLoading:false,});
            }
        });
    
    }   
    

    GetApplicationTypes(){
        this.setState({ApplicationTypes:[
            'New Loan',
            'Redoc',
            'VarnIncrease',
            'VarnDecrease',
            'VarnSubstitution',
            'VarnBorrChange',
            'VarnIncreaseSubstitution',
            'VarnIncreaseBorrChange',
            'VarnIncreaseSubstitutionBorrChange',
            'VarnDecreaseSubstitution',
            'VarnDecreaseBorrChange',
            'VarnDecreaseSubstitutionBorrChange',
            'VarnProductChange',
            'VarnOther']});
        // InstructionService.GetDischargeTypes().then((resp)=>{
        //     this.setState({ApplicationTypes:resp.data.Content});
            
        // },
        // (err)=>{
        //     //alert(err);
        //     alert("There is an error with the request");
        // });
    }

    GetOtherPartyTypes(){
        //this.setState({OtherPartyTypes:['Broker','Solicitor','Creditor']});
        // InstructionService.GetDischargeTypes().then((resp)=>{
        //     this.setState({ApplicationTypes:resp.data.Content});
            
        // },
        // (err)=>{
        //     //alert(err);
        //     alert("There is an error with the request");
        // });
    }

    GetDischargeReasonTypes(){
        //this.setState({subApplicationTypeOptions:['Sale','External Refinance','Internal Refinance','Debt Repaid']});
        InstructionService.GetDischargeReasonTypes().then((resp)=>{
            //alert(resp.data.Content)
            this.setState({subApplicationTypeOptions:resp.data.Content},()=>{
                
                //console.log("irene again")
                console.log(this.state.subApplicationTypeOptions)
            });
            //this.UpdateSecuritySegment();
        },
        (err)=>{
            //alert(err);
            alert("There is an error with the request");
        });
    }

    GetStates(){
        let respVal = [];
        
        InstructionService.GetInstructionStates().then((resp)=>{
            this.setState({States:resp.data.Content},()=>{
                let mapd=[];
                mapd.push(<option label="State"></option>);
                for(let f=0;f<this.state.States.length;f++){
                    mapd.push(<option>{this.state.States[f]}</option>);
                };

                let mapTitles=[];
                let mapApplicantTransactionList=[];
                let mapChangeNameYNList=[];
                let mapChangeNameReasonList=[];
                let mapPartyTypes=[];
                let mapPartyPartnershipTypes=[];
                let mapFloorTypes=[];
                let mapDeliveryTypes=[];
                let mapTrustTypes=[];
                let mapCompanyTypes=[];
                let mapSettlementActionLists=[];
                let mapCommercialTypes=[];
                let mapResidentialTypes=[];
                let mapUnitTypeList=[];
                let mapStreetTypeList=[];
                let mapCountries=[];
                let mapPrimaryPurposes=[];
                let mapTransactions=[];
                let mapTitleTorrensExtent=[];
                let mapTitleTenureTypeList=[];
                let mapTitleTypeList=[];
                let mapEncumbranceTypeList=[];
                let mapFinanceTypes=[];
                let mapNonPropertyTransactionList=[];
                let mapFinancialDepositAccountTypeList=[];
                let mapMotorVehicleTypeList=[];
                let mapMortgagePriorityList=[];
                let mapAssetInsuranceCategoryList=[];
                let mapAssetInsuranceTypeList=[];
                let mapOtherAssetTypeList=[];
                let mapNonPropertyShareTypeList=[];
                let mapFinancialLoanTypeList=[];
                let mapPaidStatusList=[];
                let mapFeeNameList=[];
                let mapFeeTypeList=[];
                let mapFeePayableList=[];
                let mapPayerTypeList=[];
                let mapInsuranceTypeList=[];
                let mapConditionTypeList=[];
                let mapDocumentIssuerList=[];
                let mapSendDocsToRecipientList=[];
                let mapSalesChannelList=[];
                let mapDocumentationInstructionsMethodList=[];
                let mapClassificationList=[];
                let mapGenderList=[];

                // mapTitles.push(<option label="Mr"></option>);
                // mapTitles.push(<option label="Ms"></option>);                
                // mapTitles.push(<option label="Mrs"></option>);


              
                // dischargeReasonTypeOptions.push(<option>Select reasoning</option>)
                for(let x=0;x<this.state.MappedTitles.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapTitles.push(<option>{this.state.MappedTitles[x]}</option>)
                }
                for(let x=0;x<this.state.GenderList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapGenderList.push(<option>{this.state.GenderList[x]}</option>)
                }                
                for(let x=0;x<this.state.ApplicantTransactionList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapApplicantTransactionList.push(<option>{this.state.ApplicantTransactionList[x]}</option>)
                }                 
                for(let x=0;x<this.state.ChangeNameYNList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapChangeNameYNList.push(<option>{this.state.ChangeNameYNList[x]}</option>)
                }
                for(let x=0;x<this.state.ChangeNameReasonList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapChangeNameReasonList.push(<option>{this.state.ChangeNameReasonList[x]}</option>)
                }               
                for(let x=0;x<this.state.MappedPartyTypes.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapPartyTypes.push(<option>{this.state.MappedPartyTypes[x]}</option>)
                }                
                for(let x=0;x<this.state.MappedPartnershipTypes.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapPartyPartnershipTypes.push(<option>{this.state.MappedPartnershipTypes[x]}</option>)
                }
                for(let x=0;x<this.state.FloorTypes.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapFloorTypes.push(<option>{this.state.FloorTypes[x]}</option>)
                }
                for(let x=0;x<this.state.DeliveryTypes.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapDeliveryTypes.push(<option>{this.state.DeliveryTypes[x]}</option>)
                }
                for(let x=0;x<this.state.TrustTypes.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapTrustTypes.push(<option>{this.state.TrustTypes[x]}</option>)
                }
                for(let x=0;x<this.state.CompanyTypes.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapCompanyTypes.push(<option>{this.state.CompanyTypes[x]}</option>)
                }
                for(let x=0;x<this.state.SettlementActionLists.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapSettlementActionLists.push(<option>{this.state.SettlementActionLists[x]}</option>)
                }
                for(let x=0;x<this.state.PropertyCommercialTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapCommercialTypes.push(<option>{this.state.PropertyCommercialTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.PropertyResidentialTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapResidentialTypes.push(<option>{this.state.PropertyResidentialTypeList[x]}</option>)
                }                
                for(let x=0;x<this.state.UnitTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapUnitTypeList.push(<option>{this.state.UnitTypeList[x]}</option>)
                }                
                for(let x=0;x<this.state.StreetTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapStreetTypeList.push(<option>{this.state.StreetTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.CountryCodeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapCountries.push(<option>{this.state.CountryCodeList[x]}</option>)
                }
                for(let x=0;x<this.state.PropertyPurposeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapPrimaryPurposes.push(<option>{this.state.PropertyPurposeList[x]}</option>)
                }
                for(let x=0;x<this.state.PropertyTransactionList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapTransactions.push(<option>{this.state.PropertyTransactionList[x]}</option>)
                }
                for(let x=0;x<this.state.TitleTorrensExtent.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapTitleTorrensExtent.push(<option>{this.state.TitleTorrensExtent[x]}</option>)
                }
                for(let x=0;x<this.state.TitleTenureTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapTitleTenureTypeList.push(<option>{this.state.TitleTenureTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.TitleTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapTitleTypeList.push(<option>{this.state.TitleTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.EncumbranceTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapEncumbranceTypeList.push(<option>{this.state.EncumbranceTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.FinanceTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapFinanceTypes.push(<option>{this.state.FinanceTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.NonPropertyTransactionList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapNonPropertyTransactionList.push(<option>{this.state.NonPropertyTransactionList[x]}</option>)
                }
                for(let x=0;x<this.state.FinancialDepositAccountTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapFinancialDepositAccountTypeList.push(<option>{this.state.FinancialDepositAccountTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.MotorVehicleTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapMotorVehicleTypeList.push(<option>{this.state.MotorVehicleTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.MortgagePriorityList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapMortgagePriorityList.push(<option>{this.state.MortgagePriorityList[x]}</option>)
                }
                for(let x=0;x<this.state.AssetInsuranceCategoryList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapAssetInsuranceCategoryList.push(<option>{this.state.AssetInsuranceCategoryList[x]}</option>)
                }
                for(let x=0;x<this.state.AssetInsuranceTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapAssetInsuranceTypeList.push(<option>{this.state.AssetInsuranceTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.OtherAssetTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapOtherAssetTypeList.push(<option>{this.state.OtherAssetTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.NonPropertyShareTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapNonPropertyShareTypeList.push(<option>{this.state.NonPropertyShareTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.FinancialLoanTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapFinancialLoanTypeList.push(<option>{this.state.FinancialLoanTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.PaidStatusList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapPaidStatusList.push(<option>{this.state.PaidStatusList[x]}</option>)
                }
                for(let x=0;x<this.state.FeeNameList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapFeeNameList.push(<option>{this.state.FeeNameList[x]}</option>)
                }
                for(let x=0;x<this.state.FeeTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapFeeTypeList.push(<option>{this.state.FeeTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.FeePayableList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapFeePayableList.push(<option>{this.state.FeePayableList[x]}</option>)
                }
                for(let x=0;x<this.state.PayerTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapPayerTypeList.push(<option>{this.state.PayerTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.InsuranceTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapInsuranceTypeList.push(<option>{this.state.InsuranceTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.ConditionTypeList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapConditionTypeList.push(<option>{this.state.ConditionTypeList[x]}</option>)
                }
                for(let x=0;x<this.state.DocumentIssuerList.length;x++){
                    //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
                    mapDocumentIssuerList.push(<option>{this.state.DocumentIssuerList[x]}</option>)
                }
                for(let x=0;x<this.state.SendDocsToRecipientList.length;x++){
                    mapSendDocsToRecipientList.push(<option>{this.state.SendDocsToRecipientList[x]}</option>)
                }
                for(let x=0;x<this.state.SalesChannelList.length;x++){
                    mapSalesChannelList.push(<option>{this.state.SalesChannelList[x]}</option>)
                }
                for(let x=0;x<this.state.DocumentationInstructionsMethodList.length;x++){
                    mapDocumentationInstructionsMethodList.push(<option>{this.state.DocumentationInstructionsMethodList[x]}</option>)
                }
                for(let x=0;x<this.state.ClassificationList.length;x++){
                    mapClassificationList.push(<option>{this.state.ClassificationList[x]}</option>)
                }
                
                //let mappedDischargeReasonOptions = dischargeReasonTypeOptions.length?dischargeReasonTypeOptions.reduce((p,c)=>[p,'',c]):'';
               
                
                this.setState({
                    MappedStates:mapd, 
                    MappedTitles: mapTitles, 
                    ApplicantTransactionList: mapApplicantTransactionList,
                    ChangeNameYNList: mapChangeNameYNList,
                    ChangeNameReasonList: mapChangeNameReasonList,
                    MappedPartyTypes:mapPartyTypes, 
                    MappedPartnershipTypes:mapPartyPartnershipTypes,
                    FloorTypes:mapFloorTypes,
                    DeliveryTypes: mapDeliveryTypes,
                    TrustTypes: mapTrustTypes,
                    CompanyTypes: mapCompanyTypes,
                    SettlementActionLists: mapSettlementActionLists,
                    PropertyCommercialTypeList: mapCommercialTypes,
                    PropertyResidentialTypeList: mapResidentialTypes,
                    UnitTypeList: mapUnitTypeList,
                    StreetTypeList: mapStreetTypeList,
                    CountryCodeList: mapCountries,
                    PropertyPurposeList: mapPrimaryPurposes,
                    PropertyTransactionList: mapTransactions,
                    TitleTorrensExtent: mapTitleTorrensExtent,
                    TitleTenureTypeList: mapTitleTenureTypeList,
                    TitleTypeList: mapTitleTypeList,
                    EncumbranceTypeList: mapEncumbranceTypeList,
                    FinanceTypeList: mapFinanceTypes,
                    NonPropertyTransactionList: mapNonPropertyTransactionList,
                    FinancialDepositAccountTypeList: mapFinancialDepositAccountTypeList,
                    MotorVehicleTypeList: mapMotorVehicleTypeList,
                    MortgagePriorityList: mapMortgagePriorityList,
                    AssetInsuranceCategoryList: mapAssetInsuranceCategoryList,
                    AssetInsuranceTypeList: mapAssetInsuranceTypeList,
                    OtherAssetTypeList: mapOtherAssetTypeList,
                    NonPropertyShareTypeList: mapNonPropertyShareTypeList,
                    FinancialLoanTypeList:mapFinancialLoanTypeList,
                    PaidStatusList:mapPaidStatusList,
                    FeeNameList:mapFeeNameList,
                    FeeTypeList:mapFeeTypeList,
                    FeePayableList:mapFeePayableList,
                    PayerTypeList:mapPayerTypeList,
                    InsuranceTypeList:mapInsuranceTypeList,
                    ConditionTypeList:mapConditionTypeList,
                    DocumentIssuerList: mapDocumentIssuerList,
                    SendDocsToRecipientList: mapSendDocsToRecipientList,
                    SalesChannelList: mapSalesChannelList,
                    DocumentationInstructionsMethodList: mapDocumentationInstructionsMethodList,
                    ClassificationList: mapClassificationList,
                    GenderList: mapGenderList

                },()=>{
                    //this.GetDischargeReasonTypes();
                    //this.GetTitles();
                    this.UpdateIncomingMortgageeSegment();
                    this.UpdateSecuritySegment();
                    this.UpdatePartySegment();
                    this.UpdateRelatedPartySegment();
                    this.UpdatePartyRep();
                    this.GetApplicationTypes();
                    this.GetOtherPartyTypes();
                    this.UpdateImportNotesSegment();
                    this.UpdateLoanIdSegment();
                    this.UpdateFeeSegment();
                    this.UpdateInstructionsDocumentsSegment();
                    //this.GetTrustAccountOptions();
                    //this.UpdatePayoutSegment();
                });
            });
        },
        (err)=>{
            this.setState({

                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
            });
            
        });

    }
    GetTitles(){
        this.setState({Titles:['Mr', 'Ms', 'Mrs']});
    }

    GetExternalUserPrivilege(){
        
       /*  AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({CanInstructNewLoans:resp.data.Content.CanInstructNewLoans},()=>{}
        }) */

        AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({
                CanInstructDischarges:resp.data.Content.CanInstructDischarges,
                CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
                CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
                CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
            },()=>{console.log(this.state.CanInstructNewLoans)});
        },(err)=>{
            console.log("there is an error")
            //this.setState({isInstructPriv:false});
        })
    }

    GetLenderDetails(){
        //let userdetails = AuthService.getUserDetails();
        //let usertypeid =  userdetails != null?userdetails.map(d=>d.usertypeid) : '';
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2)
        {
            AuthService.GetLenderDetails().then((resp)=>{
                this.setState({
                    IsLenderThinkTank:resp.data.Content
                },()=>{console.log(this.state.IsLenderThinkTank)});
            },(err)=>{
                console.log("there is an error")
                //this.setState({isInstructPriv:false});
            })
        }
        
    }


    UpdateLenderReferenceCombined(e){
        e.preventDefault();
        let vals = this.state.LoanIds;
        var ref = '';
        for(let x=0;x<vals.length;x++){
            if(vals[x]===null){
                continue;
            }
            if(x===0){
                ref+=vals[x]
            }else{
                ref+=', '+vals[x]
            }
        }
        //this.setState({InstructionLenderReference:ref});
    }

    LoanDetailsLVRNumberUpdated(e){
        e.preventDefault();
        let val = this.state.LoanDetailsLVRNumber;
        val=e.target.value;
        
        this.setState({LoanDetailsLVRNumber:val});
    }

    LoanDetailsFHOGUpdated(e){
        e.preventDefault();
        let val = this.state.LoanDetailsFHOG;
        val=e.target.value;
        
        this.setState({LoanDetailsFHOG:val});
    }

    FHOGAmountUpdated(e){
        e.preventDefault();
        let val = this.state.LoanDetailsFHOGAmount;
        val=e.target.value;
        
        this.setState({LoanDetailsFHOGAmount:val});
    }

    LoanIdUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanIds;
        val[i]=e.target.value;
        
        this.setState({LoanIds:val},()=>{this.UpdateLoanIdSegment();});

    }
    IndicativeAmountUpdated(e,i){
        e.preventDefault();
        let val = this.state.IndicativeAmounts;
        val[i]=e.target.value;
        this.setState({IndicativeAmounts:val},()=>{this.UpdateLoanIdSegment();});
    }

    LoanTermUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanTermMonths;
        val[i]=e.target.value;
        this.setState({LoanTermMonths:val},()=>{this.UpdateLoanIdSegment();});
    }

    LoanInterestRateUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanInterestRates;
        val[i]=e.target.value;
        this.setState({LoanInterestRates:val},()=>{this.UpdateLoanIdSegment();});
    }

    LoanDefaultInterestRateUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanDefaultInterestRates;
        val[i]=e.target.value;
        this.setState({LoanDefaultInterestRates:val},()=>{this.UpdateLoanIdSegment();});
    }

    LoanPaymentAmountUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanPaymentAmounts;
        val[i]=e.target.value;
        this.setState({LoanPaymentAmounts:val},()=>{this.UpdateLoanIdSegment();});
    }

    LoanPurposeUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanPurposes;
        val[i]=e.target.value;
        this.setState({LoanPurposes:val},()=>{this.UpdateLoanIdSegment();});
    }

    LoanAmountUpdated(e,i){
        e.preventDefault();
        let val = this.state.LoanAmounts;
        val[i]=e.target.value;
        this.setState({LoanAmounts:val},()=>{this.UpdateLoanIdSegment();});
    }

    LoanSegmentKeyDatesUpdated(e,i,key){
        e.preventDefault();
        let keyDates = this.state.LoanSegmentKeyDates;
        if(keyDates[i]===undefined){
            let keyDatesEntity =  {'ExpiryDateOfCommitment':'','AnticipatedLoanExpiry':''};            
            keyDates.push(keyDatesEntity);
        }
        keyDates[i][key]=e.target.value;

        this.setState({LoanSegmentKeyDates:keyDates},()=>{this.UpdateLoanIdSegment()});
    }

    LoanSegmentLoansToBeRepaidUpdated(e,i,key){
        e.preventDefault();
        let loansToBeRepaid = this.state.LoanSegmentLoansToBeRepaid;
        if(loansToBeRepaid[i]===undefined){
            let loansToBeRepaidEntity =  {'AccountType':'','AccountTypeDescription':'','AccountName':'','AccountNo':'','PaymentPaid':'','PaymentAmountEXGST':'','PaymentTotal':'',
                                        'PaymentGST':'','PaymentBalance':''};            
            loansToBeRepaid.push(loansToBeRepaidEntity);
        }
        loansToBeRepaid[i][key]=e.target.value;

        this.setState({LoanSegmentLoansToBeRepaid:loansToBeRepaid},()=>{this.UpdateLoanIdSegment()});
    }

    LoanSegmentLoanDocPrepUpdated(e,i,key){
        e.preventDefault();
        let loanDocPrep = this.state.LoanSegmentLoanDocPrep;
        if(loanDocPrep[i]===undefined){
            let loanDocPrepEntity =  {'MortgagePaymentsExistingLoanAmount':'','MortgagePaymentsOthersAmount':'','RentPaidAmount':'','IncomeTaxAmount':'','HirePurchaseAmount':'','LivingExpensesAmount':'','OtherExpensesAmount':'',
                                        'OtherCommitmentsAmount':'','TotalExpensesAmount':'','GrossSalaryPreTaxAmount':'','NetBusinessIncomePreTaxAmount':'','NetRentalIncomeAmount':'','OtherIncomeAmount':'','TotalIncomeAmount':'','SurplusAmount':'','TotalAssetsAmount':'',
                                        'TotalLiabilitiesAmount':'','NetAssetsAmount':'',};            
            loanDocPrep.push(loanDocPrepEntity);
        }
        loanDocPrep[i][key]=e.target.value;

        this.setState({LoanSegmentLoanDocPrep:loanDocPrep},()=>{this.UpdateLoanIdSegment()});
    }

    UpdateLoanIdSegment(){
        var ents = this.state.LoanIdEntities;
        var loanIds = this.state.LoanIds;
        // var loanTermMonths = this.state.LoanTermMonths;
        // var loanTermTypes
        let termTypes=[];
            
        for(let i=0; i<this.state.MappedLoanTermTypes.length;i++){
            termTypes.push(<option>{this.state.MappedLoanTermTypes[i]}</option>);
        }
        let mappedLoanTermTypes = termTypes.length? termTypes.reduce((p,c)=>[p,'',c]):'';

        let paymentFrequencies=[];
            
        for(let i=0; i<this.state.MappedLoanPaymentFrequencies.length;i++){
            paymentFrequencies.push(<option>{this.state.MappedLoanPaymentFrequencies[i]}</option>);
        }
        let mappedLoanPaymentFrequencies = paymentFrequencies.length? paymentFrequencies.reduce((p,c)=>[p,'',c]):'';


        let paymentTypes=[];
            
        for(let i=0; i<this.state.MappedLoanPaymentTypes.length;i++){
            paymentTypes.push(<option>{this.state.MappedLoanPaymentTypes[i]}</option>);
        }
        let mappedLoanPaymentTypes = paymentTypes.length? paymentTypes.reduce((p,c)=>[p,'',c]):'';

        

        if(this.state.LoanIdEntities.length>this.state.LoanIdEntityCount){
            for(let i = this.state.LoanIdEntities.length; i>this.state.LoanIdEntityCount && i>this.state.LoanIdEntityMinCount ; i--){
                ents.pop();
                loanIds.pop();

            }
            this.setState({LoanIdEntities:ents,LoanIds:loanIds},()=>{return;});
        }else{
        
            let keyDates = this.state.LoanSegmentKeyDates;
            let loansToBeRepaid = this.state.LoanSegmentLoansToBeRepaid;
            let loanPurposes = this.state.LoanPurposes;
            let loanDocPrep = this.state.LoanSegmentLoanDocPrep;
            for(let i = 0; i<this.state.LoanIdEntityCount; i++){
                let keyDatesEntity =  {'ExpiryDateOfCommitment':'','AnticipatedLoanExpiry':''};
                let loansToBeRepaidEntity =  {'AccountType':'','AccountTypeDescription':'','AccountName':'','AccountNo':'','PaymentPaid':'','PaymentAmountEXGST':'','PaymentTotal':'',
                                        'PaymentGST':'','PaymentBalance':''}; 
                let loanDocPrepEntity =  {'MortgagePaymentsExistingLoanAmount':'','MortgagePaymentsOthersAmount':'','RentPaidAmount':'','IncomeTaxAmount':'','HirePurchaseAmount':'','LivingExpensesAmount':'','OtherExpensesAmount':'',
                'OtherCommitmentsAmount':'','TotalExpensesAmount':'','GrossSalaryPreTaxAmount':'','NetBusinessIncomePreTaxAmount':'','NetRentalIncomeAmount':'','OtherIncomeAmount':'','TotalIncomeAmount':'','SurplusAmount':'','TotalAssetsAmount':'',
                'TotalLiabilitiesAmount':'','NetAssetsAmount':'',};

                if(loanIds.length<this.state.LoanIdEntityCount){
                    loanIds.push(null);
                    keyDates.push(keyDatesEntity);
                    loansToBeRepaid.push(loansToBeRepaidEntity);
                    loanPurposes.push();
                    loanDocPrep.push(loanDocPrepEntity);
                }
            
                ents[i]=((                
                <div>
                    {/* Loan details Start */}
                    <h5 className="listContentTitle">Loan Account {i+1}</h5>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Loan Account Number
                        </div>
                        {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                        <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Amount Requested
                        </div>
                        <input type="number" min="1" step="any" id={'IndicativeAmount'+i} className="dollarTextBox" value={this.state.IndicativeAmounts[i]} onChange={(e)=>{e.preventDefault();this.IndicativeAmountUpdated(e,i)}} placeholder="Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>
                    {/* <div className="inputBox">
                        <div className="inputLabel">
                        Current Loan Repayment Amount
                        </div>
                        <input type="number" min="1" step="any" id={'CurrentLoanRepaymentAmount'+i} className="dollarTextBox" value={this.state.CurrentLoanRepaymentAmount[i]} onChange={(e)=>{e.preventDefault();this.CurrentLoanRepaymentAmountUpdated(e,i)}} placeholder="Current Loan Repayment Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div> */}
                    <div className="inputBox">
                        <div className="inputLabel">
                            Default Rate
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDefaultInterestRate'+i} value={this.state.LoanDefaultInterestRates[i]} onChange={(e)=>{e.preventDefault();this.LoanDefaultInterestRateUpdated(e,i)}} placeholder="Loan Default Interest Rate" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Interest Rate
                        </div>
                        <input type="number" min="1" step="any" id={'LoanInterestRate'+i} value={this.state.LoanInterestRates[i]} onChange={(e)=>{e.preventDefault();this.LoanInterestRateUpdated(e,i)}} placeholder="Interest Rate" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"PartyTypeLabel"+1}>
                            Is Locked?
                        </div>
                        <div className="multiColsInputBoxContentWidth" id={"LoanTermIsLocked"+i} value={this.state.LoanTermIsLocked[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanTermIsLocked; vals[i]=e.target.value;this.setState({LoanTermIsLockedUpdated:vals},()=>{this.UpdateLoanIdSegment()})}}>    
                            <RadioButton value="Yes" name={"LoanTermIsLocked"+i} title="Yes" checked={this.state.LoanTermIsLocked[i]==='Yes'} />                              
                            <RadioButton value="No" name={"LoanTermIsLocked"+i} title="No" checked={this.state.LoanTermIsLocked[i]==='No'} />                                           
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Payment Amount
                        </div>
                        <input type="number" min="1" step="any" id={'LoanPaymentAmount'+i} className="dollarTextBox" value={this.state.LoanPaymentAmounts[i]} onChange={(e)=>{e.preventDefault();this.LoanPaymentAmountUpdated(e,i)}} placeholder="Payment Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                        Loan Payment Frequency
                        </div>
                        <div>
                            <select className="form-control" id="LoanPaymentFrequency" value={this.state.LoanPaymentFrequencies[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanPaymentFrequencies; vals[i]=e.target.value;this.setState({LoanPaymentFrequencies:vals},()=>{this.UpdateLoanIdSegment(e,i)})}}>
                                <option selected>Select Loan Payment Frequency</option>
                                {mappedLoanPaymentFrequencies}
                            </select>
                        </div>
                    </div> 
                    <div className="inputBox">
                        <div className="inputLabel">
                            Number of Payments
                        </div>
                        <input type="number" min="1" step="any" id={'LoanTerm'+i} className="dollarTextBox" value={this.state.LoanTermMonths[i]} onChange={(e)=>{e.preventDefault();this.LoanTermUpdated(e,i)}} placeholder="Loan Term (Months)" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Loan Payment Type
                        </div>
                        <div>
                            <select className="form-control" id="LoanPaymentType" value={this.state.LoanPaymentTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanPaymentTypes; vals[i]=e.target.value;this.setState({LoanPaymentTypes:vals},()=>{this.UpdateLoanIdSegment(e,i)})}}>
                                <option selected>Select Payment Type</option>
                                {mappedLoanPaymentTypes}
                            </select>
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Loan Term Type
                        </div>
                        <div>
                            <select className="form-control" id="LoanTermType" value={this.state.LoanTermTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanTermTypes; vals[i]=e.target.value;this.setState({LoanTermTypes:vals},()=>{this.UpdateLoanIdSegment(e,i)})}}>
                                <option selected>SelectLoan Term Type</option>
                                {mappedLoanTermTypes}
                            </select>
                        </div>
                    </div>                    
                    {/* <div className="inputBox">
                        <div className="inputLabel">
                            Variation Amount
                        </div>
                        <input type="number" min="1" step="any" id={'VariationAmount'+i} className="dollarTextBox" value={this.state.LoanDetailsVariationAmount[i]} onChange={(e)=>{e.preventDefault();this.LoanDetailsVariationAmountUpdated(e,i)}} placeholder="Variation Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>                      */}

                    <div className="inputBox">
                        <div className="inputLabel">
                            Loan Purpose
                        </div>
                        {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                        <input type="text" id={'LoanPurpose'+i} className="form-control" value={this.state.LoanPurposes[i]} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanPurposeUpdated(e,i)}} placeholder="Loan Purpose" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Loan Amount
                        </div>
                        <input type="number" min="1" step="any" id={'LoanAmount'+i} className="dollarTextBox" value={this.state.LoanAmounts[i]} onChange={(e)=>{e.preventDefault();this.LoanAmountUpdated(e,i)}} placeholder="Loan Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                    </div>
                    
                   {/* Loan details End */}

                   {/* KeyDates Start */}
                   <div className="inputBox LPNameBox">
                        <div className="inputLabel">
                            Expiry Date Of Commitment
                        </div>
                        <div className="dateBox">
                            <input type="date" id={'LoanSegmentExpiryDateOfCommitment'+i} className={"form-control"+(this.state.LoanSegmentKeyDates[i]['ExpiryDateOfCommitment']?"successTextBox": '')} value={this.state.LoanSegmentKeyDates[i]['ExpiryDateOfCommitment']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.LoanSegmentKeyDatesUpdated(e,i,'ExpiryDateOfCommitment')}} autoComplete={false} placeholder="DD/MM/YY" required/>
                        </div>
                    </div> 
                    <div className="inputBox LPNameBox">
                        <div className="inputLabel">
                            Anticipated Loan Expiry
                        </div>
                        <div className="dateBox">
                            <input type="date" id={'LoanSegmentAnticipatedLoanExpiry'+i} className={"form-control"+(this.state.LoanSegmentKeyDates[i]['AnticipatedLoanExpiry']?"successTextBox": '')} value={this.state.LoanSegmentKeyDates[i]['AnticipatedLoanExpiry']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.LoanSegmentKeyDatesUpdated(e,i,'AnticipatedLoanExpiry')}} autoComplete={false} placeholder="DD/MM/YY" required/>
                        </div>
                    </div>
                    {/* KeyDates End */}
                    {/* LoansToBeRepaid Start */}
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Account Type
                        </div>
                        <div>
                            <select className="form-control" id="LoansToBeRepaidAccountType" value={this.state.LoanSegmentLoansToBeRepaid[i]['AccountType']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanSegmentLoansToBeRepaid; vals[i]=e.target.value;this.setState({LoanSegmentLoansToBeRepaid:vals},()=>{this.LoanSegmentLoansToBeRepaidUpdated(e,i,['AccountType'])})}}>
                                {/* <option selected>SelectLoan Term Type</option> */}
                                {this.state.FinancialLoanTypeList}
                            </select>
                        </div>
                    </div>  
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Account Type Description
                        </div>
                        <input type="text" id={'LoansToBeRepaidAccountTypeDescription'+i} className="form-control" value={this.state.LoanSegmentLoansToBeRepaid[i]['AccountTypeDescription']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoansToBeRepaidUpdated(e,i,['AccountTypeDescription'])}} placeholder="Account Type" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Account Name
                        </div>
                        <input type="text" id={'LoansToBeRepaidAccountName'+i} className="form-control" value={this.state.LoanSegmentLoansToBeRepaid[i]['AccountName']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoansToBeRepaidUpdated(e,i,['AccountName'])}} placeholder="Account Name" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Account No
                        </div>
                        <input type="text" id={'LoansToBeRepaidAccountNo'+i} className="form-control" value={this.state.LoanSegmentLoansToBeRepaid[i]['AccountNo']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoansToBeRepaidUpdated(e,i,['AccountNo'])}} placeholder="Account No" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Paid Status
                        </div>
                        <div>
                            <select className="form-control" id="PaymentPaidStatus" value={this.state.LoanSegmentLoansToBeRepaid[i]['PaymentPaid']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanSegmentLoansToBeRepaid; vals[i]=e.target.value;this.setState({LoanSegmentLoansToBeRepaid:vals},()=>{this.LoanSegmentLoansToBeRepaidUpdated(e,i,['PaymentPaid'])})}}>
                                {/* <option selected>SelectLoan Term Type</option> */}
                                {this.state.PaidStatusList}
                            </select>
                        </div>
                    </div>  
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Payment Amount EXGST
                        </div>
                        <input type="text" id={'LoansToBeRepaidPaymentAmountEXGST'+i} className="form-control" value={this.state.LoanSegmentLoansToBeRepaid[i]['PaymentAmountEXGST']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoansToBeRepaidUpdated(e,i,['PaymentAmountEXGST'])}} placeholder="Payment Amount EXGST" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Payment Total Amount
                        </div>
                        <input type="text" id={'LoansToBeRepaidPaymentTotal'+i} className="form-control" value={this.state.LoanSegmentLoansToBeRepaid[i]['PaymentTotal']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoansToBeRepaidUpdated(e,i,['PaymentTotal'])}} placeholder="Payment Total Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Payment GST
                        </div>
                        <input type="text" id={'LoansToBeRepaidPaymentAmountEXGST'+i} className="form-control" value={this.state.LoanSegmentLoansToBeRepaid[i]['PaymentGST']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoansToBeRepaidUpdated(e,i,['PaymentGST'])}} placeholder="GST" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Payment Balance
                        </div>
                        <input type="number" min="1" step="any" id={'LoansToBeRepaidPaymentBalance'+i} className="form-control" value={this.state.LoanSegmentLoansToBeRepaid[i]['PaymentBalance']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoansToBeRepaidUpdated(e,i,['PaymentBalance'])}} placeholder="Balance" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                        
                    </div>
                    {/* LoansToBeRepaid End */}
                    {/* LoanDocPrep Start */}
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Mortgage Payments Existing Loan Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepMortgagePaymentsExistingLoanAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['MortgagePaymentsExistingLoanAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['MortgagePaymentsExistingLoanAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Mortgage Payments Others Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepMortgagePaymentsOthersAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['MortgagePaymentsOthersAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['MortgagePaymentsOthersAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Rent Paid Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepRentPaidAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['RentPaidAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['RentPaidAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Income Tax Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepIncomeTaxAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['IncomeTaxAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['IncomeTaxAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Hire Purchase Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepHirePurchaseAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['HirePurchaseAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['HirePurchaseAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Living Expenses Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepLivingExpensesAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['LivingExpensesAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['LivingExpensesAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Other Expenses Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepOtherExpensesAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['OtherExpensesAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['OtherExpensesAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Other Commitments Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepOtherCommitmentsAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['OtherCommitmentsAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['OtherCommitmentsAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Total Expenses Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepTotalExpensesAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['TotalExpensesAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['TotalExpensesAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Gross Salary Pre Tax Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepGrossSalaryPreTaxAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['GrossSalaryPreTaxAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['GrossSalaryPreTaxAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Net Business Income Pre Tax Amount                      
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepNetBusinessIncomePreTaxAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['NetBusinessIncomePreTaxAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['NetBusinessIncomePreTaxAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Net Rental Income Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepNetRentalIncomeAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['NetRentalIncomeAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['NetRentalIncomeAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Other Income Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepMOtherIncomeAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['OtherIncomeAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['OtherIncomeAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Total Income Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepTotalIncomeAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['TotalIncomeAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['TotalIncomeAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Surplus Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepSurplusAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['SurplusAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['SurplusAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Total Assets Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepTotalAssetsAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['TotalAssetsAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['TotalAssetsAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Total Liabilities Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepTotalLiabilitiesAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['TotalLiabilitiesAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['TotalLiabilitiesAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                            Net Assets Amount                           
                        </div>
                        <input type="number" min="1" step="any" id={'LoanDocPrepNetAssetsAmount'+i} className="form-control" value={this.state.LoanSegmentLoanDocPrep[i]['NetAssetsAmount']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanSegmentLoanDocPrepUpdated(e,i,['NetAssetsAmount'])}} placeholder="Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />                        
                    </div>
                    {/* LoanDocPrep End */}

                </div>));
            }
            this.setState({LoanIdEntities:ents,LoanIds:loanIds,
                 LoanSegmentKeyDates: keyDates,
                 LoanSegmentLoansToBeRepaid: loansToBeRepaid,
                 LoanSegmentLoanDocPrep: loanDocPrep,
                });
        }
    }

    CommissionSegmentPayerTypeUpdated(e)
    {
        e.preventDefault();
        this.setState({CommissionSegmentPayerType:e.target.value});
    }
    CommissionSegmentPaymentPaidStatusUpdated(e)
    {
        e.preventDefault();
        this.setState({CommissionSegmentPaymentPaidStatus:e.target.value});
    }
    CommissionSegmentPaymentUpdated(e,key){
        e.preventDefault();
        let payment = this.state.CommissionSegmentPayment;
        if(payment===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let paymentEntity = {'PaidType':'','AmountExGST':'','Total':'','WhenPayable':'','Total':'','Balance':''};
            
            payment.push(paymentEntity);
        }
        payment[key]=e.target.value;

        this.setState({CommissionSegmentPayment:payment},()=>{this.UpdateFeeSegment()});

    }

    InsuranceSegmentInsuranceTypeUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentInsuranceType:e.target.value});
    }
    InsuranceSegmentInsuranceDescriptionUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentInsuranceDescription:e.target.value});
    }
    InsuranceSegmentInsurancePolicyNoUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentInsurancePolicyNo:e.target.value});
    }
    InsuranceSegmentPaymentPaidStatusUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentPaymentPaidStatus:e.target.value});
    }
    InsuranceSegmentPaymentAmountExGSTUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentPaymentAmountExGST:e.target.value});
    }
    InsuranceSegmentPaymentTotalUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentPaymentTotal:e.target.value});
    }
    InsuranceSegmentPaymentGSTUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentPaymentGST:e.target.value});
    }
    InsuranceSegmentPaymentBalanceUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentPaymentBalance:e.target.value});
    }
    InsuranceSegmentPremiumPayerTypeUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentPremiumPayerType:e.target.value});
    }
    InsuranceSegmentInsuranceStartDateUpdated(e)
    {
        e.preventDefault();
        this.setState({ InsuranceSegmentInsuranceStartDate:e.target.value});
    }
    InsuranceSegmentInsuranceEndDateUpdated(e)
    {
        e.preventDefault();
        this.setState({InsuranceSegmentInsuranceEndDate:e.target.value});
    }

    SpecialConditionSegmentConditionNameUpdated(e)
    {
        e.preventDefault();
        this.setState({SpecialConditionSegmentConditionName:e.target.value});
    }
    SpecialConditionSegmentConditionDescriptionUpdated(e)
    {
        e.preventDefault();
        this.setState({SpecialConditionSegmentConditionDescription:e.target.value});
    }

    UpdateFeeSegmentFees(e,i,key){
        e.preventDefault();
        let fees = this.state.FeeSegmentFees;
        if(fees[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let feesEntity = {'Name':'','Description':'','Type':'','WhenPayable':'','PayFeesFromLoanAccount':'','PaymentPaid':'','PaymentAmountEXGST':'','PaymentTotal':'','PaymentGST':'','PaymentBalance':'','PayerPayerType':''};
            
            fees.push(feesEntity);
        }
        fees[i][key]=e.target.value;

        this.setState({FeeSegmentFees:fees},()=>{this.UpdateFeeSegment()});

    }

    FeeSegmentDisclosureDateValidUpdated(e)
    {
        e.preventDefault();
        this.setState({FeeSegmentDisclosureDateValid:e.target.value});
    }

    InstructionsSegmentDocumentsUpdated(e,i,key){
        e.preventDefault();
        let docs = this.state.InstructionsSegmentDocuments;
        if(docs[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let docsEntity = {'Name':'','NumberOfCopies':'','Issuer"':''};
            
            docs.push(docsEntity);
        }
        docs[i][key]=e.target.value;

        this.setState({InstructionsSegmentDocuments:docs},()=>{this.UpdateInstructionsDocumentsSegment()});

    }

    UpdateInstructionsDocumentsSegment(){
        let ents = this.state.InstructionsSegmentDocumentsEntities;

        if(ents.length>this.state.InstructionsSegmentDocumentsEntityCount){
           
            //let partyPhones = this.state.PartySegmentPhoneNumbers;
            // let partyHomePhones = this.state.PartySegmentHomePhoneNumbers;
            // let partyWorkPhones = this.state.PartySegmentWorkPhoneNumbers;
            // let partyHomeFax = this.state.PartySegmentHomeFaxNumbers;
            // let partyWorkFax = this.state.PartySegmentWorkFaxNumbers;
            //let feeNames = this.state.FeeSegmentNames;
            let docs = this.state.InstructionsSegmentDocuments;
            for(let i = this.state.InstructionsSegmentDocuments.length; i>this.state.InstructionsSegmentDocumentsEntityCount  && i>this.state.InstructionsSegmentDocumentsEntityMinCount; i--){
                // partyAddresses.pop();
                // partyNames.pop();
                // partyTypes.pop();
                ents.pop();
                // partyMobiles.pop();
                //partyPhones.pop();
                docs.pop();
            }
            this.setState({
                InstructionsSegmentDocumentsEntities:ents,
                InstructionsSegmentDocuments: docs,
                // PartySegmentAddresses:partyAddresses,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,PartySegmentEntities:ents,
                // //PartySegmentPhoneNumbers:partyPhones,
                // PartySegmentHomePhoneNumbers: partyHomePhones,
                // PartySegmentWorkPhoneNumbers: partyWorkPhones,
                // PartySegmentHomeFaxNumbers: partyHomeFax,
                // PartySegmentWorkFaxNumbers: partyWorkFax,
                // PartySegmentMobileNumbers:partyMobiles,PartySegmentEmails:partyEmails,PartySegmentPorCTypes:partyPorCTypes,PartySegmentPrimaryApplicant:partyPrimaryApplicant
            });
        }else{
            // let partyTypes= this.state.PartySegmentPartyTypes;
            // let partyAdd = this.state.PartySegmentAddresses;
            // let partyNames = this.state.PartySegmentNames;
            // let partyPrimaryApplicant = this.state.PartySegmentPrimaryApplicant;
            // let partyPorCTypes = this.state.PartySegmentPorCTypes;
            let docs = this.state.InstructionsSegmentDocuments;
            //let partyPhones = this.state.PartySegmentPhoneNumbers;
           
            let docsEntity = {'Name':'','NumberOfCopies':'','Issuer"':''};
            // let partyNameEntity = {'NameTitle':'','FirstName':'','MiddleNames':'','Surname':'','Birthdate':'',
            // 'CompanyName':'','Acn':'','Abn':'','Arbn':'','NumberofDirectors':'','CompanyTypeDesc':'','CompanyPosition':'','CompanyAttentionName':''
            // ,'PartnershipName':'','PartnershipType':'','PartnershipAbn':''};
            for(let f = 0; f<this.state.InstructionsSegmentDocumentsEntityCount; f++){
                if(docs.length<this.state.InstructionsSegmentDocumentsEntityCount){
                    docs.push(docsEntity);
                    // partyNames.push(partyNameEntity);
                    // partyAdd.push(addressEntity);
                    // partyTypes.push('Borrower');
                    // partyPrimaryApplicant.push('Yes');
                    // partyPorCTypes.push('Person');
                    // partyEmails.push('');
                    // //partyPhones.push('');
                    // partyHomePhones.push('');
                    // partyWorkPhones.push('');
                    // partyHomeFax.push('');
                    // partyWorkFax.push('');
                    // partyMobiles.push('');
                }
            }
            this.setState({
                InstructionsSegmentDocumentsEntities:ents,
                //FeeSegmentNames: feeNames,
                InstructionsSegmentDocuments: docs,
                // PartySegmentAddresses:partyAdd,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,PartySegmentPorCTypes:partyPorCTypes,PartySegmentEmails:partyEmails, 
                // //PartySegmentPhoneNumbers:partyPhones,
                // PartySegmentHomePhoneNumbers: partyHomePhones,
                // PartySegmentWorkPhoneNumbers: partyWorkPhones,
                // PartySegmentHomeFaxNumbers: partyHomeFax,
                // PartySegmentWorkFaxNumbers: partyWorkFax,
                // PartySegmentMobileNumbers:partyMobiles,PartySegmentPrimaryApplicant:partyPrimaryApplicant
            },()=>{
                        for(let i =0; i < this.state.InstructionsSegmentDocumentsEntityCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Document #{i+1}</h5>

                                    <div id={"DocumentId"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Document Name
                                            </div>
                                            <input type="text" id={'DocumentName'+i}  className="form-control" value={this.state.InstructionsSegmentDocuments[i]['Name']} onChange={(e)=>{e.preventDefault();this.InstructionsSegmentDocumentsUpdated(e,i,'Name')}} placeholder="Document Name" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>                                           
                                        </div>                                       
                                                                                                     
                                    </div>

                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Number Of Copies
                                        </div>
                                        <input type="number" min="1" step="any" id={'DocumentNumberofCopies'+i} className="form-control" value={this.state.InstructionsSegmentDocuments[i]['DocumentNumberofCopies']} onChange={(e)=>{e.preventDefault();this.InstructionsSegmentDocumentsUpdated(e,i,'DocumentNumberofCopies')}} placeholder="Number of Documents" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                                    </div>

                                    
                                    {/* <div className="inputBox">
                                    <div className="inputLabel" id={"LoanTermTypeLabel"+1}>
                                    Loan Payment Frequency
                                    </div>
                                    <div>
                                        <select className="form-control" id="LoanPaymentFrequency" value={this.state.LoanPaymentFrequencies[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanPaymentFrequencies; vals[i]=e.target.value;this.setState({LoanPaymentFrequencies:vals},()=>{this.UpdateLoanIdSegment(e,i)})}}>
                                            <option selected>Select Loan Payment Frequency</option>
                                            {mappedLoanPaymentFrequencies}
                                        </select>
                                    </div>
                                </div>  */}

                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Issuer
                                        </div>
                                        <div>
                                            <select className="form-control" id="DocumentIssuer" value={this.state.InstructionsSegmentDocuments[i]['Issuer']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.InstructionsSegmentDocuments; vals[i]['Issuer']=e.target.value;this.setState({InstructionsSegmentDocuments:vals},()=>{this.InstructionsSegmentDocumentsUpdated(e,i,'Issuer')})}}>
                                                {this.state.DocumentIssuerList}
                                            </select>
                                        </div>
                                    </div>

                                  
                                </>));
                               
                        }                        
                        this.setState({InstructionsSegmentDocumentsEntities:ents,
                            //FeeSegmentNames: feeNames,
                            InstructionsSegmentDocuments: docs,
                            });
            });

           
        }
    }

    UpdateFeeSegment(){
        let ents = this.state.FeeSegmentEntities;

        if(ents.length>this.state.FeeSegmentEntityCount){
           
            //let partyPhones = this.state.PartySegmentPhoneNumbers;
            // let partyHomePhones = this.state.PartySegmentHomePhoneNumbers;
            // let partyWorkPhones = this.state.PartySegmentWorkPhoneNumbers;
            // let partyHomeFax = this.state.PartySegmentHomeFaxNumbers;
            // let partyWorkFax = this.state.PartySegmentWorkFaxNumbers;
            //let feeNames = this.state.FeeSegmentNames;
            let fees = this.state.FeeSegmentFees;
            for(let i = this.state.FeeSegmentEntities.length; i>this.state.FeeSegmentEntityCount  && i>this.state.FeeSegmentEntityMinCount; i--){
                // partyAddresses.pop();
                // partyNames.pop();
                // partyTypes.pop();
                ents.pop();
                // partyMobiles.pop();
                //partyPhones.pop();
                fees.pop();
            }
            this.setState({
                FeeSegmentEntities:ents,
                FeeSegmentFees: fees,
                // PartySegmentAddresses:partyAddresses,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,PartySegmentEntities:ents,
                // //PartySegmentPhoneNumbers:partyPhones,
                // PartySegmentHomePhoneNumbers: partyHomePhones,
                // PartySegmentWorkPhoneNumbers: partyWorkPhones,
                // PartySegmentHomeFaxNumbers: partyHomeFax,
                // PartySegmentWorkFaxNumbers: partyWorkFax,
                // PartySegmentMobileNumbers:partyMobiles,PartySegmentEmails:partyEmails,PartySegmentPorCTypes:partyPorCTypes,PartySegmentPrimaryApplicant:partyPrimaryApplicant
            });
        }else{
            // let partyTypes= this.state.PartySegmentPartyTypes;
            // let partyAdd = this.state.PartySegmentAddresses;
            // let partyNames = this.state.PartySegmentNames;
            // let partyPrimaryApplicant = this.state.PartySegmentPrimaryApplicant;
            // let partyPorCTypes = this.state.PartySegmentPorCTypes;
            let fees = this.state.FeeSegmentFees;
            //let partyPhones = this.state.PartySegmentPhoneNumbers;
           
            let feesEntity = {'Name':'','Description':'','Type':'','WhenPayable':'','PayFeesFromLoanAccount':'','PaymentPaid':'','PaymentAmountEXGST':'','PaymentTotal':'','PaymentGST':'','PaymentBalance':'','PayerPayerType':''};
            // let partyNameEntity = {'NameTitle':'','FirstName':'','MiddleNames':'','Surname':'','Birthdate':'',
            // 'CompanyName':'','Acn':'','Abn':'','Arbn':'','NumberofDirectors':'','CompanyTypeDesc':'','CompanyPosition':'','CompanyAttentionName':''
            // ,'PartnershipName':'','PartnershipType':'','PartnershipAbn':''};
            for(let f = 0; f<this.state.FeeSegmentEntityCount; f++){
                if(fees.length<this.state.FeeSegmentEntityCount){
                    fees.push(feesEntity);
                    // partyNames.push(partyNameEntity);
                    // partyAdd.push(addressEntity);
                    // partyTypes.push('Borrower');
                    // partyPrimaryApplicant.push('Yes');
                    // partyPorCTypes.push('Person');
                    // partyEmails.push('');
                    // //partyPhones.push('');
                    // partyHomePhones.push('');
                    // partyWorkPhones.push('');
                    // partyHomeFax.push('');
                    // partyWorkFax.push('');
                    // partyMobiles.push('');
                }
            }
            this.setState({
                FeeSegmentEntities:ents,
                //FeeSegmentNames: feeNames,
                FeeSegmentFees: fees,
                // PartySegmentAddresses:partyAdd,PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,PartySegmentPorCTypes:partyPorCTypes,PartySegmentEmails:partyEmails, 
                // //PartySegmentPhoneNumbers:partyPhones,
                // PartySegmentHomePhoneNumbers: partyHomePhones,
                // PartySegmentWorkPhoneNumbers: partyWorkPhones,
                // PartySegmentHomeFaxNumbers: partyHomeFax,
                // PartySegmentWorkFaxNumbers: partyWorkFax,
                // PartySegmentMobileNumbers:partyMobiles,PartySegmentPrimaryApplicant:partyPrimaryApplicant
            },()=>{
                        for(let i =0; i < this.state.FeeSegmentEntityCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Fee #{i+1}</h5>

                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Fee Name
                                        </div>
                                        <div>
                                            <select className="form-control" id="FeeSegmentFeeName" value={this.state.FeeSegmentFees[i]['Name']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FeeSegmentFees; vals[i]['Name']=e.target.value;this.setState({FeeSegmentFees:vals},()=>{this.UpdateFeeSegmentFees(e,i,'Name')})}}>
                                                {this.state.FeeNameList}
                                            </select>
                                        </div>
                                    </div>
                                    <div id={"FeeName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Fee Description
                                            </div>
                                            <input type="text" id={'FeeNameDesc'+i}  className="form-control" value={this.state.FeeSegmentFees[i]['Description']} onChange={(e)=>{e.preventDefault();this.UpdateFeeSegmentFees(e,i,'Description')}} placeholder="Fee Description" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>                                            {/* <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdateFeeSegment()})}}>    
                                                <RadioButton value="Borrower" name={"PartyType"+i} title="Borrower" checked={this.state.PartySegmentPartyTypes[i]==='Borrower'}/>                              
                                                <RadioButton value="Guarantor" name={"PartyType"+i} title="Guarantor" checked={this.state.PartySegmentPartyTypes[i]==='Guarantor'}/>                                                                                              
                                            </div> */}
                                        </div>                                       
                                                                                                     
                                    </div>

                                    {/*  
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Number of Payments
                                        </div>
                                        <input type="number" min="1" step="any" id={'LoanTerm'+i} className="dollarTextBox" value={this.state.LoanTermMonths[i]} onChange={(e)=>{e.preventDefault();this.LoanTermUpdated(e,i)}} placeholder="Loan Term (Months)" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                                    </div> */}

                                  
                                </>));
                               
                        }                        
                        this.setState({FeeSegmentEntities:ents,
                             //FeeSegmentNames: feeNames
                             FeeSegmentFees: fees
                            });
            });

           
        }
    }
    

    PartyRepNameUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepNames;
        val[i]=e.target.value;
        this.setState({PartyRepNames:val},()=>{this.UpdateSecuritySegment()});
    }

    PartyRepEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepEmails;
        val[i]=e.target.value;
        this.setState({PartyRepEmails:val},()=>{this.UpdateSecuritySegment()});
    }
    PartyRepNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepNumbers;
        val[i]=e.target.value;
        this.setState({PartyRepNumbers:val},()=>{this.UpdateSecuritySegment()});
    }
    PartyRepTypeUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartyRepType;
        val[i]=e.target.value;
        this.setState({PartyRepType:val},()=>{this.UpdateSecuritySegment()});
    }
    UpdatePartyRep(){
        
       
    }


    IncomingMortgageeNameUpdated(e,i){
        //alert(e)
        e.preventDefault();
        let val = this.state.IncomingMortgageeNames;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNames:val},()=>{this.UpdateSecuritySegment()});
    }

    IncomingMortgageeEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeEmails;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeEmails:val},()=>{this.UpdateSecuritySegment()});
    }
    IncomingMortgageeNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeNumbers;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeNumbers:val},()=>{this.UpdateSecuritySegment()});
    }

    IncomingMortgageeTypeUpdated(e,i){
        e.preventDefault();
        let val = this.state.IncomingMortgageeType;
        val[i]=e.target.value;
        this.setState({IncomingMortgageeType:val},()=>{this.UpdateSecuritySegment()});
    }

    UpdateIncomingMortgageeSegment(){
        let ents = this.state.IncomingMortgageeSegmentEntities;
    }   

    PartySegmentEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentEmails;
        val[i]=e.target.value;

        let emailValidationError = "";

        if(e.target.value.trim() == ""){
            emailValidationError = "Please fill out email field";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else if(!this.isValidEmail(e.target.value)){
            emailValidationError = "Invalid email";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else{
            emailValidationError = "";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: true
                }
            );
        }

        this.setState({PartySegmentEmails:val},()=>{this.UpdatePartySegment()});
    }
    PartySegmentHomePhoneNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentHomePhoneNumbers;
        val[i]=e.target.value;
        this.setState({PartySegmentHomePhoneNumbers:val},()=>{this.UpdatePartySegment()});
    }
    PartySegmentHomeFaxNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentHomeFaxNumbers;
        val[i]=e.target.value;
        this.setState({PartySegmentHomeFaxNumbers:val},()=>{this.UpdatePartySegment()});
    }

    PartySegmentWorkPhoneNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentWorkPhoneNumbers;
        val[i]=e.target.value;
        this.setState({PartySegmentWorkPhoneNumbers:val},()=>{this.UpdatePartySegment()});
    }
    PartySegmentWorkFaxNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentWorkFaxNumbers;
        val[i]=e.target.value;
        this.setState({PartySegmentWorkFaxNumbers:val},()=>{this.UpdatePartySegment()});
    }

    PartySegmentMobileUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentMobileNumbers;
        val[i]=e.target.value;
        this.setState({PartySegmentMobileNumbers:val},()=>{this.UpdatePartySegment()});
    }

    PartySegmentAddressUsedforMailingUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentAddressUsedforMailing;
        val[i]=e.target.value;
        this.setState({PartySegmentAddressUsedforMailing:val},()=>{this.UpdatePartySegment()});
    }

    PartySegmentAddressPostSettlementUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentAddressPostSettlement;
        val[i]=e.target.value;
        this.setState({PartySegmentAddressPostSettlement:val},()=>{this.UpdatePartySegment()});
    }

    PartySegmentAddressPreSettlementUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentAddressPreSettlement;
        val[i]=e.target.value;
        this.setState({PartySegmentAddressPreSettlement:val},()=>{this.UpdatePartySegment()});
    }

    PartySegmentAddressResidentialUpdated(e,i){
        e.preventDefault();
        let val = this.state.PartySegmentAddressResidential;
        val[i]=e.target.value;
        this.setState({PartySegmentAddressResidential:val},()=>{this.UpdatePartySegment()});
    }

    PartyAddressUpdated(e,i,key){
        e.preventDefault();
        let address = this.state.PartySegmentAddresses;
        if(address[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let addressEntity = {'UsedforMailing':'','building':'','floor':'','floorType':'','street':'','POBox':'','deliveryType':'','overseasline1':'','overseasline2':'','overseasline3':'','overseasline4':'','city':'','state':'','overseasstate':'','postcode':'','countrycode':''};
            
            address.push(addressEntity);
        }
        address[i][key]=e.target.value;

        this.setState({PartySegmentAddresses:address},()=>{this.UpdatePartySegment()});

    }

    PartyOrCompanyTypeUpdated(e,i){
        e.preventDefault();
        // let types = this.state.PartySegmentPartyTypes;
        // types[i]=e.target.value;
        
        //this.setState({PartySegmentPorCTypes:types},()=>{this.UpdatePartySegment()});
        let inps = document.getElementById('PartyName'+i).getElementsByTagName('input');        
        for(let x = 0;x<inps.length;x++){
            inps[x].required=false;
        }
        if(e.target.value==='Person'){
            document.getElementById('PartyNamePerson'+i).style.display='inline-block';
            document.getElementById('PartyNameCompany'+i).style.display='none';
            document.getElementById('PartyNamePerson'+i).getElementsByTagName('input');
            let personInps = document.getElementById('PartyNamePerson'+i).getElementsByTagName('input');
            for(let x = 0;x<personInps.length;x++){
                personInps[x].required=true;
            }
        }
        if(e.target.value==='Company'){
            document.getElementById('PartyNameCompany'+i).style.display='inline-block';
            document.getElementById('PartyNamePerson'+i).style.display='none';
            let companyInps = document.getElementById('PartyNameCompany'+i).getElementsByTagName('input');        
            for(let x = 0;x<companyInps.length;x++){
                companyInps[x].required=true;
            }
        }
    }

    PartyNameUpdated(e,i,key){
        e.preventDefault();
        let names = this.state.PartySegmentNames;
        names[i][key]=e.target.value;
        this.setState({PartySegmentNames:names},()=>{this.UpdatePartySegment()});
    }

    PartyTypeUpdated(e,i){
        e.preventDefault();
        let types =this.state.PartySegmentPartyTypes;
        types[i]=e.target.value;
        this.setState({PartySegmentPartyTypes:types},()=>{this.UpdatePartySegment()});
    }

    PartyGuarantorLiabilityAmountUpdated(e,i){
        e.preventDefault();
        let liablityAmount =this.state.PartySegmentGuarantorLiabilityAmount;
        liablityAmount[i]=e.target.value;
        this.setState({PartySegmentGuarantorLiabilityAmount:liablityAmount},()=>{this.UpdatePartySegment()});
    }

    PartySegmentGuarantorLiabilityOtherUpdated(e,i){
        e.preventDefault();
        let liabilityOther =this.state.PartySegmentGuarantorLiabilityOther;
        liabilityOther[i]=e.target.value;
        this.setState({PartySegmentGuarantorLiabilityOther:liabilityOther},()=>{this.UpdatePartySegment()});
    }

    UpdateApplicationSegment(){

    }

    UpdatePartySegment(){
        let ents = this.state.PartySegmentEntities;

        // let titleOptions=[];
        // for(let i=0; i<this.state.MappedTitles.length;i++){
        //     titleOptions.push(<option>{this.state.MappedTitles[i]}</option>);
        // }
        
        
        //let mappedTitleOptions = titleOptions.length? titleOptions.reduce((p,c)=>[p,'',c]):'';

        // console.log("title")
        // console.log(mappedTitleOptions)
        
        if(ents.length>this.state.PartySegmentCount){
            let partyAddresses = this.state.PartySegmentAddresses;
            let partyApplicantTypes = this.state.PartySegmentApplicantTypes;
            let partyIndependentLegalAdvice = this.state.PartySegmentIndependentLegalAdvice;
            let partyIndependentFinancialAdvice = this.state.PartySegmentIndependentFinancialAdvice;
            let partyChangeOfName = this.state.PartySegmentChangeOfName;
            let partyChangeOfNameReason = this.state.PartySegmentChangeOfNameReason;
            let partySignUnderPoA = this.state.PartySegmentSignUnderPoA;
            let partyGrantGSA = this.state.PartySegmentGrantGSA;
            let partyGrantSSA = this.state.PartySegmentGrantSSA;
            let partyGuarantorLimitedLiability = this.state.PartySegmentGuarantorLimitedLiability;
            let partyGuarantorLiabilityAmount = this.state.PartySegmentGuarantorLiabilityAmount;
            let partyGuarantorLiabilityOther = this.state.PartySegmentGuarantorLiabilityOther;
            let partyNames = this.state.PartySegmentNames;
            let partyPrimaryApplicant = this.state.PartySegmentPrimaryApplicant;
            let partyPorCTypes = this.state.PartySegmentPorCTypes;
            let partyTypes= this.state.PartySegmentPartyTypes;
            let partyEmails = this.state.PartySegmentEmails;
            let partyMobiles = this.state.PartySegmentMobileNumbers;
            //let partyPhones = this.state.PartySegmentPhoneNumbers;
            let partyHomePhones = this.state.PartySegmentHomePhoneNumbers;
            let partyWorkPhones = this.state.PartySegmentWorkPhoneNumbers;
            let partyHomeFax = this.state.PartySegmentHomeFaxNumbers;
            let partyWorkFax = this.state.PartySegmentWorkFaxNumbers;
            let partyAddressUsedforMailing = this.state.PartySegmentAddressUsedforMailing;
            let partyAddressPostSettlement = this.state.PartySegmentAddressPostSettlement;
            let partyAddressPreSettlement = this.state.PartySegmentAddressPreSettlement;
            let partyAddressResidential = this.state.PartySegmentAddressResidential;
            for(let i = this.state.PartySegmentEntities.length; i>this.state.PartySegmentCount  && i>this.state.PartySegmentMinCount; i--){
                partyAddresses.pop();
                partyApplicantTypes.pop();
                partyIndependentLegalAdvice.pop();
                partyIndependentFinancialAdvice.pop();
                partyChangeOfName.pop();
                partyChangeOfNameReason.pop();
                partySignUnderPoA.pop();
                partyGrantGSA.pop();
                partyGrantSSA.pop();
                partyGuarantorLimitedLiability.pop();
                partyGuarantorLiabilityAmount.pop();
                partyGuarantorLiabilityOther.pop();
                partyNames.pop();
                partyTypes.pop();
                ents.pop();
                partyMobiles.pop();
                //partyPhones.pop();
                partyHomePhones.pop();
                partyWorkPhones.pop();
                partyHomeFax.pop();
                partyWorkFax.pop();
                partyEmails.pop();
                partyPrimaryApplicant.pop();
                partyPorCTypes.pop();
                partyAddressUsedforMailing.pop();
                partyAddressPostSettlement.pop();
                partyAddressPreSettlement.pop();
                partyAddressResidential.pop();
            }
            this.setState({PartySegmentAddresses:partyAddresses,
                PartySegmentApplicantTypes: partyApplicantTypes,
                PartySegmentIndependentLegalAdvice: partyIndependentLegalAdvice,
                PartySegmentIndependentFinancialAdvice: partyIndependentFinancialAdvice,
                PartySegmentChangeOfName: partyChangeOfName,
                PartySegmentChangeOfNameReason: partyChangeOfNameReason,
                PartySegmentSignUnderPoA: partySignUnderPoA,
                PartySegmentGrantGSA: partyGrantGSA,
                PartySegmentGrantSSA: partyGrantSSA,
                PartySegmentGuarantorLimitedLiability: partyGuarantorLimitedLiability,
                PartySegmentGuarantorLiabilityAmount: partyGuarantorLiabilityAmount,
                PartySegmentGuarantorLiabilityOther: partyGuarantorLiabilityOther,
                PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,PartySegmentEntities:ents,
                //PartySegmentPhoneNumbers:partyPhones,
                PartySegmentHomePhoneNumbers: partyHomePhones,
                PartySegmentWorkPhoneNumbers: partyWorkPhones,
                PartySegmentHomeFaxNumbers: partyHomeFax,
                PartySegmentWorkFaxNumbers: partyWorkFax,
                PartySegmentMobileNumbers:partyMobiles,PartySegmentEmails:partyEmails,PartySegmentPorCTypes:partyPorCTypes,PartySegmentPrimaryApplicant:partyPrimaryApplicant,
                PartySegmentAddressUsedforMailing: partyAddressUsedforMailing,
                PartySegmentAddressPostSettlement: partyAddressPostSettlement,
                PartySegmentAddressPreSettlement: partyAddressPreSettlement,
                PartySegmentAddressResidential: partyAddressResidential
             });
        }else{
            let partyTypes= this.state.PartySegmentPartyTypes;
            let partyAdd = this.state.PartySegmentAddresses;
            let partyApplicantTypes = this.state.PartySegmentApplicantTypes;
            let partyIndependentLegalAdvice = this.state.PartySegmentIndependentLegalAdvice;
            let partyIndependentFinancialAdvice = this.state.PartySegmentIndependentFinancialAdvice;
            let partyChangeOfName = this.state.PartySegmentChangeOfName;
            let partyChangeOfNameReason = this.state.PartySegmentChangeOfNameReason;
            let partySignUnderPoA = this.state.PartySegmentSignUnderPoA;
            let partyGrantGSA = this.state.PartySegmentGrantGSA;
            let partyGrantSSA = this.state.PartySegmentGrantSSA;
            let partyGuarantorLimitedLiability = this.state.PartySegmentGuarantorLimitedLiability;
            let partyGuarantorLiabilityAmount = this.state.PartySegmentGuarantorLiabilityAmount;
            let partyGuarantorLiabilityOther = this.state.PartySegmentGuarantorLiabilityOther;
            let partyNames = this.state.PartySegmentNames;
            let partyPrimaryApplicant = this.state.PartySegmentPrimaryApplicant;
            let partyPorCTypes = this.state.PartySegmentPorCTypes;
            let partyEmails = this.state.PartySegmentEmails;
            //let partyPhones = this.state.PartySegmentPhoneNumbers;
            let partyHomePhones = this.state.PartySegmentHomePhoneNumbers;
            let partyWorkPhones = this.state.PartySegmentWorkPhoneNumbers;
            let partyHomeFax = this.state.PartySegmentHomeFaxNumbers;
            let partyWorkFax = this.state.PartySegmentWorkFaxNumbers;
            let partyMobiles = this.state.PartySegmentMobileNumbers;
            let partyAddressUsedforMailing = this.state.PartySegmentAddressUsedforMailing;
            let partyAddressPostSettlement = this.state.PartySegmentAddressPostSettlement;
            let partyAddressPreSettlement = this.state.PartySegmentAddressPreSettlement;
            let partyAddressResidential = this.state.PartySegmentAddressResidential;
            let addressEntity = {'UsedforMailing':'','building':'','floor':'','floorType':'','street':'','POBox':'','deliveryType':'','overseasline1':'','overseasline2':'','overseasline3':'','overseasline4':'','city':'','state':'','overseasstate':'','postcode':'','countrycode':''};
            let partyNameEntity = {'Gender':'','PreviousName':'','NameTitle':'','FirstName':'','MiddleNames':'','Surname':'','Birthdate':'',
            'CompanyName':'','Acn':'','Abn':'','Arbn':'','NumberofDirectors':'','CompanyTypeDesc':'','CompanyPosition':'','CompanyAttentionName':''
            ,'PartnershipName':'','PartnershipType':'','PartnershipAbn':''};
            for(let f = 0; f<this.state.PartySegmentCount; f++){
                if(partyEmails.length<this.state.PartySegmentCount){
                 
                    partyNames.push(partyNameEntity);
                    partyAdd.push(addressEntity);
                    partyApplicantTypes.push('');
                    partyTypes.push('Borrower');
                    partyIndependentLegalAdvice.push('Yes');
                    partyIndependentFinancialAdvice.push('Yes');
                    partyChangeOfName.push('Yes');
                    partyChangeOfNameReason.push('');
                    partySignUnderPoA.push('');
                    partyGrantGSA.push('');
                    partyGrantSSA.push('');
                    partyGuarantorLimitedLiability.push('Yes');
                    partyGuarantorLiabilityAmount.push('');
                    partyGuarantorLiabilityOther.push('');
                    partyPrimaryApplicant.push('Yes');
                    partyPorCTypes.push('Person');
                    partyEmails.push('');
                    //partyPhones.push('');
                    partyHomePhones.push('');
                    partyWorkPhones.push('');
                    partyHomeFax.push('');
                    partyWorkFax.push('');
                    partyMobiles.push('');
                    partyAddressUsedforMailing.push('Yes');
                    partyAddressPostSettlement.push('');
                    partyAddressPreSettlement.push('');
                    partyAddressResidential.push('');
                }
            }
            this.setState({PartySegmentAddresses:partyAdd,
                PartySegmentApplicantTypes: partyApplicantTypes,
                PartySegmentIndependentLegalAdvice: partyIndependentLegalAdvice,
                PartySegmentIndependentFinancialAdvice: partyIndependentFinancialAdvice,
                PartySegmentChangeOfName: partyChangeOfName,
                PartySegmentChangeOfNameReason: partyChangeOfNameReason,
                PartySegmentSignUnderPoA: partySignUnderPoA,
                PartySegmentGrantGSA: partyGrantGSA,
                PartySegmentGrantSSA: partyGrantSSA,
                PartySegmentGuarantorLimitedLiability: partyGuarantorLimitedLiability,
                PartySegmentGuarantorLiabilityAmount: partyGuarantorLiabilityAmount,
                PartySegmentGuarantorLiabilityOther: partyGuarantorLiabilityOther,
                PartySegmentNames:partyNames,PartySegmentPartyTypes:partyTypes,PartySegmentPorCTypes:partyPorCTypes,PartySegmentEmails:partyEmails, 
                //PartySegmentPhoneNumbers:partyPhones,
                PartySegmentHomePhoneNumbers: partyHomePhones,
                PartySegmentWorkPhoneNumbers: partyWorkPhones,
                PartySegmentHomeFaxNumbers: partyHomeFax,
                PartySegmentWorkFaxNumbers: partyWorkFax,
                PartySegmentMobileNumbers:partyMobiles,PartySegmentPrimaryApplicant:partyPrimaryApplicant,PartySegmentAddressUsedforMailing:partyAddressUsedforMailing,
                PartySegmentAddressPostSettlement: partyAddressPostSettlement,
                PartySegmentAddressPreSettlement: partyAddressPreSettlement,
                PartySegmentAddressResidential: partyAddressResidential },()=>{
                        for(let i =0; i < this.state.PartySegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Loan Party #{i+1}</h5>
                                    <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Party Type
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Borrower" name={"PartyType"+i} title="Borrower" checked={this.state.PartySegmentPartyTypes[i]==='Borrower'}/>                              
                                                <RadioButton value="Guarantor" name={"PartyType"+i} title="Guarantor" checked={this.state.PartySegmentPartyTypes[i]==='Guarantor'}/>                                                                                              
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Primary Applicant?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyPrimaryApplicant"+i} value={this.state.PartySegmentPrimaryApplicant[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPrimaryApplicant; vals[i]=e.target.value;this.setState({PartySegmentPrimaryApplicant:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartyPrimaryApplicant"+i} title="Yes" checked={this.state.PartySegmentPrimaryApplicant[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartyPrimaryApplicant"+i} title="No" checked={this.state.PartySegmentPrimaryApplicant[i]==='No'} />                                           
                                            </div>
                                        </div> 
                                        {/* <div className="inputBox">
                                            <div className="inputLabel" id={"PersonOrCompanyLabel"+1}>
                                                Individual or Company?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PersonOrCompany"+i} value={this.state.PartySegmentPorCTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPorCTypes; vals[i]=e.target.value;this.setState({PartySegmentPorCTypes:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Person" name={"PersonOrCompany"+i} title="Person" checked={this.state.PartySegmentPorCTypes[i]==='Person'} />                              
                                                <RadioButton value="Company" name={"PersonOrCompany"+i} title="Company" checked={this.state.PartySegmentPorCTypes[i]==='Company'} />                              
                                            </div>
                                        </div> */}
                                        <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i}>
                                            <div className="inputLabel">
                                                Applicant Transaction
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantTransactionTypeLabel"+i} value={this.state.PartySegmentApplicantTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentApplicantTypes; vals[i]=e.target.value;this.setState({PartySegmentApplicantTypes:vals},()=>{this.UpdatePartySegment()})}}>
                                                {this.state.ApplicantTransactionList}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Independent Legal Advice?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyIndependentLegalAdvice"+i} value={this.state.PartySegmentIndependentLegalAdvice[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentIndependentLegalAdvice; vals[i]=e.target.value;this.setState({PartySegmentIndependentLegalAdvice:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartyIndependentLegalAdvice"+i} title="Yes" checked={this.state.PartySegmentIndependentLegalAdvice[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartyIndependentLegalAdvice"+i} title="No" checked={this.state.PartySegmentIndependentLegalAdvice[i]==='No'} />                                           
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Independent Financial Advice?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyIndependentFinancialAdvice"+i} value={this.state.PartySegmentIndependentFinancialAdvice[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentIndependentFinancialAdvice; vals[i]=e.target.value;this.setState({PartySegmentIndependentFinancialAdvice:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartyIndependentFinancialAdvice"+i} title="Yes" checked={this.state.PartySegmentIndependentFinancialAdvice[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartyIndependentFinancialAdvice"+i} title="No" checked={this.state.PartySegmentIndependentFinancialAdvice[i]==='No'} />                                           
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Change Of Name?
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="No" value={this.state.PartySegmentChangeOfName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentChangeOfName; vals[i]=e.target.value;this.setState({PartySegmentChangeOfName:vals},()=>{this.UpdatePartySegment()})}}>
                                                {this.state.ChangeNameYNList}
                                                </select>
                                            </div>
                                            {/* <div className="multiColsInputBoxContentWidth" id={"PartyChangeOfName"+i} value={this.state.PartySegmentChangeOfName[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentChangeOfName; vals[i]=e.target.value;this.setState({PartySegmentChangeOfName:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartyChangeOfName"+i} title="Yes" checked={this.state.PartySegmentChangeOfName[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartyChangeOfName"+i} title="No" checked={this.state.PartySegmentChangeOfName[i]==='No'} />                                           
                                            </div> */}
                                        </div>
                                        <div className="inputBox LPNameBox" id={"ApplicantTransactionLabel"+i} style={{display:this.state.PartySegmentChangeOfName[i]==='No'?'none':'block'}}>
                                            <div className="inputLabel">
                                               Change of the Name Reason
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"ApplicantChangeOfNameReasonLabel"+i} defaultValue="Marriage" value={this.state.PartySegmentChangeOfNameReason[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentChangeOfNameReason; vals[i]=e.target.value;this.setState({PartySegmentChangeOfNameReason:vals},()=>{this.UpdatePartySegment()})}}>
                                                {this.state.ChangeNameReasonList}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                SignUnderPoA?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartySignUnderPoA"+i} value={this.state.PartySegmentSignUnderPoA[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentSignUnderPoA; vals[i]=e.target.value;this.setState({PartySegmentSignUnderPoA:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartySignUnderPoA"+i} title="Yes" checked={this.state.PartySegmentSignUnderPoA[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartySignUnderPoA"+i} title="No" checked={this.state.PartySegmentSignUnderPoA[i]==='No'} />                                           
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                GrantGSA?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyGrantGSA"+i} value={this.state.PartySegmentGrantGSA[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentGrantGSA; vals[i]=e.target.value;this.setState({PartySegmentGrantGSA:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartyGrantGSA"+i} title="Yes" checked={this.state.PartySegmentGrantGSA[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartyGrantGSA"+i} title="No" checked={this.state.PartySegmentGrantGSA[i]==='No'} />                                           
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                GrantSSA?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyGrantSSA"+i} value={this.state.PartySegmentGrantSSA[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentGrantSSA; vals[i]=e.target.value;this.setState({PartySegmentGrantSSA:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartyGrantSSA"+i} title="Yes" checked={this.state.PartySegmentGrantSSA[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartyGrantSSA"+i} title="No" checked={this.state.PartySegmentGrantSSA[i]==='No'} />                                           
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                            GuarantorLimitedLiability?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyGuarantorLimitedLiability"+i} value={this.state.PartySegmentGuarantorLimitedLiability[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentGuarantorLimitedLiability; vals[i]=e.target.value;this.setState({PartySegmentGuarantorLimitedLiability:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Yes" name={"PartyGuarantorLimitedLiability"+i} title="Yes" checked={this.state.PartySegmentGuarantorLimitedLiability[i]==='Yes'} />                              
                                                <RadioButton value="No" name={"PartyGuarantorLimitedLiability"+i} title="No" checked={this.state.PartySegmentGuarantorLimitedLiability[i]==='No'} />                                           
                                            </div>
                                        </div>
                                        <div className="inputBox" style={{display:this.state.PartySegmentGuarantorLimitedLiability[i]==='Yes'?'block':'none'}}>
                                            <div className="inputLabel">
                                                GuarantorLiabilityAmount
                                            </div>
                                            <input type="number" min="1" step="any" id={'PartyGuarantorLiabilityAmount'+i} className={this.state.PartySegmentGuarantorLiabilityAmount[i]?"successTextBox": ''} value={this.state.PartySegmentGuarantorLiabilityAmount[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyGuarantorLiabilityAmountUpdated(e,i)}} autoComplete={false} placeholder="GuarantorLiabilityAmount" />
                                        </div> 
                                        <div className="inputBox" style={{display:this.state.PartySegmentGuarantorLimitedLiability[i]==='Yes'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Guarantor Liability Other
                                            </div>
                                            <input type="number" min="1" step="any" id={'PartyGuarantorGuarantorLiabilityOther'+i} className={this.state.PartySegmentGuarantorLiabilityOther[i]?"successTextBox": ''} value={this.state.PartySegmentGuarantorLiabilityOther[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyGuarantorLiabilityOtherUpdated(e,i)}} autoComplete={false} placeholder="Guarantor Liability Other" />
                                        </div> 
                                        <div className="inputBox LPNameBox" id={"PersonOrCompanyLabel"+i}>
                                            <div className="inputLabel">
                                                Individual or Company or Partnership?
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"PersonOrCompanyLabel"+i} value={this.state.PartySegmentPorCTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPorCTypes; vals[i]=e.target.value;this.setState({PartySegmentPorCTypes:vals},()=>{this.UpdatePartySegment()})}}>
                                                {this.state.MappedPartyTypes}
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="inputBox LPNameBox" id={"PartyTitlePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':this.state.PartySegmentPorCTypes[i]==='Company'?'none':'block'}}> */}
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Gender
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"PartyNamePersonGender"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentNames[i]['Gender']} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Gender')}}>
                                                {this.state.GenderList}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Previous Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonPreviousName'+i} className={this.state.PartySegmentNames[i]['PreviousName']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['PreviousName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'PreviousName')}} autoComplete={false} placeholder="Previous Name" />
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Title
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"PartyNamePersonTitle"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentNames[i]['NameTitle']} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'NameTitle')}}>
                                                {this.state.MappedTitles}
                                                </select>
                                            </div>
                                        </div>                                      
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonGivenName'+i} className={this.state.PartySegmentNames[i]['GivenName']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['GivenName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'GivenName')}} autoComplete={false} placeholder="Given Name" required={this.state.PartySegmentPorCTypes[i]==='Person'?true:false} />
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Middle Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'PartyNamePersonMiddleName'+i} className={this.state.PartySegmentNames[i]['MiddleName']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['MiddleName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'MiddleName')}} autoComplete={false} placeholder="Middle Name" />
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Surname
                                            </div>
                                            <div className="multiColsIinputBoxnputBox">
                                                <input type="text" id={'PartyNamePersonSurname'+i} className={this.state.PartySegmentNames[i]['Surname']?"successTextBox": ''} value={this.state.PartySegmentNames[i]['Surname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Surname')}} autoComplete={false} placeholder="Surname" required={this.state.PartySegmentPorCTypes[i]==='Person'?true:false} />
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Date Of Birth
                                            </div>
                                            <div className="dateBox">
                                                <input type="date" id={'PartyNamePersonDOB'+i} className={"form-control"+(this.state.PartySegmentNames[i]['Birthdate']?"successTextBox": '')} value={this.state.PartySegmentNames[i]['Birthdate']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Birthdate')}} autoComplete={false} placeholder="DD/MM/YY" required/>
                                            </div>
                                            {/* <br/> */}
                                        </div> 
                                        <div id={"PartyNameCompany"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Company'?'block':'none'}}>
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Company Name
                                                </div>
                                                <div>
                                                <input type="text" id={'PartyNamePersonCompanyName'+i}  value={this.state.PartySegmentNames[i]['CompanyName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'CompanyName')}} autoComplete={false} placeholder="Company Name" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ACN
                                                </div>                                                
                                                <div>
                                                    <input type="text" id={'PartyNamCompanyACN'+i} value={this.state.PartySegmentNames[i]['Acn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Acn')}} autoComplete={false} placeholder="ACN" />
                                                    
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ABN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyAbn'+i} value={this.state.PartySegmentNames[i]['Abn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Abn')}} autoComplete={false} placeholder="ABN" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ARBN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyArbn'+i} value={this.state.PartySegmentNames[i]['Arbn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Arbn')}} autoComplete={false} placeholder="ARBN" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Number of Directors
                                                </div>
                                                <div>                                                    
                                                    <input type="number" id={'PartyNameCompanyNumberofDirectors'+i} value={this.state.PartySegmentNames[i]['NumberofDirectors']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'NumberofDirectors')}} autoComplete={false} placeholder="Number of Directors" />
                                                </div>                                               
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Company Type
                                                </div>
                                               <div className="inputBox">
                                                    <select className="form-control" id={"PartyNameCompanyTypeDesc"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentNames[i]['CompanyTypeDesc']} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'CompanyTypeDesc')}}>
                                                        {this.state.CompanyTypes}
                                                    </select>                                            
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Position
                                                </div>
                                               <div className="inputBox">
                                                    <div>                                                    
                                                        <input type="text" id={'PartyNameCompanyPosition'+i} value={this.state.PartySegmentNames[i]['CompanyPosition']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'CompanyPosition')}} autoComplete={false} placeholder="Position" />
                                                    </div>                                            
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Name
                                                </div>
                                               <div className="inputBox">
                                                    <div>                                                    
                                                        <input type="text" id={'PartyNameCompanyAttentionName'+i} value={this.state.PartySegmentNames[i]['CompanyAttentionName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'CompanyAttentionName')}} autoComplete={false} placeholder="Name" />
                                                    </div>                                          
                                                </div>
                                            </div>
                                        </div>
                                        <div id={"PartyNamePartnership"+i} style={{display:this.state.PartySegmentPorCTypes[i]==='Partnership'?'block':'none'}}>
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Partnership Name
                                                </div>
                                                <div>
                                                <input type="text" id={'PartyNamePartnershipName'+i}  value={this.state.PartySegmentNames[i]['PartnershipName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'PartnershipName')}} autoComplete={false} placeholder="Partnership Name" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ABN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNamePartnershipAbn'+i} value={this.state.PartySegmentNames[i]['PartnershipAbn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'PartnershipAbn')}} autoComplete={false} placeholder="ABN" />
                                                </div>
                                            </div>
                                            {/* <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ARBN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyArbn'+i} value={this.state.PartySegmentNames[i]['Arbn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Arbn')}} autoComplete={false} placeholder="ARBN" />
                                                </div>
                                            </div> */}
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Partnership Type
                                                </div>
                                               <div className="inputBox">
                                                    <select className="form-control" id={"PartyNamePartnershipType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentNames[i]['PartnershipType']} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'PartnershipType')}}>
                                                        {this.state.MappedPartnershipTypes}
                                                    </select>                                            
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Number of Partners
                                                </div>
                                                <div>                                                    
                                                    <input type="number" id={'PartyNamePartnershipNumberofPartners'+i} value={this.state.PartySegmentNames[i]['NumberofPartners']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'NumberofPartners')}} autoComplete={false} placeholder="Number of Partners" />
                                                </div>                                               
                                            </div>
                                            
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Position
                                                </div>
                                               <div className="inputBox">
                                                    <div>                                                    
                                                        <input type="text" id={'PartyNamePartnershipPosition'+i} value={this.state.PartySegmentNames[i]['PartnerPosition']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'PartnerPosition')}} autoComplete={false} placeholder="Position" />
                                                    </div>                                            
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Name
                                                </div>
                                               <div className="inputBox">
                                                    <div>                                                    
                                                        <input type="text" id={'PartyNamePartnershipAttentionName'+i} value={this.state.PartySegmentNames[i]['PartnerAttentionName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'PartnerAttentionName')}} autoComplete={false} placeholder="Name" />
                                                    </div>                                          
                                                </div>
                                            </div>
                                        </div>
                                        <div className="inputLabel">Contact Details</div>
                                        <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>                                        
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Email
                                                </div>
                                                <div>
                                                    <input type="email" id={'PartyEmail'+i} className={(this.state.PartySegmentEmails[i] && this.state.validEmail==true)?'successTextBox':this.state.emailValidationError?"":''} value={this.state.PartySegmentEmails[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentEmailUpdated(e,i)}} autoComplete={false} placeholder="example@email.com" required={this.state.SelfActing[0]}/>                                                                                
                                                </div>
                                            </div>
                                    
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Home Phone
                                                </div>
                                                <div>                                                    
                                                    <input type="number" id={'PartyHomePhoneNumber'+i} className="form-control" value={this.state.PartySegmentHomePhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentHomePhoneNumberUpdated(e,i)}} autoComplete={false} placeholder="Phone Number"/>
                                                </div>
                                                <br/>
                                                <div>                                                    
                                                    <input type="number" id={'PartyHomeFaxNumber'+i} className="form-control" value={this.state.PartySegmentHomeFaxNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentHomeFaxNumberUpdated(e,i)}} autoComplete={false} placeholder="Fax Number"/>
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Mobile
                                                </div>
                                                <div>                                                    
                                                <input type="number" id={'PartyMobileNumber'+i} className="form-control" value={this.state.PartySegmentMobileNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentMobileUpdated(e,i)}} autoComplete={false} placeholder="Mobile"/>
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Work Phone
                                                </div>
                                                <div>                                                    
                                                    <input type="number" id={'PartyWorkPhoneNumber'+i} className="form-control" value={this.state.PartySegmentWorkPhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentWorkPhoneNumberUpdated(e,i)}} autoComplete={false} placeholder="Phone Number"/>
                                                </div>
                                                <br/>
                                                <div>                                                    
                                                    <input type="number" id={'PartyWorkFaxNumber'+i} className="form-control" value={this.state.PartySegmentWorkFaxNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentWorkFaxNumberUpdated(e,i)}} autoComplete={false} placeholder="Fax Number"/>
                                                </div>
                                            </div>
                                            <div className="inputBox" id="divPartyAddress">
                                                <div className="inputLabel">
                                                    Address
                                                </div>
                                                <div className="inputBox">
                                                    <div className="inputLabel" id={"PartyUsedforMailingLabel"+1}>
                                                        UsedforMailing?
                                                    </div>
                                                    {/* <div className="multiColsInputBoxContentWidth" id={"PartyUsedforMailing"+i} value={this.state.PartySegmentAddresses[i]['UsedforMailing']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentAddresses; vals[i]['UsedforMailing']=e.target.value;this.setState({PartySegmentAddresses:vals},()=>{this.PartyAddressUpdated(e,i,'UsedforMailing')})}}>    
                                                        <RadioButton value="Yes" name={"PartyUsedforMailing"+i} title="Yes" checked={this.state.PartySegmentAddresses[i]['UsedforMailing']==='Yes'} />                              
                                                        <RadioButton value="No" name={"PartyUsedforMailing"+i} title="No" checked={this.state.PartySegmentAddresses[i]['UsedforMailing']==='No'} />                                           
                                                    </div> */}
                                                    <div className="multiColsInputBoxContentWidth" id={"PartyUsedforMailing"+i} value={this.state.PartySegmentAddressUsedforMailing[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentAddressUsedforMailing; vals[i]=e.target.value;this.setState({PartySegmentAddressUsedforMailing:vals},()=>{this.UpdatePartySegment()})}}>    
                                                        <RadioButton value="Yes" name={"PartyUsedforMailing"+i} title="Yes" checked={this.state.PartySegmentAddressUsedforMailing[i]==='Yes'} />                              
                                                        <RadioButton value="No" name={"PartyUsedforMailing"+i} title="No" checked={this.state.PartySegmentAddressUsedforMailing[i]==='No'} />                                           
                                                    </div>
                                                </div>

                                                <div className="inputBox">
                                                    <div className="inputLabel" id={"PartyPostSettlementLabel"+1}>
                                                        PostSettlement?
                                                    </div>
                                                    <div className="multiColsInputBoxContentWidth" id={"PartyPostSettlement"+i} value={this.state.PartySegmentAddressPostSettlement[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentAddressPostSettlement; vals[i]=e.target.value;this.setState({PartySegmentAddressPostSettlement:vals},()=>{this.UpdatePartySegment()})}}>    
                                                        <RadioButton value="Yes" name={"PartyPostSettlement"+i} title="Yes" checked={this.state.PartySegmentAddressPostSettlement[i]==='Yes'} />                              
                                                        <RadioButton value="No" name={"PartyPostSettlement"+i} title="No" checked={this.state.PartySegmentAddressPostSettlement[i]==='No'} />                                           
                                                    </div>
                                                </div>

                                                <div className="inputBox">
                                                    <div className="inputLabel" id={"PartyPreSettlementLabel"+1}>
                                                        PreSettlement?
                                                    </div>
                                                    <div className="multiColsInputBoxContentWidth" id={"PartyPreSettlement"+i} value={this.state.PartySegmentAddressPreSettlement[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentAddressPreSettlement; vals[i]=e.target.value;this.setState({PartySegmentAddressPreSettlement:vals},()=>{this.UpdatePartySegment()})}}>    
                                                        <RadioButton value="Yes" name={"PartyPreSettlement"+i} title="Yes" checked={this.state.PartySegmentAddressPreSettlement[i]==='Yes'} />                              
                                                        <RadioButton value="No" name={"PartyPreSettlement"+i} title="No" checked={this.state.PartySegmentAddressPreSettlement[i]==='No'} />                                           
                                                    </div>
                                                </div>

                                                <div className="inputBox">
                                                    <div className="inputLabel" id={"PartyResidentialLabel"+1}>
                                                        Residential?
                                                    </div>
                                                    <div className="multiColsInputBoxContentWidth" id={"PartyResidential"+i} value={this.state.PartySegmentAddressResidential[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentAddressResidential; vals[i]=e.target.value;this.setState({PartySegmentAddressResidential:vals},()=>{this.UpdatePartySegment()})}}>    
                                                        <RadioButton value="Yes" name={"PartyResidential"+i} title="Yes" checked={this.state.PartySegmentAddressResidential[i]==='Yes'} />                              
                                                        <RadioButton value="No" name={"PartyResidential"+i} title="No" checked={this.state.PartySegmentAddressResidential[i]==='No'} />                                           
                                                    </div>
                                                </div>
                                                
                                                <div className="multiRowsInputBox">
                                                    <input type="text" id={'PartyBuilding'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['building']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'building')}} placeholder="Building Name" autoComplete={false}/>
                                                    <div className="multiColsInputBoxContentWidth">    
                                                        <input type="text" id={'PartyFloor'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['floor']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'floor')}} placeholder="Floor Number" autoComplete={false}/>
                                                        <select className="form-control" id={"PartyFloorType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['floorType']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'floorType')}}>
                                                            {this.state.FloorTypes}
                                                        </select>
                                                    </div>
                                                    <div className="multiColsInputBoxContentWidth">    
                                                        <input type="text" id={'PartyUnitNumberFrom'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['unitnumberfrom']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'unitnumberfrom')}} placeholder="Unit Number From" autoComplete={false}/>
                                                        <input type="text" id={'PartyUnitNumberTo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['unitnumberto']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'unitnumberto')}} placeholder="Unit Number To" autoComplete={false}/>
                                                        <select className="form-control" id={"PartyUnitType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['unitType']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'unitType')}}>
                                                            {this.state.UnitTypeList}
                                                        </select>
                                                    </div>
                                                    <input type="text" id={'PartyStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['street']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'street')}} placeholder="Street" autoComplete={false} required/>                                                
                                                    <div className="multiColsInputBoxContentWidth">    
                                                        <input type="text" id={'PartyPOBox'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['POBox']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'POBox')}} placeholder="PO Box Number" autoComplete={false}/>
                                                        <select className="form-control" id={"PartyDeliveryType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['deliveryType']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'deliveryType')}}>
                                                            {this.state.DeliveryTypes}
                                                        </select>
                                                    </div>
                                                    <input type="text" id={'PartyOverseasLine1'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['overseasline1']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'overseasline1')}} placeholder="Overseas Line 1" autoComplete={false}/>
                                                    <input type="text" id={'PartyOverseasLine2'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['overseasline2']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'overseasline2')}} placeholder="Overseas Line 2" autoComplete={false}/>
                                                    <input type="text" id={'PartyOverseasLine3'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['overseasline3']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'overseasline3')}} placeholder="Overseas Line 3" autoComplete={false}/>
                                                    <input type="text" id={'PartyOverseasLine4'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['overseasline4']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'overseasline4')}} placeholder="Overseas Line 4" autoComplete={false}/>
                                                    
                                                    <input type="text" id={'PartyCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['city']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'city')}} placeholder="Suburb" autoComplete={false} />
                                                
                                                    <div className="multiColsInputBox">
                                                        <select className="form-control" id={"PartyStateName"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['state']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'state')}}>
                                                            {this.state.MappedStates}
                                                        </select>
                                                        <input type="text" id={'PartyPostcode'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" maxLength="4" value={this.state.PartySegmentAddresses[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.PartyAddressUpdated(e,i,'postcode')}}} placeholder="Postcode" autoComplete={false} required/>
                                                    </div>
                                                    <div className="multiColsInputBox">
                                                    <input type="text" id={'PartyOverseasState'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" value={this.state.PartySegmentAddresses[i]['overseasstate']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'overseasstate')}} placeholder="Overseas State" autoComplete={false}/>
                                                        <select className="form-control" id={"PartyCountryCode"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.PartySegmentAddresses[i]['countrycode']} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'countrycode')}}>
                                                            <option selected>Country Code</option>
                                                            {this.state.CountryCodeList}
                                                        </select>
                                                        
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>       
                                        <br/>                                                                   
                                    </div>

                                    {/* <div style={{'display':this.state.SelfActing[0]?'block':'none'}} className="PartyAddress" id={"PartyAddress"+i}>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Street
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyStreet'+i} className="form-control" value={this.state.PartySegmentAddresses[i]['street']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'street')}} autoComplete={false} placeholder="Street" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                City
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyCity'+i} className="form-control" value={this.state.PartySegmentAddresses[i]['city']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'city')}} autoComplete={false} placeholder="City" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                State
                                            </div>
                                            <div>                                                    
                                                <select className="form-control" id={"StateName"+i} value={this.state.PartySegmentAddresses[i]['state']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyAddressUpdated(e,i,'state')}}>
                                                {this.state.MappedStates}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Postcode
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'Postcode'+i} className="form-control" maxLength="4" value={this.state.PartySegmentAddresses[i]['postcode']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.PartyAddressUpdated(e,i,'postcode')}}} autoComplete={false} placeholder="Postcode" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Phone Number
                                            </div>
                                            <div>                                                    
                                            <input type="text" id={'PartyPhoneNumber'+i} className="form-control" value={this.state.PartySegmentPhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartySegmentNumberUpdated(e,i)}} autoComplete={false} placeholder="" required={this.state.SelfActing[0]}/>
                                            </div>
                                        </div>
                                    </div>
                                     */}
                                </>));
                               
                        }                        
                        this.setState({PartySegmentEntities:ents});
            });

           
        }

    }

    RelatedPartyTypeUpdated(e,i){
        e.preventDefault();
        let partyTypes = this.state.RelatedPartySegmentPartyTypes;
        partyTypes[i]=e.target.value;
        this.setState({RelatedPartySegmentPartyTypes:partyTypes},()=>{this.UpdateRelatedPartySegment()});
    }

    RelatedPartyNameUpdated(e,i,key){
        e.preventDefault();
        let names = this.state.RelatedPartySegmentNames;
        names[i][key]=e.target.value;
        this.setState({RelatedPartySegmentNames:names},()=>{this.UpdateRelatedPartySegment()});
    }

    RelatedPartyTypeUpdated(e,i){
        e.preventDefault();
        let types =this.state.RelatedPartySegmentPartyTypes;
        types[i]=e.target.value;
        this.setState({RelatedPartySegmentPartyTypes:types},()=>{this.UpdateRelatedPartySegment()});
    }   
    
    RelatedPartyAddressUpdated(e,i,key){
        e.preventDefault();
        let address = this.state.RelatedPartySegmentAddresses;
        if(address[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let addressEntity = {'building':'','floor':'','floorType':'','street':'','POBox':'','deliveryType':'','overseasline1':'','overseasline2':'','overseasline3':'','overseasline4':'','city':'','state':'','overseasstate':'','postcode':'','countrycode':''};
            
            address.push(addressEntity);
        }
        address[i][key]=e.target.value;

        this.setState({RelatedPartySegmentAddresses:address},()=>{this.UpdateRelatedPartySegment()});

    }

    // RelatedPartySegmentNumberUpdated(e,i){
    //     e.preventDefault();
    //     let val = this.state.RelatedPartySegmentPhoneNumbers;
    //     val[i]=e.target.value;
    //     this.setState({RelatedPartySegmentPhoneNumbers:val},()=>{this.UpdateRelatedPartySegment()});
    // }

    RelatedPartySegmentHomePhoneNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.RelatedPartySegmentHomePhoneNumbers;
        val[i]=e.target.value;
        this.setState({RelatedPartySegmentHomePhoneNumbers:val},()=>{this.UpdateRelatedPartySegment()});
    }

    RelatedPartySegmentHomeFaxNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.RelatedPartySegmentHomeFaxNumbers;
        val[i]=e.target.value;
        this.setState({RelatedPartySegmentHomeFaxNumbers:val},()=>{this.UpdateRelatedPartySegment()});
    }

    RelatedPartySegmentWorkPhoneNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.RelatedPartySegmentWorkPhoneNumbers;
        val[i]=e.target.value;
        this.setState({RelatedPartySegmentWorkPhoneNumbers:val},()=>{this.UpdateRelatedPartySegment()});
    }
    RelatedPartySegmentWorkFaxNumberUpdated(e,i){
        e.preventDefault();
        let val = this.state.RelatedPartySegmentWorkFaxNumbers;
        val[i]=e.target.value;
        this.setState({RelatedPartySegmentWorkFaxNumbers:val},()=>{this.UpdateRelatedPartySegment()});
    }

    RelatedPartySegmentMobileUpdated(e,i){
        e.preventDefault();
        let val = this.state.RelatedPartySegmentMobileNumbers;
        val[i]=e.target.value;
        this.setState({RelatedPartySegmentMobileNumbers:val},()=>{this.UpdateRelatedPartySegment()});
    }

    RelatedPartySegmentMobileUpdated(e,i){
        e.preventDefault();
        let val = this.state.RelatedPartySegmentMobileNumbers;
        val[i]=e.target.value;
        this.setState({RelatedPartySegmentMobileNumbers:val},()=>{this.UpdateRelatedPartySegment()});
    }

    RelatedPartySegmentEmailUpdated(e,i){
        e.preventDefault();
        let val = this.state.RelatedPartySegmentEmails;
        val[i]=e.target.value;

        let emailValidationError = "";

        if(e.target.value.trim() == ""){
            emailValidationError = "Please fill out email field";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else if(!this.isValidEmail(e.target.value)){
            emailValidationError = "Invalid email";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else{
            emailValidationError = "";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: true
                }
            );
        }

        this.setState({RelatedPartySegmentEmails:val},()=>{this.UpdateRelatedPartySegment()});
    }

    TrustSegmentSettlorNamesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentSettlorNames;
        val[i]=e.target.value;
        this.setState({TrustSegmentSettlorNames:val},()=>{this.UpdateRelatedPartySegment()});
    }

    TrustSegmentTrustNamesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentTrustNames;
        val[i]=e.target.value;
        this.setState({TrustSegmentTrustNames:val},()=>{this.UpdateRelatedPartySegment()});
    }   

    TrustSegmentTrustTypesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentTrustTypes;
        val[i]=e.target.value;
        this.setState({TrustSegmentTrustTypes:val},()=>{this.UpdateRelatedPartySegment()});
    }

    TrustSegmentDeclarationDatesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentDeclarationDates;
        val[i]=e.target.value;
        this.setState({TrustSegmentDeclarationDates:val},()=>{this.UpdateRelatedPartySegment()});
    }

    TrustSegmentEstablishmentDatesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentEstablishmentDates;
        val[i]=e.target.value;
        this.setState({TrustSegmentEstablishmentDates:val},()=>{this.UpdateRelatedPartySegment()});
    }

    TrustSegmentVestingDatesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentVestingDates;
        val[i]=e.target.value;
        this.setState({TrustSegmentVestingDates:val},()=>{this.UpdateRelatedPartySegment()});
    }

    TrustSegmentTrusteeAppointmentDatesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentTrusteeAppointmentDates;
        val[i]=e.target.value;
        this.setState({TrustSegmentTrusteeAppointmentDates:val},()=>{this.UpdateRelatedPartySegment()});
    }

    TrustSegmentDeedVariationDatesUpdated(e,i){
        e.preventDefault();
        let val = this.state.TrustSegmentDeedVariationDates;
        val[i]=e.target.value;
        this.setState({TrustSegmentDeedVariationDates:val},()=>{this.UpdateRelatedPartySegment()});
    }

    UpdateRelatedPartySegment(){
        let ents = this.state.RelatedPartySegmentEntities;
        
        if(ents.length>this.state.RelatedPartySegmentCount){
            let partyAddresses = this.state.RelatedPartySegmentAddresses;
            let partyNames = this.state.RelatedPartySegmentNames;
            let partyPorCTypes = this.state.RelatedPartySegmentPorCTypes;
            let partyTypes= this.state.RelatedPartySegmentPartyTypes;
            let partyEmails = this.state.RelatedPartySegmentEmails;
            //let partyPhones = this.state.RelatedPartySegmentPhoneNumbers;
            let partyHomePhones = this.state.RelatedPartySegmentHomePhoneNumbers;
            let partyWorkPhones = this.state.RelatedPartySegmentWorkPhoneNumbers;
            let partyHomeFax = this.state.RelatedPartySegmentHomeFaxNumbers;
            let partyWorkFax = this.state.RelatedPartySegmentWorkFaxNumbers;
            let partyMobiles = this.state.RelatedPartySegmentMobileNumbers;
            let trustSettlorNames = this.state.TrustSegmentSettlorNames;
            let trustNames = this.state.TrustSegmentTrustNames;
            let trustTypes = this.state.TrustSegmentTrustTypes;
            let trustDeclarationDates = this.state.TrustSegmentDeclarationDates;
            let trustEstablishmentDates = this.state.TrustSegmentEstablishmentDates;
            let trustVestingDates = this.state.TrustSegmentVestingDates;
            let trustTrusteeAppointmentDates = this.state.TrustSegmentTrusteeAppointmentDates;
            let trustDeedVariationDates = this.state.TrustSegmentDeedVariationDates;

            for(let i = this.state.RelatedPartySegmentEntities.length; i>this.state.RelatedPartySegmentCount  && i>this.state.RelatedPartySegmentMinCount; i--){
                partyAddresses.pop();
                partyNames.pop();
                partyTypes.pop();
                ents.pop();
                //partyPhones.pop();
                partyHomePhones.pop();
                partyWorkPhones.pop();
                partyHomeFax.pop();
                partyWorkFax.pop();
                partyEmails.pop();
                partyMobiles.pop();
                partyEmails.pop();
                partyPorCTypes.pop();
                trustSettlorNames.pop();
                trustNames.pop();
                trustTypes.pop();
                trustDeclarationDates.pop();
                trustEstablishmentDates.pop();
                trustVestingDates.pop();
                trustTrusteeAppointmentDates.pop();
                trustDeedVariationDates.pop();
            }
            this.setState({RelatedPartySegmentAddresses:partyAddresses,RelatedPartySegmentNames:partyNames,RelatedPartySegmentPartyTypes:partyTypes,RelatedPartySegmentEntities:ents,
                //RelatedPartySegmentPhoneNumbers:partyPhones,
                RelatedPartySegmentHomePhoneNumbers: partyHomePhones,
                RelatedPartySegmentWorkPhoneNumbers: partyWorkPhones,
                RelatedPartySegmentHomeFaxNumbers: partyHomeFax,
                RelatedPartySegmentWorkFaxNumbers: partyHomeFax,
                RelatedPartySegmentMobileNumbers:partyMobiles,
                RelatedPartySegmentEmails:partyEmails,RelatedPartySegmentPorCTypes:partyPorCTypes,
                TrustSegmentSettlorNames: trustSettlorNames,
                TrustSegmentTrustNames: trustNames,
                TrustSegmentTrustTypes: trustTypes,
                TrustSegmentDeclarationDates: trustDeclarationDates,
                TrustSegmentEstablishmentDates: trustEstablishmentDates,
                TrustSegmentVestingDates: trustVestingDates,
                TrustSegmentTrusteeAppointmentDates: trustTrusteeAppointmentDates,
                TrustSegmentDeedVariationDates: trustDeedVariationDates,
            });
        }else{
            let partyTypes= this.state.RelatedPartySegmentPartyTypes;
            let partyAdd = this.state.RelatedPartySegmentAddresses;
            let partyNames = this.state.RelatedPartySegmentNames;
            let partyPorCTypes = this.state.RelatedPartySegmentPorCTypes;
            let partyEmails = this.state.RelatedPartySegmentEmails;
            //let partyPhones = this.state.RelatedPartySegmentPhoneNumbers;
            let partyHomePhones = this.state.RelatedPartySegmentHomePhoneNumbers;
            let partyWorkPhones = this.state.RelatedPartySegmentWorkPhoneNumbers;
            let partyHomeFax = this.state.RelatedPartySegmentHomeFaxNumbers;
            let partyWorkFax = this.state.RelatedPartySegmentWorkFaxNumbers;
            let partyMobiles = this.state.RelatedPartySegmentMobileNumbers;
            // let addressEntity = {'state':'','city':'','number':'','postcode':'','street':''};
            // let partyNameEntity = {'GivenName':'','MiddleName':'','Surname':'','CompanyName':'','Acn':'','Abn':'','Arbn':''};
            let addressEntity = {'building':'','floor':'','floorType':'','street':'','POBox':'','deliveryType':'','overseasline1':'','overseasline2':'','overseasline3':'','overseasline4':'','city':'','state':'','overseasstate':'','postcode':'','countrycode':''};
            let partyNameEntity = {'NameTitle':'','FirstName':'','MiddleNames':'','Surname':'','Birthdate':'','CompanyName':'','Acn':'','Abn':'','Arbn':''};
            let otherPartyTypes=[];
            let trustSettlorNames = this.state.TrustSegmentSettlorNames;
            let trustNames = this.state.TrustSegmentTrustNames;
            let trustTypes = this.state.TrustSegmentTrustTypes;
            let trustDeclarationDates = this.state.TrustSegmentDeclarationDates;
            let trustEstablishmentDates = this.state.TrustSegmentEstablishmentDates;
            let trustVestingDates = this.state.TrustSegmentVestingDates;
            let trustTrusteeAppointmentDates = this.state.TrustSegmentTrusteeAppointmentDates;
            let trustDeedVariationDates = this.state.TrustSegmentDeedVariationDates;

            console.log("test" + this.state.OtherPartyTypes.length);
            for(let i=0; i<this.state.OtherPartyTypes.length;i++){
                console.log("test" + this.state.OtherPartyTypes[i]);
                otherPartyTypes.push(<option>{this.state.OtherPartyTypes[i]}</option>);
            }
            let mappedOtherPartyTypes = otherPartyTypes.length? otherPartyTypes.reduce((p,c)=>[p,'',c]):'';
            for(let f = 0; f<this.state.RelatedPartySegmentCount; f++){
                if(partyEmails.length<this.state.RelatedPartySegmentCount){
                 
                    partyNames.push(partyNameEntity);
                    partyAdd.push(addressEntity);
                    partyTypes.push('Borrower');
                    partyPorCTypes.push('Person');
                    partyEmails.push('');
                    partyMobiles.push('');
                    //partyPhones.push('');
                    partyHomePhones.push('');
                    partyWorkPhones.push('');
                    partyHomeFax.push('');
                    partyWorkFax.push('');
                    trustSettlorNames.push('');
                    trustNames.push('');
                    trustTypes.push('');
                    trustDeclarationDates.push('');
                    trustEstablishmentDates.push('');
                    trustVestingDates.push('');
                    trustTrusteeAppointmentDates.push('');
                    trustDeedVariationDates.push('');
                }
            }
            this.setState({RelatedPartySegmentAddresses:partyAdd,RelatedPartySegmentNames:partyNames,RelatedPartySegmentPartyTypes:partyTypes,RelatedPartySegmentPorCTypes:partyPorCTypes,RelatedPartySegmentEmails:partyEmails, 
                //RelatedPartySegmentPhoneNumbers:partyPhones,
                RelatedPartySegmentHomePhoneNumbers: partyHomePhones,
                RelatedPartySegmentWorkPhoneNumbers: partyWorkPhones,
                RelatedPartySegmentHomeFaxNumbers: partyHomeFax,
                RelatedPartySegmentWorkFaxNumbers: partyWorkFax,
                RelatedPartySegmentMobileNumbers:partyMobiles,
                TrustSegmentSettlorNames: trustSettlorNames,
                TrustSegmentTrustNames: trustNames,
                TrustSegmentTrustTypes: trustTypes,
                TrustSegmentDeclarationDates: trustDeclarationDates,
                TrustSegmentEstablishmentDates: trustEstablishmentDates,
                TrustSegmentVestingDates: trustVestingDates,
                TrustSegmentTrusteeAppointmentDates: trustTrusteeAppointmentDates,
                TrustSegmentDeedVariationDates: trustDeedVariationDates,
            },()=>{
                        for(let i =0; i < this.state.RelatedPartySegmentCount; i++){
                            ents[i]=((
                                <>
                                    <h5 className="listContentTitle">Other Loan Party #{i+1}</h5>
                                    <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Party Type
                                            </div>
                                            <div>
                                                <select className="form-control" id="ApplicationTypeReason" value={this.state.RelatedPartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.RelatedPartySegmentPartyTypes; vals[i]=e.target.value;this.setState({RelatedPartySegmentPartyTypes:vals},()=>{this.RelatedPartyTypeUpdated(e,i)})}}>
                                                    <option selected>Select Related Party Type</option>
                                                    {mappedOtherPartyTypes}
                                                </select>
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"RelatedPersonOrCompanyLabel"+1}>
                                                Individual or Company?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"RelatedPersonOrCompany"+i} value={this.state.RelatedPartySegmentPorCTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.RelatedPartySegmentPorCTypes; vals[i]=e.target.value;this.setState({RelatedPartySegmentPorCTypes:vals},()=>{this.UpdateRelatedPartySegment()})}}>    
                                                <RadioButton value="Person" name={"RelatedPersonOrCompany"+i} title="Person" checked={this.state.RelatedPartySegmentPorCTypes[i]==='Person'} />                              
                                                <RadioButton value="Company" name={"RelatedPersonOrCompany"+i} title="Company" checked={this.state.RelatedPartySegmentPorCTypes[i]==='Company'} />                              
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"PartyNamePerson"+i} style={{display:this.state.RelatedPartySegmentPorCTypes[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Title
                                            </div>
                                            <div className="inputBox">
                                                <select className="form-control" id={"PartyNamePersonTitle"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentNames[i]['NameTitle']} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'NameTitle')}}>
                                                {this.state.MappedTitles}
                                                </select>
                                            </div>
                                        </div>  
                                        <div className="inputBox LPNameBox" id={"RelatedPartyNamePerson"+i} style={{display:this.state.RelatedPartySegmentPorCTypes[i]==='Person'?'block':this.state.RelatedPartySegmentPorCTypes[i]==='Company'?'none':'block'}}>
                                            <div className="inputLabel">
                                                First Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'RelatedPartyNamePersonGivenName'+i} className={this.state.RelatedPartySegmentNames[i]['GivenName']?"successTextBox": ''} value={this.state.RelatedPartySegmentNames[i]['GivenName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'GivenName')}} autoComplete={false} placeholder="Given Name" />
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"RelatedPartyNamePerson"+i} style={{display:this.state.RelatedPartySegmentPorCTypes[i]==='Person'?'block':this.state.RelatedPartySegmentPorCTypes[i]==='Company'?'none':'block'}}>
                                            <div className="inputLabel">
                                                Middle Name
                                            </div>
                                            <div className="inputBox">
                                                <input type="text" id={'RelatedPartyNamePersonMiddleName'+i} className={this.state.RelatedPartySegmentNames[i]['MiddleName']?"successTextBox": ''} value={this.state.RelatedPartySegmentNames[i]['MiddleName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'MiddleName')}} autoComplete={false} placeholder="Middle Name" />
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"RelatedPartyNamePerson"+i} style={{display:this.state.RelatedPartySegmentPorCTypes[i]==='Person'?'block':this.state.RelatedPartySegmentPorCTypes[i]==='Company'?'none':'block'}}>
                                            <div className="inputLabel">
                                                Surname
                                            </div>
                                            <div className="multiColsIinputBoxnputBox">
                                                <input type="text" id={'RelatedPartyNamePersonSurname'+i} className={this.state.RelatedPartySegmentNames[i]['Surname']?"successTextBox": ''} value={this.state.RelatedPartySegmentNames[i]['Surname']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'Surname')}} autoComplete={false} placeholder="Surname" />
                                            </div>
                                        </div>
                                        <div className="inputBox LPNameBox" id={"RelatedPartyNamePerson"+i} style={{display:this.state.RelatedPartySegmentNames[i]==='Person'?'block':'none'}}>
                                            <div className="inputLabel">
                                                Date Of Birth
                                            </div>
                                            <div className="dateBox">
                                                <input type="date" id={'RelatedPartyNamePersonDOB'+i} className={"form-control"+(this.state.PartySegmentNames[i]['Birthdate']?"successTextBox": '')} value={this.state.PartySegmentNames[i]['Birthdate']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'Birthdate')}} autoComplete={false} placeholder="DD/MM/YY" required/>
                                            </div>
                                            {/* <br/> */}
                                        </div> 

                                        <div id={"PartyNameCompany"+i} style={{display:this.state.RelatedPartySegmentPorCTypes[i]==='Company'?'block':'none'}}>
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Company Name
                                                </div>
                                                <div>
                                                <input type="text" id={'RelatedPartyNamePersonCompanyName'+i}  value={this.state.RelatedPartySegmentNames[i]['CompanyName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'CompanyName')}} autoComplete={false} placeholder="Company Name" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ACN
                                                </div>                                                
                                                <div>
                                                    <input type="text" id={'RelatedPartyNamCompanyACN'+i} value={this.state.RelatedPartySegmentNames[i]['Acn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'Acn')}} autoComplete={false} placeholder="ACN" />
                                                    
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ABN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'RelatedPartyNameCompanyAbn'+i} value={this.state.RelatedPartySegmentNames[i]['Abn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'Abn')}} autoComplete={false} placeholder="ABN" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ARBN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'RelatedPartyNameCompanyArbn'+i} value={this.state.RelatedPartySegmentNames[i]['Arbn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartyNameUpdated(e,i,'Arbn')}} autoComplete={false} placeholder="ARBN" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div id={"PartyNameCompany"+i} style={{display:this.state.RelatedPartySegmentPorCTypes[i]==='Company'?'block':'none'}}>
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Company Name
                                                </div>
                                                <div>
                                                <input type="text" id={'PartyNamePersonCompanyName'+i}  value={this.state.PartySegmentNames[i]['CompanyName']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'CompanyName')}} autoComplete={false} placeholder="Company Name" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ACN
                                                </div>                                                
                                                <div>
                                                    <input type="text" id={'PartyNamCompanyACN'+i} value={this.state.PartySegmentNames[i]['Acn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Acn')}} autoComplete={false} placeholder="ACN" />
                                                    
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ABN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyAbn'+i} value={this.state.PartySegmentNames[i]['Abn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Abn')}} autoComplete={false} placeholder="ABN" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    ARBN
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'PartyNameCompanyArbn'+i} value={this.state.PartySegmentNames[i]['Arbn']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.PartyNameUpdated(e,i,'Arbn')}} autoComplete={false} placeholder="ARBN" />
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className="inputBox" >
                                            <div className="inputLabel">
                                                Phone Number
                                            </div>
                                            <div>                                                    
                                            <input type="number" id={'RelatedPartyPhoneNumber'+i} className="form-control" value={this.state.RelatedPartySegmentPhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentNumberUpdated(e,i)}} autoComplete={false} placeholder="Phone Number"/>
                                            </div>
                                        </div>
                                        <div className="inputBox" >
                                            <div className="inputLabel">
                                                Mobile
                                            </div>
                                            <div>                                                    
                                            <input type="number" id={'RelatedPartyMobileNumber'+i} className="form-control" value={this.state.RelatedPartySegmentMobileNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentMobileUpdated(e,i)}} autoComplete={false} placeholder="Mobile"/>
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Address
                                            </div>
                                            <div className="multiRowsInputBox">
                                                <input type="text" id={'RelatedPartySecurityStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['street']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'street')}} placeholder="Street" autoComplete={false} required/>
                                                
                                                <input type="text" id={'RelatedPartySecurityCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['city']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'city')}} placeholder="City" autoComplete={false} />
                                            
                                                <div className="multiColsInputBox">
                                                    <select className="form-control" id={"StateName"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['state']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'state')}}>
                                                        {this.state.MappedStates}
                                                    </select>
                                                    <input type="text" id={'RelatedPartySecurityPostcode'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" maxLength="4" value={this.state.RelatedPartySegmentAddresses[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.RelatedPartyAddressUpdated(e,i,'postcode')}}} placeholder="Postcode" autoComplete={false} required/>
                                                </div>
                                            </div>
                                        </div>                        */}
                                        
                                    </div>

                                    <div className="inputLabel">Contact Details</div>
                                        <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>                                        
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Email
                                                </div>
                                                <div>
                                                    <input type="email" id={'RelatedPartyEmail'+i} className={(this.state.RelatedPartySegmentEmails[i] && this.state.validEmail==true)?'successTextBox':this.state.emailValidationError?"":''} value={this.state.RelatedPartySegmentEmails[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentEmailUpdated(e,i)}} autoComplete={false} placeholder="example@email.com" required={this.state.SelfActing[0]}/>                                                                                
                                                </div>
                                            </div>
                                    
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Home Phone
                                                </div>
                                                <div>                                                    
                                                    <input type="number" id={'RelatedPartyHomePhoneNumber'+i} className="form-control" value={this.state.RelatedPartySegmentHomePhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentHomePhoneNumberUpdated(e,i)}} autoComplete={false} placeholder="Phone Number"/>
                                                </div>
                                                <br/>
                                                <div>                                                    
                                                    <input type="number" id={'RelatedPartyHomeFaxNumber'+i} className="form-control" value={this.state.RelatedPartySegmentHomeFaxNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentHomeFaxNumberUpdated(e,i)}} autoComplete={false} placeholder="Fax Number"/>
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Mobile
                                                </div>
                                                <div>                                                    
                                                <input type="number" id={'RelatedPartyMobileNumber'+i} className="form-control" value={this.state.RelatedPartySegmentMobileNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentMobileUpdated(e,i)}} autoComplete={false} placeholder="Mobile"/>
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Work Phone
                                                </div>
                                                <div>                                                    
                                                    <input type="number" id={'RelatedPartyWorkPhoneNumber'+i} className="form-control" value={this.state.RelatedPartySegmentWorkPhoneNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentWorkPhoneNumberUpdated(e,i)}} autoComplete={false} placeholder="Phone Number"/>
                                                </div>
                                                <br/>
                                                <div>                                                    
                                                    <input type="number" id={'RelatedPartyWorkFaxNumber'+i} className="form-control" value={this.state.RelatedPartySegmentWorkFaxNumbers[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.RelatedPartySegmentWorkFaxNumberUpdated(e,i)}} autoComplete={false} placeholder="Fax Number"/>
                                                </div>
                                            </div>
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Address
                                                </div>
                                                <div className="multiRowsInputBox">
                                                    <input type="text" id={'RelatedPartyBuilding'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['building']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'building')}} placeholder="Building Name" autoComplete={false}/>
                                                    <div className="multiColsInputBoxContentWidth">    
                                                        <input type="text" id={'RelatedPartyFloor'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['floor']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'floor')}} placeholder="Floor Number" autoComplete={false}/>
                                                        <select className="form-control" id={"PartyFloorType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['floorType']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'floorType')}}>
                                                            {this.state.FloorTypes}
                                                        </select>
                                                    </div>
                                                    <input type="text" id={'RelatedPartyStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['street']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'street')}} placeholder="Street" autoComplete={false} required/>                                                
                                                    <div className="multiColsInputBoxContentWidth">    
                                                        <input type="text" id={'RelatedPartyPOBox'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['POBox']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'POBox')}} placeholder="PO Box Number" autoComplete={false}/>
                                                        <select className="form-control" id={"PartyDeliveryType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['deliveryType']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'deliveryType')}}>
                                                            {this.state.DeliveryTypes}
                                                        </select>
                                                    </div>
                                                    <input type="text" id={'RelatedPartyOverseasLine1'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['overseasline1']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'overseasline1')}} placeholder="Overseas Line 1" autoComplete={false}/>
                                                    <input type="text" id={'RelatedPartyOverseasLine2'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['overseasline2']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'overseasline2')}} placeholder="Overseas Line 2" autoComplete={false}/>
                                                    <input type="text" id={'RelatedPartyOverseasLine3'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['overseasline3']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'overseasline3')}} placeholder="Overseas Line 3" autoComplete={false}/>
                                                    <input type="text" id={'RelatedPartyOverseasLine4'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['overseasline4']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'overseasline4')}} placeholder="Overseas Line 4" autoComplete={false}/>
                                                    
                                                    <input type="text" id={'RelatedPartyCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['city']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'city')}} placeholder="Suburb" autoComplete={false} />
                                                
                                                    <div className="multiColsInputBox">
                                                        <select className="form-control" id={"RelatedPartyStateName"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.RelatedPartySegmentAddresses[i]['state']} onChange={(e)=>{e.preventDefault();this.RelatedPartyAddressUpdated(e,i,'state')}}>
                                                            {this.state.MappedStates}
                                                        </select>
                                                        <input type="text" id={'RelatedPartyPostcode'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" maxLength="4" value={this.state.RelatedPartySegmentAddresses[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.RelatedPartyAddressUpdated(e,i,'postcode')}}} placeholder="Postcode" autoComplete={false} required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="inputLabel">Trust Details</div>
                                        <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Settlor Name
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'TrustSegmentSettlorName'+i} value={this.state.TrustSegmentSettlorNames[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentSettlorNamesUpdated(e,i)}} autoComplete={false} placeholder="Settlor Name" />
                                                </div>
                                            </div>
                                            <div className="inputBox" >
                                                <div className="inputLabel">
                                                    Trust Name
                                                </div>
                                                <div>                                                    
                                                    <input type="text" id={'TrustSegmentTrustName'+i} value={this.state.TrustSegmentTrustNames[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentTrustNamesUpdated(e,i)}} autoComplete={false} placeholder="Trust Name" />
                                                </div>
                                            </div>
                                            <div className="inputBox LPNameBox">
                                                <div className="inputLabel">
                                                    Trust Type
                                                </div>
                                                <div className="inputBox">
                                                    <select className="form-control" id={"TrustSegmentTrustType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.TrustSegmentTrustTypes[i]} onChange={(e)=>{e.preventDefault();this.TrustSegmentTrustTypesUpdated(e,i)}}>
                                                    {this.state.TrustTypes}
                                                    </select>
                                                </div>
                                            </div>  
                                            <div className="inputBox LPNameBox">
                                                <div className="inputLabel">
                                                    Declaration Date
                                                </div>
                                                <div className="dateBox">
                                                    <input type="date" id={'TrustSegmentDeclarationDate'+i} className={"form-control"+(this.state.TrustSegmentDeclarationDates[i]?"successTextBox": '')} value={this.state.TrustSegmentDeclarationDates[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentDeclarationDatesUpdated(e,i)}} autoComplete={false} placeholder="DD/MM/YY"/>
                                                </div>
                                            </div> 
                                            <div className="inputBox LPNameBox">
                                                <div className="inputLabel">
                                                    Establishment Date
                                                </div>
                                                <div className="dateBox">
                                                    <input type="date" id={'TrustSegmentEstablishmentDate'+i} className={"form-control"+(this.state.TrustSegmentEstablishmentDates[i]?"successTextBox": '')} value={this.state.TrustSegmentEstablishmentDates[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentEstablishmentDatesUpdated(e,i)}} autoComplete={false} placeholder="DD/MM/YY"/>
                                                </div>
                                            </div>   
                                            <div className="inputBox LPNameBox">
                                                <div className="inputLabel">
                                                    Vesting Date
                                                </div>
                                                <div className="dateBox">
                                                    <input type="date" id={'TrustSegmentVestingDate'+i} className={"form-control"+(this.state.TrustSegmentVestingDates[i]?"successTextBox": '')} value={this.state.TrustSegmentVestingDates[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentVestingDatesUpdated(e,i)}} autoComplete={false} placeholder="DD/MM/YY"/>
                                                </div>
                                            </div> 
                                            <div className="inputBox LPNameBox">
                                                <div className="inputLabel">
                                                    Appointment Date
                                                </div>
                                                <div className="dateBox">
                                                    <input type="date" id={'TrustSegmentAppointmentDate'+i} className={"form-control"+(this.state.TrustSegmentTrusteeAppointmentDates[i]?"successTextBox": '')} value={this.state.TrustSegmentTrusteeAppointmentDates[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentTrusteeAppointmentDatesUpdated(e,i)}} autoComplete={false} placeholder="DD/MM/YY"/>
                                                </div>
                                            </div> 
                                            <div className="inputBox LPNameBox">
                                                <div className="inputLabel">
                                                    Variation Date
                                                </div>
                                                <div className="dateBox">
                                                    <input type="date" id={'TrustSegmentVariationDate'+i} className={"form-control"+(this.state.TrustSegmentDeedVariationDates[i]?"successTextBox": '')} value={this.state.TrustSegmentDeedVariationDates[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.TrustSegmentDeedVariationDatesUpdated(e,i)}} autoComplete={false} placeholder="DD/MM/YY"/>
                                                </div>
                                            </div> 
                                        </div>     
                                    <br /> 
                                </>));
                               
                        }                        
                        this.setState({RelatedPartySegmentEntities:ents});
            });

           
        }

    }
   
    IsValidPostcode(value){
        let re = /[^0-9]/;
        return !re.test(value);
    }

    SecurityAddressUpdated(e,i,key){
        e.preventDefault();
        let address = this.state.SecuritySegmentAddreses;
        address[i][key]=e.target.value;
        this.setState({SecuritySegmentAddreses:address},()=>{this.UpdateSecuritySegment()});

    }

    SecurityTitlesUpdated(e,i,key){
        //alert(e.target.value);
        e.preventDefault();
        let titles = this.state.SecuritySegmentTitles;
        //titles[i][key]=e.target.value;
        // this.setState({SecuritySegmentTitles:titles},()=>{this.UpdateSecuritySegment()});
        if(titles[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let titlesEntity =  {'TenureType':'','TitleType':'','TorrensExtent':'','TorrensStrata':'','TorrensAutoConsol':'','TorrensBlock':'','TorrensBuildUnitPlan':'','TorrensDistrict':'','TorrensDivision':'',
            'TorrensFolio':'','TorrensFolioID':'','TorrensLocation':'','TorrensLot':'','TorrensParcel':'','TorrensPlan':'','TorrensPlanType':'','TorrensSection':'','TorrensTitle':'','TorrensTitleRef':'',
            'TorrensUnit':'','TorrensVolume':'','TorrensVolumeFolio':'','CompanyACN':'','CompanyName':'','CompanyShareCertNumb':'','CompanySharesFrom':'','CompanySharesTo':'','CompanySharesTotal':''};
            
            titles.push(titlesEntity);
        }
        titles[i][key]=e.target.value;

        this.setState({SecuritySegmentTitles:titles},()=>{this.UpdateSecuritySegment()});
    }

    SecuritySegmentLeaseStartDateUpdated(e,i){
        e.preventDefault();
        let startDate = this.state.SecuritySegmentLeaseStartDates;
        startDate[i]=e.target.value;
        this.setState({SecuritySegmentLeaseStartDates:startDate},()=>{this.UpdateSecuritySegment()});
    }

    SecuritySegmentLeaseEndDateUpdated(e,i){
        e.preventDefault();
        let endDate = this.state.SecuritySegmentLeaseEndDates;
        endDate[i]=e.target.value;
        this.setState({SecuritySegmentLeaseEndDates:endDate},()=>{this.UpdateSecuritySegment()});
    }

    SecuritySegmentEncumbrancesUpdated(e,i,key){
        //alert(e.target.value);
        e.preventDefault();
        let encumbrances = this.state.SecuritySegmentEncumbrances;
        //titles[i][key]=e.target.value;
        // this.setState({SecuritySegmentTitles:titles},()=>{this.UpdateSecuritySegment()});
        if(encumbrances[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let encumbrancesEntity =  {'Description':'','EncumbranceType':'','RegisteredNumber':'','DateDay':'','DateMonth':'','DateYear':'','EncumbranceRelatedID':'','CreditProviderRelatedID':''};
            
            encumbrances.push(encumbrancesEntity);
        }
        encumbrances[i][key]=e.target.value;

        this.setState({SecuritySegmentEncumbrances:encumbrances},()=>{this.UpdateSecuritySegment()});
    }
    SecuritySegmentFinanceTypesUpdated(e,i){
        e.preventDefault();
        let financeTypes = this.state.SecuritySegmentFinanceTypes;
        financeTypes[i]=e.target.value;
        this.setState({SecuritySegmentFinanceTypes:financeTypes},()=>{this.UpdateSecuritySegment()});
    }
    // SecuritySegmentContractPricesUpdated(e,i){
    //     e.preventDefault();
    //     let contractPrices = this.state.SecuritySegmentContractPriceAmount;
    //     contractPrices[i]=e.target.value;
    //     this.setState({SecuritySegmentContractPriceAmount:contractPrices},()=>{this.UpdateSecuritySegment()});
    // }
    SecuritySegmentContractPricesUpdated(e,i,key){
        //alert(e.target.value);
        e.preventDefault();
        let contractPrices = this.state.SecuritySegmentContractPrices;
        //titles[i][key]=e.target.value;
        // this.setState({SecuritySegmentTitles:titles},()=>{this.UpdateSecuritySegment()});
        if(contractPrices[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let contractPricesEntity =  {'ContractPriceAmount':'','TransferOfLandAmount':''};
            
            contractPrices.push(contractPricesEntity);
        }
        contractPrices[i][key]=e.target.value;

        this.setState({SecuritySegmentContractPrices:contractPrices},()=>{this.UpdateSecuritySegment()});
    }
    SecuritySegmentBuildersContractAmountUpdated(e,i){
        e.preventDefault();
        let buildersContractAmount = this.state.SecuritySegmentBuildersContractAmount;
        buildersContractAmount[i]=e.target.value;
        this.setState({SecuritySegmentBuildersContractAmount:buildersContractAmount},()=>{this.UpdateSecuritySegment()});
    }

    FinancialSegmentNonRealEstatesUpdated(e,i,key){
        e.preventDefault();
        let nonRealEstates = this.state.FinancialSegmentNonRealEstates;
        //titles[i][key]=e.target.value;
        // this.setState({SecuritySegmentTitles:titles},()=>{this.UpdateSecuritySegment()});
        if(nonRealEstates[i]===undefined){
            //let addressEntity = {'state':'','city':null,'number':null,'postcode':null};
            let nonRealEstatesEntity =  {'PrimarySecurity':'','Transaction':''};
            
            nonRealEstates.push(nonRealEstatesEntity);
        }
        nonRealEstates[i][key]=e.target.value;

        this.setState({FinancialSegmentNonRealEstates:nonRealEstates},()=>{this.UpdateSecuritySegment()});
    }

    FinancialSegmentNonRealEstateDepositAccountsUpdated(e,i,key){
        e.preventDefault();
        let nonRealEstateDepositAccounts = this.state.FinancialSegmentNonRealEstateDepositAccounts;
        if(nonRealEstateDepositAccounts[i]===undefined){
            let nonRealEstateDepositAccountsEntity =  {'DepositAccountType':'','Description':'','AccountName':'','AcctNbr':'','BSB':'',};
            
            nonRealEstateDepositAccounts.push(nonRealEstateDepositAccountsEntity);
        }
        nonRealEstateDepositAccounts[i][key]=e.target.value;

        this.setState({FinancialSegmentNonRealEstateDepositAccounts:nonRealEstateDepositAccounts},()=>{this.UpdateSecuritySegment()});
    }

    FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,key){
        e.preventDefault();
        let nonRealEstateOtherAssets = this.state.FinancialSegmentNonRealEstateOtherAssets;
        if(nonRealEstateOtherAssets[i]===undefined){
            let nonRealEstateOtherAssetsEntity =  {'OtherAssetType':'','OtherAssetDescription':'','ShareType':'','MaturityDate':'','VehicleType':'','Make':'','Year':''};
            
            nonRealEstateOtherAssets.push(nonRealEstateOtherAssetsEntity);
        }
        nonRealEstateOtherAssets[i][key]=e.target.value;

        this.setState({FinancialSegmentNonRealEstateOtherAssets:nonRealEstateOtherAssets},()=>{this.UpdateSecuritySegment()});
    }

    FinancialSegmentMortgagesUpdated(e,i,key){
        e.preventDefault();
        let mortgages = this.state.FinancialSegmentMortgages;
        if(mortgages[i]===undefined){
            let mortgagesEntity =  {'NewMortgage':'','AdditionalLoanAgainstMortgage':'','DeedOfPriority':'','MortgageDate':'','MortgageNumb':'','Priority':'',
            'PriorityAmount':'','MortgageNumber':'','MortgageeName':'','MortgagorName':''};
            
            mortgages.push(mortgagesEntity);
        }
        mortgages[i][key]=e.target.value;

        this.setState({FinancialSegmentMortgages:mortgages},()=>{this.UpdateSecuritySegment()});
    }

    FinancialSegmentAssetInsuranceUpdated(e,i,key){
        e.preventDefault();
        let assetInsurance = this.state.FinancialSegmentAssetInsurance;
        if(assetInsurance[i]===undefined){
            let assetInsuranceEntity =  {'Category':'','AssetInsuranceType':'','InsuredAmount':'','PremiumAmount':'','PolicyNumber':'','InsuranceEffectiveDate':'',
            'InsuranceExpiryDate':''};
            
            assetInsurance.push(assetInsuranceEntity);
        }
        assetInsurance[i][key]=e.target.value;

        this.setState({FinancialSegmentAssetInsurance:assetInsurance},()=>{this.UpdateSecuritySegment()});
    }

    FinancialSegmentValuationUpdated(e,i,key){
        e.preventDefault();
        let valuation = this.state.FinancialSegmentValuation;
        if(valuation[i]===undefined){
            let valuationEntity =  {'TotalValue':'','ReplacementInsuranceFull':'','ReplacementInsuranceAmount':'','Date':'','ValuerRelatedID':''};
            
            valuation.push(valuationEntity);
        }
        valuation[i][key]=e.target.value;

        this.setState({FinancialSegmentValuation:valuation},()=>{this.UpdateSecuritySegment()});
    }

    // SecurityIsPrimarySecurityUpdated = (event, i) => {
    //     let states = this.state.reststates
    //     const { staterestrictions } = this.state;
    //     let newArr = [];
    //     var index = event.target.getAttribute('id')
    //     states.forEach(stat => {
    //        if (stat.value === event.target.value){             
    //         stat.isChecked =  event.target.checked
    //           if(stat.isChecked){
    //             newArr = [...staterestrictions, stat.id];
    //           }          
    //           else {
    //             newArr =  staterestrictions.filter(item => item != stat.id);
    //           }
    //        }  
    //     }) 
    //     this.setState(
    //         {staterestrictions: newArr,
    //             //checkedAll: false
    //             checkedAll: states.length == newArr.length?true:false,
    //             hasstaterestriction: newArr.length > 0 ? true:false,
    //         }
    //         , ()=>{console.log('updated state', newArr, 'index', index)}
    //     )
    //   } 

    SecurityIsPrimarySecurityUpdated(e,i){
        e.preventDefault();
        alert(e.target.checked)
        alert(e.target.value);
        //return;

        let isPrimarySecurities = this.state.SecuritySegmentPrimarySecurities;
        isPrimarySecurities[i]=e.target.checked;
        //alert("dsfgdfg");
        this.setState(initialState=>
            ({
                SecuritySegmentPrimarySecurities: isPrimarySecurities
            }),()=>{
                this.UpdateSecuritySegment();
            console.log("isPrimary");
            console.log(this.state.SecuritySegmentPrimarySecurities)
                //alert(this.state.emailasusername)
                // if(this.state.emailasusername){
                //     this.setState({
                //         username: this.state.email
                //     })
                // }
                // else {
                //     this.setState({
                //         username: ''
                //     })
                // }
            });
        // let isPrimarySecurities = this.state.SecuritySegmentPrimarySecurities;
        // isPrimarySecurities[i]=e.target.value;
        // this.setState({SecurityTypes:SecuritySegmentPrimarySecurities},()=>{
        //     this.UpdateSecuritySegment();
        //     console.log("isPrimary");
        //     console.log(this.state.SecurityTypes)
        // });
    }

    SecurityReasonUpdated(e,i){
        e.preventDefault();
        let dischargeReason = this.state.SecurityTypes;
        dischargeReason[i]=e.target.value;
        this.setState({SecurityTypes:dischargeReason},()=>{this.UpdateSecuritySegment()});
    }

    SecuritySettlementActionUpdated(e,i){
        e.preventDefault();
        let settlementAction = this.state.SecuritySegmentSettlementActions;
        settlementAction[i]=e.target.value;
        this.setState({SecuritySegmentSettlementActions:settlementAction},()=>{this.UpdateSecuritySegment()});
    }

    SecurityTitleReferenceUpdated(e,i){
        e.preventDefault();
        let titleReferences = this.state.SecuritySegmentTitleReferences;
        titleReferences[i]=e.target.value;
        this.setState({SecuritySegmentTitleReferences:titleReferences},()=>{this.UpdateSecuritySegment()});
    }

    SecurityResidentialTypesUpdated(e,i){
        e.preventDefault();
        let residentialTypes = this.state.SecuritySegmentResidentialTypes;
        residentialTypes[i]=e.target.value;
        this.setState({SecuritySegmentResidentialTypes:residentialTypes},()=>{this.UpdateSecuritySegment()});
    }

    SecurityCommercialTypesUpdated(e,i){
        e.preventDefault();
        let commericalTypes = this.state.SecurityCommercialTypes;
        commericalTypes[i]=e.target.value;
        this.setState({SecurityCommercialTypes:commericalTypes},()=>{this.UpdateSecuritySegment()});
    }

    SecurityPrimaryPurposeUpdated(e,i){
        e.preventDefault();
        let primaryPurposes = this.state.SecuritySegmentPropertyPrimaryPurposes;
        primaryPurposes[i]=e.target.value;
        this.setState({SecuritySegmentPropertyPrimaryPurposes:primaryPurposes},()=>{this.UpdateSecuritySegment()});
    }

    SecurityTransactionsUpdated(e,i){
        e.preventDefault();
        let transactions = this.state.SecuritySegmentTransactions;
        transactions[i]=e.target.value;
        this.setState({SecuritySegmentTransactions:transactions},()=>{this.UpdateSecuritySegment()});
    }

    GoBackClearForm(i){
        
    }

    UpdateSecuritySegment(){
        let ents = this.state.SecuritySegmentEntities;
        var dischargeReasonTypeOptions=[];
        // dischargeReasonTypeOptions.push(<option>Select reasoning</option>)
        for(let x=0;x<this.state.subApplicationTypeOptions.length;x++){
            //dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x].replace("DebtRepaid", "Debt Repaid").replace("Refinance","External Refinance").replace("InternalRefinance", "Internal Refinance")}</option>)
            dischargeReasonTypeOptions.push(<option>{this.state.subApplicationTypeOptions[x]}</option>)
        }
        let mappedDischargeReasonOptions = dischargeReasonTypeOptions.length?dischargeReasonTypeOptions.reduce((p,c)=>[p,'',c]):'';
        if(ents.length>this.state.SecuritySegmentCount){
            //alert('a')
            var names = this.state.PartyRepNames;
        var emails = this.state.PartyRepEmails;
        var numbers = this.state.PartyRepNumbers;
        let mortNames=this.state.IncomingMortgageeNames;
        let mortEmails = this.state.IncomingMortgageeEmails;
        let mortNumbers = this.state.IncomingMortgageeNumbers;
        let types = this.state.IncomingMortgageeType;
        let selfActingChecks = this.state.SelfActing;
        let isPrimarySecurities  = this.state.SecuritySegmentPrimarySecurities;
        let settlementActions = this.state.SecuritySegmentSettlementActions;
        let constructions = this.state.SecuritySegmentConstructions;
        let securitySegmentResidentialOrCommercialTypes = this.state.SecuritySegmentResidentialOrCommercialTypes;
        let securityResidentialTypes = this.state.SecuritySegmentResidentialTypes;
        let securityCommercialTypes = this.state.SecuritySegmentCommercialTypes;
        let residentialOffthePlan = this.state.SecuritySegmentResidentialOffThePlans;
        let primaryPurposes = this.state.SecuritySegmentPropertyPrimaryPurposes;
        let transactions = this.state.SecuritySegmentTransactions;
        let securityHeld = this.state.SecuritySegmentSecurityHeld;
        let isTrustAsset = this.state.SecuritySegmentTrustAssets;
        let leaseStartDates = this.state.SecuritySegmentLeaseStartDates;
        let leaseEndDates = this.state.SecuritySegmentLeaseEndDates;
        let encumbrances = this.state.SecuritySegmentEncumbrances;
        let financeTypes = this.state.SecuritySegmentFinanceTypes;
        let contractPrices = this.state.SecuritySegmentContractPrices;
        let buildersContractAmount = this.state.SecuritySegmentBuildersContractAmount;
        let nonRealEstates = this.state.FinancialSegmentNonRealEstates;
        let nonRealEstateDepositAccounts = this.state.FinancialSegmentNonRealEstateDepositAccounts;
        let nonRealEstateOtherAssets = this.state.FinancialSegmentNonRealEstateOtherAssets;
        let mortgages = this.state.FinancialSegmentMortgages;
        let assetInsurance = this.state.FinancialSegmentAssetInsurance;
        let valuation = this.state.FinancialSegmentValuation;
        
        let partyRepNames = this.state.PartyRepNames;
        let partyRepEmails=this.state.PartyRepEmails;
        let partyRepNumbers=this.state.PartyRepNumbers;
            let partyAddresses = this.state.SecuritySegmentAddreses;
            let securityTitles = this.state.SecuritySegmentTitles;
            let titleRefs = this.state.SecuritySegmentTitleReferences;
            for(let i = this.state.SecuritySegmentEntities.length; i>this.state.SecuritySegmentCount && i>this.state.SecuritySegmentMinCount ; i--){
                ents.pop();
                selfActingChecks.pop();
                partyAddresses.pop();
                mortNames.pop();
                mortEmails.pop();
                mortNumbers.pop();
                types.pop();
                names.pop();
                emails.pop();
                numbers.pop();
                titleRefs.pop();
                partyRepNames.pop();
                partyRepEmails.pop();
                partyRepNumbers.pop();
                isPrimarySecurities.pop();
                settlementActions.pop();
                securitySegmentResidentialOrCommercialTypes.pop();
                securityResidentialTypes.pop();
                securityCommercialTypes.pop();
                residentialOffthePlan.pop();
                securityTitles.pop();
                primaryPurposes.pop();
                transactions.pop();
                constructions.pop();
                securityHeld.pop();
                isTrustAsset.pop();
                leaseStartDates.pop();
                leaseEndDates.pop();
                encumbrances.pop();
                financeTypes.pop();
                contractPrices.pop();
                buildersContractAmount.pop();
                nonRealEstates.pop();
                nonRealEstateDepositAccounts.pop();
                nonRealEstateOtherAssets.pop();
                mortgages.pop();
                assetInsurance.pop();
                valuation.pop();
            }
            this.setState({SecuritySegmentAddreses:partyAddresses,PartyRepNames:names,PartyRepEmails:emails,PartyRepNumbers:numbers,SecuritySegmentTitleReferences:titleRefs,SelfActing:selfActingChecks,
                PartyRepNames:partyRepNames, PartyRepEmails:partyRepEmails, PartyRepNumbers:partyRepNumbers, SecuritySegmentPrimarySecurities:isPrimarySecurities,
                SecuritySegmentSettlementActions: settlementActions,
                SecuritySegmentConstructions: constructions,
                SecuritySegmentSecurityHeld: securityHeld,
                SecuritySegmentTrustAssets: isTrustAsset,
                SecuritySegmentResidentialOrCommercialTypes: securitySegmentResidentialOrCommercialTypes,
                SecuritySegmentResidentialTypes: securityResidentialTypes,
                SecuritySegmentCommercialTypes: securityCommercialTypes,
                SecuritySegmentResidentialOffThePlans: residentialOffthePlan,
                SecuritySegmentTitles: securityTitles,
                SecuritySegmentPropertyPrimaryPurposes: primaryPurposes,
                SecuritySegmentTransactions: transactions,
                SecuritySegmentLeaseStartDates: leaseStartDates,
                SecuritySegmentLeaseEndDates: leaseEndDates,
                SecuritySegmentEncumbrances: encumbrances,
                SecuritySegmentFinanceTypes: financeTypes,
                SecuritySegmentContractPrices: contractPrices,
                SecuritySegmentBuildersContractAmount: buildersContractAmount,
                FinancialSegmentNonRealEstates: nonRealEstates,
                FinancialSegmentNonRealEstateDepositAccounts: nonRealEstateDepositAccounts,
                FinancialSegmentNonRealEstateOtherAssets: nonRealEstateOtherAssets,
                FinancialSegmentMortgages: mortgages,
                FinancialSegmentAssetInsurance: assetInsurance,
                FinancialSegmentValuation: valuation,
            });
        }else{
            //alert('b');
            var names = this.state.PartyRepNames;
            var emails = this.state.PartyRepEmails;
            var numbers = this.state.PartyRepNumbers;
            let mortNames=this.state.IncomingMortgageeNames;
            let mortEmails = this.state.IncomingMortgageeEmails;
            let mortNumbers = this.state.IncomingMortgageeNumbers;
            let types = this.state.IncomingMortgageeType;
            let selfActingChecks = this.state.SelfActing;
            var dischargeReasonTypeOptions=[];
            let partyRepNames = this.state.PartyRepNames;
            let partyRepEmails=this.state.PartyRepEmails;
            let partyRepNumbers=this.state.PartyRepNumbers;
            let isPrimarySecurities  = this.state.SecuritySegmentPrimarySecurities;
            let partyAdd = this.state.SecuritySegmentAddreses;
            let tRefs = this.state.SecuritySegmentTitleReferences;
            let dischargeReason = this.state.SecurityTypes;
            let settlementActions = this.state.SecuritySegmentSettlementActions;
            let constructions = this.state.SecuritySegmentConstructions;
            let securityHeld = this.state.SecuritySegmentSecurityHeld;
            let isTrustAsset = this.state.SecuritySegmentTrustAssets;
            let securitySegmentResidentialOrCommercialTypes = this.state.SecuritySegmentResidentialOrCommercialTypes;
            let securityResidentialTypes = this.state.SecuritySegmentResidentialTypes;
            let securityCommercialTypes = this.state.SecuritySegmentCommercialTypes;
            let residentialOffthePlan = this.state.SecuritySegmentResidentialOffThePlans;
            let securityTitles = this.state.SecuritySegmentTitles;
            let primaryPurposes = this.state.SecuritySegmentPropertyPrimaryPurposes;
            let transactions = this.state.SecuritySegmentTransactions;
            let leaseStartDates = this.state.SecuritySegmentLeaseStartDates;
            let leaseEndDates = this.state.SecuritySegmentLeaseEndDates;
            let encumbrances = this.state.SecuritySegmentEncumbrances;
            let financeTypes = this.state.SecuritySegmentFinanceTypes;
            let contractPrices = this.state.SecuritySegmentContractPrices;
            let buildersContractAmount = this.state.SecuritySegmentBuildersContractAmount;
            let nonRealEstates = this.state.FinancialSegmentNonRealEstates;
            let nonRealEstateDepositAccounts = this.state.FinancialSegmentNonRealEstateDepositAccounts;
            let nonRealEstateOtherAssets = this.state.FinancialSegmentNonRealEstateOtherAssets;
            let mortgages = this.state.FinancialSegmentMortgages;
            let assetInsurance = this.state.FinancialSegmentAssetInsurance;
            let valuation = this.state.FinancialSegmentValuation;

            for(let i = 0; i<this.state.SecuritySegmentCount; i++){
                //let addressEntity = {'state':'','city':'','number':'','postcode':'','street':''};
                let addressEntity = {'nonStdAddress':'','overseasline1':'','overseasline2':'','overseasline3':'','overseasline4':'','city':'','state':'','overseasstate':'','postcode':'','countrycode':''};
                let titlesEntity = {'TenureType':'','TitleType':'','TorrensExtent':'','TorrensStrata':'','TorrensAutoConsol':'','TorrensBlock':'','TorrensBuildUnitPlan':'','TorrensDistrict':'','TorrensDivision':'',
                'TorrensFolio':'','TorrensFolioID':'','TorrensLocation':'','TorrensLot':'','TorrensParcel':'','TorrensPlan':'','TorrensPlanType':'','TorrensSection':'','TorrensTitle':'','TorrensTitleRef':'',
                'TorrensUnit':'','TorrensVolume':'','TorrensVolumeFolio':'','CompanyACN':'','CompanyName':'','CompanyShareCertNumb':'','CompanySharesFrom':'','CompanySharesTo':'','CompanySharesTotal':''};
                let encumbrancesEntity =  {'Description':'','EncumbranceType':'','RegisteredNumber':'','DateDay':'','DateMonth':'','DateYear':'','EncumbranceRelatedID':'','CreditProviderRelatedID':''};
                let contractPricesEntity =  {'ContractPriceAmount':'','TransferOfLandAmount':''};
                let nonRealEstatesEntity =  {'PrimarySecurity':'','Transaction':''};            
                let nonRealEstateDepositAccountsEntity =  {'DepositAccountType':'','Description':'','AccountName':'','AcctNbr':'','BSB':'',};
                let nonRealEstateOtherAssetsEntity =  {'OtherAssetType':'','OtherAssetDescription':'','ShareType':'','MaturityDate':'','VehicleType':'','Make':'','Year':''};
                let mortgagesEntity =  {'NewMortgage':'','AdditionalLoanAgainstMortgage':'','DeedOfPriority':'','MortgageDate':'','MortgageNumb':'','Priority':'',
                                'PriorityAmount':'','MortgageNumber':'','MortgageeName':'','MortgagorName':''};
                let assetInsuranceEntity =  {'Category':'','AssetInsuranceType':'','InsuredAmount':'','PremiumAmount':'','PolicyNumber':'','InsuranceEffectiveDate':'',
                'InsuranceExpiryDate':''};
                let valuationEntity =  {'TotalValue':'','ReplacementInsuranceFull':'','ReplacementInsuranceAmount':'','Date':'','ValuerRelatedID':''};

                if(partyAdd.length<this.state.SecuritySegmentCount){
                    partyAdd.push(addressEntity);
                    securityTitles.push(titlesEntity);
                    tRefs.push('');
                    //selfActingChecks.push(null);
                    names.push('');
                    emails.push('');
                    numbers.push('');
                    mortNames.push('');
                    mortEmails.push('');
                    mortNumbers.push('');
                    partyRepNames.push('');
                    partyRepEmails.push('');
                    partyRepNumbers.push('');
                    dischargeReason.push('');
                    types.push(0);
                    selfActingChecks.push(false);
                    isPrimarySecurities.push('Yes');
                    settlementActions.push('');
                    securitySegmentResidentialOrCommercialTypes.push('');
                    securityResidentialTypes.push('');
                    securityCommercialTypes.push('');
                    residentialOffthePlan.push('');
                    primaryPurposes.push('');
                    transactions.push('');
                    constructions.push('Yes');
                    securityHeld.push('Yes');
                    isTrustAsset.push('Yes');
                    leaseStartDates.push();
                    leaseEndDates.push();
                    encumbrances.push(encumbrancesEntity);
                    financeTypes.push();
                    contractPrices.push(contractPricesEntity);
                    buildersContractAmount.push();
                    nonRealEstates.push(nonRealEstatesEntity);
                    nonRealEstateDepositAccounts.push(nonRealEstateDepositAccountsEntity);
                    nonRealEstateOtherAssets.push(nonRealEstateOtherAssetsEntity);
                    mortgages.push(mortgagesEntity);
                    assetInsurance.push(assetInsuranceEntity);
                    valuation.push(valuationEntity);
                }
            }
                this.setState({SecuritySegmentAddreses:partyAdd,PartyRepNames:names,PartyRepEmails:emails,PartyRepNumbers:numbers,SecuritySegmentTitleReferences:tRefs,SelfActing:selfActingChecks,
                    PartyRepNames:partyRepNames, PartyRepEmails:partyRepEmails, PartyRepNumbers:partyRepNumbers, SecurityTypes:dischargeReason, SecuritySegmentPrimarySecurities:isPrimarySecurities,
                    SecuritySegmentSettlementActions: settlementActions,
                    SecuritySegmentConstructions: constructions,
                    SecuritySegmentSecurityHeld: securityHeld,
                    SecuritySegmentTrustAssets: isTrustAsset,
                    SecuritySegmentResidentialOrCommercialTypes: securitySegmentResidentialOrCommercialTypes,
                    SecuritySegmentResidentialTypes: securityResidentialTypes,
                    SecuritySegmentCommercialTypes: securityCommercialTypes,
                    SecuritySegmentResidentialOffThePlans: residentialOffthePlan,
                    SecuritySegmentTitles: securityTitles,
                    SecuritySegmentPropertyPrimaryPurposes: primaryPurposes,
                    SecuritySegmentTransactions: transactions,
                    SecuritySegmentLeaseStartDates: leaseStartDates,
                    SecuritySegmentLeaseEndDates: leaseEndDates,
                    SecuritySegmentEncumbrances: encumbrances,
                    SecuritySegmentFinanceTypes: financeTypes,
                    SecuritySegmentContractPrices: contractPrices,
                    SecuritySegmentBuildersContractAmount: buildersContractAmount,
                    FinancialSegmentNonRealEstates: nonRealEstates,
                    FinancialSegmentNonRealEstateDepositAccounts: nonRealEstateDepositAccounts,
                    FinancialSegmentNonRealEstateOtherAssets: nonRealEstateOtherAssets,
                    FinancialSegmentMortgages: mortgages,
                    FinancialSegmentAssetInsurance: assetInsurance,
                    FinancialSegmentValuation: valuation,
                    },()=>{
                    for(let i = 0; i<this.state.SecuritySegmentCount; i++){
                ents[i]=((
                <div>
                    <h5 className="listContentTitle">Security {i+1}</h5>
                    {/* <div className="inputBox"> */}
                        {/* <CheckBox id={"SecurityIsPrimarySecurity" + i} handleCheckChildElement={(e)=>{let vals = this.state.SecuritySegmentPrimarySecurities; vals[i]=e.target.value;this.setState({SecuritySegmentPrimarySecurities:vals},()=>{this.SecurityIsPrimarySecurityUpdated(e,i)})}} isChecked={this.state.SecuritySegmentPrimarySecurities[i]} /> Primary Security? */}
                        {/* <div className="multiColsInputBoxContentWidth" id={"PartyPrimaryApplicant"+i} value={this.state.PartySegmentPrimaryApplicant[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPrimaryApplicant; vals[i]=e.target.value;this.setState({PartySegmentPrimaryApplicant:vals},()=>{this.UpdatePartySegment()})}}>     */}
                    {/* </div> */}
                    <div className="inputBox">
                        <div className="inputLabel">
                            Asset Class
                        </div>
                        <div>
                            <select className="form-control" id={"SecurityAssetClass"+i} value={this.state.SecurityTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecurityTypes; vals[i]=e.target.value;this.setState({SecurityTypes:vals},()=>{this.SecurityReasonUpdated(e,i)})}}>
                                <option selected>Select Asset Type</option>
                                {mappedDischargeReasonOptions}
                            </select>
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Settlement Action
                        </div>
                        <div>
                            <select className="form-control" id={"SecuritySettlemetActionList"+i} value={this.state.SecuritySegmentSettlementActions[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentSettlementActions; vals[i]=e.target.value;this.setState({SecuritySegmentSettlementActions:vals},()=>{this.SecuritySettlementActionUpdated(e,i)})}}>
                                {/* <option selected>Select Asset Type</option> */}
                                {this.state.SettlementActionLists}
                            </select>
                        </div>
                    </div>  
                    {/* Real Estate Start */}
                    <div className="inputBox">
                        <div className="inputLabel" id={"PartyTypeLabel"+1}>
                            Construction?
                        </div>
                        <div className="multiColsInputBoxContentWidth" id={"SecuritySegmentIsConstruction"+i} value={this.state.SecuritySegmentConstructions[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentConstructions; vals[i]=e.target.value;this.setState({SecuritySegmentConstructions:vals},()=>{this.UpdateSecuritySegment()})}}>    
                            <RadioButton value="Yes" name={"SecuritySegmentIsConstruction"+i} title="Yes" checked={this.state.SecuritySegmentConstructions[i]==='Yes'} />                              
                            <RadioButton value="No" name={"SecuritySegmentIsConstruction"+i} title="No" checked={this.state.SecuritySegmentConstructions[i]==='No'} />                                           
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel" id={"PartyTypeLabel"+1}>
                            Is Trust Asset?
                        </div>
                        <div className="multiColsInputBoxContentWidth" id={"SecuritySegmentTrustAssets"+i} value={this.state.SecuritySegmentTrustAssets[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentTrustAssets; vals[i]=e.target.value;this.setState({SecuritySegmentTrustAssets:vals},()=>{this.UpdateSecuritySegment()})}}>    
                            <RadioButton value="Yes" name={"SecuritySegmentTrustAsset"+i} title="Yes" checked={this.state.SecuritySegmentTrustAssets[i]==='Yes'} />                              
                            <RadioButton value="No" name={"SecuritySegmentTrustAsset"+i} title="No" checked={this.state.SecuritySegmentTrustAssets[i]==='No'} />                                           
                        </div>
                    </div>  
                    <div className="inputBox">
                        <div className="inputLabel" id={"PartyTypeLabel"+1}>
                            Primary Security?
                        </div>
                        <div className="multiColsInputBoxContentWidth" id={"SecuritySegmentPrimary"+i} value={this.state.SecuritySegmentPrimarySecurities[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentPrimarySecurities; vals[i]=e.target.value;this.setState({SecuritySegmentPrimarySecurities:vals},()=>{this.UpdateSecuritySegment()})}}>    
                            <RadioButton value="Yes" name={"SecuritySegmentPrimary"+i} title="Yes" checked={this.state.SecuritySegmentPrimarySecurities[i]==='Yes'} />                              
                            <RadioButton value="No" name={"SecuritySegmentPrimary"+i} title="No" checked={this.state.SecuritySegmentPrimarySecurities[i]==='No'} />                                           
                        </div>
                    </div>                    
                    <div className="inputBox">
                        <div className="inputLabel">
                            Property Primary Purpose
                        </div>
                        <div>
                            <select className="form-control" id={"SecurityAssetClass"+i} value={this.state.SecuritySegmentPropertyPrimaryPurposes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentPropertyPrimaryPurposes; vals[i]=e.target.value;this.setState({SecuritySegmentPropertyPrimaryPurposes:vals},()=>{this.SecurityPrimaryPurposeUpdated(e,i)})}}>
                                {/* <option selected>Select Primary Purpose</option> */}
                                {this.state.PropertyPurposeList}
                            </select>
                        </div>
                    </div>                        
                    <div className="inputBox">
                        <div className="inputLabel" id={"PartyTypeLabel"+1}>
                            Security Already Held?
                        </div>
                        <div className="multiColsInputBoxContentWidth" id={"SecuritySegmentSecurityHeld"+i} value={this.state.SecuritySegmentSecurityHeld[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentSecurityHeld; vals[i]=e.target.value;this.setState({SecuritySegmentSecurityHeld:vals},()=>{this.UpdateSecuritySegment()})}}>    
                            <RadioButton value="Yes" name={"SecuritySegmentSecurityHeld"+i} title="Yes" checked={this.state.SecuritySegmentSecurityHeld[i]==='Yes'} />                              
                            <RadioButton value="No" name={"SecuritySegmentSecurityHeld"+i} title="No" checked={this.state.SecuritySegmentSecurityHeld[i]==='No'} />                                           
                        </div>
                    </div> 
                    
                    <div className="inputBox">
                        <div className="inputLabel">
                            Transaction
                        </div>
                        <div>
                            <select className="form-control" id={"SecurityAssetClass"+i} value={this.state.SecuritySegmentTransactions[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentTransactions; vals[i]=e.target.value;this.setState({SecuritySegmentTransactions:vals},()=>{this.SecurityTransactionsUpdated(e,i)})}}>
                                {/* <option selected>Select Transaction</option> */}
                                {this.state.PropertyTransactionList}
                            </select>
                        </div>
                    </div>   
                    <div className="inputBox">
                        <div className="inputLabel" id={"PartyTypeLabel"+1}>
                            Residential or Commercial?
                        </div>
                        <div className="multiColsInputBoxContentWidth" id={"SecurityResidentialOrCommercial"+i} value={this.state.SecuritySegmentResidentialOrCommercialTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentResidentialOrCommercialTypes; vals[i]=e.target.value;this.setState({SecuritySegmentResidentialOrCommercialTypes:vals},()=>{this.UpdateSecuritySegment()})}}>    
                            <RadioButton value="Residential" name={"SecurityResidentialOrCommercial"+i} title="Residential" checked={this.state.SecuritySegmentResidentialOrCommercialTypes[i]==='Residential'} />                              
                            <RadioButton value="Commercial" name={"SecurityResidentialOrCommercial"+i} title="Commercial" checked={this.state.SecuritySegmentResidentialOrCommercialTypes[i]==='Commercial'} />                              
                        </div>
                    </div>
                    <div className="inputBox" style={{display:this.state.SecuritySegmentResidentialOrCommercialTypes[i]==='Residential'?'block':'none'}}>
                        <div className="inputLabel">
                            Off the Plan?
                        </div>
                        <div className="multiColsInputBoxContentWidth" id={"SecuritySegmentResidentialOffThePlans"+i} value={this.state.SecuritySegmentResidentialOffThePlans[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentResidentialOffThePlans; vals[i]=e.target.value;this.setState({SecuritySegmentResidentialOffThePlans:vals},()=>{this.UpdateSecuritySegment()})}}>    
                            <RadioButton value="Yes" name={"SecuritySegmentResidentialOffThePlans"+i} title="Yes" checked={this.state.SecuritySegmentResidentialOffThePlans[i]==='Yes'} />                              
                            <RadioButton value="No" name={"SecuritySegmentResidentialOffThePlans"+i} title="No" checked={this.state.SecuritySegmentResidentialOffThePlans[i]==='No'} />                                           
                        </div>
                    </div>
                    <div className="inputBox" style={{display:this.state.SecuritySegmentResidentialOrCommercialTypes[i]==='Residential'?'block':'none'}}>
                        <div className="inputLabel">
                            Residential Type
                        </div>
                        <div>
                            <select className="form-control" id={"SecurityAssetClass"+i} value={this.state.SecuritySegmentResidentialTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecurityTypes; vals[i]=e.target.value;this.setState({SecuritySegmentResidentialTypes:vals},()=>{this.SecurityResidentialTypesUpdated(e,i)})}}>
                                <option selected>Select Residential Type</option>
                                {this.state.PropertyResidentialTypeList}
                            </select>
                        </div>
                    </div> 
                    <div className="inputBox" style={{display:this.state.SecuritySegmentResidentialOrCommercialTypes[i]==='Commercial'?'block':'none'}}>
                        <div className="inputLabel">
                            Commercial Type
                        </div>
                        <div>
                            <select className="form-control" id={"SecurityAssetClass"+i} value={this.state.SecurityTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecurityTypes; vals[i]=e.target.value;this.setState({SecurityTypes:vals},()=>{this.SecurityReasonUpdated(e,i)})}}>
                                <option selected>Select Commercial Type</option>
                                {this.state.PropertyCommercialTypeList}
                            </select>
                        </div>
                    </div>         
                    <div className="SecurityAddress" id={"SecurityAddress"+i}>
                        <div className="inputLabel">Location Details</div>
                        <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>  
                            <div className="inputBox">
                                <div className="inputLabel">
                                    Address
                                </div>
                                <div className="multiRowsInputBox">
                                    <input type="text" id={'SecurityStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['street']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'street')}} placeholder="Street" autoComplete={false} required/>
                                    <input type="text" id={'SecurityOverseasLine1'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['overseasline1']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'overseasline1')}} placeholder="Overseas Line 1" autoComplete={false}/>
                                    <input type="text" id={'SecurityOverseasLine2'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['overseasline2']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'overseasline2')}} placeholder="Overseas Line 2" autoComplete={false}/>
                                    <input type="text" id={'SecurityOverseasLine3'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['overseasline3']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'overseasline3')}} placeholder="Overseas Line 3" autoComplete={false}/>
                                    <input type="text" id={'SecurityOverseasLine4'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['overseasline4']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'overseasline4')}} placeholder="Overseas Line 4" autoComplete={false}/>
                                                    
                                    <input type="text" id={'SecurityCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['city']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'city')}} placeholder="City" autoComplete={false} />
                                
                                    <div className="multiColsInputBox">
                                        <select className="form-control" id={"StateName"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['state']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'state')}}>
                                            {this.state.MappedStates}
                                        </select>
                                        <input type="text" id={'SecurityPostcode'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" maxLength="4" value={this.state.SecuritySegmentAddreses[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.SecurityAddressUpdated(e,i,'postcode')}}} placeholder="Postcode" autoComplete={false} required/>
                                    </div>
                                    <div className="multiColsInputBox">
                                    <input type="text" id={'SecurityOverseasState'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" value={this.state.SecuritySegmentAddreses[i]['overseasstate']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'overseasstate')}} placeholder="Overseas State" autoComplete={false}/>
                                        <select className="form-control" id={"CountryCode"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentAddreses[i]['countrycode']} onChange={(e)=>{e.preventDefault();this.SecurityAddressUpdated(e,i,'countrycode')}}>
                                            <option selected>Country Code</option>
                                            {this.state.CountryCodeList}
                                        </select>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* <div className="form-check">
                            <input className="form-check-input" type="checkbox" readOnly={this.state.InstructionSubmit?true:false} name="exampleRadios" id={"securityselfacting" + i} checked={this.state.SelfActing[i]}  onChange={(e)=>{let vals = this.state.SelfActing;vals[i]=!this.state.SelfActing[i];this.setState({SelfActing:vals},()=>{this.UpdateSecuritySegment()})}}/>
                            <label className="form-check-label" for="exampleRadios1">
                                Self Acting
                            </label>
                        </div> */}

                       

                        {/* <div id={"PartyName"+i}>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                Party Type
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}}>    
                                                <RadioButton value="Borrower" name={"PartyType"+i} title="Borrower" checked={this.state.PartySegmentPartyTypes[i]==='Borrower'}/>                              
                                                <RadioButton value="Guarantor" name={"PartyType"+i} title="Guarantor" checked={this.state.PartySegmentPartyTypes[i]==='Guarantor'}/>
                                            </div>
                                        </div> 
                        </div> */}
                                               
                        <div className="inputBox">
                            <div className="inputLabel">
                                Title Type List
                            </div>
                            <div>
                                <select className="form-control" id={"SecuritySegmentTitles"+i} value={this.state.SecuritySegmentTitles[i]['TitleType']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,'TitleType')}} autoComplete={false}>
                                    {/* <option selected>Select Title Type List</option> */}
                                    {this.state.TitleTypeList}
                                </select>
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Title Tenure Type
                            </div>
                            <div>
                                <select className="form-control" id={"SecuritySegmentTitles"+i} value={this.state.SecuritySegmentTitles[i]['TenureType']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,'TenureType')}} autoComplete={false}>
                                    {/* <option selected>Select Title Type List</option> */}
                                    {this.state.TitleTenureTypeList}
                                </select>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Extent
                            </div>
                            <div>
                                <select className="form-control" id={"SecuritySegmentTitles"+i} value={this.state.SecuritySegmentTitles[i]['TorrensExtent']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,'TorrensExtent')}} autoComplete={false}>
                                    {/* <option selected>Select Title Type List</option> */}
                                    {this.state.TitleTorrensExtent}
                                </select>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                Torrens Strata
                            </div>
                            <div className="multiColsInputBoxContentWidth" id={"SecurityTorrensStrata"+i} value={this.state.SecuritySegmentTitles[i]['TorrensStrata']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentTitles; vals[i]['TorrensStrata']=e.target.value;this.setState({SecuritySegmentTitles:vals},()=>{alert(this.state.SecuritySegmentTitles[i]['TorrensStrata']);this.SecurityTitlesUpdated(e,i,'TorrensStrata')})}}>    
                                <RadioButton value="Yes" name={"SecurityTorrensStrata"+i} title="Yes" checked={this.state.SecuritySegmentTitles[i]['TorrensStrata']==='Yes'} />                              
                                <RadioButton value="No" name={"SecurityTorrensStrata"+i} title="No" checked={this.state.SecuritySegmentTitles[i]['TorrensStrata']==='No'} />                              
                            </div>
                        </div>
                        {/* <div className="inputBox">
                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                Construction?
                            </div>
                            <div className="multiColsInputBoxContentWidth" id={"SecuritySegmentIsConstruction"+i} value={this.state.SecuritySegmentConstructions[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SecuritySegmentConstructions; vals[i]=e.target.value;this.setState({SecuritySegmentConstructions:vals},()=>{this.UpdateSecuritySegment()})}}>    
                                <RadioButton value="Yes" name={"SecuritySegmentIsConstruction"+i} title="Yes" checked={this.state.SecuritySegmentConstructions[i]==='Yes'} />                              
                                <RadioButton value="No" name={"SecuritySegmentIsConstruction"+i} title="No" checked={this.state.SecuritySegmentConstructions[i]==='No'} />                                           
                            </div>
                        </div> */}
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Auto Consol
                            </div>
                            <input type="text" id={'SecurityTorrensAutoConsol'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensAutoConsol']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensAutoConsol'])}} placeholder="Enter Torrens Auto Consul" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Block
                            </div>
                            <input type="text" id={'TorrensBlock'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensBlock']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensBlock'])}} placeholder="Enter Torrens Block" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Build Unit Plan
                            </div>
                            <input type="text" id={'SecurityTorrensBuildUnitPlan'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensBuildUnitPlan']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensBuildUnitPlan'])}} placeholder="Enter Torrens Build Unit Plan" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens District
                            </div>
                            <input type="text" id={'SecurityTorrensDistrict'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensDistrict']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensDistrict'])}} placeholder="Enter Torrens District" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Division
                            </div>
                            <input type="text" id={'SecurityTorrensDivision'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensDivision']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensDivision'])}} placeholder="Enter Torrens Division" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Folio
                            </div>
                            <input type="text" id={'TorrensFolio'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensFolio']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensFolio'])}} placeholder="Enter Torrens Folio" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Folio ID
                            </div>
                            <input type="text" id={'SecurityTorrensFolioID'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensFolioID']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensFolioID'])}} placeholder="Enter Torrens Folio ID" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Location
                            </div>
                            <input type="text" id={'SecurityTorrensLocation'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensLocation']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensLocation'])}} placeholder="Enter Torrens Location" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Lot
                            </div>
                            <input type="text" id={'SecurityTorrensLot'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensLot']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensLot'])}} placeholder="Enter Torrens Lot" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Parcel
                            </div>
                            <input type="text" id={'SecurityTorrensParcel'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensParcel']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensParcel'])}} placeholder="Enter Torrens Parcel" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Plan
                            </div>
                            <input type="text" id={'SecurityTorrensPlan'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensPlan']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensPlan'])}} placeholder="Enter Torrens Plan" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Plan Type
                            </div>
                            <input type="text" id={'SecurityTorrensPlanType'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensPlanType']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensPlanType'])}} placeholder="Enter Torrens Plan Type" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Section
                            </div>
                            <input type="text" id={'SecurityTorrensSection'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensSection']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensSection'])}} placeholder="Enter Torrens Section" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Title
                            </div>
                            <input type="text" id={'SecurityTorrensTitle'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensTitle']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensTitle'])}} placeholder="Enter Torrens Title" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Title Ref
                            </div>
                            <input type="text" id={'SecurityTorrensTitleRef'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensTitleRef']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensTitleRef'])}} placeholder="Enter Torrens Title Ref" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Unit
                            </div>
                            <input type="text" id={'SecurityTorrensUnit'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensUnit']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensUnit'])}} placeholder="Enter Torrens Unit" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Volume
                            </div>
                            <input type="text" id={'SecurityTorrensVolume'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensVolume']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensVolume'])}} placeholder="Enter Torrens Volume" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Torrens Volume Folio
                            </div>
                            <input type="text" id={'SecurityTorrensVolumeFolio'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['TorrensVolumeFolio']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['TorrensVolumeFolio'])}} placeholder="Enter Torrens Volume Folio" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Company ACN
                            </div>
                            <input type="text" id={'SecurityCompanyACN'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['CompanyACN']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['CompanyACN'])}} placeholder="Enter Company ACN" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Company Name
                            </div>
                            <input type="text" id={'SecurityCompanyName'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['CompanyName']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['CompanyName'])}} placeholder="Enter Company Name" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Company Share Cert Numb
                            </div>
                            <input type="text" id={'SecurityCompanyShareCertNumb'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['CompanyShareCertNumb']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['CompanyShareCertNumb'])}} placeholder="Enter Company Share Cert Numb" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Company Shares From
                            </div>
                            <input type="text" id={'SecurityCompanySharesFrom'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['CompanySharesFrom']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['CompanySharesFrom'])}} placeholder="Enter Company Shares From" autoComplete={false} />
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Company Shares To
                            </div>
                            <input type="text" id={'SecurityCompanySharesTo'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['CompanySharesTo']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['CompanySharesTo'])}} placeholder="Enter Company Shares To" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Company Shares Total
                            </div>
                            <input type="text" id={'SecurityCompanySharesTotal'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentTitles[i]['CompanySharesTotal']} onChange={(e)=>{e.preventDefault();this.SecurityTitlesUpdated(e,i,['CompanySharesTotal'])}} placeholder="Enter Company Shares Total" autoComplete={false} />
                        </div>
                        <div className="inputBox LPNameBox" id={"SecurityLeaseDates"+i} style={{display:this.state.SecuritySegmentTitles[i]['TenureType']==='Leasehold'?'block':'none'}}>
                            <div className="inputLabel">
                                Lease Start Date
                            </div>
                            <div className="dateBox">
                                <input type="date" id={'SecuritySegmentLeaseStartDates'+i} className={"form-control"+(this.state.SecuritySegmentLeaseStartDates[i]?"successTextBox": '')} value={this.state.SecuritySegmentLeaseStartDates[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.SecuritySegmentLeaseStartDateUpdated(e,i)}} autoComplete={false} placeholder="DD/MM/YY" required/>
                            </div>
                            <div className="inputLabel">
                                Lease End Date
                            </div>
                            <div className="dateBox">
                                <input type="date" id={'SecuritySegmentLeaseStartDates'+i} className={"form-control"+(this.state.SecuritySegmentLeaseEndDates[i]?"successTextBox": '')} value={this.state.SecuritySegmentLeaseEndDates[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.SecuritySegmentLeaseEndDateUpdated(e,i)}} autoComplete={false} placeholder="DD/MM/YY" required/>
                            </div>
                        </div>  

                        <div className="inputBox">
                            <div className="inputLabel">
                                Encumbrance Description
                            </div>
                            <input type="text" id={'SecurityEncumbrance'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentEncumbrances[i]['Description']} onChange={(e)=>{e.preventDefault();this.SecuritySegmentEncumbrancesUpdated(e,i,['Description'])}} placeholder="Enter Encumbrance Description" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Encumbrance Type
                            </div>
                            <div>
                                <select className="form-control" id={"SecurityEncumbranceTypes"+i} value={this.state.SecuritySegmentEncumbrances[i]['EncumbranceType']} onChange={(e)=>{e.preventDefault();this.SecuritySegmentEncumbrancesUpdated(e,i,'EncumbranceType')}} autoComplete={false}>
                                    <option selected>Select Encumbrance Type</option>
                                    {this.state.EncumbranceTypeList}
                                </select>
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Registered Number
                            </div>
                            <input type="text" id={'SecurityEncumbranceRegisteredNumber'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentEncumbrances[i]['RegisteredNumber']} onChange={(e)=>{e.preventDefault();this.SecuritySegmentEncumbrancesUpdated(e,i,['RegisteredNumber'])}} placeholder="Enter Registered Number" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Finance Type
                            </div>
                            <div>
                                <select className="form-control" id={"SecurityFinanceTypes"+i} value={this.state.SecuritySegmentFinanceTypes[i]} onChange={(e)=>{e.preventDefault();this.SecuritySegmentFinanceTypesUpdated(e,i)}} autoComplete={false}>                                    
                                    {this.state.FinanceTypeList}
                                </select>
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Contract Price Amount
                            </div>
                            <input type="number" min="1" step="any" id={'SecurityContractPriceAmount'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentContractPrices[i]['ContractPriceAmount']} onChange={(e)=>{e.preventDefault();this.SecuritySegmentContractPricesUpdated(e,i,['ContractPriceAmount'])}} placeholder="Contract Price Amount" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Transfer Of Land Amount
                            </div>
                            <input type="number" min="1" step="any" id={'SecurityTransferofLandAmount'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentContractPrices[i]['TransferOfLandAmount']} onChange={(e)=>{e.preventDefault();this.SecuritySegmentContractPricesUpdated(e,i,['TransferOfLandAmount'])}} placeholder="Transfer Of Land Amount" autoComplete={false} />
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Builders Contract Amount
                            </div>
                            <input type="number" min="1" step="any" id={'SecurityBuildersContractAmount'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.SecuritySegmentBuildersContractAmount[i]} onChange={(e)=>{e.preventDefault();this.SecuritySegmentBuildersContractAmountUpdated(e,i)}} placeholder="Builders Contract Amount" autoComplete={false} />
                        </div>
                        {/* Real Estate End */}   

                        {/* Non Real Estate Start */}   
                        <div className="inputBox">
                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                Primary Security?
                            </div>
                            <div className="multiColsInputBoxContentWidth" id={"FinancialSegmentNonRealEstates"+i} value={this.state.FinancialSegmentNonRealEstates[i]['PrimarySecurity']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentNonRealEstates; vals[i]['PrimarySecurity']=e.target.value;this.setState({FinancialSegmentNonRealEstates:vals},()=>{this.FinancialSegmentNonRealEstatesUpdated(e,i,['PrimarySecurity'])})}}>    
                                <RadioButton value="Yes" name={"FinancialSegmentNonRealEstates"+i} title="Yes" checked={this.state.FinancialSegmentNonRealEstates[i]['PrimarySecurity']==='Yes'} />                              
                                <RadioButton value="No" name={"FinancialSegmentNonRealEstates"+i} title="No" checked={this.state.FinancialSegmentNonRealEstates[i]['PrimarySecurity']==='No'} />                                           
                            </div>
                        </div>                    
                        <div className="inputBox">
                            <div className="inputLabel">
                                Transaction
                            </div>
                            <div>
                                <select className="form-control" id={"NonPropertyTransactionTypes"+i} value={this.state.FinancialSegmentNonRealEstates[i]['Transaction']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentNonRealEstates; vals[i]['Transaction']=e.target.value;this.setState({SecuritySegmentPropertyPrimaryPurposes:vals},()=>{this.FinancialSegmentNonRealEstatesUpdated(e,i,['Transaction'])})}}>
                                    {/* <option selected>Select Primary Purpose</option> */}
                                    {this.state.NonPropertyTransactionList}
                                </select>
                            </div>
                        </div>    

                        <div className="inputBox">
                            <div className="inputLabel">
                                Deposit Account Type
                            </div>
                            <div>
                                <select className="form-control" id={"DepositAccountTypes"+i} value={this.state.FinancialSegmentNonRealEstateDepositAccounts[i]['DepositAccountType']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentNonRealEstateDepositAccounts; vals[i]['DepositAccountType']=e.target.value;this.setState({FinancialSegmentNonRealEstateDepositAccounts:vals},()=>{this.FinancialSegmentNonRealEstateDepositAccountsUpdated(e,i,['DepositAccountType'])})}}>
                                    <option selected>Select Deposit Account Type</option>
                                    {this.state.FinancialDepositAccountTypeList}
                                </select>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Account Description
                            </div>
                            <div>                                
                                <input type="text" id={'DepositAccountDesc'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentNonRealEstateDepositAccounts[i]['Description']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateDepositAccountsUpdated(e,i,['Description'])}} placeholder="Enter Account Description" autoComplete={false} />
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Account Name
                            </div>
                            <div>
                                <input type="text" id={'DepositAccountName'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentNonRealEstateDepositAccounts[i]['AccountName']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateDepositAccountsUpdated(e,i,['AccountName'])}} placeholder="Enter Account Name" autoComplete={false} />
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Account Number
                            </div>
                            <div>
                                <input type="text" id={'DepositAccountNumber'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentNonRealEstateDepositAccounts[i]['AcctNbr']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateDepositAccountsUpdated(e,i,['AcctNbr'])}} placeholder="Enter Account Number" autoComplete={false} />
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                BSB
                            </div>
                            <div>
                                <input type="text" id={'DepositAccountNumberBSB'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentNonRealEstateDepositAccounts[i]['BSB']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateDepositAccountsUpdated(e,i,['BSB'])}} placeholder="Enter BSB" autoComplete={false} />
                            </div>
                        </div> 
                           
                        {/* Non Real Estate End */}                       

                        {/*Other Asset Start */}         

                        <div className="inputBox">
                            <div className="inputLabel">
                                Other Asset Type
                            </div>
                            <div>
                                <select className="form-control" id={"OtherAssetType"+i} value={this.state.FinancialSegmentNonRealEstateOtherAssets[i]['OtherAssetType']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentNonRealEstateOtherAssets; vals[i]['OtherAssetType']=e.target.value;this.setState({FinancialSegmentNonRealEstateOtherAssets:vals},()=>{this.FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,['OtherAssetType'])})}}>
                                    {/* <option selected>Select Primary Purpose</option> */}
                                    {this.state.OtherAssetTypeList}
                                </select>
                            </div>
                        </div>  
                        <div className="inputBox">
                            <div className="inputLabel">
                                Other Asset Description
                            </div>
                            <div>
                                <input type="text" id={'OtherAssetDescription'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentNonRealEstateOtherAssets[i]['OtherAssetDescription']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,['OtherAssetDescription'])}} placeholder="Enter Description" autoComplete={false} />
                            </div>
                        </div>    

                        <div className="inputBox">
                            <div className="inputLabel">
                                Share Type
                            </div>
                            <div>
                                <select className="form-control" id={"OtherAssetShareType"+i} value={this.state.FinancialSegmentNonRealEstateOtherAssets[i]['ShareType']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentNonRealEstateOtherAssets; vals[i]['ShareType']=e.target.value;this.setState({FinancialSegmentNonRealEstateOtherAssets:vals},()=>{this.FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,['ShareType'])})}}>
                                    {/* <option selected>Select Primary Purpose</option> */}
                                    {this.state.NonPropertyShareTypeList}
                                </select>
                            </div>
                        </div>     
                        <div className="inputBox">
                            <div className="inputLabel">
                                Maturity Date
                            </div>
                            <div className="dateBox">
                                <input type="date" id={'OtherAssetMaturityDate'+i} className={"form-control"+(this.state.FinancialSegmentNonRealEstateOtherAssets[i]['MaturityDate']?"successTextBox": '')} value={this.state.FinancialSegmentNonRealEstateOtherAssets[i]['MaturityDate']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,['MaturityDate'])}} autoComplete={false} placeholder="DD/MM/YY"/>
                            </div>
                        </div>    
                        <div className="inputBox">
                            <div className="inputLabel">
                                Motor Vehicle Type
                            </div>
                            <div>
                                <select className="form-control" id={"MotorVehicleType"+i} value={this.state.FinancialSegmentNonRealEstateOtherAssets[i]['VehicleType']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentNonRealEstateOtherAssets; vals[i]['VehicleType']=e.target.value;this.setState({FinancialSegmentNonRealEstateOtherAssets:vals},()=>{this.FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,['VehicleType'])})}}>
                                    {/* <option selected>Select Primary Purpose</option> */}
                                    {this.state.MotorVehicleTypeList}
                                </select>
                            </div>
                        </div>     
                        <div className="inputBox">
                            <div className="inputLabel">
                                Vehicle Make
                            </div>
                            <div>
                                <input type="text" id={'MotorVehicleMake'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentNonRealEstateOtherAssets[i]['Make']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,['Make'])}} placeholder="Enter Vehicle Make" autoComplete={false} />
                            </div>
                        </div>   
                        <div className="inputBox">
                            <div className="inputLabel">
                                Vehicle Year
                            </div>
                            <div>
                                <input type="number" id={'MotorVehicleYear'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentNonRealEstateOtherAssets[i]['Year']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentNonRealEstateOtherAssetsUpdated(e,i,['Year'])}} placeholder="Enter Vehicle Year" autoComplete={false} />
                            </div>
                        </div>  

                        {/* Other Asset End */}        
                        {/* Mortgage Start */}

                        <div className="inputBox">
                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                New Mortgage?
                            </div>
                            <div className="multiColsInputBoxContentWidth" id={"FinancialSegmentNewMortgage"+i} value={this.state.FinancialSegmentMortgages[i]['NewMortgage']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentMortgages; vals[i]['NewMortgage']=e.target.value;this.setState({FinancialSegmentMortgages:vals},()=>{this.FinancialSegmentMortgagesUpdated(e,i,['NewMortgage'])})}}>    
                                <RadioButton value="Yes" name={"FinancialSegmentNewMortgage"+i} title="Yes" checked={this.state.FinancialSegmentMortgages[i]['NewMortgage']==='Yes'} />                              
                                <RadioButton value="No" name={"FinancialSegmentNewMortgage"+i} title="No" checked={this.state.FinancialSegmentMortgages[i]['NewMortgage']==='No'} />                                           
                            </div>
                        </div>  
                        <div className="inputBox">
                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                Additional Loan Against Mortgage?
                            </div>
                            <div className="multiColsInputBoxContentWidth" id={"FinancialSegmentAdditionalLoanAgainstMortgage"+i} value={this.state.FinancialSegmentMortgages[i]['AdditionalLoanAgainstMortgage']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentMortgages; vals[i]['AdditionalLoanAgainstMortgage']=e.target.value;this.setState({FinancialSegmentMortgages:vals},()=>{this.FinancialSegmentMortgagesUpdated(e,i,['AdditionalLoanAgainstMortgage'])})}}>    
                                <RadioButton value="Yes" name={"FinancialSegmentAdditionalLoanAgainstMortgage"+i} title="Yes" checked={this.state.FinancialSegmentMortgages[i]['AdditionalLoanAgainstMortgage']==='Yes'} />                              
                                <RadioButton value="No" name={"FinancialSegmentAdditionalLoanAgainstMortgage"+i} title="No" checked={this.state.FinancialSegmentMortgages[i]['AdditionalLoanAgainstMortgage']==='No'} />                                           
                            </div>
                        </div>  
                        <div className="inputBox">
                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                Deed Of Priority?
                            </div>
                            <div className="multiColsInputBoxContentWidth" id={"FinancialSegmentDeedOfPriority"+i} value={this.state.FinancialSegmentMortgages[i]['DeedOfPriority']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentMortgages; vals[i]['DeedOfPriority']=e.target.value;this.setState({FinancialSegmentMortgages:vals},()=>{this.FinancialSegmentMortgagesUpdated(e,i,['DeedOfPriority'])})}}>    
                                <RadioButton value="Yes" name={"FinancialSegmentDeedOfPriority"+i} title="Yes" checked={this.state.FinancialSegmentMortgages[i]['DeedOfPriority']==='Yes'} />                              
                                <RadioButton value="No" name={"FinancialSegmentDeedOfPriority"+i} title="No" checked={this.state.FinancialSegmentMortgages[i]['DeedOfPriority']==='No'} />                                           
                            </div>
                        </div>      
                        <div className="inputBox">
                            <div className="inputLabel">
                                Mortgage Date
                            </div>
                            <div className="dateBox">
                                <input type="date" id={'FinancialSegmentMortgageDate'+i} className={"form-control"+(this.state.FinancialSegmentMortgages[i]['MortgageDate']?"successTextBox": '')} value={this.state.FinancialSegmentMortgages[i]['MortgageDate']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.FinancialSegmentMortgagesUpdated(e,i,['MortgageDate'])}} autoComplete={false} placeholder="DD/MM/YY"/>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Mortgage Number
                            </div>
                            <div>
                                <input type="text" id={'FinancialSegmentMortgageNumber'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentMortgages[i]['Make']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentMortgagesUpdated(e,i,['MortgageNumb'])}} placeholder="Enter Mortgage Number" autoComplete={false} />
                            </div>
                        </div>               
                        <div className="inputBox">
                            <div className="inputLabel">
                                Priority
                            </div>
                            <div>
                                <select className="form-control" id={"MortgagePriority"+i} value={this.state.FinancialSegmentMortgages[i]['Priority']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentMortgages; vals[i]['Priority']=e.target.value;this.setState({SecuritySegmentPropertyPrimaryPurposes:vals},()=>{this.FinancialSegmentMortgagesUpdated(e,i,['Priority'])})}}>
                                    {/* <option selected>Select Primary Purpose</option> */}
                                    {this.state.MortgagePriorityList}
                                </select>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Collateral Mortgage Mortgage Number
                            </div>
                            <div>
                                <input type="text" id={'FinancialSegmentCollateralMortgageNumber'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentMortgages[i]['MortgageNumber']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentMortgagesUpdated(e,i,['MortgageNumber'])}} placeholder="Enter Collateral Mortgage Number" autoComplete={false} />
                            </div>
                        </div> 
                        <div className="inputBox">
                            <div className="inputLabel">
                                Mortgagee Name
                            </div>
                            <div>
                                <input type="text" id={'FinancialSegmentCollateralMortgageeName'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentMortgages[i]['MortgageeName']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentMortgagesUpdated(e,i,['MortgageeName'])}} placeholder="Enter Mortgagee Name" autoComplete={false} />
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Mortgagor Name
                            </div>
                            <div>
                                <input type="text" id={'FinancialSegmentCollateralMortgagorName'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentMortgages[i]['MortgagorName']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentMortgagesUpdated(e,i,['MortgagorName'])}} placeholder="Mortgagor Name" autoComplete={false} />
                            </div>
                        </div>   

                        {/* Mortgage End */}   

                        {/* AssetInsurance Start */}
                        <div className="inputBox">
                            <div className="inputLabel">
                                Asset Insuranace Category
                            </div>
                            <div>
                                <select className="form-control" id={"AssetInsuranceCategory"+i} value={this.state.FinancialSegmentAssetInsurance[i]['Category']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentAssetInsurance; vals[i]['Category']=e.target.value;this.setState({FinancialSegmentAssetInsurance:vals},()=>{this.FinancialSegmentAssetInsuranceUpdated(e,i,['Category'])})}}>
                                    {/* <option selected>Select Primary Purpose</option> */}
                                    {this.state.AssetInsuranceCategoryList}
                                </select>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Asset Insurance Type
                            </div>
                            <div>
                                <select className="form-control" id={"AssetInsuranceType"+i} value={this.state.FinancialSegmentAssetInsurance[i]['AssetInsuranceType']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentAssetInsurance; vals[i]['AssetInsuranceType']=e.target.value;this.setState({FinancialSegmentAssetInsurance:vals},()=>{this.FinancialSegmentAssetInsuranceUpdated(e,i,['AssetInsuranceType'])})}}>
                                    {/* <option selected>Select Primary Purpose</option> */}
                                    {this.state.AssetInsuranceTypeList}
                                </select>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Insured Amount
                            </div>
                            <input type="number" min="1" step="any" id={'AssetInsuranceInsuredAmount'+i} className="dollarTextBox" value={this.state.FinancialSegmentAssetInsurance[i]['InsuredAmount']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentAssetInsuranceUpdated(e,i,['InsuredAmount'])}} placeholder="Insured Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Premium Amount
                            </div>
                            <input type="number" min="1" step="any" id={'AssetInsurancePremiumAmount'+i} className="dollarTextBox" value={this.state.FinancialSegmentAssetInsurance[i]['PremiumAmount']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentAssetInsuranceUpdated(e,i,['PremiumAmount'])}} placeholder="Premium Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Policy Number
                            </div>
                            <div>
                                <input type="text" id={'AssetInsurancePolicyNumber'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.FinancialSegmentAssetInsurance[i]['PolicyNumber']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentAssetInsuranceUpdated(e,i,['PolicyNumber'])}} placeholder="Policy Number" autoComplete={false} />
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Insurance Effective Date
                            </div>
                            <div className="dateBox">
                                <input type="date" id={'AssetInsuranceEffectiveDate'+i} className={"form-control"+(this.state.FinancialSegmentAssetInsurance[i]['InsuranceEffectiveDate']?"successTextBox": '')} value={this.state.FinancialSegmentAssetInsurance[i]['InsuranceEffectiveDate']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.FinancialSegmentAssetInsuranceUpdated(e,i,['InsuranceEffectiveDate'])}} autoComplete={false} placeholder="DD/MM/YY"/>
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Insurance Expiry Date
                            </div>
                            <div className="dateBox">
                                <input type="date" id={'AssetInsuranceExpiryDate'+i} className={"form-control"+(this.state.FinancialSegmentAssetInsurance[i]['InsuranceExpiryDate']?"successTextBox": '')} value={this.state.FinancialSegmentAssetInsurance[i]['InsuranceExpiryDate']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.FinancialSegmentAssetInsuranceUpdated(e,i,['InsuranceExpiryDate'])}} autoComplete={false} placeholder="DD/MM/YY" required/>
                            </div>
                        </div>  
                        {/* AssetInsurance End */}
                        {/* Valuation Start */}
                        <div className="inputBox">
                            <div className="inputLabel">
                                Valuation Total Value
                            </div>
                            <input type="number" min="1" step="any" id={'ValuationTotalValue'+i} className="dollarTextBox" value={this.state.FinancialSegmentValuation[i]['TotalValue']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentValuationUpdated(e,i,['TotalValue'])}} placeholder="Enter Total Value" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                Replacement Insurance Full?
                            </div>
                            <div className="multiColsInputBoxContentWidth" id={"ValuationReplacementInsuranceFull"+i} value={this.state.FinancialSegmentValuation[i]['ReplacementInsuranceFull']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.FinancialSegmentValuation; vals[i]['ReplacementInsuranceFull']=e.target.value;this.setState({FinancialSegmentValuation:vals},()=>{this.FinancialSegmentValuationUpdated(e,i,['ReplacementInsuranceFull'])})}}>    
                                <RadioButton value="Yes" name={"ValuationReplacementInsuranceFull"+i} title="Yes" checked={this.state.FinancialSegmentValuation[i]['ReplacementInsuranceFull']==='Yes'} />                              
                                <RadioButton value="No" name={"ValuationReplacementInsuranceFull"+i} title="No" checked={this.state.FinancialSegmentValuation[i]['ReplacementInsuranceFull']==='No'} />                                           
                            </div>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Premium Amount
                            </div>
                            <input type="number" min="1" step="any" id={'ValuationReplacementInsuranceAmount'+i} className="dollarTextBox" value={this.state.FinancialSegmentValuation[i]['ReplacementInsuranceAmount']} onChange={(e)=>{e.preventDefault();this.FinancialSegmentValuationUpdated(e,i,['ReplacementInsuranceAmount'])}} placeholder="Replacement Insurance Amount" autoComplete={false} required readOnly={this.state.InstructionSubmit?true:false}/>
                        </div>
                        <div className="inputBox">
                            <div className="inputLabel">
                                Insurance Expiry Date
                            </div>
                            <div className="dateBox">
                                <input type="date" id={'ValuationDate'+i} className={"form-control"+(this.state.FinancialSegmentValuation[i]['Date']?"successTextBox": '')} value={this.state.FinancialSegmentValuation[i]['Date']} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{e.preventDefault();this.FinancialSegmentValuationUpdated(e,i,['Date'])}} autoComplete={false} placeholder="DD/MM/YY" required/>
                            </div>
                        </div>  
                        {/* Valuation End */}
                    </div>
                    
                </div>));
            
            }
            // this.setState({SecuritySegmentEntities:ents,SecuritySegmentAddreses:partyAdd,SecuritySegmentTitleReferences:titleRefs,PartyRepNames:names,PartyRepEmails:emails,PartyRepNumbers:numbers,SelfActing:selfActingChecks,PartyRepNames:partyRepNames, PartyRepEmails:partyRepEmails, PartyRepNumbers:partyRepNumbers});
            this.setState({SecuritySegmentEntities:ents});
        });
        }
        
    }


    SimultaneousSettlementSegmentLoanIdUpdated(e,i){
        e.preventDefault();
        let loanIds = this.state.SimultaneousSettlementSegmentLoanIds;
        loanIds[i]=e.target.value;
        this.setState({SimultaneousSettlementSegmentLoanIds:loanIds},()=>{this.UpdateSimultaneuosSettlementSegment()});

    }
    UpdateSimultaneuosSettlementSegment(){
        let ents = this.state.SimultaneousSettlementSegmentEntities;
        let loans = this.state.SimultaneousSettlementSegmentLoanIds;
        if(this.state.SimultaneousSettlementSegmentEntities.length>this.state.SimultaneousSettlementSegmentCount){
            for(let i = this.state.SimultaneousSettlementSegmentEntities.length;i>this.state.SimultaneousSettlementSegmentCount && i>0;i--){
                ents.pop();
                loans.pop();
                
            }
            this.setState({SimultaneousSettlementSegmentEntities:ents,SimultaneousSettlementSegmentLoanIds:loans});
        }else{
            for(let i = 0;i<this.state.SimultaneousSettlementSegmentCount;i++){
                if(loans.length<this.state.SimultaneousSettlementSegmentCount){
                    loans.push(null);
                }
                ents[i]=((
                    <div>
                        <div className="SimultaneousSettlement" id={"SimultaneousSettlement"+i}>
                            <div className="inputBox">
                                <div className="inputLabel">
                                Loan Id {i+1}
                                </div>
                                <input type="text" id={'SimulataneousSettlementLoanId'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" value={this.state.SimultaneousSettlementSegmentLoanIds[i]} onChange={(e)=>{e.preventDefault();this.SimultaneousSettlementSegmentLoanIdUpdated(e,i)}} placeholder="" autoComplete={false} required/>
                            </div>
                        </div>
                    </div>
                ));
                
            }
            this.setState({SimultaneousSettlementSegmentEntities:ents,SimultaneousSettlementSegmentLoanIds:loans});
        }
    }

    UpdateGuarantorTitleDeedToAddress(e,i,key){
        e.preventDefault();
        let address = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
        let oPrev = this.state.BorrowerClearTitleToAddress;
        address[i][key]=e.target.value;
        this.setState({ImportantNoteSegmentGuarantorTitleDeedToAddress:address,BorrowerClearTitleToAddress:oPrev},()=>{this.UpdateImportNotesSegment()});

    }

    UpdateBorrowerClearTitleToAddress(e,i,key){
        e.preventDefault();
        let address = this.state.BorrowerClearTitleToAddress;
        let oPrev = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
        
        address[i][key]=e.target.value;
        this.setState({ImportantNoteSegmentGuarantorTitleDeedToAddress:oPrev,BorrowerClearTitleToAddress:address},()=>{this.UpdateImportNotesSegment()});

    }

    SettlementNotToBeBookedPriorToUpdated(e,i){
        e.preventDefault();
        let vals = this.state.ImportantNoteSegmentSettlementNotToBeBookedPriorToDates;
        vals[i]=e.target.value;
        this.setState({ImportantNoteSegmentSettlementNotToBeBookedPriorToDates:vals},()=>{this.UpdateImportNotesSegment()});
    }

    CollectSurplusUpdated(e,i){
        e.preventDefault();
    }

    GuarantorUpdated(e,i){
        e.preventDefault();
        let vals = this.state.ImportantNoteSegmentGuarantorNames;
        vals[i]=e.target.value;
        this.setState({ImportantNoteSegmentGuarantorNames:vals},()=>{this.UpdateImportNotesSegment()});
    }

    UpdateImportNotesSegment(){
        let ents = this.state.ImportantNoteSegmentEntities;
        if(this.state.ImportantNoteSegmentEntities.length>this.state.ImportantNoteSegmentCount){
            let borrPartyAdd = this.state.BorrowerClearTitleToAddress;
            let guarPartyAdd = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
            for(let i = this.state.ImportantNoteSegmentEntities.length;i>this.state.ImportantNoteSegmentCount && i>this.state.ImportantNoteSegmentMinCount; i--){
                ents.pop();
                borrPartyAdd.pop();
                guarPartyAdd.pop();
            }
            this.setState({ImportantNoteSegmentGuarantorTitleDeedToAddress:guarPartyAdd,BorrowerClearTitleToAddress:borrPartyAdd},()=>{});
            this.setState({ImportantNoteSegmentEntities:ents});
        }else{
            let borrPartyAdd = this.state.BorrowerClearTitleToAddress;
            let guarPartyAdd = this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress;
            for(let i  =0; i<this.state.ImportantNoteSegmentCount;i++){
                //let addressEntity = {'state':'','city':'','number':'','postcode':''};
                let addressEntity = {'building':'','floor':'','floorType':'','street':'','POBox':'','deliveryType':'','overseasline1':'','overseasline2':'','overseasline3':'','overseasline4':'','city':'','state':'','postcode':''};
            
                let ent2 = {'state':'','city':'','number':'','postcode':'','street':''};
                if(borrPartyAdd.length<this.state.ImportantNoteSegmentCount){   
                    borrPartyAdd.push(ent2);
                    guarPartyAdd.push(addressEntity);
                }

                ents[i]=((
                <div>
                    <div className="inputBox">
                        <div className="inputLabel">
                        Do you want to mail the Guarantor Title Deed?
                        </div>
                        <div className="multiColsInputBoxContentWidth" name="provideGuarAddDetails" id="provideGuarAddDetails" value={this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{this.setState({HasImportantNoteSegmentGuarantorTitleDeedToAddress:this.stringToBool(e.target.value)},
                            ()=>{
                                ;document.getElementById('GuarantorTitleDeedToSegment').style.display=(this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress?'block':'none');
                            })}}>
                            {/* <div className="multiColsInputBoxContentWidth" id={"PartyType"+i} value={this.state.PartySegmentPartyTypes[i]} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{ alert('hi');let vals = this.state.PartySegmentPartyTypes; vals[i]=e.target.value;this.setState({PartySegmentPartyTypes:vals},()=>{this.UpdatePartySegment()})}}>     */}
                                <RadioButton value="Yes" name="provideGuarAddDetails" title="Yes"/>                              
                                <RadioButton value="No" name="provideGuarAddDetails" title="No"/>
                            {/* </div>  */}
                        </div>
                    </div>
                    <div id="GuarantorTitleDeedToSegment" style={{display:'none'}}>
                        <div className="GuarantorClearTitleToAdd" id={"GuarantorClearTitleToAdd"+i}>
                            <div className="inputBox">
                                <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Street
                                        </div>
                                        <div> 
                                        <input type="text" id={'GuarantorClearTitleToStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false}  value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['street']} onChange={(e)=>{e.preventDefault();this.UpdateGuarantorTitleDeedToAddress(e,i,'street')}} placeholder="Street" autoComplete={false} required={this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress?true:false}/>
                                        </div>
                                    </div>  
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            City
                                        </div>
                                        <div> 
                                        <input type="text" id={'SecurityCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['city']} onChange={(e)=>{e.preventDefault();this.UpdateGuarantorTitleDeedToAddress(e,i,'city')}} autoComplete={false} placeholder="City" />
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            State
                                        </div>
                                        <div> 
                                            <select className="form-control" id={"GuarantorClearTitleToState"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['state']} onChange={(e)=>{e.preventDefault();this.UpdateGuarantorTitleDeedToAddress(e,i,'state')}}>
                                                {this.state.MappedStates}
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Postcode
                                        </div>
                                        <div> 
                                        <input type="text" id={'GuarantorClearTitleToPostCode'+i} readOnly={this.state.InstructionSubmit?true:false} className="form-control" value={this.state.ImportantNoteSegmentGuarantorTitleDeedToAddress[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.UpdateGuarantorTitleDeedToAddress(e,i,'postcode')}}} autoComplete={false} placeholder="Postcode" required={this.state.HasImportantNoteSegmentGuarantorTitleDeedToAddress?true:false}/>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                            Do you want to mail the Borrower Title Deed?
                        </div>                        
                        <div className="multiColsInputBoxContentWidth" name="hasBorrowerClearTitleTo" id="hasBorrowerClearTitleTo" value={this.state.HasBorrowerClearTitleToAddress} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{this.setState({HasBorrowerClearTitleToAddress:this.stringToBool(e.target.value)},
                            ()=>{
                                ;document.getElementById('BorrowerClearTitleToSegment').style.display=(this.state.HasBorrowerClearTitleToAddress?'block':'none');
                            })}}>
                            <RadioButton value="Yes" name="hasBorrowerClearTitleTo" title="Yes"/>                              
                            <RadioButton value="No" name="hasBorrowerClearTitleTo" title="No"/>
                        </div>
                    </div>
                    <div id="BorrowerClearTitleToSegment" style={{display:'none'}}>
                        <div className="BorrowerClearTitleToAdd" id={"BorrowerClearTitleToAdd"+i}>
                            <div className="inputBox">
                                <div className="subFormBox" style={{backgroundColor: '#E4EBF0'}}>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Street
                                        </div>
                                        <div> 
                                        <input type="text" id={'BorrowerClearTitleToStreet'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} value={this.state.BorrowerClearTitleToAddress[i]['street']} onChange={(e)=>{e.preventDefault();this.UpdateBorrowerClearTitleToAddress(e,i,'street')}} placeholder="Street" autoComplete={false} required={this.state.HasBorrowerClearTitleToAddress?true:false}/>
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            City
                                        </div>
                                        <div> 
                                        <input type="text" id={'SecurityCity'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false}  value={this.state.BorrowerClearTitleToAddress[i]['city']} onChange={(e)=>{e.preventDefault();this.UpdateBorrowerClearTitleToAddress(e,i,'city')}} autoComplete={false} placeholder="City" />
                                        </div>
                                    </div>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            State
                                        </div>
                                        <div> 
                                        <select className="form-control" id={"BorrowerClearTitleToState"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.BorrowerClearTitleToAddress[i]['state']} onChange={(e)=>{e.preventDefault();this.UpdateBorrowerClearTitleToAddress(e,i,'state')}}>
                                            {this.state.MappedStates}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Postcode
                                        </div>
                                        <div> 
                                        <input type="text" id={'BorrowerClearTitleToPostCode'+i} className="form-control" readOnly={this.state.InstructionSubmit?true:false} maxLength="4" value={this.state.BorrowerClearTitleToAddress[i]['postcode']} onChange={(e)=>{e.preventDefault();if(!this.IsValidPostcode(e.target.value)){return;}else{this.UpdateBorrowerClearTitleToAddress(e,i,'postcode')}}} autoComplete={false} placeholder="Postcode" required={this.state.HasBorrowerClearTitleToAddress?true:false}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inputBox">
                        <div className="inputLabel">
                        What is the anticipated settlement date? (Optional)
                        </div>
                        <div> 
                        <input type="date" id={'SettlementNotToBeBookedPriorTo'+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.ImportantNoteSegmentSettlementNotToBeBookedPriorToDates[i]} autoComplete={false} onChange={(e)=>{e.preventDefault();this.SettlementNotToBeBookedPriorToUpdated(e,i)}} placeholder="DD/MM/YY" />
                            {/* <FormTextBox 
                                type="date" className="dateTextBoxRight"
                                placeholder="DD/MM/YY"
                            /> */}
                        </div>
                    </div>  
                    <div className="inputBox">
                        <div className="inputLabel">
                        Would you like MSA to collect the surplus?
                        </div>
                        {/* <div className="multiColsInputBoxContentWidth" name="collectSurplus" id="collectSurplus" value={this.state.ImportantNoteSegmentCollectSurplus} readOnly={this.state.InstructionSubmit?true:false} autoComplete={false} onChange={(e)=>{let vals = this.state.ImportantNoteSegmentCollectSurplus; vals[0]=this.stringToBool(e.target.value);this.setState({ImportantNoteSegmentCollectSurplus:vals},()=>{console.log(this.state.ImportantNoteSegmentCollectSurplus)})}}> */}
                        {/* <div className="multiColsInputBoxContentWidth" name="collectSurplus" id="collectSurplus" readOnly={this.state.InstructionSubmit?true:false} autoComplete={false} onChange={(e)=>{let vals = this.state.ImportantNoteSegmentCollectSurplus; vals[0]=this.stringToBool(e.target.value);this.setState({ImportantNoteSegmentCollectSurplus:vals},()=>{console.log(this.state.ImportantNoteSegmentCollectSurplus)})}}> */}
                        <div className="multiColsInputBoxContentWidth" name="collectSurplus" id="collectSurplus" readOnly={this.state.InstructionSubmit?true:false} autoComplete={false} onChange={(e)=>{let vals = this.state.ImportantNoteSegmentCollectSurplus; vals[0]=this.stringToBool(e.target.value);this.setState({ImportantNoteSegmentCollectSurplus:vals})}}>
                            <RadioButton value="on" name="collectSurplus" title="Yes"/>                              
                            <RadioButton value="off" name="collectSurplus" title="No"/>
                        </div>                        
                    </div>
                </div>))
            }
            this.setState({ImportantNoteSegmentEntities:ents,ImportantNoteSegmentGuarantorTitleDeedToAddress:guarPartyAdd,BorrowerClearTitleToAddress:borrPartyAdd});
        }
    }

    UpdateSpecialConditionValues(e,i){
        e.preventDefault();
        let vals = this.state.SpecialConditionSegmentConditionValues;
        vals[i]=e.target.value;
        this.setState({SpecialConditionSegmentConditionValues:vals},()=>{this.UpdateSpecialConditionSegment()});

    }

    UpdateSpecialConditionSegmentTypes(e,i){
        e.preventDefault();
        let vals = this.state.SpecialConditionSegmentConditionTypes;
        vals[i]=e.target.value;
        this.setState({SpecialConditionSegmentConditionTypes:vals},()=>{this.UpdateSpecialConditionSegment()});
    }

    UpdateSpecialConditionSegment(){
        let ents = this.state.SpecialConditionSegmentEntities;
        if(ents.length>this.state.SpecialConditionSegmentCount){
            let types =this.state.SpecialConditionSegmentConditionTypes;
            let vals = this.state.SpecialConditionSegmentConditionValues;
            for(let i =ents.length ; i >this.state.SpecialConditionSegmentCount; i--){
                ents.pop();
                types.pop();
                vals.pop();
            }
            this.setState({SpecialConditionSegmentConditionTypes:types,SpecialConditionSegmentConditionValues:vals,SpecialConditionSegmentEntities:ents});

        }else{
            let types =this.state.SpecialConditionSegmentConditionTypes;
            let vals = this.state.SpecialConditionSegmentConditionValues;
            for(let i =0;i<this.state.SpecialConditionSegmentCount;i++){
                if(types.length<this.state.SpecialConditionSegmentCount){
                    types.push(null);
                    vals.push('');
                }
           
            }
            this.setState({SpecialConditionSegmentConditionTypes:types,SpecialConditionSegmentConditionValues:vals},()=>{
                for (let i=0;i<this.state.SpecialConditionSegmentCount;i++){
                    ents[i]=((
                        <div>
                             <h6>Special Condition {i+1}</h6>
                             {/* <label for={"SpecialConditionType"+i}>Condition Type</label>
                             <select className="form-control" id={"SpecialConditionType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionTypes[i] }onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionSegmentTypes(e,i)}}>
                                 <option value='All' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='ALL'}>All</option>
                                 <option value='MSA' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='MSA'}>MSA</option>
                            </select> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                Condition Type
                                </div>
                                <div> 
                                <select className="form-control" id={"SpecialConditionType"+i} readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionTypes[i] }onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionSegmentTypes(e,i)}}>
                                    <option value='All' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='ALL'}>All</option>
                                    <option value='MSA' selected={this.state.SpecialConditionSegmentConditionTypes[i]==='MSA'}>MSA</option>
                                </select>
                                </div>
                            </div>  
                            {/* <div className="form-group">
                                <label for={"SpecialConditionComment"+i}>Condition</label>
                                <textarea id={'SpecialConditionComment'+i} className="form-control"  readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionValues[i]} onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionValues(e,i)}} placeholder=""/>
                            </div> */}
                            <div className="inputBox">
                                <div className="inputLabel">
                                Condition
                                </div>
                                <div> 
                                <textarea id={'SpecialConditionComment'+i} className="form-control"  readOnly={this.state.InstructionSubmit?true:false} value={this.state.SpecialConditionSegmentConditionValues[i]} onChange={(e)=>{e.preventDefault();this.UpdateSpecialConditionValues(e,i)}} placeholder=""/>
                                </div>
                            </div>
                        </div>
                     ));
                }
                this.setState({SpecialConditionSegmentEntities:ents});
            });
            
        }
        
    }


    CheckMatter(event){
        event.preventDefault();
        this.setState({
            isChecking:true,
        });
        setTimeout(()=>{
            this.setState({isChecking:false});
        },300);
        InstructionService.CheckIsExistingMatter(this.state.lenderReference).then(response=>{
            this.setState({
                isExistingLenderReference:response.data.Exists,
                matterNumber:response.data.MatterId,
                matterDescription:response.data.MatterDescription,
            });
        },error=>{
            this.setState({
                isExistingLenderReference:false,
                errorMessage:error.message,
            })
        })
        this.setState({
            hasCheckedReferenceNumber:true,
            isChecking:false,
        },()=>{
            this.setState({
                copy:this.state.isExistingLenderReference? "":"",
            })
        });
        //check matter exists
        
    }

    UpdateLenderReferenceNumber(event){
        event.preventDefault();
        this.setState({
            lenderReference:event.target.value,
            InstructionLenderReference:event.target.value
        },_=>{
            if(this.state.lenderReference===""){
                this.setState({
                    hasCheckedReferenceNumber:false
                })
            }
        })
    }

    DownloadInstructionHtml(event){
        event.preventDefault();
        InstructionService.GetInstructionHtml(this.state.msg).then(
            resp => {
              const file = new Blob([resp.data], {type: resp.request.response.type});
                saveAs(file,this.state.msg+"_Instruction"+".html");  
            },err=>{

            })
    }

    DownloadInstructionXml(event){
        event.preventDefault();
        InstructionService.GetNewLoanInstructionXml(this.state.msg).then(
            resp => {
              const file = new Blob([resp.data], {type: resp.request.response.type});
                saveAs(file,this.state.msg+"_Instruction"+".xml");  
            },err=>{

            })
    }

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    } 

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    SwitchForm(selectedForm){ 
        this.setState({
            FormStepsValue: selectedForm
        }); 
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }


    render() {   
        

        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        // if(this.state.isLoading){
        //     return(<ProgressDisplay></ProgressDisplay>)
        // }
        
        if(this.state.leftContent != this.state.pageTitle){
            // alert(this.state.lmwInstructMSASub)
             return( 
                 <DisplayLeftMenu 
                     page={this.state.leftContent} 
                     desktopMenu={this.state.desktopMenu} 
                     lmwInstructMSASub={this.state.lmwInstructMSASub}
                 />
             )
        } 

        if(this.state.msg!=='' && !this.state.InstructionSubmit){
            return( 
            <div className="instruction page">
                {/* <Header/> */}
                <div className="instruction container">
                    <div className="instruction inner">
                        <p>{this.state.PayoutStage==='QA'?(<span>This request has been uploaded and locked for MSA. Our reference number for this loan is <b>{this.state.msg}</b>.</span>):'Payout has now been saved. Please lock the payout to submit to MSA.'}</p>
                        <div className="flex">
                        <a type="link" className="btn btn-secondary" href="/Instruction">Go Back</a>
                        </div>
                    </div>
                </div>
            </div>
                );
        }

        let loanIdRenderable = this.state.LoanIdEntities.length? this.state.LoanIdEntities.reduce((p,c)=>[p,'',c]):'';
        let partyRepRenderable = this.state.PartyRepEntities.length? this.state.PartyRepEntities.reduce((p,c)=>[p,'',c]):'';
        let incomMortRenderable = this.state.IncomingMortgageeSegmentEntities.length? this.state.IncomingMortgageeSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let partySegRenderable = this.state.PartySegmentEntities.length? this.state.PartySegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let relatedPartySegRenderable = this.state.RelatedPartySegmentEntities.length? this.state.RelatedPartySegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let securitySegRenderable = this.state.SecuritySegmentEntities.length? this.state.SecuritySegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let simultaneousSegRenderable = this.state.SimultaneousSettlementSegmentEntities.length? this.state.SimultaneousSettlementSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let importantNotesSegRenderable = this.state.ImportantNoteSegmentEntities.length? this.state.ImportantNoteSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let specialCondSegRenderable = this.state.SpecialConditionSegmentEntities.length? this.state.SpecialConditionSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let feeSegmentRenderable = this.state.FeeSegmentEntities.length? this.state.FeeSegmentEntities.reduce((p,c)=>[p,'',c]):'';
        let instructionsSegmentRenderable = this.state.InstructionsSegmentDocumentsEntities.length? this.state.InstructionsSegmentDocumentsEntities.reduce((p,c)=>[p,'',c]):'';
        let applicationOptions=[];
        for(let i=0; i<this.state.ApplicationTypes.length;i++){
            applicationOptions.push(<option>{this.state.ApplicationTypes[i]}</option>);
        }

        
        let mappedApplicationOptions = applicationOptions.length? applicationOptions.reduce((p,c)=>[p,'',c]):'';       
       

        return ( 
             
            <LeftMenuContent 
                id="NewLoanInstructionFormPage"  
                page="newloansinstructionsform" 
                desktopMenu={this.state.desktopMenu} 
                callBack = {this.handleLeftMenuCallback} 
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 

                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />
                    

                    <div className="content"> 
                    
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>

                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 

                        </div> */}

                    
                        <div className="FormsBox">
                        <form id="instruction-form">
                            <TitleSegment>New Loans Application</TitleSegment> 
                            <div>

                                <div className={this.state.FormStepsValue==""||this.state.FormStepsValue=="MatterDetails"?"FormStepsSelected":"FormSteps"}>

                                    <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="number">2</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">3</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">4</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">5</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">6</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">7</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">8</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>

                                    <div>
                                        <h6>Matter Details</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                             
                                        </div>
                                        <div>
                                            <Button 
                                                addCSS="secondaryBTN  btnAuto"
                                                clickFunction={() => this.SwitchForm('PartyDetails')}
                                            >NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="PartyDetails"?"FormStepsSelected":"FormSteps"}>

                                    <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">3</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">4</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">5</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">6</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">7</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">8</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>

                                    <div>
                                        <h6>Party Details</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button 
                                            addCSS="secondaryBTN btnAuto"
                                            clickFunction={() => this.SwitchForm('MatterDetails')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('RelatedParty')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="RelatedParty"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">4</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">5</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">6</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">7</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">8</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>

                                    <div>
                                        <h6>Related Party Details</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('PartyDetails')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('Security')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="Security"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">5</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">6</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">7</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">8</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>


                                    <div>
                                        <h6>Security Details</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('RelatedParty')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('LoanDetails')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="LoanDetails"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">5</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">6</div>
                                            <div className="bar"></div>
                                        </div> 
                                        <div className="steps">
                                            <div className="number">7</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">8</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>


                                    <div>
                                        <h6>Loan Details</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('Security')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('FeeDetails')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="FeeDetails"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">5</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">6</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">7</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">8</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>



                                    <div>
                                        <h6>Fee Details</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('LoanDetails')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('InsuranceDetails')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="InsuranceDetails"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">5</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">6</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">7</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">8</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>


                                    <div>
                                        <h6>Insurance Details</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('FeeDetails')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('SpecialCondition')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={this.state.FormStepsValue=="SpecialCondition"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">5</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">6</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">7</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">8</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">9</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>


                                    <div>
                                        <h6>Special Condition</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('InsuranceDetails')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('Instructions')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="Instructions"?"FormStepsSelected":"FormSteps"}>

                                    <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">5</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">6</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">7</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">8</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">9</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">10</div>
                                            <div className="bar"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>


                                    <div>
                                        <h6>Instructions</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('SpecialCondition')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('Commissions')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="Commissions"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">5</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">6</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">7</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">8</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">9</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">10</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="number">11</div>
                                            <div className="bar"></div>
                                        </div>
                                        
                                    </div>


                                    <div>
                                        <h6>Commissions</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('Instructions')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="secondaryBTN  btnAuto" clickFunction={() => this.SwitchForm('Comments')}>NEXT</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.state.FormStepsValue=="Comments"?"FormStepsSelected":"FormSteps"}>

                                <div className="ProcessStepsBar">
                                        <div className="steps">
                                            <div className="numberFirst">1</div>
                                            <div className="numberDone">2</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">3</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">4</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">5</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">6</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">7</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">8</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">9</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">10</div>
                                            <div className="barDone"></div>
                                        </div>
                                        <div className="steps">
                                            <div className="numberDone">11</div>
                                            <div className="barDone"></div>
                                        </div>
                                        
                                    </div>

                                    <div>
                                        <h6>Comments</h6>
                                    </div>
                                    <Card>FORMS HERE</Card>
                                    <div className="fsBtnBox">
                                        <div>
                                            <Button addCSS="secondaryBTN btnAuto" clickFunction={() => this.SwitchForm('Commissions')}>BACK</Button>
                                        </div>
                                        <div>
                                            <Button addCSS="btnAuto">SUBMIT</Button>
                                        </div>
                                    </div>
                                </div>


                                
                            </div> 



                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                            
                                {this.state.isLoading?<ProgressDisplay></ProgressDisplay> : ""}
                                    {this.state.InstructionSubmit?(
                                        <div className="spacingBottomLarge">
                                        <Card>
                                        <div>
                                            <p>This request has been uploaded to MSA. Our reference number for this loan is <b>{this.state.msg}</b>.</p>
                                            <div>
                                                <ButtonGroupDischarges className="DischargesGroup" addCSS="dischargesButton">
                                                    {/* <Button addCSS="btnRegular btnSmall" clickFunction={ (e)=>this.DownloadInstructionHtml(e)}>Download HTML</Button> */}
                                                    <Button 
                                                        addCSS="btnRegular btnSmall"
                                                        clickFunction={ (e)=>this.DownloadInstructionXml(e)}>Download XML</Button> 
                                                    </ButtonGroupDischarges>
                                                {/* <ButtonIcon
                                                    id=""
                                                    addCSS="ButtonIconSelected biLeft mainBtn"
                                                    onClick={this.DownloadInstructionHtml}
                                                >Download HTML</ButtonIcon>
                                                <ButtonIcon
                                                    id=""
                                                    addCSS="ButtonIconSelected biRight mainBtn"
                                                    onClick={this.DownloadInstructionXml}
                                                >Download XML</ButtonIcon>
                                                <br/> */}
                                            </div>                                        
                                </div>
                                </Card>
                                </div>):''}
                            
                            <div className="spacingBottomLarge" id="divMatterDetails" style={this.state.activeMatterDetails?{display:"block"}:{display:"none"}}>                                
                            {/* <div className="spacingBottomLarge" id="divMatterDetails" style={this.state.activeSection=="divMatterDetails"?{display:"block"}:{display:"none"}}>                                 */}
                                {/* <div className="spacingBottomLarge" id="divMatterDetails" style={this.state.activeMatterDetails?{display:"none"}:{display:"block"}}> */}
                                    <h5>Matter Details</h5> 
                                    <form id="instruction-form-matterdetails">
                                    <Card>                                
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Identifier Unique ID
                                        </div>
                                        <div>
                                            <input type="text" className={this.state.InstructionLenderReference?"successTextBox":''} id='LenderRef' placeholder="Lender unique loan application number"  value={this.state.InstructionLenderReference} onChange={(e)=>{e.preventDefault();this.UpdateLenderReferenceNumber(e)}} required/>
                                        </div>
                                    </div> 
                                    <div>
                                        <div className="inputLabel">
                                            Submission Date
                                        </div>
                                        <div className="dateBox">
                                            <input type="date" id='SubmissionDate' className={"form-control"+(this.state.ApplicationDateValidGood? this.state.ApplicationDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.ApplicationDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({ApplicationDateValidGood:true}):this.setState({ApplicationDateValidGood:false})}}} value={this.state.ApplicationDateValid} onChange={(e)=>{e.preventDefault();this.SubmissionDateValidUpdated(e)}} placeholder="DD/MM/YY" required/>
                                        </div>
                                        <br/>
                                    </div>  
                                    {/* <div>
                                        <div className="inputLabel">
                                            Disclosure Date
                                        </div>
                                        <div className="dateBox">
                                            <input type="date" id='DisclosureDate' className={"form-control"+(this.state.DisclosureDateValidGood? this.state.DisclosureDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.DisclosureDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DisclosureDateValidGood:true}):this.setState({DisclosureDateValidGood:false})}}} value={this.state.DisclosureDateValid} onChange={(e)=>{e.preventDefault();this.DisclosureDateValidUpdated(e)}} placeholder="DD/MM/YY" required/>
                                        </div>
                                    </div> */}
                                    <div>
                                        <div className="inputLabel">
                                            Application Type
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" id="ApplicationType" value={this.state.applicationTypeName} onChange={(e)=>{let vals = this.state.applicationTypeName; vals=e.target.value;this.setState({applicationTypeName:vals})}}>                                                                                        
                                                {mappedApplicationOptions}
                                            </select>
                                        </div>
                                        <br/>
                                    </div> 
                                    <div>
                                        <div className="inputLabel">
                                            Send Docs To Reciepient
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" id="SendDocsToRecipient" value={this.state.ApplicationSendDocsToRecipient} onChange={(e)=>{let vals = this.state.ApplicationSendDocsToRecipient; vals=e.target.value;this.setState({ApplicationSendDocsToRecipient:vals})}}>                                                                                        
                                                {this.state.SendDocsToRecipientList}
                                            </select>
                                        </div>
                                        <br/>
                                    </div> 
                                    {/* <div className="inputBox">
                                        <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                            Electronic Signing?
                                        </div>
                                        <div className="multiColsInputBoxContentWidth" id={"ElectronicSigning"} value={this.state.ApplicationElectronicSigning} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ApplicationElectronicSigning; vals=e.target.value;this.setState({ApplicationElectronicSigning:vals},()=>{this.ApplicationElectronicSigningUpdated(e)})}}>    
                                            <RadioButton value="Yes" name={"ElectronicSigning"} title="Yes" checked={this.state.ApplicationElectronicSigning==='Yes'} />                              
                                            <RadioButton value="No" name={"ElectronicSigning"} title="No" checked={this.state.ApplicationElectronicSigning==='No'} />                                           
                                        </div>
                                    </div>  */}
                                    <div className="inputBox">
                                            <div className="inputLabel" id={"ElectronicSigningLabel"}>
                                            Electronic Signing?
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"ElectronicSigning"} value={this.state.ApplicationElectronicSigning} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.ApplicationElectronicSigning; vals=e.target.value;this.setState({ApplicationElectronicSigning:vals})}}>    
                                                <RadioButton value="Yes" name={"ElectronicSigning"} title="Yes" checked={this.state.ApplicationElectronicSigning==='Yes'} />                              
                                                <RadioButton value="No" name={"ElectronicSigning"} title="No" checked={this.state.ApplicationElectronicSigning==='No'} />                                           
                                            </div>
                                        </div> 
                                    <div>
                                        <div className="inputLabel">
                                            Method
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" id="applicationMethod" value={this.state.ApplicationMethod} onChange={(e)=>{let vals = this.state.ApplicationMethod; vals=e.target.value;this.setState({ApplicationMethod:vals})}} required>                                                                                        
                                                {/* <option selected>SelectLoan Term Type</option> */}
                                                {this.state.DocumentationInstructionsMethodList}
                                            </select>
                                        </div>
                                        <br/>
                                    </div> 
                                    <div>
                                        <div className="inputLabel">
                                            Sales Channel
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" id="applicationSalesChannel" value={this.state.ApplicationSalesChannel} onChange={(e)=>{let vals = this.state.ApplicationSalesChannel; vals=e.target.value;this.setState({ApplicationSalesChannel:vals})}}>                                                                                        
                                                {this.state.SalesChannelList}
                                            </select>
                                        </div>
                                        <br/>
                                    </div>
                                    <div>
                                        <div className="inputLabel">
                                            Classification
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" id="applicationClassification" value={this.state.ApplicationClassification} onChange={(e)=>{let vals = this.state.ApplicationClassification; vals=e.target.value;this.setState({ApplicationClassification:vals})}}>                                                                                        
                                                {this.state.ClassificationList}
                                            </select>
                                        </div>
                                        <br/>
                                    </div>
                                    
                                    {/* <div>
                                        <div className="inputLabel">
                                            Submission Time
                                        </div>
                                        <div className="multiColsInputBoxContentWidth">
                                            <input type="text" id={'NewLoanHour'} className={"form-control"+(this.state.DateValidGood? this.state.ApplicationDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.ApplicationDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DateValidGood:true}):this.setState({DateValidGood:false})}}} value={this.state.ApplicationDateValid} onChange={(e)=>{e.preventDefault();this.PayoutDateValidUpdated(e)}} placeholder="hh" required/>
                                            <input type="text" id={'NewLoanMin'} className={"form-control"+(this.state.DateValidGood? this.state.ApplicationDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.ApplicationDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DateValidGood:true}):this.setState({DateValidGood:false})}}} value={this.state.ApplicationDateValid} onChange={(e)=>{e.preventDefault();this.PayoutDateValidUpdated(e)}} placeholder="mm" required/>
                                            <input type="text" id={'NewLoanSec'} className={"form-control"+(this.state.DateValidGood? this.state.ApplicationDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.ApplicationDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DateValidGood:true}):this.setState({DateValidGood:false})}}} value={this.state.ApplicationDateValid} onChange={(e)=>{e.preventDefault();this.PayoutDateValidUpdated(e)}} placeholder="ss" required/>
                                        </div>
                                    </div>   */}
                                </Card>
                                </form>
                                {/* </div> */}
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToPartyDetails"                                  
                                    onClick={this.GoToPartyDetails}
                                >Next to Loan Party Details</ButtonIcon> </div>
                                }
                            </div>                            
                            <div className="spacingBottomLarge" id="divPartyDetails" style={this.state.activePartyDetails?{display:"block"}:{display:"none"}}>
                            {/* <div className="spacingBottomLarge" id="divPartyDetails" style={this.state.activeSection=="divPartyDetails"?{display:"block"}:{display:"none"}}>   */}
                                <h5>Loan Party Details</h5> 
                                <form id="instruction-form-partydetails">
                                <Card>
                                    {partySegRenderable}
                                    
                                    <div className="multiColsInputBox">
                                        <div>
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                onClick={(e)=>{e.preventDefault();this.setState({PartySegmentCount:this.state.PartySegmentCount+1},()=>{this.PartyTypeUpdated(e);this.UpdatePartySegment()})}}
                                            >Add loan party</ButtonIcon>
                                        </div>
                                        <div className="tar">
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                display={this.state.PartySegmentCount===this.state.PartySegmentMinCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.PartySegmentCount>=0){this.setState({PartySegmentCount:this.state.PartySegmentCount-1},()=>{this.UpdatePartySegment()})}}}
                                            >Remove loan party</ButtonIcon>         </div>                              
                                    </div>    
                                </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackToMatterDetails"                                  
                                    onClick={this.BackToMatterDetails}
                                >Back to Matter Details</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToRelatedPartyDetails"                                  
                                    onClick={this.GoToRelatedPartyDetails}
                                >Next to Related Party Details</ButtonIcon> </div>
                                }
                            </div>

                            <div className="spacingBottomLarge" id="divRelatedParty" style={this.state.activeRelatedPartyDetails?{display:"block"}:{display:"none"}}>
                                <h5>Related Party Details</h5> 
                                <form id="instruction-form-relatedparty">
                                    <Card>
                                        {relatedPartySegRenderable}
                                        
                                        <div className="multiColsInputBox">
                                            <div>
                                                <ButtonIcon
                                                    id="btnCFPost"
                                                    addCSS="ButtonIconForm"
                                                    iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                    onClick={(e)=>{e.preventDefault();this.setState({RelatedPartySegmentCount:this.state.RelatedPartySegmentCount+1},()=>{this.PartyTypeUpdated(e);this.UpdateRelatedPartySegment()})}}
                                                >Add related loan party</ButtonIcon>
                                            </div>
                                            <div className="tar">
                                                <ButtonIcon
                                                    id="btnCFPost"
                                                    addCSS="ButtonIconForm"
                                                    iconURL="../../../../images/icons/OutlineWhite/Minus.svg"
                                                    display={this.state.RelatedPartySegmentCount===this.state.RelatedPartySegmentMinCount?'none':'inline-block'}
                                                    onClick={(e)=>{e.preventDefault();if(this.state.RelatedPartySegmentCount>=0){this.setState({RelatedPartySegmentCount:this.state.RelatedPartySegmentCount-1},()=>{this.UpdateRelatedPartySegment()})}}}
                                                >Remove related loan party</ButtonIcon>         </div>                              
                                        </div>    
                                    </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackToPartyDetails"                                  
                                    onClick={this.BackToPartyDetails}
                                >Back to Party Details</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToSecurityDetails"                                  
                                    onClick={this.GoToSecurityDetails}
                                >Next to Security Details</ButtonIcon> </div>
                                }
                            </div>



                            <div className="spacingBottomLarge" id="divSecurityDetails" style={this.state.activeSecurityDetails?{display:"block"}:{display:"none"}}>
                                <h5>Security Details</h5> 
                                <form id="instruction-form-securitydetails">
                                    <Card>
                                        {securitySegRenderable}                                    
                                        <div className="multiColsInputBox">
                                            <div>
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.SecuritySegmentCount===this.state.SecuritySegmentMaxCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();this.setState({SecuritySegmentCount:this.state.SecuritySegmentCount+1},()=>{this.SecurityTitleReferenceUpdated(e);this.UpdateSecuritySegment()})}}
                                            >Add security</ButtonIcon>
                                            </div>
                                            <div className="tar">
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.SecuritySegmentCount===this.state.SecuritySegmentMinCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.SecuritySegmentCount>=0){this.setState({SecuritySegmentCount:this.state.SecuritySegmentCount-1},()=>{this.UpdateSecuritySegment()})}}}
                                            >Remove security</ButtonIcon>
                                            </div>
                                        </div>                                         
                                    </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackToRelatedPartyDetails"                                  
                                    onClick={this.BackToRelatedPartyDetails}
                                >Back to Related Party Details</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToLoanDetails"                                  
                                    onClick={this.GoToLoanDetails}
                                >Next to Loan Details</ButtonIcon> </div>
                                }
                            </div>


                            <div className="spacingBottomLarge" id="divLoanDetails" style={this.state.activeLoanDetails?{display:"block"}:{display:"none"}}>
                                <h5>Loan Details Segment</h5>
                                <form id="instruction-form-loandetails">
                                    <Card>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Loan Details LVR Number
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'LoanDetailsLVRNumber'} className="form-control" value={this.state.LoanDetailsLVRNumber} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.LoanDetailsLVRNumberUpdated(e)}} placeholder="LVR Number" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel" id={"PartyTypeLabel"+1}>
                                                FHOG
                                            </div>
                                            <div className="multiColsInputBoxContentWidth" id={"LoanDetailsFHOG"} value={this.state.LoanDetailsFHOG} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.LoanDetailsFHOG; vals=e.target.value;this.setState({LoanDetailsFHOG:vals},()=>{this.LoanDetailsFHOGUpdated(e)})}}>    
                                                <RadioButton value="Yes" name={"LoanDetailsFHOG"} title="Yes" checked={this.state.LoanDetailsFHOG==='Yes'} />                              
                                                <RadioButton value="No" name={"LoanDetailsFHOG"} title="No" checked={this.state.LoanDetailsFHOG==='No'} />                                           
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                FHOG Amount
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="number" min="1" step="any" id={'FHOGAmount'} className="form-control" value={this.state.LoanDetailsFHOGAmount} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.FHOGAmountUpdated(e)}} placeholder="FHOG Amount" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        {loanIdRenderable}
                                        <div className="multiColsInputBox">
                                            <div> 
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                onClick={(e)=>{e.preventDefault();let incVal = this.state.LoanIdEntityCount+1;this.setState({LoanIdEntityCount:incVal},()=>{this.UpdateLoanIdSegment()})}}
                                            >Add Loan Id</ButtonIcon>
                                            </div>
                                            <div className="tar">
                                            <ButtonIcon
                                                id="btnCFPost"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.LoanIdEntityCount===this.state.LoanIdEntityMinCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.LoanIdEntityCount>=0){let incVal=this.state.LoanIdEntityCount-1;this.setState({LoanIdEntityCount:incVal},()=>{this.UpdateLoanIdSegment()})}}}
                                            >Remove Loan Id</ButtonIcon>
                                            </div>
                                        </div> 
                                    </Card>
                                </form> 
                                <div><ButtonIcon
                                    id="btnBackToSecurityDetails"                                  
                                    onClick={this.BackToSecurityDetails}
                                >Back to Security Details</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToFeeDetails"                                  
                                    onClick={this.GoToFeeDetails}
                                >Next to Fee Details</ButtonIcon> </div>
                                }
                            </div>                          

                            <div className="spacingBottomLarge" id="divFeeDetails" style={this.state.activeFeeDetails?{display:"block"}:{display:"none"}}>
                                <h5>Fee Segment</h5> 
                                <form id="instruction-form-feedetails">
                                    <Card>
                                        <div>
                                            <div className="inputLabel">
                                                Disclosure Date
                                            </div>
                                            <div className="dateBox">
                                                <input type="date" id='FeeSegmentDisclosureDate' className={"form-control"+(this.state.FeeSegmentDisclosureDates? this.state.FeeSegmentDisclosureDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.FeeSegmentDisclosureDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({FeeSegmentDisclosureDateValidGood:true}):this.setState({FeeSegmentDisclosureDateValidGood:false})}}} value={this.state.FeeSegmentDisclosureDateValid} onChange={(e)=>{e.preventDefault();this.FeeSegmentDisclosureDateValidUpdated(e)}} placeholder="DD/MM/YY" required/>
                                            </div>
                                        </div>                                   
                                        {feeSegmentRenderable}
                                        <div className="multiColsInputBox">
                                            <div> 
                                            <ButtonIcon
                                                id="btnAddFee"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                // display={this.state.FeeSegmentEntityCount===this.state.FeeSegmentEntityMaxCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();let incVal = this.state.FeeSegmentEntityCount+1;this.setState({FeeSegmentEntityCount:incVal},()=>{this.UpdateFeeSegment()})}}
                                            >Add Fee</ButtonIcon>
                                            </div>
                                            <div className="tar">
                                            <ButtonIcon
                                                id="btnRemoveFee"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.FeeSegmentEntityCount===this.state.FeeSegmentEntityMinCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.FeeSegmentEntityCount>=0){let incVal=this.state.FeeSegmentEntityCount-1;this.setState({FeeSegmentEntityCount:incVal},()=>{this.UpdateFeeSegment()})}}}
                                            >Remove Fee</ButtonIcon>
                                            </div>
                                        </div> 
                                    </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackToLoanDetails"                                  
                                    onClick={this.BackToLoanDetails}
                                >Back to Loan Details</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToInsuranceDetails"                                  
                                    onClick={this.GoToInsuranceDetails}
                                >Next to Insurance Details</ButtonIcon> </div>
                                }
                            </div>      

                            <div className="spacingBottomLarge" id="divInsuranceDetails" style={this.state.activeInsuranceDetails?{display:"block"}:{display:"none"}}>
                                <h5>Insurance Segment</h5> 
                                <form id="instruction-form-insurancedetails">
                                    <Card>                                                                    
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Insurance Type
                                            </div>
                                            <div>
                                                <select className="form-control" id="InsuranceSegmentInsuranceType" value={this.state.InsuranceSegmentInsuranceType} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.InsuranceSegmentInsuranceType; vals=e.target.value;this.setState({InsuranceSegmentInsuranceType:vals},()=>{this.InsuranceSegmentInsuranceTypeUpdated(e)})}}>
                                                    {/* <option selected>SelectLoan Term Type</option> */}
                                                    {this.state.InsuranceTypeList}
                                                </select>
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Description
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'InsuranceSegmentInsuranceDescription'} className="form-control" value={this.state.InsuranceSegmentInsuranceDescription} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentInsuranceDescriptionUpdated(e)}} placeholder="Description" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Policy No
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'InsuranceSegmentInsurancePolicyNo'} className="form-control" value={this.state.InsuranceSegmentInsurancePolicyNo} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentInsurancePolicyNoUpdated(e)}} placeholder="Policy No" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Paid Status
                                            </div>
                                            <div>
                                                <select className="form-control" id="InsuranceSegmentPaymentPaidStatus" value={this.state.InsuranceSegmentPaymentPaidStatus} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.InsuranceSegmentPaymentPaidStatus; vals=e.target.value;this.setState({CommissionSegmentPaymentPaidStatus:vals},()=>{this.InsuranceSegmentPaymentPaidStatusUpdated(e)})}}>
                                                    {/* <option selected>SelectLoan Term Type</option> */}
                                                    {this.state.PaidStatusList}
                                                </select>
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Amount Ex GST
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'InsuranceSegmentPaymentAmountExGST'} className="form-control" value={this.state.InsuranceSegmentPaymentAmountExGST} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentPaymentAmountExGSTUpdated(e)}} placeholder="Amount Ex GST" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Total
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'InsuranceSegmentPaymentTotal'} className="form-control" value={this.state.InsuranceSegmentPaymentTotal} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentPaymentTotalUpdated(e)}} placeholder="Total" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment GST
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'InsuranceSegmentPaymentGST'} className="form-control" value={this.state.InsuranceSegmentPaymentGST} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentPaymentGSTUpdated(e)}} placeholder="GST" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Balance
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="number" min="1" step="any" id={'InsuranceSegmentPaymentBalance'} className="form-control" value={this.state.InsuranceSegmentPaymentBalance} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentPaymentBalanceUpdated(e)}} placeholder="Balance" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payer Type
                                            </div>
                                            <div>
                                                <select className="form-control" id="InsuranceSegmentPayerType" value={this.state.InsuranceSegmentPremiumPayerType} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.InsuranceSegmentPremiumPayerType; vals=e.target.value;this.setState({InsuranceSegmentPremiumPayerType:vals},()=>{this.InsuranceSegmentPremiumPayerTypeUpdated(e)})}}>
                                                    {/* <option selected>SelectLoan Term Type</option> */}
                                                    {this.state.InsuranceTypeList}
                                                </select>
                                            </div>
                                        </div> 
                                        <div>
                                            <div className="inputLabel">
                                                Insurance Start Date
                                            </div>
                                            <div className="dateBox">
                                                <input type="date" id='InsuranceStartDate' className={"form-control"+(this.state.DisclosureDateValidGood? this.state.DisclosureDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.DisclosureDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DisclosureDateValidGood:true}):this.setState({DisclosureDateValidGood:false})}}} value={this.state.DisclosureDateValid} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentInsuranceStartDateUpdated(e)}} placeholder="DD/MM/YY" required/>
                                            </div>
                                        </div> 
                                        <div>
                                            <div className="inputLabel">
                                                Insurance End Date
                                            </div>
                                            <div className="dateBox">
                                                <input type="date" id='InsuranceEndDate' className={"form-control"+(this.state.DisclosureDateValidGood? this.state.DisclosureDateValid===null?'':' successTextBox':' errorTextBox')} onBlur={(e)=>{e.preventDefault();if(this.state.DisclosureDateValid!==null){let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);d >=today ? this.setState({DisclosureDateValidGood:true}):this.setState({DisclosureDateValidGood:false})}}} value={this.state.DisclosureDateValid} onChange={(e)=>{e.preventDefault();this.InsuranceSegmentInsuranceEndDateUpdated(e)}} placeholder="DD/MM/YY" required/>
                                            </div>
                                        </div> 
                                    </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackFeeDetails"                                  
                                    onClick={this.BackFeeDetails}
                                >Back to Fee Details</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToSpecialCondition"                                  
                                    onClick={this.GoToSpecialCondition}
                                >Next to Special Condition</ButtonIcon> </div>
                                }
                            </div>   

                            <div className="spacingBottomLarge" id="divSpecialCondition" style={this.state.activeSpecialCondition?{display:"block"}:{display:"none"}}>
                                <h5>Special Condition Segment</h5> 
                                <form id="instruction-form-specialcondition">
                                    <Card>  
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Special Condition
                                            </div>
                                            <div>
                                                <select className="form-control" id="SpecialConditionSegmentConditionName" value={this.state.SpecialConditionSegmentConditionName} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.SpecialConditionSegmentConditionName; vals=e.target.value;this.setState({SpecialConditionSegmentConditionName:vals},()=>{this.SpecialConditionSegmentConditionNameUpdated(e)})}}>
                                                    {/* <option selected>SelectLoan Term Type</option> */}
                                                    {this.state.ConditionTypeList}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Description
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'SpecialConditionSegmentConditionDescription'} className="form-control" value={this.state.SpecialConditionSegmentConditionDescription} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.SpecialConditionSegmentConditionDescriptionUpdated(e)}} placeholder="Description" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>                                                               
                                        {/* {insuranceSegmentRenderable}
                                        <div className="multiColsInputBox">
                                            <div> 
                                            <ButtonIcon
                                                id="btnAddFee"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                onClick={(e)=>{e.preventDefault();let incVal = this.state.FeeSegmentEntityCount+1;this.setState({FeeSegmentEntityCount:incVal},()=>{this.UpdateFeeSegment()})}}
                                            >Add Insurance</ButtonIcon>
                                            </div>
                                            <div className="tar">
                                            <ButtonIcon
                                                id="btnRemoveFee"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.FeeSegmentEntityCount===this.state.FeeSegmentEntityMinCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.FeeSegmentEntityCount>=0){let incVal=this.state.FeeSegmentEntityCount-1;this.setState({FeeSegmentEntityCount:incVal},()=>{this.UpdateFeeSegment()})}}}
                                            >Remove Insurance</ButtonIcon>
                                            </div>
                                        </div>  */}
                                    </Card>
                                </form>
                            </div>     

                            <div className="spacingBottomLarge" id="divInstructionsDetails" style={this.state.activeInstructions?{display:"block"}:{display:"none"}}>
                                <h5>Instructions Segment</h5> 
                                <form id="instruction-form-instructionsdetails">
                                    <Card>                                                                    
                                        {instructionsSegmentRenderable}
                                        <div className="multiColsInputBox">
                                            <div> 
                                            <ButtonIcon
                                                id="btnAddDocument"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                onClick={(e)=>{e.preventDefault();let incVal = this.state.InstructionsSegmentDocumentsEntityCount+1;this.setState({InstructionsSegmentDocumentsEntityCount:incVal},()=>{this.UpdateInstructionsDocumentsSegment()})}}
                                            >Add Document</ButtonIcon>
                                            </div>
                                            <div className="tar">
                                            <ButtonIcon
                                                id="btnRemoveDocument"
                                                addCSS="ButtonIconForm"
                                                iconURL="../../../../images/icons/OutlineWhite/Plus.svg"
                                                display={this.state.InstructionsSegmentDocumentsEntityCount===this.state.InstructionsSegmentDocumentsEntityMinCount?'none':'inline-block'}
                                                onClick={(e)=>{e.preventDefault();if(this.state.InstructionsSegmentDocumentsEntityCount>=0){let incVal=this.state.InstructionsSegmentDocumentsEntityCount-1;this.setState({InstructionsSegmentDocumentsEntityCount:incVal},()=>{this.UpdateInstructionsDocumentsSegment()})}}}
                                            >Remove Document</ButtonIcon>
                                            </div>
                                        </div> 
                                    </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackToSpecialCondition"                                  
                                    onClick={this.BackToSpecialCondition}
                                >Back to SpecialCondition</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToCommissions"                                  
                                    onClick={this.GoToCommissions}
                                >Next to Commissions Condition</ButtonIcon> </div>
                                }
                            </div>            
                            
                            <div className="spacingBottomLarge" id="divCommissionsDetails" style={this.state.activeCommissions?{display:"block"}:{display:"none"}}>
                                <h5>Commissions Segment</h5> 
                                <form id="instruction-form-commissionsdetails">
                                    <Card>                                                                    
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payer Type
                                            </div>
                                            <div>
                                                <select className="form-control" id="CommissionSegmentPayerType" value={this.state.CommissionSegmentPayerType} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.CommissionSegmentPayerType; vals=e.target.value;this.setState({CommissionSegmentPayerType:vals},()=>{this.CommissionSegmentPayerTypeUpdated(e)})}}>
                                                    {/* <option selected>SelectLoan Term Type</option> */}
                                                    {this.state.PayerTypeList}
                                                </select>
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Paid Status
                                            </div>
                                            <div>
                                                <select className="form-control" id="CommissionSegmentPaymentPaid" value={this.state.CommissionSegmentPaymentPaidStatus} readOnly={this.state.InstructionSubmit?true:false} onChange={(e)=>{let vals = this.state.CommissionSegmentPaymentPaidStatus; vals=e.target.value;this.setState({CommissionSegmentPaymentPaidStatus:vals},()=>{this.CommissionSegmentPaymentPaidStatusUpdated(e)})}}>
                                                    {/* <option selected>SelectLoan Term Type</option> */}
                                                    {this.state.PaidStatusList}
                                                </select>
                                            </div>
                                        </div> 
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Amount Ex GST
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'CommissionSegmentPaymentAmountExGST'} className="form-control" value={this.state.CommissionSegmentPayment['AmountExGST']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.CommissionSegmentPaymentUpdated(e,'AmountExGST')}} placeholder="Amount Ex GST" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Total
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'CommissionSegmentPaymentTotal'} className="form-control" value={this.state.CommissionSegmentPayment['Total']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.CommissionSegmentPaymentUpdated(e,'Total')}} placeholder="Total" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment GST
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="text" id={'CommissionSegmentPaymentGST'} className="form-control" value={this.state.CommissionSegmentPayment['GST']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.CommissionSegmentPaymentUpdated(e,'GST')}} placeholder="GST" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                        <div className="inputBox">
                                            <div className="inputLabel">
                                                Payment Balance
                                            </div>
                                            {/* <input type="text" id={'LoanId'+i} className="form-control" value={this.state.LoanIds[i]} onBlur={(e)=>{e.preventDefault();this.UpdateLenderReferenceCombined(e)}} onChange={(e)=>{e.preventDefault();this.LoanIdUpdated(e,i)}} placeholder="Loan Id" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required /> */}
                                            <input type="number" min="1" step="any" id={'CommissionSegmentPaymentBalance'} className="form-control" value={this.state.CommissionSegmentPayment['Balance']} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.CommissionSegmentPaymentUpdated(e,'Balance')}} placeholder="Balance" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} required />
                                        </div>
                                    </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackToInstructions"                                  
                                    onClick={this.BackToInstructions}
                                >Back to Instructions</ButtonIcon> </div>
                                {this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id="btnGoToComments"                                  
                                    onClick={this.GoToComments}
                                >Next to Comments</ButtonIcon> </div>
                                }
                            </div>
                            <div className="spacingBottomLarge" id="divComments" style={this.state.activeComments?{display:"block"}:{display:"none"}}>
                                <h5>Comment Segment</h5> 
                                <form id="instruction-form-comments">
                                    <Card>                                                                    
                                        <input type="text" id={'CommentId'} className="form-control" value={this.state.Comment} onBlur={(e)=>{e.preventDefault();}} onChange={(e)=>{e.preventDefault();this.CommentUpdated(e)}} placeholder="Comments" autoComplete={false}  readOnly={this.state.InstructionSubmit?true:false} />
                                    </Card>
                                </form>
                                <div><ButtonIcon
                                    id="btnBackToCommissions"                                  
                                    onClick={this.BackToCommissions}
                                >Back to Commissions</ButtonIcon> </div>
                                {this.state.InstructionSubmit?<div><a type="link" className="btn btn-secondary" href="/dischargeinstructions">                            
                                    <ButtonIcon
                                        id=""
                                        addCSS="ButtonIconSelected biRight mainBtn"
                                        >
                                    Done</ButtonIcon> 
                                </a></div>
                                :this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id=""
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={this.CreateNewLoanInstructionEntity}
                                >Submit New Loans Application</ButtonIcon> </div>
                                }
                            </div>

                                {/* {this.state.InstructionSubmit?<div><a type="link" className="btn btn-secondary" href="/dischargeinstructions">                            
                                    <ButtonIcon
                                        id=""
                                        addCSS="ButtonIconSelected biRight mainBtn"
                                        >
                                    Done</ButtonIcon> 
                                </a></div>
                                :this.state.isLoading?                               
                                    <ProgressDisplay></ProgressDisplay>
                                :
                                <div><ButtonIcon
                                    id=""
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={this.CreateNewLoanInstructionEntity}
                                >Submit New Loans Application</ButtonIcon> </div>
                                } */}
                           

                        </form>    
                        </div> 
                        
                        
                     

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
NewLoanInstructionFormPage=AutoLogout(NewLoanInstructionFormPage);
export default NewLoanInstructionFormPage
