import React, { Component } from 'react' 

import Button from '../../atom/Button/Button';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import HighlightText from '../../atom/HighlightText/HighlightText'; 
import IconBox from '../../atom/IconBox/IconBox';
import FormCheckBox from '../../atom/FormCheckBox/FormCheckBox';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import Line from '../../atom/Line/Line';
import MessageBox from '../../atom/MessageBox/MessageBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import BackBar from '../../molecules/BackBar/BackBar'; 
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox'; 
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import Accordion from '../../organism/Accordion/Accordion'; 
import DataTableDocuments from '../../organism/DataTableDocuments/DataTableDocuments';
import CommunicationContent from '../../organism/CommunicationContent/CommunicationContent';
import CommunicationForm from '../../organism/CommunicationForm/CommunicationForm';
import LoanDetails from '../../organism/LoanDetails/LoanDetails';
import ProgressStep from '../../organism/ProgressStep/ProgressStep';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/UserDetailPage.css';   
import ButtonGroupCapsule from '../../molecules/ButtonGroupCapsule/ButtonGroupCapsule';
import CheckBox from '../../atom/CheckBox/CheckBox';
import { UserLender } from '../../../services/user-lender';
import { MatterService } from '../../../services/matter-service';
import { Redirect } from 'react-router-dom';
import ValidationText from '../../atom/ValidationText/ValidationText';
import DropDownList from '../../atom/DropDownList/DropDownList';
import { assertNullLiteralTypeAnnotation } from '@babel/types';
import AutoLogout from '../../../auto-logout';
import InsightBox from '../../molecules/InsightBox/InsightBox';


class UserDetailPage extends Component {  
    constructor(props){
        super(props);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }
       
        this.state = {
            userid: this.props.match.params.myKey == null?'' : this.props.match.params.myKey.slice(1),
           //userid: this.props.userid == null?'' : this.props.userid,
            pageTitle : 'admincontrol',//'userdetail',
            leftContent :'admincontrol', //'userdetail',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            userStatus: 'active',
            backtoUserAdmin: false,

            active: true,
            inactive: null,
            selectedoption: "active",
            emailasusername: false,
            username: '',
            email: '',
            enabled: true,
            disabled: false,
            lastname: '',
            firstname: '',
            stateid: '',
            shouldRedirect: false,
            errorMessage: '',
            states: [],
            ustate: '',
            hasstaterestriction: false,
            matterTypes: [],
            matterStatuses: [],
            matterStates: [],     
            items:[],
            irene: [],
            reststates: [],
            selecttedstates: [],
            selectedmattertypes: [],
            selectedprivileges: [],          
            checked : [],
            staterestrictions: [],
            checkedAll: false,
            hasmattertyperestriction : false,
            mattertyperestrictions : [],
            checkedAllType: false,
            userprivileges: [],
            seluserprivileges : [],
           
            usernameError: "",
            lastnameError: "",
            stateidError: "",
            newLenderUserId: "",
            stateView: [],            
            className: 'formTextBox',
            validFirstname: false,
            validLastname: false,
            validEmail: false,
            validPhone: false,
            validMobile: false,
            validFax: false,
            firstnameValidationError: null,
            lastnameValidationError: null,
            emailValidationError: null,
            phoneValidationError: null,
            mobileValidationError: null,
            faxValidationError: null,
            errorCounter: 0,
            //firstnameValidationError: null,
            profileDisplay: "",
            userCreated: false,
            InsightBox:0, 
        }


        this.onValueChange = this.onValueChange.bind(this);
        // this.cancelChange = this.cancelChange.bind(this);
        this.EmailAsUsernameChanged = this.EmailAsUsernameChanged.bind(this);
        this.usernameChanged = this.usernameChanged.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        // this.enabledChanged = this.enabledChanged.bind(this);
        this.firstnameChanged = this.firstnameChanged.bind(this);
        // this.isFirstnameValid = this.isFirstnameValid.bind(this);
        this.lastnameChanged = this.lastnameChanged.bind(this);
        //this.isLastnameValid = this.isLastnameValid.bind(this);
        
        // this.notesChanged = this.notesChanged.bind(this);
        this.phoneChanged = this.phoneChanged.bind(this);
        //this.isPhoneValid = this.isPhoneValid.bind(this);
        this.mobileChanged = this.mobileChanged.bind(this);
        //this.isMobileValid = this.isMobileValid.bind(this);
        this.faxChanged = this.faxChanged.bind(this);
        // this.isFaxValid = this.isFaxValid.bind(this);
        this.stateChanged = this.stateChanged.bind(this);
        this.stateRestrictionChanged = this.stateRestrictionChanged.bind(this);
        this.SaveUserHandler = this.SaveUserHandler.bind(this);          
        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.handleStatusCallback = this.handleStatusCallback.bind(this);   
        this.backToUserAdmin = this.backToUserAdmin.bind(this);           
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }


    backToUserAdmin(child){
        //alert('child');
        this.setState({
            backtoUserAdmin: true
        });
    }

    componentDidMount() {   
        if(this.state.userid > 0)
        {
            //load values from database
            this.getLenderUserDetails()      
            //this.loadUserLookupLists()    
        }
        else this.loadLookupLists();
        window.addEventListener("resize", this.updateWindowDimension)       
    }

    getLenderUserDetails()
    {
        UserLender.getLenderUserDetails(         
            this.state.userid
            ).then(
                resp => {
                const userDetails = resp.data.Content;      
                this.setState({
                    userDetails:  userDetails,
                    username: userDetails.Username,
                    email: userDetails.Email,
                    emailasusername: userDetails.Username === userDetails.Email ? true : false,
                    stateid : userDetails.StateId,
                    ustate: userDetails.StateId,
                    statevalue: userDetails.StateName,
                    lastname: userDetails.Lastname,
                    firstname: userDetails.Firstname,
                    //usertypeid: userDetails.usertypeid,
                    //lenderid: userDetails.lenderid,
                    //mortmgrid: userDetails.mortmgrid,
                    //brokerid: userDetails.brokerid,
                    //relmgrid: userDetails.relmgrid,
                    notes: userDetails.Notes,
                    phone: userDetails.Phone,
                    mobile : userDetails.Mobile,
                    fax : userDetails.Fax,
                    enabled: userDetails.Enabled,
                    hasstaterestriction: userDetails.HasStateRestrictions,
                    reststates: userDetails.UserStateRestriction,                
                    hasmattertyperestriction : userDetails.HasMatterTypeRestrictions,
                    matterTypes : userDetails.UserMatterTypeRestriction,
                    userprivileges : userDetails.UserExternalPrivileges,   
                    userStatus: userDetails.Enabled  == 1?'active':'inactive',   
                    //stateView:  [{id: 8, value: 'ACT'},{id: 3, value: 'VIC'},]
                },()=>{
                    this.initialStateChecked();
                            this.initialMatterChecked();
                            this.initialPrivilegesChecked();    
                            this.loadStateList();
                });
                },
                err => {
                    alert("There is an error with the request")
                    this.setState({
                        
                        shouldRedirect: true
                        // hasSearched: true,
                        // isLoading: false,
                        // errorExists: true,
                        // errorMessage: err,
                    });
                }
            );      

    }

    initialStateChecked() {
        let states = this.state.reststates
        const { staterestrictions } = this.state;
        let newArr = [];
        states.forEach(stat => {
            if(stat.isChecked){             
                newArr.push(stat.id);           
            }
        }
        ) 
        this.setState(
            {staterestrictions: newArr,
                //checkedAll: !this.state.checkedAll
                checkedAll: states.length == newArr.length?true:false,
            }
            , ()=>{
                //console.log('initial state', newArr)
            }
        )        
      }


      initialMatterChecked() {
          
        let types = this.state.matterTypes
        const { checked } = this.state;
        let newArr = [];
        types.forEach(type => {
          //fruite.isChecked = checked
            
            if(type.isChecked == true){             
                newArr.push(type.id);           
            }
        }
        ) 
        
        this.setState(
            {mattertyperestrictions: newArr,
                //checkedAll: true
                checkedAllType: types.length == newArr.length?true:false,
            }
            , ()=>{
                //console.log('inital matter', newArr)
            }
        )        
      }

      initialPrivilegesChecked() {
          
        let privs = this.state.userprivileges
        let newArr = [];
        privs.forEach(priv => {
          //fruite.isChecked = checked            
            if(priv.isChecked == true){  
                var index = priv.id;         
                newArr.push(priv.id);    
                this.setState({seluserprivileges: newArr,
                //checkedAll: false
            }
            , ()=>{
                //console.log('updated priv', newArr, 'index', index)
            })    
            }
        }
        ) 
        this.setState(
            {seluserprivileges: newArr,
                //checkedAll: true
            }
            , ()=>{
                //console.log('inital privileges', newArr)
            }
        )        
      }

    loadLookupLists() {
        let self = this;
    
        //----let p1 = MatterService.getMatterTypeGroupList();
        let p1 = UserLender.getMatterTypeList();
        let p2 = MatterService.getMatterStatusList();
        let p3 = MatterService.getStateList();
        let p4 = UserLender.getExternalPrivListForUser();
        let p5 = MatterService.getStateViewList();

        //----let p3 = UserLender.getStateListForUser();
        /* ---let p5 = MatterService.getLenderList();
        let---- p6 = MatterService.getMortMgrList();
        let ----p7 = MatterService.GetBrokerList(); */
        
        // Promise.all([p1, p2, p3, p4]).then(values => {
            Promise.all([p1,p2,p3,p4,p5]).then(values => {
          let matterTypes = values[0].data;
        //   matterTypes.splice(0, 0);   
          
          let statuses = values[1].data;
          statuses.splice(0, 0);   
          //statuses.splice(0, 0, AllOption);
    
          let resstates = values[2].data;    
          resstates.splice(0, 0);   

         let userprivileges = values[3].data;

         let stateView = values[4].data;
          
          /* let lenders = values[4].data;
    
          let mortManagers = values[5].data;
    
          let brokers = values[6].data;
          brokers.splice(0,0,AllOption); */
          this.setState({
            //set values
            matterTypes: matterTypes,
            //matterType: matterType,
           matterStatuses: statuses,
           // matterStatus: matterStatus,
            states: resstates,//states,
            reststates: values[2].data,//resstates    
            //hasstaterestriction: true,  
            userprivileges: userprivileges  ,
            //matterState: matterState,
            stateView: stateView,
            stateid: 8
            
            /* lenders: lenders,
            lender: lenders[0],
            mortManagers: mortManagers,
            mortManager: mortManagers[0],
            brokers:brokers,
            broker:brokers[0], */
          },()=>{
//               if(this.state.userid > 0){
// alert(this.state.userid)
//               }
//             else {
//                 alert("no")
                this.initialPrivilegesChecked();
            //}
              //console.log(this.state.stateView)
              //console.log(this.state.reststates)
              //console.log("states"+this.state.reststates+"userstates:" + this.state.states);
            //   this.initialAllStateChecked();
            //   this.initialAllTypeChecked();
            });
          
        });       
      } 

      loadStateList() {
        let p = MatterService.getStateViewList();

        Promise.all([p]).then(values => {
        
            let stateView = values[0].data;
            
            this.setState({
                stateView: stateView                
            });            
        });       
      } 


      initialAllTypeChecked() {
          
        let types = this.state.matterTypes
        const { checked } = this.state;
        let newArr = [];
        types.forEach(type => {
          type.isChecked = checked
            
            if(type.isChecked){             
                newArr.push(type.id);           
            }
        }
        ) 
        if(this.state.hasmattertyperestriction == true)
          {
              //alert('hello')
          }
          else{
              //alert('hi');
              newArr=[]
          }
        this.setState(
            {mattertyperestrictions: newArr,
                checkedAllType: true}
            , ()=>{
                //console.log('updated mattertypes', newArr)
            }
        )        
      }

    
    

    onValueChange(e){ 
        //alert("hello");
        this.setState({
            selectedoption:e.target.value
        },()=>{

       //alert("selected" + this.state.selectedoption )
       if( this.state.selectedoption =="yes"){
        this.setState({
            enabled: true,
            disabled: false
        })
       }
       else{
        this.setState({
            enabled: false,
            disabled: true
        })
       }
        });
    }

    stateRestrictionChanged = (event) =>{
        this.setState({
                hasstaterestriction: !this.state.hasstaterestriction
            }
        ,()=>{this.initialAllStateChecked()}
            );    
        
        console.log(this.state.hasstaterestriction)

    }

    handleAllStateChecked= (event) => {
        let states = this.state.reststates
        const { checked } = this.state;
        let newArr = [];
        states.forEach(stat => {
            stat.isChecked = event.target.checked
            
            if(stat.isChecked){             
                newArr.push(stat.id);           
            }
        }
        ) 
        this.setState(
            {staterestrictions: newArr,
                checkedAll: !this.state.checkedAll,
                hasstaterestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{
                //console.log('updated state', newArr)
            }
        )        
      }

      handleCheckChildElement = (event) => {
        let states = this.state.reststates
        const { staterestrictions } = this.state;
        let newArr = [];
        var index = event.target.getAttribute('id')
        states.forEach(stat => {
           if (stat.value === event.target.value){             
            stat.isChecked =  event.target.checked
              if(stat.isChecked){
                newArr = [...staterestrictions, stat.id];
              }          
              else {
                newArr =  staterestrictions.filter(item => item != stat.id);
              }
           }  
        }) 
        this.setState(
            {staterestrictions: newArr,
                //checkedAll: false
                checkedAll: states.length == newArr.length?true:false,
                hasstaterestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{
                //console.log('updated state', newArr, 'index', index)
            }
        )
      } 
      
      handleAllTypesChecked= (event) => {
        let types = this.state.matterTypes
        const { checked } = this.state;
        let newArr = [];
        types.forEach(type => {
            type.isChecked = event.target.checked
            
            if(type.isChecked){             
                newArr.push(type.id);           
            }
        }
        ) 
        this.setState(
            {mattertyperestrictions : newArr,
                checkedAllType: !this.state.checkedAllType,
                hasmattertyperestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{
                //console.log('updated types', newArr)
            }
        )        
      }

      handleCheckTypeChildElement = (event) => {
        let types = this.state.matterTypes
        const { mattertyperestrictions  } = this.state;
        let newArr = [];
        var index = event.target.getAttribute('id')
        types.forEach(type => {
           if (type.value === event.target.value){             
            type.isChecked =  event.target.checked
              if(type.isChecked){
                newArr = [...mattertyperestrictions , type.id];
              }          
              else {
                newArr =  mattertyperestrictions .filter(item => item != type.id);
              }
           }  
        }) 
        this.setState(
            {mattertyperestrictions : newArr,
                //checkedAllType: false
                checkedAllType: types.length == newArr.length?true:false,
                hasmattertyperestriction: newArr.length > 0 ? true:false,
            }
            , ()=>{
                //alert(this.state.checkedAllType);
                //console.log('updated types', newArr, 'index', index)
            }
        )
      }  

    handleCheckPrivElement= (event) => {
        let privs = this.state.userprivileges
        const { seluserprivileges } = this.state;
        let newArr = [];
        var index = event.target.getAttribute('id')
        privs.forEach(priv => {
           if (priv.value === event.target.value){             
            priv.isChecked =  event.target.checked
              if(priv.isChecked){
                newArr = [...seluserprivileges, priv.id];
              }          
              else {
                newArr =  seluserprivileges.filter(item => item != priv.id);
              }
           }  
        }) 
        this.setState(
            {seluserprivileges: newArr,
                //checkedAll: false
            }
            , ()=>{
                //console.log('updated priv', newArr, 'index', index)
            }
        )
      } 
      
      stateChanged(event) {
        let stateidError = "";
        this.setState({
            ustate: event.target.value,
            stateid: event.target.value,//event.target.value.id
        },()=>{
            //alert(this.state.ustate + " " + this.state.stateid)
        });
        if(event.target.value.id == 0){
            stateidError = "Please select State";
            this.setState(
                {stateidError: stateidError}
            );
        }
        else{
            stateidError = "";
            this.setState(
                {stateidError: stateidError}
            );
        }

        if(stateidError){
            this.setState({stateidError},()=>{
                //console.log(stateidError)
            });
            return false;
        }
        

        // return true;
        //alert(event.value)
    }

    firstnameChanged(event) {
        let errorCounter = this.state;
        this.setState({
            firstname: event.target.value,
            firstnameValidationError: ''
        },()=>{
        //     //alert(this.state.firstname.length);
        //     //this.isFirstnameValid(this.state.firstname);
        //     this.setState({
        //         validFirstname: this.state.firstname.length>0?true:false,
        //    },()=>{
        //         if(!this.state.validFirstname){
        //             this.setState({
        //                 firstnameValidationError: 'Please enter given name',
        //                 errorCounter: errorCounter + 1
        //             });
        //         }
        //         else{
        //             this.setState({
        //                 firstnameValidationError: '',
        //                 errorCounter: 0
        //             });
        //         }
        //     });  

        this.validateFirstname(this.state.firstname);
           
       });
    }

    lastnameChanged(event) {
        let errorCounter = this.state;
        this.setState({
            lastname: event.target.value,
            lastnameValidationError: ''
        },()=>{
            // //this.isLastnameValid(this.state.lastname);
            // this.setState({
            //     validLastname: this.state.lastname.length>0?true:false
            // },()=>{
            //     if(!this.state.validLastname){
            //         this.setState({
            //             lastnameValidationError: 'Please enter surname',
            //             errorCounter: errorCounter + 1
            //         });
            //     }
            //     else{
            //         this.setState({
            //             errorCounter: 0
            //         });
            //     }
            // });
            this.validateLastname(this.state.lastname);
        });
    }

    // isFirstnameValid(event){
    //     this.setState({
    //         validFirstname: event.length>0?true:false
    //     });        

    // }

    // isLastnameValid(event){
    //     this.setState({
    //         validLastname: event.length>0?true:false
    //     });        

    // }

    isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidNumber(number){
        ///^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        const re =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        return re.test(String(number).toLowerCase());
    }

    emailChanged(event) {
        let emailValidationError = "";
        this.setState({
            email: event.target.value
        },()=>{
            if(this.state.emailasusername){
                this.setState({
                    username: this.state.email
                })
            }
        });
        if(event.target.value.trim() == ""){
            emailValidationError = "Please fill out email field";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else if(!this.isValidEmail(event.target.value)){
            emailValidationError = "Invalid email";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: false
                }
            );
        }
        else{
            emailValidationError = "";
            this.setState(
                {
                    emailValidationError: emailValidationError,
                    validEmail: true
                }
            );
        }

        if(emailValidationError){
            this.setState({emailValidationError},()=>{
                //console.log(emailValidationError)
            });
            return false;
        }
        

        return true;
        //alert(event.value)
        
    }

    EmailAsUsernameChanged(event){
        const email = this.state
        //alert("dsfgdfg");
        this.setState(initialState=>
            ({
                emailasusername: !initialState.emailasusername
            }),()=>{
                //alert(this.state.emailasusername)
                if(this.state.emailasusername){
                    this.setState({
                        username: this.state.email
                    })
                }
                else {
                    this.setState({
                        username: ''
                    })
                }
            });
        //alert(this.state.emailasusername)
        //console.log(this.state.emailasusername)   
    }

    usernameChanged(event) {
        let usernameError = "";
        if(this.state.emailasusername){
            this.setState({
                username: this.state.email
            });
        }
        else{
            this.setState({
                username: event.target.value
            });
        }
       
        // if(event.target.value.trim() == ""){
        //     usernameError = "Please fill out username field";
        //     this.setState(
        //         {usernameError: usernameError}
        //     );
        // }
        // else{
        //     usernameError = "";
        //     this.setState(
        //         {usernameError: usernameError}
        //     );
        // }

        // if(usernameError){
        //     this.setState({usernameError},()=>{console.log(usernameError)});
        //     return false;
        // }
        

        // return true;
        //alert(event.value)
    }

    phoneChanged(event) {
        //let phoneValidationError = "";
        this.setState({
            phone: event.target.value
        },()=>{
            // const {phone} = this.state
            // //if(phone.length > 0 && this.isValidNumber(phone)){
            // if(phone.length > 0){
            //     this.setState({
            //         validPhone: true,//this.state.phone.length>0?true:false         
            //         phoneValidationError: ""           
            //     }); 
            // }
            // else{                
            //     this.setState({
            //         validPhone: false,
            //         phoneValidationError: "Invalid phone number"
            //     });                
            // }
            
        });
    }
    

    mobileChanged(event) {
        this.setState({
            mobile: event.target.value
        },()=>{
            const {mobile} = this.state
            if(mobile.length > 0 && this.isValidNumber(mobile)){
                this.setState({
                    validMobile: true,
                    mobileValidationError: ""
                }); 
            }
            else{                
                this.setState({
                    validMobile: false,
                    mobileValidationError: "Invalid mobile number"
                });                
            }
            
        });
    }

    faxChanged(event) {
        this.setState({
            fax: event.target.value
        },()=>{
            const {fax} = this.state
            //if(fax.length > 0 && this.isValidNumber(fax)){
            // if(fax.length > 0){
            //     this.setState({
            //         validFax: true,
            //         mobileValidationError: ""
            //     }); 
            // }
            // else{                
            //     this.setState({
            //         validFax: false,
            //         mobileValidationError: "Invalid mobile number fax"
            //     });                
            // }
            
        });
    }

    validateFirstname(firstname)
    {
        let errorCounter = this.state;
        if(firstname.length == 0 ){
            this.setState({
                validFirstname: false,
                firstnameValidationError: 'Please enter given name',
                errorCounter: errorCounter + 1
            })   
                 
        }
        else{
            this.setState({
                validFirstname: true,
                firstnameValidationError: '',
                errorCounter: errorCounter <= 0 ? 0 : errorCounter - 1
            })    
           
        }
    }

    validateLastname(lastname)
    {
        let errorCounter = this.state;
        if(lastname.length == 0 ){
            this.setState({
                validLastname: false,
                lastnameValidationError: 'Please enter given name',
                errorCounter: errorCounter + 1
            })   
                 
        }
        else{
            this.setState({
                validLastname: true,
                lastnameValidationError: '',
                errorCounter: errorCounter <= 0 ? 0 : errorCounter - 1
            })    
           
        }
    }

    validateInput =() => {
        this.validateFirstname(this.state.firstname);
        this.validateLastname(this.state.lastname);


        // if( this.state.firstname.length == 0 ){
        //     this.setState({
        //         validFirstname: false,
        //         firstnameValidationError: 'Please enter given name',
        //     })
        //     return;
        // }
        // if( this.state.lastname.length == 0 ){
        //     this.setState({
        //         validLastname: false,
        //         lastnameValidationError: 'Please enter surname',
        //     })
        //     return;
        // }
        // if(this.state.email.trim() == ""){
        //     emailValidationError = "Please fill out email field";
        //     this.setState(
        //         {
        //             emailValidationError: emailValidationError,
        //             validEmail: false
        //         }
        //     );
        // }
        // else if(!this.isValidEmail(event.target.value)){
        //     emailValidationError = "Invalid email";
        //     this.setState(
        //         {
        //             emailValidationError: emailValidationError,
        //             validEmail: false
        //         }
        //     );
        // }

    }

    SaveUserHandler(event){
        //alert("hello");
        // console.log("errorcount" + this.state.errorCounter);
        // event.preventDefault();
        //ADD VALIDATION HERE
        //this.validateInput();
        
        //"Email is required."
        
        //if(this.state.errorCounter > 0) {alert("there is an error"); return;}
        const isValid = true;// this.validateInput();
        if (isValid){
        
         let self = this.state;
         let jstaterestrictions = JSON.stringify(self.staterestrictions);
         let jmattertyperestrictions = JSON.stringify(self.mattertyperestrictions);
         let jseluserprivileges = JSON.stringify(self.seluserprivileges);
 
             let userinfo = {
             userid: this.state.userid,
             username: self.emailasusername == true ? self.email : self.username,
             email: self.email,
             lastname: self.lastname,
             firstname: self.firstname,
             stateid: self.stateid,
             usertypeid: self.usertypeid,
             lenderid: self.lenderid,
             mortmgrid: self.mortmgrid,
             brokerid: self.brokerid,
             relmgrid: self.relmgrid,
             notes: self.notes,
             phone: self.phone,
             mobile : self.mobile,
             fax : self.fax,
             emailasusername: self.emailasusername,
             //enabled: self.enabled,
             enabled: self.userStatus=='active'?true:false,
             hasstaterestriction: self.hasstaterestriction,
             //staterestrictions: self.staterestrictions,
             staterestrictions : jstaterestrictions,
             hasmattertyperestriction : self.hasmattertyperestriction,
             //mattertyperestrictions : self.mattertyperestrictions,
             mattertyperestrictions : jmattertyperestrictions,
             userprivileges : jseluserprivileges,//self.seluserprivileges,//self.userprivileges,
             //noteTypeId: this.state.isPrivateNote ? 9 : null,
             };
 
             UserLender.CreateUpdateLenderUser(userinfo).then((resp)=>{
                 //alert(this.state.userid+ " " +resp.data.UserTypeId)
                 //console.log(resp)
                 if((this.state.userid!= null || this.state.userid.trim() != "")  && this.state.userid == resp.data.UserTypeId) {
                     alert("User successfully updated!");
                    //  this.setState({
                    //     userCreated: true,
                    //     userid: resp.data.UserTypeId,
                    // });
                 }
                 else{
                    alert("User successfully added!");                    
                    //window.open("/userdetails:" + resp.data.UserTypeId);
                    //window.close();
                    this.setState({
                        userCreated: true,
                        userid: resp.data.UserTypeId,
                    });
                }
                 //alert(resp.data.UserTypeId);                
                //  window.open("/userdetails:" + resp.data.userid);
                //  window.close();
             },(err)=>{
                 //alert(err);
                 alert("There is an error with the request");
             });
            // UserLender.CreateUpdateLenderUser(userinfo).then()
         }
         
     }

     handleStatusCallback(child){
        //alert(child);
        this.setState({
            userStatus: child
        });
        // this.props.callBack(child, this.state.desktopMenu);
    }

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    } 

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }





    render() { 
        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }

        if(this.state.userCreated){
            //return(<Redirect to = '/admincontrol'  />)
            //return(<Redirect to = '/userdetails:'{this.state.userid} />)
            window.location.href='/userdetails:'+this.state.userid
        }

        if(this.state.backtoUserAdmin == true){
            return(<Redirect to = '/admincontrol'  />)
        }
        // if(this.state.leftContent != this.state.pageTitle){
        //     return( 
        //         <DisplayLeftMenu 
        //             page={this.state.leftContent} 
        //             desktopMenu={this.state.desktopMenu} 
        //         />
        //     )
        // }
        if(this.state.leftContent != this.state.pageTitle){
            return( <DisplayLeftMenu page={this.state.leftContent}></DisplayLeftMenu>)
        }

        let StateRestictionChekboxes, MatterTypeRestictionChekboxes, DisplayUsername ;
        
        StateRestictionChekboxes= (       
            <div>
                {
                    this.state.reststates.map((rstates) => {
                        return (<div className="dvStates"><CheckBox className="chk" handleCheckChildElement={this.handleCheckChildElement}  {...rstates} /></div>)
                    })
                }                
            </div>
        );           
       
        MatterTypeRestictionChekboxes= (       
            <div>
                {
                    this.state.matterTypes.map((types) => {
                        return (<div className="dvMatterTypes"><CheckBox className="chk" handleCheckChildElement={this.handleCheckTypeChildElement}  {...types} /></div>)
                    })
                }                
            </div>
        );
         

        return ( 
             
            // <LeftMenuContent id="UserDetailPage" page="userdetail" callBack = {this.handleLeftMenuCallback}>  
            <LeftMenuContent
                id="UserDetailPage"
                page="admincontrol"
                desktopMenu={this.state.desktopMenu}
                callBack = {this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
                    
                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />
                    

                    <div className="content"> 

                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>

                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 

                        </div> */}


                    
                        <BackBar onClick={this.backToUserAdmin}>back to Admin Control</BackBar>
                        
                        <div className="FormsBox">  

                            <div className="spacingBottomLarge"> 
                                
                                <TitleSegment>{!this.state.userid?'':this.state.firstname + " " + this.state.lastname}</TitleSegment> 

                                <ButtonGroupCapsule highlighted={this.state.userStatus} callback={this.handleStatusCallback} />                     

                                <Card>
                                    <h5 className="listContentTitle">Personal Details</h5>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Name
                                        </div>
                                        <div className="multiColsInputBox">
                                            {/* <input type="text" placeholder="Given Name" /> */}
                                            <input type="text" className={this.state.validFirstname==true?'successTextBox':this.state.firstnameValidationError?'errorTextBox':this.state.className} placeholder="Given Name" onChange={this.firstnameChanged} value={this.state.firstname} />                        
                                            {/* <input type="text" placeholder="Surname" /> */}
                                            <input type="text" className={this.state.validLastname==true?'successTextBox':this.state.lastnameValidationError?'errorTextBox':this.state.className} placeholder="Surname" onChange={this.lastnameChanged}  value={this.state.lastname} />
                                        </div>
                                        <div className="multiColsInputBox">                                          
                                            <ValidationText>{this.state.firstnameValidationError}</ValidationText>
                                            <ValidationText>{this.state.lastnameValidationError}</ValidationText>
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Email
                                        </div>
                                        <div>  
                                            {/* <input type="text" placeholder="example@email.com" /> */}
                                            <FormTextBox type="text" className={this.state.validEmail==true?'successTextBox':this.state.emailValidationError?"errorTextBox" :"formTextBox"} placeholder="example@email.com" onChange={this.emailChanged} value={this.state.email}  />
                                            <ValidationText>{this.state.emailValidationError}</ValidationText>
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Username
                                        </div>
                                        <div className="multiColsInputBox">
                                            {/* <input type="text" placeholder="Enter chosen username" /> */}
                                            <input type="text" className="formTextBox" placeholder={this.state.username?this.state.username:"Enter chosen username"} onChange={this.usernameChanged} value={this.state.username} disabled={this.state.emailasusername}/>
                                            {/* <FormCheckBox 
                                                title="Use email as the username"
                                            />  */}
                                            {/* <FormCheckBox handleCheckChildElement={this.EmailAsUsernameChanged} checked={this.state.emailasusername} title=" Use email as the username" /> */}
                                            <CheckBox handleCheckChildElement={this.EmailAsUsernameChanged} isChecked={this.state.emailasusername} value=" Use email as the username" />
                                        </div>
                                    </div> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Contact Information
                                        </div>
                                        {/* <div className="multiRowsInputBox">
                                            <input type="text" placeholder="Phone" />
                                            <input type="text" placeholder="Mobile" />
                                            <input type="text" placeholder="Fax" />
                                        </div> */}
                                        
                                        <div className="row">
                                            <div className="column">
                                                {/* <input type="text" className={this.state.validPhone?"successTextBox" :"formTextBox"} placeholder="Phone" onChange={this.phoneChanged} /> */}
                                                <input type="text" className={this.state.validPhone==true?'successTextBox':this.state.phoneValidationError?'errorTextBox':this.state.className} placeholder="Phone" onChange={this.phoneChanged} value={this.state.phone}/>
                                                <ValidationText>{this.state.phoneValidationError}</ValidationText>                                                
                                            </div>
                                            <div className="column">
                                                <input type="text" className={this.state.validMobile==true?'successTextBox':this.state.mobileValidationError?'errorTextBox':this.state.className} placeholder="Mobile" onChange={this.mobileChanged} value={this.state.mobile}/>
                                                <ValidationText>{this.state.mobileValidationError}</ValidationText>
                                            </div>
                                        </div>  
                                        <div className="row">
                                            <div className="column">
                                                <input type="text" className={this.state.validFax==true?'successTextBox':this.state.faxValidationError?'errorTextBox':this.state.className} placeholder="Fax" onChange={this.faxChanged} value={this.state.fax}/>
                                                <ValidationText>{this.state.faxValidationError}</ValidationText>
                                            </div>
                                            <div className="column">                       
                                            </div>
                                        </div>   
                                    </div>  
                                    <div className="spacingBottomLarge">
                                       <div className="inputLabel">
                                            Time Zone
                                        </div>
                                       
                                       <div>
                                            <DropDownList options={this.state.stateView} value={this.state.ustate} onChange={this.stateChanged} selectedoption={this.state.ustate} />
                                        </div> 
                                    </div>

                                    <h5 className="listContentTitle">User Controls</h5> 
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Select the states this user can access:
                                        </div>
                                        <div>
                                            <div style={{padding:"5px"}}>
                                                
                                                {
                                                    <CheckBox handleCheckChildElement={this.handleAllStateChecked}  isChecked={this.state.checkedAll} value="All states"  />
                                                }
                                            </div>
                                            <div>
                                                {StateRestictionChekboxes}
                                            </div>
                                            
                                            
                                        </div>
                                    </div> 
                                    <div style={{clear:"both"}}></div>
                                    <div>&nbsp;</div>
                                    <div className="inputBox">
                                        <div className="inputLabel">
                                            Choose access areas:
                                        </div>
                                        <div style={{padding:"5px"}}>
                                            {<CheckBox handleCheckChildElement={this.handleAllTypesChecked}  isChecked={this.state.checkedAllType} name="checkedAllType" value="All matters"  />}
                                        </div>
                                        <div>
                                            {MatterTypeRestictionChekboxes}
                                            {/* {
                                                this.state.userprivileges.map((privs) => {                                   
                                                return (<CheckBox handleCheckChildElement={this.handleCheckPrivElement}  {...privs} />)
                                                })
                                            } */}
                                        </div>
                                        
                                    </div> 
                                    <div>
                                        <div className="inputLabel">
                                            Choose user permissions:
                                        </div>
                                        <div>
                                        {
                                            this.state.userprivileges.map((privs) => {                                   
                                            return ( <div className="dvPermissionTypes"><CheckBox className="chk" handleCheckChildElement={this.handleCheckPrivElement}  {...privs} /></div>)
                                            //return (<FormCheckBox handleCheckChildElement={this.handleCheckPrivElement} id={privs.id} title={privs.value} />)
                                            })
                                        }
                                            
                                        </div>
                                    </div>    
                                </Card>
                            </div> 
 

                            <div>
                                <Button addCSS="mainBtn"  clickFunction={this.SaveUserHandler}>Save changes</Button>
                                <Button addCSS="secondaryBTN mainBtn" clickFunction={this.backToUserAdmin}>Cancel</Button>
                            </div>


                        </div> 
                        
                        
                         

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
UserDetailPage=AutoLogout(UserDetailPage);
export default UserDetailPage
