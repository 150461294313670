import { AuthService } from "./auth-service";
import WebApiService from "./web-api-service";

class matterOutstandingService extends WebApiService {
  getMatterOutstandings(matterId) {
    return new Promise((resolve, reject) => {
      const url = "api/getMatterOutstandings";
      const options = {
        headers: {
          Authorization: AuthService.token,
          matterId: matterId,
          
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  uploadDocument(matterId, files, fileName, OutstandingItemName) {
    return new Promise((resolve, reject) => {
      const url = "api/uploadMatterDocuments";
      const data = new FormData();
      files.forEach(element => {
        data.append("file", element);
      });

      //   data.append("matterId", matterId);
      //   data.append(fileName, fileImage);

      const options = {
        headers: {
          'Authorization': AuthService.token,
          'fileName':fileName,
          "matterId": matterId,
          "OutstandingName":OutstandingItemName,
          //   ,          files: files
        }
      };

      super.specialUpload(url, data, options).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  isGeneralDocumentUploaded(matterId) {
    return new Promise((resolve, reject) => {
      const url = "api/isGeneralDocumentUploaded";
      const options = {
        headers: {
          Authorization: AuthService.token,
          matterId: matterId,
          
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  isOutstandingRequirementUploaded(matterId, outstandingItem) {
    return new Promise((resolve, reject) => {
      const url = "api/isOutstandingRequirementUploaded";
      const options = {
        headers: {
          Authorization: AuthService.token,
          matterId: matterId,
          outstandingItem: outstandingItem,
          
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  isMatterOutstanding(matterId) {
    return new Promise((resolve, reject) => {
      const url = "api/isMatterOutstanding";
      const options = {
        headers: {
          Authorization: AuthService.token,
          matterId: matterId,          
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  UserCannotUploadOutstandingItems(){
    return new Promise((resolve,reject) =>{
        const url ="api/CannotUploadOutstandingItems";
        const options={
            "headers":{
                'Authorization':AuthService.token,
            }
        }

        super.get(url,options).then(
            (response)=>{
                resolve(response);
            },
            (err)=>{
                reject(err);
            }
        )
    }
    )
}

}

export const MatterOutstandingService = new matterOutstandingService();
