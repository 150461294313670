import React, { Component } from 'react' 

import Button from '../../atom/Button/Button';
import IconBox from '../../atom/IconBox/IconBox';

import './css/FileUploadBox.css';

class FileUploadBox extends Component {
    render() {
        return (
            <div className="FileUploadBox">
                <div className="FUBTitle">{this.props.title}</div>
                <div className="FUBContent">
                    <div className="FUBIcon">
                        <IconBox 
                            iconURL="../../../../images/icons/OutlineGrey/Upload.svg"
                        />
                    </div>
                    <div className="FUBDescription">
                        <div>
                            <div>Drag & drop file here</div>
                            <div>File format: PDF, PBG, JPG</div>
                        </div>
                    </div>
                    <div className="FUBButton">
                        <Button addCSS="tertiaryBTN">Browse</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default FileUploadBox
