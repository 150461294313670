import React, { Component } from 'react'

import './css/Button.css';

class Button extends Component {
    render() {
        return (
            // <div className={"btnRegular " + this.props.addCSS }>{this.props.children}</div>
            <>
            <div 
                key="{this.props.id}"
                id={this.props.id}
                className={this.props.className?this.props.className:"btnRegular " + this.props.addCSS }
                onClick={this.props.clickFunction}
            >
                {this.props.children}
            </div>
            </>
        )
    }
}

export default Button
