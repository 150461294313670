import { AuthService }                                      from './auth-service';
import WebApiService                                        from './web-api-service';

class dashboardService extends WebApiService {

    getFilterCount() {

        return new Promise((resolve, reject) => {

            const url = "api/getQuickFilterCount";
            const options = {
                "headers": {
                    Authorization: AuthService.token,

                }
            };

            super.get(url, options).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getFilterCountDischarges() {

        return new Promise((resolve, reject) => {

            const url = "api/getQuickFilterCountDischarges";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                }
            };

            super.get(url, options).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getFilterCountConsents() {

        return new Promise((resolve, reject) => {

            const url = "api/getQuickFilterCountConsents";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                }
            };

            super.get(url, options).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getDashboardRefreshInterval() {

        return new Promise((resolve, reject) => {

            const url = "api/getDashboardRefreshInterval";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getNextDashboardNotice(noticeId) {

        return new Promise((resolve, reject) => {

            const url = "api/getNextDashboardNotice";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'noticeId': noticeId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
}

export const DashboardService = new dashboardService();
