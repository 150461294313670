import React, { Component } from 'react'

 
import Button from '../../atom/Button/Button';

import './css/TabButtonBox.css';

class TabButtonBox extends Component {

    constructor(props){
        super(props)
        
        this.state={
            tbbActive:null
            // tbbActive:this.props.tbbinit["activeTarget"]
        }
    }

    // updatetbbActive(){
    //     this.setState({
    //         tbbActive:$activeBTN
    //     })
    // }

    // componentDidMount(){
    //     
    // } 
    
    render() {
        //console.log("tbbActive: "++" "+); 
  
        var $btnProps=this.props.tbbinit["buttons"]; 
        const $btnList=$btnProps.map(($btnProps, $index)=>  
        
            <Button
                key={$index}
                id={$btnProps.id}
                addCSS={((this.state.tbbActive==null&&$btnProps.status==1)||this.state.tbbActive==$btnProps.target?"TabButtonSelected":"TabButton")+" "+this.props.tbbinit["addcss"]+" "+$btnProps.addcss}    

                clickFunction={()=>this.props.tbbinit["action"]($btnProps.target)}
            >
            {/* {this.state.tbbActive}-
            {$btnProps.target}- */}
                {$btnProps.label}   
            </Button>
        );

        return (
            <div className="TabButtonBox" >
                <div>{this.props.tbbinit["title"]}</div>
                <div className="tbbContent">  
                    { $btnList }
                </div>
            </div>
        )
    }
}

export default TabButtonBox
