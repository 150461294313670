import React, { Component } from 'react'

import './css/TitleSegment.css';

class TitleSegment extends Component {
    render() {
        return (
            <h4 className={"TitleSegment " +this.props.addCSS}>{this.props.children}</h4> 
        )
    }
}

export default TitleSegment
