// import React from 'react';
// import ReactDOM from 'react-dom'; 
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import {BrowserRouter as Router} from 'react-router-dom';

import React                            from 'react';
import ReactDOM                         from 'react-dom';
import { BrowserRouter as Router }      from "react-router-dom";
import App                              from './App';
import reportWebVitals from './reportWebVitals';
//import RegisterServiceWorker            from './registerServiceWorker';

ReactDOM.render(
   // <React.StrictMode>
   <Router>
   <App />
 </Router>,
   
 //</React.StrictMode>,
  document.getElementById('root')
);
 
reportWebVitals();
//RegisterServiceWorker();