
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

class DisplayLeftMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            leftContent : this.props.page,
        }
    }

    componentDidMount(){
        //alert(this.props.desktopMenu);
        //alert(this.props.lmwInstructMSASub)
    }

    render(){
        
        return(
            <Redirect to = {{
                pathname: this.state.leftContent,
                state: {
                    desktopMenu: this.props.desktopMenu,
                    lmwInstructMSASub: this.props.lmwInstructMSASub
                }
            }}  />
        )
    }

}
export default DisplayLeftMenu