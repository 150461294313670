import React, { Component } from 'react'

import './css/ButtonGroupDischarges.css';

class ButtonGroupDischarges extends Component {
    render() {
        return (
            // <div className="loginGroup" id={this.props.id} >
            <div className={this.props.className?this.props.className:"DischargesGroup" + this.props.addCSS } id={this.props.id} >            
                {this.props.children}
            </div>
        )
    }
}

export default ButtonGroupDischarges
