import React, { Component } from 'react'
import RadioButton from '../../atom/RadioButon/RadioButon';

import './css/RadioButtonGroup.css';

class RadioButtonGroup extends Component {
    render() {
        return (
            <div className="dischargeRadioGroup" onChange={this.props.onChange}>
               <div>{this.props.children}</div> 
            </div>
        )
    }
}

export default RadioButtonGroup
