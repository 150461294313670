import React, { PureComponent, useState }               from 'react';
import { Redirect }                                     from "react-router-dom";
// import ProgressDisplay                                  from "../../../controls/progress-display";
import { MatterEscalateService }                        from '../../../services/matter-escalate-service';
// import { networkInterfaces }                            from 'os';
// import MSAlogo                                          from '../../../images/LogoBW.png';
import MSALogo from '../../../images/logo/MSANational.png';

//import                                                  './style/style.css';
import { AuthService } from '../../../services/auth-service';


export default class EscalateMatterForm extends React.Component{
    constructor(props){
        super(props);

        this.state={
            matterId: this.props.match.params.token.substring(1,this.props.match.params.token.length),
            matterDescription:'',
            lenderReferenceNumber:'',
            possibleEscalationReasons:[],
            selectedReason:null,
            comments:"",
            loading:true,
            shouldRedirect:false,
            response:null,
            errorResponse:null,
            isInError:false,
            isSuccessful:false,
            value:'Select Escalation Reason',
            dropDownOptions:(<div></div>),
            errorMessage: null,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
        };
        this.RequestEscalateMatter=this.RequestEscalateMatter.bind(this);
        this._onDatalistChange = this._onDatalistChange.bind(this);
        this._onCommentChange=this._onCommentChange.bind(this);
    }
    _onDatalistChange(e){
        this.setState({selectedReason:e});
    }
    _onCommentChange(e){
        this.setState({comments:e.target.value});
    }

    RequestEscalateMatter(e){
        e.preventDefault();
        this.setState({isSuccessful:false,isInError:false});
        MatterEscalateService.PostMatterEscalationRequest(this.state.matterId,this.state.selectedReason,this.state.comments).then((resp)=>
        {
            this.setState({isSuccessful:true,response:resp},()=>{
               
            });
        },(err)=>
        {
            this.setState({
                isInError:true,errorResponse:'There is an error with the request'//err
            });
        });
    }

    componentDidMount(){
        this.setState({loading:true});
        document.title = "Loantrak - Escalate:" + this.state.matterId;
        MatterEscalateService.GetMatterEscalationDetails(this.state.matterId).then(
            (resp)=>{
                this.setState({lenderReferenceNumber:resp.data.Content.LenderReferenceNumber,matterDescription:resp.data.Content.BorrowerDescription});
            },
            (err)=>{
                this.setState({shouldRedirect:true,
                    errorMessage: 'There is an error with the request',
                    loading:false},()=>{});
            }
        );
        MatterEscalateService.GetMatterEscalationReasons().then(
            resp=>{
                this.setState({possibleEscalationReasons:resp.data.Content,loading:false},()=>{
                    let options = this.state.possibleEscalationReasons.map(o=>(<option key={o.EscalationReasonTypeId} value={o.EscalationReasonTypeDescription}>{o.EscalationReasonTypeDescription}</option>))
                    //let [selectedOption, setSelectedOption] = useState(this.state.possibleEscalationReasons[0].EscalationReasonTypeDescription);
                    this.setState({dropDownOptions:(
                        <select
                        className="user-input"
                        value={options[0].value}
                        onChange={e => this._onDatalistChange(e.target.value)}>
                        {options}
                    </select>)} )
                });
            },
            err=>{
                this.setState({shouldRedirect:true,
                    errorMessage: 'there is an error with the request',
                    loading:false},()=>{});
            }
        );
    }

    render(){
        if (this.state.shouldRedirect) {
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
              }
              else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
              }
              //return <Redirect to="/" />;
          } else {
            if (this.state.loading) {
              //return <ProgressDisplay />;
              return <div></div>;
            }
            else{
                let errorMessage="";
                let successMessage="";
                let MyDropDown = (<div></div>);
                let escalateButton = (<input type="submit" onClick={(e)=>this.RequestEscalateMatter(e)}/>);
                
                if(this.state.isSuccessful){
                    successMessage=(<div>Matter Escalation Request Received. Please note an Escalation Request can only be made once.</div>);
                    escalateButton=(<div></div>);
                }

                if(this.state.isInError){
                    errorMessage=(<div>{this.state.errorResponse.message}</div>)
                }
            
                
                        
                   
               
                return(
                    <div className="escalate-card">
                      <div className="escalate-card-header">
                                <img className="msa-logo-escalate-header" src={MSALogo} alt="MSA National" />
                            </div>
                            
                        <div className="escalate-card-container">
                          
                            <form className="escalate-form">
                                <h3 >Loan Escalation Request for {this.state.matterDescription} - MSA Reference: {this.state.matterId} - Lender Reference: {this.state.lenderReferenceNumber}</h3>
                                <ul>
                                    <li className="flex form-item">
                                        <span className="submit-hint">
                                        Reason :
                                        </span>
                                        {this.state.dropDownOptions}
                                        
                                    
                                    </li>
                                    <li className="flex form-item">
                                        <span className="submit-hint">
                                            Comments
                                        </span>
                                        <textarea className="user-input" value={this.state.comments} onChange={this._onCommentChange} multiline rows="4"></textarea>
                                    </li>
                                    <li className="flex form-item">
                                        {escalateButton}
                                    </li>
                                
                                

                                </ul>
                                
                            </form>
                            {successMessage}
                            {errorMessage}
                        </div>
                    </div>)
            }
        }
    }
}