import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import Button from '../../atom/Button/Button';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card';
import HighlightText from '../../atom/HighlightText/HighlightText';
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment';

import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';
import TextIcon from '../../molecules/TextIcon/TextIcon';

import DataTableFundingRequest from '../../organism/DataTableFundingRequest/DataTableFundingRequest';
import DataTableUser from '../../organism/DataTableUser/DataTableUser';
import DataTablePaging from '../../organism/DataTablePaging/DataTablePaging';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';

import './css/FundingRequestPage.css';
import SearchBox from '../../molecules/SearchBox/SearchBox';
import { AuthService } from '../../../services/auth-service';
import { UserLender } from '../../../services/user-lender';
import AutoLogout from '../../../auto-logout';
import SearchTextIcon from '../../molecules/SearchTextIcon/SearchTextIcon';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import InsightBox from '../../molecules/InsightBox/InsightBox';
import { MatterService } from '../../../services/matter-service';
import { FundService } from '../../../services/fund-service';
import DropDownList from '../../atom/DropDownList/DropDownList';
import AllOption from "../../../models/all-option";
import DefaultOption from "../../../models/all-option";
import AnyOption from '../../../models/any-option';

class FundingRequestPage extends Component {
    constructor(props) {
        super(props);

        try {
            var dmInit = this.props.location.state.desktopMenu;
            var lmwimsInit = this.props.location.state.lmwInstructMSASub;
        } catch {
            var dmInit = "";
            var lmwimsInit = "";
        }

        this.state = {
            pageTitle: 'fundingrequest',
            leftContent: 'fundingrequest',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            lenderFundTotal: [],
            lenderFundingItems: [],
            lenderUser: '',
            lenderFundingItemsCount: [],
            isUpdated: false,
            ViewUserAction: false,
            CreateUserAction: false,
            isDisplayed: true,
            data: 'aa',
            FundFilter: '',
            SearchFilter: '',
            SearchInput: '',
            enabledCount: [],
            disabledCount: [],
            page: '',
            param: '',
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
            profileDisplay: "",
            createNewUser: false,
            isLoading: false,
            searchCount: 0,
            InsightBox: 0,

            searchFormDisplay: "",
            windowWidth: window.innerWidth,
            windowWidthPrevious: "",
            SettlementDate: "",
            srchState: null,
            srchStateName: "Any State",
            srchStatus: null,
            srchStatusName: "Any Request Status",
            format: "",
            hasSearched: false,
            MappedStates:[],
            FundingState:"",
            MappedRequestStatus:[],
            RequestStatus:"",
            searchText: "",
        }

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.lenderFundingItemsChanged = this.lenderFundingItemsChanged.bind(this);
        this.handleFilterAll = this.handleFilterAll.bind(this);
        this.handleFilterEnabled = this.handleFilterEnabled.bind(this);
        this.handleFilterDisabled = this.handleFilterDisabled.bind(this);
        this.updateState = this.updateState.bind(this);

        this.cancelChange = this.cancelChange.bind(this);
        //this.getLenderListForUser = this.getLenderListForUser.bind(this);
        this.updateSelectedPage = this.updateSelectedPage.bind(this);
        // this.getEnabledLenderListForUser = this.getEnabledLenderListForUser.bind(this);
        // this.getDisabledUserLenderList = this.getDisabledUserLenderList.bind(this);

        this.searchUser = this.searchUser.bind(this);
        this.changeSearhInput = this.changeSearhInput.bind(this);
        this.simpleUserSearch = this.simpleUserSearch.bind(this);

        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this);
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);


        this.showSearchForm = this.showSearchForm.bind(this);
        this.hideSearchForm = this.hideSearchForm.bind(this);
        this.GetStates = this.GetStates.bind(this);
        this.GetRequestStatus = this.GetRequestStatusTypes.bind(this);
        this.FundingStateUpdated = this.FundingStateUpdated.bind(this);
        this.FundingStatusUpdated = this.FundingStatusUpdated.bind(this);
        this.searchInputChanged = this.searchInputChanged.bind(this);
    }

    componentDidMount() {
        document.title = "Funding Request";

        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("FundingRequest").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }

        MatterService.CanUserViewFundingItems().then((resp) => {
            // if(resp.status==200)
            // {
            //     console.log(resp)
            //     this.setState({ CanViewFundingItems: true, shouldRedirect: false });
            // }
            //alert(resp.data.Content)
            this.setState({ CanViewFundingItems: resp.data.Content, shouldRedirect: resp.data.Content == true ? false : true });
           
        }, (err) => {
            this.setState({ CanViewFundingItems: false, shouldRedirect: true });
        });

        MatterService.CanUserViewConversation().then((resp) => {
            console.log("CanUserViewConversationResp");
            console.log(resp);
            // if(resp.status==200)
            // {
            //     console.log(resp)
            //     this.setState({ CanViewFundConversation: true, shouldRedirect: false, isLoading: true },()=>
            //     {
            //         this.getMatterDescription();
            //         this.getFundingConversations()  
            //     });
            // }
            //alert(resp.data.Content)
            this.setState({ CanViewFundConversation: resp.data.Content});
           
        }, (err) => {
            this.setState({ CanViewFundConversation: false, 
                //shouldRedirect: true 
            });
        });  


       
        //get funding items
        //this.GetMappedItems()
        this.loadLookupLists()
       
        

       
        window.addEventListener("resize", this.updateWindowDimension)

        if (this.state.windowWidth <= 768) {
            this.hideSearchForm();
        } else {
            this.showSearchForm();
        }
    }

    loadLookupLists() {
        //load various filters and list options for advanced search function
        let self = this;
    
        let p1 = FundService.GetRequestStatusTypes();
        let p2 = MatterService.getStateList();
        
        Promise.all([p1, p2]).then(values => {
          let fundingStatuses = values[0].data;
          fundingStatuses.splice(0, 0, AnyOption);
    
          let states = values[1].data;
          states.splice(0, 0, AnyOption);
    
        
    
        //  let brokers = values[6].data;
          //brokers.splice(0,0,AllOption);
          self.setState({
            //set values
            MappedRequestStatus: fundingStatuses,
            //matterType: matterTypes,
            MappedStates: states,
          
          },()=>{
              console.log(this.state.MappedRequestStatus)
              if(this.state.MappedStates!=null){
                //this.getFundingItems()
            }
          });
        });
        // if (as.shouldSearch) {
        //   this.detailedSearch(); 
        //   as.shouldSearch = false;
        // }
      }

    GetMappedItems()
    {
        this.GetStates();
        this.GetRequestStatusTypes();
        if(this.MappedStates!=null){
            this.getFundingItems()
        }
        
    }

    GetStates(){
        let respVal = [];
        
        FundService.GetStates().then((resp)=>{
            this.setState({States:resp.data.Content},()=>{
                let mapd=[];
                mapd.push(<option label="Any State"></option>);
                for(let f=0;f<this.state.States.length;f++){
                    mapd.push(<option>{this.state.States[f]}</option>);
                };

                this.setState({
                    MappedStates:mapd                  

                },()=>{
                    
                });
            });
        },
        (err)=>{
            // this.setState({

            //     shouldRedirect: true,
            //     errorMessage: 'there is an error with the request'
            // });
            
        });

    }

    GetRequestStatusTypes(){
        let respVal = [];
        
        FundService.GetRequestStatusTypes().then((resp)=>{
            this.setState({Statuses:resp.data.Content},()=>{
                console.log("statuses")
                console.log(resp.data.Content)
               
                let mapStatus=[];
                mapStatus.push(<option label="Any Request Status"></option>);
                for(let f=0;f<this.state.Statuses.length;f++){
                    mapStatus.push(<option>{this.Statuses.States[f]}</option>);
                };


                // let statuses = this.state.Statuses;
                // statuses.splice(0, 0);   
              
                
                this.setState({
                    MappedRequestStatus: mapStatus,                    
                    // MappedRequestStatus: statuses,                    

                },()=>{
                    
                });
            });
        },
        (err)=>{
            this.setState({

                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
            });
            
        });

    }

    getFundingItems()
    {
        var srchStateId = this.state.srchState;//this.state.srchState !=null? this.state.srchState.id:0;
        var srchStatusId = this.state.srchStatus;//this.state.srchStatus !=null? this.state.srchStatus.id:0;
        //alert(srchStatusId)

        //FundService.SearchFundingItems(this.state.srchState, this.state.srchStatus, this.state.SettlementDate, this.state.format).then(
            FundService.SearchFundingItems(srchStateId, srchStatusId, this.state.SettlementDate, this.state.format).then(
                resp => {
                //const data = resp.data.Content;
                //const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
                this.setState({
                    lenderFundTotal: resp.data.Content.length,
                    lenderFundingItems: resp.data.Content,//slice,//resp.data.Content,
                   // hasSearched: true,
                    isLoading: false,
                    //pageCount: Math.ceil(data.length / this.state.perPage),
                    //data: slice,//resp.data.Content,          
                },()=>{
                    console.log("lenderFundingItems")
                    console.log(this.state.lenderFundingItems)
                    //this.getLenderFundListCount();
                });
                },
                err => {
                    this.setState({
                        shouldRedirect: true
                    });
                }
            );    

    } 

   
    handleLeftMenuCallback(child, dmMode, InstructSubMenu) {
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    toggleShowHide = () => {
        this.setState(state => ({ isDisplayed: !state.isDisplayed }));
    };    

    updateSelectedPage(e) {
        //alert(e.selected)
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            //console.log(this.state.offset + " offset - perpage " + this.state.perPage),
            this.receivedData()
        });

    }

    onStartClick = (e) => {
        const selectedPage = 0;
        const offset = 0;

        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            this.receivedData()
        });

    };

    onEndClick = (e) => {
        const selectedPage = this.state.pageCount - 1;//e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            // console.log(this.state.offset + " offset - perpage " + this.state.perPage);
            this.receivedData()
        });

    };


    receivedData() {
        //alert(this.state.offset)
        const data = this.state.lenderFundTotal;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)

        this.setState({
            isLoading: false,
            pageCount: Math.ceil(data.length / this.state.perPage),
            //currentPage : 0,
            data: slice,//resp.data.Content
            lenderFundingItems: slice,
            //offset: 0,
        }, () => {
            // console.log(data)
        });
    }

    handleFilterAll(e) {
        this.getLenderListForUser();

        this.setState({
            FundFilter: 'All',
            currentPage: 0,
            offset: 0,
            pageCount: 0,
            SearchInput: ''
        })
    }

    handleFilterEnabled(e) {
        this.getEnabledLenderListForUser();

        this.setState({
            FundFilter: 'Enabled',
            currentPage: 0,
            offset: 0,
            pageCount: 0,
            SearchInput: ''
        })
    }

    handleFilterDisabled(e) {
        this.getDisabledUserLenderList()
        this.setState({
            FundFilter: 'Disabled',
            currentPage: 0,
            offset: 0,
            pageCount: 0,
            SearchInput: ''
        })
    }   

    changeSearhInput(event) {
        this.setState({
            SearchInput: event.target.value,
            //FundFilter: 'Search'
        }, () => {
            //  console.log("SearchFilter: " + this.state.SearchInput)
        });
    }

    searchUser(e) {
        // if(this.state.SearchInput == "")        {
        //     this.getLenderListForUser();  
        // }
        // else{
        //this.simpleUserSearch();
        // }

        //alert(this.state.SearchInput)
        this.setState({
            FundFilter: 'Search',
            SearchFilter: this.state.SearchInput
        }, () => {
            // console.log("search param: " + this.state.SearchFilter)
        })
    }

    simpleUserSearch() {
        this.setState({
            isLoading: true,
            FundFilter: 'Search',
        })
        //alert('dsfdsf')
        //alert(this.state.SearchInput);
        if (this.state.SearchInput == '') {
            //return;
            this.setState({
                FundFilter: 'All'
            })
        }
        //let p = UserLender.simpleUserSearch(this.state.SearchInput);

        UserLender.simpleUserSearch(
            this.state.SearchInput
        ).then(
            resp => {
                const data = resp.data.Content;
                //alert(data.length)
                //const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
                const slice = data.slice(0, 0 + this.state.perPage)
                this.setState({
                    //lenderFundingItemsCount: data.length,
                    searchCount: data.length,
                    lenderFundTotal: resp.data.Content,
                    lenderFundingItems: slice,//resp.data.Content,
                    // hasSearched: true,
                    isLoading: false,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    data: slice,//resp.data.Content,   
                    selectedPage: 0,

                    currentPage: 0,

                    offset: 0
                    //    length
                    //     perPage       
                }, () => {
                    //console.log(data)
                    //this.getUserLenderListCount();
                    //this.updateSelectedPage(this.state.selectedPage);
                });
            },
            err => {
                // this.setState({
                //     hasSearched: true,
                //     isLoading: false,
                //     errorExists: true,
                //     errorMessage: err,
                // });
            }
        );
    }
    

    lenderFundingItemsChanged(event) {
        //setter

        const target = event.target;

        this.setState({
            lenderUser: target.value
        });
    }
    updateState() {
        this.setState(state => ({ data: '!state.isDisplayed' }));
    }

    cancelChange(event) {
        event.preventDefault();
        //alert(event.target.getAttribute('id'))

        this.setState({
            ViewUserAction: true,
            CreateUserAction: false
        })
    }


    updateWindowDimension = () => {
        this.setState({
            windowWidth: window.innerWidth,
            windowWidthPrevious: window.innerWidth,
            profileDisplay: ""
        })

        if (this.state.windowWidth < 768) {
            this.hideInsightBox();
        }

        if (this.state.windowWidth <= 768) {
            this.hideSearchForm();
        } else {
            this.showSearchForm();
        }
    }

    toggleProfileMenu() {
        if (this.state.profileDisplay == "") {
            this.setState({
                profileDisplay: "display"
            });
        } else {
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox() {
        // alert("Show Insights")
        if (this.state.InsightBox == 0) {
            this.setState({
                InsightBox: 1
            });
        } else {
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox() {
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights() {
        this.handleLeftMenuCallback("insights", "", "");
        // window.open('/insights') 
    }


    showSearchForm() {
        this.setState({
            searchFormDisplay: ""
        })
    }

    hideSearchForm() {
        this.setState({
            searchFormDisplay: "none"
        })
    }

    SettlementDateUpdated(e){
        e.preventDefault();
        this.setState({SettlementDate:e.target.value, isLoading: true},
            ()=>{this.getFundingItems()});
    }

    // FundingStateUpdated(e){
    //     e.preventDefault();
    //     alert(e.target.value)
    //     this.setState({srchState:e.target.value, isLoading: true},
    //         ()=>{this.getFundingItems()});
    // }

    FundingStateUpdated(event) {
        //setter
    
        const val = event.target.value;
        var srchStateName = "";
        //alert(val)
        if(val == 1) srchStateName= "WA";
        else if(val == 2) srchStateName= "SA";
        else if(val == 3) srchStateName= "VIC";
        else if(val == 4) srchStateName= "NSW";
        else if(val == 5) srchStateName= "QLD";
        else if(val == 6) srchStateName= "TAS";
        else if(val == 7) srchStateName= "NT";
        else if(val == 8) srchStateName= "ACT";
        else srchStateName= "AnyState";

      
            const target = event.target;
            this.setState({
              srchState: val,
              srchStateName: srchStateName
            },()=>{
                this.getFundingItems()
              //alert(this.state.matterType)
            });
        
        
    
        // this.setState({
        //     srchState: val
        //   //srchState: {id:target.value}
        //   //matterType: target.value
        // },()=>{
        //   //alert(this.state.matterType)
        //   this.getFundingItems()
        // });
      }

    // FundingStatusUpdated(e){
    //     e.preventDefault();
    //     alert(e.target.value)
    //     this.setState({srchStatus:e.target.value, isLoading: true},
    //         ()=>{this.getFundingItems()});
    // }

    FundingStatusUpdated(event) {
        const val = event.target.value;
        var srchStatusName = "";
        //alert(val)

        if(val == 1) srchStatusName= "In Progess";
        else if(val == 2) srchStatusName= "Locked";
        else srchStatusName= "Any Status";
    
        this.setState({
          //srchStatus: {id:target.value}
          srchStatus: val,
          srchStatusName: srchStatusName
          //matterType: target.value
        },()=>{
            this.getFundingItems()
          //alert(this.state.matterType)
        });
    }

    searchInputChanged(event) {
        const target = event.target;
    
        this.setState({
            searchText: target.value
        });
    }   

    searchClicked(e)
    {
        e.preventDefault();        
        // alert(this.state.lenderFundingItems.length)
        //alert(this.state.searchText);
        var newRequestList = [];
        if(this.state.searchText!="")
        {
            if(this.state.lenderFundingItems.length > 0)
            {
                //alert("yen" + this.state.lenderFundingItems.length )
                var newRequestList = [];
                this.state.lenderFundingItems.forEach(elem => {
                    //alert(elem.MatterDescription)
                    if(elem.MatterDescription.toLowerCase().includes(this.state.searchText.toLowerCase()))
                    {
                        newRequestList.push(elem);
                        //newRequestList.push(elem);
                        // this.setState({
                        //     lenderFundingItems: []
                        // },()=>{
                        //     newRequestList.push(elem);
                        //     newRequestList.push(elem);
                        //     newRequestList.push(elem);
                        // });
                        
                    }
                      
               });
    
               this.setState({
                lenderFundingItems: newRequestList
               });
            
            }
            else{
                this.setState({
                    lenderFundingItems: []
                   });
                
            }
        }
        else{
            this.getFundingItems();
        }
       
       
    }

    render() {


        if (this.state.shouldRedirect) {
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if (localStorage.getItem("ssoTimeout") == null) {
                return <Redirect to="/error" />;
            }
            else {
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        if (this.state.leftContent != this.state.pageTitle) {
            return (
                <DisplayLeftMenu
                    page={this.state.leftContent}
                    desktopMenu={this.state.desktopMenu}
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                />
            )
        }

        let paging = '';
        let divFundDetails = "";

        if (this.state.FundFilter == 'Search') {
            if (this.state.searchCount > 0) {
                paging = (
                    <DataTablePaging
                        pageCount={this.state.pageCount}
                        currentPage={this.state.currentPage}
                        handlePageClick={this.updateSelectedPage}
                        items={this.state.offset}
                        total={this.state.lenderFundTotal.length}
                        onEndClick={this.onEndClick}
                        onStartClick={this.onStartClick}
                        perPage={this.state.perPage}
                    >

                    </DataTablePaging>
                )
            }
            else { paging = ""; }

        }
        else {
            if (this.state.lenderFundingItemsCount > 0) {
                //alert(this.state.lenderFundingItemsCount)
                paging = (
                    <DataTablePaging
                        pageCount={this.state.pageCount}
                        currentPage={this.state.currentPage}
                        handlePageClick={this.updateSelectedPage}
                        items={this.state.offset}
                        total={this.state.lenderFundTotal.length}
                        onEndClick={this.onEndClick}
                        onStartClick={this.onStartClick}
                        perPage={this.state.perPage}
                    >

                    </DataTablePaging>
                )
            }

            else { paging = ""; }
        }




        if (this.state.isLoading) {
            divFundDetails = (<Card><ProgressDisplay /></Card>);
        }
        else {
            // var LenderDetails = {""}
            divFundDetails = (
                <>
                    {
                        (this.state.lenderFundingItems.length > 0) ?
                        <Card>
                            <DataTableFundingRequest
                                FundingRequestItems={this.state.lenderFundingItems}
                                StateName={this.state.srchStateName}
                                StatusName={this.state.srchStatusName}
                                SettlementDate={this.state.SettlementDate}
                                CanUserViewConversation={this.state.CanViewFundConversation}
                               //TotalAmount={this.state.toal}
                            />
                        </Card>
                        : <Card>No record found </Card>
                    }
                </>
            )
        }

        return (

            <LeftMenuContent
                id="FundingRequestPage"
                page="fundingrequest"
                desktopMenu={this.state.desktopMenu}
                callBack={this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={() => this.showInsightBox()}
                showInsightsDetails={this.moreInsights}
            >

                <ProfileMenu
                    addCSS={this.state.profileDisplay == "display" ? "table" : "none"}
                    clickAction={() => this.toggleProfileMenu()}
                />

                <div className="content">
                    <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>



                    <div>
                        <TitleSegment addCSS="titleDesktop">Funding Request Items</TitleSegment>

                        <div className="filterBox" style={{ display: this.state.searchFormDisplay }}>

                            <IconBox
                                id="slbBTNClose"
                                title="Close Search"
                                addCSS=""
                                iconURL="../../../images/icons/OutlineDark/Cross.svg"
                                clickAction={() => this.hideSearchForm()}
                            />


                            {/* <SearchBox
                                label="Full Text Search:"
                                placeholder="Input Text"
                                value=""
                            /> */}

                            <SearchBox
                                addCSS={this.state.basicSearchDisplay}
                                label="Full Text Search:"
                                placeholder="Type in Borrower Name to search from the List"
                                onClick={(e) => this.searchClicked(e)}  searchTextChange={this.searchInputChanged} value={this.state.searchText}
                            />

                            <div>
                                <div className="inputLabel">
                                    State:
                                </div>
                                <div>
                                    <DropDownList options={this.state.MappedStates} value={this.state.MappedStates} onChange={this.FundingStateUpdated}></DropDownList>                                        
                                </div>
                            </div>
                            <div>
                                <div className="inputLabel">
                                    Lender:
                                </div>
                                <div>
                                    <select class="dropdownlist">
                                        <option value="0" id="0" selected>AMP Limited</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div className="inputLabel">
                                    Request Status:
                                </div>
                                <div>
                                    <DropDownList options={this.state.MappedRequestStatus} value={this.state.MappedRequestStatus} onChange={this.FundingStatusUpdated} >
                                    </DropDownList>             
                                </div>
                            </div>
                            <div>
                                <div className="inputLabel">
                                    Settlement Date:
                                </div>
                                <div>
                                    {/* <input
                                        type="text"
                                        name=""
                                        placeholder="09/09/2019"
                                        value={this.state.SettlementDate} onChange={this.lenderReferenceChanged} 
                                        autoFocus
                                    /> */}
                                    <div className="dateBox">
                                        <input type="date" id='SettlementDate'  onBlur={(e)=>{e.preventDefault();let parts= e.target.value.split('/'); let d = new Date(e.target.value); let today = new Date();today.setHours(0);today.setMinutes(0);today.setMilliseconds(0);today.setMinutes(0);today.setSeconds(0);}} value={this.state.SettlementDate} onChange={(e)=>{e.preventDefault();this.SettlementDateUpdated(e)}} placeholder="DD/MM/YYY"   autoFocus required/>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div>
                            <Button
                                addCSS="btnSearch secondaryBTN TextIconCenter "
                                clickFunction={() => this.showSearchForm()}
                            >
                                <TextIcon
                                    tiText="Search Funding Request"
                                    iconURL="../../../images/icons/OutlineDark/Search.svg"
                                />
                            </Button>
                        </div>


                        <h6>{this.state.srchState!=null?this.state.srchStateName: "Any State" } {" - " + "AMP Limited -"} {this.state.srchStatus!=null?this.state.srchStatusName:"Any Request Status"} {" - "} { this.state.SettlementDate!=""? this.state.SettlementDate:"No Settlement Date"} </h6>
                        
                        <Card>
                            {divFundDetails}
                            {/* <DataTableFundingRequest /> */}
                        </Card>

                        {/* {this.state.lenderFundingItems.length >0 ?
                            <div className="bgrBox"><ButtonIcon
                                id="btnGenReport"
                                addCSS="ButtonIconSelected biRight mainBtn"
                                iconURL="../../../../images/icons/OutlineWhite/Upload.svg"
                            >Generate Report</ButtonIcon> </div>
                        :""}            */}
                        



                    </div>




                </div>



            </LeftMenuContent>
        )
    }
}
FundingRequestPage = AutoLogout(FundingRequestPage);
export default FundingRequestPage
