import React from 'react';
import './css/CheckBox.css'

export const CheckBox = props => {
    return (
    <div className={props.className?props.className : "chk"}>
        <input  id={props.id} onClick={props.handleCheckChildElement} type="checkbox" checked={props.isChecked} value={props.value} /> {props.value}
    </div>
       
      
    )
}

export default CheckBox
