import React, { Component } from 'react' 
import { Redirect } from 'react-router-dom';
import Dropzone   from 'react-dropzone';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import IconBox from '../../atom/IconBox/IconBox';
import FormTextArea from '../../atom/FormTextArea/FormTextArea';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import Line from '../../atom/Line/Line';
import MessageBox from '../../atom/MessageBox/MessageBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import BackBar from '../../molecules/BackBar/BackBar'; 
import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';
import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox'; 


import Accordion from '../../organism/Accordion/Accordion'; 
import DataTableDocuments from '../../organism/DataTableDocuments/DataTableDocuments';
import CommunicationContent from '../../organism/CommunicationContent/CommunicationContent';
import CommunicationForm from '../../organism/CommunicationForm/CommunicationForm';
import LoanDetails from '../../organism/LoanDetails/LoanDetails';
import ProgressStep from '../../organism/ProgressStep/ProgressStep';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/NewLoansInstructionsPage.css';   
import { AuthService } from '../../../services/auth-service';
import { InstructionService } from '../../../services/instruction-service';
import { MatterNoteService } from '../../../services/matter-note-service';
import { FilePond } from 'filepond';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import AutoLogout from '../../../auto-logout';
import InsightBox from '../../molecules/InsightBox/InsightBox';


class NewLoansInstructionsPage extends Component { 
    
    constructor(props){
        super(props) 

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }
        let lendername= AuthService.getLendername();
        this.state={ 
            pageTitle : 'newloansinstructions',
            leftContent : 'newloansinstructions',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            isInstructPriv: false,
            CanInstructNewLoans: false,
            CanInstructDischarges: false,
            CanProvideDischargePayout: false,
            CanInstructDischargeUpload: false,
            isLoading:false,           
            successfulSubmit:false,
            InstructionSubmit:false,
            LenderFile: '',
            LenderReference:'',
            base64: '',
            matterId: null,
            LenderFiles:[],
            MatterNote:{},
            msg:'',
            rework: false,
            profileDisplay: "",
            SupportingFiles: [],
            LenderName: lendername,
            InsightBox:0, 
            CanAutoViewMatterOnUpload: false,
        }
    

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.lenderReferenceChanged = this.lenderReferenceChanged.bind(this);
        this.convertToBase64 = this.convertToBase64.bind(this);
        this.submitInstruction = this.submitInstruction.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    } 

    componentDidMount(){
        document.title='Loantrak - New Loans Instruction';
        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("new loans instruction").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }

        AuthService.isUserAuthorisedToInstruct().then((resp)=>{
            this.setState({isInstructPriv:true});
        },(err)=>{
            this.setState({
                isInstructPriv:false,
                shouldRedirect:true
            });
        });

        AuthService.CanAutoViewMatterOnUpload().then(
            (resp)=>{
              this.setState({CanAutoViewMatterOnUpload:resp.data.Content})
            },
            (err)=>{
              this.setState({CanAutoViewMatterOnUpload:false})
            }
          );

        AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({
                CanInstructDischarges:resp.data.Content.CanInstructDischarges,
                CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
                CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
                CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
                shouldRedirect: !resp.data.Content.CanInstructNewLoans,
            },()=>{
                console.log(this.state.CanInstructNewLoans)
            });
        },(err)=>{
            console.log("there is an error")
            //this.setState({isInstructPriv:false});
        })

        window.addEventListener("resize", this.updateWindowDimension)
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    lenderReferenceChanged(e){
        this.setState({
            LenderReference: e.target.value
        })
    }

    convertToBase64(file){
        console.log(file);
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            if(file.name.toLowerCase().includes('.xml')){
                reader.readAsText(file);
            }else{
                reader.readAsDataURL(file);
            }
            
            reader.onload = () => resolve(file.name.toLowerCase().includes('.xml')?btoa(reader.result):reader.result);
            reader.onerror = error => reject(error);
          });
        
    }    

    handleOnDrop = (files, rejectedfiles) =>{    
        let instruction={};
        let instrFiles = this.state.LenderFiles;
  
        if(files && files.length > 0)
        {         
            let fileData = "";   
            files.forEach((e,i)=>{         
                //const f = new Blob([files[i].name], {type: files[i].type});
                //const url = URL.createObjectURL(f);       
                
                var fileName = files[i].name;
                var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
                

                if(!regex.test(fileName) || fileName.length > 200)
                {
                    alert ("Invalid filename. Please check.");
                    return;
                }
                
                var base64 ="";
                
                this.convertToBase64(files[i]).then((resp)=>{;base64=resp;console.log(base64);
                    if(files[i].name.toLowerCase().includes('.xml'))
                    {
                        instrFiles.push({
                            'FileName':"Instruction_"+i+".xml",
                            'FileData':base64,
                            'FileUrl':"",
                        });   
                        
                        this.setState({LenderFiles:instrFiles});
                    }
                    else{

                        // alert("Hello");
                        // console.log(files[i]);
                        // console.log(files[i].name);
                        instrFiles.push({
                            'FileName':files[i].name,
                            'FileData':base64.split(',')[1],
                            'FileUrl':"",
                        });   
                        console.log(instrFiles[i]);
                        this.setState({LenderFiles:instrFiles});
                    }
              
                });    
                    
              
            }
                    
                
            );
          
        }

    }   
    
    Validate(){
        let valid=true;
        let xmlFileInside=false;
        for(let i =0; i<this.state.LenderFiles.length; i++){
            if(this.state.LenderFiles[i]['FileName'].includes('.xml')){
                xmlFileInside=true;
            }
        }
        
        if(!xmlFileInside){
            alert('No Xml provided.');
            valid=false;
        }

    }

    submitInstruction(e){        
        e.preventDefault();
        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("new loans instruction").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }
        let elem = document.getElementById('newloans-form');
        if(!elem.checkValidity()){
            elem.reportValidity();
            return;
        }
        if(this.state.LenderFiles.length == 0){
            alert("Please select files to upload.");
            return;
        }
        this.setState({
            isLoading: true,
            //InstructionSubmit:true,
        });       

        if(this.state.LenderName !=null && this.state.LenderName == "Bank of China (Australia) Ltd" || this.state.LenderName == "Bank of China (Australia) Limited")
        {
            var instruction = {};        
            instruction['LenderReference']=this.state.LenderReference;
            instruction['Files']=this.state.LenderFiles;
            console.log(instruction);

            InstructionService.SendInstructionData(instruction).then((resp)=>{
            this.setState({msg:'',
                InstructionSubmit:true,
                isLoading:false,
                matterId:resp.data.Content.MatterId,
                rework: resp.data.Content.Rework
            },()=>
            {
                if(this.state.InstructionSubmit && this.state.CanAutoViewMatterOnUpload && this.state.matterId!=null)
                {
                    // alert("BOC")
                    // alert(this.state.CanAutoViewMatterOnUpload)
                    window.open('/matter-details:'+this.state.matterId, "_self")
                }
            });            
         
            },(err)=>{
                //alert(err)
                if (err.response && err.response.status === 401) {

                    alert('You are not authorized for rework. Please contact MSA.');
                    this.setState({
                        isLoading:false,
                        //shouldRedirect: true
                    });

                }
                else if(err.toString().includes('wait'))
                {
                    //alert('Cannot rework right now. Please try again later');
                    alert(err)
                    this.setState({
                        isLoading:false,
                        //shouldRedirect: true
                    });

                } else {
                    alert('An error has occured. Please contact MSA.');
                    this.setState({isLoading:false,});
                }            
            });
        }
        else{
            let instrFiles = [];
            let supportingFiles = [];
                    
            for(let i =0; i<this.state.LenderFiles.length; i++){
                if(this.state.LenderFiles[i]['FileName'].includes('.xml')){
                    instrFiles.push(
                        //files[i]
                        {
                            'FileName':this.state.LenderFiles[i]['FileName'],
                            'FileData':this.state.LenderFiles[i]['FileData'],
                            'FileUrl':this.state.LenderFiles[i]['FileUrl']
                        }
                    ); 
    
                }
                else if(this.state.LenderFiles[i]['FileName'].includes('.json') && this.state.LenderName !=null && this.state.LenderName=='Think Tank Group Pty Ltd'){
                    instrFiles.push(
                        //files[i]
                        {
                            'FileName':this.state.LenderFiles[i]['FileName'],
                            'FileData':this.state.LenderFiles[i]['FileData'],
                            'FileUrl':this.state.LenderFiles[i]['FileUrl']
                        }
                    ); 
    
                }
                else{
                    supportingFiles.push(
                        //files[i]
                        {
                            'FileName':this.state.LenderFiles[i]['FileName'],
                            'FileData':this.state.LenderFiles[i]['FileData'],
                            'FileUrl':this.state.LenderFiles[i]['FileUrl']
                        },
                    ); 
    
                    this.setState({
                        SupportingFiles: supportingFiles
                    });
                }
            }
    
            //var arr = this.state.LenderFiles;
    
            //let instrFiles = this.state.LenderFiles;
            let files = this.state.LenderFiles;
            var x = 0;
    
            // console.log("irene");
            // console.log(files);
            // console.log(files[0]);
            // console.log("lenderfiles");
            // console.log(this.state.LenderFiles);
            // console.log("lenderfiles0");
            // console.log(this.state.LenderFiles[0]);
            var instruction = {};        
            instruction['LenderReference']=this.state.LenderReference;
            //alert(this.state.LenderFiles.length)
            // console.log("instrFiles")
            // console.log(instrFiles)
            //return;
    
            if(instrFiles!=[] && instrFiles!=null && instrFiles.length !=0)
            {
                //alert("for instruction files has been called")
                x=instrFiles.length;
                //alert(x)
                instruction['Files']=instrFiles;
                    console.log(instruction);
                    InstructionService.SendInstructionData(instruction).then((resp)=>{
                        this.setState({msg:'',
                            //InstructionSubmit:true,
                            isLoading:false,
                            matterId:resp.data.Content.MatterId,
                            rework: resp.data.Content.Rework
                        },()=>{
                            
                            if(supportingFiles!=[] && supportingFiles!=null && supportingFiles.length !=0)
                            {
                                //alert("instruct with supporting")
                                let instrSupportingFiles = supportingFiles;//this.state.SupportingFiles;
                               
                                
                                instrSupportingFiles.forEach((e,i)=>{   
                                    let sfiles =[];      
                                    x=x+1;
                                    //alert(x)                       
                            
                                    sfiles.push(
                                        instrSupportingFiles[i]
                                    );   
                                    instruction['Files']=sfiles;
                                    console.log(instruction);
                                    InstructionService.SendInstructionData(instruction).then((resp)=>{
                                        this.setState({msg:'',
                                            //InstructionSubmit:true,
                                            isLoading:false,
                                            matterId:resp.data.Content.MatterId,
                                            rework: resp.data.Content.Rework
                                        },()=>{
                                            // x=x+1;
                                            // alert(x)
                                        });
                                        
                                    
                                    },(err)=>{
                                        //alert(err)
                                        if (err.response && err.response.status === 401) {
                            
                                            alert('You are not authorized for rework. Please contact MSA.');
                                            this.setState({
                                                isLoading:false,
                                                //shouldRedirect: true
                                            });
                            
                                        }
                                        else if(err.toString().includes('wait'))
                                        {
                                            //alert('Cannot rework right now. Please try again later');
                                            alert(err)
                                            this.setState({
                                                isLoading:false,
                                                //shouldRedirect: true
                                            });
                            
                                        } else {
                                            alert('An error has occured. Please contact MSA.');
                                            this.setState({isLoading:false,});
                                        }            
                                    });
                                },()=>{
                                    // this.setState({
                                    //     InstructionSubmit:true,
                                    // });
                                    // 
                                });
    
                                if(x==this.state.LenderFiles.length){
                                    this.setState({
                                        InstructionSubmit:true,
                                    },()=>{
                                        // alert("b")
                                        // alert(this.state.CanAutoViewMatterOnUpload)
                                        if(this.state.InstructionSubmit && this.state.CanAutoViewMatterOnUpload && this.state.matterId!=null)
                                        {
                                            window.open('/matter-details:'+this.state.matterId, "_self")
                                        } 
                                    });
                                }
                            }    

                            // alert("a")
                            // alert(this.state.CanAutoViewMatterOnUpload)
                            if(this.state.InstructionSubmit && this.state.CanAutoViewMatterOnUpload && this.state.matterId!=null)
                            {
                                window.open('/matter-details:'+this.state.matterId, "_self")
                            } 
                               
                                                   
                        });
                        
                    
                    },(err)=>{
                        //alert(err)
                        if (err.response && err.response.status === 401) {
            
                            alert('You are not authorized for rework. Please contact MSA.');
                            this.setState({
                                isLoading:false,
                                //shouldRedirect: true
                            });
            
                        }
                        else if(err.toString().includes('wait'))
                        {
                            //alert('Cannot rework right now. Please try again later');
                            alert(err)
                            this.setState({
                                isLoading:false,
                                //shouldRedirect: true
                            });
            
                        } else {
                            alert('An error has occured. Please contact MSA.');
                            this.setState({isLoading:false,});
                        }            
                    });
    
                    if(x==this.state.LenderFiles.length){
                        this.setState({
                            InstructionSubmit:true,
                        })                           
                    }
            }
            else{
                if(supportingFiles!=[] && supportingFiles!=null && supportingFiles.length !=0)
                {
                    //alert("suport api call");
                    let instrSupportingFiles = supportingFiles;//this.state.SupportingFiles;
                    console.log("instrSupportingFiles")
                    console.log(instrSupportingFiles)
                    instrSupportingFiles.forEach((e,i)=>{   
                        x=x+1;
                        //alert(x)
                        let sfiles =[];                             
                
                        sfiles.push(
                            instrSupportingFiles[i]
                        );   
                        instruction['Files']=sfiles;
                        console.log(instruction);
                        InstructionService.SendInstructionData(instruction).then((resp)=>{
                            this.setState({msg:'',
                                //InstructionSubmit:true,
                                isLoading:false,
                                matterId:resp.data.Content.MatterId,
                                rework: resp.data.Content.Rework
                            },()=>{
                                // x=x+1;
                                // alert(x)
                                if(this.state.CanAutoViewMatterOnUpload && this.state.matterId!=null)
                                {
                                    window.open('/matter-details:'+this.state.matterId, "_self")
                                }
                                
                            });
                            
                        
                        },(err)=>{
                            //alert(err)
                            if (err.response && err.response.status === 401) {
                
                                alert('You are not authorized for rework. Please contact MSA.');
                                this.setState({
                                    isLoading:false,
                                    //shouldRedirect: true
                                });
                
                            }
                            else if(err.toString().includes('wait'))
                            {
                                //alert('Cannot rework right now. Please try again later');
                                alert(err)
                                this.setState({
                                    isLoading:false,
                                    //shouldRedirect: true
                                });
                
                            } else {
                                alert('An error has occured. Please contact MSA.');
                                this.setState({isLoading:false,});
                            }            
                        });
                    },()=>{
                        // this.setState({
                        //     InstructionSubmit:true,
                        // });
                        // if(x==this.state.LenderFiles.length){
                        //     this.setState({
                        //         InstructionSubmit:true,
                        //     });
                        // }
                    });
                    if(x==this.state.LenderFiles.length){
                        this.setState({
                            InstructionSubmit:true,
                        },()=>{
                          
                            // alert("aaaaaa")
                            // alert(this.state.CanAutoViewMatterOnUpload)
                                    
                            // if(this.state.InstructionSubmit && this.state.CanAutoViewMatterOnUpload && this.state.matterId!=null)
                            // {
                            //     window.open('/matter-details:'+this.state.matterId, "_self")
                            // } 
                        });
                    }
                }
                if(x==this.state.LenderFiles.length){
                    this.setState({
                        InstructionSubmit:true,
                    },()=>{
                        // alert("aaaaaaaaaaa")
                        // alert(this.state.CanAutoViewMatterOnUpload)
                        // if(this.state.InstructionSubmit && this.state.CanAutoViewMatterOnUpload && this.state.matterId!=null)
                        // {
                        //     window.open('/matter-details:'+this.state.matterId, "_self")
                        // } 
                    });
                }
                
            }

        }      

    }   

    updateWindowDimension=()=>{
        this.setState({ 
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }




    render() { 
        let divInstruction = (<div />);
        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        
        if(this.state.leftContent != this.state.pageTitle){
            // alert(this.state.lmwInstructMSASub)
             return( 
                 <DisplayLeftMenu 
                     page={this.state.leftContent} 
                     desktopMenu={this.state.desktopMenu} 
                     lmwInstructMSASub={this.state.lmwInstructMSASub}
                 />
             )
        }

        // if (this.state.isLoading) {            
        //     divInstruction = (
        //         <ProgressDisplay />
        //     )            
        // }

        let files = [];
        this.state.LenderFiles.forEach((v,i)=>{files.push((<li>{v['FileName']}</li>))});         

        return ( 
             
            <LeftMenuContent
                id="InstructMSAPage"
                page="newloansinstructions" 
                desktopMenu={this.state.desktopMenu} 
                callBack = {this.handleLeftMenuCallback} 
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 

                   <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    /> 

                    <div className="content"> 
                    
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>

                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>

                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 

                            </div> */}


                        
                        <div className="FormsBox">
                            
                            <div className="spacingBottomLarge">
                                <TitleSegment>New Loans</TitleSegment> 
                                <Card>
                                    {divInstruction}
                                    {this.state.matterId===null ? this.state.IsError? (<div>An error has occured. </div>): (
                                        <form id="newloans-form">
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Lender Reference
                                                </div>
                                                <div>
                                                    {/* <input type="text" placeholder="e.g. MNXXXXXXXXXXXXXXXXX" /> */}
                                                    <input type="text" className={this.state.LenderReference?'successTextBox':''} placeholder="e.g. MNXXXXXXXXXXXXXXXXX" id={'LenderRef'} value={this.state.LenderReference} onChange={this.lenderReferenceChanged} required autoComplete={false}/>
                                                </div>
                                            </div> 
                                            <div>
                                                <div className="inputLabelSub">
                                                    Drag and drop any XML files for instruction, and accompanying supporting documents. Please ensure that an Instruction XML is provided.
                                                </div>
                                                <div>
                                                    {files != "" ?  <div className="inputLabel">Uploaded:</div> : ""}
                                                    <div className="inputLabel">
                                                        {files}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="inputUploadBox">
                                                        <div className="iubContent">
                                                            <IconBox
                                                                addCSS="iubIcon"
                                                                id=""
                                                                title=""
                                                                iconURL="../../../../images/icons/OutlineGrey/Upload.svg"
                                                            />
                                                        <Dropzone onDrop={this.handleOnDrop} style={{height:"100px", width:"100%", backgroundColor:"none"}}>                                                
                                                                
                                                            
                                                                <div className="titleBox">Drag & drop file here or click to select file to upload.</div>
                                                                <div className="subText">File format: XML</div>
                                                            </Dropzone> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></form>):
                                            (<div><p><h5>{this.state.rework && this.state.InstructionSubmit?"Rework Instructions have been received by MSA National, and will be actioned shortly.":this.state.InstructionSubmit?"We have successfully recorded this new loan in our system.":""}</h5></p><p><h5><b>MSA Reference: {this.state.matterId}</b></h5></p>
                                            <p>{this.state.msg}</p><br/>
                                            </div>) }
                                </Card>
                            </div>

                            <div>
                            {this.state.InstructionSubmit && this.state.msg===''?
                                <a type="link" className="btn btn-secondary" href="/newloansinstructions">                            
                                    <ButtonIcon
                                                id=""
                                                addCSS="ButtonIconSelected biRight mainBtn"
                                                //iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                                //onClick={this.submitInstruction}
                                                >
                                    Close</ButtonIcon>
                                </a>
                                :this.state.isLoading?<ProgressDisplay/>
                                :
                                <ButtonIcon
                                    id=""
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={this.submitInstruction}>
                                Submit New Loan</ButtonIcon>
                            }
                            </div>

                        </div> 

                        
                    </div>                   

            </LeftMenuContent>  
        )
    }
}
NewLoansInstructionsPage=AutoLogout(NewLoansInstructionsPage);
export default NewLoansInstructionsPage
