import React, { Component } from 'react'

import CheckBox from '../../atom/CheckBox/CheckBox';


import './css/DataTableFundingRequest.css';
import FundDiscussionPage from '../../pages/FundDiscussionPage/FundDiscussionPage';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import { FundService } from '../../../services/fund-service';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import { AuthService } from '../../../services/auth-service';

class DataTableFundingRequest extends Component {

    constructor(props) {
        super(props);
        //alert(this.props.FundingRequestItems.length)
        this.state = {
            FundingRequestItems : this.props.FundingRequestItems,            
            MatterId: null,
            viewFundingDiscussion: false,
            SelectedFundingRequestItems:[],

        }

        this.download = this.download.bind(this);
        this.CreateBatchAndDownloadCSV = this.CreateBatchAndDownloadCSV.bind(this);

    }


    componentDidMount()
    {
        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("FundingRequestData").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }
        //alert(this.props.FundingRequestItems.length)
    }

    viewFundingDiscussions(id){
        if(!this.props.CanUserViewConversation)
        {
            alert("You do not have permission to view funding discussion");
            return;
        }
        //alert(id)
        window.open('/fundingdiscussion:' + id)
        // this.setState({
        //     viewFundingDiscussion: true,
        //     MatterId: id
        // });
    }

    SelectedItemsChanged(event, matterId)
    {
       var newRequestList = [];

        this.props.FundingRequestItems.forEach(element => {
            if(element.MatterId == matterId){
                if(event.target.checked)       
                {
                    //alert("selected")
                    this.state.SelectedFundingRequestItems.push(element)
                    console.log("this.state.SelectedFundingRequestItems")
                    console.log(this.state.SelectedFundingRequestItems)
                }    
                else{
                   // alert("de-selected")
                    var myArray = this.state.SelectedFundingRequestItems;
                    var myNewArray = myArray.filter(function(item){ 
                        //console.log("item[15]")
                        //console.log(item.MatterId)
                        return item.MatterId != matterId 
                    })  
                    console.log("myNewArray")
                    console.log(myNewArray)
                    //this.state.SelectedFundingRequestItems.push(myNewArray)
                    this.setState({
                        SelectedFundingRequestItems: myNewArray
                    },()=>{
                        console.log("this.state.SelectedFundingRequestItems")
                        console.log(this.state.SelectedFundingRequestItems)
                    })
                }     

                // console.log("newRequestList")
                // console.log(newRequestList)

                //console.log(this.state.SelectedFundingRequestItems)
                // this.state.SelectedFundingRequestItems.forEach(selectedItems=>{
                //     if()
                // });
                //newRequestList.push(element)
            }
            
        });

        // this.setState({
        //     SelectedFundingRequestItems: newRequestList
        //    },()=>{
        //     console.log("SelectedFundingRequestItems")
        //     console.log(this.state.SelectedFundingRequestItems)
        //    });
    }    

    CreateBatchAndDownloadCSV()
    {
        if(this.state.SelectedFundingRequestItems.length < 1)
        {
            alert("Please select items to create batch");
            return;
        }
        this.setState({
            isLoading:true
        });
        var fundingDetails = {};        
        //fundingDetails['MatterId']=this.state.MatterId;
        fundingDetails['SettlementDate']=this.props.SettlementDate;
        fundingDetails['FundingRequestItemData'] = this.state.SelectedFundingRequestItems;//this.state.FundingRequestItems;
        console.log(fundingDetails);

        FundService.AddFundingRequest(fundingDetails).then((resp)=>{
        this.setState({msg:'',
            isLoading:false,           
        },()=>
        {
            console.log(resp.data)
        
            let headerMessage = "";
            // for (let i = 0; i < resp.Content.Columns.length; i++) {
            //   headerMessage = headerMessage + resp.Content.Columns[i].Title + ",";
            // }
            headerMessage = headerMessage + "Matter Number" + ",";
            headerMessage = headerMessage + "Lender Reference" + ",";
            headerMessage = headerMessage + "Matter Description" + ",";
            headerMessage = headerMessage + "Lender Name" + ",";
            headerMessage = headerMessage + "MortMgr Name" + ",";
            headerMessage = headerMessage + "File Owner" + ",";
            headerMessage = headerMessage + "Matter Type" + ",";
            headerMessage = headerMessage + "Funding Amount Desc" + ",";
            headerMessage = headerMessage + "Funding Amount" + ",";
            headerMessage = headerMessage + "Funding Request Number" + ",";
            headerMessage = headerMessage + "Funding Request Status Type" + ",";
            headerMessage = headerMessage + "\n";
            let message = resp.data;//resp.Content.Matters;
            message = headerMessage + message.join("\n");
            this.download(
              //as.lender.value + " - " + as.matterStatus.value + ".csv",
              //this.state.lender==""?'':"OPMatters" + " - " + this.state.matterType + ".csv",
                "AMP Limited- " + this.props.StateName + "- "  + this.props.SettlementDate+ ".csv",
                message
            );
        });            
     
        },(err)=>{
            //alert(err)
            if (err.response && err.response.status === 401) {

                alert('You are not authorized. Please contact MSA.');
                this.setState({
                    isLoading:false,
                    //shouldRedirect: true
                });

            }
            else {
                alert('An error has occured. Please contact MSA.');
                this.setState({isLoading:false,});
            }            
        });
    }

   
    download(filename, text) {
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);
    
        element.style.display = "none";
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    }

    render() {

        let divFundingRequestItems='';

        if(this.state.viewFundingDiscussion){
            // return(<UserDetailPage
            //     userid={this.state.userid}
            //     ></UserDetailPage>)
            //return(<FundDiscussionPage MatterDescription="sdfdsfsdff" Matte></FundDiscussionPage>)
            window.location.href='/fundingdiscussion:'+this.state.MatterId
        }

        var totalAmount = 0;

        if (this.props.FundingRequestItems.length > 0){
            this.props.FundingRequestItems.forEach(element => {
                totalAmount = totalAmount + element.FundingAmount;
            });
            divFundingRequestItems = this.props.FundingRequestItems.map(d => (
                <div className="dataScrollBox">
                   
                    <div className={d.FundingRequestId!=null?"dtRow dtRowHighlight":"dtRow"} key={d.MatterId}>
                        {d.FundingRequestId!=null?
                            <div>
                                
                            </div>
                            :
                            <div>
                                {/* <CheckBox id={d.FundingRequestId} /> */}
                                <CheckBox id={d.FundingRequestId} handleCheckChildElement={(e)=>this.SelectedItemsChanged(e,d.MatterId)} isChecked={this.state.SelectedFundingRequestItems[d]} />
                            </div>
                        }                      

                        <div onClick={(e)=>{this.viewFundingDiscussions(d.MatterId, e)}}>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            {d.LenderRefNo}
                        </div>

                        <div onClick={(e)=>{this.viewFundingDiscussions(d.MatterId, e)}}>
                            <div className="dtHeaderMobile">Matter ID</div>
                            {d.MatterId}
                        </div>

                        <div onClick={(e)=>{this.viewFundingDiscussions(d.MatterId, e)}}>
                            <div className="dtHeaderMobile">Borrower</div>
                            {d.MatterDescription}
                        </div>

                        <div onClick={(e)=>{this.viewFundingDiscussions(d.MatterId, e)}}>
                            <div className="dtHeaderMobile">File Mgr</div>
                            {d.FileOwnerName}
                        </div>

                        <div onClick={(e)=>{this.viewFundingDiscussions(d.MatterId, e)}}>
                            <div className="dtHeaderMobile">Matter Type</div>
                            {d.MatterTypeStr}
                        </div>

                        <div onClick={(e)=>{this.viewFundingDiscussions(d.MatterId, e)}}>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            {d.FundingAmountDesc}
                        </div>

                        {/* <div className="dtRowRight"> */}
                        <div>
                            <div className="dtHeaderMobile">Amount</div>
                            {/* {d.FundingAmount} */}
                            ${new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,      
                            maximumFractionDigits: 2,
                            }).format(d.FundingAmount)}
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            {d.FundingRequestId}
                        </div>

                    </div>                 
                </div>

            ));
          }
        



        return (
            <>
            <div className="DataTable">

                <div className="dtHeader">
                    <div></div>
                    <div>Lender Ref</div>
                    <div>Matter ID</div>
                    <div>Borrower</div>
                    <div>File Manager</div>
                    <div>Matter Type</div>
                    <div>Funding Desc</div>
                    <div>Amount</div>
                    <div>Funding Request ID</div>
                </div>
                {divFundingRequestItems}
                {/* <div className="dataScrollBox"> */}
                   
                    {/* <div className="dtRow dtRowHighlight" key="">

                        <div>
                          
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            A123456
                        </div>

                    </div>
                    <div className="dtRow dtRowHighlight" key="">
                        <div>
                             
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            A123456
                        </div>

                    </div>
                    <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div>
                    <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div>
                    <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div> */}
                    {/* <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div>
                    <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div>
                    <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div>
                    <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div>
                    <div className="dtRow" key="">

                        <div>
                            <CheckBox id="" />
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Lender Ref</div>
                            123456
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Matter ID</div>
                            3173116
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Borrower</div>
                            Smith, John
                        </div>

                        <div>
                            <div className="dtHeaderMobile">File Mgr</div>
                            Linda Elsayed
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>
                            Refinance
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Desc</div>
                            Lender Subsidy
                        </div>

                        <div className="dtRowRight">
                            <div className="dtHeaderMobile">Amount</div>
                            $275.00
                        </div>

                        <div>
                            <div className="dtHeaderMobile">Funding Request ID</div>

                        </div>

                    </div> */}
                {/* </div> */}

                

                <div className="dtRow dtRowTotal" key="">
                    <div>Count: {this.props.FundingRequestItems.length} rows</div>
                    <div className="dtRowRight">Total: ${new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,      
                        maximumFractionDigits: 2,
                        }).format(totalAmount)}
                    </div>
                </div>

               

            </div>
             {this.props.FundingRequestItems.length >0 ?
                this.state.isLoading ? <ProgressDisplay />  :
                <div className="bgrBox"><ButtonIcon
                    id="btnGenReport"
                    addCSS="ButtonIconSelected biRight mainBtn"
                    iconURL="../../../../images/icons/OutlineWhite/Upload.svg"
                    onClick={this.CreateBatchAndDownloadCSV}
                >Create a Batch and Download</ButtonIcon> </div>
            :""}    
            </>       

        )
    }
}

export default DataTableFundingRequest
