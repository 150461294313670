import React, { Component } from 'react'
 


import Button from '../../atom/Button/Button';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import FormTextBoxIcon from '../../atom/FormTextBoxIcon/FormTextBoxIcon';
import Logo from '../../atom/Logo/Logo';
import ValidationText from '../../atom/ValidationText/ValidationText';

import DisclaimerPage from '../../pages/DisclaimerPage/DisclaimerPage';
import { Redirect }                                         from 'react-router-dom';
import {AuthService}                                  from '../../../services/auth-service';

import './css/LoginForm.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';


class LoginForm extends Component { 
    constructor(props){
        super(props);
        this.state = {            
            userName: "",
            userPassword: "",
            code: "",
            loginInProgress: false,
            errorMessage: "",
            shouldRedirect: false,
            mustAuthenticate:false,
            forgotPassword:false,
            userType:null,
            externalPrivileges: [],
            CanInstructDischargeUpload:'',
            CanInstructDischarges:'',
            CanInstructNewLoans:'',
            CanProvideDischargePayout:'',
            hasLoginError: null,
            displayError: null,
            matterRestrictions: []

        };
        this.updateUsername = this.updateUsername.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.handleAuthenticateCodeChanged = this.handleAuthenticateCodeChanged.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.Authenticate = this.Authenticate.bind(this);
        this.sendData = this.sendData.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    sendData = () =>{
        //this.props.loginFormCallback([this.state.userName, null, null, null, null]);
        this.props.loginFormCallback([this.state.userName,this.state.userPassword,this.state.userType,this.state.shouldRedirect, this.state.forgotPassword, false, this.state.mustAuthenticate]);
    }

    updateUsername(event){
        this.setState({
            userName: event.target.value
        },()=>{
            //this.props.loginFormCallback([this.state.userName, null, null, null, null]);
            this.sendData()
        });
        
    }
    updatePassword(event){
        this.setState({
            userPassword: event.target.value
        });
    }

    handleAuthenticateCodeChanged(event){
        event.preventDefault();
        this.setState({code:event.target.value});
    }

    handleKeyDown(event){ 
        //event.preventDefault();
        if(event.key==="Enter"){
           this.handleLogin(event);
        }
    }

    handleKeyDownAuthenticate(event){ 
        //event.preventDefault();
        if(event.key==="Enter"){
           this.Authenticate(event);
        }
    }


    handleLogin(event)
    {
        event.preventDefault(); //prevent function from happening on load 
        
        //this.CleanErrorAndSuccess();
        this.setState({
            loginInProgress: true,
            errorMessage: null,
            displayError: null
        },()=>{
            this.sendData()
        });

        if (!this.state.userName && !this.state.userPassword) {    //check if user and pass are entered
            this.setState({
                loginInProgress: false,                
                errorMessage: 'Please input your Email & Password', 
            },()=>{
                //alert(this.state.errorMessage);
                //this.sendData()
            });

            return;
        }else if (!this.state.userName) {    //check if user and pass are entered
            this.setState({
                loginInProgress: false,                
                errorMessage: 'Please input your Email', 
            },()=>{
                //alert(this.state.errorMessage);
                //this.sendData()
            });

            return;
        }else if(!this.state.userPassword){
            this.setState({
                loginInProgress: false,                
                errorMessage: 'Please input your Password',
            },()=>{
                //alert(this.state.errorMessage);
                //this.sendData()
            });
            return;
        }

        //delete record userverificationcode
        // AuthService.deleteBrokerCode(this.state.userName).then((resp) => {
        //     // if(window.innerWidth>768){
        //     //     window.open('/loan-details:'+this.state.matterId)
        //     //     }
        //     // if(window.innerWidth<=768){
        //     //     this.context.router.history.push(
        //     //        '/loan-details:'+this.state.matterId
        //     //        )
        //     //     }
        //     this.setState({
        //         // errorMessage: null,
        //         // userCode: null,
        //         // successMessage:null
        //     })
        //     // //after viewing successfully, add the matter to viewed matters
        //     // this.state.viewedMatters.push(this.state.matter);     
        //     // AuthService.setViewedMatters(this.state.viewedMatters);
        // },
        // (err)=>{
        //     // this.setState({
        //     //     brokershouldverify: true,
        //     //     lendershouldverify: true,
        //     //     errorMessage:'There is an error with the request.',//+err,                    
        //     // });
        //     // return;
        // });

        // AuthService.login(this.state.userName, this.state.userPassword).then((resp) =>(
        //     this.setState({                                                             //Evaluate Repsonse and login or decline based on that. 
        //         shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : (resp.IsSuccessful == 'undefined' ? resp.Successful : resp.IsSuccessful),
        //         mustAuthenticate:resp.MustAuthenticate,
        //         loginInProgress: false,
        //         forgotPassword:resp.MustRedirect,
        //         userType:resp.UserTypeId,                 
        //         errorMessage:null,
        //      },()=>{
        //         //console.log("MustRedirect"+ resp.MustRedirect + "mustAuthenticate" + resp.MustAuthenticate + "IsSuccessful"  + resp.IsSuccessful + "userType" + resp.UserTypeId)
        //         //this.props.loginFormCallback([this.state.userName,this.state.userPassword,this.state.userType,this.state.shouldRedirect, this.state.forgotPassword, false]);
        //         this.sendData()
        //     })
        // ), (err) => {
        //      this.setState({
        //      loginInProgress: false,
        //     //  userName: '',
        //     //  userPassword: '',
        //     //     mustAuthenticate:false,
        //     //     data:err,
        //     errorMessage: (err),
        //     });
        // },()=>{
        //     this.sendData()
        // },this.sendData());

        // //this.sendData()
        AuthService.login(this.state.userName, this.state.userPassword).then((resp) =>{
            //console.log("login resp")
            //console.log(resp)
            // console.log(resp.UserTypeId)
            // console.log(resp.User)
           // console.log(resp.User.UserType)
          //  console.log(resp.User.UserType.UserTypeId)
            this.setState({                                                             //Evaluate Repsonse and login or decline based on that. 
                 shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
                 mustAuthenticate:resp.MustAuthenticate,
                 loginInProgress: false,
                 forgotPassword:resp.MustRedirect,
                 userType:resp.UserTypeId==undefined?resp.User.UserType.UserTypeId:resp.UserTypeId,
                 
                 errorMessage:null,
             },()=>{
                //alert(this.state.shouldRedirect)
                //alert(this.state.mustAuthenticate)
                //alert(this.state.userType);
                //if((this.state.userType == 3 || this.state.userType == 2) && this.state.shouldRedirect && !this.state.mustAuthenticate){
                if(this.state.shouldRedirect && !this.state.mustAuthenticate){

                    if(this.state.userType == 4){
                        AuthService.getUserBrokerExternalPrivilegesList().then((resp)=>{
                            this.state.externalPrivileges.push(resp.data.Content);   
                            AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                            //set user matter type restrictions
                            AuthService.GetUserMatterTypeRestriction().then((r)=>{
                                this.state.matterRestrictions.push(r.data.Content);   
                                AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                                this.sendData()
                                },(err)=>{
                                    //alert(err)
                                    alert("There is an error with the request")
                                        this.setState({
                                        hasLoginError: true
                                        })
                                });
                            //log user activity
                            // AuthService.logUserActivity("login").then((r) =>{
                            //     alert(r.Content)
                            // });
                            
                       },(err)=>{
                           //alert(err)
                           alert("There is an error with the request")
                           this.setState({
                            hasLoginError: true
                           })
                       });
                    }
                    else{
                        AuthService.getUserExternalPrivilegesList().then((resp)=>{
                            this.state.externalPrivileges.push(resp.data.Content);   
                            AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                            AuthService.GetUserMatterTypeRestriction().then((r)=>{
                                this.state.matterRestrictions.push(r.data.Content);   
                                AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                                this.sendData()
                                },(err)=>{
                                    //alert(err)
                                    alert("There is an error with the request")
                                        this.setState({
                                        hasLoginError: true
                                        })
                                });
                            //log user activity
                            // AuthService.logUserActivity("login").then((r) =>{
                            //     alert(r.Content)
                            // });
                            //this.sendData()
                       },(err)=>{
                           //alert(err)
                           alert("There is an error with the request")
                           this.setState({
                            hasLoginError: true
                           })
                       });
                    }                     
                    
                }
                else{
                    this.sendData()
                }
                
             })
            }, (err) => {
                //alert(err)
                if(err.toString().includes('Unauthorized')){
                    this.setState({
                        loginInProgress: false,
                        mustAuthenticate:false,
                        data:err,
                        errorMessage: 'This account is network restricted.',
                        userInputClassName: this.state.userInputClassName + " input-error",
                        passwordInputClassName : this.state.passwordInputClassName + " input-error",
                        loginButtonClass: this.state.loginButtonClass+" error",
                    },()=>{setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)});
                }
                else if(err.toString().includes('SSO')){
                    this.setState({
                        loginInProgress: false,
                        mustAuthenticate:false,
                        data:err,
                        errorMessage: err,
                        userInputClassName: this.state.userInputClassName + " input-error",
                        passwordInputClassName : this.state.passwordInputClassName + " input-error",
                        loginButtonClass: this.state.loginButtonClass+" error",
                    },()=>{setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)});
                }
                else if(err.toString().includes('password was reset')){
                    this.setState({
                        loginInProgress: false,
                        mustAuthenticate:false,
                        data:err,
                        //errorMessage: 'Password for this account has been reset. <br /> Please check your inbox for the temporary password.',
                        errorMessage: err,
                        userInputClassName: this.state.userInputClassName + " input-error",
                        passwordInputClassName : this.state.passwordInputClassName + " input-error",
                        loginButtonClass: this.state.loginButtonClass+" error",
                    },()=>{setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)});
                }
                else if(err.toString().includes('disabled')){
                    this.setState({
                        loginInProgress: false,
                        mustAuthenticate:false,
                        data:err,
                        errorMessage: err,
                        userInputClassName: this.state.userInputClassName + " input-error",
                        passwordInputClassName : this.state.passwordInputClassName + " input-error",
                        loginButtonClass: this.state.loginButtonClass+" error",
                    },()=>{setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)});
                }
                else if(err.toString().includes('401')){
                    this.setState({
                        loginInProgress: false,
                        mustAuthenticate:false,
                        data:err,
                        errorMessage: 'Please check user name and password.',
                        userInputClassName: this.state.userInputClassName + " input-error",
                        passwordInputClassName : this.state.passwordInputClassName + " input-error",
                        loginButtonClass: this.state.loginButtonClass+" error",
                    },()=>{setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)});
                }
                else{
                    let error = err;
                    //alert(error);
                    this.setState({
                        loginInProgress: false,
                        mustAuthenticate:false,
                        data:err,
                        displayError: "<b>An unexpected error has occurred.</b> </br> <i> Please contact MSA support team at <a href='mailto:loantraksupport@msanational.com.au'>loantraksupport@msanational.com.au</a>.</i>",
                        userInputClassName: this.state.userInputClassName + " input-error",
                        passwordInputClassName : this.state.passwordInputClassName + " input-error",
                        loginButtonClass: this.state.loginButtonClass+" error",
                    },()=>{setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)});
                }
        });    
        
    }

    Authenticate(event){
        event.preventDefault(); //prevent function from happening on load 
        //this.CleanErrorAndSuccess();
        this.setState({
            loginInProgress: true
        });

        //if (!this.state.userName || !this.state.userPassword || !this.state.code ) {    //check if user and pass are entered
        if (!this.state.userName) {    //check if user and pass are entered
            this.setState({
                loginInProgress: false,
                // userInputClassName: this.state.userInputClassName + " input-error",
                // passwordInputClassName : this.state.passwordInputClassName + " input-error",
                errorMessage: 'Please check your username',
                // loginButtonClass: this.state.loginButtonClass+" error",
            },()=>{
                //setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)
            });

            return;
        }

        if (!this.state.userPassword) {    //check if user and pass are entered
            this.setState({
                loginInProgress: false,
                // userInputClassName: this.state.userInputClassName + " input-error",
                // passwordInputClassName : this.state.passwordInputClassName + " input-error",
                errorMessage: 'Please check your password',
                // loginButtonClass: this.state.loginButtonClass+" error",
            },()=>{
                //setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)
            });

            return;
        }

        if (!this.state.code ) {    //check if user and pass are entered
            this.setState({
                loginInProgress: false,
                // userInputClassName: this.state.userInputClassName + " input-error",
                // passwordInputClassName : this.state.passwordInputClassName + " input-error",
                errorMessage: 'Please check your security code',
                // loginButtonClass: this.state.loginButtonClass+" error",
            },()=>{
                //setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)
            });

            return;
        }

        AuthService.authenticateUser(this.state.userName, this.state.userPassword,this.state.code.trim()).then((resp) =>(
             
            this.setState({                                                             //Evaluate Repsonse and login or decline based on that. 
                 shouldRedirect: resp.MustRedirect? false : resp.IsSuccessful,
                 loginInProgress: false,
                 forgotPassword:resp.MustRedirect,
                 //userType:resp.UserTypeId,
                 userType:resp.UserTypeId==undefined?resp.User.UserType.UserTypeId:resp.UserTypeId,
                 errorMessage:null,
             },()=>{
                //this.sendData()               
               if((this.state.userType == 3 || this.state.userType == 2) && this.state.shouldRedirect){
                   AuthService.getUserExternalPrivilegesList().then((resp)=>{
                       this.state.externalPrivileges.push(resp.data.Content);   
                       AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                       //set user matter type restrictions
                        AuthService.GetUserMatterTypeRestriction().then((resp)=>{
                            this.state.matterRestrictions.push(resp.data.Content);   
                            AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                            this.sendData()
                            },(err)=>{
                                //alert(err)
                                alert("There is an error with the request")
                                    this.setState({
                                    hasLoginError: true
                                    })
                            });
                       
                  },(err)=>{
                      //alert(err)
                      alert("There is an error with the request")
                       this.setState({
                        hasLoginError: true
                       })
                  });
               }
               else if((this.state.userType == 4) && this.state.shouldRedirect){
                AuthService.getUserBrokerExternalPrivilegesList().then((resp)=>{
                    this.state.externalPrivileges.push(resp.data.Content);   
                    AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                    //set user matter type restrictions
                    AuthService.GetUserMatterTypeRestriction().then((resp)=>{
                        this.state.matterRestrictions.push(resp.data.Content);   
                        AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                        this.sendData()
                        },(err)=>{
                            //alert(err)
                            alert("There is an error with the request")
                                this.setState({
                                hasLoginError: true
                                })
                        });
                    
                },(err)=>{
                    //alert(err)
                    alert("There is an error with the request")
                        this.setState({
                        hasLoginError: true
                        })
                });
               }
               else{
                   this.sendData()
               }
               
             })
        ), (err) => {
            let error = err;
            this.setState({
                loginInProgress: false,
                data:err,
                //errorMessage: error == "Error: Request failed with status code 401"? "<b>Authentication failed.</b><br/> Unauthorized access.<br/>" : ('Please check user name, password and security code.'),
                errorMessage: 'Please check user name, password and security code.',
                // userInputClassName: this.state.userInputClassName + " input-error",
                // passwordInputClassName : this.state.passwordInputClassName + " input-error",
                // loginButtonClass: this.state.loginButtonClass+" error",
            },()=>{
                //setTimeout(()=>{this.setState({loginButtonClass: "login-button"})},1000)
            });
        });
    }

    getNewVerificationCode(e)
    {
        // if(localStorage.getItem("ssoTimeout") == null){
        //     AuthService.logUserActivity("access verification").then((r) =>{
        //         //alert(r.Content)
        //     },(err)=>{
        //         this.setState({
        //          hasError: true
        //         })
        //     });
        // }
        // this.setState({
        //     errorMessage:null,
        //     successMessage:null,           
        // });
        AuthService.resendUserAuthenticationCode(this.state.userName).then((resp) => {
            this.setState({
                //errorMessage:resp.Message,
                //successMessage:resp.Message,                
                //enterCode: true,
            })
        },
        (err)=>{
            // this.setState({
            //     errorMessage:'Invalid Details. Please check your security code and details.',//+err,
            //     resetPasswordButton:'submit-button error',
            //     resetPasswordEmailInput:this.state.resetPasswordEmailInput+" input-error",
            // },()=>{setTimeout(()=>{this.setState({resetPasswordButton: "submit-button"})},1000);});
            return;
        });
    }


    render() {
        let progressIcon = "";
       // let mustAuthenticateMsg = '';
        //let loginButton = '';
        // let mustAuthenticateCodeInput = '';

        
        if (this.state.loginInProgress) {   
            progressIcon=<ProgressDisplay/>;
        }    
        else{
            // progressIcon=(
            //     <Button onClick={ (e)=>this.handleLogin(e)}>Login</Button>
            // );
            if(this.props.mustAuthenticate){
                progressIcon=(
                   <Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={ (e)=>this.Authenticate(e)}>Login</Button>
           );
               
           }
           else{
               progressIcon=(
                <Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={ (e)=>this.handleLogin(e)}>Login</Button>
           );
           }
        }   
        // if (this.state.loginInProgress) {   
        //     return(<ProgressDisplay/>);
        // }

        if(this.props.mustAuthenticate){
            //mustAuthenticateMsg=(<DisplayMessage message="We've never seen you log in from this location below, so we've sent a code to your email to verify this login. Please check your email and provide the security code below."/>);
            //loginButton=(<Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={ (e)=>this.Authenticate(e)}>Login</Button>);
            // mustAuthenticateCodeInput=(<FormTextBoxIcon 
            //     type="input"  
            //     title="Authentication Code" 
            //     placeholder="Authentication Code" 
            //     icon={"../../../../images/interface/iconLockOutlineDark.svg"}
            //     onChange={this.handleAuthenticateCodeChanged}  
            // />);
        }
        else{
            //loginButton=(<Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={ (e)=>this.handleLogin(e)}>Login</Button>);
        }

        if(this.state.hasLoginError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        }        

       return (        
            <> 
                <Logo />
                {/* <h5>{progressIcon}</h5> */}
                {/* {mustAuthenticateMsg} */}
                {this.props.mustAuthenticate?
                    //(<DisplayMessage message="We've never seen you log in from this location below, so we've sent a code to your email to verify this login. Please check your email and provide the security code below."/>)
                    (<>
                        <DisplayMessage message="We've sent a code to your email/mobile to verify this login. Please check your inbox and provide the security code below."/>
                        <div style={{alignItems:"center",fontSize:"12px"}}>Haven't received the code or code has expired? <a onClick={(e) => this.getNewVerificationCode(e)} style={{textAlign:'center'}}>Re-send Code</a></div>
                    </>)
                    :''
                }
                <ValidationText msgeType="errorMsge">{this.state.errorMessage}</ValidationText>
                
                 
                <FormTextBoxIcon 
                    type="text"   
                    title="Your Email" 
                    placeholder="Your Email" 
                    icon={"../../../../images/interface/iconEmailOutlineDark.svg"}
                    onChange={this.updateUsername}
                    onKeyDown={ (e)=>this.handleKeyDown(e)}  
                />

                <FormTextBoxIcon 
                    type="password"  
                    title="Password" 
                    placeholder="Password" 
                    icon={"../../../../images/interface/iconLockOutlineDark.svg"}
                    onChange={this.updatePassword} 
                    onKeyDown={ (e)=>this.handleKeyDown(e)}  
                />
                {/* {mustAuthenticateCodeInput} */}
                {this.props.mustAuthenticate?
                    (<FormTextBoxIcon 
                        type="input"  
                        title="Security Code" 
                        placeholder="Security Code" 
                        icon={"../../../../images/interface/iconLockOutlineDark.svg"}
                        onChange={this.handleAuthenticateCodeChanged}  
                        onKeyDown={ (e)=>this.handleKeyDownAuthenticate(e)}  
                    />)
                    : ''
                }
                



                {/* <FormTextBox type="text" className="emailTextBox" placeholder="Your Email" onChange={this.updateUsername}  /> */}
                {/* <FormTextBox type="password"  className="passwordTextBox" placeholder="Password" onChange={this.updatePassword}/> */}

                {/* {loginButton} */}
                {/* {this.props.mustAuthenticate?(
                    <Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={ (e)=>this.Authenticate(e)}>Login</Button>
                ):(<Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={ (e)=>this.handleLogin(e)}>Login</Button>)} */}
                {/* <Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={ (e)=>this.handleLogin(e)}>Login</Button> */}
                {progressIcon}
                
                {/* <DisplayMessage message={this.props.responseMessage} /> */}
                <DisplayMessage message={this.state.displayError} />
                
            </>
        );
    }
}

export default LoginForm
