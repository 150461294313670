import React, { Component } from 'react'

 
import IconBox from '../../atom/IconBox/IconBox';

import './css/SearchTextIcon.css';

class SearchTextIcon extends Component {
    render() {
        return ( 
            <div className="TextIcon">
                {/* <div>{this.props.tiText}</div> */}
                <input type="text" placeholder={this.props.placeholder} onChange={this.props.searchTextChange} searchTextChange={this.props.searchTextChange} value={this.props.value} />              
                <IconBox 
                    iconURL={this.props.iconURL} 
                    clickAction ={this.props.onClick}
                />
            </div>
        )
    }
}

export default SearchTextIcon
