import React, { Component } from 'react'

import Button from '../../atom/Button/Button';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import IconBox from '../../atom/IconBox/IconBox';

import './css/SearchBox.css';

class SearchBox extends Component {

    constructor(props){
        super(props)  
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown(event){ 
        //event.preventDefault();
        if(event.key==="Enter"){
            this.props.onClick();
        }
    }

    render() {
        return ( 
            <div className={"SearchBox "+this.props.addCSS}> 
                <div>
                    <div className="inputLabel">
                        {/* Search for user */}
                        {this.props.label}
                    </div>
                    <div>  
                        <div className={this.props.advancedSearch==1?"multiColsInputBoxAdvance":"multiColsInputBox"}>
                            {/* <input type="text" placeholder="Enter given or surname" /> */}
                            <input 
                                type="text" 
                                placeholder={this.props.placeholder} 
                                onChange={this.props.searchTextChange} 
                                // searchTextChange={this.props.searchTextChange} 
                                value={this.props.value}
                                onKeyDown={ (e)=>this.handleKeyDown(e)}   />
                           
                           {this.props.btnType=="icon"?
                           (<ButtonIcon
                                id=""
                                addCSS="MainButtonIcon "
                                iconURL="../../../../images/icons/OutlineWhite/Search.svg"
                                onClick={this.props.onClick}
                            /> ):
                            (<ButtonIcon
                                id=""
                                addCSS="ButtonIconSelected mainBtn"
                                iconURL="../../../../images/icons/OutlineWhite/Search.svg"
                                onClick={this.props.onClick}
                            >Search</ButtonIcon>)}

                            {this.props.advancedSearch==1 && this.props.windowWidth>736?
                           (<Button
                                id=""
                                addCSS="secondaryBTN btnAdvancedSearch"
                                iconURL=""
                                clickFunction={this.props.advancedSearchAction}
                            >Advanced Search</Button> ):
                            ("")}

                            {this.props.advancedSearch==1 && this.props.windowWidth<=736?
                                (<ButtonIcon
                                    id=""
                                    addCSS="SecondaryButtonIcon btnAdvancedSearch"
                                    iconURL="../../../../images/icons/OutlineDark/Plus.svg"
                                    onClick={this.props.advancedSearchAction}
                                /> ):
                                 ("")} 
                           
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}

export default SearchBox
