import { AuthService }                                      from './auth-service';
import WebApiService                                        from './web-api-service';

class matterEventService extends WebApiService {
    
    getMatterEvents(matterId) {
        return new Promise((resolve, reject) => {

            const url = "api/getMatterProgressBar";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getMatterEventsLimited(token)
    {
        return new Promise((resolve,reject)=>{
            const url="api/getMatterProgressLimited";
            const options = {
                'headers':{
                    'Token':token
                }
            }

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
    getMatterEventsLimitedWeb(token)
    {
        return new Promise((resolve,reject)=>{
            const url="api/getMatterProgressLimitedWeb";
            const options = {
                'headers':{
                    'Token':token
                }
            }

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
    getMatterProgressBar(matterId) {

        return new Promise((resolve, reject) => {

            const url = "api/getMatterProgressBar";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get(url, options).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getMatterWFComponents(matterId) {

        return new Promise((resolve, reject) => {

            const url = "api/getMatterWFComponentsView";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
}

export const MatterEventService = new matterEventService();
