import React                                                from 'react';
import PropTypes                                            from "prop-types";
// import { DropDownList }                                     from '@progress/kendo-react-dropdowns';

// import { AuthService }                                      from '../../services/auth-service';
import { AuthService } from '../../../services/auth-service';
import DropDownList from '../../atom/DropDownList/DropDownList';

class MortManagerControl extends React.Component {
    //renders a dropdown list with the mortmgr details 
    render() {

        if (this.props.data && this.props.data.length > 0) {

            if ( AuthService.user.UserTypeId === 3 && this.props.data.length === 1 ) {
                return (this.props.data[0].value);
            }

            //return (<DropDownList className="k-dropdownlist" data={this.props.data} textField={'value'} value={this.props.value} onChange={ this.props.onChange } />);
            return (<DropDownList options={this.props.data} value={this.props.data} onChange={ this.props.onChange } />);
        }

        return ('');
    }
}

MortManagerControl.protoType = {
    value: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default MortManagerControl;