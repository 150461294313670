import React, { Component } from 'react';
import Moment from "moment";
 
import HighlightText from '../../atom/HighlightText/HighlightText'; 

 
import './css/DataTableCustody.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';


class DataTableCustody extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: false,
          hasSearched: false,
          matterNumber: null,
          lenderReference: "",
          borrowersName: "",
          securityAddress: "",
          //titleReference: null,
          titleReference: "",
          solicitor: "",
          putawayNumber: null,
          selectedMatterNumber: null,
          //matterData: [],
          pseudoHeightVal: 500,
          searchParam: '',
          searchParamId: '',
          searchParamText: '',
          searchParamList: [
            {text: 'by MSA Reference', id: 1},
            {text: 'by Lender Reference', id: 2},
            {text: 'by Borrowers Name', id: 3},
            // {text: 'by Title Reference', id: 4},
            {text: 'by Security Address', id: 4}
          ],
          searchInput: '',      
        };
    
        this.updateSelectedMatter = this.updateSelectedMatter.bind(this);
      }
    
    componentDidMount(){        
        //console.log(this.props.data);
        //console.log(this.props.matterData)
    }

    updateSelectedMatter(d, e) {
        //this.props.updateSelectedMatter(e.dataItem);
        this.props.updateSelectedMatter(d);
        this.setState({
          selectedMatterNumber: d//e.dataItem
        });
    }
    render() {
        let matterGrid;
        if (this.props.isLoading) {
            matterGrid = <ProgressDisplay />;
        }
        if (
            this.props.hasSearched &&
            this.props.matterData != [] &&
            !this.props.isLoading //&& this.props.matterData.length > 0
        )
        {         
            matterGrid = (
                this.props.data.map(d => 
                    <>   
                        <div className="dtRow" key={d.MatterId} onClick={(e)=>{this.updateSelectedMatter(d, e)}}  title={"Matter Id: " + d.OrigMatterId}>
                            <div className="dtRowSmallBold">
                                <div className="dtHeaderMobile">MSA Ref</div>
                                {/* {d.MatterId} */}
                                {/* {d.OrigMatterId} */}
                                {('0000000' + d.MatterId).slice(d.MatterId.toString().length)}
                            </div> 
                            
                            <div>
                                <div className="dtHeaderMobile">Lender Ref</div>
                                {d.LenderId}
                            </div>
                            
                            <div>
                                <div className="dtHeaderMobile">Borrowers Name</div>
                                {d.BorrowerDetails}
                            </div>

                            <div>
                                <div className="dtHeaderMobile">Settlement Date/Time</div>
                                {/* {Moment(d.SettledDateTime).format("YYYY-MM-DD")} */}
                                {d.SettledDateTime !=null ? Moment(d.SettledDateTime).format("YYYY-MM-DD") : ""}
                            </div> 

                            <div>
                                <div className="dtHeaderMobile">Securities</div>
                                {/* {d.Securities == null ? '' : d.Securities.Address}  {d.Securities == null ? '' : d.Securities.Suburb}  */}
                                {/* {d.Securities == null ? '' : d.Securities} */}
                                {d.Securities != "" ? d.Securities : d.SecurityItems!=""?d.SecurityItems : ''}
                            </div>
                            <div>
                                <div className="dtHeaderMobile">Transaction Type</div>
                                {/* {d.TransactionType} */}
                                {d.TransactionType!= "" && d.TransactionType!=null ? d.TransactionType : 'N/A'}
                            </div>
                        </div>
                    </>
                )
            );
        }
        return ( 
            <div className="DataTable">
                <div className="dtHeader">
                    <div>MSA Ref</div>
                    <div>Lender Ref</div>
                    <div>Borrowers Name</div>
                    <div>Settlement Date/Time</div>
                    <div>Securities</div>
                    <div>Transaction Type</div>
                </div>
                {matterGrid}             
 
            </div> 
        )
    }
}

export default DataTableCustody
