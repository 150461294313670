import { AuthService }                                      from './auth-service';
import WebApiService                                        from './web-api-service';

class matterNoteService extends WebApiService {
    GetShowPrivateNoteOption(){
        return new Promise((resolve,reject) =>{
            const url ="api/CanPrivateNoteBeMade";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }
    LenderCanRequestForMNP(){
        return new Promise((resolve,reject) =>{
            const url ="api/CanRequestForMNP";
            const options={
                "headers":{
                    'Authorization':AuthService.token,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            )
        }
        )
    }
    GetEscalatableStatus(matterId){
        return new Promise((resolve,reject)=>{
            const url = "api/IsMatterEscalatable";
            const options = {
                "headers":{
                    'Authorization':AuthService.token,
                    'MatterId':matterId,
                }
            }

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }

            );
        })
    }
    getMatterNotes(matterId) {

        return new Promise((resolve, reject) => {

            const url = "api/getNoteViews";
            const options = {
                "headers": {
                    'Authorization': AuthService.token,
                    'matterId': matterId
                }
            };

            super.get( url, options )
                .then((response) => {
                    resolve(response);
                }, (err) => {
                    reject(err);
                });
        });
    }

    getMatterNoteTypes() {

        return new Promise((resolve, reject) => {

            const url = "api/getNoteTypeViews";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getNoteTypeLookups() {

        return new Promise((resolve, reject) => {

            const url = "api/getNoteTypeLookups";
            const options = {
                "headers": {
                    'Authorization': AuthService.token
                }
            };

            super.get( url, options ).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    postNewNote(note) {
        //window.alert(note.noteTypeId);
        return new Promise((resolve, reject) => {

            const url = "api/postNewNote";
            let data = new FormData();
            data.append('noteTypeId',note.noteTypeId);
            data.append('matterId',note.matterId);
            data.append('noteHeader',note.noteHeader);
            data.append('noteDetail',note.noteDetail)
            super.post(url, data).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
}

export const MatterNoteService = new matterNoteService();
