import React, { Component } from 'react'

import './css/TextCapsule.css';

class TextCapsule extends Component {
    render() {
        return (  
            <div className={ "TextCapsule " + this.props.addCSS } >{this.props.children}</div>
        )
    }
}

export default TextCapsule
