import React, { Component }                                 from 'react';
import { Redirect }                                         from 'react-router-dom';
// import ProgressDisplay                                      from './controls/progress-display'
import { AuthService }                                      from './services/auth-service';
import ProgressDisplay from './components/atom/ProgressDisplay/ProgressDisplay';

class ErrorPage extends Component {

  constructor(props) {
    super(props);
    this.state=({
      loading:true,
      shouldRedirect: false,
      fromSSO: false,
      errorMessage: null,
      ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
    })
   
    this.handleBackToLogin = this.handleBackToLogin.bind(this);
  }
  componentDidMount(){
    //window.alert("Hello")    
    
    //AuthService.clearStorage();
    if(localStorage.getItem("ssoTimeout") != null){
      this.setState({fromSSO:true,
        errorMessage: 'There is an error with the request'
      });
      //alert('sso');
    }
    AuthService.logout();
    this.setState({loading:false,});
  }

  handleBackToLogin()
  {
    this.setState({shouldRedirect:true,});
  }

  render() {
    //let backtologin= '';
    // if(!this.state.fromSSO){
    //   backtologin=(<a style={{textDecorationLine:'underline'}}onClick={this.handleBackToLogin}>Back to Login Page</a>);
    // }
    if(this.state.fromSSO){
      return <Redirect to="/ssoerror" />;
      //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
    }
    if(this.state.loading){
      return (<ProgressDisplay />);
    }
    if(this.state.shouldRedirect){
      return <Redirect to="/" />;
    }
    else{
      //return ( <Redirect to='/' /> );  
      
      return(
          <div style={{margin:"auto", width:'100%', textAlign:"center", padding: "100px", marginTop: "100px"}}>
              {/* <h1>OOPS!</h1>
              <h2>Something went wrong.<br /></h2>
              <h5>Please contact MSA support if the issue persists.</h5>
              <br /><br /> */}
              <h5>Your session has expired.</h5>
              <a style={{textDecorationLine:'underline'}}onClick={this.handleBackToLogin}>Back to Login Page</a>
              {/* {backtologin} */}
          </div>
      );
    }
    
  } 
}

export default ErrorPage;
