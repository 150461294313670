import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

import DataListResult from '../../atom/DataListResult/DataListResult';
import IconBox from '../../atom/IconBox/IconBox';

import './css/DataTablePaging.css';

class DataTablePaging extends Component {
    constructor(props)
    {
        super(props)        
        this.state={
            perPage:this.props.perPage?this.props.perPage : 10,
        }
    }

    render() {
        return (
            <div className="DataTablePaging">
                
                <div className="dtpContentBox">
                    
                    <div className="dtpPage">
                        <IconBox iconURL="../../../images/icons/OutlineDark/Start.svg" clickAction={this.props.onStartClick}  />                  
                        <ReactPaginate
                            previousLabel={<IconBox iconURL="../../../images/icons/OutlineDark/Play_Back.svg"/>}//{'previous'}
                            previousClassName={'dtpPrev'}
                            nextLabel={<IconBox iconURL="../../../images/icons/OutlineDark/Play.svg" />}//{'next'}
                            nextClassName={'IconBox'}
                            breakClassName={''}
                            // breakLabel={ '...'}
                            //breakClassName={'pageLink'}//{'pageLink'}//{'break-me'}
                            pageCount={this.props.pageCount}//{1}//{this.props.pageCount}
                            forcePage={this.props.currentPage}
                            marginPagesDisplayed={1}//{2}
                            pageRangeDisplayed={2}//5
                            onPageChange={this.props.handlePageClick}
                            containerClassName={'dtpUL'}
                            activeClassName={'pageLinkSelected'}
                            pageClassName={'pageLink'}
                        />                       

                        <IconBox iconURL="../../../images/icons/OutlineDark/End.svg" clickAction={this.props.onEndClick}  />

                        {/* <div className="pglSpacer"></div> */}
                    </div>

                    {/* <DataListResult addCSS="pglResultDesktop">Showing {this.props.items+1}-{this.props.total<=10?this.props.total:this.props.items+10>=this.props.total?this.props.total:this.props.items+10} of {this.props.total} results</DataListResult> */}
                    <DataListResult addCSS="pglResultDesktop">Showing {this.props.items+1}-{this.props.total<=this.state.perPage?this.props.total:this.props.items+this.state.perPage>=this.props.total?this.props.total:this.props.items+this.state.perPage} of {this.props.total} results</DataListResult>

                    

                </div>
                <div>
                    {this.props.children}
                </div>
            </div>        
        )
    }
}

export default DataTablePaging
