import React, { Component } from 'react'

import './css/Line.css';

class Line extends Component {
    render() {
        return (
            <div className="Line"></div>
        )
    }
}

export default Line
