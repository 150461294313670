import React, { Component } from 'react';
import ReactHtmlParser                                      from 'html-react-parser';
import Moment from "moment";
import SAlert from "sweetalert2";
import { FilePond, registerPlugin } from "react-filepond";
 
import IconBox from '../../atom/IconBox/IconBox'; 

 
import './css/DataTableOutstandingItemsDetail.css';
import { AuthService } from '../../../services/auth-service';
import { MatterOutstandingService } from "../../../services/matter-outstanding-service";

import{
    isBrowser,
    isMobile
  } from "react-device-detect";
  
  import {saveAs}         from 'file-saver';
import MessageBox from '../../atom/MessageBox/MessageBox';
import Line from '../../atom/Line/Line';
import Accordion from '../Accordion/Accordion';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';
import './css/filepond.min.css';
import '../../molecules/FileUploadBox/css/FileUploadBox.css';
import DataTableDocuments from '../DataTableDocuments/DataTableDocuments';

class DataTableOutstandingItemsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matter: this.props.matterId.substring(0, 8),
            loading: true,
            modalIsOpen: false,
            outstanding: [],
            outstandingcategories: [],
            msaOutstanding: [],
            lenderOutstanding: [],
            mortgageManagerOutstanding: [],
            brokerOutstanding: [],
            borrowerOutstanding: [],
            outgoingLenderOutstanding: [],
            otherOutstanding: [],
            solicitorOutstanding: [],
            unallocatedOutstanding: [],
            shouldRedirect: false,
            showGroups: true,
            divHeight: 0,
            files: [],
            uploadSuccess: false,
            canUserGenralUpload: true,
            uploadInfo: "",
            isGeneralDocumentUploaded: false,
            errorMessage: null,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            isUnallocatedExpanded: true,
            expanded: false,

        };
        this.displayInfo = this.displayInfo.bind(this);
    }

    
    displayInfo(){
        this.setState({
          uploadInfo: "A user has uploaded a document to this item."
        })
    }
        
    componentDidMount() {

        this.displayInfo();
        
        this.setState({
            loading: true,
            outstanding: [],
        });
    
        window.addEventListener("resize", this.updateDimensions);
    
        AuthService.isUserCanGenralUpload().then((resp)=>{
            this.setState({canUserGenralUpload:true});
        },(err)=>{
            this.setState({canUserGenralUpload:false});
        });

        MatterOutstandingService.isOutstandingRequirementUploaded(this.props.matterId, this.props.note.OutstandingItemName.replace(/[^a-z0-9]/gi, '_').toLowerCase()).then((resp)=>{
            //console.log(resp);
            //alert(this.props.note.OutstandingItemName);
            let outstandingDocUploaded = resp.data.Content;
            this.setState({isGeneralDocumentUploaded:outstandingDocUploaded});
          },(err)=>{
              //this.setState({outstandingDocUploaded:false});
              this.setState({isGeneralDocumentUploaded:false});
          });
    
        
    }

    handleInit() {
   
    }
  
    handleItemUpdate(fileItems) {
      // console.log(fileItems);
      // Set current file objects to this.state
      this.setState({
        files: fileItems.map(fileItem => fileItem.file)
      },()=>{	
       // this.btnUploadFiles(this.state.files)
      });
      // console.log(this.state.files);
      //this.btnUploadFiles(this.state.files)
    }

    btnUploadFiles(fileItem) {
        // alert(fileItems);
        // return;
        if (this.state.files.length != 0) {
          // console.log("Button has been clicked!");
    
          // console.log("To be upload files");
          // console.log(
          //   this.props.matterId,
          //   this.state.files,
          //   this.props.note.OutstandingItemName
          // );
          //var safeFilename = this.props.note.OutstandingItemName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          var safeFilename = fileItem.replace(/[^a-z0-9]/gi, '_').toLowerCase();

          // alert(safeFilename)
          // return;

          var fileName = this.state.files[0].name;
          var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
          

          if(!regex.test(fileName) || fileName.length > 200)
          {
            alert ("Invalid filename. Please check.");
            return;
          }
          
    
          this.setState({ showModal: true });
          MatterOutstandingService.uploadDocument(
            this.props.matterId,
            this.state.files,
            safeFilename,
            //this.props.note.OutstandingItemName
            fileItem
          ).then(
            response => {
              if( new SAlert({
                //title: "File Upload",
                //html: "Document transferred: <br />" + response.data.Message,
                // "Document transferred: " + files[0].name + "<br />" + result.Message
                //html: "<h4>A document has been successfully uploaded.</h4> ",//<br /> Document Name: " + response.data.Message,
                html: "<h5>The document has been uploaded and will be sent to the system shortly.</h5> ",
                //onClose:()=>{this.reloadNotes()}
                didClose:()=>{this.reloadNotes()}
                //onClose:this.reloadNotes(true)
              })
            //   .then(function(){
                  
            //   }

            //   )
              ){
                this.setState({
                  files: [],
                  showModal:false,	
                  uploadSuccess: true
                });
                //this.props.Reload();
              }else{
                alert("Document has ben transferred.");
                this.setState({
                  files: [],
                  showModal:false
                });
                this.props.Reload();
              }
             
            
              
            },
            error => {
              new SAlert({
                title: "File Upload",
                html: "Document transfer FAILED.",//<br /><br />" + error.message,
                type: "error"
              });
              this.setState({ showModal: false });
    
            }
          );
        } else {
          this.pond.browse();
          this.setState({ showModal: false });
          // SAlert({
          //   title: "File Upload",
          //   html: "Please select file(s)",
          //   type: "error"
          // });
        }
    }
    
    reloadNotes(e){	
        //alert(this.state.uploadSuccess)	
       if(this.state.uploadSuccess == true)	
        //if(e == true)	
        {	
          window.location.reload();	
          //return(<DataTableDocuments></DataTableDocuments>);
        }	
    }

    render(){
        let outstandingUploadDiv= '';
        let fileUpload="";
        let genUploadDiv= '';
        let infospace=<div>&nbsp;</div>;
        let ifUploaded="uploaded"; 

        // if(this.state.isGeneralDocumentUploaded){
        //   GUUploaded="uploaded"; 
        // }
        
        fileUpload = (

            
            

            <FilePond	
                labelIdle=" 
                    <div class='GUContent'>
                        <div class='GUTextBox'>
                            <div classname='GUText1'>Drag & drop file here</div>
                            <div id='format'>File format: PDF, PBG, JPG</div>
                        </div>
                        <div id='button'> Browse</div>
                    </div>" 
                ref={ref => (this.pond = ref)}	
                files={this.state.files}	
                allowMultiple={false}	
                oninit={() => this.handleInit()}	
                onupdatefiles={fileItems => this.handleItemUpdate(fileItems)}	
                onClick={click => this.handleOnClick(click)}	
                onaddfile={()=>this.btnUploadFiles(this.props.note.OutstandingItemName)}
            />);

            if(this.state.isGeneralDocumentUploaded){
                outstandingUploadDiv=(  
                  <div className="GUBox GUBoxUploaded"> 
                      <div className="GUTitleBox">
                          <div className="GUTitle">{fileUpload}</div>
                          <div 
                              className="info" 
                            //   onMouseOver={this.displayInfo} 
                            //   onMouseLeave={()=>{this.setState({uploadInfo: ""})}}
                              >
                          </div>
                          <div className="infotip">
                              {this.state.uploadInfo == "" ? infospace : this.state.uploadInfo}
                          </div>   
                      </div> 
                  </div> 
                );
            } else {
                outstandingUploadDiv=(
                    <div>{fileUpload}</div>
                );
            }
       
            let detail = ''; 

            detail = (
              <div className="notesBody">
                  {/* {ReactHtmlParser(this.props.note.IssueDetails)} */}
                  {this.props.note.IssueDetails == null ? this.props.note.IssueDetails : ReactHtmlParser(this.props.note.IssueDetails)}
              </div>
            );  
        
            if (this.props.selected === true) {
              detail = (
                  <div className="notesBody">
                      {/* {ReactHtmlParser(this.state.outstanding.IssueDetails)} */}
                  </div>
              );   
            }
            
        return (
            <div>
                <div>{detail}</div>
                <div>
                    {outstandingUploadDiv}
                </div> 
            </div>
        );
    }
}

export default DataTableOutstandingItemsDetail
