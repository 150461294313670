import React, { Component } from 'react'

import './css/GroupBox.css';

class GroupBox extends Component {
    render() {
        return (
            <div className="GroupBox">
                <h6>{this.props.title} </h6>
                {this.props.children} 
            </div>
        )
    }
}

export default GroupBox
