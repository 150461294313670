import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Moment from "moment";
import SAlert from "sweetalert2";
import {
    isBrowser,
    isMobile
} from "react-device-detect";

import { saveAs } from 'file-saver';

import Button from '../../atom/Button/Button';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import HighlightText from '../../atom/HighlightText/HighlightText';
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import TextIcon from '../../molecules/TextIcon/TextIcon';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import DataTableCustody from '../../organism/DataTableCustody/DataTableCustody';
import DataTableUser from '../../organism/DataTableUser/DataTableUser';
import DataTablePaging from '../../organism/DataTablePaging/DataTablePaging';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent'; 

import './css/CustodyCenterPage.css';  
import SearchBox from '../../molecules/SearchBox/SearchBox';
import { CustodyService } from "../../../services/custody-service";
import MatterDetailsPage from '../MatterDetailsPage/MatterDetailsPage';
import { MatterDocumentService } from "../../../services/matter-document-service";
import SearchTextIcon from '../../molecules/SearchTextIcon/SearchTextIcon';
import { AuthService } from '../../../services/auth-service';
import AutoLogout from '../../../auto-logout';
import CustodySearchLoanBox from '../../organism/CustodySearchLoanBox/CustodySearchLoanBox';
import { assertFlowPredicate } from '@babel/types';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import InsightBox from '../../molecules/InsightBox/InsightBox';

class CustodyCenterPage extends Component {
    constructor(props){
        super(props);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        this.state = {
            pageTitle : 'custodycentre',
            leftContent : 'custodycentre',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            searchText: '',
            data: [],
            pageCount: 0,
            perPage: 10,
            currentPage: 0, 
            offset: 0,
            requestPack: false,
            requestPackModal: false,
            hasSearched: false,
            hasMatter: false,
            loading: false,
            selectedMatterData: [],
            matterDocuments: [],
            deliveryLocations: [],
            shouldClear: false,
            hasSearched: false,
            height: 1,
            width: 1,
            contLoading: false,
            matterSelectionExists: false,
            errorExists: false,
            errorMessage: "",
            matterLocation: "",
            selectedDeliveryLocation: "",
            msg:'',
            custodyDocumentRequested: false,
            docsSentOrReceived: false,
            alreadyExists: false,
            shouldRedirect: false,
            searched: false,
            profileDisplay: "",
            msgType: null, 
            // Search parameters
            address: null,
            borrowers: null,
            fileOwner: null,
            lender: null,
            lenderRefNo: null,
            matterNo: null,
            matterState: null,
            matterStatus:null,
            mortManager: null,
            matterType: null,
            titleRefNo: null,
            custodyFormDisplay: "none",
            basicSearchDisplay: "",
            windowWidth: window.innerWidth,
            windowWidthPrevious: "",
            matterIsDigital: false,
            matterNonDigitalDocuments: [],
            canDownloadCustodianDocuments: false,
            downloading: false,
            currentDocumentLocation: null,
            InsightBox:0, 
        }

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.searchClicked = this.searchClicked.bind(this);
        this.handleSearchCallback = this.handleSearchCallback.bind(this);        
        this.searchInputChanged = this.searchInputChanged.bind(this);
        this.updateSelectedPage = this.updateSelectedPage.bind(this);
        this.updateSelectedMatter = this.updateSelectedMatter.bind(this);
        this.backToSearch = this.backToSearch.bind(this);
        this.rowClicked = this.rowClicked.bind(this);
        this.handleRequestPack = this.handleRequestPack.bind(this);
        this.cancelRequestPack = this.cancelRequestPack.bind(this);
        this.sendRequestPack = this.sendRequestPack.bind(this);
        this.cancelCustodyRequest = this.cancelCustodyRequest.bind(this);
        
        this.checkIfCustodyDocumentRequested = this.checkIfCustodyDocumentRequested.bind(this);
        this.checkIfCustodyDocumentSentOrReceived = this.checkIfCustodyDocumentSentOrReceived.bind(this);
        this.checkIfMatterIsDigital = this.checkIfMatterIsDigital.bind(this);
        this.getDocumentDeliveryLocations = this.getDocumentDeliveryLocations.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.hideCustodyForm = this.hideCustodyForm.bind(this);
        this.showCustodyForm = this.showCustodyForm.bind(this);
        this.hideBasicSearch = this.hideBasicSearch.bind(this);
        this.showBasicSearch = this.showBasicSearch.bind(this);
        this.updateWindowDimension = this.updateWindowDimension.bind(this);
        this.updateSelectedDeliveryLocation = this.updateSelectedDeliveryLocation.bind(this);
        this.downloadDocuments = this.downloadDocuments.bind(this);       
        this.getCurrentDocumentLocation = this.getCurrentDocumentLocation.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
        
    }

    componentDidMount(){
        document.title='Loantrak - Custody Centre';
        AuthService.isNewVersionDetected();
        
        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("custody centre").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            })
        }

        AuthService.isUserCustodyCentreEnabled().then((resp)=>{
            this.setState({isCustodyPriv:resp.data.Content,shouldRedirect:!resp.data.Content});
            },(err)=>{
                this.setState({isCustodyPriv:false,shouldRedirect:true});
        });
        AuthService.userCanDownloadCustodyFiles().then((resp)=>{
            this.setState({canDownloadCustodianDocuments:resp.data.Content});
            },(err)=>{
                this.setState({canDownloadCustodianDocuments:false});
        });

        window.addEventListener("resize", this.updateWindowDimension);
        // window.addEventListener("load", this.updateWindowDimension);

         
    }

    updateSelectedDeliveryLocation(arg) {
        //arg.preventDefault();
        const target = arg.target;
        this.setState(
          {
            selectedDeliveryLocation: target.value
          },
          () => {
            //alert(this.state.selectedDeliveryLocation);
          }
        );
      }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    rowClicked(id, name) {
        const self = this;
        MatterDocumentService.getCustodyDocumentAsPdf(id).then(
            resp => {
                //const file = new Blob([resp.data], {type: resp.request.response.type});
                const file = new Blob([resp.data], { type: "application/pdf" });
                const url = URL.createObjectURL(resp.data);
                var isIE = document.documentMode;

                if (!isMobile && !isIE) {
                    window.open(url);
                } else {
                    //saveAs(file, name + ".pdf");
                }
            },
            err => {
                console.log("view doc error" + err)
                if (err.response && err.response.status === 401) {
                    self.setState({
                        shouldRedirect: true,
                        hasError: true
                    });
                } else {
                    self.setState({
                        shouldRedirect: false
                    });

                    new SAlert({
                        title: "View Document",
                        text: "Unable to retrieve document.",
                        type: "warning"
                    });
                }
            }
        );
        this.setState({
            loading: false
        });
    }

    getDocumentDeliveryLocations() {
        CustodyService.getDocumentDeliveryLocations().then(
          resp => {
            this.setState({ 
                deliveryLocations: resp.data.Content,
                selectedDeliveryLocation: resp.data.Content.length != 0 ? resp.data.Content[0].id : ""
            },()=>{
                // console.log("delivery")
                // console.log(this.state.deliveryLocations)
                // console.log(this.state. selectedDeliveryLocation)
            });
          },
          err => {
            this.setState({ 
                hasError: true
            })
          }
        );
      }

    getCurrentDocumentLocation(matterId) {
        CustodyService.getCurrentDocumentLocation(matterId).then(
          resp => {
            this.setState({ 
                currentDocumentLocation: resp.data.Content,               
            },()=>{
                // console.log("delivery")
                console.log(this.state.currentDocumentLocation)
                // console.log(this.state. selectedDeliveryLocation)
            });
          },
          err => {
            this.setState({ 
                hasError: true
            })
          }
        );
    }

    handleRequestPack(e) {
        e.preventDefault();
        this.setState({
            msg: '',
          });
      
        this.setState({
          requestPack: true,
          requestPackModal: true
        });
    
        this.getDocumentDeliveryLocations();
    }

    downloadDocuments(e){
        /* e.preventDefault();*/
        this.setState({downloading: true},()=>{
            CustodyService.downloadCustodyCentreDocumentsAsZip(this.state.selectedMatterData.MatterId).then(
                resp => {
                  //   console.log("zip")
                  //   console.log(resp.data)
                  const file = new Blob([resp.data], { type: "application/zip" });
                  const url = URL.createObjectURL(resp.data);
                  saveAs(file,this.state.selectedMatterData.MatterId+".zip");  
                  this.setState({downloading: false})
                },
                err => {
                    this.setState({downloading: false, hasError: true})
                  if (err.response && err.response.status === 401) {
                    console.log(err.response);
                  }})
        })
        
    }

    sendRequestPack(e, isUrgent) {
        let btnId =  e.target.id;
        //alert(deliveryLocationId)
        //alert(isUrgent)
        //alert(this.state.selectedDeliveryLocation)
        e.preventDefault();
        this.setState({
            contLoading: true
        });
        CustodyService.registerCustodyRequest(
          this.state.selectedMatterData.MatterId,
          this.state.selectedDeliveryLocation,
          //deliveryLocationId,
          isUrgent
        ).then((resp)=>{
          this.setState({msg:resp.data.Message,contLoading: false, msgType:'success', requestPackModal: false});
          //this.setState({msg:"Custody Document request has been submitted",contLoading: false, msgType:'success'});
          this.checkIfCustodyDocumentRequested(this.state.selectedMatterData.MatterId);
          this.checkIfCustodyDocumentSentOrReceived(this.state.selectedMatterData.MatterId);
          this.checkIfMatterIsDigital(this.state.selectedMatterData.MatterId);
          if(btnId === 'btnSendRequest'){
            new SAlert({
                title: "Custody Request Submitted",
                text: "Custody document request  has been submitted successfully.",
                type: "success"
            });
        }

        },(err)=>{
            //alert(err)
          //this.setState({msg:'A request for delivery already exists. Please contact MSA for further details.',contLoading: false,custodyDocumentRequested : true, alreadyExists: true, msgType:'error'});
          if(err.toString().includes("Exists")){
            this.setState({msg:'A request for delivery already exists. Please contact MSA for further details.',contLoading: false,custodyDocumentRequested : true, alreadyExists: true, msgType:'error'});
          }
          else{
            this.setState({msg:'An unexpected error occured. Please contact MSA for further details.',contLoading: false,custodyDocumentRequested : false, alreadyExists: false, msgType:'error'});
          }
        });     
      
    }

    cancelRequestPack(e) {
        e.preventDefault();
        this.setState({
          requestPack: false,
          msg:'',
          requestPackModal: false
        });
        this.setState({
          matterSelectionExists: true
        });        
    }

    cancelCustodyRequest(e){   
        let btnId =  e.target.id;
        //alert(e.target.id);        
        //return;    
        CustodyService.cancelCustodyRequest(
          this.state.selectedMatterData.MatterId,
          //this.state.selectedDeliveryLocation.id,
        ).then((resp)=>{
          this.setState({
              msg:resp.data.Message,
              custodyDocumentRequested: false,
              docsSentOrReceived: false,
              contLoading: false
            });
            if(btnId === 'CancelFront'){
                new SAlert({
                    title: "Custody Request Cancelled",
                    text: "Custody document request  has been cancelled successfully.",
                    type: "success"
                });
            }
            
        },(err)=>{
          this.setState({msg:'There is an error with the request. Please contact MSA for further details.'});
        });       
    }
    
    searchClicked(arg) //{       
    {
        //searchText
        //alert(this.state.searchText);
        this.setState({
            isLoading: true,
            currentPage: 0,
            searched: true
        });
        //Do Search
        CustodyService.getCustodyMatterSearch(
        //   matterNumber,
        //   lenderReference,
        //   borrowersName,
        //   securityAddress,
        //   titleReference,
        //   solicitor,
        //   putawayNumber
        // '',
        // '',
        // '',
        // '',
        // '',
        // '',
        this.state.searchText
        ).then(
            resp => {
            const data = resp.data.Content;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
            this.setState({
                matterData: resp.data.Content,
                hasSearched: true,
                isLoading: false,
                pageCount: Math.ceil(data.length / this.state.perPage),
                data: slice,//resp.data.Content,                
            },()=>{
                //console.log(data)
            });
            },
            err => {
            this.setState({
                hasSearched: true,
                isLoading: false,
                errorExists: true,
                errorMessage: 'There is an error with the request',//err,
                hasError: true
            });
            }
        );        
    }

    searchInputChanged(event) {
        //setter
        //alert(event.target.value);
    
        const target = event.target;
    
        this.setState({
            searchText: target.value
        });
    }   
    
    updateSelectedMatter(e) {
        
        //this.getDocumentLocation(e.MatterId);
        this.getMatterDocuments(e.MatterId);
        this.getVerifiedNonDigitalDocuments(e.MatterId);
        this.checkIfCustodyDocumentRequested(e.MatterId);      
        this.checkIfCustodyDocumentSentOrReceived(e.MatterId);
        this.checkIfMatterIsDigital(e.MatterId); 
        this.getCurrentDocumentLocation(e.MatterId);
        this.setState({
            selectedMatterData: e,
            // matterSelectionExists: true
          });
    }

    checkIfCustodyDocumentRequested(MatterId){
        MatterDocumentService.checkCustodyDocumentRequested(MatterId).then(
            resp => {
                // console.log("checkCustodyDocumentRequested");
                // console.log(resp.data);
              //let custodyDocumentRequested = resp.data.Content;
              this.setState({
                custodyDocumentRequested: resp.data =='True'?true:false, //resp.data.Content,
                matterSelectionExists: true
                //contLoading: false
              });
            },
            err => {
              this.setState({
                hasError: true
                //contLoading: false
              });
            }
          );
    }

    checkIfCustodyDocumentSentOrReceived(MatterId){
        MatterDocumentService.checkIfCustodyDocumentSentOrReceived(MatterId).then(
            resp => {
                // console.log("checkCustodyDocumentRequested");
                // console.log(resp.data);
              //let custodyDocumentRequested = resp.data.Content;
              this.setState({
                docsSentOrReceived: resp.data =='True'?true:false, //resp.data.Content,
                matterSelectionExists: true
                //contLoading: false
              });
            },
            err => {
              this.setState({
                  hasError: true
                //contLoading: false
              });
            }
          );
    }    

    checkIfMatterIsDigital(MatterId){
        MatterDocumentService.checkMatterIsDigital(MatterId).then(
            resp => {
                // console.log("checkMatterIsDigital");
                // console.log(resp.data);
              //let custodyDocumentRequested = resp.data.Content;
              this.setState({
                matterIsDigital: resp.data =='True'?true:false, //resp.data.Content,
                matterSelectionExists: true
                //contLoading: false
              },()=>
              {
                // console.log("hello")
                // console.log(this.state.matterIsDigital)
              });
            },
            err => {
              this.setState({
                hasError: true
                //contLoading: false
              });
            }
          );
    }

    getMatterDocuments(e) {
        this.setState({ contLoading: true });
        MatterDocumentService.getVerifiedMatterDocuments(e).then(
          resp => {
            let docs = resp.data.Content.map(d => ({
              MatterDocumentId: d.MatterDocumentId,
              DocName: d.DocName,
              DocModDate: Moment(d.UpdatedDate).format("YYYY-MM-DD")
            }));
            this.setState({
              matterDocuments: docs, //resp.data.Content,
              contLoading: false
            });
          },
          err => {
            this.setState({
              contLoading: false
            });
          }
        );
    }

    getVerifiedNonDigitalDocuments(e) {
        this.setState({ contLoading: true });
        MatterDocumentService.getVerifiedNonDigitalDocuments(e).then(
          resp => {
            let docs = resp.data.Content.map(d => ({
              MatterDocumentId: d.MatterDocumentId,
              DocName: d.DocName,
              DocModDate: Moment(d.UpdatedDate).format("YYYY-MM-DD")
            }));
            this.setState({
              matterNonDigitalDocuments: docs, //resp.data.Content,
              contLoading: false
            });
          },
          err => {
            this.setState({
              contLoading: false
            });
          }
        );
    }

    updateSelectedPage(e) {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
      
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            //console.log(this.state.offset + " offset - perpage " + this.state.perPage),
            this.receivedData()
        });
        
    }

    onStartClick = (e) => {
        const selectedPage = 0;
        const offset = 0;
    
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            this.receivedData()
        });
    
    };

    onEndClick = (e) => {
        const selectedPage = this.state.pageCount-1;//e.selected;
        const offset = selectedPage * this.state.perPage;
        
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            console.log(this.state.offset + " offset - perpage " + this.state.perPage);
            this.receivedData()
        });
    
    };   

    receivedData() {
        const data = this.state.matterData;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({      
          isLoading: false,
          pageCount: Math.ceil(data.length / this.state.perPage),
          //currentPage : 0,
          data: slice,//resp.data.Content
          offset: 0,
        },()=>{console.log(data)});    
    }

    backToSearch(e){
        this.setState({
          matterSelectionExists: false,
          //searchText: ''
        });
      }

    updateWindowDimension=()=>{
         
        this.setState({ 
            windowWidth: window.innerWidth,
            profileDisplay: ""
        })

         
        // if(this.state.windowWidth<=768){ 
        //     //alert("Mobile") 
        //     if(this.state.windowWidth!=this.state.windowWidthPrevious){
        //         this.setState({
        //             custodyFormDisplay: "none"
        //         }) 
        //     }
        // }else{
        //     //alert("Desktop");
        //     this.setState({
        //         custodyFormDisplay: ""
        //     })
        // }
        
        this.setState({
            windowWidthPrevious: window.innerWidth 
        }) 

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    } 

    showCustodyForm(){ 
        this.hideBasicSearch();
        this.setState({
            custodyFormDisplay: ""
        })   
    }

        hideCustodyForm(){ 
            this.showBasicSearch();
            this.setState({
                custodyFormDisplay: "none"
            }) 
        } 

    // showAdvancedSearch(){ 
    //     this.hideBasicSearch();
    //     this.setState({
    //         custodyFormDisplay: ""
    //     }) 
    // } 

    showBasicSearch(){ 
        this.setState({
            basicSearchDisplay: ""
        })   
    }

        hideBasicSearch(){
            this.setState({
                basicSearchDisplay: "none"
            }) 
        } 

     

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    handleSearchCallback= (as) => {
        // alert(as[0])
        //alert(as[1]);
        this.setState({
            matterNo: as[0],
            borrowers: as[1],
            lenderRefNo: as[2],
            address: as[3],
            matterState: as[4],
            matterType: as[5],
            matterStatus: as[6],
            mortManager: as[7],
            fileOwner: as[8],
            titleRefNo: as[9],
            lender: as[10],
            isLoading: true,
            currentPage: 0,
            searched: true,
            //searchText:as[0],
        },()=>{
            //alert(this.state.matterState)
           // alert("from login " + this.state.createAccountSuccess);
           //Do Search
           //CustodyService.getCustodyMatterSearch(
        CustodyService.getCustodyMatterAdvancedSearch(
             this.state.matterNo,
              this.state.borrowers,
              this.state.lenderRefNo,
              this.state.address,
              this.state.matterState,
              this.state.matterType,
              this.state.matterStatus,
              this.state.mortManager,
              this.state.fileOwner,
              this.state.titleRefNo,
              this.state.lender
            //this.state.searchText
            ).then(
                resp => {
                const data = resp.data.Content;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)        
                this.setState({
                    matterData: resp.data.Content,
                    hasSearched: true,
                    isLoading: false,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    data: slice,//resp.data.Content,                
                },()=>{
                    //console.log(data)
                });
                },
                err => {
                this.setState({
                    hasSearched: true,
                    isLoading: false,
                    errorExists: true,
                    errorMessage: 'There is an error with the request',//err,
                });
                }
            );    
        });

        
          
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }


    render() {

        console.log("WINDOW: "+this.state.windowWidth); 
        console.log("custodyFormDisplay: "+this.state.custodyFormDisplay); 

        let paging='';
        let custodyMatterChild = '';
        let matterRequestDocumentsGrid = '';
        let custodyFormDisplay=this.state.custodyFormDisplay; 
        console.log("custodyFormDisplay2: "+custodyFormDisplay); 
        

        if(this.state.shouldRedirect || this.state.hasError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }

        if(this.state.data.length > 0){
            paging=(
                <DataTablePaging
                    pageCount= {this.state.pageCount}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.updateSelectedPage}
                    items={this.state.offset}
                    total={this.state.matterData.length}
                    onEndClick={this.onEndClick}
                    onStartClick={this.onStartClick}
                >
                    
                </DataTablePaging>
            )
        }
        
        
        if(this.state.leftContent != this.state.pageTitle){
            return( 
                <DisplayLeftMenu 
                    page={this.state.leftContent} 
                    desktopMenu={this.state.desktopMenu} 
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                />
            )
        } 

        if (this.state.matterSelectionExists) {
            // if(this.state.selectedMatterData!=null){
            return(
              <MatterDetailsPage
                loading={this.state.contLoading}
                matterLocation={this.state.matterLocation}
                height={this.state.height}
                width={this.state.width}
                handleRequestPack={this.handleRequestPack}
                matterDocuments={this.state.matterDocuments}
                matterData={this.state.selectedMatterData}
                requestPackModal={this.state.requestPackModal}
                selectedMatterData={this.state.selectedMatterData}
                deliveryLocations={this.state.deliveryLocations}
                selectedDeliveryLocation={this.state.selectedDeliveryLocation}
                cancelRequestPack={this.cancelRequestPack}
                backToSearch= {this.backToSearch}
                rowClicked={this.rowClicked}
                custodyDocumentRequested={this.state.custodyDocumentRequested}
                docsSentOrReceived={this.state.docsSentOrReceived}
                sendRequestPack={this.sendRequestPack}
                msg={this.state.msg}
                cancelCustodyRequest={this.cancelCustodyRequest}
                alreadyExists={this.state.alreadyExists}
                msgType={this.state.msgType}
                matterIsDigital={this.state.matterIsDigital}
                matterNonDigitalDocuments={this.state.matterNonDigitalDocuments}
                // deliveryLocationId = 
                updateSelectedDeliveryLocation={this.updateSelectedDeliveryLocation}
                downloadDocuments = {this.downloadDocuments}
                downloading = {this.state.downloading}
                canDownloadCustodianDocuments = {this.state.canDownloadCustodianDocuments}
                currentDocumentLocation = {this.state.currentDocumentLocation}
              ></MatterDetailsPage>
            );

        }

       
        return ( 
             
            <LeftMenuContent 
                id="CustodyCenterPage"
                page="custodycentre" 
                desktopMenu={this.state.desktopMenu}
                callBack = {this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
                    
                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />

                    <div className="content">  
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>

                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />

                            <TitleSegment>Loan Insights</TitleSegment>

                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>

                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 

                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>

                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>

                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 

                        </div> */}
                        
                        <div>
                            <TitleSegment addCSS="titleDesktop">Custody Centre</TitleSegment>

                            <SearchBox
                                addCSS={this.state.basicSearchDisplay}
                                label="Search for matter"
                                placeholder="Type in matter number, reference or security"
                                onClick={() => this.searchClicked()}  searchTextChange={this.searchInputChanged} value={this.state.searchText} 
                                advancedSearch="1"
                                advancedSearchAction={() => this.showCustodyForm()} 
                                windowWidth={this.state.windowWidth}
                                />
                            
                            {/* <div>
                                <Button
                                    addCSS="btnSearch secondaryBTN TextIconCenter "
                                    clickFunction={()=>this.showCustodyForm()}
                                >    
                                    <TextIcon
                                        tiText="Search Loan"
                                        iconURL="../../../images/icons/OutlineDark/Search.svg"
                                    />
                                </Button>
                                
                            </div> */}
                            
                             
                            
                            <CustodySearchLoanBox
                                parentCallback = {this.handleSearchCallback}
                                display={custodyFormDisplay}
                                closeAction={()=>this.hideCustodyForm()}
                                // handleDetailedSearch={this.handleDetailedSearch}
                                // getAdvancedSearch={this.getAdvancedSearch}
                                // resetAdvancedSearch={this.resetAdvancedSearch}
                                // updateAdvancedSearch={this.updateAdvancedSearch}
                                // handleGenerateCSV={this.handleGenerateCSV}
                                // canGenerateCsv={this.state.userIsNotBroker}
                                // columnAction={()=>this.handleColumnClick()}
                                // columnBtnStatus={columnBtnStatus}
                                // slbActionGTC={slbActionGTC}                            
                            />
                            <br />
                            {/*
                            <div>
                                 <Button addCSS="btnSearch secondaryBTN TextIconCenter ">    
                                    <SearchTextIcon
                                        tiText="Search for matter"
                                        placeholder="Search for matter"
                                        iconURL="../../../images/icons/OutlineDark/Search.svg"
                                        onClick={() => this.searchClicked()}  
                                        searchTextChange={this.searchInputChanged}
                                        value={this.state.searchText} 
                                    />
                                </Button> */}
                                 {/* <CustodySearchLoanBox
                                parentCallback = {this.handleSearchCallback}
                                // display={custodyFormDisplay}
                                // closeAction={()=>this.hideCustodyForm()}
                                // handleDetailedSearch={this.handleDetailedSearch}
                                // getAdvancedSearch={this.getAdvancedSearch}
                                // resetAdvancedSearch={this.resetAdvancedSearch}
                                // updateAdvancedSearch={this.updateAdvancedSearch}
                                // handleGenerateCSV={this.handleGenerateCSV}
                                // canGenerateCsv={this.state.userIsNotBroker}
                                // columnAction={()=>this.handleColumnClick()}
                                // columnBtnStatus={columnBtnStatus}
                                // slbActionGTC={slbActionGTC}                            
                            /> 
                            </div>*/}

                            {/* {custodyMatterChild} */}
                            
                            {(this.state.data.length!==0 && this.state.searched) ?(
                                <>
                                <Card>
                                
                                    <DataTableCustody 
                                        clearSearch={this.clearSearch}
                                        searchMatters={this.searchMatters}
                                        hasSearched={this.state.hasSearched}
                                        isLoading={this.state.isLoading}
                                        matterData={this.state.matterData}
                                        updateSelectedMatter={this.updateSelectedMatter}
                                        data={this.state.data}
                                        currentPage={this.state.currentPage}
                                        updateSelectedPage={this.updateSelectedPage}
                                        pageCount={this.state.pageCount}
                                    />

                                    {/* <ButtonLoadMore 
                                        addCSS="btnLoadMore"
                                        resultDisplay="1 of 10"
                                    /> */}

                                </Card>
                                {paging}
                                </>
                            ):(this.state.hasSearched && this.state.data.length==0?<Card>
                                <div>No record found.</div>
                            </Card>:this.state.searched?<ProgressDisplay/>:'')
                            }

                        </div>
                    </div>                   

            </LeftMenuContent>  
        )
    }
}
CustodyCenterPage=AutoLogout(CustodyCenterPage);
export default CustodyCenterPage
