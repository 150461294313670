import { AuthService } from "./auth-service";
import WebApiService from "./web-api-service";

class matterDocumentService extends WebApiService {
  // getDocumentTypes() {
  //
  //     return new Promise((resolve, reject) => {
  //
  //         const url = "api/getNoteTypes";
  //         const options = {
  //             "headers": {
  //                 'Authorization': AuthService.token
  //             }
  //         };
  //
  //         super.get( url, options ).then((response) => {
  //             resolve(response);
  //         }, (err) => {
  //             reject(err);
  //         });
  //     });
  // }

  
  getVerifiedMatterDocuments(matterId) {
    return new Promise((resolve, reject) => {
      const url = "api/getVerifiedDocuments";
      const options = {
        headers: {
          Authorization: AuthService.token,
          matterId: matterId
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getVerifiedNonDigitalDocuments(matterId) {
    return new Promise((resolve, reject) => {
      const url = "api/getVerifiedNotDigitalDocuments";
      const options = {
        headers: {
          Authorization: AuthService.token,
          matterId: matterId
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  //uploadTitleSearch(files, fileName) {
  uploadTitleSearch(files,stateName) {
    return new Promise((resolve, reject) => {
      const url = "api/readSecurityTitleSearches";
      const data = new FormData();
      files.forEach(element => {
        data.append("file", element);
      });

      //   data.append("matterId", matterId);
      //   data.append(fileName, fileImage);

      const options = {
        headers: {
          'Authorization': AuthService.token,
          //'fileName':fileName,
          'stateName':stateName,
          //"matterId": matterId,
          //"OutstandingName":OutstandingItemName,
          //   ,          files: files
        }
      };

      super.specialUpload(url, data, options).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getMatterDocuments(matterId) {
    return new Promise((resolve, reject) => {
      const url = "api/getDocumentsForMatter";
      const options = {
        headers: {
          Authorization: AuthService.token,
          matterId: matterId
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getDocumentAsPdf(documentId) {
    return new Promise((resolve, reject) => {
      const url = "api/getDocument";
      const options = {
        responseType: "blob",
        headers: {
          Authorization: AuthService.token,
          matterDocumentId: documentId
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getCustodyDocumentAsPdf(documentId) {
    return new Promise((resolve, reject) => {
      const url = "api/getCustodyDocument";
      const options = {
        responseType: "blob",
        headers: {
          Authorization: AuthService.token,
          matterDocumentId: documentId
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  // showFile(blob, filename) {
  //
  //     // It is necessary to create a new blob object with mime-type explicitly set
  //     // otherwise only Chrome works like it should
  //     var newBlob = new Blob([blob], {type: "application/pdf"});
  //
  //     // IE doesn't allow using a blob object directly as link href
  //     // instead it is necessary to use msSaveOrOpenBlob
  //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(newBlob);
  //         return;
  //     }
  //
  //     // For other browsers:
  //     // Create a link pointing to the ObjectURL containing the blob.
  //     const data = window.URL.createObjectURL(newBlob);
  //     const link = document.createElement('a');
  //
  //     link.href = data;
  //     link.download=filename;
  //     link.click();
  //
  //     setTimeout(function(){
  //         // For Firefox it is necessary to delay revoking the ObjectURL
  //         window.URL.revokeObjectURL(data);
  //
  //     }, 100);
  // }

  uploadDocument(matterId, fileName, fileImage) {
    return new Promise((resolve, reject) => {
      const url = "api/uploadMatterDocuments";
      let data = new FormData();

      data.append("matterId", matterId);
      data.append(fileName, fileImage);

      super.post(url, data).then(
        resp => {
          resolve(resp);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getDocumentsAsZip(matterId) {
    return new Promise((resolve, reject) => {
      const url = "api/geZipFile";
      const options = {
        responseType: "blob",
        headers: {
          Authorization: AuthService.token,
          matterId: matterId
        }
      };

      super.get(url, options).then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  checkCustodyDocumentRequested(matterId){
    const url = "api/checkCustodyDocumentRequested";
    const options ={
        'headers':{
            'Authorization': AuthService.token,
            'MatterId':matterId,
        }
    };
    return new Promise(
        (resolve,reject)=>{
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            })
        }
    );
  }

  checkIfCustodyDocumentSentOrReceived(matterId){
    const url = "api/checkCustodyDocumentSentOrReceived";
    const options ={
        'headers':{
            'Authorization': AuthService.token,
            'MatterId':matterId,
        }
    };
    return new Promise(
        (resolve,reject)=>{
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            })
        }
    );
  }

  checkMatterIsDigital(matterId){
    const url = "api/checkIfMatterIsDigital";
    const options ={
        'headers':{
            'Authorization': AuthService.token,
            'MatterId':matterId,
        }
    };
    return new Promise(
        (resolve,reject)=>{
            super.get(url,options).then((response)=>{
                resolve(response);
            },(err)=>{
                reject(err);
            })
        }
    );
  }

}

export const MatterDocumentService = new matterDocumentService();
