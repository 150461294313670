import React, { Component } from 'react'

import './css/RadioButton.css';

class RadioButton extends Component {
    render() {
        return (
            <div  className="formRadio">
            {/* <input type="radio" id={this.props.id} value={this.props.value} name={this.props.name} checked={this.props.checked} onChange={this.props.onChange} /> <label for={this.props.for}>{this.props.for}</label> */}
            {/* <input type="radio" id={this.props.id} value={this.props.value} name={this.props.name} checked={this.props.checked} onChange={this.props.onChange} /> */}
            <input type="radio" id={this.props.id} value={this.props.value} name={this.props.name} checked={this.props.checked} onChange={this.props.onChange} />
            <div>{this.props.title}</div>
            </div>
        )
    }

    // let $type=this.props.type;
    //     return (  
    //         <div className="FormCheckBox" >
    //             <input type={$type==""||$type==null?"checkbox":$type} checked={this.props.checked} onChange={this.props.onChange} onClick={this.props.onClick}/>
    //             <div>{this.props.title}</div>
    //         </div>
    //     )
}

export default RadioButton
