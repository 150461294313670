import React                                                from 'react';
import PropTypes from "prop-types";
// import { AuthService }                                      from '../../services/auth-service';
import { Redirect }                                         from 'react-router-dom';
// import ProgressDisplay from '../../controls/progress-display';
// import { dateFieldName } from '@telerik/kendo-intl';
import { AuthService } from '../../../services/auth-service';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import SSOErrorPage from '../../../ssoerrorpage';


class SSOLogin extends React.Component {
    static contextTypes = {
        router: PropTypes.object
      };

    constructor (props) {
        super(props);
        //let matterrestrictions = AuthService.getUserTypeRestrictions();
        this.state={
            data: "null",
            //data: this.props.match.params.data.substring(1,this.props.match.params.data.length),
            token: this.props.match.params.token.substring(1,this.props.match.params.token.length),
            userid: this.props.match.params.userid.substring(1,this.props.match.params.userid.length),
            shouldRedirect: null,//false,
            errorMessage: null,
            //url:'https://tictocssouat.msanational.com.au/?errormessage=',
            //ssourl: AuthService.getSSOUrl(),
            // ssourl: "", //this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length) + 'Home/AllSignOut',
            // ssoredirectUrl: "",//this.props.match.params.redirecturl.substring(1,this.props.match.params.redirecturl.length),            
            //ssourl: this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length),
            // ssoredirectUrl: this.props.match.params.redirecturl.substring(1,this.props.match.params.redirecturl.length),
            ssourl: "",
            //ssoredirectUrl: "",
            ssoredirectUrl: this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length),
            
            externalPrivileges: [],
            CanInstructDischargeUpload:'',
            CanInstructDischarges:'',
            CanInstructNewLoans:'',
            CanProvideDischargePayout:'',
            matterRestrictions: [],
            UserHasNewLoansRestriction: false,
            UserHasDischargesRestriction: false,
            UserHasConsentsRestriction: false,
        }
    }

    // componentDidMount(){   
    //     //alert(this.props.match.params.token.substring(1,this.props.match.params.token.length));
    //     //alert(this.props.match.params.userid.substring(1,this.props.match.params.userid.length));
    //     // alert(this.props.match.params.ssourl);
    //     // alert(this.props.match.params.redirecturl);
    //     //alert(this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length));
    //     //alert(this.props.match.params.redirecturl.substring(1,this.props.match.params.redirecturl.length));
    //     //AuthService.ssoGetSsoUrl(this.state.token,this.state.userid).then((resp) =>( 
    //     AuthService.ssoGetSsoUrl(this.state.userid).then((resp) =>( 
    //         this.setState({
    //             ssourl: resp.data.Content + 'Home/AllSignOut' //UAT
    //             //ssourl: 'https://tictocsso.msanational.com.au/' + 'Home/AllSignOut' //PROD
    //         },()=>{
    //             //console.log(resp.Content);
    //             AuthService.ssologin(this.state.token,this.state.userid).then((resp) =>(   
    //                 this.setState({                                                             //Evaluate Repsonse and login or decline based on that. 
    //                         //shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
    //                         errorMessage:null,
    //                     }
    //                     ,()=>{
    //                         //alert(this.state.shouldRedirect)
    //                         //alert(this.state.mustAuthenticate)
    //                         //alert(this.state.userType);
    //                         //if((this.state.userType == 3 || this.state.userType == 2) && this.state.shouldRedirect && !this.state.mustAuthenticate){
    //                         //if(this.state.shouldRedirect && !this.state.mustAuthenticate){
    //                             AuthService.getUserExternalPrivilegesList().then((r)=>{
    //                                 this.state.externalPrivileges.push(r.data.Content);   
    //                                 AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
    //                                 console.log(this.state.externalPrivileges)
                                    
                                        


                                    
    //                                 //this.sendData()
    //                            },(err)=>{
    //                                //alert(err)
    //                                //alert("There is an error with the request")
    //                                this.setState({
    //                                 shouldRedirect: false,
    //                                 errorMessage: ('Login failed.' )
    //                                })
    //                            });

    //                            AuthService.GetUserMatterTypeRestriction().then((resp)=>{
    //                             this.state.matterRestrictions.push(resp.data.Content);   
    //                             AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
    //                             console.log(this.state.matterRestrictions)
                                
    //                             },(err)=>{
    //                                 //alert(err)
    //                                 alert("There is an error with the request")
    //                                     this.setState({
    //                                     hasLoginError: true
    //                                     })
    //                             });

    //                             var matterrestrictions = AuthService.getUserTypeRestrictions();
    //                                     let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
    //                                     let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
    //                                     let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
    //                                     this.setState({
    //                                         // shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
    //                                         UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
    //                                         UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
    //                                         UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
    //                                     },()=>{
    //                                         this.setState({
    //                                             shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
    //                                         })
    //                                     })

    //                         // }
    //                         // else{
    //                         //     //this.sendData()
    //                         // }
                            
    //                      })
    //             ), (err) => {            
    //                 this.setState({
    //                 shouldRedirect: false,
    //                 errorMessage: ('Login failed.' )//+ err),
    //                 },()=>{
    //                     //alert(this.state.shouldRedirect + "" + this.state.errorMessage);
    //                 });
    //             },()=>{
    //                 // document.cookie = "bearer=;expires="+ Date.toString();
    //             }); 
    //         }
    //         ))
        
    //    ), (err) => {            
    //     // if(this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length) == 16 || this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length) == 1639){
    //     //     this.setState({
    //     //         ssourl: 'https://tictocssouat.msanational.com.au/' + 'Home/AllSignOut' //UAT
    //     //         //ssourl: 'https://tictocsso.msanational.com.au/' + 'Home/AllSignOut' //PROD
    //     //     });
    //     // }
    //     // if(this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length) == 11 || this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length) == 183){
    //     //     this.setState({
    //     //         ssourl: 'https://nanossouat.msanational.com.au/' + 'Home/AllSignOut'
    //     //         //ssourl: 'https://nanosso.msanational.com.au/' + 'Home/AllSignOut' //PROD
    //     //     });
    //     // }
    //     // if(this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length) == 19 || this.props.match.params.ssourl.substring(1,this.props.match.params.ssourl.length) == 171){
    //     //     this.setState({
    //     //         ssourl: 'https://judossouat.msanational.com.au/' + 'Home/AllSignOut'
    //     //         //ssourl: 'https://judosso.msanational.com.au/' + 'Home/AllSignOut' //PROD
    //     //     });
    //     // }
    //     }     
        

    //     // alert(this.props.match.params.redirecturl.substring(1,this.props.match.params.redirecturl.length));
    //     //return;
    //     // console.log("this.props.match.params.ssourl");
    //     // console.log(this.props.match.params.ssourl);
    //     // console.log("this.props.match.params.ssoredirectUrl");
    //     // console.log(this.props.match.params.redirecturl);
    //     //return;
        
    //     //alert(this.state.ssoredirectUrl);
    // //     AuthService.getUserExternalPrivilegesList().then((resp)=>{
    // //         this.state.externalPrivileges.push(resp.data.Content);   
    // //         AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
    // //         console.log(this.state.externalPrivileges)
    // //         //this.sendData()
    // //    },(err)=>{
    // //        //alert(err)
    // //        //alert("There is an error with the request")
    // //        this.setState({
    // //         errorMessage: ('Login failed.' )
    // //        })
    // //    });
    //     // AuthService.ssologin(this.state.token,this.state.userid).then((resp) =>(   
    //     //     this.setState({                                                             //Evaluate Repsonse and login or decline based on that. 
    //     //             //shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
    //     //             errorMessage:null,
    //     //         }
    //     //         ,()=>{
    //     //             //alert(this.state.shouldRedirect)
    //     //             //alert(this.state.mustAuthenticate)
    //     //             //alert(this.state.userType);
    //     //             //if((this.state.userType == 3 || this.state.userType == 2) && this.state.shouldRedirect && !this.state.mustAuthenticate){
    //     //             //if(this.state.shouldRedirect && !this.state.mustAuthenticate){
    //     //                 AuthService.getUserExternalPrivilegesList().then((r)=>{
    //     //                     this.state.externalPrivileges.push(r.data.Content);   
    //     //                     AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
    //     //                     console.log(this.state.externalPrivileges)
    //     //                     this.setState({
    //     //                         shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
    //     //                     })
    //     //                     //this.sendData()
    //     //                },(err)=>{
    //     //                    //alert(err)
    //     //                    //alert("There is an error with the request")
    //     //                    this.setState({
    //     //                     errorMessage: ('Login failed.' )
    //     //                    })
    //     //                });
    //     //             // }
    //     //             // else{
    //     //             //     //this.sendData()
    //     //             // }
                    
    //     //          })
    //     // ), (err) => {            
    //     //     this.setState({
    //     //     shouldRedirect: false,
    //     //     errorMessage: ('Login failed.' )//+ err),
    //     //     },()=>{
    //     //         //alert(this.state.shouldRedirect + "" + this.state.errorMessage);
    //     //     });
    //     // },()=>{
    //     //     // document.cookie = "bearer=;expires="+ Date.toString();
    //     // });        
        
    // }


    componentDidMount(){   
        AuthService.ssoGetSsoUrl(this.state.userid).then((resp) => {
            this.setState({
              url: resp.data.Content + 'Home/AllSignOut' //UAT
            }, ()=>{
                AuthService.ssologin(this.state.token,this.state.userid).then((resp) => {
                    this.setState({                                                             //Evaluate Repsonse and login or decline based on that. 
                        //shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
                        errorMessage:null,
                    }
                    ,()=>{
                        AuthService.GetUserMatterTypeRestriction().then((a)=>{
                            this.state.matterRestrictions.push(a.data.Content);   
                            AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                            console.log(this.state.matterRestrictions)
                                                    
                        },(err)=>{
                            //alert(err)
                            alert("There is an error with the request")
                                this.setState({
                                hasLoginError: true
                                })
                        });
                        AuthService.getUserExternalPrivilegesList().then((r)=>{
                            this.state.externalPrivileges.push(r.data.Content);   
                            AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                            console.log(this.state.externalPrivileges)                           
                        
                            var matterrestrictions = AuthService.getUserTypeRestrictions();
                            let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
                            let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
                            let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
                            this.setState({
                                // shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
                                UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
                                UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
                                UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
                                shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
                            },()=>{
                                // this.setState({
                                //     shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
                                // })
                            })
                            // this.setState({
                            //     shouldRedirect: resp.MustRedirect && !resp.MustAuthenticate? false : resp.IsSuccessful,
                            // });
                            //this.sendData()
                       },(err)=>{
                           //alert(err)
                           //alert("There is an error with the request")
                           this.setState({
                            shouldRedirect: false,
                            errorMessage: ('Login failed.' )
                           });
                       });

                      
                    });
                  },(err)=>{
                    this.setState({
                        shouldRedirect: false,
                        errorMessage: ('Login failed.' )//+ err),
                        });
                      
                  });
            });
          },(err)=>{
            
          });
        
    }



    // render(){        
    //         if (this.state.shouldRedirect) {    //If login correct, navigate to home 
    //             return ( <Redirect to = '/newloans-dashboard' /> );
    //         } 
    //         else{
    //             if(this.state.shouldRedirect != null && this.state.errorMessage != null){
    //                 window.location.href=this.state.url + this.state.errorMessage;
    //             }
    //             else{
    //                 return(<div><ProgressDisplay/></div>)
    //             }                
                
    //         }            
    // }

    render(){    
        let ssoredirectUrl =    '/'+this.state.ssoredirectUrl ; 
            if (this.state.shouldRedirect && this.state.externalPrivileges != []) {    //If login correct, navigate to home 
                //check if user has matter type restriction
                if(this.state.UserHasNewLoansRestriction  == true || this.state.UserHasDischargesRestriction == true || this.state.UserHasConsentsRestriction == true){
                    if(this.state.UserHasNewLoansRestriction){
                        //return ( <Redirect to = '/dashboard' /> );
                        ssoredirectUrl = '/dashboard';
                    }
                    else{
                        if(this.state.UserHasDischargesRestriction){
                            //return ( <Redirect to = '/consents' /> );
                            ssoredirectUrl = '/discharges';
                        }
                        else  //return ( <Redirect to = '/discharges' /> );
                        {
                            ssoredirectUrl = '/consents';
                        }
                    }
                }
                else{
                    if(this.state.ssoredirectUrl=="" || this.state.ssoredirectUrl==null || this.state.ssoredirectUrl== 'search'){
                        ssoredirectUrl = '/dashboard';
                    }
                }
                
                return ( <Redirect to = {ssoredirectUrl} /> );
            } 
            else{
                if(this.state.shouldRedirect != null && this.state.errorMessage != null){
                    //alert(this.state.errorMessage);
                    //window.location.href=this.state.ssourl + this.state.errorMessage;
                    //window.location.href=this.state.ssourl;
                    //return ( <Redirect to = '/logout' /> );
                    //return ( <SSOErrorPage ssourl={this.state.ssourl} /> );
                    return ( <Redirect to ={{pathname: '/ssoerror', state: {redirectSsoUrl: this.state.ssourl} }}  /> );
                }
                else{
                    return(<div><ProgressDisplay/></div>)
                }                
                
            }            
    }

}

export default SSOLogin;
