import React, { Component } from 'react'
import {withRouter}  from 'react-router-dom'; 
import PropTypes from "prop-types";
import Moment from "moment";
import SAlert from "sweetalert2";
import ReactPaginate from 'react-paginate';
// import { orderBy } from "@progress/kendo-data-query";
//  import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
// import { Container as Grid, Row, Col as Column } from "react-grid-system";
import { Redirect } from 'react-router-dom';
import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import BGTransparentDark from '../../atom/BGTransparentDark/BGTransparentDark';
import Button from '../../atom/Button/Button';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card';
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment';

import ButtonLoadMore from '../../molecules/ButtonLoadMore/ButtonLoadMore';
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';
import TextIcon from '../../molecules/TextIcon/TextIcon';
  
import DataTableSearch from '../../organism/DataTableSearch/DataTableSearch';
import DataTablePaging from '../../organism/DataTablePaging/DataTablePaging';
import SearchLoanBox from '../../organism/SearchLoanBox/SearchLoanBox';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent'; 
import DefaultOption from "../../../models/all-option";
import DefaultStatus from "../../../models/default-status";
import { AuthService } from '../../../services/auth-service';
import { MatterService } from '../../../services/matter-service';


import './css/SearchPage.css';   
import { tsImportEqualsDeclaration } from '@babel/types';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import AutoLogout from '../../../auto-logout';
import InsightBox from '../../molecules/InsightBox/InsightBox';

const stateOption = {
    id: 0,
    value: "All"
  };

class SearchPage extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props){
        super(props);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        let user = AuthService.user;

        this.state = {
            pageTitle : 'search',
            leftContent : 'search',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            //advancedSearch: SearchPage.createAdvancedSearchConfig(),
            //matters: this.props.matters
            //matter: []
            loading: false,
            message: "",
            matters: [],
            sort: [],
            height: "100px",
            shouldRedirect: false,
            advancedSearch: SearchPage.createAdvancedSearchConfig(),
            user: user,
            userIsNotBroker:false,
            errorMessage: null,
            skip: 0,
            pageCount: 0,
            perPage: 10,
            currentPage: 0, 
            offset: 0,
            show: false,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            searchFormDisplay:"",
            windowWidth: window.innerWidth,
            windowWidthPrevious: "",
            profileDisplay: "",
            hasError: false,
            cannotDownloadCSVReport: false,
            InsightBox:0, 
        };

        let as = this.state.advancedSearch;
        if(localStorage.getItem("sessionTimeout") != 0 && localStorage.getItem("sessionTimeout") != null){
            if (this.props.location.state.searchArg != null) {
                as.matterStatus = this.props.location.state.searchArg;
                as.matterType = this.props.location.state.loanType;
                this.props.location.state.searchArg = null;
                this.props.location.state.loanType = null;
                as.shouldSearch = true;
                this.setState({
                matterStatus: as.matterStatus,
                advancedSearch: as
                });
            } else {
                as.matterStatus = DefaultStatus;
                this.setState({
                matterStatus: as.matterStatus,
                advancedSearch: as
                });
            }
        }

        //this.getadvancedSearch = this.getadvancedSearch.bind(this);

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        //this.rowClick = this.rowClick.bind(this);
        this.handleDetailedSearch = this.handleDetailedSearch.bind(this);
        this.handleGenerateCSV = this.handleGenerateCSV.bind(this);

        this.sortChange = this.sortChange.bind(this);
        this.getMatters = this.getMatters.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.changePage = this.changePage.bind(this);
        this.getAdvancedSearch = this.getAdvancedSearch.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.resetAdvancedSearch = this.resetAdvancedSearch.bind(this);
        this.selectedChanged = this.selectedChanged.bind(this);
        this.updateAdvancedSearch = this.updateAdvancedSearch.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.updateAdvancedSearchColumns = this.updateAdvancedSearchColumns.bind(
        this
        );
        this.componentDidMount = this.componentDidMount.bind(this);
        this.download = this.download.bind(this);
        if(localStorage.getItem("sessionTimeout") != 0 && localStorage.getItem("sessionTimeout") != null) this.updateAdvancedSearchColumns();
        this.userIsNotBroker=this.userIsNotBroker.bind(this);
        // this.onStartClick=this.onStartClick.bind(this);
        // this.onEndClick=this.onEndClick.bind(this);
        this.handleCancelUpdate = this.handleCancelUpdate.bind(this);
        this.handleColumnClick = this.handleColumnClick.bind(this);
        this.handleColumnUpdate = this.handleColumnUpdate.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
        //this.buildColumnEditor = this.buildColumnEditor.bind(this);

        this.updateWindowDimension = this.updateWindowDimension.bind(this);
        this.showSearchForm = this.showSearchForm.bind(this);
        this.hideSearchForm = this.hideSearchForm.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);  

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
  }

  handleLeftMenuCallback(child, dmMode, InstructSubMenu){
    //alert(InstructSubMenu);
    this.setState({
        leftContent: child,
        desktopMenu: dmMode,
        lmwInstructMSASub: InstructSubMenu
    });
}

  resetAdvancedSearch() {
    let as = this.state.advancedSearch;
    //let  brokerOption = DefaultOption;
    let lenderOption = DefaultOption;
    let mortMgrOption = DefaultOption;
    let brokerOption = DefaultOption;
    switch (this.state.user.UserTypeId) {
      case 2:
        lenderOption = {
          id: this.state.user.lenderId,
          value: this.state.user.LenderName
        };
        break;
      case 3:
        mortMgrOption = {
          id: this.state.user.MortManagerId,
          value: this.state.user.MortManagerName
        };
        break;
      // case 4:
      //     brokerOption = {
      //         id: this.state.user.BrokerId,
      //         value: this.state.user.BrokerName
      //     };
      //     break;
      default:
        break;
    }

    as.address = "";
    as.borrowers = "";
    as.fileOwner = DefaultOption;
    as.lender = lenderOption;
    as.lenderRefNo = "";
    as.matterNo = "";
    as.matterState = stateOption;
    as.matterStatus = DefaultStatus;
    as.mortManager = mortMgrOption;
    as.matterType = DefaultOption;
    as.broker = brokerOption;
    this.setState({
      advancedSearch: as
    });
    
  }

  handleCloseClick(event) {
    let type = event.target.id.substring(0, 1);
    let id = event.target.id.substring(1);

    if (type === "D") this.removeDetailTab(Number(id));
    if (type === "A") this.removeReportListTab();
    else this.removeReportTab(Number(id));
  }

  updateAdvancedSearch(advancedSearch) {
    //console.log("advance" + advancedSearch.columns);
    if (!advancedSearch.columns) {
      advancedSearch.columns = [];
    }

    this.setState({
      advancedSearch: advancedSearch
    });
  }

  updateAdvancedSearchColumns() {
    let config = this.state.advancedSearch;
    console.log(this.state.advancedSearch.matterType.id);
    //alert(this.state.advancedSearch.matterType.id);

    MatterService.getAdvancedSearchColumns(this.state.advancedSearch.matterType.id).then(resp => {
      config.columns = resp.data.Content;

      this.setState({
        advancedSearch: config
      });
    });
  }

  handleSelect(e) {
    this.setState({
      selected: e.selected
    });
  }
  selectedChanged(e) {
    this.setState({
      selected: e.selected
    });
  }

  static createAdvancedSearchConfig() {
    if(localStorage.getItem("sessionTimeout") != 0 && localStorage.getItem("sessionTimeout") != null){
    return {
      loading: false,
      //boolean shouldsearch
      shouldSearch: false,

      // These parameters store the list options
      matterTypes: [DefaultOption],
      statuses: [DefaultStatus],
      states: [AuthService.user.State],
      fileOwners: [DefaultOption],
      lenders: [DefaultOption],
      mortManagers: [DefaultOption],
      
      // Search parameters
      address: "",
      borrowers: "",
      fileOwner: DefaultOption,
      lender: DefaultOption,
      lenderRefNo: "",
      matterNo: "",
      matterState: stateOption,
      matterStatus: DefaultStatus,
      mortManager: DefaultOption,
      matterType: DefaultOption,
      results: [],
      titleRefNo: "",
      columns: [],

      // Grid state
      skip: 0,
      pageSize: 10,
      total: 0,
      workComplete: null,
      pageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSize: true,
        previousNext: true
      }
    };
  }
  }

  userIsNotBroker(){
    
    MatterService.CheckUserIsNotBroker().then(response=>{
      this.setState({userIsNotBroker:!response.data.Content});
    },
    error=>{
      this.setState({userIsNotBroker:false,
        loading: false,
        shouldRedirect: true,
        errorMessage: 'there is an error with the request'
      });
    })
  } 

  componentDidMount() {
        document.title = "Loantrak - Advanced Search";      
        window.addEventListener("resize", this.updateWindowDimension);   

        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("search").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }
        this.userIsNotBroker();
        MatterService.UserCannotDownloadCSVReport().then(
            (resp)=>{
              this.setState({cannotDownloadCSVReport:resp.data.Content})
            },
            (err)=>{
              this.setState({cannotDownloadCSVReport:false})
            }
        );
  }

  componentWillUnmount() {
    
  }

  handleDetailedSearch() {
    
    if(this.state.windowWidth<=768){ 
        this.hideSearchForm();
    }

      ///reset pagination
      this.setState({
        skip: 0,
        pageCount: 0,
        perPage: 10,
        currentPage: 0, 
        offset: 0,
      })
    let as = this.getAdvancedSearch();
    
    
    as.loading = true;
    as.skip = 0;
    this.updateAdvancedSearch(as);
    console.log(as)
    this.updateAdvancedSearchColumns(as);
    this.buildColumnEditor(as);
    //this.handleColumnUpdate(as);
    
    this.runDetailSearch(as);
  }

  handleGenerateCSV() {
    let as = this.getAdvancedSearch();
    //this.updateAdvancedSearch(as);
    this.runGenerateCSV(as);
  }

  runGenerateCSV(as) {
    //previous simple search function, returned details on home screen
    if(this.state.userIsNotBroker){
      MatterService.generateCSV(as).then(
        resp => {
          console.log(as.matterStatus.value);
          
          let headerMessage = "";
          for (let i = 0; i < resp.Content.Columns.length; i++) {
            headerMessage = headerMessage + resp.Content.Columns[i].Title + ",";
          }
          headerMessage = headerMessage + "\n";
          let message = resp.Content.Matters;
          message = headerMessage + message.join("\n");
          this.download(
            as.lender.value + " - " + as.matterStatus.value + ".csv",
            message
          );
          //as.workComplete();
        },
        err => {
          this.setState(
            {
              loading: false,
              shouldRedirect: true,
              errorMessage: 'there is an error with the request'
            },
            () => {
              //if (as.workComplete) as.workComplete();
            }
          );
        }
      );
    }
    else{

    }
  }

  download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  runDetailSearch(as) {
    //previous simple search function, returned details on home screen

    //this.updateAdvancedSearchColumns(as);

    let self = this;
    MatterService.detailSearch(as).then(
      resp => {
        let matters = resp.Content.Matters.map(matter => ({
          matterNo: matter.MatterId,
          matterDescription: matter.MatterDescription,
          matterTypeName: matter.MatterTypeName,
          settlementDate: matter.SettlementDate
            ? Moment(matter.SettlementDate).format("DD-MM-YYYY HH:mm:ss")
            : "",
          stateName: matter.StateName,
          fileOwner: matter.FileOwnerFullName,
          lenderName: matter.LenderName,
          lenderRefNo: matter.LenderRefNo,
          
          securities: matter.Securities===""?[]:matter.Securities,
          titleRefs: matter.TitleRefs===""?[]:matter.TitleRefs,
          loanAmount:  matter.LoanAmount,
          relationshipManager: matter.RelationshipManager,
          instructionDate: matter.InstructionsReceivedDate 
           ? Moment(matter.InstructionsReceivedDate).format("DD-MM-YYYY HH:MM:SS ")
           : "",
           fundingChannel: matter.FundingTypeChannel,
           matterCurrentStatus: matter.MatterCurrentStatus,
           matterStatusCategory: matter.MatterStatusCategory,
           payoutAmount: matter.PayoutAmount

        }));

        let message = "";

        if (!matters || matters.length === 0) {
          message = "No matching records found";
          new SAlert({
            title: "Detailed search",
            text: message,
            type: "info"
          });
        }

        as.results = matters;
        as.total = resp.Content.Total;
        as.message = message;
        as.loading = false;

        self.updateAdvancedSearch(as);
       // this.updateAdvancedSearch(as);

        //as.workComplete();
        this.setState({
            pageCount: Math.ceil(as.total / this.state.perPage),
            total: as.total
        },()=>{
            //alert(as.total)
        })
      },
      err => {
        this.setState(
          {
            loading: false,
            shouldRedirect: true,
            errorMessage: 'there is an error with the request'
          },
          () => {
            //if (as.workComplete) as.workComplete();
          }
        );
      }
    );
  }

//   rowClick(event, rowIdx) {
//       //row click push to relevant matter
//       if (event.rowIdx !== -1 && window.innerWidth > 768) {
//         window.open("/loan-details:" + event.dataItem.matterNo);
//       }
//       if (event.rowIdx !== -1 && window.innerWidth <= 768) {
//         this.context.router.history.push(
//           "/loan-details:" + event.dataItem.matterNo
//         );
//       }
//     //this.context.router.history.push(
//     //'/loan-details:'+event.dataItem.matterNo
//     //)
//   }

    rowClick(e, matterId) {
        window.open('/matter-details:'+matterId)
    //     this.state.history.push({pathname:'/matter-details:'+matterId,
    //     state: {
    //         searchArg : this.getAdvancedSearch, 
    //     }
    // });

    // this.props.history.push({pathname:"/search",
    //     state: {
    //        // searchArg : null, 
    //        searchArg: this.props.searchArg
    //     }
    // });
        //row click push to relevant matter
        // if (event.rowIdx !== -1 && window.innerWidth > 768) {
        //   window.open("/matter-details:" + event.dataItem.matterNo);
        // }
        // if (event.rowIdx !== -1 && window.innerWidth <= 768) {
        //   this.context.router.history.push(
        //     "/matter-details:" + event.dataItem.matterNo
        //   );
        // }
      //this.context.router.history.push(
      //'/loan-details:'+event.dataItem.matterNo
      //)
    }

  sortChange(event) {
    //sort matters on change
    /*
        this.setState({
            matters: this.GetMatters(event.sort),
            sort: event.sort
        });
        */
  }

  getMatters(sort) {
    //get matters after sort
    //return orderBy(this.state.matters, sort);
  }
  getAdvancedSearch() {
    //advanced search getter
    return this.state.advancedSearch;
  }
  pageChange(event) {
    //handle page change events and run search if needed
    let as = this.getAdvancedSearch();

    as.loading = true;
    as.skip = event.page.skip;
    as.pageSize = event.page.take;

    this.runDetailSearch(as);

    this.updateAdvancedSearch(as);
  }

    changePage(action,take) {
        alert(this.state.skip)
        //let skip = 10;
        //if(action == 'add'){
        this.setState({
            skip: this.state.skip+action
        },()=>
        {
            //handle page change events and run search if needed
            let as = this.getAdvancedSearch();

            as.loading = true;
            as.skip = this.state.skip;
            as.pageSize = take;

            this.runDetailSearch(as);

            this.updateAdvancedSearch(as);
        })
      
        // //handle page change events and run search if needed
        // let as = this.getAdvancedSearch();

        // as.loading = true;
        // as.skip = this.state.skip;
        // as.pageSize = take;

        // this.runDetailSearch(as);

        // this.updateAdvancedSearch(as);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        //alert (offset);
    
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            console.log(this.state.offset + " offset - perpage " + this.state.perPage);
            //this.receivedData()
            let as = this.getAdvancedSearch();

        as.loading = true;
        as.skip = offset;
        as.pageSize = this.state.perPage;

        this.runDetailSearch(as);

        this.updateAdvancedSearch(as);
        });
    
    };

    onStartClick = (e) => {
        const selectedPage = 0;
        const offset = 0;
    
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            console.log(this.state.offset + " offset - perpage " + this.state.perPage);
            let as = this.getAdvancedSearch();

            as.loading = true;
            as.skip = offset;
            as.pageSize = this.state.perPage;

            this.runDetailSearch(as);

            this.updateAdvancedSearch(as);
        });
    
    };

    onEndClick = (e) => {
        const selectedPage = this.state.pageCount-1;//e.selected;
        const offset = selectedPage * this.state.perPage;
        
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            console.log(this.state.offset + " offset - perpage " + this.state.perPage);
            
            let as = this.getAdvancedSearch();

            as.loading = true;
            as.skip = offset;
            as.pageSize = this.state.perPage;

            this.runDetailSearch(as);

            this.updateAdvancedSearch(as);
        });
    
    };   

    handleCancelUpdate() {
        this.setState({
          show: false
        });
      }
    
    handleColumnClick() { 
        this.setState({
            show: !this.state.show
        });
    }

    buildColumnEditor(advancedSearch) {
        console.log("buildColumnEditor")
        console.log(advancedSearch.columns)
        //sets the columns available for editing
        let list = advancedSearch.columns;
    
        let popupContent = list.map(function(c) {
          if (c.Visible && c.Enabled) {
            return (
                <>
                    <div className="inputLabel"> 
                        <div>
                            <input id={c.Field} type="checkbox" ref={c.Field} defaultChecked />
                        </div>
                        <div>{c.Title}</div>
                    </div> 
                </>
            );
          } else {
                if(!c.Enabled){
                    return (
                        <>
                            <div className="inputLabel" style={{display:"none"}}> 
                                <div style={{display:"none"}}>
                                    <input id={c.Field} type="checkbox" ref={c.Field} />
                                </div>
                                <div style={{display:"none"}}>{c.Title}</div>
                            </div> 
                        </>
                    );
                }
                else{
                    return (
                        <>
                            <div className="inputLabel"> 
                                <div>
                                    <input id={c.Field} type="checkbox" ref={c.Field} />
                                </div>
                                <div>{c.Title}</div>
                            </div> 
                        </>
                    );
                }
          }
        });
    
        return (
          <div
            id="custDisplayColumn"
           style={{ textAlign: "left", padding: "10px" }}
          >
            <h3>Grid Display Columns</h3>
            {popupContent}
            
            <div className="button-group-filt">
              <Button primary clickFunction={this.handleColumnUpdate}>
                Apply
              </Button>
              <Button addCSS="secondaryBTN"  clickFunction={this.handleCancelUpdate}>Cancel</Button>
            </div>
          </div>
        );
      }

      handleColumnUpdate(event) {
        //handles updating columns based on how they're organised
        //event.preventDefault();    
        let self = this;
        //let as = this.props.getAdvancedSearch();
        let as = this.getAdvancedSearch();
        let cols = as.columns;
        let i;
    
        for (i = 0; i < cols.length; i++) {
          let myElement = self.refs[cols[i].Field];
          let value = false;
          if (myElement.value) {
            value = myElement.checked;
          }
          cols[i].Visible = value;
        }
    
        as.columns = cols;
        console.log("updated columns");
        console.log(cols);
        console.log("upodate as");
        console.log(as)  ;      
        //this.props.updateAdvancedSearch(as);
        //this.updateAdvancedSearch(as);
    
        this.setState({
          show: false
        });
        //let as = this.getAdvancedSearch();

        as.loading = true;
        //as.skip = 10;
        as.pageSize = this.state.perPage;
         as.columns = cols;
    
        this.updateAdvancedSearch(as);
        this.runDetailSearch(as);    
        
    }

    onLoadMore(){
        this.setState({
            perPage: this.state.perPage + 10
        },()=>{
            //alert(this.state.perPage )
            let as = this.getAdvancedSearch();

            as.loading = true;
            as.take = this.state.perPage;
            //as.skip = event.page.skip;
            as.pageSize = this.state.perPage;

            this.runDetailSearch(as);

            this.updateAdvancedSearch(as);
            if(as.take >= as.total.length){
                this.setState({
                    loadMorelabel: "Nothing more to load"
                });
            }
        });        
    }


    updateWindowDimension=()=>{ 
        this.setState({
            windowWidth: window.innerWidth,
            profileDisplay: ""
        }) 
        
        if(this.state.windowWidth<=768){ 
            //alert("Mobile") 
            if(this.state.windowWidth!=this.state.windowWidthPrevious){
                this.setState({
                    searchFormDisplay: "none"
                }) 
            }
        }else{
            //alert("Desktop");
            this.setState({
                searchFormDisplay: ""
            })
        }

        this.setState({
            windowWidthPrevious: window.innerWidth 
        }) 

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    }

    showSearchForm(){
        this.setState({
            searchFormDisplay: ""
        })  
    }

    hideSearchForm(){
        this.setState({
            searchFormDisplay: "none"
        })  
    } 

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
      // alert("Show Insights")
      if(this.state.InsightBox==0){
          this.setState({
              InsightBox: 1
          }); 
      }else{
          this.setState({
              InsightBox: 0
          });
      }
  }

  hideInsightBox(){
      // /alert("Hide Insights")
      this.setState({
          InsightBox: 0
      });
  }

  moreInsights(){ 
      this.handleLeftMenuCallback("insights", "","");
      // window.open('/insights') 
  }



    render() {

        console.log("WINDOW: "+this.state.windowWidth);
        let searchFormDisplay=this.state.searchFormDisplay; 
        

        if(this.state.leftContent != this.state.pageTitle){
            return( 
                <DisplayLeftMenu 
                    page={this.state.leftContent} 
                    desktopMenu={this.state.desktopMenu} 
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                />
            )
        }

        if(this.state.hasError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        } 

        let grid;
        let filter;
        let paging = '';
        let popupContent = "";
        let popup='';
        let as = this.getAdvancedSearch();
        let divDownloadCSV = '';
        let divMobileDownloadCSV = '';
        if(!this.state.cannotDownloadCSVReport){
            divDownloadCSV=(<ButtonIcon 
                addCSS="SubButtonIcon"
                iconURL="../../../images/icons/OutlineDark/Download.svg"
                onClick={this.handleGenerateCSV}>
                Download .csv file
            </ButtonIcon>);
            divMobileDownloadCSV = (<ButtonIcon 
                addCSS="btnDownloadCSVMobile biRight"
                iconURL="../../../images/icons/OutlineDark/Download.svg" onClick={this.handleGenerateCSV}>Download .csv file</ButtonIcon>);
        }
        if(localStorage.getItem("sessionTimeout") != 0 && localStorage.getItem("sessionTimeout") != null){       

            if (as.columns) {
                popupContent = this.buildColumnEditor(as);
            }
            popup = (            
                <div className="popup-box" style={{display: this.state.show ? 'block':'none', height:"100%"}}>
                    <div className="box">                       
                        {/* {this.buildColumnEditor(as)} */}
                        {popupContent}
                    </div>
                </div>
            );
            
            var columnBtnStatus=0;
            if (as.loading) {
                //if not finished loading show loading circle
                grid = <ProgressDisplay />;

                filter = (
                    <div>         
                        <SearchLoanBox
                            display={searchFormDisplay}
                            closeAction={()=>this.hideSearchForm()}
                            handleDetailedSearch={this.handleDetailedSearch}
                            getAdvancedSearch={this.getAdvancedSearch}
                            resetAdvancedSearch={this.resetAdvancedSearch}
                            updateAdvancedSearch={this.updateAdvancedSearch}
                            handleGenerateCSV={this.handleGenerateCSV}
                            canGenerateCsv={this.state.userIsNotBroker} 
                            slbActionGTC="1fr 1fr"  
                        />
                    </div>
                );
            } else {
 

                
      
                if (as.results && as.results.length > 0) {
                    // if search results are not nothing then return them in the grid by passing arguments
                    let columns = [];
                    let gridColumns = as.columns;       

                    gridColumns.forEach(function(c) {
                        if (c.Visible) {
                            // columns.push(
                            //   <Column
                            //     key={c.DetailSearchColumnId}
                            //     field={c.Field}
                            //     title={c.Title}
                            //     width={c.Width + "px"}
                            //   />
                            // );
                            columns.push(c.DetailSearchColumnId, c.Field,c.Title);   
                        }
                    });

                    grid = (
                        <div id="af-results">
                            {/* <Grid
                                id="search-grid"
                                //style={ gridStyle }
                                data={as.results}
                                onRowClick={this.rowClick}
                                sortable={{ allowUnsort: true, mode: "multiple" }}
                                sort={this.state.sort}
                                onSortChange={this.sortChange}
                                resizable={true}
                                pageable={true}
                                onPageChange={this.pageChange}
                                total={as.total}
                                skip={as.skip}
                                pageSize={as.pageSize}
                                >            
                                {columns}            
                            </Grid> */}
                            <DataTableSearch
                                id="search-grid"
                                //style={ gridStyle }
                                matter={as.results}
                                columns={as.columns}
                                onRowClick={this.rowClick}
                                sortable={{ allowUnsort: true, mode: "multiple" }}
                                sort={this.state.sort}
                                onSortChange={this.sortChange}
                                resizable={true}
                                pageable={true}
                                //onPageChange={this.pageChange}
                                total={as.total}
                                skip={as.skip}
                                pageSize={as.pageSize}
                                >
                                {columns} 
                            </DataTableSearch>
                        </div>
                    );

                    paging=(
                        <DataTablePaging
                            pageCount= {this.state.pageCount}
                            currentPage={this.state.currentPage}
                            handlePageClick={this.handlePageClick}
                            items={this.state.offset}
                            total={as.total}
                            onEndClick={this.onEndClick}
                            onStartClick={this.onStartClick}
                        >
                            {/* <ButtonIcon 
                                addCSS="SubButtonIcon"
                                iconURL="../../../images/icons/OutlineDark/Download.svg"
                                onClick={this.handleGenerateCSV}>
                                Download .csv file
                            </ButtonIcon> */}
                            {divDownloadCSV}
                        </DataTablePaging>
                    )
                    
                    columnBtnStatus=1;

                } else {
                    if (this.state.message)
                        //otherwise do nothing or display error
                        //   grid = <NoticeDisplay messageHtml={this.state.message} />;
                        grid="";
                    else {
                        //grid = "No matching records found";
                        paging="";
                    }

                    columnBtnStatus=0;
                }

                var slbActionGTC="1fr 1fr";
                if(columnBtnStatus==1){
                    slbActionGTC="1fr 1fr auto";
                }

                filter = (
                    <div>
                        <SearchLoanBox
                            display={searchFormDisplay}
                            closeAction={()=>this.hideSearchForm()}
                            handleDetailedSearch={this.handleDetailedSearch}
                            getAdvancedSearch={this.getAdvancedSearch}
                            resetAdvancedSearch={this.resetAdvancedSearch}
                            updateAdvancedSearch={this.updateAdvancedSearch}
                            handleGenerateCSV={this.handleGenerateCSV}
                            canGenerateCsv={this.state.userIsNotBroker}
                            columnAction={()=>this.handleColumnClick()}
                            columnBtnStatus={columnBtnStatus}
                            slbActionGTC={slbActionGTC}                            
                        />         
                    </div>
                );

            }
        }
        else{
            return <Redirect to="/" />;
        }

        return ( 
            
            <LeftMenuContent 
                id="SearchPage" 
                page="search" 
                desktopMenu={this.state.desktopMenu} 
                callBack = {this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 

                <ProfileMenu
                    addCSS={this.state.profileDisplay=="display"?"table":"none"}
                    clickAction={() => this.toggleProfileMenu()}
                />
                    
                <div className="content"> 

                    <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                    {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                        <IconBox 
                            addCSS="btnClose" 
                            iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                            tooltip="Close Insights"
                            clickAction={() => this.hideInsightBox()} 
                        />

                        <TitleSegment>Loan Insights</TitleSegment>

                        <div className='StatBar'>
                            <div className='SBLabel'>Stats</div>
                            <div className='SBData'>100</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Settled Matters</div>
                            <div className='SBData'>100</div>
                        </div>

                        <div className='StatGroup'>
                            <div className='SGTitle'>Number of Settled Matters</div>
                            <div className='SGBar'>
                                <div className='SGLabel'>NSW</div>
                                <div className='SGData'>1000</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>VIC</div>
                                <div className='SGData'>500</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>QLD</div>
                                <div className='SGData'>356</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>WA</div>
                                <div className='SGData'>576</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>SA</div>
                                <div className='SGData'>234</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>TAS</div>
                                <div className='SGData'>642</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>NT</div>
                                <div className='SGData'>123</div>
                            </div> 
                        </div> 

                        <div className='StatBar'>
                            <div className='SBLabel'>Average Days to Settlement</div>
                            <div className='SBData'>3</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Settled using eSign Documents</div>
                            <div className='SBData'>25</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Escalated Files</div>
                            <div className='SBData'>30</div>
                        </div>

                        <div className='StatAction'>
                            <ButtonIcon
                                id="insights"
                                addCSS="ButtonIconSelected biRight mainBtn"
                                iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                onClick={()=>{this.moreInsights()}}
                            >More Insights</ButtonIcon>
                        </div> 

                    </div> */}



                    {/* <BGTransparentDark /> */}
                        {filter}
                        
                        <div>
                            
                            <div>
                                <Button
                                    addCSS="btnSearch secondaryBTN TextIconCenter "
                                    clickFunction={()=>this.showSearchForm()}
                                >    
                                    <TextIcon
                                        tiText="Search Loan"
                                        iconURL="../../../images/icons/OutlineDark/Search.svg"
                                    />
                                </Button>
                            </div>
                            

                            { grid?(
                                <>
                                    <div className="filterBox">
                                        {divMobileDownloadCSV}
                                        {/* <ButtonIcon 
                                        addCSS="btnDownloadCSVMobile biRight"
                                        iconURL="../../../images/icons/OutlineDark/Download.svg" onClick={this.handleGenerateCSV}>Download .csv file</ButtonIcon> */}

                                        <ButtonIcon 
                                        addCSS="btnColOptions biRight"
                                        iconURL="../../../images/icons/OutlineDark/Options.svg"  onClick={this.handleColumnClick}>Column options</ButtonIcon>
                                    </div>

                                    <Card addCSS="dataCard">
                                        {popup}
                                        {/* <Popup
                                            anchor={this.anchor}
                                            show={this.state.show}
                                            popupClass={"popup-content"}
                                        >
                                            {popupContent}
                                        </Popup> */}
                                        <div>
                                            {/* {popupContent} */}
                                        </div>
                                        {/* <DataTableSearch matter={this.state.matter}/> */}
                                        {grid}
                                        {/* {searchResults} */}

                                        {/* <ButtonLoadMore 
                                            btnLabel="Load more..."
                                            addCSS="btnLoadMore"
                                            onClickFunction={this.onLoadMore}
                                            //resultDisplay="1 of 10"
                                        /> */} 
                                        {paging}
                                    </Card>     
                                    
                                </>
                                ):''
                            }

                                                
                        </div>
                    </div>                  

            </LeftMenuContent>  
        )
    }
}
SearchPage=AutoLogout(SearchPage);
export default SearchPage
