import React, { Component } from 'react'
import { Redirect }                                         from 'react-router-dom';
import CenterContent from '../../template/CenterContent/CenterContent'; 
import LoginForm from '../../organism/LoginForm/LoginForm'; 
import Card from '../../atom/Card/Card';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';
import ResetPasswordPage  from '../../pages/ResetPasswordPage/ResetPasswordPage'; 
import DisclaimerPage from '../../pages/DisclaimerPage/DisclaimerPage';
import CreateAccountPage from '../../pages/CreateAccountPage/CreateAccountPage';
import {AuthService}                                  from '../../../services/auth-service';
import Label from '../../atom/Label/Label';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import Button from '../../atom/Button/Button';

import './css/DischargeApplicationPage.css';

import MSALogo from '../../../images/logo/MSANational.png';
import LoanParty from '../../organism/LoanParty/LoanParty';
import RadioButtonGroup from '../../molecule/RadioButtonGroup/RadioButtonGroup';
import RadioButton from '../../atom/RadioButon/RadioButon';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
//import DischargeApplicationForm from '../../organism/DischargeApplicationForm/DischargeApplicationForm';

class DischargeApplicationPage extends Component {
    constructor(props){
        super(props);
        this.state={                
                
        }
        
    }

    
    

    render() {
        
        return (             
            <CenterContent id="DischargeApplicationPage">
                <MarginGuide />    
                <div className="content">
                    {/* <Card> */}
                        {/* <DischargeApplicationForm loginFormCallback = {this.handleLoginFormCallback}/>  */}
                        <>             
                            <h4>Discharge Application</h4>                
                            <Card>
                                {/* <ValidationText>fsdf </ValidationText> */}
                                <Label>Lender Reference</Label>
                                <FormTextBox type="text" className="formTextBox" placeholder="e.g. MNXXXXXXXXXXXXXXXX"  />
                                <Label>Discharge Type</Label>
                                <RadioButtonGroup onChange={this.onChange}>
                                    {/* <RadioButton value="full" name="dischargeType"/><label for="full" >Full</label>                                
                                    <RadioButton value="partial" name="dischargeType"/><label for="partial" >Partial</label> */}
                                    <RadioButton value="full" name="dischargeType" for="Full"/>                              
                                    <RadioButton value="partial" name="dischargeType" for="Partial"/>
                                </RadioButtonGroup>
                            </Card>
                            

                            <h5>Loan Party Details</h5> 
                            <Card>  
                                <LoanParty></LoanParty>
                                {/* <h6>Loan Party #1</h6>
                                <Label>Party Type</Label>
                                <FormTextBox type="text" className="formTextBox" placeholder="User Code"  />
                                <Label>Individual or Company</Label>
                                <FormTextBox type="password" className="formTextBox" placeholder="New Password"  />
                                <FormTextBox type="password" className="formTextBox" placeholder="Confirm Password"  /> */}
                                <Button onClick={ (e)=>this.handleSendCode(e)}>Change Password</Button>
                            </Card>
                            <Button onClick={ (e)=>this.handleSendCode(e)}>Change Password</Button>
                            {/* <br/>
                            <DisplayMessage message="{this.state.respMessage}" /> */}
                        </>
                    {/* </Card>  */}
                    
                </div>
            </CenterContent>  
        )
    }
}

export default DischargeApplicationPage;