import React, { Component } from 'react'
 

import Logo from '../../atom/Logo/Logo';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import Button from '../../atom/Button/Button';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
import ValidationText from '../../atom/ValidationText/ValidationText';
import { Redirect }                                         from 'react-router-dom';
import {AuthService}                                  from '../../../services/auth-service';

import './css/PasswordCodeForm.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class PasswordCodeForm extends Component {
    constructor(props){
        super(props);
        this.state = {            
            errorMessage: null,
            userName: "",
            enterCode:null,
            forgotPassword:false,
            cancel:null,
            successMessage: null,
            loginInProgress: false,
        };
        this.updateUsername = this.updateUsername.bind(this);
        this.handleRequestCode = this.handleRequestCode.bind(this);
    }

    componentDidMount(){
        this.setState({
            userName: this.props.userName
        })
    }

    updateUsername(event){
        this.setState({
            userName: event.target.value
        });
    }

    handleRequestCode(event)
    {
        event.preventDefault(); //prevent function from happening on load 
        //this.CleanErrorAndSuccess();
        this.setState({
            loginInProgress: true,
        });
        if (!this.state.userName) {
            this.setState({
                
                errorMessage: 'Email is required. Please enter and retry.',
                loginInProgress: false,
            },()=>{setTimeout(()=>{this.setState({resetPasswordButton: "submit-button"})},1000)});

            return;
        }

        AuthService.requestUserPasswordCode(this.state.userName).then((resp) => {
            this.setState({
                errorMessage:null,
                successMessage:resp.Message,
                forgotPassword: true,
                enterCode: true,
                loginInProgress: false,
            },()=>{this.props.callBack([this.state.userName, this.state.enterCode, this.state.successMessage]);})
        },
        (err)=>{
            this.setState({
                errorMessage: "There is an error with the request",
                //errorMessage: err,
                //errorMessage:'Email you have provided does not exist in our system.',//+err,    
                loginInProgress: false,            
            },()=>{setTimeout(()=>{this.setState({resetPasswordButton: "submit-button"})},1000);});
            return;
        });
    }

    handleGoBack(event){
        //Goes back to Login page From Forgot Password page
        event.preventDefault();
        //this.CleanErrorAndSuccess();
        this.setState({
            cancel:null,
            enterCode:null,
            errorMessage:null,
            userName:'',
        });
        this.props.callBack([false,null]);
    }

    render() {   
        let progressIcon = "";
        
        if (this.state.loginInProgress) {   
            progressIcon=<ProgressDisplay/>;
        }  
        
        else{
            progressIcon=(
            <ButtonGroup>
                <Button clickFunction={ (e)=>this.handleRequestCode(e)}>Request Code</Button>
                <Button 
                    addCSS="tertiaryBTN"
                    clickFunction={ (e)=>this.handleGoBack(e)}>Cancel</Button> 
            </ButtonGroup>);
        }  
        return (
            <> 
                <Logo />
                    <h5>Reset Password</h5>
                    <p>
                        We will send a text to your verified mobile number or an email to your registered email address. Please provide your registered email below.
                    </p>
                    {/* <p>
                        Don't have an account yet? Click <a onClick={this.createAccount}>here</a>.
                    </p> */}

                    <ValidationText  msgeType="errorMsge">{this.state.errorMessage}</ValidationText>
                    <FormTextBox type="text" className="emailTextBox" placeholder="Your Email" onChange={this.updateUsername} defaultValue={this.state.userName} value={this.state.userName} />
                    {/* <ButtonGroup>
                        <Button onClick={ (e)=>this.handleGoBack(e)}>Cancel</Button>
                        <Button onClick={ (e)=>this.handleRequestCode(e)}>Request Code</Button>
                    </ButtonGroup> */}
                    {progressIcon}
                   </>
            
        )
    }
   
}

export default PasswordCodeForm
