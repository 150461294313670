import React, { Component } from 'react'
 

import Logo from '../../atom/Logo/Logo';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import FormTextBoxIcon from '../../atom/FormTextBoxIcon/FormTextBoxIcon';
import Button from '../../atom/Button/Button';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import ValidationText from '../../atom/ValidationText/ValidationText';

import { Redirect } from 'react-router-dom';
import {AuthService} from '../../../services/auth-service';

import './css/ResetPasswordForm.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class ResetPasswordForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            versionNo: '',
            errorMessage: false,
            data: null,
            userName: "",
            userPassword: "",
            loginInProgress: false,
            errorMessage: "",
            shouldRedirect: false,
            mustAuthenticate:false,
            codeRecipient: '',
            forgotPassword:false,
            userType:null,    
            unknownForgotPassword: false,
            createAccount: false,
            cencel:null,
            respMessage: null,
            externalPrivileges: [],
            matterRestrictions: []
            
        };
        this.updateUsername = this.updateUsername.bind(this);
        this.handleRequestCode = this.handleRequestCode.bind(this);
        this.handleCancel = this.handleCancel.bind(this);        
        this.handleUserCodeChanged = this.handleUserCodeChanged.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handleConfirmPasswordChanged = this.handleConfirmPasswordChanged.bind(this);
        this.backClicked = this.backClicked.bind(this);
    }

    componentDidMount(){
        //alert(this.props.userName);
        this.setState({
            userName: this.props.resetDetails[0],
            respMessage: this.props.resetDetails[1],
            codeRecipient: this.props.resetDetails[1].includes('@')?'email':'mobile phone',
        },()=>{
            //alert(this.state.userName+""+this.state.respMessage)
        })
    }

    backClicked(event){
        alert();
        // this.setState({
        //     goBack: true
        // },()=>{
        //     this.props.createAccountCallback([this.state.goBack,null])
        // });

    }

    updateUsername(event){
        this.setState({
            userName: event.target.value
        });
    }

    handleUserCodeChanged( event ) {    //Gets new user code when entered, nonfunctional backend api
        this.setState({
            userCode: event.target.value
        });
    }

    handlePasswordChanged( event ) {    //Gets new password when entered, nonfunctional backend api           
        this.setState({
            userPassword: event.target.value,
            passwordInputClassName:"input-boxes input-text-box active-input",

        });
    }    

    handleConfirmPasswordChanged( event ) {    //Gets new password when entered, nonfunctional backend api           
        this.setState({
            confirmUserPassword: event.target.value
        });
    }


    handleRequestCode(event)
    {
        event.preventDefault(); //prevent function from happening on load 
        //this.props.callBack(true);
        //this.CleanErrorAndSuccess();
        if (!this.state.userName) {
            this.setState({
                
                errorMessage: 'User Name is required. Please enter and retry.',                
               
            },()=>{setTimeout(()=>{this.setState({resetPasswordButton: "submit-button"})},1000)});

            return;
        }

        AuthService.requestUserPasswordCode(this.state.userName).then((resp) => {
            this.setState({
                errorMessage:null,
                successMessage:resp.Message,
                forgotPassword: true,
                enterCode: true,
            },()=>{
                this.props.callBack(true);
            })
        },
        (err)=>{
            this.setState({
                errorMessage:'Sorry, there is an error with the request.',//+err,
                resetPasswordButton:'submit-button error',
                resetPasswordEmailInput:this.state.resetPasswordEmailInput+" input-error",
            },()=>{setTimeout(()=>{this.setState({resetPasswordButton: "submit-button"})},1000);});
            return;
        });
    }

    

    handleSendCode(event){
        event.preventDefault();
        //alert("gdgd");
        //this.props.callBack(true);
        //return;
        //this.CleanErrorAndSuccess();

        this.setState({
            loginInProgress: true,
        });
        //Sends api call to begin expecting entry of code from text
        if (!this.state.userCode) {
            this.setState({
                successMessage:'',
                loginInProgress: false,
                errorMessage: 'Security Code is required. The code has been sent to your email.',
            });

            return;
        }
        if (this.state.userPassword!==this.state.confirmUserPassword) {
            this.setState({
                successMessage:'',
                loginInProgress: false,
                errorMessage: 'Your password and confirmation password do not match. Please try again.',
                
            });

            return;
        }
        //eslint-disable-next-line
        const regExp=/^(?=.*[A-z])(?=.*\d)(?=.*[#$^+=!*()@%&?/\\\,.|~_\-\[\]]).{8,}$/;
        if(regExp.test(this.state.userPassword)){
        this.setState({loginInProgress:true},()=>{
            AuthService.changePassword(this.state.userName, this.state.userPassword, this.state.userCode.trim()).then((resp) => ( //Fire off Authservice function to api  providing username and new password 
        
                this.setState({                                                            
                    createAccount:false,
                    forgotPassword:false,
                    enterCode:false,
                    //userType:null,
                    loginInProgress: false,
                    shouldRedirect:resp.IsSuccessful,
                    errorMessage:null,
                    userType:resp.UserTypeId==undefined?resp.User.UserType.UserTypeId:resp.UserTypeId,
                    mustAuthenticate: resp.MustAuthenticate
                },()=>{
                    //alert(this.state.shouldRedirect);
                    
                    if((this.state.userType == 3 || this.state.userType == 2) && this.state.shouldRedirect && !this.state.mustAuthenticate){
                        AuthService.getUserExternalPrivilegesList().then((resp)=>{
                            this.state.externalPrivileges.push(resp.data.Content);   
                            AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                            //set user matter type restrictions
                            AuthService.GetUserMatterTypeRestriction().then((resp)=>{
                                this.state.matterRestrictions.push(resp.data.Content);   
                                AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                                this.props.callBack([this.state.shouldRedirect, this.state.mustAuthenticate, this.state.userPassword]);
                                },(err)=>{
                                    //alert(err)
                                    alert("There is an error with the request")
                                        this.setState({
                                        hasLoginError: true
                                        })
                                });
        
                            
                       },(err)=>{
                           //alert(err)
                           alert('Sorry, there is an error with the request.')
                       });
                    }
                    else if((this.state.userType == 4) && this.state.shouldRedirect && !this.state.mustAuthenticate){
                        AuthService.getUserBrokerExternalPrivilegesList().then((resp)=>{
                            this.state.externalPrivileges.push(resp.data.Content);   
                            AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                            //set user matter type restrictions
                            AuthService.GetUserMatterTypeRestriction().then((resp)=>{
                                this.state.matterRestrictions.push(resp.data.Content);   
                                AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                                this.props.callBack([this.state.shouldRedirect, this.state.mustAuthenticate, this.state.userPassword]);
                                },(err)=>{
                                    //alert(err)
                                    alert("There is an error with the request")
                                        this.setState({
                                        hasLoginError: true
                                        })
                                });
        
                            
                       },(err)=>{
                           //alert(err)
                           alert('Sorry, there is an error with the request.')
                       });
                    }
                    else{
                        this.props.callBack([this.state.shouldRedirect, this.state.mustAuthenticate, this.state.userPassword]);
                    }                     
                    

                })
            ), (err) => {
                this.setState({
                    createAccount:false,
                    forgotPassword:true,
                    enterCode:true,
                    loginInProgress: false,
                    shouldRedirect:false,
                    errorMessage:'Please check your security code and details.',                    
                },()=>{setTimeout(()=>{this.setState({submitResetButton: "submit-button"})},1000);});
            });
            // this.setState({
            //     loginInProgress:false
            // });


        });       
      
    }else{
            this.setState({
                errorMessage:'Please check your security code and details.Please ensure your password is 8 characters long. It must contain an uppercase letter, symbol and number.',
            },()=>{
                this.setState({
                    loginInProgress:false
                });
            });
        }
    }

    handleCancel(event){
        this.props.cancelCallback([false,null]);
    }

    render() {    
        let progressIcon = "";
        
        if (this.state.loginInProgress) {   
            progressIcon=<ProgressDisplay/>;
        }else{
            progressIcon=(  
                <div className="buttonBox">  
                    <Button  
                        clickFunction={ (e)=>this.handleSendCode(e)}>Change Password</Button> 
                    <Button 
                        addCSS="tertiaryBTN" 
                        clickFunction={ (e)=>this.handleCancel(e)}
                    >
                        Cancel
                    </Button> 
                </div> 
            );
        }    
        return (
            <> 
                <Logo />
                    <h5>Please enter the code sent to your {this.state.codeRecipient} on Security Code field. </h5>
                    
                    <p>
                        Your new password must contain an uppercase letter, lowercase letter, symbol, number and must be at least 8 characters long
                    </p>
                    
                    <ValidationText  msgeType="errorMsge">{this.state.errorMessage}</ValidationText>


                    <FormTextBoxIcon 
                        id="resetEmail"
                        type="text"   
                        title="Your Email" 
                        placeholder="Your Email" 
                        icon={"../../../../images/interface/iconEmailOutlineDark.svg"}
                        onChange={this.updateUsername}
                        defaultValue={this.props.resetDetails[0]}
                        autocomplete="off"   
                    />

                    <FormTextBoxIcon 
                        id="resetUserCode"
                        type="text"   
                        title="Security Code" 
                        placeholder="Security Code" 
                        icon={"../../../../images/icons/OutlineDark/User_Soild.svg"}
                        onChange={this.handleUserCodeChanged} 
                        autocomplete="new-password"  
                    />

                    <FormTextBoxIcon 
                        id="resetNewPassword"
                        type="password"   
                        title="New Password" 
                        placeholder="New Password" 
                        icon={"../../../../images/interface/iconLockOutlineDark.svg"}
                        onChange={this.handlePasswordChanged} 
                        autocomplete="new-password" 
                    />

                    <FormTextBoxIcon 
                        id="resetConfirmPassword"
                        type="password"   
                        title="Confirm Password" 
                        placeholder="Confirm Password" 
                        icon={"../../../../images/interface/iconLockOutlineDark.svg"}
                        onChange={this.handleConfirmPasswordChanged} 
                        autocomplete="new-password"  
                    />


                    {/* <FormTextBox type="text" className="emailTextBox" placeholder="Your Email" onChange={this.updateUsername} defaultValue={this.props.resetDetails[0]} /> */}

                    {/* <FormTextBox type="text" className="emailTextBox" placeholder="User Code" onChange={this.handleUserCodeChanged} /> */}

                    {/* <FormTextBox type="password" className="passwordTextBox" placeholder="New Password" onChange={this.handlePasswordChanged} /> */}

                    {/* <FormTextBox type="password" className="passwordTextBox" placeholder="Confirm Password" onChange={this.handleConfirmPasswordChanged}  /> */}

                    {/* <Button onClick={ (e)=>this.handleSendCode(e)}>Change Password</Button> */}
                    
                    {progressIcon}
                    
                    <br/>
                    
                    <DisplayMessage message={this.state.respMessage} />
                     
                   </>
            
        )
    }
   
}

export default ResetPasswordForm
