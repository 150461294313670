import React, { Component } from 'react'

import './css/IconBox.css';

class IconBox extends Component {
    render() {
        return (
            <div 
                className={"IconBox "+this.props.addCSS} 
                id={this.props.id}
                title={this.props.tooltip}
                style={{ backgroundImage: `url(${this.props.iconURL})`}}
                onClick={this.props.clickAction}
                onMouseEnter={this.props.mouseEnter}
                onMouseOut={this.props.mouseOut}
            >
                
            </div>
        )
    }
}

export default IconBox
