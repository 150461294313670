import React from "react";
import "./css/autologout.css";
// import { Redirect }                                         from 'react-router-dom';
// import { Button } from "./controls/standard-button";
import { AuthService }                                      from './services/auth-service';
import PropTypes                                            from 'prop-types'
import ButtonIcon from "./components/atom/ButtonIcon/ButtonIcon";
import { Redirect } from 'react-router-dom';
import {withRouter}  from 'react-router-dom'; 
import Button from "./components/atom/Button/Button";
import ButtonGroup from "./components/molecule/ButtonGroup/ButtonGroup";
//export default function(ComposedClass){
    export default function AutoLogout(ComposedClass){

    //class AutoLogout extends React.Component {
        return class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {
                warningTime: (15-2) * 60 * 1000,//100 * 60 * 10, //default 15 minutes
                signoutTime: 15 * 60 * 1000,//100 * 60 * 15,
                isWarning: false,
                //session: 'sessionTimeout',
                loading:true,
                ssoTimeout: 'ssoTimeout',
                ssoSignoutTime: 15 * 60 * 1000,//100 * 60 * 15,
                shouldRedirect: false,
                redirectUrl: 'newloans-dashboard',
                //url:'https://tictocssouat.msanational.com.au'//'https://tictocssouat.msanational.com.au/Home/Refresh?url='//'http://localhost:7360/Home/Refresh?url='//
                ssourl: AuthService.getSSOUrl()
            };

            this.extendSession = this.extendSession.bind(this);
            this.endSession = this.endSession.bind(this);

        }

        static contextTypes = {
            router: PropTypes.object
        }

        nextPath(path,arg){
            // this.context.router.history.push(
            //     {pathname:path,
            //     state: {user: arg}});
            this.props.history.push({pathname:path,
                state: {user: arg}
    });
        }

        componentDidMount(){
             //alert(window.location.href);
             var url = window.location.href;
             var i = url.lastIndexOf('/');
             var redirectUrl = url.substring(i+1);
            console.log("initload" + this.state.warningTime)
                console.log("initload" + this.state.signoutTime)
            //alert("kfjksalg");
            //console.log("HOC");
            //alert(localStorage.getItem("sessionTimeout"));
            console.log(localStorage.getItem("sessionTimeout"));
            if(localStorage.getItem("ssoTimeout") != 0 && localStorage.getItem("ssoTimeout") != null)
            {
                //alert(localStorage.getItem("ssoDateTimeout"));
                this.useLocalStorage = (typeof localStorage !== 'undefined');
                console.log("ssotimeout " + localStorage.getItem(this.state.session));
                let sessionTimeout = localStorage.getItem("ssoTimeout");
                
                //alert(sessionTimeout);
                // if(sessionTimeout == "undefined")
                // {
                //     sessionTimeout = 15;
                // }
    
                //sessionTimeout = 5;//for testing
                
                this.setState({
                    warningTime: (sessionTimeout-(sessionTimeout-1)) * 60 * 1000,
                    redirectUrl: redirectUrl,

                    //signoutTime: (sessionTimeout) * 60 * 1000,
                },()=>{
                    console.log(this.state.warningTime)
                    console.log(this.state.signoutTime)
                    this.setSSOTimeout();
                })
            }
            else{
                // if(localStorage.getItem("sessionTimeout") != 0 && localStorage.getItem("sessionTimeout") != null)
                // {
                //     this.useLocalStorage = (typeof localStorage !== 'undefined');
                // console.log(localStorage.getItem(this.state.session));
                // let sessionTimeout = localStorage.getItem("sessionTimeout");

                // if(localStorage.getItem('ssoDateTimeout'))
                // {
                //     localStorage.removeItem('ssoDateTimeout');
                // }
                
                // //alert(sessionTimeout);
                // if(sessionTimeout == "undefined")
                // {
                //     sessionTimeout = 15;
                // }

                // //sessionTimeout = 5;//for testing
                
                // this.setState({
                //     warningTime: (sessionTimeout-2) * 60 * 1000,
                //     signoutTime: (sessionTimeout) * 60 * 1000,
                // },()=>{
                //     console.log(this.state.warningTime)
                //     console.log(this.state.signoutTime)
                //     this.setTimeout();
                // })
                
            
                
                // this.events = [
                //     'load',
                //     'mousemove',
                //     'mousedown',
                //     'click',
                //     'scroll',
                //     'keypress'
                // ];

                // for(var i in this.events){
                //     window.addEventListener(this.events[i], this.resetTimeout);
                //     console.log(i+"a")
                // }

                // //this.setTimeout();
                // }
                
            }
        }

        clearTimeoutFunc = () => {
            if(this.warnTimeout) clearTimeout(this.warnTimeout);
            if(this.logoutTimeout) clearTimeout(this.logoutTimeout);
        }

        // setTimeout = () => {
        //     this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
        //     this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
        // }

        setSSOTimeout = () => {
            this.warnTimeout = setTimeout(this.ssoWarn, this.state.warningTime);
            //this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
        }

        resetTimeout = () => {
            this.clearTimeoutFunc();
            this.setTimeout();
        }

        ssoWarn = () => {
            //if time now is same as time passed in the parameter
            let today = new Date();
            let paramdate=new Date(localStorage.getItem("ssoDateTimeout"));
            console.log(today);
            console.log("paramdate" + paramdate);

            // var dt = new Date();
            //         dt.setMinutes(dt.getMinutes()+json.SessionTimeout);
          
            
            // if(today.getTime() >= paramdate.getTime())
            // {
            //     this.setState({
            //         shouldRedirect: true
            //     },()=>
            //     {
            //         //alert(this.state.redirectUrl + this.state.url);
            //         console.log(localStorage.getItem("ssoTimeout"));
            //         let ssoTimeoutMinutes = parseInt(localStorage.getItem("ssoTimeout"));
            //         var dt = new Date();
            //         dt.setMinutes(dt.getMinutes()+ssoTimeoutMinutes);
            //         console.log(dt);
            //         AuthService.setSSODateTimeout(dt);
            //         //window.location.href = "";//this.state.redirectUrl + this.state.url;
            //         console.log("refreshing session");
                    
            //         this.setSSOTimeout();
            //     });
                
                
            // }
            // this.setSSOTimeout();
           
            console.log("ssowarn");

            if(today.getTime() >= paramdate.getTime())
            {
                this.setState({
                    shouldRedirect: true
                },()=>
                {
                    //alert(this.state.redirectUrl + this.state.url);
                    // console.log(localStorage.getItem("ssoTimeout"));
                    // let ssoTimeoutMinutes = parseInt(localStorage.getItem("ssoTimeout"));
                    // var dt = new Date();
                    // dt.setMinutes(dt.getMinutes()+ssoTimeoutMinutes);
                    // console.log(dt);
                    // AuthService.setSSODateTimeout(dt);
                    // //window.location.href = "";//this.state.redirectUrl + this.state.url;
                    // console.log("refreshing session");
                    
                    // this.setSSOTimeout();
                    AuthService.SSOlogout();
                    console.log("logging out");
                });
                
                
            }
            this.setSSOTimeout();
            
        }

        // warn = () => {
        //     //window.alert("You will be loogged out automatically in 1 minute")            
        //     console.log("You will be logged out automatically in 2 minutes")
        //     console.log(this.state.warningTime)
        //     console.log(this.state.signoutTime)
        //     this.setState({
        //         isWarning: true
        //     });
            
        // }

        logout = () => {
            //Send a logout request to the API
            this.setState({
                isWarning: false
            })
            console.log('logging out');
            this.destroy();           
        }

        destroy = () => {
            console.log("you have been logged out")
            //clear the session
            //browserHistory.push('/');
           // window.location.assign('/')
           //this.context.router.history.push('/logout');
           //AuthService.clearStorage();
           this.nextPath('/logout', null);
            //AuthService.logout();
            this.setState({loading:false,});
            console.log("logoutval" + localStorage.getItem("sessionTimeout"));
        }

        extendSession(e) {
            this.resetTimeout();
            this.setState({
                isWarning: false
            })
        }

        endSession(path,e) {
            this.logout();
            this.setState({
                isWarning: false
            });
        }

        render()
        {
            if(this.state.shouldRedirect){
                window.location.href =  this.state.ssourl + '?url=' + this.state.redirectUrl;
                //window.location.href=this.state.url;
            }
            if(this.state.loading){
                
              }else{
                return ( <Redirect to='/' /> );  
              }
            var warningModal = (
            
                <div className="popup-box" style={{display: this.state.isWarning ? 'block':'none', height:"100%"}}>
                    <div className="box" style={{height:"200px"}}>
                        <br />
                        Your login is about to expire, would you like to extend your session?
                        <br /><br />
                    {/* <input type="button" text='hello'></input> */}
                    
                        <ButtonGroup>
                            <Button
                                clickFunction={(e) => this.extendSession(e)}
                            >
                            Extend Session
                            </Button>
                            <Button
                                addCSS="tertiaryBTN" 
                                clickFunction={(e) => this.endSession('/logout', e)}
                            >
                            End Session
                            </Button>
                        </ButtonGroup>                 
                        </div>
                    
                    
                </div>
            
            );
            
            return(
                <div>
                    <ComposedClass{...this.props} />
                    {/* <ComposedClass /> */}
                    {warningModal}
                </div>
            );
        }

    }


}
//export default AutoLogout;