import React, { Component } from 'react';
import Moment from "moment";
import SAlert from "sweetalert2";

import IconBox from '../../atom/IconBox/IconBox';


import './css/DataTableDocuments.css';
import { AuthService } from '../../../services/auth-service';
import { MatterDocumentService } from '../../../services/matter-document-service';

import {
    isBrowser,
    isMobile
} from "react-device-detect";

import { saveAs } from 'file-saver';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class DataTableDocuments extends Component {
    constructor(props) {
        super(props);



        this.state = {
            loading: true,
            documents: [],
            shouldRedirect: false,
            errorMessage: null,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
        };
        //this.updateDimensions = this.updateDimensions.bind(this);
        this.rowClicked = this.rowClicked.bind(this);
        this.openDoc = this.openDoc.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true,
            documents: []
        });

        MatterDocumentService.getMatterDocuments(this.props.matterId).then(
            resp => {
                let docs = resp.data.Content.map(d => ({
                    id: d.MatterDocumentId,
                    name: d.DocName,
                    date: Moment(d.DocModDate).format("DD/MM/YYYY hh:mm A")
                }));

                this.setState({
                    documents: docs,
                    loading: false
                });
            },
            err => {
                if (err.response && err.response.status === 401) {
                    this.setState({
                        documents: [],
                        loading: false,
                        shouldRedirect: true,
                        errorMessage: 'there is an error with the request'
                    });
                } else {
                    this.setState({
                        documents: [],
                        loading: false,
                        shouldRedirect: false
                    });
                }
            }
        );
        // Upon load initialize the function for document centre height resize
        //this.updateDimensions();
        // Upon load initialize attach a function for document centre height resize
        //window.addEventListener("resize", this.updateDimensions);
    }

    rowClicked(id, name) {

        // let message = "";

        // if (message.length === 0) {
        //   message = "No matching records found";
        //   new SAlert({
        //     title: "Detailed search",
        //     text: message,
        //     type: "info"
        //   });
        // }
        // return;
        // SAlert({
        //     title: "View Document",
        //     text: "Unable to retrieve document.",
        //     type: "warning"
        //   });

        // alert(id);
        // alert(name);
        // return;
        this.setState({
            loading: true,
        });
        const self = this;
        MatterDocumentService.getDocumentAsPdf(id).then(
            resp => {
                //const file = new Blob([resp.data], {type: resp.request.response.type});
                const file = new Blob([resp.data], { type: "application/pdf" });
                const url = URL.createObjectURL(resp.data);
                var isIE = document.documentMode;

                if (!isMobile && !isIE) {
                    window.open(url);
                    //this.openDoc(url);
                    // window.open('/view-document:'+url)

                    //this.props.setDocFrame(url);
                    //   this.props.updateDocOnly();
                } else {
                    //this.props.setDocFrame(url);
                    //this.props.updateDocOnly();
                    saveAs(file, name + ".pdf");
                }

                //window.open(url);
            },
            err => {
                if (err.response && err.response.status === 401) {
                    self.setState({
                        shouldRedirect: true
                    });
                } else {
                    self.setState({
                        shouldRedirect: false
                    });

                    new SAlert({
                        title: "View Document",
                        text: "Unable to retrieve document.",
                        type: "warning"
                    });
                }
            }
        );
        this.setState({
            loading: false
        });
    }

    openDoc(url) {
        // alert(url);
        // window.open(url)

    }

    render() {
        let displayDocuments = '';
        let docHeader = '';
        if(this.state.loading){
            return(<ProgressDisplay/>);
        }
        if (this.state.documents.length == 0) {
            displayDocuments = "No Documents found";
        } else {
            docHeader = ( 
                <div className = "dtHeader">
                    <div> File Name </div> 
                    <div> Date Uploaded </div> 
                </div>
            );
            displayDocuments = this.state.documents.map(doc => (
                <div className = "dtRow" onClick = { (e) => this.rowClicked(doc.id, doc.name) }>
                    <div>
                        <div className = "dtHeaderMobile"> File Name </div> 
                        <div
                            id = { doc.id }
                            name = { doc.name }
                            // onClick = { (e) => this.rowClicked(doc.id, doc.name) }
                             >
                            <IconBox addCSS = "iconPDF"
                            iconURL = "../../../../images/icons/OutlineDark/PDF.svg" /
                            >
                            { doc.name }
                        </div>
                    </div >

                <div>
                    <div className = "dtHeaderMobile" > Date Uploaded </div>    { doc.date }
                    </div>
                </div>
            ));
        }
        return ( 
            <div className="DataTable">
            {
                        // <div className="dtHeader">
                        //     <div>File Name</div>
                        //     <div>Date Uploaded</div> 
                        // </div>
                    } 
                    { docHeader }
                <div className="dtContent">
                     
                    { displayDocuments } 
                </div>
            </div> 
        )
    }
}

export default DataTableDocuments