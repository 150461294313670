import React, { Component } from 'react'
import { Redirect }                                         from 'react-router-dom';
import CenterContent from '../../template/CenterContent/CenterContent'; 
import LoginForm from '../../organism/LoginForm/LoginForm'; 
import Card from '../../atom/Card/Card';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';
import ResetPasswordPage  from '../../pages/ResetPasswordPage/ResetPasswordPage'; 
import DisclaimerPage from '../../pages/DisclaimerPage/DisclaimerPage';
import CreateAccountPage from '../../pages/CreateAccountPage/CreateAccountPage';
import {AuthService} from '../../../services/auth-service';

import './css/LoginPage.css';

import MSALogo from '../../../images/logo/MSANational.png';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class LoginPage extends Component {
    constructor(props){
        super(props);
        let matterrestrictions = AuthService.getUserTypeRestrictions();
        this.state={       
                userName : '', 
                userPassword: '',
                userType: null,
                shouldRedirect: false,
                unknownForgotPassword: false,
                createAccount: false,
                createAccountSuccess: false,
                respMessage: '',
                mustAuthenticate: false,
                errorMessage: AuthService.getNetworkError(),
                proceedPasswordReset: false,
                matter: this.props.match.params.myKey != null?this.props.match.params.myKey.substring(1,8): null,
                matterrestrictions: matterrestrictions,
                UserHasNewLoansRestriction: null,
                UserHasDischargesRestriction: null,
                UserHasConsentsRestriction: null,
                UserHasMatterRestriction: false
        }
        this.handleCreateAccount = this.handleCreateAccount.bind(this);
        this.handleUnknownForgotPassword = this.handleUnknownForgotPassword.bind(this);
        this.handleLoginFormCallback = this.handleLoginFormCallback.bind(this);
    }

    componentDidMount(){ 
        console.log("this.props.location.state")
        console.log(this.props.location.state)
        if(this.props.location.state !=undefined){
            //alert(this.props.location.state.matterId)
            this.setState({
                matter: this.props.location.state.matterId
            });
        }
        
        document.title = "Loantrak - Login";
        //clear local storage everytime user wil visit the login page
        AuthService.clearStorage();
        //clear cache if a version is detected
        AuthService.isNewVersionDetected();

        AuthService.getVersion().then(resp => {
            this.setState({
                versionNo: resp.data.VersionNo
            }, (err) => { 
                //this.red()
                this.setState({
                    errorMessage: err
                    //errorMessage: 'There is an error with the request'//err
                },()=>{
                    //this.red()
                });
            });
        },(err)=>{
            this.setState({
                errorMessage: err
                //errorMessage: 'There is an error with the request'//err
            })
            //this.red()
        });


        // let userHasNewLoansRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        // let userHasDischargesRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        // let userHasConsentsRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasNewLoansRestiction) : false;
        // this.setState({	
        //     userHasNewLoansRestriction: userHasNewLoansRestriction,
        //     userHasDischargesRestriction: userHasDischargesRestriction,
        //     userHasConsentsRestriction: userHasConsentsRestriction,
        // }
        // var matterrestrictions = AuthService.getUserTypeRestrictions();
        // // let userHasNewLoansRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.userHasNewLoansRestriction) : null;
        // // let userHasDischargesRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasDischargesRestriction) : null;
        // // let userHasConsentsRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasConsentsRestriction) : null;
        
        // let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        // let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        // let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        
        
        // this.setState({	
        //     UserHasNewLoansRestriction: userHasNewLoansRestriction,//[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
        //     UserHasDischargesRestriction: userHasDischargesRestriction,//[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
        //     UserHasConsentsRestriction: userHasConsentsRestriction,//[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
        // },()=>{
        //     console.log("userHasNewLoansRestrictiontest"+this.state.UserHasNewLoansRestriction);
        //     console.log("userHasDischargesRestrictiontest"+this.state.UserHasDischargesRestriction);
        //     console.log("userHasConsentsRestrictiontest"+this.state.UserHasConsentsRestriction);
        // });

        
        
    }

    // red(){
    //     //alert('ddsfdsf')
    // //     this.props.history.push({pathname:"/maintenance",
    // //     state: {
    // //        // searchArg : null, 
    // //        //searchArg: this.props.searchArg
    // //     }
    // // });
    // return( <iframe src={process.env.PUBLIC_URL + 'maintenancepage.html'} width="100%" height="850px"></iframe>)
    // }

    handleCreateAccount(){
        this.setState({
            createAccount: true,
            unknownForgotPassword: false,
            forgotPassword:false,
        });
    }

    handleUnknownForgotPassword( event ) {
        event.preventDefault();
        //Go to forgotten password screen if
        //this.CleanErrorAndSuccess();        
        this.setState({
            unknownForgotPassword:true,
            forgotPassword: true,
        });
    }

    handleCallback = (childData) => {
        //alert(childData+"dfsdf")
        this.setState(
            {
                forgotPassword: childData[0],
                proceedPasswordReset: !childData[0],
                userName: '',
            }, ()=>{
                //alert(this.state.data);
                //alert(this.state.proceedPasswordReset)
            }
        );        
    }

    handleLoginFormCallback = (childData) => {
        //alert(this.state.matter)
        //console.log(childData)
        //alert(childData)
        var matterrestrictions = AuthService.getUserTypeRestrictions();
        // let userHasNewLoansRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.userHasNewLoansRestriction) : null;
        // let userHasDischargesRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasDischargesRestriction) : null;
        // let userHasConsentsRestriction = this.state.matterrestrictions != null? this.state.matterrestrictions.map(d=>d.UserHasConsentsRestriction) : null;
        
        let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        this.setState(
            {
                userName: childData[0],
                userPassword: childData[1],
                userType: childData[2],
                shouldRedirect: childData[3],
                forgotPassword: childData[4],
                unknownForgotPassword: childData[5],        
                mustAuthenticate: childData[6],
                proceedPasswordReset: false,
                UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
                UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
                UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
                UserHasMatterRestriction: ((userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false) ||
                                        (userHasDischargesRestriction[0] != null && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false) ||
                                        (userHasConsentsRestriction[0] != null && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false)
                                        )? true: false
                // UserHasNewLoansRestriction: userHasNewLoansRestriction,//[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
                // UserHasDischargesRestriction: userHasDischargesRestriction,//[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
                // UserHasConsentsRestriction: userHasConsentsRestriction,//[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
            }, ()=>{
               
        
        
        // this.setState({	
        //     UserHasNewLoansRestriction: userHasNewLoansRestriction,//[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
        //     UserHasDischargesRestriction: userHasDischargesRestriction,//[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
        //     UserHasConsentsRestriction: userHasConsentsRestriction,//[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
        // },()=>{
            console.log("userHasNewLoansRestrictiontest"+this.state.UserHasNewLoansRestriction);
            console.log("userHasDischargesRestrictiontest"+this.state.UserHasDischargesRestriction);
            console.log("userHasConsentsRestrictiontest"+this.state.UserHasConsentsRestriction);
        // });
               //alert(childData);               
               //alert(this.state.userName + "" + this.state.userPassword + " userType " + this.state.userType + " shouldRedirect " + this.state.shouldRedirect + " forgotPassword " +
               //this.state.forgotPassword + " unknownForgotPassword" + this.state.unknownForgotPassword );
            }
        );

        
        
    }
    disclaimerCallback= (ack) => {
        this.setState({
            shouldRedirect: ack
        });
    }

    handleCreateAccountCallback= (child) => {
        this.setState({
            createAccountSuccess: child[0],
            respMessage: child[1],
            createAccount:  child[0]?false:true,
        },()=>{
           // alert("from login " + this.state.createAccountSuccess);
        });
    }

    

    render() {
        if(this.state.errorMessage){
            return( <div className="App">      
            <iframe src={process.env.PUBLIC_URL + 'maintenancepage.html'} width="100%" height="850px"></iframe>
            </div>)
        }
        if (this.state.shouldRedirect) {    //If login correct, navigate to home 
            if(this.state.matter != null){
                return ( <Redirect to={"/matter-details:" + this.state.matter} /> );
            }
            else {
                if(this.state.UserHasMatterRestriction){
                    if(this.state.UserHasNewLoansRestriction){
                        return ( <Redirect to = '/dashboard' /> );
                    }
                    else{
                        if(this.state.UserHasDischargesRestriction){
                            return ( <Redirect to = '/discharges' /> );
                        }                    
                        else{
                            return ( <Redirect to = '/consents' /> );
                        }
                    }
                    
                }
                else  return ( <Redirect to = '/dashboard' /> );                
                
            }

                
        }
        if(this.state.proceedPasswordReset){
            return (<CenterContent id="LoginPage">
            <MarginGuide />    
            <div className="content">
                <Card>
                    <LoginForm responseMessage={this.state.respMessage} mustAuthenticate={this.state.mustAuthenticate} loginFormCallback = {this.handleLoginFormCallback}/> 
                </Card> 
                <div className="tac spacingBottomMedium spacingTopMedium">
                    {/* <a onClick={this.handleCreateAccount}>Create Account</a> 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    {/* <a href="mailto:loantraksupport@msanational.com.au?Subject=LoanTrak%20Login%20Issue" className="contact-us">Contact Us</a> */}
                    Forgot your password? <a onClick={this.handleUnknownForgotPassword}>Reset Password</a>
                </div>
                <div className="tac spacingBottomXL">
                    {/* Forgot your password? <a onClick={this.handleUnknownForgotPassword}>Reset Password</a> */}
                    <a href="mailto:loantraksupport@msanational.com.au?Subject=LoanTrak%20Login%20Issue" className="contact-us">Contact Us</a>
                </div>
                <div className="tac ">
                <img src={MSALogo} alt="Loantrak" />
                </div>
            </div>
        </CenterContent> );
        }
        if(this.state.createAccount)
        {
            return(<CreateAccountPage parentCallback = {this.handleCreateAccountCallback}></CreateAccountPage>);
        }
        // if(this.state.userType!==4 && !this.state.unknownForgotPassword && this.state.userType != null && !this.state.mustAuthenticate){
        //     return(<DisclaimerPage userDetails = {[this.state.userName, this.state.userPassword]} parentCallback = {this.disclaimerCallback} />);
        // }
        // if(this.state.mustAuthenticate){
        //     return(<DisclaimerPage userDetails = {[this.state.userName, this.state.userPassword]} parentCallback = {this.disclaimerCallback} />);
          
        // }
        if(!this.state.unknownForgotPassword && this.state.userType != null && !this.state.mustAuthenticate){
            return(<ResetPasswordPage userName={this.state.userName} parentCallback = {this.handleCallback}></ResetPasswordPage>);
        }
        if(this.state.forgotPassword && this.state.unknownForgotPassword){
            return(<ResetPasswordPage userName={this.state.userName} parentCallback = {this.handleCallback}></ResetPasswordPage>);
        }
        // if(this.state.forgotPassword && (this.state.userType===4 || this.state.unknownForgotPassword)){
        //     return(<ResetPasswordPage userName={this.state.userName} parentCallback = {this.handleCallback}></ResetPasswordPage>);
        // }
        if(this.state.forgotPassword && this.state.unknownForgotPassword){
            return(<ResetPasswordPage userName={this.state.userName} parentCallback = {this.handleCallback}></ResetPasswordPage>);
        }
        if(this.state.forgotPassword && (this.state.userType===4 || this.state.userType===5 || this.state.unknownForgotPassword)){
            return(<ResetPasswordPage userName={this.state.userName} parentCallback = {this.handleCallback}></ResetPasswordPage>);
        }
       
        if(this.state.createAccountSuccess){
            return ( <CenterContent id="LoginPage">
                <MarginGuide />    
                <div className="content">
                    <Card>
                        <LoginForm responseMessage={this.state.respMessage} loginFormCallback = {this.handleLoginFormCallback}/> 
                    </Card> 
                    <div className="tac spacingBottomMedium spacingTopMedium">
                        {/* <a onClick={this.handleCreateAccount}>Create Account</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        {/* <a href="mailto:loantraksupport@msanational.com.au?Subject=LoanTrak%20Login%20Issue" className="contact-us">Contact Us</a> */}
                        Forgot your password? <a onClick={this.handleUnknownForgotPassword}>Reset Password</a>
                    </div>
                    <div className="tac spacingBottomXL">
                        {/* Forgot your password? <a onClick={this.handleUnknownForgotPassword}>Reset Password</a> */}
                        <a href="mailto:loantraksupport@msanational.com.au?Subject=LoanTrak%20Login%20Issue" className="contact-us">Contact Us</a>
                    </div>
                    <div className="tac ">
                    <img src={MSALogo} alt="Loantrak" />
                    </div>
                </div>
            </CenterContent>  );
        }
        
        // if(this.state.mustAuthenticate){
        //     <Card>
        //                 <LoginForm responseMessage={this.state.respMessage} mustAuthenticate={this.state.mustAuthenticate} loginFormCallback = {this.handleLoginFormCallback}/> 
        //     </Card> 
        // }

        return (             
            <CenterContent id="LoginPage">
                <MarginGuide />    
                <div className="content">
                    <Card>
                        <LoginForm responseMessage={this.state.respMessage} mustAuthenticate={this.state.mustAuthenticate} loginFormCallback = {this.handleLoginFormCallback}/> 
                    </Card> 
                    <div className="tac spacingBottomMedium spacingTopMedium">
                        {/* <a onClick={this.handleCreateAccount}>Create Account</a> 
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        {/* <a href="mailto:loantraksupport@msanational.com.au?Subject=LoanTrak%20Login%20Issue" className="contact-us">Contact Us</a> */}
                        Forgot your password? <a onClick={this.handleUnknownForgotPassword}>Reset Password</a>
                    </div>
                    <div className="tac spacingBottomXL">
                        {/* Forgot your password? <a onClick={this.handleUnknownForgotPassword}>Reset Password</a> */}
                        <a href="mailto:loantraksupport@msanational.com.au?Subject=LoanTrak%20Login%20Issue" className="contact-us">Contact Us</a>
                    </div>
                    <div className="tac ">
                    <img src={MSALogo} alt="Loantrak" />
                    </div>
                </div>
            </CenterContent>  
        )
    }
}

export default LoginPage;