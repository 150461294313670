import axios from "axios";
import querystring from "querystring";

import { AuthService } from "./auth-service";
import Config from "../modules/config";

export default class WebApiService {
  get(url, options) {
    console.log("Url: " + Config.serverUrl + url);

    return new Promise((resolve, reject) => {
      axios.get(Config.serverUrl + url, options).then(
        response => {
          
          if(response.headers['authorization']!==undefined){
            //AuthService.setToken(response.headers['authorization']);
          }
          resolve(response);
        },
        err => {
          const message = "Url: " + Config.serverUrl + url + " - " + err;
          console.log(message);
          reject(message);
        }
      );
    });
  }

  noHeader(url, options) {
    console.log("Url: " + Config.serverUrl + url);

    return new Promise((resolve, reject) => {
      axios.get(Config.serverUrl + url, options).then(
        response => {
          resolve(response);
        },
        err => {
          const message = "Url: " + Config.serverUrl + url + " - " + err;
          console.log(message);
          reject(message);
        }
      );
    });
  }
  registerPost(url, headers) {
    console.log("Url: " + url);
    const options = headers;
    return new Promise((resolve, reject) => {
      axios.post(Config.serverUrl + url, headers).then(
        response => {
         
          resolve(response);
        },
        err => {
          console.log("Url: " + url + "Error: " + err);
          reject(err);
        }
      );
    });
  }
  postNoAuth(url, params) {
    console.log("Url: " + url);

    return new Promise((resolve, reject) => {
      let paramString = querystring.stringify(params);
      let parameters = this.getPostHeaderAuthless();

      axios.post(Config.serverUrl + url, params,parameters).then(
        response => {
          resolve(response);
        },
        err => {
          console.log("Url: " + url + "Error: " + err);
          reject(err);
        }
      );
    });
  }

  post(url, params) {
    console.log("Url: " + url);

    return new Promise((resolve, reject) => {
      let paramString = querystring.stringify(params);
      let parameters = this.getPostHeader();

      axios.post(Config.serverUrl + url, params,parameters).then(
        response => {
          
          resolve(response);
        },
        err => {
          console.log("Url: " + url + "Error: " + err);
          reject(err);
        }
      );
    });
  }

  postJSON(url, params) {
    console.log("Url: " + url);

    return new Promise((resolve, reject) => {
      let paramString = JSON.stringify(params);
      let headers = this.getJSONPostHeader();

      axios.post(Config.serverUrl + url, paramString, headers).then(
        response => {
          resolve(response);
        },
        err => {
          console.log("Url: " + url + "Error: " + err);
          reject(err);
        }
      );
    });
  }

  
  postJSONWithHeaders(url, params,headers) {
    console.log("Url: " + url);

    return new Promise((resolve, reject) => {
      let paramString = JSON.stringify(params);
      let headers = this.getJSONPostHeader();

      axios.post(Config.serverUrl + url, paramString, headers).then(
        response => {
          
          resolve(response);
        },
        err => {
          console.log("Url: " + url + "Error: " + err);
          reject(err);
        }
      );
    });
  }
  post_with_token(url,token){
    let param = new FormData()
    return new Promise((resolve,reject)=>{
      
      let headers = {'headers':{'Authorization':token}};
      axios.post(Config.serverUrl + url ,param,headers ).then(
        response=>{
          resolve(response);
        },
        err => {
          console.log("Url: " + url + "Error: "+err);
          reject(err);
        }
      )
    })
  }
  upload(url, content) {
    return new Promise((resolve, reject) => {
      let headers = this.getUploadHeader();

      axios.post(Config.serverUrl + url, content, headers).then(
        response => {
          
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  specialUpload(url, content, options) {
    return new Promise((resolve, reject) => {
      //let headers = this.getUploadHeader();

      axios.post(Config.serverUrl + url, content, options).then(
        response => {
          
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  // post(url, params) {
  //   console.log("Url: " + url);

  //   return new Promise((resolve, reject) => {
  //     let paramString = querystring.stringify(params);
  //     let parameters = this.getPostHeader();

  //     axios.post(Config.serverUrl + url, params,parameters).then(
  //       response => {
  //         AuthService.setToken(response.headers.accesskey);
  //         resolve(response);
  //       },
  //       err => {
  //         console.log("Url: " + url + "Error: " + err);
  //         reject(err);
  //       }
  //     );
  //   });
  // }
  uploadWithMatter(url, content, matterId, fileName) {
    console.log(fileName);
    console.log(matterId);
    const header = {
      headers: {
        //"content-type": "multipart/form-data",
        fileName: fileName,
        Authorization: AuthService.token,
        "matterId": matterId,
      }
    };
    return new Promise((resolve, reject) => {
      // let headers = this.getUploadHeader();
      //let paramString = querystring.stringify(content);
      axios.post(Config.serverUrl + url,content,header).then(
        response => {
         
          console.log(response);
          resolve(response);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getPostHeaderAuthless() {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    };
  }

  getPostHeader() {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: AuthService.token
      }
    };
  }

  getJSONPostHeader() {
    return {
      headers: {
        "Content-Type": "application/json",
        'Authorization': AuthService.token
      }
    };
  }

  getUploadHeader() {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: AuthService.token
      }
    };
  }
}
