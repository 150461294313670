import React, { Component } from 'react'
   
import Line from '../../atom/Line/Line';
import IconBox from '../../atom/IconBox/IconBox';

import PropTypes                                            from 'prop-types';
import { BrowserRouter, Route }                             from 'react-router-dom';
import { Redirect }                                         from 'react-router-dom'; 

import './css/LeftMenuSlim.css';
import { AuthService } from '../../../services/auth-service';

class LeftMenu extends Component {

    constructor(props){
        super(props);

        let userprivileges = AuthService.getUserExternalPrivilegesMenu();
        let userdetails = AuthService.getUserDetails();
        let matterrestrictions = AuthService.getUserTypeRestrictions();//AuthService.GetUserMatterTypeRestriction();
        this.state={
            dashboardCss: 'ButtonIconSelected',
            dischargesCss: '',
            consentsCss: '',
            searchCss: '',
            instructCss: '',
            custodyCss: '',
            adminCss: '',
            resourcesCss: '',
            fundingrequestCss: '',
            logout: false,
            currentPage: "",
            isInstructPriv:false,
            isCustodyPriv:false,
            isAdminPriv:false,
            username: null,
            CanInstructNewLoans: null,
            CanInstructDischarges: null,
            CanProvideDischargePayout: null,
            CanInstructDischargeUpload: null,
            CanUploadConsent: null,
            CanUploadNewLoansOrVariation: null,
            CanViewLoanStat: null,
            CanViewFundingItems: null,
            CanInstructNewLoansForm: null,
            CanInstructNewLoansUpload: null,
            userprivileges : userprivileges,
            userdetails:userdetails,
            matterrestrictions: matterrestrictions,
            userHasMatterTypeRestriction:false,
            UserHasNewLoansRestriction:false,
            UserHasDischargesRestriction: false,
            UserHasConsentsRestriction: false,
        }

        this.changePage = this.changePage.bind(this);
        this.logout = this.logout.bind(this);
        //this.SaveUserHandler = this.SaveUserHandler.bind(this); 
         
        // this.toggleAction = this.props.toggleAction.bind(this);
    }

    static contextTypes = {
        router: PropTypes.object
    }


    componentDidMount(){
        //alert("leftmenu" + this.props.page);
        let usertypeid =  this.state.userdetails != null? this.state.userdetails.map(d=>d.usertypeid): '';
        if(usertypeid == 2 || usertypeid == 3){
            //check user access
            let CanInstructNewLoans =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoans) : null;
            let CanInstructDischarges =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructDischarges) : null;
            let CanProvideDischargePayout =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanProvideDischargePayout) : null;
            let CanInstructDischargeUpload =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructDischargeUpload) : null;
            let CanUploadConsent = this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanUploadConsent) : null;
            let CanUploadNewLoansOrVariation = this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanUploadNewLoansOrVariation) : null;
            let CanAdminSubUsers =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanAdminSubUsers) : null;
            let IsCustodyPrivilege =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.IsCustodyPrivilege) : null;        
            let CanViewLoanStat =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanViewLoanStat) : null;  
            let CanViewFundingItems =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanViewFundingItems) : null;
            let CanInstructNewLoansForm =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoansForm) : null;   
            let CanInstructNewLoansUpload =  this.state.userprivileges != null? this.state.userprivileges.map(d=>d.CanInstructNewLoansUpload) : null;          

            this.setState({
                isInstructPriv:(CanInstructNewLoans[0]==true || CanInstructDischarges[0]==true || CanProvideDischargePayout[0]==true || CanInstructDischargeUpload[0]==true || CanUploadConsent[0]==true|| CanUploadNewLoansOrVariation[0]==true)?true:false,
                CanInstructDischarges:CanInstructDischarges[0],
                CanProvideDischargePayout:CanProvideDischargePayout[0],
                CanInstructNewLoans:CanInstructNewLoans[0],
                CanInstructDischargeUpload:CanInstructDischargeUpload[0],
                CanUploadConsent: CanUploadConsent[0],
                CanUploadNewLoansOrVariation: CanUploadNewLoansOrVariation[0],
                isCustodyPriv:IsCustodyPrivilege[0],
                isAdminPriv:CanAdminSubUsers[0],
                CanViewLoanStat:CanViewLoanStat[0],
                CanViewFundingItems:CanViewFundingItems[0],
                CanInstructNewLoansForm:CanInstructNewLoansForm[0],
                CanInstructNewLoansUpload:CanInstructNewLoansUpload[0],
            });
        }

        if(usertypeid == 4){//broker
            this.setState({
                CanViewLoanStat: true
            });
        }

        var matterrestrictions = AuthService.getUserTypeRestrictions();
        let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;
        
        
        this.setState({	
            UserHasNewLoansRestriction: userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false ,
            UserHasDischargesRestriction: userHasDischargesRestriction[0] && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false,
            UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
        }
        //,()=>{alert(this.state.username)}	
        ,()=>{
            console.log("userHasNewLoansRestriction1"+this.state.UserHasNewLoansRestriction);
            console.log("userHasDischargesRestriction1"+this.state.UserHasDischargesRestriction);
            console.log("userHasConsentsRestriction1"+this.state.UserHasConsentsRestriction);
        }
        );

        // AuthService.isUserAuthorisedToInstruct().then((resp)=>{
        //     this.setState({isInstructPriv:true});
        // },(err)=>{
        //     this.setState({isInstructPriv:false});
        // });
        // AuthService.isUserCustodyCentreEnabled().then((resp)=>{
        //     this.setState({isCustodyPriv:resp.data.Content});
        //     },(err)=>{
        //         this.setState({isCustodyPriv:false});
        // });
        // AuthService.isUserAdminEnabled().then((resp)=>{
        //     this.setState({isAdminPriv:resp.data.Content});
        // },(err)=>{
        //   this.setState({isAdminPriv:false});
        // });

        //get instructions privileges
        // AuthService.getUserExternalPrivilegesList().then((resp)=>{
        //     this.setState({
        //         CanInstructDischarges:resp.data.Content.CanInstructDischarges,
        //         CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
        //         CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
        //         CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
        //     },()=>{console.log(this.state.CanInstructNewLoans)});
        // },(err)=>{
        //     console.log("there is an error")
        //     //this.setState({isInstructPriv:false});
        // })

        this.setState({ 
            currentPage: this.props.page
        })

        // if(this.props.page =='dashboard'){
        //     this.setState({
        //         dashboardCss: 'ButtonIconSelected',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }
        // if(this.props.page =='discharges'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: 'ButtonIconSelected',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }
        // if(this.props.page =='search'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: 'ButtonIconSelected',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(this.props.page =='instructions'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: 'ButtonIconSelected',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(this.props.page =='custodycentre'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: 'ButtonIconSelected',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(this.props.page =='admincontrol'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: 'ButtonIconSelected',
        //         resourcesCss: '',
        //     })
        // }

        // if(this.props.page =='resources'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: 'ButtonIconSelected',
        //     })
        // }
    }

    static contextTypes = {
        router: PropTypes.object
    }

    // nextPath(path,arg){
    //     this.context.router.history.push(
    //         {pathname:path,
    //         state: {user: arg}})
    // }


    changePage(e){
        //alert('hello' + e.target.id);

        this.setState({ 
            currentPage: e.target.id
        })

        this.props.callBack(e.target.id);
        // if(e.target.id == 'dashboard'  || this.props.page =='dashboard'){
        //     this.setState({
        //         dashboardCss: 'ButtonIconSelected',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'discharges'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: 'ButtonIconSelected',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'search' || this.props.page =='search'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: 'ButtonIconSelected',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'instructions'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: 'ButtonIconSelected',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'custodycentre'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: 'ButtonIconSelected',
        //         adminCss: '',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'admincontrol'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: 'ButtonIconSelected',
        //         resourcesCss: '',
        //     })
        // }

        // if(e.target.id == 'resources'){
        //     this.setState({
        //         dashboardCss: '',
        //         dischargesCss: '',
        //         searchCss: '',
        //         instructCss: '',
        //         custodyCss: '',
        //         adminCss: '',
        //         resourcesCss: 'ButtonIconSelected',
        //     })
        // }

    }

    nextPath(path,arg){
        this.context.router.history.push({
            pathname:path,
            state: {
                user: arg
            }
        });
    }

    logout(){
        this.setState({
            
             logout: true
        })
    }

    // openSubMenu=($subMenu)=>{
    //     //this.props.toggleAction("wide",$subMenu)
    //     alert()
    // }
 

    render() {

        if(this.state.logout == true){
            return(<Redirect to='/logout'></Redirect>)
        }

        return ( 
            <div className="LeftMenuSlim">
                
                <IconBox iconURL="../../../images/interface/iconMenuVeggieDark.svg" tooltip="Expand Menu" clickAction={()=>this.props.toggleAction("slim","")} />
                {this.state.CanViewLoanStat?
                <IconBox  
                    iconURL={this.props.page =="insights"?"../../../images/icons/OutlineWhite/Insights.png":"../../../images/icons/OutlineDark/Insights.png"} 
                    tooltip="Loan Insights"
                    mouseEnter={this.props.statBoxShow} 
                    addCSS={this.props.page =="insights"?"btnInsights btnInsightsSelected":"btnInsights"} 
                    clickAction={this.props.showInsightsDetails}
                />
                :
                ''    
                }

                {(this.state.UserHasNewLoansRestriction || this.state.UserHasDischargesRestriction || this.state.UserHasConsentsRestriction) ?
                    <>
                        {this.state.UserHasNewLoansRestriction ?
                        <IconBox 
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="dashboard"?"White":"Dark")+"/Dashboard.svg"}  
                            tooltip="Dashboard" 
                            addCSS={this.props.page =="dashboard"?"IconBoxDark":""} 
                            clickAction={()=>{this.props.callBack("dashboard")}}
                        />
                        :''}
                        
                        {this.state.UserHasDischargesRestriction ?
                        <IconBox
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="discharges"?"White":"Dark")+"/Discharges.svg"} 
                            tooltip="Discharges"
                            addCSS={this.props.page =="discharges"?"IconBoxDark":""} 
                            clickAction={()=>{this.props.callBack("discharges")}}
                        />
                        :''}

                        {this.state.UserHasConsentsRestriction ?
                        <IconBox
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="consents"?"White":"Dark")+"/Discharge_Authorities.svg"} 
                            tooltip="Consents"
                            addCSS={this.props.page =="consents"?"IconBoxDark":""} 
                            clickAction={()=>{this.props.callBack("consents")}}
                        />
                        :''}
                    </>
                    :
                    <>
                        <IconBox 
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="dashboard"?"White":"Dark")+"/Dashboard.svg"}  
                            tooltip="Dashboard" 
                            addCSS={this.props.page =="dashboard"?"IconBoxDark":""} 
                            clickAction={()=>{this.props.callBack("dashboard")}}
                        />
                        <IconBox
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="discharges"?"White":"Dark")+"/Discharges.svg"} 
                            tooltip="Discharges"
                            addCSS={this.props.page =="discharges"?"IconBoxDark":""} 
                            clickAction={()=>{this.props.callBack("discharges")}}
                        />
                        <IconBox
                            iconURL={"../../../images/icons/Outline"+(this.props.page=="consents"?"White":"Dark")+"/Discharge_Authorities.svg"} 
                            tooltip="Consents"
                            addCSS={this.props.page =="consents"?"IconBoxDark":""} 
                            clickAction={()=>{this.props.callBack("consents")}}
                        />
                    </>
                }

                
                <IconBox 
                    iconURL={"../../../images/icons/Outline"+(this.props.page=="search"?"White":"Dark")+"/Search.svg"} 
                    tooltip="Search Loans" 
                    addCSS={this.props.page =="search"?"IconBoxDark":""}
                    clickAction={()=>{this.props.callBack("search")}}
                />

                {/* <IconBox 
                    iconURL={"../../../images/icons/Outline"+(this.props.page=="instructions"||this.props.page =="payoutupload"||this.props.page =="newloansinstructions"?"White":"Dark")+"/Diagonal_Arrow.svg"}
                    tootltip="Instructions"
                    addCSS={this.props.page=="instructions"||this.props.page =="payoutupload"||this.props.page =="newloansinstructions"?"IconBoxDark":""} 
                    clickAction={()=>this.props.toggleAction("slim","instructions")} 
                />  */}
                {this.state.isInstructPriv?(<IconBox 
                    iconURL={"../../../images/icons/Outline"+(this.props.page =="dischargeinstructions" || this.props.page=="dischargesupload"||this.props.page =="payoutupload"||this.props.page =="newloansinstructions"||this.props.page =="consentsupload"?"White":"Dark")+"/Diagonal_Arrow.svg"}
                    tootltip="Instructions"
                    //addCSS={this.props.page =="dischargeinstructions" || this.props.page=="dischargesupload"||this.props.page =="payoutupload"||this.props.page =="newloansinstructions"?"IconBoxDark":""} 
                    addCSS={this.props.page =="dischargeinstructions" || this.props.page=="dischargesupload"||this.props.page =="payoutupload"||this.props.page =="newloansinstructions"||this.props.page =="consentsupload"||this.props.page =="newloansinstructionsform"||this.props.page =="newloansinstructionsupload"?"IconBoxDark":""} 
                    clickAction={()=>this.props.toggleAction("slim","instructions")} 
                />):''}

                {this.state.CanInstructNewLoansForm?(
                <div 
                    id="newloansinstructionsform"
                    className={this.props.page =="newloansinstructionsform"?"lmMenuSubSelected":""}
                    onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loans Form
                </div>):''}
                {this.state.CanInstructNewLoansUpload?(
                <div 
                    id="newloansinstructionsupload"
                    className={this.props.page =="newloansinstructionsupload"?"lmMenuSubSelected":""}
                    onClick={(e)=>{this.changePage(e, this.state.lmwInstructMSASub)}}>+ New Loans Upload
                </div>):''}

                {this.state.isCustodyPriv?(<IconBox 
                    iconURL={"../../../images/icons/Outline"+(this.props.page=="custodycentre"?"White":"Dark")+"/Lock.svg"} 
                    tooltip="Custody Centre"
                    addCSS={this.props.page =="custodycentre"?"IconBoxDark":""}
                    clickAction={()=>{this.props.callBack("custodycentre")}}
                />)
                :''
                }

                {this.state.isAdminPriv?(<IconBox
                    iconURL={"../../../images/icons/Outline"+(this.props.page=="admincontrol"?"White":"Dark")+"/User.svg"} 
                    tooltip="Admin Control"
                    addCSS={this.props.page =="admincontrol"?"IconBoxDark":""}
                    clickAction={()=>{this.props.callBack("admincontrol")}}
                />)
                :''
                }

                {this.state.CanViewFundingItems?(<IconBox
                    iconURL={"../../../images/icons/Outline"+(this.props.page=="fundingrequest" || this.props.page =='fundingdiscussion'?"White":"Dark")+"/User.svg"} 
                    tooltip="Funding Items"
                    addCSS={this.props.page =="fundingrequest" || this.props.page =='fundingdiscussion' ?"IconBoxDark":""}
                    clickAction={()=>{this.props.callBack("fundingrequest")}}
                />)
                :''
                }

                <IconBox 
                    iconURL={"../../../images/icons/Outline"+(this.props.page=="resources"?"White":"Dark")+"/Star.svg"} 
                    tooltip="Resources"
                    addCSS={this.props.page =="resources"?"IconBoxDark":""}
                    clickAction={()=>{this.props.callBack("resources")}} 
                />
            
                <Line />   

                <IconBox
                    iconURL="../../../images/icons/OutlineDark/Sign_out.svg" tooltip="Logout"
                    clickAction={this.logout}
                />  

            </div> 
        )
    }
}

export default LeftMenu
