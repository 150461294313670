import React, { Component } from 'react'

import './css/MarginGuide.css';

class MarginGuide extends Component {
    render() {
        return (
            <>
                <div className="marginTestLeft"></div>
                <div className="marginTestCenter"></div>
                <div className="marginTestRight"></div>
            </>
        )
    }
}

export default MarginGuide
