import React, { Component } from 'react'

import './css/HighlightText.css';

class HighlightText extends Component {
    render() {
        return (
            <div className={"HighlightText " + this.props.type + " " + this.props.addCSS} onClick={this.props.onClick} id = {this.props.id}>{this.props.children}</div>
        )
    }
}

export default HighlightText
