import React, { Component } from 'react'
 
import HighlightText from '../../atom/HighlightText/HighlightText'; 

import { Redirect } from 'react-router-dom';
import { MatterService } from '../../../services/matter-service';
import Moment from "moment";


 
import './css/DataTableEventsDischarges.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class DataTableEventsDischarges extends Component {
    constructor(props){
        super(props);
        this.state={
            loading: false,
            matters: [],
            shouldRedirect: false,
            matterStatusHighlight: 'HighlightBlue',
        }
        this.rowClick = this.rowClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true
        })

        MatterService.getRecentMatterEventsFiltered(2).then(resp => {
            this.setState({
                matters: resp.data.Content,
                loading: false
            });
         }, (err) => {
             if ( err.response && err.response.status === 401) {
                 this.setState({
                     matter: [],
                     loading: false,
                     shouldRedirect: true
                 });
             } else {
                 this.setState({
                     matter: [],
                     loading: false,
                     shouldRedirect: true
                 });
 
                
             }
 
         });
        
        // MatterService.getRecentMatterEventsDischarges().then(resp => {
        //    this.setState({
        //        matters: resp.data.Content,
        //        loading: false
        //    });
        // }, (err) => {
        //     if ( err.response && err.response.status === 401) {
        //         this.setState({
        //             matter: [],
        //             loading: false,
        //             shouldRedirect: true
        //         });
        //     } else {
        //         this.setState({
        //             matter: [],
        //             loading: false,
        //             shouldRedirect: true
        //         });

               
        //     }

        // });
    }

    rowClick = (e, matterId)  => {
          //if(e.target!==-1){
            //alert(e.target.id);
            //alert(matterId);
            window.open('/matter-details:'+matterId)
          //}
       
        //if(event.rowIdx!==-1 &&window.innerWidth>768){
            //window.open('/matter')
            //window.open('/matter:'+event.dataItem.MatterId)
        //     }
        // if(event.rowIdx!==-1 &&window.innerWidth<=768){
        //     this.context.router.history.push(
        //        '/loan-details:'+event.dataItem.MatterId
        //        )
        //     }
        //this.context.router.history.push(
        //    '/loan-details:'+event.dataItem.MatterId    
        //)
    }

    render() {

        if(this.state.loading)
        {
            return(<ProgressDisplay></ProgressDisplay>);
        }

        if (this.state.shouldRedirect) {
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return (<Redirect to='/error' />);
        }
        let grid='';
        if(this.state.matters != null  && this.state.matters.length != 0){
            grid=(
                <>            
                    {
                        this.state.matters.map((matter) => {  
                            var matterlen = matter.MatterId.toString().length;              
                            return (
                                // <CheckBox className="chkStates" handleCheckChildElement={this.handleCheckChildElement}  {...rstates} />
                                // <div className="dtRow" id={matter.MatterId} onClick={(e)=>this.rowClick(e)} >
                                // <div className={matter.Reason == null ||  matter.Reason == "" ?"dtRow" : "dtRow dtRowNotes"} id={matter.MatterId} onClick={(e)=>this.rowClick(e, matter.MatterId)} >
                                <div className={matter.EventNotes == null ||  matter.EventNotes == "" ?"dtRow" : "dtRow dtRowNotes"} id={matter.MatterId} onClick={(e)=>this.rowClick(e, matter.MatterId)} >
                                    <div className="dtRowSmallBold">
                                        <div className="dtHeaderMobile">MSA Ref</div>
                                        {('0000000' + matter.MatterId).slice(matterlen)}
                                    </div> 
                            
                                    <div>
                                        <div className="dtHeaderMobile">Borrowers</div>
                                        {/* {matter.Description.length > 30? matter.Description.slice(0,30)+"..." : matter.Description } */}
                                        {matter.MatterDescription == null? '' : matter.MatterDescription.length > 30? matter.MatterDescription.slice(0,30)+"..." : matter.MatterDescription }
                                    </div>
                            
                                    <div>
                                        <div className="dtHeaderMobile">Lender Ref</div>
                                        {matter.LenderRefNo}
                                    </div>
    
                                    <div className="statusCol">
                                        <div className="dtHeaderMobile">Current Status</div> 
                                        {/* {matter.MatterStatusCategory} */}
                                        {/* <HighlightText type="HighlightYellow">{matter.MatterStatus}</HighlightText> */}
                                        {/* <HighlightText type={matter.MatterStatusCategory == 1? "HighlightGreen" : (matter.MatterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.MatterStatus}</HighlightText> */}
                                        {/* {matter.Message} */}
                                        {/* <HighlightText type={matter.MatterStatusCategory == 1? "HighlightGreen" : (matter.MatterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.Message}</HighlightText> */}
                                        <HighlightText type={matter.MatterStatusCategory == 1? "HighlightGreen" : (matter.MatterStatusCategory == 2 ? "HighlightYellow" : "HighlightBlue")}>{matter.MatterStatus}</HighlightText>
                                    </div>
                                    <div>
                                        <div className="dtHeaderMobile">Date</div>
                                        {/* {matter.MessageDate} */}
                                        {matter.EventDate}
                                        {/* {Moment(matter.EventDate).format("DD-MMM-YYYY HH:MM:SS ")} */}
                                    </div>
                                    {/* <div className="dtNotes"><strong>Notes:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div> */}
                                    {
                                        matter.EventNotes == null ||  matter.EventNotes == "" ? ""
                                        :
                                        (<div className="dtNotes"><strong>Reason: </strong>{matter.EventNotes}</div>)
                                    }   

                                    {/* {
                                        matter.Reason == null ||  matter.Reason == "" ? ""
                                        :
                                        (<div className="dtNotes"><strong>EventNotes:</strong>{matter.Reason}</div>)
                                    } */}
                                    
                                </div> 
                            )
                        })
                    }                
                </>       
            )
        }        

        let datatable = "";

        if(this.state.matters!=null && this.state.matters.length != 0){
            datatable=(
            <>
                <div className="dtHeader">
                    <div>MSA Ref</div>
                    <div>Borrowers</div>
                    <div>Lender Ref</div>
                    <div className="statusCol">Current Status</div>
                    <div>Date</div>
                </div>

                {grid}
            </>);
        }
        else{
            datatable = (<div>No Recent Events</div>);
        }
        return ( 
            <div className="DataTable DataTableEvents">
                {datatable}
            </div> 
        )
    }
}

export default DataTableEventsDischarges
