import { AuthService } from "./auth-service";
import WebApiService from "./web-api-service";
class matterEscalateService extends WebApiService {
    GetMatterEscalationReasons(){
        return new Promise((resolve, reject) => {
        const options = {
            headers: {
              'Authorization': AuthService.token,
              
            }
          };

        const url = "api/GetEscalateMatterOptions";

        super.get(url,options).then(
        (response)=>{
            resolve(response);
        },
        (err)=>{
            reject(err);
        })
    });
    }
    GetMatterEscalationDetails(matterId){
        return new Promise((resolve,reject)=>{
            const options = {
                headers:{
                    'Authorization':AuthService.token,
                    'MatterId':matterId
                }  
            };
            const url = "api/getEscalateMatterDetails";

            super.get(url,options).then(
                (response)=>{
                    resolve(response);
                },
                (err)=>{
                    reject(err);
                }
            );

        });
    }
    PostMatterEscalationRequest(matterId, reasonDescription, reasonComment){
        return new Promise((resolve,reject)=>{
            let data = new FormData();
            data.append('MatterId',matterId);
            data.append('EscalationReasonTypeDescription',reasonDescription);
            data.append('EscalationReasonComment',reasonComment);

            const url = "api/EscalateMatter";
            super.post(url,data).then((resp)=>{
                resolve(resp);
            },
            (err)=>{
                reject(err);
            });
        })
       
        
    }

}

export const MatterEscalateService = new matterEscalateService();