import React, { Component } from 'react' 
 
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import IconBox from '../../atom/IconBox/IconBox';
import { Redirect }                                         from 'react-router-dom';

import './css/ProfileMenu.css';
import { AuthService } from '../../../services/auth-service';
import ButtonGroupCap from '../ButtonGroupCap/ButtonGroupCap';

class ProfileMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            username : null,
            logout: false,
            isMFARequired: false,
            isMFAMobileEnabled: false,
            MFAOption: 'email',
            hasError: false,
            externalPrivileges: [],
        }
       this.logout = this.logout.bind(this);
       this.handleStatusCallback = this.handleStatusCallback.bind(this); 
    }
    componentDidMount(){
        var username = AuthService.getUsername();	
        	
        this.setState({	
            username: username	
        })

         //check if user is Required for MFA
         AuthService.isUserRequiredMFA().then((resp)=>{
            this.setState({isMFARequired:resp.data.Content,},()=>{
                // console.log('isMFARequired')
                // console.log(this.state.isMFARequired)
                // console.log(resp)
            });
        },(err)=>{
            this.setState({isMFARequired:false});
        });


        //check if user is Required for MFA Mobile
        AuthService.isUserMobileMFAEnabled().then((resp)=>{
            this.setState({
                //isMFAMobileEnabled:true,
                isMFAMobileEnabled: resp.data.Content,
                MFAOption: resp.data.Content == true? 'sms' : 'email'
            },()=>{
                // console.log('isUserMobileMFAEnabled')
                // console.log(this.state.isMFAMobileEnabled)
                // console.log(this.state.MFAOption)
            });
        },(err)=>{
            this.setState({isMFAMobileEnabled:false});
        });

    }

    logout(){
        this.setState({ 
             logout: true
        })
    }

    handleStatusCallback(child){
        this.setState({
            MFAOption: child
        },()=>
        {
            var brokerprivs = [];
            brokerprivs.push({"RequireMFA":true, "MobileMFA": child=="sms"?true:false});
            //update external priv
            AuthService.updateMFAMobilePrivilege().then((resp)=>{
            this.setState({
                hasError: false,
                externalPrivileges: brokerprivs
            },()=>{
                AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                // console.log('isUserMobileMFAEnabled')
                // console.log(this.state.isMFAMobileEnabled)
                // console.log(this.state.MFAOption)
                });
            },(err)=>{
                this.setState({isMFAMobileEnabled:false, hasError:true});
            });
        });
    }

    render() {
        if(this.state.logout == true){
            return(<Redirect to='/logout'></Redirect>)
        }  

        if(this.state.hasError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        }

        return (
            <>
                <IconBox
                    addCSS="pmBTN"
                    iconURL={"../../../images/icons/OutlineDark/"+(this.props.addCSS=="none"?"User.svg":"Cross.svg")}
                    clickAction={this.props.clickAction}
                /> 
                
               <div className={"pmContent " + this.props.addCSS}> 
                    {/* <div className="pmUser">Hello, <span className="pmName">John Doe</span></div>  */}
                    <div className="pmUser">Hello, <span className="pmName">{this.state.username}</span></div>
                    {this.state.isMFARequired && localStorage.getItem("ssoTimeout") == null ? 
                    (<div>
                        <ButtonGroupCap highlighted={this.state.MFAOption} callback={this.handleStatusCallback} firstOption="Email MFA" secondOption="SMS MFA" />     
                        {/* <br />    */}
                    </div>)
                    : ''}
                    <ButtonIcon 
                        addCSS="pmContentBTN"
                        iconURL="../../../images/icons/OutlineDark/Sign_out.svg" 
                        onClick={this.logout}
                    >Logout</ButtonIcon> 
                </div>
            </> 
        )
    }
}

export default ProfileMenu
