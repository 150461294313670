import React, { Component } from 'react';
import ReactHtmlParser                                      from 'html-react-parser';
import Moment from "moment";
import SAlert from "sweetalert2";
import { FilePond, registerPlugin } from "react-filepond";
 
import IconBox from '../../atom/IconBox/IconBox'; 
import Button from '../../atom/Button/Button'; 

 
import './css/DataTableOutstandingItems.css';
import { AuthService } from '../../../services/auth-service';
import { MatterOutstandingService } from "../../../services/matter-outstanding-service";

import{
    isBrowser,
    isMobile
  } from "react-device-detect";
  
  import {saveAs}         from 'file-saver';
import MessageBox from '../../atom/MessageBox/MessageBox';
import Line from '../../atom/Line/Line';
import Accordion from '../Accordion/Accordion';
import FileUploadBox from '../../molecules/FileUploadBox/FileUploadBox';
import './css/filepond.min.css';
import '../../molecules/FileUploadBox/css/FileUploadBox.css';
import DataTableDocuments from '../DataTableDocuments/DataTableDocuments';
import DataTableOutstandingItemsDetail from '../DataTableOutstandingItemsDetail/DataTableOutstandingItemsDetail';
import { assertAnyTypeAnnotation } from '@babel/types';

class DataTableOutstandingItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matter: this.props.matterId.substring(0, 8),
            loading: true,
            modalIsOpen: false,
            outstanding: [],
            outstandingcategories: [],
            msaOutstanding: [],
            lenderOutstanding: [],
            mortgageManagerOutstanding: [],
            brokerOutstanding: [],
            borrowerOutstanding: [],
            outgoingLenderOutstanding: [],
            otherOutstanding: [],
            solicitorOutstanding: [],
            unallocatedOutstanding: [],
            shouldRedirect: false,
            showGroups: true,
            divHeight: 0,
            files: [],
            uploadSuccess: false,
            canUserGenralUpload: false,
            uploadInfo: "",
            isGeneralDocumentUploaded: false,
            errorMessage: null,
            //ssourl: AuthService.getSSOUrl(),//'https://tictocssouat.msanational.com.au/?errormessage='
            isGeneralExpanded: true,
            isUnallocatedExpanded: true,
            expanded: true,
            cannotUploadOutstandingItems: false,
            matterIsOutstanding: false
        };
        this.displayInfo = this.displayInfo.bind(this);
        this.handleAccordionCallback = this.handleAccordionCallback.bind(this);
    }

    handleAccordionCallback(child){
        //alert(child);
        if(child[0] == "Unallocated"){
            this.setState({
                isUnallocatedExpanded: child[1],
              });
        }
        else{
            this.setState({
                expanded: child[1],
              });
        }       
      
    }

    displayInfo(){
        this.setState({
          uploadInfo: "A user has uploaded a document to this item."
        })
    }
        
    componentDidMount() {
        this.displayInfo();
        //alert(this.state.canUserGenralUpload)
        
        this.setState({
            loading: true,
            outstanding: [],
        });
    
        window.addEventListener("resize", this.updateDimensions);
    
        AuthService.isUserCanGenralUpload().then((resp)=>{
            this.setState({canUserGenralUpload:true});
        },(err)=>{
            this.setState({canUserGenralUpload:false},()=>
            {
                //alert(this.state.canUserGenralUpload)
            });
        });

        MatterOutstandingService.UserCannotUploadOutstandingItems().then(
            (resp)=>{
              this.setState({cannotUploadOutstandingItems:resp.data.Content})
            },
            (err)=>{
              this.setState({cannotUploadOutstandingItems:false})
            }
          );
    
        MatterOutstandingService.isGeneralDocumentUploaded(this.props.matterId).then((resp)=>{
            //console.log(resp);
            let generalDocUploaded = resp.data.Content;
            this.setState({isGeneralDocumentUploaded:generalDocUploaded});
        },(err)=>{
            this.setState({isGeneralDocumentUploaded:false});
        });
        
        MatterOutstandingService.isMatterOutstanding(this.props.matterId).then((resp)=>{
            let outstandingMatter = resp.data.Content;
            this.setState({isMatterOutstanding:outstandingMatter});
          },(err)=>{
              this.setState({isMatterOutstanding:false});
          });
    
        MatterOutstandingService.getMatterOutstandings(this.props.matterId).then(
            resp => {
            this.setState(
                {
                outstanding: resp.data.Content,
                loading: false
                },
                () => {
                    const { unallocatedOutstanding } = this.state;
                    let unallocatedArr = [];
                    let msaArr = [];
                    let lenderArr = [];
                    let mortmgrArr = [];
                    let brokerArr = [];
                    let borrowerArr = [];
                    let outgoingLenderArr = [];
                    let otherArr = [];
                    let solicitorArr = [];
                //this.updateDimensions();
                (this.state.outstanding).forEach(o => {
                    if(o.OutstandingResponsiblePartyTypeId == null){
                        //newArr = [...unallocatedOutstanding];
                        unallocatedArr.push(o);
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 1){   //MSA                     
                        msaArr.push(o)                    
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 2){ //"Lender"
                        lenderArr.push(o)    
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 3){ //"Mortgage Manager"
                        mortmgrArr.push(o)    
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 4){ //"Broker"
                        brokerArr.push(o)    
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 5){ //"Borrower")
                        borrowerArr.push(o)    
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 6){ //"Outgoing Lender"
                        outgoingLenderArr.push(o)    
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 7){ //"Other"
                        otherArr.push(o)    
                    }
                    if(o.OutstandingResponsiblePartyTypeId == 8){ //"Solicitor"
                        solicitorArr.push(o)    
                    }
                });

                this.setState({
                    unallocatedOutstanding: unallocatedArr,
                    msaOutstanding: msaArr,
                    lenderOutstanding: lenderArr,
                    mortgageManagerOutstanding: mortmgrArr,
                    brokerOutstanding: brokerArr,
                    borrowerOutstanding: borrowerArr,
                    outgoingLenderOutstanding: outgoingLenderArr,
                    otherOutstanding: otherArr,
                    solicitorOutstanding: solicitorArr
                },()=>{
                    //console.log(unallocatedArr);
                });
                
            }
            );
            },
            err => {
            if (err.substring(err.length - 3, err.length) === 401) {
                this.setState({
                outstanding: [],
                loading: false,
                shouldRedirect: true,
                errorMessage: 'there is an error with the request'
                });
            } else {
                this.setState({
                outstanding: [],
                loading: false,
                shouldRedirect: false
                });
            }
            }
        );
    }

    handleInit() {
   
    }
  
    handleItemUpdate(fileItems) {
      // console.log(fileItems);
      // Set current file objects to this.state
      this.setState({
        files: fileItems.map(fileItem => fileItem.file)
      },()=>{	
       // this.btnUploadFiles(this.state.files)
      });
      // console.log(this.state.files);
      //this.btnUploadFiles(this.state.files)
    }

    btnUploadFiles(fileItem) {
        //alert(fileItem)
        // console.log(this.state.files);
        // console.log(this.state.files[0].name);
        //return;
        if (this.state.files.length != 0) {
          // console.log("Button has been clicked!");
    
          // console.log("To be upload files");
          // console.log(
          //   this.props.matterId,
          //   this.state.files,
          //   this.props.note.OutstandingItemName
          // );
          //var safeFilename = this.props.note.OutstandingItemName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          var fileName = this.state.files[0].name;
          var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
          

          if(!regex.test(fileName) || fileName.length > 200)
          {
            alert ("Invalid filename. Please check.");
            return;
          }
          else{
            //alert (true);
            //return;
          }
          var safeFilename = fileItem.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          
    
          this.setState({ showModal: true });
          MatterOutstandingService.uploadDocument(
            this.props.matterId,
            this.state.files,
            //safeFilename,
            //this.props.note.OutstandingItemName
            fileItem
          ).then(
            response => {
              if( new SAlert({
                //title: "File Upload",
                //html: "Document transferred: <br />" + response.data.Message,
                // "Document transferred: " + files[0].name + "<br />" + result.Message
                //html: "<h4>A document has been successfully uploaded.</h4> ",//<br /> Document Name: " + response.data.Message,	
                html: "<h5>The document has been uploaded and will be sent to the system shortly.</h5> ",
                //onClose:()=>{this.reloadNotes()}
                didClose:()=>{this.reloadNotes()}
                //didClose:this.reloadNotes()
              })
            //   .then(function(){
                  
            //   }

            //   )
              ){
                this.setState({
                  files: [],
                  showModal:false,	
                  uploadSuccess: true
                });
                //this.props.Reload();
              }else{
                alert("Document has ben transferred.");
                this.setState({
                  files: [],
                  showModal:false
                });
                this.props.Reload();
              }
             
            
              
            },
            error => {
              new SAlert({
                title: "File Upload",
                html: "Document transfer FAILED.",//<br /><br />" + error.message,
                type: "error"
              });
              this.setState({ showModal: false });
    
            }
          );
        } else {
          this.pond.browse();
          this.setState({ showModal: false });
          // SAlert({
          //   title: "File Upload",
          //   html: "Please select file(s)",
          //   type: "error"
          // });
        }
    }
    
    reloadNotes(){	
        //alert(this.state.uploadSuccess)	
        if(this.state.uploadSuccess == true)	
        {	
          window.location.reload();	
          //return(<DataTableDocuments></DataTableDocuments>);
        }	
    }

    render(){
        let detail = '';
        let outstandingItems = [];
        let MSAOutstandingItems= '';
        let lenderOutstandingItems= '';
        let mortmgrOutstandingItems= '';
        let brokerOutstandingItems= '';
        let borrowerOutstandingItems= '';
        let outgoinglenderOutstandingItems= '';
        let otherOutstandingItems= '';
        let solicitorOutstandingItems= '';
        let unallocatedOutstandingItems= '';
        let outstandings = '';        

        outstandingItems = this.state.outstanding.map(n => {
            return (
              <DataTableOutstandingItemsDetail
                matterId={this.state.matter}
                note={n}
                Reload={this.props.Reload}
                selected={true}
              />
            );
        });
        
        if( this.state.outstanding.length > 0){
            detail= this.state.outstanding.map(n => (
                <div className="notesBody">  
                   {/* {n.IssueDetails} */}
                   {n.IssueDetails == null ? n.IssueDetails : ReactHtmlParser(n.IssueDetails)}
                   {/* {ReactHtmlParser(n.IssueDetails)}  */}
                    </div>
            ))
        }
       
        if (this.props.selected === true) {
            detail = (
              <div className="notesBody">
                {/* {ReactHtmlParser(this.state.outstanding.IssueDetails)} */}
              </div>
            );   
        }

        let fileUpload= '';
        let genUploadDiv= '';
        let infospace=<div>&nbsp;</div>;

        if(this.state.canUserGenralUpload){
 

            fileUpload = (<FilePond	 
              labelIdle="
                    <div class='GUContent'>
                        <div class='GUTextBox'>
                            <div classname='GUText1'>Drag & drop file here</div>
                            <div id='format'>File format: PDF, PBG, JPG</div>
                        </div>
                        <div id='button'> Browse</div>
                    </div>" 
              ref={ref => (this.pond = ref)}	
              files={this.state.files}	
              allowMultiple={false}	
              oninit={() => this.handleInit()}	
              onupdatefiles={fileItems => this.handleItemUpdate(fileItems)}	
              onClick={click => this.handleOnClick(click)}	
              onaddfile={()=>this.btnUploadFiles("general_upload")}
            />);
          }   
        //   else{
        //     genUploadDiv=("sdfsdf");
        //   }

        //   if(this.state.canUserGenralUpload == false){
        //     genUploadDiv='';
        //   }
          
          if(this.state.isGeneralDocumentUploaded && this.state.canUserGenralUpload){
            genUploadDiv=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"GeneralUpload"}
                    title={"General Upload"}
                    expanded={this.state.isGeneralExpanded}
                > 
                    <div className="GUBox GUBoxUploaded"> 
                      <div className="GUTitleBox">
                          <div className="GUTitle">{fileUpload}</div>
                          <div 
                              className="info" 
                            //   onMouseOver={this.displayInfo} 
                            //   onMouseLeave={()=>{this.setState({uploadInfo: ""})}}
                              >
                          </div>
                          <div className="infotip">
                              {this.state.uploadInfo == "" ? infospace : this.state.uploadInfo}
                          </div>   
                      </div> 
                  </div>  
                    {/* <div className="GUBox"> 
                        <div className="GUTitleBox">
                            <div className="GUTitle">{fileUpload}</div>
                            {this.state.uploadInfo == ""?"":(
                            <div 
                                className="info" 
                                onMouseOver={this.displayInfo} 
                                onMouseLeave={()=>{this.setState({uploadInfo: ""})}}>
                            </div>)}
                            <div className="infotip">
                                {this.state.uploadInfo == "" ? infospace : this.state.uploadInfo}
                            </div>   
                        </div> 
                    </div>  */}

                 

                </Accordion>
            );
          }
          if(!this.state.isGeneralDocumentUploaded  && this.state.canUserGenralUpload){
            genUploadDiv=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"GeneralUpload"}
                    title={"General Upload"}
                    expanded={this.state.isGeneralExpanded}
                > 
                    {/* <div style={{float:'left', width: '100%'}}>{fileUpload}</div>
                    <br/> <br/> */}
                    <div>{fileUpload}</div>
                </Accordion>
            );
            // genUploadDiv=(
            //   <div style={{float:'left', width: '100%'}}>{fileUpload}</div>
            // );
          }    

        let MSADisplay= '';
        let lenderDisplay= '';
        let mortmgrdDisplay= '';
        let brokerDisplay= '';
        let borrowerDisplay= '';
        let outgoinglenderDisplay= '';
        let otherDisplay= '';
        let solicitordDisplay= '';
        let unallocatedDisplay= '';

        unallocatedOutstandingItems = this.state.unallocatedOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        MSAOutstandingItems = this.state.msaOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        lenderOutstandingItems = this.state.lenderOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        mortmgrOutstandingItems = this.state.mortgageManagerOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        brokerOutstandingItems = this.state.brokerOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        borrowerOutstandingItems = this.state.borrowerOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        outgoinglenderOutstandingItems = this.state.outgoingLenderOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        otherOutstandingItems = this.state.otherOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });


        solicitorOutstandingItems = this.state.solicitorOutstanding.map(n => {
            return (
                <>
                    {n.OutstandingItemName}
                    {!this.state.cannotUploadOutstandingItems?(<DataTableOutstandingItemsDetail
                        matterId={this.state.matter}
                        note={n}
                        Reload={this.props.Reload}
                        selected={true}
                    />):<br />}
              </>
            );
        });

        if(this.state.unallocatedOutstanding.length > 0){
            unallocatedDisplay=(            
                <Accordion  callback={this.handleAccordionCallback}
                    name={"Unallocated"}
                    title={"Unallocated (" + this.state.unallocatedOutstanding.length + ")"}
                    // iconURL="../../../../images/icons/OutlineDark/Minus.svg"
                    // addCSS="AMOpen"
                    // addCSSContent="ACOpen"
                    expanded={this.state.isUnallocatedExpanded}        
                > 
                    {unallocatedOutstandingItems}
                </Accordion>         
            )
        }
    
        if(this.state.msaOutstanding.length > 0){
            MSADisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"MSA"}
                    title={"MSA (" + this.state.msaOutstanding.length + ")"}
                    expanded={this.state.expanded}
                >            
                    {MSAOutstandingItems}          
                </Accordion>
            )
        }

        if(this.state.lenderOutstanding.length > 0){
            lenderDisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"Lender"}
                    title={"Lender (" + this.state.lenderOutstanding.length + ")"}
                    expanded={this.state.expanded}
                >            
                    {lenderOutstandingItems}              
                </Accordion>
            )
        }

        if(this.state.mortgageManagerOutstanding.length > 0){
            mortmgrdDisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"MortgageManager"}
                    title={"Mortgage Manager (" + this.state.mortgageManagerOutstanding.length + ")"}
                    expanded={this.state.expanded}
                >            
                    {mortmgrOutstandingItems}              
                </Accordion>
            )
        }

        if(this.state.brokerOutstanding.length > 0){
            brokerDisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"Broker"}
                    title={"Broker (" + this.state.brokerOutstanding.length + ")"}
                    expanded={this.state.expanded}
                >            
                    {brokerOutstandingItems}   
                </Accordion>
            )
        }

        if(this.state.borrowerOutstanding.length > 0){
            borrowerDisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"Borrower"}
                    title={"Borrower (" + this.state.borrowerOutstanding.length + ")"}
                    expanded={this.state.expanded}
                    >            
                        {borrowerOutstandingItems}           
                </Accordion>
            )
        }

        if(this.state.outgoingLenderOutstanding.length > 0){
            outgoinglenderDisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"OutgoingLender"}
                    title={"Outgoing Lender (" + this.state.outgoingLenderOutstanding.length + ")"}
                    expanded={this.state.expanded}
                >            
                    {outgoinglenderOutstandingItems}               
                </Accordion>
            )
        }

        if(this.state.otherOutstanding.length > 0){
            otherDisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"Other"}
                    title={"Other (" + this.state.otherOutstanding.length + ")"}
                    expanded={this.state.expanded}
                >            
                    {otherOutstandingItems}   
                </Accordion>
            )
        }

        if(this.state.solicitorOutstanding.length > 0){
            solicitordDisplay=(
                <Accordion  callback={this.handleAccordionCallback}
                    name={"Solicitor"}
                    title={"Solicitor (" + this.state.solicitorOutstanding.length + ")"}
                    expanded={this.state.expanded}
                >            
                    {solicitorOutstandingItems}   
                </Accordion>
            )
        }   

        let display= "";
        if (this.state.outstanding.length === 0 && this.state.matterIsOutstanding) {
            display = (
                <MessageBox
                    addCSS="MBWarning"
                    iconURL="../../../../images/icons/OutlineDark/Exlaimation_Circle.svg">Outstanding items will be updated shortly.
                </MessageBox>

            );
          } else if(this.state.outstanding.length === 0 && !this.state.matterIsOutstanding) {
            
                display = (
                    <MessageBox
                        addCSS="MBWarning"
                        iconURL="../../../../images/icons/OutlineDark/Exlaimation_Circle.svg">No Outstanding Requirements.
                    </MessageBox>
    
                    );
            }
            else{
                display = (
                <MessageBox
                    addCSS="MBWarning"
                    iconURL="../../../../images/icons/OutlineDark/Exlaimation_Circle.svg">Please upload the required documents below.
                </MessageBox>

                );
            }
          
        
        return ( 
            <>     
                {display}           
                {/* <MessageBox
                    addCSS="MBWarning"
                    iconURL="../../../../images/icons/OutlineDark/Exlaimation_Circle.svg">Please upload the required documents below.
                </MessageBox> */}

                {/* <div><i>{detail}</i></div>	 */}

                <Line />

                {genUploadDiv}                
                
                {unallocatedDisplay}
                {/* <br/> <br/> */}
                {MSADisplay}
                {lenderDisplay}
                {mortmgrdDisplay}
                {brokerDisplay}
                {borrowerDisplay}
                {outgoinglenderDisplay}
                {otherDisplay}
                {solicitordDisplay}                
            </> 
        )
    }
}

export default DataTableOutstandingItems
