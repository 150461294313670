import React, { Component }                                 from 'react';
import { Redirect }                                         from 'react-router-dom';
//import ProgressDisplay                                      from '../../controls/progress-display'
import { AuthService }                                      from '../../../services/auth-service';

class Logout extends Component {

  constructor(props) {
    super(props);
    this.state=({
      loading:true,

    })
   

  }
  componentDidMount(){
    //window.alert("Hello")
    
    AuthService.logout();
    this.setState({loading:false,});
  }
  render() {
    if(this.state.loading){
      //return (<ProgressDisplay />);
      return (<div>logging out</div>);
    }else{
      return ( <Redirect to='/' /> );  
    }
    
  } 
}

export default Logout;
