import React, { Component } from 'react'
import PropTypes from "prop-types";
import Button from '../../atom/Button/Button';
import IconBox from '../../atom/IconBox/IconBox';

import { MatterService } from '../../../services/matter-service';
import AllOption from "../../../models/all-option";

 
import './css/SearchLoanBox.css';
import DropDownList from '../../atom/DropDownList/DropDownList';
import LenderControl from './lender-control';
import MortManagerControl from './mort-manager-control';

class SearchLoanBox extends Component {
    anchor = null;
  constructor(props) {
    super(props);
    this.addressChanged = this.addressChanged.bind(this);
    this.borrowersChanged = this.borrowersChanged.bind(this);
    //this.buildColumnEditor = this.buildColumnEditor.bind(this);
    this.detailedSearch = this.detailedSearch.bind(this);
    this.generateCSV = this.generateCSV.bind(this);
    this.fileOwnerChanged = this.fileOwnerChanged.bind(this);
    this.handleCancelUpdate = this.handleCancelUpdate.bind(this);
    this.handleColumnClick = this.handleColumnClick.bind(this);
    this.handleColumnUpdate = this.handleColumnUpdate.bind(this);
    this.lenderChanged = this.lenderChanged.bind(this);
    this.lenderRefNoChanged = this.lenderRefNoChanged.bind(this);
    this.loadLookupLists = this.loadLookupLists.bind(this);
    this.matterNoChanged = this.matterNoChanged.bind(this);
    this.matterStateChanged = this.matterStateChanged.bind(this);
    this.matterStatusChanged = this.matterStatusChanged.bind(this);
    this.matterTypeChanged = this.matterTypeChanged.bind(this);
    this.mortManagerChanged = this.mortManagerChanged.bind(this);
    this.brokerDetailChanged = this.brokerDetailChanged.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.titleRefNoChanged = this.titleRefNoChanged.bind(this);
    this.workComplete = this.workComplete.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    let as = this.props.getAdvancedSearch();

    this.state = {
      matterTypes: [as.matterType],
      matterStatuses: [as.matterStatus],
      matterStates: [as.matterState],
      fileOwners: [as.fileOwner],
      lenders: [as.lender],
      mortManagers: [as.mortManager],
      shouldSearch: as.shouldSearch,
      matterNo: as.matterNo,
      borrowers: as.borrowers,
      matterType: as.matterType,
      matterStatus: as.matterStatus,
      matterState: as.matterState,
      fileOwner: as.fileOwner,
      lender: as.lender,
      mortManager: as.mortManager,
      titleRefNo: as.titleRefNo,
      lenderRefNo: as.lenderRefNo,
      address: as.address,
      brokers:[],
      broker:as.broker,
      show: false,
      isWorking: false,
    };
  }

  componentDidMount() {
    //instantiate advanced search to state
    let as = this.props.getAdvancedSearch();
    console.log("as")
    console.log(as)
    console.log(as.matterType)
    this.loadLookupLists(as);
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  loadLookupLists(as) {
    //load various filters and list options for advanced search function
    let self = this;

    let p1 = MatterService.getMatterTypeGroupList();
    let p2 = MatterService.getMatterStatusList();
    let p3 = MatterService.getStateList();
    let p4 = MatterService.getFileOwnerList();
    let p5 = MatterService.getLenderList();
    let p6 = MatterService.getMortMgrList();
    //let p7 = MatterService.GetBrokerList();
    
    Promise.all([p1, p2, p3, p4, p5, p6]).then(values => {
      let matterTypes = values[0].data;
      matterTypes.splice(0, 0, AllOption);

      let statuses = values[1].data;
      statuses.splice(0, 0, AllOption);

      let states = values[2].data;
      states.splice(0, 0, AllOption);

      let fileOwners = values[3].data;
      fileOwners.splice(0, 0, AllOption);

      let lenders = values[4].data;

      let mortManagers = values[5].data;

    //  let brokers = values[6].data;
      //brokers.splice(0,0,AllOption);
      self.setState({
        //set values
        matterTypes: matterTypes,
        matterType: as.matterType,
        matterStatuses: statuses,
        matterStatus: as.matterStatus,
        matterStates: states,
        matterState: as.matterState,
        fileOwners: fileOwners,
        fileOwner: as.fileOwner,
        lenders: lenders,
        lender: lenders[0],
        mortManagers: mortManagers,
        mortManager: mortManagers[0],
      //  brokers:brokers,
       // broker:brokers[0],
      },()=>{
          console.log(this.state.matterTypes)
      });
    });
    if (as.shouldSearch) {
      this.detailedSearch(); 
      as.shouldSearch = false;
    }
  }

  borrowersChanged(event) {
    //changed events for filter

    const target = event.target;
    this.setState({
      borrowers: target.value
    });
  }

  resetSearch() {
    //reset search

    this.props.resetAdvancedSearch();
    let as = this.props.getAdvancedSearch();
    let self = this;

    let matterStatus = as.matterStatus;
    let matterState = as.matterState;

    this.setState(
      {
        matterType: AllOption,
        matterStatus: AllOption,
        matterState: AllOption,
        fileOwner: AllOption
      },
      () => {
        self.setState(
          {
            matterNo: "",
            borrowers: "",
            shouldSearch: false,
            matterType: AllOption,
            matterStatus: matterStatus,
            matterStatuses: [matterStatus],
            matterState: matterState,
            matterStates: [matterState],
            fileOwner: AllOption,
            fileOwners: [AllOption],
            mortManager: AllOption,
            mortManagers: [AllOption],
            brokerDetail:"",
            titleRefNo: "",
            lenderRefNo: "",
            address: "",
            show: false
          },
          () => {
            self.loadLookupLists(as); //update lookup lists
          }
        );
      }
    );
  }
  brokerDetailChanged(event){
    const target = event.target;

    this.setState({
      broker:{id:target.value},
    });
    
  }
  addressChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      address: target.value
    });
  }

  fileOwnerChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      fileOwner: {id:target.value}
    });
  }

  lenderChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      lender: {id:target.value}
    });
  }

  lenderRefNoChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      lenderRefNo: target.value
    });
  }

  matterNoChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      matterNo: target.value
    });
  }

  matterTypeChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      matterType: {id:target.value}
    });
  }

  mortManagerChanged(event) {
    //setter

    const target = event.target;

    this.setState({
      mortManager: {id:target.value}
    });
  }

  matterStateChanged(event) {
    //setter

    const target = event.target;
    this.setState({
      matterState: {id:target.value}
    },()=>{
        //alert(this.state.matterState)
    });
  }

  matterStatusChanged(event) {
    //setter

    const target = event.target;
    this.setState({
      matterStatus: {id:target.value}
    });
  }

  titleRefNoChanged(event) {
    //setter

    const target = event.target;
    this.setState({
      titleRefNo: target.value
    });
  }

  detailedSearch() {
    //setter

    let self = this;

    self.setState(
      {
        //passess vaues and performs search
        isWorking: true,
        show: false
      },
      () => {
        let as = this.props.getAdvancedSearch();
        as.matterNo = this.state.matterNo;
        as.borrowers = this.state.borrowers;
        as.matterType = this.state.matterType;
        as.matterStatus = this.state.matterStatus;
        as.matterState = this.state.matterState;
        as.fileOwner = this.state.fileOwner;
        as.lender = this.state.lender;
        as.mortManager = this.state.mortManager;
        as.titleRefNo = this.state.titleRefNo;
        as.lenderRefNo = this.state.lenderRefNo;
        as.address = this.state.address;
        as.workComplete = this.workComplete;
        as.broker=null;
        self.props.handleDetailedSearch(as);
      }
    );
  }

  generateCSV() {
    //setter

    let self = this;

    self.setState(
      {
        //passess vaues and performs search
        isWorking: true,
        show: false
      },
      () => {
        let as = this.props.getAdvancedSearch();
        as.matterNo = this.state.matterNo;
        as.borrowers = this.state.borrowers;
        as.matterType = this.state.matterType;
        as.matterStatus = this.state.matterStatus;
        as.matterState = this.state.matterState;
        as.fileOwner = this.state.fileOwner;
        as.lender = this.state.lender;
        as.mortManager = this.state.mortManager;
        as.titleRefNo = this.state.titleRefNo;
        as.lenderRefNo = this.state.lenderRefNo;
        as.address = this.state.address;
        as.workComplete = this.workComplete;

        self.props.handleGenerateCSV(as);
      }
    );
  }

  workComplete() {
    this.setState({
      isWorking: false
    });
  }

  static getMatchingItem(list, id) {
    //find matching item from list and give it the proper ID
    let ndx;

    for (ndx = 0; ndx < list.length; ndx++) {
      if (list[ndx].id === id) {
        return list[ndx];
      }
    }

    return { id: 0, value: "All" };
  }

  handleCancelUpdate() {
    this.setState({
      show: false
    });
  }

  handleColumnClick() {
    this.setState({
      show: !this.state.show
    });
  }

  // buildColumnEditor(advancedSearch) {
  //   //sets the columns available for editing
  //   let list = advancedSearch.columns;

  //   let popupContent = list.map(function(c) {
  //     if (c.Visible) {
  //       return (
  //         <div>
  //           <input id={c.Field} type="checkbox" ref={c.Field} defaultChecked />{" "}
  //           {c.Title}{" "}
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div>
  //           <input id={c.Field} type="checkbox" ref={c.Field} /> {c.Title}{" "}
  //         </div>
  //       );
  //     }
  //   });

  //   return (
  //     <div
  //       id="custDisplayColumn"
  //       style={{ float: "left", textAlign: "left", padding: "10px" }}
  //     >
  //       <h3>Grid Display Columns</h3>
  //       {popupContent}
  //       <div className="button-group-filt">
  //         <Button primary onClick={this.handleColumnUpdate}>
  //           Apply
  //         </Button>
  //         <Button onClick={this.handleCancelUpdate}>Cancel</Button>
  //       </div>
  //     </div>
  //   );
  // }

  handleColumnUpdate(event) {
    //handles updating columns based on how they're organised
    event.preventDefault();

    let self = this;
    let as = this.props.getAdvancedSearch();
    let cols = as.columns;
    let i;

    for (i = 0; i < cols.length; i++) {
      let myElement = self.refs[cols[i].Field];
      let value = false;
      if (myElement.value) {
        value = myElement.checked;
      }
      cols[i].Visible = value;
    }

    as.columns = cols;
    this.props.updateAdvancedSearch(as);

    this.setState({
      show: false
    });
  }
    render() {
        return (

           
          <div className={"sbBox "+this.props.display}>
              <div className="sbBG"></div>

              <div className="SearchLoanBox">

                  <IconBox 
                      id="slbBTNClose"
                      title="Close Search"
                      addCSS="" 
                      iconURL="../../../images/icons/OutlineDark/Cross.svg"
                      clickAction={this.props.closeAction}
                  />
                  
                  <div className="slbTitle">
                      <h5>Search Loans</h5>
                  </div>

                  <div className="slbFormsBox">
                      <div>
                          <div className="inputLabel">
                              MSA Reference
                          </div>
                          <div>
                              <input 
                                  type="text"
                                  name="matterNo"
                                  onChange={this.matterNoChanged}
                                  value={this.state.matterNo}
                                  autoFocus 
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Loan Type 
                          </div>
                          <div>
                              <DropDownList options={this.state.matterTypes} value={this.state.matterType} onChange={this.matterTypeChanged} >
                                  {/* All */}
                              </DropDownList>              
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              MSA File Owner 
                          </div>
                          <div>
                              <DropDownList options={this.state.fileOwners} value={this.state.fileOwner} onChange={this.fileOwnerChanged}>
                                  {/* All */}
                              </DropDownList>
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Title Reference 
                          </div>
                          <div>
                              <input type="text" 
                                  onChange={this.titleRefNoChanged}
                                  value={this.state.titleRefNo}
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Borrowers
                          </div>
                          <div>
                              <input type="text" 
                                  onChange={this.borrowersChanged}
                                  value={this.state.borrowers}
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Status 
                          </div>
                          <div>
                              <DropDownList options={this.state.matterStatuses} value={this.state.matterStatus} onChange={this.matterStatusChanged}>
                                  {/* All */}
                              </DropDownList>
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Lender 
                          </div>
                          <div>
                              <LenderControl
                                  data={this.state.lenders}
                                  value={this.state.lender}
                                  onChange={this.lenderChanged}
                              />
                              {/* <DropDownList options={this.state.lenders} value={this.state.lender} onChange={this.lenderChanged}>
                                  
                              </DropDownList> */}
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Lender Reference 
                          </div>
                          <div>
                              <input type="text" 
                                  onChange={this.lenderRefNoChanged}
                                  value={this.state.lenderRefNo}
                              />
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Branch 
                          </div>
                          <div>
                              <DropDownList options={this.state.matterStates} value={this.state.matterState} onChange={this.matterStateChanged}>
                                  {/* All */}
                              </DropDownList>
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Referer 
                          </div>
                          <div>
                              <MortManagerControl
                                  data={this.state.mortManagers}
                                  value={this.state.mortManager}
                                  onChange={this.mortManagerChanged}
                              />
                              {/* <DropDownList options={this.state.mortManagers} value={this.state.mortManager} onChange={this.mortManagerChanged}>
                                  
                              </DropDownList> */}
                          </div>
                      </div>
                      <div>
                          <div className="inputLabel">
                              Security 
                          </div>
                          <div>
                              <input type="text"
                                  onChange={this.addressChanged}
                                  value={this.state.address}
                              />
                          </div>
                      </div>
                      <div className="slbActionBox">  
                          <div className="slbAction" style={{"grid-template-columns":this.props.slbActionGTC}}>
                              <Button clickFunction={this.detailedSearch}>Search</Button>
                              <Button addCSS="secondaryBTN" clickFunction={this.resetSearch}>Clear</Button>

                              {this.props.columnBtnStatus==1?( 
                              <div 
                                className="btnFieldsBox"
                                onClick={this.props.columnAction}
                              > 
                                <IconBox
                                  iconURL="../../../images/icons/OutlineDark/Options.svg"
                                  addCSS=""
                                  tooltip="Column Actions" 
                                />
                              </div>
                              ):""}
                          </div>
                      </div> 
                  </div>
              
              </div>
       
          </div>
                        
        )
    }
}
SearchLoanBox.propTypes = {
    getAdvancedSearch: PropTypes.func.isRequired,
    handleDetailedSearch: PropTypes.func.isRequired,
    handleGenerateCSV: PropTypes.func.isRequired,
    updateAdvancedSearch: PropTypes.func.isRequired
  };
export default SearchLoanBox
