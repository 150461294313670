import React, { Component } from 'react'

import './css/BoxFullscreen.css';

class BoxFullscreen extends Component {
    render() {
        return (
            <div className="bfCenter" id={this.props.id}>
                {this.props.children} 
            </div>
        )
    }
}

export default BoxFullscreen
