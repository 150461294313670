import React, { Component } from 'react'

import './css/ButtonIcon.css';
 


class ButtonIcon extends Component {
    render() {
        return (
            <div 
                id={this.props.id}
                className={"ButtonIcon " + this.props.addCSS}
                style={{ backgroundImage: `url(${this.props.iconURL})`,display:`${this.props.display}`}}
                // style={this.props.style}
                onClick={this.props.onClick}
            > 
                {this.props.children}
            </div>
        )
    }
}

export default ButtonIcon
