import React, { Component } from 'react'
import { Redirect }                                         from 'react-router-dom';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card';
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 

import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';
import TabButtonBox from '../../molecules/TabButtonBox/TabButtonBox';

import LoanStatus from '../../organism/LoanStatus/LoanStatus';
import DataTable from '../../organism/DataTable/DataTable'; 

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';



import './css/ConsentsPage.css';
import AutoLogout from '../../../auto-logout';
import { AuthService } from '../../../services/auth-service';
import DataTableEventsConsents from '../../organism/DataTableEventsConsents/DataTableEventsConsents';
import InsightBox from '../../molecules/InsightBox/InsightBox';

class ConsentsPage extends Component {

    constructor(props){
        super(props);
        let matterrestrictions = AuthService.getUserTypeRestrictions();
    //     this.state={
    //         tbbTarget: null ,
    //         $windowWidth: window.innerWidth,
    //         pageTitle : 'discharges',
    //         leftContent : 'discharges',
    //         desktopMenu: this.props.location.state.desktopMenu,
    //         lmwInstructMSASub: this.props.location.state.lmwInstructMSASub
    //     }
    //    // alert(this.state.lmwInstructMSASub)

    //     this.updateTab=this.updateTab.bind(this)
        
    //     this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }
 
        this.state = {
            tbbTarget: null ,
            $windowWidth: window.innerWidth,
            pageTitle : 'consents',
            leftContent : 'consents',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            profileDisplay: "",
            hasError: false,
            matterrestrictions: matterrestrictions,
            UserHasConsentsRestriction: null,
            UserHasMatterRestriction: false,
            InsightBox:0,
        }
        //alert(this.state.lmwInstructMSASub)

        this.updateTab=this.updateTab.bind(this)
        
        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    updateWindowDimension=()=>{
        this.setState({
            $windowWidth: window.innerWidth,
            profileDisplay: ""
        })

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    } 

    updateTab($tbbTarget){
        //console.log("Active: "+$tbbTarget)

        this.setState({
            tbbTarget: $tbbTarget
        })
    }


    componentDidMount(){
        window.addEventListener("resize", this.updateWindowDimension)
        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("consents").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            })
        }

        var matterrestrictions = AuthService.getUserTypeRestrictions();
        let userHasNewLoansRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasNewLoansRestriction) : false;
        let userHasDischargesRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasDischargesRestriction) : false;
        let userHasConsentsRestriction = matterrestrictions != null? matterrestrictions.map(d=>d.UserHasConsentsRestriction) : false;

        this.setState(
        {
            UserHasConsentsRestriction: userHasConsentsRestriction[0] && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false,
            UserHasMatterRestriction: ((userHasNewLoansRestriction[0] != null && userHasNewLoansRestriction[0] != "" ? userHasNewLoansRestriction[0] : false) ||
                                        (userHasDischargesRestriction[0] != null && userHasDischargesRestriction[0] != "" ? userHasDischargesRestriction[0] : false) ||
                                        (userHasConsentsRestriction[0] != null && userHasConsentsRestriction[0] != "" ? userHasConsentsRestriction[0] : false)
                                        )? true: false
        })
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }
    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }
    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }


    render() {

        console.log("Window Width: "+this.state.$windowWidth); 
        console.log("Active: "+this.state.tbbTarget);

        var $mobileMaxWidth=768; 
        const $TabButtonProps={ 
            "title":"",
            "activeTarget":"LoanStatusBox",
            "action": this.updateTab,
            "addcss": "TabButtonIcon",
            "buttons": [ 
                {
                    "id": "1",
                    "label": "Loan Status",
                    "target":"LoanStatusBox",
                    "addcss":" iGreyLoanStatus",
                    "status": this.state.tbbTarget=="LoanStatusBox"||this.state.tbbTarget==null?1:0
                },{
                    "id": "2",
                    "label": "Recently Viewed",
                    "target":"RecentLoansBox",
                    "addcss":"iGreyrecentlyViewed",
                    "status": this.state.tbbTarget=="RecentLoansBox"?1:0
                }, {
                    "id": "3",
                    "label": "Recent Events",
                    "target": "RecentEventsBox",
                    "addcss":" iGreyRecentEvents",
                    "status": this.state.tbbTarget == "RecentEventsBox" ? 1 : 0
                }  
            ]
        }; 

        if(this.state.hasError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        }

        //if(this.state.UserHasConsentsRestriction == true && localStorage.getItem("ssoTimeout") == null ){
        if(this.state.UserHasMatterRestriction )
        {
            if(this.state.UserHasConsentsRestriction == false){
                if(localStorage.getItem("ssoTimeout") == null ){
                    return <Redirect to="/error" />;
                }
                else {
                    return <Redirect to="/ssoerror" />;
                    //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
                }
            }
        }        
        
        if(this.state.leftContent != this.state.pageTitle){
            //alert(this.state.lmwInstructMSASub)
            return( 
                <DisplayLeftMenu 
                    page={this.state.leftContent} 
                    desktopMenu={this.state.desktopMenu}lmwInstructMSASub={this.state.lmwInstructMSASub}
                />
            )
        } 

        return ( 
             
            <LeftMenuContent 
                id="ConsentsPage"  
                page="consents" 
                desktopMenu={this.state.desktopMenu}
                callBack = {this.handleLeftMenuCallback} lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
                    <ProfileMenu
                        addCSS={this.state.profileDisplay=="display"?"table":"none"}
                        clickAction={() => this.toggleProfileMenu()}
                    />

                    <div className="content">
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                        {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>
                            <IconBox 
                                addCSS="btnClose" 
                                iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                                tooltip="Close Insights"
                                clickAction={() => this.hideInsightBox()} 
                            />
                            <TitleSegment>Loan Insights</TitleSegment>
                            <div className='StatBar'>
                                <div className='SBLabel'>Stats</div>
                                <div className='SBData'>100</div>
                            </div>
                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Settled Matters</div>
                                <div className='SBData'>100</div>
                            </div>
                            <div className='StatGroup'>
                                <div className='SGTitle'>Number of Settled Matters</div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>NSW</div>
                                    <div className='SGData'>1000</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>VIC</div>
                                    <div className='SGData'>500</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>QLD</div>
                                    <div className='SGData'>356</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>WA</div>
                                    <div className='SGData'>576</div>
                                </div>
                                <div className='SGBar'>
                                    <div className='SGLabel'>SA</div>
                                    <div className='SGData'>234</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>TAS</div>
                                    <div className='SGData'>642</div>
                                </div> 
                                <div className='SGBar'>
                                    <div className='SGLabel'>NT</div>
                                    <div className='SGData'>123</div>
                                </div> 
                            </div> 
                            <div className='StatBar'>
                                <div className='SBLabel'>Average Days to Settlement</div>
                                <div className='SBData'>3</div>
                            </div>
                            <div className='StatBar'>
                                <div className='SBLabel'>Settled using eSign Documents</div>
                                <div className='SBData'>25</div>
                            </div>
                            <div className='StatBar'>
                                <div className='SBLabel'>Number of Escalated Files</div>
                                <div className='SBData'>30</div>
                            </div>
                            <div className='StatAction'>
                                <ButtonIcon
                                    id="insights"
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={()=>{this.moreInsights()}}
                                >More Insights</ButtonIcon>
                            </div> 
                        </div> */}

                        <TabButtonBox  
                            tbbinit={ $TabButtonProps } 
                        /> 

                        <div className={ this.state.tbbTarget=="LoanStatusBox"||this.state.tbbTarget==null||this.state.$windowWidth>$mobileMaxWidth?"LoanStatusBox content1 block":" LoanStatusBox content1 none"}> 
                            <TitleSegment>Loan Status</TitleSegment>
                            <Card addCSS="loanStatus">
                                <LoanStatus /> 
                            </Card>
                        </div>

                        <div className={ this.state.tbbTarget=="RecentLoansBox" || this.state.$windowWidth>=$mobileMaxWidth?"RecentLoansBox content2 block":"RecentLoansBox content2 none"}> 
                            <TitleSegment>Recently Viewed Loans</TitleSegment>
                            <Card addCSS="loanStatus"> 
                                <DataTable />
                            </Card> 
                        </div>

                        <div className={this.state.tbbTarget == "RecentEventsBox" || this.state.$windowWidth >= $mobileMaxWidth ? "RecentEventsBox content3 block" : "RecentEventsBox content3 none"} >
                            <div className="RVLTitleBox">
                                <div className="RVLTitle">
                                    <TitleSegment>Recent Events</TitleSegment>
                                </div>
                            </div>
                            <Card addCSS="recentEventsCard"> 
                                <DataTableEventsConsents />  
                            </Card>
                        </div>

                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
ConsentsPage=AutoLogout(ConsentsPage);
export default ConsentsPage
