import React, { Component } from 'react'
 

import Logo from '../../atom/Logo/Logo';
import FormTextBox from '../../atom/FormTextBox/FormTextBox';
import Button from '../../atom/Button/Button';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
import ValidationText from '../../atom/ValidationText/ValidationText';
import {AuthService}                                  from '../../../services/auth-service';

import './css/CreateAccountForm.css';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';

class CreateAccountForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            mobileNumber: '',
            userName: '',
            goBack: false,
            respMessage: null,
            loginInProgress: false,
        }

        this.updateFirstname = this.updateFirstname.bind(this);
        this.updateLastname = this.updateLastname.bind(this);
        this.updateMobile = this.updateMobile.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.backClicked = this.backClicked.bind(this);
        this.createAccount = this.createAccount.bind(this);
    }

    updateFirstname(event){
        this.setState({
            firstName: event.target.value
        });
    }
    updateLastname(event){
        this.setState({
            lastName: event.target.value
        });
    }
    updateMobile(event){
        this.setState({
            mobileNumber: event.target.value
        });
    }
    updateEmail(event){
        this.setState({
            userName: event.target.value
        });
    }

    backClicked(event){
        this.setState({
            goBack: true
        },()=>{
            this.props.createAccountCallback([this.state.goBack,null])
        });

    }

    createAccount(event){
        event.preventDefault();
        this.setState({
            loginInProgress: true,
            respMessage:'',
        });
        AuthService.createAccount(this.state.userName, this.state.mobileNumber,this.state.firstName, this.state.lastName).then((resp) =>(
             
        this.setState({                                                             //Evaluate Repsonse and login or decline based on that. 
            respMessage:  resp.Message.includes('User created')? '<b>Create Account Successful</b><br /> Your account has been created! We have sent login details to your email provided':resp.Message.replace("error", ""),//'Your account has been created! We have sent login details to your email provided.',
            forgotPassword:false,
            loginInProgress: false,
            createAccount:false,
            //respMessage:null,
         },()=>{
             if(resp.Message.includes('error')){
                this.props.createAccountCallback([false,this.state.respMessage])
             }
             else this.props.createAccountCallback([true,this.state.respMessage])
            
         })
    ), (err) => {
        this.setState({
            loginInProgress: false,
            respMessage: 'Please ensure your details are correct. You must also have an active matter with MSA.',           
        },()=>{
            //setTimeout(()=>{this.setState({createAccountButtonClass: "create-account-button"})},1000)
        });
    });

    }

    render() {  
        let progressIcon = "";
        
        if (this.state.loginInProgress) {   
            progressIcon=<ProgressDisplay/>;
        }    
        else{
            progressIcon=(
            <ButtonGroup>
                
                {/* <Button className="btnCancel" onClick={this.backClicked}>Back</Button> */}
                <Button className={this.state.loginInProgress?"btnDisabled":""} clickFunction={this.createAccount}>Create Account</Button>
                <Button 
                    className={this.state.loginInProgress?"btnDisabled":""}
                    addCSS="tertiaryBTN" 
                    clickFunction={this.backClicked}>
                    Back
                </Button>
            </ButtonGroup>);
        }  
        return (
            <> 
               <Logo />
               {/* <ProgressDisplay></ProgressDisplay> */}
               {/* {progressIcon} */}
               {/* <h5>{progressIcon}</h5> */}
                <h5>Create Account</h5>
                <p>Please provide your information below.</p>
                <ValidationText  msgeType="errorMsge">{this.state.respMessage}</ValidationText>
                <FormTextBox type="text" className="formTextBox" placeholder="First Name" onChange={this.updateFirstname}></FormTextBox>
                <FormTextBox type="text" className="formTextBox" placeholder="Last Name" onChange={this.updateLastname}></FormTextBox>
                <FormTextBox type="text" className="formTextBox" placeholder="Mobile Number" onChange={this.updateMobile}></FormTextBox>
                <FormTextBox type="text" className="formTextBox" placeholder="Email" onChange={this.updateEmail}></FormTextBox>
                {/* <ButtonGroup>
                    <Button className={this.state.loginInProgress?"btnDisabled":""} onClick={this.backClicked}>Back</Button>
                    <Button className={this.state.loginInProgress?"btnDisabled":""} onClick={this.createAccount}>Create Account</Button>
                </ButtonGroup> */}
                {progressIcon}
            </>
            
        )
    }
   
}

export default CreateAccountForm
