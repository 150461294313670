import React                                                from 'react';

import './css/ProgressDisplay.css';
import Loader from '../Loader/Loader';

export default class ProgressDisplay extends React.Component {

    render() {
        return (
            <div className="progress-container">
                {/* <div className="progress-div"> */}
                {/* <Loader className="progress-loader" /> */}
                Loading... &nbsp;&nbsp;
                {/* <div className="hourSpin">
                    &#8987;
                </div> */}
                <span class="dots-circle-spinner loading"></span>
                {/* </div> */}
            </div>
        );
    }
}