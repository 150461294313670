var config = {
    dateFormat: 'D/M/YYYY',
    dateTimeFormat: 'D/M/YYYY ',
    serverUrl: process.env.REACT_APP_API_URL,
    documentUrl: process.env.DOC_SERVICE_URL,
    versionNo: '1.0.0.35',
    isDev: false,
    isTest: false,
    isMsa: true
};

export default config;
