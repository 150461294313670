import React                                                from 'react';
import PropTypes                                            from "prop-types";
// import { DropDownList }                                     from '@progress/kendo-react-dropdowns';

// import { AuthService }                                      from "../../services/auth-service";
import { AuthService } from '../../../services/auth-service';
import DropDownList from '../../atom/DropDownList/DropDownList';

class LenderControl extends React.Component {

    render() {  //Controls which lender the user is able to search from 

        if (this.props.data && this.props.data.length > 0) {

            let data = this.props.data;
            

            if ( AuthService.user.UserTypeId === 2 && data.length === 1 ) {
                let opt = data[0];
                return (opt.value);
            }

            // return (<DropDownList className="k-dropdownlist" data={this.props.data} textField={'value'} value={this.props.value} onChange={ this.onChange } />);
            return (<DropDownList options={this.props.data} value={this.props.data} onChange={ this.props.onChange } />);
        }

        return ('');
    }
}

LenderControl.protoType = {
    value: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default LenderControl;