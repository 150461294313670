import React, { Component } from 'react'
 
import CenterContent from '../../template/CenterContent/CenterContent'; 
import Card from '../../atom/Card/Card';
import MarginGuide from '../../atom/MarginGuide/MarginGuide';
import Logo from '../../atom/Logo/Logo'; 
import Button from '../../atom/Button/Button';
import {AuthService}                                  from '../../../services/auth-service';

import './css/DisclaimerPage.css';

class DisclaimerPage extends Component {
    constructor(props){
        super(props);
        this.state={
            shouldRedirect: false,
            userType: null,
            externalPrivileges: [],
            matterRestrictions: []
        }

        this.handleAcknowledged = this.handleAcknowledged.bind(this);

    }

    handleAcknowledged(event){
        event.preventDefault();
       
        AuthService.termsAcknowledge(this.props.userDetails[0], this.props.userDetails[1], true).then(resp=>{
            //console.log("disclaimer resp")
            //console.log(resp)
            this.setState({
                shouldRedirect: resp.IsSuccessful,
                // loginInProgress: false,
                // forgotPassword:resp.MustRedirect,
                userType:resp.UserTypeId==undefined?resp.User.UserType.UserTypeId:resp.UserTypeId,
            },()=>{
                //alert(this.state.shouldRedirect)
                //alert(this.state.userType);
                if(this.state.userType == 2 || this.state.userType == 3 && this.state.shouldRedirect){
                    AuthService.getUserExternalPrivilegesList().then((resp)=>{
                        this.state.externalPrivileges.push(resp.data.Content);   
                        AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                        //set user matter type restrictions
                        AuthService.GetUserMatterTypeRestriction().then((resp)=>{
                            this.state.matterRestrictions.push(resp.data.Content);   
                            AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                            this.props.parentCallback(this.state.shouldRedirect);
                            },(err)=>{
                                //alert(err)
                                alert("There is an error with the request")
                                    this.setState({
                                    hasLoginError: true
                                    })
                            });
    
                        
                   },(err)=>{
                       alert(err)
                   });
                }
                else if(this.state.userType == 4 && this.state.shouldRedirect){
                    AuthService.getUserBrokerExternalPrivilegesList().then((resp)=>{
                        this.state.externalPrivileges.push(resp.data.Content);   
                        AuthService.setUserExternalPrivilegesMenu(this.state.externalPrivileges);
                        //set user matter type restrictions
                        AuthService.GetUserMatterTypeRestriction().then((resp)=>{
                            this.state.matterRestrictions.push(resp.data.Content);   
                            AuthService.setUserMatterTypeRestriction(this.state.matterRestrictions);
                            this.props.parentCallback(this.state.shouldRedirect);
                            },(err)=>{
                                //alert(err)
                                alert("There is an error with the request")
                                    this.setState({
                                    hasLoginError: true
                                    })
                            });
    
                        
                   },(err)=>{
                       alert(err)
                   });
                }
                else{
                    this.props.parentCallback(this.state.shouldRedirect);
                }
                
                 
                
                
            })
        },(err)=>{

        });
        
    }

    render() {
        return ( 
             
            <CenterContent id ="DisclaimerPage">

                <MarginGuide />
    
                <div className="content">
                    <Card>
                        <Logo />
                        <h3>Loantrak Unauthorised Use/Access</h3>
                        <p>
                        Loantrak is a system owned and operated by MSA National Pty Limited ACN 604 605 156 (“MSA”) and it’s for official use only by its Clients and their authorised representatives. Anyone using this system expressly consents to the Loantrak terms and conditions (click here to see the <a href ="https://msanational.com.au/downloads/LoanTrak-terms-and-conditions-032019.pdf" target="_blank">LoanTrak terms and conditions</a>). Unauthorised access, unauthorised attempted access, or unauthorised use of Loantrak is a violation of the Loantrak terms and conditions and may be subject to having their activities on this system monitored and recorded by system personnel. All http and ftp accesses are logged. In the course of such monitoring, or in the course of system maintenance or trouble shooting, the activities or authorised users also may be monitored. Anyone using Loantrak expressly consents to such monitoring and is advised that if such monitoring reveals possible evidence or criminal activity, further legal action may be taken.
                        </p>
                        <Button clickFunction={this.handleAcknowledged}>Acknowledge</Button>
                    </Card>  
                </div>

            </CenterContent>  
        )
    }
}

export default DisclaimerPage
