import React, { Component } from 'react' 
import { Redirect } from 'react-router-dom';
import Dropzone   from 'react-dropzone';

import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon';
import Card from '../../atom/Card/Card'; 
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 
import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/VariationUploadPage.css';   
import { AuthService } from '../../../services/auth-service';
import { InstructionService } from '../../../services/instruction-service';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import AutoLogout from '../../../auto-logout';
import InsightBox from '../../molecules/InsightBox/InsightBox';


class VariationUploadPage extends Component { 
    
    constructor(props){
        super(props) 

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        this.state={ 
            pageTitle : 'variationupload',
            leftContent : 'variationupload',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            shouldRedirect: false,
            isInstructPriv: null,
            isLoading:false,           
            successfulSubmit:false,
            InstructionSubmit:false,
            LenderFile: '',
            LenderReference:'',
            base64: '',
            matterId: null,
            LenderFiles:[],
            MatterNote:{},
            msg:'',
            InsightBox:0, 
            CanAutoViewMatterOnUpload: false,
        }
    

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.lenderReferenceChanged = this.lenderReferenceChanged.bind(this);
        this.convertToBase64 = this.convertToBase64.bind(this);
        this.submitInstruction = this.submitInstruction.bind(this);

        this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    } 

    componentDidMount(){
        document.title='Loantrak - New Loans/Variation Upload';
        // AuthService.isUserAuthorisedToInstruct().then((resp)=>{
        //     this.setState({isInstructPriv:true});
        // },(err)=>{
        //     this.setState({
        //         isInstructPriv:false,
        //         shouldRedirect:true
        //     });
        // });

        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("new loan or variation upload").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            });
        }

        AuthService.CanAutoViewMatterOnUpload().then(
            (resp)=>{
                this.setState({CanAutoViewMatterOnUpload:resp.data.Content})
            },
            (err)=>{
              this.setState({CanAutoViewMatterOnUpload:false})
            }
          );
        AuthService.getUserExternalPrivilegesList().then((resp)=>{
            this.setState({
                CanInstructDischarges:resp.data.Content.CanInstructDischarges,
                CanProvideDischargePayout:resp.data.Content.CanProvideDischargePayout,
                CanInstructNewLoans:resp.data.Content.CanInstructNewLoans,
                CanInstructDischargeUpload:resp.data.Content.CanInstructDischargeUpload,
                CanUploadNewLoansOrVariation:resp.data.Content.CanUploadNewLoansOrVariation,
                shouldRedirect: !resp.data.Content.CanUploadNewLoansOrVariation,
            },()=>{
                console.log(this.state.CanUploadNewLoansOrVariation)
            });
        },(err)=>{
            console.log("there is an error")
            //this.setState({isInstructPriv:false});
        })

        window.addEventListener("resize", this.updateWindowDimension)
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    lenderReferenceChanged(e){
        this.setState({
            LenderReference: e.target.value
        })
    }

    convertToBase64(file){
        console.log(file);
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            if(file.name.toLowerCase().includes('.xml')){
                reader.readAsText(file);
            }else{
                reader.readAsDataURL(file);
            }
            
            reader.onload = () => resolve(file.name.toLowerCase().includes('.xml')?btoa(reader.result):reader.result);
            reader.onerror = error => reject(error);
          });
        
    }    

    handleOnDrop = (files, rejectedfiles) =>{    
        let instruction={};
        let instrFiles = this.state.LenderFiles;
  
        if(files && files.length > 0)
        {         
            let fileData = "";   
            files.forEach((e,i)=>{         
                //const f = new Blob([files[i].name], {type: files[i].type});
                //const url = URL.createObjectURL(f);    
                var fileName = files[i].name;
                var regex = /^[0-9a-zA-Z \-_.]*$/;//"^[a-zA-Z0-9 \-_.]";
                

                if(!regex.test(fileName) || fileName.length > 200)
                {
                    alert ("Invalid filename. Please check.");
                    return;
                }
                
                var base64 ="";
                
                this.convertToBase64(files[i]).then((resp)=>{;base64=resp;console.log(base64);
                    if(files[i].name.toLowerCase().includes('.xml'))
                    {
                        instrFiles.push({
                            'FileName':"Instruction_"+i+".xml",
                            'FileData':base64,
                            'FileUrl':"",
                        });   
                        
                        this.setState({LenderFiles:instrFiles});
                    }
                    else{

                        // alert("Hello");
                        // console.log(files[i]);
                        // console.log(files[i].name);
                        instrFiles.push({
                            'FileName':files[i].name,
                            'FileData':base64.split(',')[1],
                            'FileUrl':"",
                        });   
                        console.log(instrFiles[i]);
                        this.setState({LenderFiles:instrFiles});
                    }
              
                });    
                    
              
            }
                    
                
            );
          
        }

    }   
    
    Validate(){
        let valid=true;
        let xmlFileInside=false;
        for(let i =0; i<this.state.LenderFiles.length; i++){
            if(this.state.LenderFiles[i]['FileName'].includes('.xml')){
                xmlFileInside=true;
            }
        }
        
        if(!xmlFileInside){
            alert('No Xml provided.');
            valid=false;
        }

    }

    submitInstruction(e){        
        e.preventDefault();
        let elem = document.getElementById('variation-form');
        if(!elem.checkValidity()){
            elem.reportValidity();
            return;
        }
        if(this.state.LenderFiles.length == 0){
            alert("Please select files to upload.");
            return;
        }
        this.setState({
            isLoading: true,
            //InstructionSubmit:true,
        });
        var instruction = {};
        instruction['LenderReference']=this.state.LenderReference;
        instruction['Files']=this.state.LenderFiles;
        console.log(instruction);

        InstructionService.SendInstructionVariationUpload(instruction).then((resp)=>{
            this.setState({msg:'',
                InstructionSubmit:true,
                isLoading:false,
                matterId:resp.data.Content.MatterId
            },()=>{
                if(this.state.InstructionSubmit && this.state.CanAutoViewMatterOnUpload && this.state.matterId!=null)
                {
                    window.open('/matter-details:'+this.state.matterId, "_self")
                }
            });
            
         
        },(err)=>{
            if (err.response && err.response.status === 401) {
                this.setState({
                    isLoading:false,
                    shouldRedirect: true
                });

            } else {
                alert('An error has occured. Please contact MSA.');
                this.setState({isLoading:false,});
            }            
        });

    }

    updateWindowDimension = () => { 

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }


    render() {  
        let divInstruction = (<div />);
        if(this.state.shouldRedirect){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
            //return( <Redirect to="/error"></Redirect>);
        }
        
        if(this.state.leftContent != this.state.pageTitle){
            // alert(this.state.lmwInstructMSASub)
             return( 
                 <DisplayLeftMenu 
                     page={this.state.leftContent} 
                     desktopMenu={this.state.desktopMenu} 
                     lmwInstructMSASub={this.state.lmwInstructMSASub}
                 />
             )
        }

        // if (this.state.isLoading) {            
        //     divInstruction = (
        //         <ProgressDisplay />
        //     )            
        // }

        let files = [];
        this.state.LenderFiles.forEach((v,i)=>{files.push((<li>{v['FileName']}</li>))});         

        return ( 
             
            <LeftMenuContent
                id="VariationUploadPage"
                page="variationupload" 
                desktopMenu={this.state.desktopMenu} 
                callBack = {this.handleLeftMenuCallback} 
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
                    

                    <div className="content"> 
                        <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>
                    
                        
                        <div className="FormsBox">
                            
                            <div className="spacingBottomLarge">
                                <TitleSegment>New Loan/Variation Upload</TitleSegment> 
                                <Card>
                                    {divInstruction}
                                    {this.state.matterId===null ? this.state.IsError? (<div>An error has occured. </div>): (
                                        <form id="variation-form">
                                            <div className="inputBox">
                                                <div className="inputLabel">
                                                    Lender Reference
                                                </div>
                                                <div>
                                                    {/* <input type="text" placeholder="e.g. MNXXXXXXXXXXXXXXXXX" /> */}
                                                    <input type="text" className={this.state.LenderReference?'successTextBox':''} placeholder="e.g. MNXXXXXXXXXXXXXXXXX" id={'LenderRef'} value={this.state.LenderReference} onChange={this.lenderReferenceChanged} required autoComplete={false}/>
                                                </div>
                                            </div> 
                                            <div>
                                                <div className="inputLabelSub">
                                                    Drag and drop any XML, PDF, PNG, DOCX, DOX, XLSX, XLS, JPG, GIF files for instruction, and accompanying supporting documents. 
                                                    {/* Please ensure that an Instruction XML is provided. */}
                                                </div>
                                                <div>
                                                    {files != "" ?  <div className="inputLabel">Uploaded:</div> : ""}
                                                    <div className="inputLabel">
                                                        {files}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="inputUploadBox">
                                                        <div className="iubContent">
                                                            <IconBox
                                                                addCSS="iubIcon"
                                                                id=""
                                                                title=""
                                                                iconURL="../../../../images/icons/OutlineGrey/Upload.svg"
                                                            />
                                                        <Dropzone onDrop={this.handleOnDrop} style={{height:"100px", width:"100%", backgroundColor:"none"}}>                                                
                                                                
                                                            
                                                                <div className="titleBox">Drag & drop file here or click to select file to upload.</div>
                                                                <div className="subText">File format: XML, PDF, PNG, DOCX, DOC, XLSX, XLS, JPG, GIF</div>
                                                            </Dropzone> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></form>):
                                            (<div><p><h5>{this.state.rework?"Rework Instructions have been received by MSA National, and will be actioned shortly.": "We have successfully recorded this new loan in our system."}</h5></p><p><h5><b>MSA Reference: {this.state.matterId}</b></h5></p>
                                            <p>{this.state.msg}</p><br/>
                                            </div>) }
                                </Card>
                            </div>

                            <div>
                            {this.state.InstructionSubmit && this.state.msg===''?
                                <a type="link" className="btn btn-secondary" href="/variationupload">                            
                                    <ButtonIcon
                                                id=""
                                                addCSS="ButtonIconSelected biRight mainBtn"
                                                //iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                                //onClick={this.submitInstruction}
                                                >
                                    Close</ButtonIcon>
                                </a>
                                :this.state.isLoading?<ProgressDisplay/>
                                :
                                <ButtonIcon
                                    id=""
                                    addCSS="ButtonIconSelected biRight mainBtn"
                                    iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                    onClick={this.submitInstruction}>
                                Submit New Loan/Variation Upload</ButtonIcon>
                            }
                            </div>

                        </div>    

                        
                    </div> 

                    

            </LeftMenuContent>  
        )
    }
}
VariationUploadPage=AutoLogout(VariationUploadPage);
export default VariationUploadPage
