import React, { Component } from 'react'
 
import HighlightText from '../../atom/HighlightText/HighlightText'; 

 
import './css/DataTableUser.css';
import IconBox from '../../atom/IconBox/IconBox';
import Moment from "moment";
import { AuthService } from '../../../services/auth-service';
import { UserLender } from '../../../services/user-lender';
import UserDetailPage from '../../pages/UserDetailPage/UserDetailPage';

class DataTableUser extends Component {

    constructor(props){
        super(props);

        this.state={
            viewUserDetail: false,
            userid: null   
        }

        this.handleClick = this.handleClick.bind(this);
    }

    viewUserDetail(id){
        //window.open('/userdetails:' + id)
        this.setState({
            viewUserDetail: true,
            userid: id
        });
    }

    handleClick(id, event){
        let name = event.target.name
        
        UserLender.updateLenderUserAccess(id) 
        .then(response => {
            if(response.data.Successful == true)
            {
               //this.getLenderListForUser()              
                if(this.props.UserFilter == 'Enabled') {this.props.getEnabledLenderListForUser(); }
                else if(this.props.UserFilter == 'Disabled') {this.props.getDisabledUserLenderList();}
                else if(this.props.UserFilter == 'Search') {this.props.simpleUserSearch();}
                else {this.props.getLenderListForUser();}

                alert('User Updated!')
            }
            //console.log(response.data.Successful)
        })
        .catch(error => {
        //console.log(error)
        })       
    }

    render() {
        let userDataTable='';

        if(this.state.viewUserDetail){
            // return(<UserDetailPage
            //     userid={this.state.userid}
            //     ></UserDetailPage>)
            window.location.href='/userdetails:'+this.state.userid
        }

        if (this.props.userLender.length > 0){
            userDataTable = this.props.userLender.map(d => (
                <div className="dtRow" key={d.UserId}> 
                    
                    <div>
                        <div className="dtHeaderMobile">Name</div>
                        {d.FullName}
                    </div> 
                    
                    <div>
                        <div className="dtHeaderMobile">Email</div>
                        {d.Email}
                    </div>
                    
                    <div>
                        <div className="dtHeaderMobile">Status</div>
                        <HighlightText type={d.Enabled ? "HighlightGreen" : "HighlightGrey"} id={d.UserId} onClick={(e)=> this.handleClick(d.UserId, e)}>{d.Enabled ? "Active" : "Inactive"}</HighlightText>                        
                    </div>

                    <div>
                        <div className="dtHeaderMobile">Last Login</div>
                        {d.LastLogin ? Moment(d.LastLogin).format("DD-MM-YYYY HH:MM:SS ")  : ""}
                    </div> 

                    <div>
                        <div className="dtHeaderMobile">Actions</div>
                        <IconBox
                            iconURL="../../../images/icons/OutlineDark/Edit.svg" 
                            clickAction={(e)=>{this.viewUserDetail(d.UserId, e)}}
                        /> 
                    </div>

                </div>
            ));
          }
        
        return ( 
            <div className="DataTable">
                <div className="dtHeader">
                    <div>Name</div>
                    <div>Email</div>
                    <div>Status</div>
                    <div>Last Login</div>
                    <div></div> 
                </div>
                {userDataTable}            
               
               
            </div> 
        )
    }
}

export default DataTableUser
