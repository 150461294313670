import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BrowserRouter, Route }                             from 'react-router-dom'
import { Redirect }                                         from 'react-router-dom';

import './css/LabelCount.css';

class LabelCount extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props){
        super(props)
        this.state = {
            shouldRedirect: false,
            path: '',
            arg: '',
            sArg: '',
        }
    }

    componentDidMount(){
        //alert(PropTypes.object);
        //alert(this.props.path, this.props.arg, this.props.sarg);
    }

    nextPath(path, arg, sArg) {
        this.setState({
            path: path,
            arg: arg,
            sArg: sArg,
            shouldRedirect: true,
        });
        //this.router.push()
        // this.context.router.history.push(
        //     {
        //         pathname: path,
        //         state: { searchArg: arg, loanType: sArg }
        //     })
            // return(<Redirect to={{
            //     //    pathname: '/search',
            //     //    arg: 15,
            //     //    sarg: 1
            //        pathname: '/search',
            //         state: { searchArg: arg, loanType: sArg }
            //    }}/> )
    }

    // nextPath(path, arg, sArg) {
    //     this.context.router.history.push(
    //         {
    //             pathname: '/logout'//path,
    //            // state: { searchArg: arg, loanType: sArg }
    //         })
    // }

    render() {
        if(this.state.shouldRedirect){
            return(<Redirect to={{
                //    pathname: '/search',
                //    arg: 15,
                //    sarg: 1
                   pathname: this.state.path,
                    state: { searchArg: this.state.arg, loanType: this.state.sArg }
               }}/> )
        }
        var $leftIcon=this.props.leftIcon;; 

        return (
           
            // <div className={"LabelCount "+this.props.addCSS} onClick={() => this.nextPath(this.props.path, this.props.arg, this.props.sarg)}>
            // <div className={"LabelCount "+this.props.addCSS} onClick={() => this.nextPath(this.props.path, this.props.arg, this.props.sarg)} ></div>
            <div className={"LabelCount "+this.props.addCSS} >
            {/* <div className={"LabelCount "+this.props.addCSS} onClick={this.props.onClick}> */}
                <div
                    // class={this.props.class}
                    // className={$leftIcon?"iconLeft":""}
                    className={this.props.className}
                    style={{ backgroundImage: `url(${$leftIcon})`}}
                    title={this.props.title}
                >{this.props.label}{this.props.children}</div>
                <div>{this.props.count}</div>
            </div>
        )
    }
}

export default LabelCount;
