import React, { Component } from 'react';
import { Redirect }                                         from 'react-router-dom';
import Moment from "moment";
import SAlert from "sweetalert2";
 
import Button from '../../atom/Button/Button'; 
import ButtonIcon from '../../atom/ButtonIcon/ButtonIcon'; 
import Card from '../../atom/Card/Card'; 
import IconBox from '../../atom/IconBox/IconBox';
import TitleSegment from '../../atom/TitleSegment/TitleSegment'; 
import LabelCount from '../../atom/LabelCount/LabelCount'; 

import BackBar from '../../molecules/BackBar/BackBar'; 
import ProfileMenu from '../../molecules/ProfileMenu/ProfileMenu';

import LeftMenuContent from '../../template/LeftMenuContent/LeftMenuContent';


import './css/MatterDetailsPage.css';
import DisplayLeftMenu from '../../template/DisplayLeftMenu';
import LabelBtn from '../../atom/LabelBtn/LabelBtn';
import LabelForm from '../../atom/LabelForm/LabelForm';
import { MatterDocumentService } from '../../../services/matter-document-service';
import ProgressDisplay from '../../atom/ProgressDisplay/ProgressDisplay';
import {
    isBrowser,
    isMobile
} from "react-device-detect";

import { saveAs } from 'file-saver';
import ButtonGroup from '../../molecule/ButtonGroup/ButtonGroup';
import DisplayMessage from '../../atom/DisplayMessage/DisplayMessage';
import AutoLogout from '../../../auto-logout';
import FormCheckBox from '../../atom/FormCheckBox/FormCheckBox';
import DropDownList from '../../atom/DropDownList/DropDownList';
import { CustodyService } from '../../../services/custody-service';
import { AuthService } from '../../../services/auth-service';
import InsightBox from '../../molecules/InsightBox/InsightBox';

class MatterDetailsPage extends Component {

    constructor(props){
        super(props);

        try{
            var dmInit=this.props.location.state.desktopMenu;
            var lmwimsInit=this.props.location.state.lmwInstructMSASub;
        }catch{
            var dmInit="";
            var lmwimsInit="";
        }

        this.state = {
            pageTitle : 'custodycentre',
            leftContent : 'custodycentre',
            desktopMenu: dmInit,
            lmwInstructMSASub: lmwimsInit,
            //matterdocs:this.props.matterDocuments,
            profileDisplay: "",
            viewDocError: false,
            isUrgentRequest: false,
            hasError: false,
            InsightBox:0, 
            //selectedDeliveryLocation: this.props.deliveryLocations.length != 0 ? this.props.deliveryLocations[0].id : ""
        }

        this.handleLeftMenuCallback = this.handleLeftMenuCallback.bind(this);
        this.rowClicked = this.rowClicked.bind(this);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
       // this.updateSelectedDeliveryLocation = this.updateSelectedDeliveryLocation.bind(this);

       this.showInsightBox = this.showInsightBox.bind(this); 
        this.hideInsightBox = this.hideInsightBox.bind(this);
        this.moreInsights = this.moreInsights.bind(this);
    }

    componentDidMount(){
        // console.log("matterdocs")
        // console.log(this.state.matterdocs)
        //console.log("requested?");
        //console.log(this.props.custodyDocumentRequested);
        // console.log("deliveryLocations");
        // console.log(this.props.deliveryLocations);
        // console.log("selectedDeliveryLocation");
        // console.log(this.state.selectedDeliveryLocation);
        // console.log("irene");
        // console.log(this.props.matterIsDigital);
        window.addEventListener("resize", this.updateWindowDimension)
        if(localStorage.getItem("ssoTimeout") == null){
            AuthService.logUserActivity("custody centre details").then((r) =>{
                //alert(r.Content)
            },(err)=>{
                this.setState({
                 hasError: true
                })
            })
        }
    }  

    updateWindowDimension = () => {
         

        if(this.state.$windowWidth<768){
            this.hideInsightBox();
        }
    }

    rowClicked(id, name) {
        this.setState({
            loading: true,
        });
       
        const self = this;
        MatterDocumentService.getCustodyDocumentAsPdf(id).then(
            resp => {
               //const file = new Blob([resp.data], {type: resp.request.response.type});
                const file = new Blob([resp.data], { type: "application/pdf" });
                const url = URL.createObjectURL(resp.data);
                var isIE = document.documentMode;

                if (!isMobile && !isIE) {
                    window.open(url);
                    //this.openDoc(url);
                    // window.open('/view-document:'+url)

                    //this.props.setDocFrame(url);
                    //   this.props.updateDocOnly();
                } else {
                    //this.props.setDocFrame(url);
                    //this.props.updateDocOnly();
                    saveAs(file, name + ".pdf");
                }

                //window.open(url);
            },
            err => {
                console.log("thisistheerror")
                console.log(err)
                // console.log(err.response)
                // console.log(err.response.status)
                if ((err.response && err.response.status === 401) || err==undefined || err =="undefined" || err.includes("Network Error") || err.includes("401")) {
                    self.setState({
                        shouldRedirect: true,
                        hasError: true
                    },()=>{console.log(this.state.hasError)});
                } else {
                    self.setState({
                        shouldRedirect: false,
                        viewDocError: true
                    },()=>{console.log(this.state.hasError)});

                    // new SAlert({
                    //     title: "View Document",
                    //     text: "Unable to retrieve document.",
                    //     type: "warning"
                    // });
                }
                console.log(this.state.hasError)
            }
        );
        this.setState({
            loading: false
        });
    }

    handleLeftMenuCallback(child, dmMode, InstructSubMenu){
        //alert(InstructSubMenu);
        this.setState({
            leftContent: child,
            desktopMenu: dmMode,
            lmwInstructMSASub: InstructSubMenu
        });
    }

    toggleProfileMenu(){
        if(this.state.profileDisplay==""){
            this.setState({
                profileDisplay: "display"
            });
        }else{
            this.setState({
                profileDisplay: ""
            });
        }
    }

    closeDialog(){
        this.setState({
            viewDocError: false
        })
    }

    setUrgentRequest(e)
    {
        this.setState({isUrgentRequest: !this.state.isUrgentRequest},()=>
        {
            //alert(this.state.isUrgentRequest)
        })
    }

    // updateSelectedDeliveryLocation(arg) {
    //     //arg.preventDefault();
    //     const target = arg.target;
    //     this.setState(
    //       {
    //         selectedDeliveryLocation: target.value
    //       },
    //       () => {
    //         alert(this.state.selectedDeliveryLocation);
    //       }
    //     );
    //   }

    showInsightBox(){
        // alert("Show Insights")
        if(this.state.InsightBox==0){
            this.setState({
                InsightBox: 1
            }); 
        }else{
            this.setState({
                InsightBox: 0
            });
        }
    }

    hideInsightBox(){
        // /alert("Hide Insights")
        this.setState({
            InsightBox: 0
        });
    }

    moreInsights(){ 
        this.handleLeftMenuCallback("insights", "","");
        // window.open('/insights') 
    }



    render() {
        let matterDocumentsGrid='';
        let matterRequestDocumentsGrid = '';
        let requestButton = '';
        let requestPackButton = '';
        // if(this.props.loading)
        // {
        //     return(<ProgressDisplay/>);
        // }
        if(this.state.hasError){
            //    return( <Redirect to="/login">
            //    </Redirect>);
            if(localStorage.getItem("ssoTimeout") == null){
                return <Redirect to="/error" />;
            }
            else{
                return <Redirect to="/ssoerror" />;
                //window.location.href=this.state.ssourl  + '?errormessage=' + this.state.errorMessage;
            }
        }
        if(this.props.loading)
        {
           requestPackButton=(<ProgressDisplay/>);
        }     
        else{
            if((!this.props.msg || this.props.msg == '') && this.props.msgType != 'error'){
                requestPackButton=(<ButtonGroup>
                    <Button id='btnSendRequest' clickFunction={(e) => this.props.sendRequestPack(e,this.state.isUrgentRequest)}>Send Request</Button>
                    <Button 
                    addCSS="tertiaryBTN"
                    clickFunction={(e) => this.props.cancelRequestPack(e)}>Close</Button> 
        
                    {/* <Button
                    style={{ float: "left" }}
                    clickFunction={(e) => this.props.cancelRequestPack(e)}
                    >
                    { this.props.msg.includes('recorded')?'Close' : 'Cancel'}
                    
                    </Button>
                    <Button
                    style={{ float: "right" }}
                    clickFunction={(e) => this.props.sendRequestPack(e)}
                    >
                    Request
                    </Button> */}
                </ButtonGroup>);   
            }
            else{
                if(this.props.msg.includes('recorded') || this.props.alreadyExists){
                    requestPackButton=(<ButtonGroup>
                        <Button id='CancelPopUp' addCSS="btnRed" clickFunction={(e) => this.props.cancelCustodyRequest(e)}>Cancel Request</Button>
                        <Button 
                        addCSS="tertiaryBTN"
                        clickFunction={(e) => this.props.cancelRequestPack(e)}>Close</Button>
                        {/* <Button id='CancelPopUp' clickFunction={(e) => this.props.cancelCustodyRequest(e)}>{ this.props.msg.includes('recorded')?'Cancel Request' : 'Send Request'}</Button>
                        <Button 
                        addCSS="tertiaryBTN"
                        clickFunction={(e) => this.props.cancelRequestPack(e)}> { this.props.msg.includes('recorded')?'Close' : 'Cancel'}</Button>        */}
                       
                    </ButtonGroup>);   
                }
                else{
                    requestPackButton=(<ButtonGroup>
                        <Button clickFunction={(e) => this.props.sendRequestPack(e,this.state.isUrgentRequest)}>Send Request</Button>
                        <Button 
                        addCSS="tertiaryBTN"
                        clickFunction={(e) => this.props.cancelRequestPack(e)}>Close</Button>             
                    </ButtonGroup>);     
                }
            }
            
        }

        //if(this.props.custodyDocumentRequested == true && this.props.matterIsDigital == false){
        if(this.props.custodyDocumentRequested == true && !this.props.docsSentOrReceived){
            requestButton = (
                <div className="DocumentButtonBox">
                    {/* <Button id='CancelFront' addCSS="txtBTN btnCancelPack" */}
                    <Button id='CancelFront' addCSS="btnCancelPack btnSmall btnRed"
                    clickFunction={this.props.cancelCustodyRequest}
                    >Cancel Request</Button>
                    <ButtonIcon 
                        addCSS="ButtonIconSelected biRight SubButtonIcon  btnStatusPack "
                        iconURL="../../../../images/icons/OutlineWhite/Check.svg"
                    >Requested</ButtonIcon>
                </div> 
            )
        }
        else{
            if(this.props.custodyDocumentRequested == true && this.props.docsSentOrReceived){
                requestButton = (
                    <div className="DocumentButtonBox">
                        <ButtonIcon 
                            addCSS="ButtonIconSelected biRight SubButtonIcon  btnSentOrReceived "
                            iconURL="../../../../images/icons/OutlineWhite/Check.svg"
                        >Packet has already been Sent or Received</ButtonIcon>                        
                    </div> 
                )
            }
            else{
                if(this.props.matterIsDigital == false){
                    requestButton = (
                        <div className="DocumentButtonBox">
                           <Button addCSS="btnRequestPack"  clickFunction={e => this.props.handleRequestPack(e)}>Request Original Paper Packet</Button>
                        </div> 
                    )
                }  
            }
                      
        }

        if (this.props.requestPackModal) {
            matterRequestDocumentsGrid = (
                this.props.matterNonDigitalDocuments.map(d => 
                    <>   
                      <div className="dtRow" key={d.MatterDocumentId} onClick={(e)=>{this.rowClicked(d.MatterDocumentId, d.DocName)}}>
                          <LabelBtn 
                              label={d.DocName}
                              count="Open"//{Moment(d.DocModDate).format("YYYY-MM-DD")}
                              leftIcon="../../../../images/icons/Colored/PDF.png"
                              addCSS="btnOpenPDF"
                          /> 
                          {/* <LabelForm 
                              label={Moment(d.DocModDate).format("YYYY-MM-DD")}
                          /> */}
                      </div>
                    </>
                )            
            );
          }

        matterDocumentsGrid = (
            this.props.matterDocuments.map(d => 
              <>   
                <div className="dtRow" key={d.MatterDocumentId} onClick={(e)=>{this.rowClicked(d.MatterDocumentId, d.DocName)}}>
                    <LabelBtn 
                        label={d.DocName}
                        count="Open"
                        leftIcon="../../../../images/icons/Colored/PDF.png"
                        addCSS="btnOpenPDF"
                    /> 
                </div>
              </>)
          );

        console.log("Window Width: "+this.state.$windowWidth); 
        console.log("Active: "+this.state.tbbTarget);

        var $mobileMaxWidth=768; 
        const $TabButtonProps={ 
            "title":"Jump to:",
            "activeTarget":"MatterDetailsBox",
            "action": this.updateTab,
            "buttons": [ 
                {
                    "id": "1",
                    "label": "Matter Details",
                    "target":"MatterDetailsBox",
                    "status": 1
                },{
                    "id": "2",
                    "label": "Documents (4)",
                    "target":"DocumentsBox",
                    "status": 0
                } 
            ]
        }; 

        if(this.state.leftContent != this.state.pageTitle){
            return( 
                <DisplayLeftMenu 
                    page={this.state.leftContent} 
                    desktopMenu={this.state.desktopMenu} 
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                />
            )
        } 

        return ( 
             
            <LeftMenuContent 
                id="MatterDetailsPage"
                page="custodycentre" 
                desktopMenu={this.state.desktopMenu}
                callBack = {this.handleLeftMenuCallback}
                lmwInstructMSASub={this.state.lmwInstructMSASub}
                statBoxShow={()=>this.showInsightBox()} 
                showInsightsDetails={this.moreInsights}
            > 
             
            {/* <LeftMenuContent id="MatterDetailsPage"> */}

                <ProfileMenu
                    addCSS={this.state.profileDisplay=="display"?"table":"none"}
                    clickAction={() => this.toggleProfileMenu()}
                />

                <div className="content">
                    <InsightBox show={this.state.InsightBox} callBackInsight={this.hideInsightBox} moreInsightCallback={this.moreInsights} onLeaveAction={() => this.hideInsightBox()}></InsightBox>


                    {/* <div className={'InsightBox '+(this.state.InsightBox==1?"":"none ")}>

                        <IconBox 
                            addCSS="btnClose" 
                            iconURL="../../../images/icons/OutlineWhite/Cross.svg" 
                            tooltip="Close Insights"
                            clickAction={() => this.hideInsightBox()} 
                        />

                        <TitleSegment>Loan Insights</TitleSegment>

                        <div className='StatBar'>
                            <div className='SBLabel'>Stats</div>
                            <div className='SBData'>100</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Settled Matters</div>
                            <div className='SBData'>100</div>
                        </div>

                        <div className='StatGroup'>
                            <div className='SGTitle'>Number of Settled Matters</div>
                            <div className='SGBar'>
                                <div className='SGLabel'>NSW</div>
                                <div className='SGData'>1000</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>VIC</div>
                                <div className='SGData'>500</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>QLD</div>
                                <div className='SGData'>356</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>WA</div>
                                <div className='SGData'>576</div>
                            </div>
                            <div className='SGBar'>
                                <div className='SGLabel'>SA</div>
                                <div className='SGData'>234</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>TAS</div>
                                <div className='SGData'>642</div>
                            </div> 
                            <div className='SGBar'>
                                <div className='SGLabel'>NT</div>
                                <div className='SGData'>123</div>
                            </div> 
                        </div> 

                        <div className='StatBar'>
                            <div className='SBLabel'>Average Days to Settlement</div>
                            <div className='SBData'>3</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Settled using eSign Documents</div>
                            <div className='SBData'>25</div>
                        </div>

                        <div className='StatBar'>
                            <div className='SBLabel'>Number of Escalated Files</div>
                            <div className='SBData'>30</div>
                        </div>

                        <div className='StatAction'>
                            <ButtonIcon
                                id="insights"
                                addCSS="ButtonIconSelected biRight mainBtn"
                                iconURL="../../../../images/icons/OutlineWhite/Next.svg"
                                onClick={()=>{this.moreInsights()}}
                            >More Insights</ButtonIcon>
                        </div> 

                    </div> */}



                    <BackBar onClick={(e) => this.props.backToSearch(e)}>back to Custody Centre</BackBar> 

                    <div className="RequestPacket-popup-box" style={{display: this.props.requestPackModal ? 'block':'none'}}>
                        <div className="RequestPacket-box">
                            <h4 className="listContentTitle">
                                Request Original Paper Packet - Loan:{" "}
                                {this.props.selectedMatterData.MatterId}
                            </h4> 
                            <div>
                                You are requesting the paper documents listed below
                                for {this.props.selectedMatterData.BorrowerDetails}
                                &nbsp;(MSA Reference:{" "}
                                {this.props.selectedMatterData.MatterId}) to be sent
                                from the MSA Vault to you at the following address:
                                {/* <DropDownList options={this.props.deliveryLocations} value={this.state.selectedDeliveryLocation} onChange={this.props.updateSelectedDeliveryLocation}> */}
                                <DropDownList options={this.props.deliveryLocations} value={this.props.selectedDeliveryLocation} onChange={this.props.updateSelectedDeliveryLocation}>
                
                                </DropDownList>                               
                                <br /><br />
                                <p style={{color:'red'}} onClick={(e)=> this.setUrgentRequest(e)} >
                                <b>
                                <FormCheckBox  checked={this.state.isUrgentRequest} onChange={(e)=> this.setUrgentRequest(e) }
                                    title="Please tick this box for an urgent request (an additional fee will be applied)"
                                />
                                </b>
                                </p>
                                {/* to be sent
                                from the MSA Vault to you at the following address: */}    
                                {/* <DropDownList
                                    data={this.props.deliveryLocations}
                                    value={this.state.selectedDeliveryLocation}
                                    textField={"value"}
                                    onChange={this.updateSelectedDeliveryLocation}
                                    style={{ width: "100%" }}
                                ></DropDownList> */}                            
                            </div>
                            <div className="RPListBox">{matterRequestDocumentsGrid}</div> 
                            <DisplayMessage message={this.props.msg} messageType={this.props.msgType}/>  
                            {requestPackButton}                     
                      
                        </div>
                    </div>
      
                    <div className="DocumentErrorPopup" style={{display: this.state.viewDocError && !this.state.hasError ? 'block':'none'}}>
                        <div className="DocumentErrorPopup-box">
                        <h4>Document View</h4>
                        Unable to retrieve document.    
                        <br />   <br /><br />
                        <Button 
                    addCSS="btnOk"
                    clickFunction={(e) => this.closeDialog(e)}>OK</Button> 
                        </div>
                    </div>
      
         


                    <div className="MatterDetailsTabBox">
                        
                        {/* <TabButtonBox  
                            tbbinit={ $TabButtonProps } 
                        />  */}

                        <div className="content1"> 
                            <TitleSegment>Matter Details</TitleSegment>
                            <Card addCSS="MatterDetailsBox"> 
                                {/* <LabelForm label="MSA Reference" count={this.props.matterData.MatterId} /> 
                                <LabelForm label="Matter Number" count={this.props.matterData.OrigMatterId} /> */}
                                <LabelForm label="MSA Reference" count={('0000000' + this.props.matterData.MatterId).slice(this.props.matterData.MatterId.toString().length)} /> 
                                <LabelForm label="Matter Number" count={('0000000' + this.props.matterData.OrigMatterId).slice(this.props.matterData.OrigMatterId.toString().length)} />
                                <LabelForm label="Lender Reference Number" count={this.props.matterData.LenderId} />
                                <LabelForm label="Borrower" count={this.props.matterData.BorrowerDetails} />
                                <LabelForm label="Number of Documents" count={this.props.matterDocuments.length} />
                                <LabelForm label={this.props.docsSentOrReceived?"Packet Sent To":"Packet Current Location"} count={this.props.currentDocumentLocation} />
                                <LabelForm label={"Securities"} count={this.props.matterData.Securities != "" ? this.props.matterData.Securities : this.props.matterData.SecurityItems!=""?this.props.matterData.SecurityItems : ''} />
                                <LabelForm label={"Transaction Type"} count={this.props.matterData.TransactionType != "" && this.props.matterData.TransactionType != null ? this.props.matterData.TransactionType : 'N/A'} />
                            </Card>
                        </div>

                        <div className="content2">
                            <TitleSegment>Documents ({this.props.matterDocuments.length})</TitleSegment>
                            <Card addCSS="DocumentsBox">
                            {matterDocumentsGrid.length!==0?(
                                <>
                                    <div className="listContentTitle docsTitleBar">
                                        <div>
                                            <h6 className="">File Name</h6>
                                        </div>
                                        {this.props.canDownloadCustodianDocuments ? this.props.downloading ? 'Downloading..' :
                                            <div>
                                                <ButtonIcon 
                                                addCSS="ButtonIconFormDark"            
                                                iconURL="../../../../images/icons/OutlineWhite/Folder.svg"
                                                onClick={this.props.downloadDocuments}
                                                >Download</ButtonIcon>                                        
                                            </div> : ''
                                        }
                                        
                                    </div>
                                    {/* <h6 className="listContentTitle">File Name</h6>
                                    <div className="DocumentButtonBox">                   
                                        <Button id='CancelFront' addCSS="btnCancelPack btnSmall"
                                        clickFunction={this.props.downloadDocuments}
                                        >Download</Button>                    
                                    </div>  */}
                                    <div className="mdgListBox">
                                        {matterDocumentsGrid}
                                    </div> 
                                </> 
                            ):"No document found." }                    
                            </Card> 

                            {requestButton} 

                                                         

                        </div>

                    </div>

                </div>                    

            </LeftMenuContent>  
        )
    }
}
MatterDetailsPage=AutoLogout(MatterDetailsPage);
export default MatterDetailsPage
