import React, { Component } from 'react'

 
import HighlightText from '../../atom/HighlightText/HighlightText';

import './css/ButtonGroupCapsule.css';
import { tsNullKeyword } from '@babel/types';

class ButtonGroupCapsule extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            highlighted: this.props.highlighted,
            activeHighlight: 'HighlightGrey bgcGreen',
            inactiveHighlight: 'HighlightGrey'
        }
        this.changeHighlight = this.changeHighlight.bind(this);
    }

    componentDidMount(){
        //alert(this.props.highlighted);
        // if(this.state.highlighted == 'active'){
        //     this.setState({
        //         activeHighlight: 'HighlightGrey bgcGreen',
        //         inactiveHighlight: 'HighlightGrey'
        //     });
        // }
        // else{
        //     this.setState({
        //         activeHighlight: 'HighlightGrey',
        //         inactiveHighlight: 'HighlightGrey bgcGreen'
        //     });
        // }
    }
    changeHighlight(e){
        //alert(e.target.id);
        this.props.callback(e.target.id);
        if(e.target.id == "active"){

            this.setState({
                activeHighlight: 'HighlightGrey bgcGreen',
                inactiveHighlight: 'HighlightGrey',
                
            });
        }
        else{
            this.setState({
                activeHighlight: 'HighlightGrey',
                inactiveHighlight: 'HighlightGrey bgcGreen',
                
            });
        }       
            
    }
    render() {
        return ( 
            <div className={"ButtonGroupCapsule " + this.props.addCSS}>
                <div className="bgcContent">
                    <HighlightText type={this.props.highlighted=='active'?'HighlightGrey bgcGreen':'HighlightGrey'} onClick={this.changeHighlight} id = "active">Active</HighlightText>
                    <HighlightText type={this.props.highlighted=='active'?'HighlightGrey':'HighlightGrey bgcGreen'}  onClick={this.changeHighlight} id = "inactive">Inactive</HighlightText>
                </div>
            </div>
        )
    }
}

export default ButtonGroupCapsule
