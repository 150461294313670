import React, { Component } from 'react'

import './css/Label.css';

class Label extends Component {
    render() {
        return (
            <label className="formLabel">
                {this.props.children}
            </label>
        )
    }
}

export default Label
