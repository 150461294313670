import React, { Component } from 'react'

import MarginGuide from '../../atom/MarginGuide/MarginGuide';
import Logo from '../../atom/Logo/Logo';
import LeftMenu from '../../organism/LeftMenu/LeftMenu' 
import LeftMenuSlim from '../../organism/LeftMenuSlim/LeftMenuSlim'; 
import BottomMenu from '../../organism/BottomMenu/BottomMenu';

import './css/LeftMenuContent.css';

class LeftMenuContent extends Component {
    constructor(props){
        super(props); 
        
        
        this.state={ 
            desktopMenu: this.props.desktopMenu,
            lmwInstructMSASub: this.props.lmwInstructMSASub,
            desktopOpenedSubMenu: this.props.desktopOpenedSubMenu
        }

        this.handleMenuCallback = this.handleMenuCallback.bind(this);
        this.toggleDesktopMenu = this.toggleDesktopMenu.bind(this);  
    }

    handleMenuCallback(child, InstructSubMenu, e){
        console.log.bind(e)
        //alert(InstructSubMenu);
        this.setState({
            lmwInstructMSASub: InstructSubMenu
        });
        //alert(this.state.lmwInstructMSASub);
        this.props.callBack(child, this.state.desktopMenu,InstructSubMenu);
    }

    toggleDesktopMenu(currentDMenu, $slimSub){ 
        console.log("Current Menu: "+currentDMenu)
        console.log("Opened Sub: "+$slimSub) 
        this.setState({
            desktopOpenedSubMenu: $slimSub 
        });

        if(currentDMenu!="slim"){
            this.setState({
                desktopMenu: "slim",
                lmwInstructMSASub: "show"
            });
        }else{
            this.setState({
                desktopMenu: ""
            });
        }
    }

    render() {

        console.log("desktopMenu: "+this.state.desktopMenu);
        let dMenu;
        
        if(this.state.desktopMenu=="slim"){
            //alert("Slim")
             dMenu=<LeftMenuSlim page={this.props.page} callBack = {this.handleMenuCallback} toggleAction={this.toggleDesktopMenu} 
             statBoxShow={this.props.statBoxShow}  
             showInsightsDetails={this.props.showInsightsDetails}
            />;
        }else{
            //alert("Wide")
            dMenu=<LeftMenu 
                    page={this.props.page} 
                    callBack = {this.handleMenuCallback}  
                    toggleAction={()=>this.toggleDesktopMenu(this.state.desktopMenu)}
                    lmwInstructMSASub={this.state.lmwInstructMSASub}
                    desktopOpenedSubMenu={this.state.desktopOpenedSubMenu}
                    statBoxShow={this.props.statBoxShow} 
                    showInsightsDetails={this.props.showInsightsDetails}
                   /> ;
        }


        return ( 
            <div className="LeftMenuContent" id={this.props.id}> 

                <MarginGuide />

                <Logo addCSS="logoMobile"/>
                
                {dMenu}


                {this.props.children} 


                <BottomMenu
                    page={this.props.page}
                    callBack={this.handleMenuCallback} 
                />

            </div> 
        )
    }
}

export default LeftMenuContent
