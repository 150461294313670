import React, { Component } from 'react'

import './css/DataListResult.css';

class DataListResult extends Component {
    render() {
        return (  
            <div className={"DataListResult " + this.props.addCSS}>{this.props.children}</div>
        )
    }
}

export default DataListResult
